import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useContext, useState, useRef, useEffect } from "react";
import { SalesContext } from "../../SalesContext/SalesContext";
import FileInputButton from "../../../Ventas/FileInputButton/FileInputButton";
import InputCalendar from '../../../form/InputCalendar';
import AuthUser from "../../../../AuthUser";
import { Toast } from "primereact/toast";
import { Dropdown } from 'primereact/dropdown';

import { useFormik } from "formik";
// import AuthUser from "../../../AuthUser";

import axios from "axios";
import { current } from "@reduxjs/toolkit";

export default function S2ContactEdit({ data, crud, convertDate }) {
    // Estados para almacenar los datos del formulario y la respuesta de la API

    const { sales, setSales } = useContext(SalesContext);
    const [numDocumento, setNumDocumento] = useState();
    const [nombres, setNombres] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');

    const [contacto_id, setContacto_id] = useState(null);
    const [clinica_id, setClinica_id] = useState(null);
    const formRef = useRef(null)
    const optionsDocument= [
        { name: 'DNI', code: 1 },
        { name: 'Carnet de Extranjeria', code: 3},
        { name: 'Pasaporte', code: 4},
     ];
    const { http } = AuthUser()
  
    const toast = useRef(null);
    const showToast = (type, title, detail) => {
        if (toast.current) {
            toast.current.show({
                severity: type,
                summary: title,
                detail: detail,
                life: 3000,
            });
        } else {
            console.error("Toast no inicializado");
        }
    };
    // Función para manejar el evento de clic en el botón "Consultar"
    const handleSubmit = () => {
        // Realiza la solicitud GET a la API con el número de documento ingresado
        axios
            .get(`https://dniruc.apisperu.com/api/v1/dni/${sales?.GCContacto?.numero_documento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
            .then((res) => {
                // Actualiza los estados con los datos obtenidos de la API
                setSales({
                    ...sales,
                    GCContacto: {
                        ...sales.GCContacto,
                        nombres: res.data.nombres,
                        apellido_paterno: res.data.apellidoPaterno,
                        apellido_materno: res.data.apellidoMaterno
                    }
                });
            })
            .catch((error) => {
                // Manejo de errores: muestra un mensaje de error y limpia los campos
                console.error(error);
                setNombres('');
                setApellidoPaterno('');
                setApellidoMaterno('');
            });
    };
    //#region VARIABLES DE INSTANCIA
    //#endregion

    //#region VARIABLES
    const [visible, setVisible] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedImage, setSelectedImage] = useState(data.persona.foto);
    //#endregion
    console.log('data de la persona', data.persona)
    //#region FUNCIONES
    const replaceContact = () => {
        setSales(prevState => ({
            ...prevState, GCContacto: {
                ...prevState.GCContacto,
                contacto_id: data.id,
                tipo_documento_id: data.persona.tipo_documento_id,
                numero_documento: data.persona.numero_documento,
                nombres: data.persona.nombres || "",
                apellido_paterno: data.persona.apellido_paterno || "",
                apellido_materno: data.persona.apellido_materno || "",
                cargo: data.persona.cargo || "",
                fecha_nacimiento: data.persona.fecha_nacimiento,
                hobbies: data.persona.hobbies || "",
                celular: data.persona.celular,
                telefono: data.persona.telefono || null,
                correo: data.persona.correo || "",
                comentarios: data.comentarios || "",
                foto: data.persona.foto || "https://i.ibb.co/4SHrqm4/user-male.png",
            }
        }));
        // setSelectedImage(data.persona.foto || "https://i.ibb.co/4SHrqm4/user-male.png");
        setVisible(true)
    };
    const onchangeInputs = (e) => {
        const { name, value } = e.target || e;
        setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, [name]: value } }));
    };
    const onchangeFechaNacimiento = (e) => {
        if(e?.value){
            let fecha = e?.value;
            const anio = fecha.getFullYear();
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const dia = fecha.getDate().toString().padStart(2, '0');
            const fechaFormateada = `${anio}-${mes}-${dia}`;
         
         
         setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, fecha_nacimiento: fechaFormateada } }));
   
         }
    };

    const handleFileChange = (selectedFiles) => {
        if (selectedFiles.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(selectedFiles[0]);
        } else {
            setSelectedImage(null);
        }
    }
    const handleDeleteImage = () => {
        setSelectedImage("https://i.ibb.co/4SHrqm4/user-male.png");
    };

    const onchangeFile = (file) => {
        setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, foto: file } }));
    }
    //#endregion

    //#region FUNCIÓN DE ENVÍO DE DATOS
    const sendEdit = async (e) => {
        console.log("envio")
        e.preventDefault();
        console.log("ya dentro")


        const resp = await crud("editar", setVisible);
        console.log("res", resp)
        if (resp) {
            setVisible(false);
        }
    }
    const handleEnvio = (e) => {
        console.log("Clic en el botón de enviar formulario");
        if (formRef.current) {
            console.log("El formulario es válido");
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        } else {
            console.log("El formulario no es válido");
        }
    }

    //#endregion

    const handleSubmitUpdate = (e, data) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append("tipo_documento_id", data.GCContacto.tipo_documento_id);
        formData.append("numero_documento", data.GCContacto.numero_documento);
        formData.append("nombres", data.GCContacto.nombres);
        formData.append("apellido_paterno", data.GCContacto.apellido_paterno);
        formData.append("apellido_materno", data.GCContacto.apellido_materno);
        formData.append("cargo", data.GCContacto.cargo);
        formData.append("fecha_nacimiento", data.GCContacto.fecha_nacimiento);
        formData.append("hobbies", data.GCContacto.hobbies);
        formData.append("celular", data.GCContacto.celular);
        formData.append("telefono", data.GCContacto.telefono);
        formData.append("correo", data.GCContacto.correo);
        formData.append("foto", data.GCContacto.foto);
        formData.append("comentarios", data.GCContacto.comentarios);
        http
            .post(`/clinica/contacto/update/${contacto_id}/${clinica_id}`, formData)
            .then((response) => {
                showToast(
                    "success",
                    "Contacto Actualizado Correctamente",
                    `Se actualizó ${data.GCContacto.nombres} correctamente`
                );
                setVisible(false);
            })
            .catch((error) => {
                console.log(error);
                showToast(
                    "error",
                    "Contacto No Actualizado",
                    `No se actualizó ${data.GCContacto.nombres}`
                );
            });
    };
    const handleChangeTypeDocument=(e)=>{
        
        // if(e.target.value!=="2"){
        //     setSales({ ...sales, GCContacto: { ...sales.GCContacto, tipo_documento_id: e.target.value ,numero_documento:""} })
        // }else{
            
        // }
        setSales({ ...sales, GCContacto: { ...sales.GCContacto, tipo_documento_id: e.target.value.code } })
        
    }


    const headerTemplate = () => {
        return (
            <div className="flex justify-content-start gap-3 align-items-center">
                <span className="flex align-items-center justify-content-center bg-indigo-200 p-2 border-round-lg">
                    <img
                        className="h-1rem w-1rem"
                        src="https://i.ibb.co/NZLzLsJ/Icon.png"
                        alt="img"
                        draggable={false}
                    />
                </span>
                <p className="text-indigo-600 text-lg font-bold">Editar Contacto</p>
            </div>
        );
    };
    const footerTemplate = () => {
        return (
            <div className="flex justify-content-between gap-2">
                <Button
                    onClick={() => setVisible(false)}
                    type='button'
                    label="Cancelar"
                    className="font-normal bg-white border-gray-500 flex-1 text-sm p-2 text-gray-800 "
                />
                <Button
                    type="button"
                    className="bg-green-500 border-green-500 flex-1 text-sm p-2"
                    label="Editar"
                    onClick={(e)=>handleEnvio(e)}
                />
            </div>
        )
    }

    return (
        <>

            <Button
                icon="pi pi-pencil text-green-500"
                className="bg-green-100 border-green-100 crudButon"
                onClick={() => replaceContact()}
            />
            <Dialog
                header={headerTemplate}
                visible={visible}
                style={{ width: '665px', height: '754px' }}
                onHide={() => {setVisible(false);setSelectedImage(null)}}
                footer={footerTemplate}
            >
                <form onSubmit={(e) => sendEdit(e)} ref={formRef} method="post" id="formulario"
                    className="text-sm flex flex-column gap-3 w-full mt-3">
                    <div className="flex flex-wrap gap-3 ventas">
                        <div className="flex-1 flex flex-column gap-3">

                            {/*  */}
                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="numero_documento">Tipo documento</label>
                                <Dropdown value={optionsDocument[optionsDocument.findIndex(ele=>ele.code==sales?.GCContacto?.tipo_documento_id)]} onChange={(e) => {handleChangeTypeDocument(e)}} options={optionsDocument} optionLabel="name"
                                placeholder="Seleccionar tipo documento" className="w-full " name="tipo_documento_id" />
                            </div>
                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="numero_documento">Número de documento</label>
                                <InputText
                                    // value={sales.GCContacto.numero_documento}
                                    // onChange={(e) => onchangeInputs(e)}
                                    value={sales?.GCContacto?.numero_documento}
                                    onChange={(e) => setSales({ ...sales, GCContacto: { ...sales.GCContacto, numero_documento: e.target.value } })}
                                    name="numero_documento"
                                    id="numero_documento"
                                    placeholder="12345678"
                                    className="p-inputtext-sm"
                                />
                                {/*  */}

                            </div>
                            <div><Button label="Consultar" onClick={handleSubmit} type="button" disabled={sales?.GCContacto?.tipo_documento_id !== 1} /></div>
                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="nombres">Nombres</label>
                                <InputText
                                    value={sales?.GCContacto?.nombres}
                                    onChange={(e) => onchangeInputs(e)}
                                    name="nombres"
                                    id="nombres"
                                    placeholder="Carlos Nemecio"
                                    className="p-inputtext-sm"
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="apellido_paterno">Apellido Paterno</label>
                                <InputText
                                    value={sales?.GCContacto?.apellido_paterno}
                                    // onChange={(e) => onchangeInputs(e)}
                                    name="apellido_paterno"
                                    id="apellido_paterno"
                                    placeholder="Aquino"
                                    className="p-inputtext-sm"
                                />
                            </div>


                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="apellido_materno">Apellido Materno</label>
                                <InputText
                                    value={sales?.GCContacto?.apellido_materno}
                                    // onChange={(e) => onchangeInputs(e)}
                                    name="apellido_materno"
                                    id="apellido_materno"
                                    placeholder="Bellos"
                                    className="p-inputtext-sm"
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="cargo">Cargo</label>
                                <InputText
                                    value={sales.GCContacto.cargo}
                                    onChange={(e) => onchangeInputs(e)}
                                    name="cargo"
                                    id="cargo"
                                    placeholder="Soporte"
                                    className="p-inputtext-sm"
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="celular">Teléfono</label>
                                <InputNumber
                                    value={sales.GCContacto.celular}
                                    onValueChange={(e) => onchangeInputs(e)}
                                    useGrouping={false}
                                    name="celular"
                                    id="celular"
                                    placeholder="987654321"
                                    className="p-inputtext-sm"
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="correo">Correo</label>
                                <InputText
                                    value={sales.GCContacto.correo}
                                    onChange={(e) => onchangeInputs(e)}
                                    type="email"
                                    name="correo"
                                    id="correo"
                                    placeholder="example@example.com"
                                    className="p-inputtext-sm"
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="fecha_nacimiento">Cumpleaños</label>
                                <InputCalendar
                                    value={convertDate(sales.GCContacto.fecha_nacimiento)}
                                    onChange={(e) => onchangeFechaNacimiento(e)}
                                    name="fecha_nacimiento"
                                    inputId="fecha_nacimiento"
                                    showIcon
                                    showButtonBar
                                    placeholder="dd/mm/yy" dateFormat="dd/mm/yy"
                                    className="p-inputtext-sm ventas text-sm"
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <label className="font-bold" htmlFor="">
                                    Hobbies
                                </label>
                                <InputText
                                    value={sales.GCContacto.hobbies}
                                    onChange={(e) => onchangeInputs(e)}
                                    name="hobbies"
                                    placeholder="Leer, etc"
                                    className="p-inputtext-sm"
                                />

                            </div>
                        </div>

                        <div className="flex-1 flex flex-column gap-3">
                            <label className="font-bold" htmlFor="foto" >Foto</label>

                            <div className="flex justify-content-center">
                                <span className="flex align-items-center justify-content-center bg-indigo-200 p-2 border-circle">
                                    {true && (
                                        <img
                                            className="border-circle"
                                            width="202px"
                                            height="202px"
                                            style={{ objectFit: "contain" }}
                                            src={selectedImage || sales.GCContacto.foto} alt="user-male"
                                            draggable={false}
                                        />
                                    )}
                                </span>
                            </div>

                            <div className="flex align-items-center justify-content-center ">
                                <FileInputButton
                                    onChange={(e) => handleFileChange(e)}
                                    file={(e) => onchangeFile(e)}
                                    accept="image/*"
                                    className="bg-green-200 border-green-200 text-sm font-normal text-green-600"
                                    type="button"
                                    label="Cambiar imagen"
                                    icon="pi pi-camera text-green-600"
                                    iconPos="right"
                                    inputId="foto"
                                />
                                <Button
                                    type="button"
                                    style={{ marginLeft: "10px" }}
                                    icon="pi pi-trash text-red-500"
                                    className="bg-red-100 border-red-100 pl-2"
                                    onClick={() => handleDeleteImage()}
                                />
                            </div>

                            <label className="flex align-items-center gap-2 font-bold" style={{ marginTop: "11.15rem" }} htmlFor="comentarios">
                                <p>Comentarios</p>
                                <i className="pi pi-pencil text-sm" />
                            </label>
                            <InputTextarea
                                value={sales.GCContacto.comentarios || ""}
                                onChange={(e) => onchangeInputs(e)}
                                className="ventas-scroll card"
                                id="comentarios"
                                name="comentarios"
                                style={{ resize: 'none', height: "7.1rem" }}
                                placeholder="Ingresa un comentario"
                                maxLength={100}
                            />

                            {/* <footer className="flex justify-content-between gap-2">
                                    <Button
                                        onClick={() => setVisible(false)}
                                        type='button'
                                        label="Cancelar"
                                        className="font-normal bg-white border-gray-500 flex-1 text-sm p-2 text-gray-800 "
                                    />
                                    <Button
                                        onClick={() => {
                                            handleSubmitUpdate(sales);
                                            setVisible(false); // Cerrar el pop-up después de editar
                                        }}
                                        className="bg-green-500 border-green-500 flex-1 text-sm p-2"
                                        label="Editar"
                                    />
                                </footer>
                             */}
                        </div>
                    </div>
                </form>
            </Dialog >
        </>
    );
}