
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";

export default function S2ContactEdit({ data }) {
   //#region VARIABLES
   const [visible, setVisible] = useState(false);
   //#endregion
   const [selectedImage, setSelectedImage] = useState(data.persona.foto);

   const headerTemplate = () => {
      return (
         <div className="flex justify-content-start">
            <span className="flex align-items-center justify-content-center bg-indigo-200  border-round-lg">
               <img
                  className="h-1rem w-1rem"
                  src="https://i.ibb.co/NZLzLsJ/Icon.png"
                  alt="img"
                  draggable={false}
               />
            </span>
         </div>
      );
   };

   return (
      <>
         <Button
            icon="pi pi-eye text-cyan-500"
            className="bg-blue-100 border-blue-100 crudButon"
            onClick={() => setVisible(true)}
         />
         <Dialog
            header={headerTemplate}
            visible={visible}
            style={{ width: "665px", height: "754px" }}
            onHide={() => setVisible(false)}
         >
            <div
               className="text-sm flex flex-column gap-3 w-full mt-3"
            >
               <p className="text-indigo-600 text-lg font-bold">
                  Mostrar Contacto
               </p>
               <div className="flex flex-wrap gap-3 ventas">
                  <div className="flex-1 flex flex-column gap-3">
                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="nombres">
                           Nombres
                        </label>
                        <InputText
                           value={data.persona.nombres}
                           disabled
                           name="nombres"
                           id="nombres"
                           placeholder="Carlos Nemecio"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="apellido_paterno">
                           Apellido Paterno
                        </label>
                        <InputText
                           value={data.persona.apellido_paterno}
                           disabled
                           name="apellido_paterno"
                           id="apellido_paterno"
                           placeholder="Aquino"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="apellido_materno">
                           Apellido Materno
                        </label>
                        <InputText
                           value={data.persona.apellido_materno}
                           disabled
                           name="apellido_materno"
                           id="apellido_materno"
                           placeholder="Bellos"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="cargo">
                           Cargo
                        </label>
                        <InputText
                           value={data.persona.cargo || ""}
                           disabled
                           name="cargo"
                           id="cargo"
                           placeholder="Soporte"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="telefono">
                           Teléfono
                        </label>
                        <InputNumber
                           value={data.persona.telefono || null}
                           disabled
                           useGrouping={false}
                           name="telefono"
                           id="telefono"
                           placeholder="987654321"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="correo">
                           Correo
                        </label>
                        <InputText
                           value={data.persona.correo || ""}
                           disabled
                           type="email"
                           name="correo"
                           id="correo"
                           placeholder="example@example.com"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="fecha_nacimiento">
                           Cumpleaños
                        </label>
                        <InputText
                           value={data.persona.fecha_nacimiento || null}
                           disabled
                           name="fecha_nacimiento"
                           placeholder="Cumpleaños"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="">
                           Hobbies
                        </label>
                        <InputText
                           value={data.persona.hobbies || ""}
                           disabled
                           name="hobbies"
                           placeholder="Leer, etc"
                           className="p-inputtext-sm"
                        />
                     </div>
                  </div>

                  <div className="flex-1 flex flex-column gap-3">
                     <label className="font-bold" htmlFor="foto">
                        Foto
                     </label>

                     <div className="flex justify-content-center">
                        <span className="flex align-items-center justify-content-center bg-indigo-200 p-2 border-circle">
                           {selectedImage && (
                              <img
                                 className="border-circle"
                                 width="202px"
                                 height="202px"
                                 style={{ objectFit: "contain" }}
                                 src={selectedImage}
                                 alt="user-male"
                                 draggable={false}
                              />
                           )}
                        </span>
                     </div>

                     <div className="flex align-items-center justify-content-center ">

                     </div>

                     <label
                        className="flex align-items-center gap-2 font-bold"
                        style={{ marginTop: "12.94rem" }}
                        htmlFor="comentarios"
                     >
                        <p>Comentarios</p>
                        <i className="pi pi-pencil text-sm" />
                     </label>
                     <InputTextarea
                        value={data.comentarios || ""}
                        disabled
                        className="ventas-scroll"
                        id="comentarios"
                        name="comentarios"
                        style={{ resize: "none", height: "7.1rem" }}
                        placeholder="Excelente"
                     />
                  </div>
               </div>
            </div>
         </Dialog>
      </>
   );
}
