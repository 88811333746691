import React, {useEffect, useState} from 'react';

import { Chart } from 'primereact/chart';

let coloresRadar = []
let colorBar = {}

let onlyBarLabel = ''
let radarLabel = []

const CercoChart = React.memo((props) => {

  const [rgbColors, setRgbColors] = useState([]);

  const [barData, setBarData] = useState({
    labels: [],
    datasets: []
  });

  let barOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
      legend: {
        labels: {
          color: '#495057',
          font: {
            size: 13
          },
          padding: 40
        },
        position: 'bottom',
        background: '#000',
        display: false
      },
      tooltips: {
        mode: 'index',
        intersect: true
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
          color: '#ebedef'
        },
        ticks: {
          min: 0,
          max: 100,
          color: '#495057'
        }
      }
    }
  };

  const [pietData] = useState({
    labels: ['A', 'B', 'C'],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: [
          "#42A5F5",
          "#66BB6A",
          "#FFA726"
        ],
        hoverBackgroundColor: [
          "#64B5F6",
          "#81C784",
          "#FFB74D"
        ]
      }
    ]
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: '#495057',
          font: {
            size: 14
          },
          padding: 40
        },
        position: 'top',
        display: false
      }
    },
    scales: {
      r : {
        pointLabels: {
          font: {
            size: 14
          }
        },
        suggestedMin: 0
      }
    }
  });

  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {

    if (props.tipoAnalisis === 'Análisis Cualitativo') {
      setRadarData({
        labels: [],
        datasets: []
      })

      setRgbColors([])

      if(props.cercoPostRadar !== null) {

        setRadarData({
          labels: [],
          datasets: []
        })

        setRgbColors([])

        coloresRadar = []

        props.cercoPostRadar.forEach(() => {
          const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
          const r = randomBetween(0, 255);
          const g = randomBetween(0, 255);
          const b = randomBetween(0, 255);
          const rgb1 = `rgba(${r},${g},${b}, 0.3)`;
          const rgb2 = `rgba(${r},${g},${b}, 1)`;

          coloresRadar.push({
            rgb1: rgb1,
            rgb2: rgb2
          })

        });

        radarLabel = []

        if (props?.enfermedad?.length === 0) {
          if (props?.codigo3?.length === 0) {
            radarLabel = ['Sin Datos']
          } else {
            props?.codigo3?.map(data => {
              radarLabel.push(`[${data?.codigo}] ${data?.enfermedad_general}`)
            })
          }
        } else {
          props?.enfermedad?.map(data => {
            radarLabel.push(`${data?.enfermedad_general}`)
          })
        }


        setRadarData({
          labels: radarLabel,
          datasets:
            props.cercoPostRadar.map((data,index) => ({
              label: data?.area?.toString(),
              data: data?.data,
              font: {
                size: 16
              },
              pointRadius: 6,
              backgroundColor: coloresRadar[index].rgb1,
              borderColor: coloresRadar[index].rgb2,
              pointBackgroundColor: coloresRadar[index].rgb2,
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: coloresRadar[index].rgb2,
              fill: true
            }))
        })

      }
    }

  }, [props.cercoPostRadar])

  useEffect(() => {

    if (props.tipoAnalisis === 'Análisis Cuantitativo') {
      if(props.cercoPostBar !== null) {

        setBarData({
          labels: [],
          datasets: []
        })

        setRgbColors([])


        const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const rgb1 = `rgba(${r},${g},${b}, 0.3)`;
        const rgb2 = `rgba(${r},${g},${b}, 1)`;

        colorBar = {
          rgb1: rgb1,
          rgb2: rgb2
        }

        if (props?.onlyEnfermedad === null) {
          if (props?.onlyCodigo3 === null) {
            onlyBarLabel = 'Sin Datos'
          } else {
            onlyBarLabel = `[${props?.onlyCodigo3?.codigo}] ${props?.onlyCodigo3?.enfermedad_general}`
          }
        } else {
          onlyBarLabel = `${props?.onlyEnfermedad?.enfermedad_general}`
        }
        

        setBarData({
          labels: props?.cercoPostBar?.area,
          datasets: [{
            label: onlyBarLabel,
            data: props?.cercoPostBar?.data,
            font: {
              size: 16
            },
            backgroundColor: rgb1,
            borderColor: rgb2,
            borderWidth: 2,
            borderSkipped: 'start'
          }]

        })

        console.log(barData);

      }

    }

  }, [props.cercoPostBar])

  return (
    <>

      {
        //Bar
        props.tipoAnalisis === 'Análisis Cuantitativo'
          &&
          <div className="w-full">
            <Chart type="bar" data={barData} options={barOptions} />
            <div className="custom_legend">
              {
                Object.keys(props.cercoPostBar).length !== 0
                &&
                <button
                  style={{
                    background: colorBar?.rgb1,
                    borderColor: colorBar.rgb2,
                  }}
                >
                  <label>{onlyBarLabel}</label>
                </button>
              }
            </div>
          </div>

      }

      {
        //Radar
        props.tipoAnalisis === 'Análisis Cualitativo'
          &&
          <div className="w-full h-5rem" >
            <Chart id="radarChartID" type="radar" data={radarData} options={lightOptions}/>
            <div className="custom_legend">
              {
                props?.cercoPostRadar?.map((data,index) => (
                  <button
                    className=""
                    id={`legenda${index}`}
                    style={{
                      background: coloresRadar[index]?.rgb1,
                      borderColor: coloresRadar[index]?.rgb2,
                    }}
                  >
                    <label>{data.area}</label>
                  </button>
                ))
              }
            </div>
          </div>


      }

    </>
  );
});

export default CercoChart;