import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import SB1Customer from "../Components/Sales/Services/Clinica/SC1Customer/SC1Customer";
import Search from "../Components/Ventas/Empresas/Search";
import Empresas from "../Components/Ventas/Empresas/Empresas";
import SkeletonEmpresa from "../Components/Ventas/Empresas/SkeletonEmpresa";
import { Dialog } from "primereact/dialog";

const PagePublic = () => {
   const { getCompany } = SB1Customer();
   const toast = useRef(null);
   const [empresas, setEmpresas] = useState(null);
   const [empresasCopy, setEmpresasCopy] = useState(null);
   const [visible, setVisible] = useState(false);
   const [data, setData] = useState(null);
   const btnEdit = (e) => {
      console.log("empresa",e)
      return (
         <Link to={`empresa/${e.empresa_id}/editar`}>
            <Button
               icon="pi pi-pencil text-green-500"
               className="bg-green-100 border-green-100"
            />
         </Link>
      );
   };
   const btnDelete = (e) => (
      <Button
         onClick={() => {
            setData(e);
            setVisible(true)
         }}
         icon="pi pi-trash text-red-500"
         className="bg-red-100 border-red-100"
      />
   );
   const filtrarDatos = (searchQuery) => {
      const filteredData = empresasCopy.filter((empresa) =>
         empresa.empresa.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setEmpresas(filteredData);

   }

   useEffect(() => {

      const fetchDataCopy = async () => {
         const promises = [
            getCompany()
         ];

         try {
            const results = await Promise.allSettled(promises);
            const valueClinica = results[0].status === "fulfilled" ? results[0].value : [];
            console.log("ValueClinica:", valueClinica);
            setEmpresas(valueClinica);
            setEmpresasCopy(valueClinica)
         } catch (error) {
            console.error('Error en la llamada a la API:', error);
         }
      }

      fetchDataCopy();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleDeleteConfirmation = (id_eliminar) => (
      <React.Fragment>
         <div className="flex w-full bot">
            <Button
               type="button"
               label="Cancelar"
               className="delete w-full"
               onClick={() => {
                  setVisible(false);
               }}
            />
            <Button
               type=""
               label="Eliminar"
               className="p-button-success w-full"
               style={{
                  color: "#5555D8",
                  backgroundColor: "#EBEBFF",
                  borderColor: "#5555D8",
               }} onClick={() => {
                  setVisible(false);
               }}
            /></div>
      </React.Fragment>
   );


   return (
      <div className="flex flex-column gap-5">
         <Toast style={{ zIndex: "100" }} ref={toast} />
         <div className="text-sm flex flex-column gap-2">
            <div className="flex flex-column gap-4 pl-6 pr-6">

               <span className="flex justify-content-center p-input-icon-center flex-1 border-none mt-4 mb-2">
                  <i className="pi pi-search relative text-indigo-600" style={{ top: "12px", left: "25px" }} />
                  <Search extraFunction={filtrarDatos} />
               </span>
               {
                  empresas
                     ? <Empresas data={empresas} pageSize={6} btnEdit={btnEdit} btnDelete={btnDelete} />
                     : <SkeletonEmpresa pageSize={6} />
               }
               <Dialog
                  visible={visible}
                  sizable={false}
                  style={{ width: "550px", height: "260px" }}
                  className="p-fluid eliminarLocal"
                  header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar</i></p>}

                  footer={handleDeleteConfirmation()}

                  onHide={() => {
                     setVisible(false);
                  }}

               >
                  {data && (
                     <div className="flex flex-column gap-3">
                        <div className="flex flex-column w-full">
                           <p style={{ fontSize: "20px" }} className=" m-0 pt-5">¿Desea eliminar a <b>{data.empresa}</b>?</p>
                        </div>
                     </div>
                  )
                  }
               </Dialog>
            </div>
         </div>
      </div>
   );
}

export default PagePublic;