import React, { useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TreeTable } from 'primereact/treetable';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import '../../Style/Operaciones/servicios.css'
import AuthUser from '../../AuthUser';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
const TableOperacionesClinicaComponent = ({ getServicios, transformedNodes, data, selectedNodeKeys, seleccionarServicios }) => {

   const toast = useRef(null);
   const { http } = AuthUser()
   const [visibleMes, setVisibleMes] = useState(false);
   const [visibleAno, setVisibleAno] = useState(false)
   const [rowData, setRowData] = useState({})
   const precio = useRef('')
   const precioAnual = useRef('')
   console.log("esto es lo extra: ", transformedNodes)
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const footerContent = (
      <div>
         <Button label="Cancelar" icon="pi pi-times" onClick={() => setVisibleMes(false)} className="p-button-text" />
         <Button label="Aceptar" icon="pi pi-check" onClick={() => handleCreate()} autoFocus />
      </div>
   );

   const footerContentAno = (
      <div>
         <Button label="Cancelar" icon="pi pi-times" onClick={() => setVisibleAno(false)} className="p-button-text" />
         <Button label="Aceptar" icon="pi pi-check" onClick={() => handleCreateAno()} autoFocus />
      </div>
   );

   const handleCreate = () => {
      let data = {
         tipo: rowData.key.substring(0, 1),
         precio_referencial_mensual_venta: precio.current.value,
      };
      let id = rowData.key.substring(rowData.key.includes('.') ? (rowData.key.lastIndexOf(".") + 1) : (rowData.key.lastIndexOf("-") + 1), rowData.key.length);
      http.put(`clinica/operaciones/servicio/preciomensual/update/${id}`, data)
         .then((response) => {
            showToast(
               "success",
               "Precio Actualizado",
               `Se actualizó el precio mensual a S/.${precio.current.value} correctamente`
            );
            getServicios();
            setVisibleMes(false);
         }).catch((error) => {
            showToast(
               "error",
               "Error al Actualizar",
               `No se pudo actualizar el precio mensual`
            );
         });
   }

   const handleCreateAno = () => {
      let data = {
         tipo: rowData.key.substring(0, 1),
         precio_referencial_anual: precioAnual.current.value,
      };
      let id = rowData.key.substring(rowData.key.includes('.') ? (rowData.key.lastIndexOf(".") + 1) : (rowData.key.lastIndexOf("-") + 1), rowData.key.length);
      http.put(`clinica/operaciones/servicio/precio/udpate/${id}`, data)
         .then((response) => {
            showToast(
               "success",
               "Precio Actualizado",
               `Se actualizó el precio anual a S/.${precioAnual.current.value} correctamente`
            );
            getServicios();
            setVisibleAno(false);
         }).catch((error) => {
            showToast(
               "error",
               "Error al Actualizar",
               `No se pudo actualizar el precio anual`
            );
         });
   }


   const buttonActionMensual = (rowData) => {
      setVisibleMes(true)
      setRowData(rowData);
   }
   const precio_input_muestra = (rowData) => {
      setVisibleMes(true)
      setRowData(rowData)
   }
   const buttonActionAnual = (rowData) => {
      setVisibleAno(true)
      setRowData(rowData);
   }

   const buttonEditMensual = (rowData) => {
      if (rowData.children && rowData.children.length > 0) return null;
      return (
         <div >
            <Tag style={{ cursor: 'pointer' }} onClick={() => buttonActionMensual(rowData)}>
               <div>S/.{rowData.data.precio_mensual} </div>
               {console.log("esta es la data: ", rowData.data)}
            </Tag>
         </div>
      )
   }
   const precio_input = (rowData) => {
      if (rowData.children && rowData.children.length > 0) return null;
      return (
         <div >
            <Tag style={{ cursor: 'pointer' }} onClick={() => precio_input_muestra(rowData)}>
               <div>S/.{rowData.data.precio_mensual} </div>
               {console.log("esta es la infooo: ", rowData.data)}
            </Tag>
         </div>
      )
   }
   const buttonEditAnual = (rowData) => {
      if (rowData.children && rowData.children.length > 0) return null;
      return (
         <div >
            <Tag key={rowData?.key} style={{ cursor: 'pointer' }} onClick={() => buttonActionAnual(rowData)}>
               <div>S/.{rowData.data.precio_anual} </div>
            </Tag>
         </div>
      )
   }
   const [globalFilter, setGlobalFilter] = useState('');
   const [filterMode, setFilterMode] = useState('lenient');
   const [filterOptions] = useState([
      { label: 'Lenient', value: 'lenient' },
      { label: 'Strict', value: 'strict' }
   ]);
   const getHeader = () => {
      return (
         <div className="flex justify-content-end">
            <div className="p-input-icon-left">
               <i className="pi pi-search" style={{ }}></i>
               <InputText style={{ textAlign: 'left',marginLeft: "50px" }}
                  type="search"
                  onInput={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Buscar área médica" />
            </div>
         </div>
      );
   };

   let header = getHeader();

   const headerPrecioReferencialMensual = (rowData)=>{
      console.log("validandoooo:", rowData);
      if(rowData.key=== 'areas_medicas'){
         return <div> ÁREAS MÉDICAS </div>
      }
      else{
         return <div>{rowData?.data?.nombre}</div>
      }
      
   }

   return (
      <><Toast ref={toast} />
         <div style={{ width: '100%', overflowY: 'auto' }}>
            <TreeTable
               globalFilter={globalFilter}
               header={header}
               filterMode={filterMode}
               tableStyle={{ width: '100%' }}
               value={transformedNodes}
               className="TablaDeOperacionesServicios ventas contactList"
               emptyMessage={<p className="text-center text-gray-500">
                  Seleccione un paquete para ver su detalle.
               </p>}
               headerStyle={{

               }}
               style={{
                  width: '100%', // Asegura que la tabla ocupe todo el ancho del contenedor
               }}
            >
               <Column
                  header="Precio Referencial Mensual"
                  field="nombre"
                  expander
                  headerStyle={{
                     width: '131px',
                     height: '20px',
                     top: '270px',
                     left: '929px',
                     color: '#248D63',
                     fontFamily: 'Montserrat',
                     fontWeight: '600',
                     fontSize: '14px',
                     lineHeight: '19.6px',

                  }}
                  style={{
                     width: '40px',
                     height: '20px',
                     top: '334px',
                     left: '936px',
                     color: '#393838',
                     fontFamily: 'Montserrat',
                     fontWeight: '600',
                     padding: '40px',
                     fontSize: '14px',
                     lineHeight: '19.6px',
                     height: '100%',
                  }} />

               <Column
                  header=""
                  body={(e) => buttonEditMensual(e)}
                  className="text-center text-green-500"
                  headerStyle={{
                     padding: '5px',
                     whiteSpace: 'normal',
                     fontSize: '15px'
                  }}
               ></Column>

            </TreeTable>

            <Dialog
               key={1}
               header={<>
                  <p
                     style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#5555D8"
                     }}
                  >Nuevo precio para&nbsp;{rowData?.data?.nombre} </p>
               </>}
               visible={visibleMes}
               style={{ width: '25vw', minWidth: "238px" }}
               onHide={() => setVisibleMes(false)}
               footer={footerContent}
            >


               <div className='flex dialogo-center mt-3 flex-row gap-3 align-items-center'>
                  S/.<InputText defaultValue={rowData?.data?.precio_mensual} className='flex w-full' key={rowData?.key} ref={precio} autoFocus=''></InputText>
               </div>
            </Dialog>
            <Dialog
               key={2}
               header={<>
                  <p
                     style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#5555D8"
                     }}
                  >Nuevo precio anual</p>
               </>}
               visible={visibleAno}
               style={{ width: '25vw', minWidth: "238px" }}
               onHide={() => setVisibleAno(false)}
               footer={footerContentAno}
            >
               <div className='flex dialogo-center mt-3 flex-row gap-3 align-items-center'>
                  S/.<InputText className='flex w-full' key={rowData?.key} ref={precioAnual} defaultValue={rowData?.data?.precio_anual}></InputText>
               </div>
            </Dialog>
            {/* <TableInPopUp data={data} /> */}
         </div></>
   );
}

export default TableOperacionesClinicaComponent;