
import React, { useState, useContext, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from "primereact/inputtext";
import "../../../Style/ANTECEDENTE.css"
import DialogAgregarAntLab from './Dialog/DialogAgregarAntLab';
import { TriajeContext } from '../../Triaje/TriajeContext';
import DialogEditarAntLab from "./Dialog/DialogEditarAntLab";
import DialogEliminarGeneral from './Dialog/DialogEliminarGeneral';
import { RAContext } from '../../RutaAtencion/RAContext';
import AuthUser from "../../../AuthUser";
import { useParams } from "react-router-dom";
export default function ANTECEDENTEOCUPACIONAL({ showToast, setAntocup, Antocup }) {
    const { http } = AuthUser();
    const { idPaciente } = useParams();


    const { antLabContext, setAntLabContext } = useContext(TriajeContext);
    const { AntOcupContext, setAntOcupContext } = useContext(TriajeContext);
    let { antLabRiesgoContext, setAntLabRiesgoContext } = useContext(TriajeContext);
    let { stateAtencion, pageViewContext } = useContext(RAContext);
    /**EDITAR Y ELIMINAR */
    const [VisibleEditarAntLab, setVisibleEditarAntLab] = useState(false);
    const [VisibleEliminarAntLab, setVisibleEliminarAntLab] = useState(false);
    const [DatosEditar, setDatosEditar] = useState(null);
    const [DatosEditarRiesgo] = useState(null);
    const [dataId, setdataId] = useState(null);

    /* ANTECEDENTES OCUPACIONALES GENERAL */
    const [EmpresaActual, setEmpresaActual] = useState(null);
    const [PuestoOcupacional, setPuestoOcupacional] = useState(null);
    const [datosAntLab, setDatosAntLab] = useState([]);


    
    useEffect(() => {
        setDatosAntLab(antLabContext.map((item, index) => ({ ...item, itemId: index + 1 })));
    }, [antLabContext]);
    

    const ObtenerEmpresaA = () => {
        http
         .get(`/clinica/paciente/get/${idPaciente}`)
         .then((response) => {
            const { nombre_comercial, puesto_ocupacional } = response.data.paciente;
            // Establecer los datos en el estado correspondiente
            setEmpresaActual(nombre_comercial);
            setPuestoOcupacional(puesto_ocupacional);

         }).catch((error) => {
            console.log("error al traer antecedenteocupacional", error)
         });
    }
    useEffect(() => {
        ObtenerEmpresaA();
    })

    // useEffect(() => {
    //     //SI NO SE SETEA ANTOCUP
    //     if (Antocup !== null) {
    //         setEmpresaActual(Antocup?.empresa_actual)
    //         setPuestoOcupacional(Antocup?.puesto_ocupacional)
    //         setAntocup(null);

    //     }
    //     console.log("fdgfgdf")
    //     console.log("Antocup", Antocup)
    // }, [Antocup])
    // const handleEmpresaActual = (e) => {
    //     c(e);
    //     setAntOcupContext({ ...AntOcupContext, empresa_actual: e })
    //     console.log("entróoo011", EmpresaActual)
    // }

    // const handlePuestoOcupacional = (e) => {
    //     c(e);
    //     setAntOcupContext({ ...AntOcupContext, puesto_ocupacional: e })
    //     console.log("entróoo01122", PuestoOcupacional)
    // }

    useEffect(() => {
        setAntOcupContext({
            empresa_actual: EmpresaActual,
            puesto_ocupacional: PuestoOcupacional,
            antecedente_laboral: antLabContext
        })
    }, [EmpresaActual, PuestoOcupacional, antLabContext])

    const EditarAntLab = (rowData, item) => {
        setVisibleEditarAntLab(true)
        setDatosEditar(rowData)
        setdataId(item);
        setAntLabRiesgoContext(rowData?.antecedentes_riesgo)
        console.log("rowData", rowData)
    };

    const EliminarAntLab = (itemIndex) => {
        // Ocultar el diálogo de eliminación
        setVisibleEliminarAntLab(false);
        
        // Eliminar el elemento de antLabContext
        const updatedAntLabContext = [...antLabContext];
        updatedAntLabContext.splice(itemIndex, 1);
    
        // Actualizar el contexto de antecedentes ocupacionales
        setAntOcupContext({ ...AntOcupContext, antecedente_laboral: updatedAntLabContext });
    
        // Mostrar un mensaje de éxito
        showToast("success", "Eliminado correctamente", "Se eliminó el antecedente laboral");
    
        // Actualizar el estado de antLabContext para que refleje los cambios
        setAntLabContext(updatedAntLabContext);
        
        // Reiniciar el estado de dataId
        setdataId(null);
    };
    
    
    
    

    const mostrarDatosActualizados = (datosActualizados) => {
        const PocisionEditarAntLab = antLabContext[dataId];
        if (PocisionEditarAntLab) {
            PocisionEditarAntLab.trabajo_actual = datosActualizados?.TrabajoActual?.name ?? null;
            PocisionEditarAntLab.empresa = datosActualizados?.Empresa ?? null;
            PocisionEditarAntLab.actividad_empresarial = datosActualizados?.ActividaEmp ?? null;
            PocisionEditarAntLab.ocupacion = datosActualizados?.Ocupacion ?? null;
            PocisionEditarAntLab.puesto_trabajo = datosActualizados?.PuestoTrab ?? null;
            PocisionEditarAntLab.fecha_ingreso = datosActualizados?.FechaFormateadaIngresoEditar ?? null;
            PocisionEditarAntLab.fecha_salida = datosActualizados?.FechaFormateadaSalidaEditar ?? null;
            PocisionEditarAntLab.tiempo = datosActualizados?.Tiempo ?? null;
            PocisionEditarAntLab.antecedentes_riesgo = antLabRiesgoContext ?? [];

            if (datosActualizados.AreaTrabajo?.name !== "Otros") {
                PocisionEditarAntLab.area_trabajo = datosActualizados.AreaTrabajo?.name ?? null;
            } else {
                PocisionEditarAntLab.area_trabajo = datosActualizados.AreaTrabajoInput ?? null;
            }

            if (datosActualizados.AlturaLabor?.name !== "Otros") {
                PocisionEditarAntLab.altura_labor = datosActualizados.AlturaLabor?.name ?? null;
            } else {
                PocisionEditarAntLab.altura_labor = datosActualizados.AlturaTrabajoInput ?? null;
            }

            showToast(
                "success",
                "Editado correctamente",
                "Se editó el antecedente laboral"
            )
        }
        setAntLabContext([...antLabContext])
        setAntOcupContext({ ...AntOcupContext, antecedente_laboral: [...antLabContext] })
        console.log("setAntOcupContext", AntOcupContext)
        setVisibleEditarAntLab(false);

    }


    const actionTemplateAntLabRiesgo = (rowData, indexData) => {
        return (
            <React.Fragment>
                <Button
                    icon={stateAtencion[pageViewContext].estado === 3 ? "pi pi-eye" : "pi pi-pencil"}
                    className="editar1"
                    onClick={() => EditarAntLab(rowData, indexData.rowIndex)}
                />
                <Button
                    icon="pi pi-trash"
                    className="eliminar1"
                    onClick={() => EliminarAntLab(indexData.rowIndex)}
                    disabled={stateAtencion[pageViewContext].estado === 3}
                />
            </React.Fragment>
        );
    };


    let id = 0
    let letDatosAntLab = antLabContext?.map((item) => {
        id = id + 1;
        return { 
            ...item, 
            itemId: id,
            empresa: EmpresaActual, // Asigna el valor de la empresa actual
            ocupacion: PuestoOcupacional // Asigna el valor del puesto ocupacional actual
        }
});
    return (
        <div>
            <div>
                <Fieldset legend="Empresa y Puesto Actual" toggleable>
                    <div className="flex flex-row w-full gap-3">
                        <div className="TITULOSPUESTOEMPRESA flex flex-column w-full gap-2">
                            <span>Empresa Actual</span>
                            <InputText value={EmpresaActual} onChange={(e) => setEmpresaActual(e.target.value)}
                                //disabled={stateAtencion[pageViewContext].estado === 3} 
                                disabled />
                        </div>
                        <div className="TITULOSPUESTOEMPRESA flex flex-column w-full gap-2">
                            <span>Puesto Ocupacional</span>
                            <InputText value={PuestoOcupacional} onChange={(e) => setPuestoOcupacional(e.target.value)}
                                //disabled={stateAtencion[pageViewContext].estado === 3}
                                disabled />
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div>
                <DialogAgregarAntLab showToast={showToast} />
            </div>
            <div className='CONTENEDOR_TABLAANTECEDENTE'>
                <DataTable
                    value={datosAntLab}
                    selectionMode="single"
                    dataKey="id"
                    rowHoverSS
                    className='DATATABLE_ANTECEDENTE'
                >
                    <Column field="itemId" header="item" sortable />
                    <Column field="empresa" header="Empresa" sortable />
                    <Column field="ocupacion" header="Ocupación" sortable />
                    <Column field="tiempo" header="Tiempo" sortable />
                    <Column field="trabajo_actual" header="Trab. actual" sortable />
                    <Column field="actividad_empresarial" header="Actividad Emp." sortable />
                    <Column field="area_trabajo" header="Área trabajo" sortable />
                    <Column field="puesto_trabajo" header="Puesto" sortable />
                    <Column field="altura_labor" header="Altura" sortable />
                    <Column header="Opciones" body={actionTemplateAntLabRiesgo} />
                </DataTable>
            </div>

            <DialogEditarAntLab
                DatosEditar={DatosEditar}
                DatosEditarRiesgo={DatosEditarRiesgo}
                VisibleEditarAntLab={VisibleEditarAntLab}
                setVisibleEditarAntLab={setVisibleEditarAntLab}
                setPuestoOcupacional={setPuestoOcupacional}
                mostrarDatosActualizados={mostrarDatosActualizados}
                setDatosEditar={setDatosEditar}

            />

            <DialogEliminarGeneral
                setVisibleEliminar={setVisibleEliminarAntLab}
                VisibleEliminar={VisibleEliminarAntLab}
                ContextEliminar={antLabContext}
                DataId={dataId}
                showToast={showToast}
                texto="antecedente laboral"
            />
        </div>
    )
}
