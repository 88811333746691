import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';

const FinalizarRevisionComponent = ({ visible, setVisible, terminarAtencion, estadoPaciente }) => {
   console.log("estadoooPaciente", estadoPaciente)
   const HideDialog = () => {
      setVisible(false);
   };


   let mensajeDialogo = "";
   let titulo = "";

   switch (estadoPaciente?.hoja_ruta?.estado_revision) {
      case "0":
         mensajeDialogo = "¿Está seguro que desea finalizar la atención?";
         titulo = "Finalizar revisión"
         break;
      case "1":
         mensajeDialogo = "La atención ya fue revisada";
         titulo = "Atención revisada"
         break;
      default:
         mensajeDialogo = "Algo salió mal";
         titulo = "Error"
         break;
   }

   const deleteDialogFooter = (id) => {

      if (estadoPaciente?.hoja_ruta?.estado_revision === "1") {
         return (
            <div className="flex w-full bot justify-content-end">
               <Button
                  type="button"
                  label="Aceptar"
                  className="delete w-5"
                  onClick={() => {
                     HideDialog();
                  }}
               /></div>
         )
      }

      return (
         <div className="flex w-full h-3rem">
            <Button
               type="button"
               label="Cancelar"
               className="delete w-full"
               onClick={() => {
                  HideDialog();
               }}
            />
            <Button
               type=""
               label="Finalizar"
               className="p-button-success w-full"
               style={{
                  backgroundColor: "#5555d8",
                  borderColor: "#5555d8",
               }}
               onClick={() => {
                  terminarAtencion();
                  HideDialog();
               }}
            />
         </div>
      );
   };


   return (
      <div>
         <Dialog
            visible={visible}
            style={{ margin: "20px", width: "350px" }}
            header={
               <>
                  <h1 style={{
                     fontFamily: 'Montserrat',
                     fontStyle: "normal",
                     fontWeight: "700",
                     fontSize: "18",
                     color: "#5555d8"
                  }}>{titulo}</h1>
               </>
            }
            footer={deleteDialogFooter()}
            onHide={() => {
               HideDialog();
            }}
         >
            <div className="flex flex-column gap-3 pt-4">
               <div className="flex flex-column w-full">
                  <p className="m-0 justify-content-center align-items"
                  >{mensajeDialogo}</p>
               </div>
            </div>
         </Dialog>
      </div>
   );
}

export default FinalizarRevisionComponent;
