import React, { useState, useEffect } from "react";
import '../../../Style/Permisos.css'
import check from '../../../assets/check-ultimate.svg'
import { Skeleton } from 'primereact/skeleton';

export default function PermisosComponent(props) {
  // Skeleton
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [])
  const loader = () => {
    return (
      <div>
        <Skeleton width="315px" height="472.89px" borderRadius="16px"></Skeleton>
      </div>
    );
  }

  if (loading) {
    return (loader())
  }
  else {
    return (
      <div className="CONTENEDOR_BIENVENIDA_AHP flex flex-column align-items-center justify-content-between">
        <div className="CONTENEDOR_BIENEVENIDA_AHP_01 relative w-full h-auto flex flex-column align-items-center justify-content-center">
          <img
              src="https://cdn.discordapp.com/attachments/864577245905551391/1090036668968472666/Bienvenido_.png"
              alt="" style={{ width: 'auto', height: '180px', marginRight:'10px'}}
            />
          <div className="LINEA_DIVISORIA_AHP absolute"></div>
          <h3 className="SUBTITULO_NEGRITA">Bienvenido</h3>
          <p className="RAZONSOCIAL_SUBTITULO">{props.razonSocial}</p>
        </div>

        <div className="CONTENEDOR_BIENEVENIDA_AHP_02 w-full h-auto flex flex-column align-items-center justify-content-center p-4">
          <p className="text-center">Las funciones que podrás realizar son las siguientes</p>

          <p><img src={check} alt="check" style={{ height: "26px", width: "26px" }} />Editar los datos de tu perfil</p>
          <p><img src={check} alt="check" style={{ height: '26px', width: '26px' }} />Editar la información de las cuentas bancarias</p>
          <p><img src={check} alt="check" style={{ height: '26px', width: '26px' }} />Agregar nuevas entidades bancarias</p>
        </div>
      </div>
    )
  }
}