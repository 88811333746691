import React, { useEffect, useState } from 'react';
import Http from "../../AuthUser";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from 'primereact/checkbox';

const CercoDiag = (props) => {

  const { http } = Http();

  const [radioButton, setRadioButton] = useState(true);
  const [enfermedades, setEnfermedades] = useState([]);
  const [codigoTipo3, setCodigoTipo3] = useState([]);
  const [codigoTipo4, setCodigoTipo4] = useState([]);

  const getAllEnfermedades = () => {
    http
      .get("/enfermedades/get")
      .then((response) => {
        setEnfermedades(response.data.data);

        let enf_cod = response?.data?.data?.map(data => data?.enfermedad_general).flat();

        setCodigoTipo3(enf_cod?.filter(data => data?.codigo?.toString().length === 3));
        setCodigoTipo4(enf_cod?.filter(data => data?.codigo?.toString().length === 4))
        // let code5 = enf_cod?.filter(data => data.codigo.toString().length > 4);

      })
      .catch((error) => {
        console.log(error);
      });

  };

  const selectedEnfermedad = (option) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.enfermedad}</div>
        </div>
      );
    }
    return "Seleccione una Enfermedad";
  };

  const panelFooter = (state) => {
    const selected = state;
    const length = selected ? selected.length : 0;
    return (
      <div className="py-2 px-3" style={{ padding: '1rem 1.5rem' }}>
        <b>{length}</b> de <b>10</b> item{length > 1 ? 's' : ''} seleccionado{length > 1 ? 's' : ''}.
      </div>
    );
  };

  useEffect(() => {
    getAllEnfermedades();
  }, [])

  useEffect(() => {

    if (props.tipoAnalisis?.name === 'Análisis Cualitativo') {
      props.setEnfermedad([])
      props.setCodigo3([])
      props.setCodigo4([])
    }

    if (props.tipoAnalisis?.name === 'Análisis Cuantitativo') {
      props.setOnlyEnfermedad(null)
      props.setOnlyCodigo3(null)
      props.setOnlyCodigo4(null)
    }

  }, [radioButton])


  return (
    <>
      <div className="enf">
        <div className="flex justify-content-between mt-2 align-items-center">
          <label className='text-xs'>Enfermedad</label>
          <Checkbox
            name="enfermedad"
            value={true}
            onChange={(e) => setRadioButton(e.value)}
            checked={radioButton === true}
          />
        </div>

        <div className="flex flex-column gap-2 mt-2">
          {/*BAR*/}
          {
            props.tipoAnalisis.name === 'Análisis Cuantitativo'
            &&
            <Dropdown
              value={props.onlyEnfermedad}
              placeholder="Seleccione una Enfermedad"
              options={enfermedades}
              optionLabel="enfermedad_general"
            //   optionGroupLabel="enfermedad"
            //   optionGroupChildren="enfermedad_general"
            //   virtualScrollerOptions={{ itemSize: 500 }}
              className="flex p-inputtext-sm"
              onChange={e => {
                props.setOnlyEnfermedad(e.value);
              }}
              disabled={!radioButton}
              filter
            />
          }
          {/*RADAR*/}
          {
            props.tipoAnalisis.name === 'Análisis Cualitativo'
            &&
            <>
              <Dropdown
                value={props.onlyEnfermedad}
                placeholder="Seleccione una Enfermedad"
                options={enfermedades}
                optionLabel="enfermedad_general"
               //  optionGroupLabel="enfermedad"
               //  optionGroupChildren="enfermedad_general"
                virtualScrollerOptions={{ itemSize: "100px" }}
               //  className="flex p-inputtext-sm"
                onChange={e => {
                  props.setOnlyEnfermedad(e.value);
                }}
                disabled={!radioButton}
                filter
              />
              <div className='flex flex-wrap  align-items-center gap-2'>
                <label className='flex text-xs text-indigo-600 align-items-center white-space-nowrap bg-indigo-100 border-round-xl w-min pl-2 pr-2 pt-1 pb-1'>
                  Infarto agudo de miocardio
                  <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '1px' }}></i>
                </label>
                <div className='flex gap-3'>
                <label className='flex text-xs text-indigo-600 align-items-center white-space-nowrap bg-indigo-100 border-round-xl w-min pl-4 pr-4 pt-1 pb-1'>
                  Nocariosis
                  <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '1px' }}></i>
                </label>
                <label className='flex text-xs text-indigo-600 align-items-center white-space-nowrap bg-indigo-100 border-round-xl w-min pl-4 pr-4 pt-1 pb-1'>
                  Obesidad
                  <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '1px' }}></i>
                </label>
                </div>
                <label className='flex text-xs text-indigo-600 align-items-center white-space-nowrap bg-indigo-100 border-round-xl w-min pl-2 pr-2 pt-1 pb-1'>
                  Enfermedad cardiorrenal Hipertensiva
                  <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '1px' }}></i>
                </label>
                <label className='flex text-xs text-indigo-600 align-items-center white-space-nowrap bg-indigo-100 border-round-xl w-min pl-2 pr-2 pt-1 pb-1'>
                  Infarto agudo de miocardio
                  <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '1px' }}></i>
                </label>
              </div>
              
              </>}

          {/*EMPTY*/}
          {
            props.tipoAnalisis === ''
            &&
            <Dropdown
              placeholder="Seleccione una Enfermedad"
              className="flex p-inputtext-sm"
              disabled
            />
          }

        </div>

      </div>
      <div className="codigo">
        <div className="flex justify-content-between mt-2 align-items-center">
          <label className='text-xs'>Codigo</label>
          <Checkbox
            name="codigo"
            value={false}
            onChange={(e) => setRadioButton(e.value)}
            checked={radioButton === false}
            disabled={props.tipoAnalisis === ''}
          />

        </div>
        <div className="flex flex-column gap-2 mt-2">

          {/*EMPTY*/}
          {
            props.tipoAnalisis === ''
            &&
            <><Dropdown
              placeholder="Seleccione una código de 4 dígitos"
              className="flex w-full p-inputtext-sm"
              disabled
            />
              <Dropdown
                placeholder="Seleccione una código de 3 dígitos"
                className="flex w-full p-inputtext-sm"
                disabled
              />
            </>
          }

          {/*RADAR*/}
          {
            props.tipoAnalisis.name === 'Análisis Cualitativo'
            &&
            <>
              {}
              <Dropdown
                value={props.onlyCodigo4}
                placeholder="Seleccione un código de 4 dígitos"
                options={codigoTipo4}
                optionLabel="codigo"
                virtualScrollerOptions={{ itemSize: 20 }}
                className="flex w-full p-inputtext-sm"
                onChange={e => {
                  props.setOnlyCodigo4(e.value);
                }}
                filter
                disabled={radioButton}
              />
              <Dropdown
                value={props.onlyCodigo3}
                placeholder="Seleccione un código de 3 dígitos"
                options={codigoTipo3}
                optionLabel="codigo"
                virtualScrollerOptions={{ itemSize: 20 }}
                className="flex w-full p-inputtext-sm"
                onChange={e => {
                  props.setOnlyCodigo3(e.value);
                }}
                sho
                filter
                disabled={radioButton}
              />
            </>
          }

          {/*BAR*/}
          {
            props.tipoAnalisis?.name === 'Análisis Cuantitativo'
            &&
            <><Dropdown
              value={props.onlyCodigo4}
              placeholder="Seleccione un código de 4 dígitos"
              options={codigoTipo4}
              optionLabel="codigo"
              virtualScrollerOptions={{ itemSize: 20 }}
              className="flex w-full p-inputtext-sm"
              onChange={e => {
                props.setOnlyCodigo4(e.value);
              }}
              filter
              disabled={radioButton}
            />
              <Dropdown
                value={props.onlyCodigo3}
                placeholder="Seleccione un código de 3 dígitos"
                options={codigoTipo3}
                optionLabel="codigo"
                virtualScrollerOptions={{ itemSize: 20 }}
                className="flex w-full p-inputtext-sm"
                onChange={e => {
                  props.setOnlyCodigo3(e.value);
                }}
                sho
                filter
                disabled={radioButton}
              />
            </>
          }


        </div>

      </div>
    </>
  );
};

export default CercoDiag;