import React, { useState ,useContext,useEffect} from "react";
import data from "../../../Services/dataHemograma";
import "../../../Style/style5.css";
import "../../../Style/Button.css";
import { Button } from "primereact/button";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
import AuthUser from "../../../AuthUser";
import LaboratorioListComponent from "../container/laboratorio_list";
import DialogArchivoComponent from "../container/dialog_archivo";


export default function TablaLaborat({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
  const { http } = AuthUser();
  const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
  const [value, setValue] = useState('');
  const [prueba, setPrueba] = useState({})
  const [nombreArchivo, setNombreArchivo] = useState(null);
  const [file, setFile] = useState(null);
  const [indexSup, setIndexSup] = useState(0);
  const [indexSub, setIndexSub] = useState(0);
  //funcion Se puede simplificar
  const [isClicked, setIsClicked] = useState(false);

  function handleClick() {
    setIsClicked(true);
  }

  const [selectedConclusion, setSelectedConclusion] = useState(null);
  const conclusion = [
    { name: 'Normal', code: 'NL' },
    { name: 'Anormal', code: 'AL' },
  ];
  const [parametros, setParametros] = useState
    ([
      {
        prueba: 'Tiempo de Coagulación',
        resultado: 0,
        um: 'mg/dl',
        rango_inicial: 100,
        rango_final: 160,
        valor_normal: '100 - 160',
        observaciones: "",
        seleccion:""
      }
    ])

  const handleCreate = () => {
    const formData = new FormData();
    formData.append("examen_id", tipoExamen);
    formData.append("prueba[examen_id]", tipoPrueba);
    formData.append("prueba[estado]", 1);
    formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
    formData.append("prueba[cantidad_parametros]", parametros?.length);
    formData.append("prueba[parametros]", JSON.stringify(parametros));
    formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));

    http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
      .then(response => {
        console.log(response)
        showToast("success","Creado Correctamente",response?.data?.resp)
      })
      .catch(error => {
        console.log(error)
        showToast("Error","Error de Guardar",error?.data?.resp)
      })
  }

  const changeExamenContext = (superior, inferior, auxParametros) => {
    setExamenesContext(examenesContext.map((elementoSup, index) => {
      if (index === superior) {
        let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
          if (indexAux === inferior) {
            return {
              ...prueba,
              parametros: auxParametros,
              estado: 1,
              cantidad_parametros: auxParametros?.length,
              perfil: auxParametros?.length > 1 ? 2 : 1
            }
          }
          return prueba;
        })
        return { ...elementoSup, pruebas: auxPrueba };
      }
      return elementoSup;
    }))
  }

  const handleInputChange = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].resultado = e.value;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };

  const handleInputInicial = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].rango_inicial = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };
  const handleInputFinal = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].rango_final = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };
  const handleObservaciones = (index, e) => {
    const tempTask = [...parametros];
    // if (!tempTask[index]) {
    //   tempTask.push({
    //     observaciones: "",
    //     seleccion: ""
    //   })


    // }
    tempTask[index]["observaciones"] = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };
  const handleSelec = (index, e) => {
    const tempTask = [...parametros];
    if (!tempTask[index]) {
      tempTask.push({
        observaciones: "",
        seleccion: ""
      })


    }
    tempTask[index].seleccion = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };

  useEffect(() => {
    console.log(examenesContext)
    let sup = 0;
    let inf = 0;
    let auxPruebas = examenesContext.find((examen, index) => {
      if (examen.examen_id === tipoExamen) {
        setIndexSup(index);
        sup = index;
        console.log(index)
      }
      return examen.examen_id === tipoExamen
    });
    let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
      if (prueba.examen_id === tipoPrueba) {
        setIndexSub(index);
        inf = index;
        console.log(index)
      }
      return prueba.examen_id === tipoPrueba
    });
    if (auxPrueba?.archivo) {
      setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
    }
    setPrueba(auxPrueba);
    console.log("PRUEBA", auxPrueba)
    console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
    let auxParametros = typeof auxPrueba?.parametros === 'string' ? JSON.parse(auxPrueba?.parametros) : auxPrueba?.parametros
    auxPrueba?.parametros && setParametros(auxParametros);
    changeExamenContext(sup, inf, auxParametros);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-column w-full h-full" style={{ padding: '20px' }}>
      <div className="flex flex-column w-full">
        <LaboratorioListComponent
          handleInputChange={handleInputChange}
          handleInputFinal={handleInputFinal}
          handleInputInicial={handleInputInicial}
          parametros={parametros}
          viewCodigo={false}
        />
      </div>
      <div className="flex flex-column gap-3">
        <div>
          <p style={{ fontWeight: '700', fontSize: '16px' }}>Observaciones</p>
          <div>
            <InputTextarea value={examenesContext?.[3]?.pruebas?.[0]?.parametros?.[0]?.observaciones}
              onChange={(e) => handleObservaciones(0, e.target.value)} rows={2} className="w-full" />

          </div>
        </div>
        <div className="flex flex-row gap-3 w-full" style={{ alignItems: 'center' }}>
          <p style={{ borderLeft: "0px", fontWeight: '700', fontSize: '16px' }}>Conclusión médica</p>
          <Dropdown
            value={conclusion[examenesContext?.[3]?.pruebas?.[0]?.parametros?.[0]?.seleccion == "NL" ? 0 : 1]}
            onChange={(e) => handleSelec(0, e.value.code)}
            options={conclusion}
            optionLabel="name"
            placeholder="Normal" />
        </div>
      </div>
      <DialogArchivoComponent
        file={file}
        handleCreate={handleCreate}
        nombreArchivo={nombreArchivo}
        prueba={prueba}
        setFile={setFile}
        setNombreArchivo={setNombreArchivo}
        viewFile={false}
      />
    </div>
  );
}
