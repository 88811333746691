import { TreeTable } from "primereact/treetable";
import MultiLevelTreeSelectWithAPI from "../Select";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import imgServicio from "../NewService.svg";
import { Button } from "primereact/button";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useEffect, useState } from "react";
import AuthUser from "../../../../AuthUser";


const ModalEditarPaquete = ({close, estado , data, servicios, showToast, update}) => {
    //console.log("data:", data[0]?.paquete?.bregma_servicio_id, data);
    const { http } = AuthUser();
    const [totalPrecio, setTotalPrecio] = useState(0);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
    const [nombrePaquete, setNombrePaquete] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [selectedTreeSelectId, setSelectedTreeSelectId] = useState(data[0]?.paquete?.bregma_servicio_id);
    const [sendData, setSendData] = useState({
        bregma_servicio_id: data[0]?.paquete?.bregma_servicio_id,
        icono: null,
        nombre: data[0]?.paquete?.nombre,
        areas_medicas: [],
        capacitaciones: [],
        examenes: [],
        laboratorios: [],
     });

    //console.log(selectedService);
    //console.log(data, servicios);
    //console.log('selectedNodeKeys',selectedNodeKeys);


    const sumPrices = (data, keysToSum) => {
        
        let precioTotal = {
            totalAnual: 0,
            totalMensual: 0
          };
        if (keysToSum === null) {
            return precioTotal
        }
        let keyNombres = Object.keys(keysToSum) || [];

        data.forEach((item) => {
            if (keyNombres.includes(item.key) && item.children.length === 0) {
              const precioMensual = Number(item.data.precio_referencial_mensual);
              const precioAnual = Number(item.data.precio_referencial_anual);
              precioTotal.totalMensual += isNaN(precioMensual) ? 0 : precioMensual;
              precioTotal.totalAnual += isNaN(precioAnual) ? 0 : precioAnual;
            }
        
            if (item.children && item.children.length > 0) {
              const childPrices = sumPrices(item.children, keysToSum);
              precioTotal.totalMensual += childPrices.totalMensual;
              precioTotal.totalAnual += childPrices.totalAnual;
            }
          });
        
          return precioTotal;
      };

    const obtenerPadres = (items,hijo) => {
        let names = hijo ? [] :[];
      
        items?.forEach(item => {
          if (item.children.length > 0) {
            names.push(item.nombre);
            names.push(...obtenerPadres(item.children,true));
          }
        });
       
        return names;
    }


      const transformDataChild = (data, parentKey,areasPadre, area) => {
        return data.map((item, index) => {
            const isChild = areasPadre?.includes(item.nombre);
    
            const node = isChild
                ? {
                    key:!area ? `${parentKey}.${item.id}` : `${parentKey}-${item.id}`,
                    data: {
                        nombre: item.nombre.replace('_', ' '),
                        precio_referencial_anual:0,
                        precio_referencial_mensual:0,
                        precio_mensual:0,
                        precio_anual:0,
                    },
                    children: [],
                }
                : {
                    key:!area ? `${parentKey}.${item.id}` : `${parentKey}-${item.id}`,
                    data: {
                        nombre: item.nombre,
                        precio_referencial_anual: item.precio_referencial_anual || 0,
                        precio_referencial_mensual: item.precio_referencial_mensual || 0,
                        precio_mensual: item.precio_mensual || 0,
                        precio_anual: item.precio_anual || 0,
                    },
                    children: [],
                };
    
            if (item.children && item.children.length > 0) {
                node.children = transformDataChild(item.children, !area ? `${parentKey}.${item.id}` : `${parentKey}-${item.id}`,areasPadre);
            }
    
            return node;
        });
    }


    const transformData = (paquetes) => {
        if (!paquetes ||  paquetes.length === 0 || !paquetes[0]){
            return [];
        } else {
            const areas = ['areas_medicas', 'capacitaciones', 'examenes'];
    
            return areas.map((area, index) => {
                const data = {
                    key: `${area}`,
                    data: {
                        nombre: area.replace('_', ' ').toUpperCase(),
                        description: `This is node ${area}`,
                    },
                    children: [],
                };
    
                const areaData = paquetes.flatMap((paquete) => paquete[area]);
                let areasPadre = obtenerPadres(areaData)

                const areaNodes = transformDataChild(areaData, `${index+1}`,areasPadre,true);

                data.children = areaNodes;
                return data;
            });
        }
    };


    const seleccionarServicios = (e) => {
        setSelectedNodeKeys(e);
        let keyNames = Object.keys(e);
/*         console.log("keyNames", e);
        console.log("keyNames Objects", keyNames); */

        const areas_medicas = keyNames
         .filter((data) => data.charAt(0) === "1")
         .map((item) => {

            return {
               id: item.slice(2, item.length),
               parcialmente: e[item]?.partialChecked ? 0 : 1,
            };
         });
        const capacitaciones = keyNames

         .filter((data) => data.charAt(0) === "2")
         .map((item) => {
            return {
               id: item.slice(2, item.length),
               parcialmente: e[item]?.partialChecked ? 0 : 1,
            };
         });
        const examenes = keyNames
         .filter((data) => data.charAt(0) === "3")
         .map((item) => {
            return {
               id: item.slice(2, item.length),
               parcialmente: e[item]?.partialChecked ? 0 : 1,
            };
         });

        setSendData({
            ...sendData,
            areas_medicas: areas_medicas,
            capacitaciones: capacitaciones,
            examenes: examenes,
        });

        //console.log(examenes,capacitaciones, areas_medicas);
     };




    const getKeyData = (data, dataServicios) => {
        const formattedData = {};

        if (!data){
            return {}
        }
      
        const processNode = (node,servicios) => {
          const { key, children } = node;

          const matchingServiceNode = servicios.find(servicio => servicio.key === key);
      
          if (!matchingServiceNode || children.length !== matchingServiceNode.children.length) {
            formattedData[key] = { checked: false, partialChecked: true };
          } else {
            formattedData[key] = { checked: true, partialChecked: false };
          }
      
          children.forEach(childNode => processNode(childNode,matchingServiceNode.children));
        };
      
        data.forEach(node => {
          if (node.children.length > 0 || !['areas_medicas', 'capacitaciones', 'examenes'].includes(node.key)) {
            processNode(node,dataServicios);
          }
        });
      
        return formattedData;
    };


    //ENVIAR DATA
    const editarPaquete = (data,paquete_id) => {
        http
            .put(`/bregma/paquete/update/${paquete_id}`, data)
            .then((response) => {
                showToast('success', 'Actualizado correctamente', 'El paquete se actualizó correctamente');
                //console.log("Paquete actualizado", [response.data]);
                update();
            })
            .catch((e) => {
                console.error(e);
                showToast('error', 'Error', 'No se pudo editar el paquete');
            });
    };
    const EnviarData = () => {
        let AlistarDatos = sendData
        AlistarDatos.bregma_servicio_id = selectedTreeSelectId
        AlistarDatos.nombre = nombrePaquete
        editarPaquete(AlistarDatos,data[0]?.paquete?.id)
        close(false)
    };

    useEffect(() => {
        setSelectedNodeKeys(getKeyData(transformData(data),servicios))
        seleccionarServicios(getKeyData(transformData(data),servicios))
        setNombrePaquete(data ? data[0]?.paquete?.nombre : '')
        setSelectedService(data[0]?.paquete?.bregma_servicio_id)
    },[data]);

    useEffect(() => {
        setTotalPrecio(sumPrices(servicios,selectedNodeKeys))
    },[selectedNodeKeys]);

    //console.log(servicios)
    



    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                footer="Costo Total:"
                footerStyle={{ textAlign: "right", fontFamily: "Montserrat", fontWeight: "600", fontSize: "14px", color: "#4545BC" }}
                />
                <Column
                footer={`S/ ${totalPrecio.totalMensual}`}
                footerStyle={{ textAlign: "center", fontFamily: "Montserrat", fontWeight: "700", fontSize: "14px", color: "rgba(86, 86, 86, 0.8)" }}
                />
                <Column
                footer={`S/ ${totalPrecio.totalAnual}`}
                footerStyle={{ textAlign: "center", fontFamily: "Montserrat", fontWeight: "700", fontSize: "14px", color: "rgba(86, 86, 86, 0.8)" }}
                />
            </Row>
        </ColumnGroup>
    );

    

    const footerContent = (
        <div>
           <Button
              className="p-button-secondary p-button-outlined"
              label="Cancelar"
              onClick={() => close(false)}
           ></Button>
           <Button onClick={() => EnviarData()} label="Actualizar"></Button>
        </div>
     );


    return (
        <>
            <Dialog
            visible={estado}
            onHide={() => close(false)}
            footer={footerContent}
            header={
                    /* Titulo con icono */
                <div className="flex gap-3" style={{ alignItems: "center" }}>
                    <img src={imgServicio} alt="" />
                    <header style={{fontSize: "18px", fontWeight: "600", color: "#5555D8",}}>
                        Actualizar servicio
                    </header>
                </div>
            }
            style={{ width: "70vw", height: "auto" }} footerColumnGroup={footerGroup}>
            {/* Contenido */}
            <div className="DialogoCrearServicio flex flex-column">
                {/* Nombre del paquete con buscador */}

                <div className="flex justify-content-between gap-3 mb-4 mt-4">
                    <div className="flex flex-1 justify-content-between">
                        <div className="flex gap-3 align-items-center">
                        <h6 style={{ fontWeight: "600", fontSize: "18px", color: "#565656" }} >
                            Nombre:
                        </h6>
                        <InputText style={{ height: "36px" }} defaultValue={data ? data[0]?.paquete?.nombre : ''} placeholder="Ingrese un nombre" onChange={(e) => setNombrePaquete(e.target.value)} />
                        </div>
                        <div className="flex gap-3 border-round-sm p-1 align-items-center">
                        <MultiLevelTreeSelectWithAPI
                            setSelectedTreeSelectId={setSelectedTreeSelectId}
                            selectedService={selectedService}
                            setSelectedService={setSelectedService}
                        />
                        </div>
                    </div>
                    <span className="p-input-icon-left align-items-center">
                        <i className="pi pi-search" />
                        <InputText
                        placeholder="Buscador"
                        style={{ background: "rgba(217, 217, 217, 0.3)", height: "36px", width: "100%", border: "transparent" }} />
                    </span>
                </div>
                {/* Tabla con precios y datos */}
                <TreeTable
                    value={servicios || []}
                    selectionMode="checkbox"
                    selectionKeys={selectedNodeKeys}
                    onSelectionChange={(e) =>
                        seleccionarServicios(e.value)
                    }
                    className="ventas_contactList"
                    emptyMessage={
                        <p className="text-center text-gray-500">
                        Seleccione un paquete para ver su
                        detalle.
                        </p>
                    }
                    style={{ height: "100%" }} footerColumnGroup={footerGroup}                              >
                    <Column
                        header={
                        <>
                            <p style={{ fontFamily: "Montserrat", fontWeight: "700", fontSize: "16px", color: "#5B5B5B" }}>Lista de servicios</p>
                        </>
                        }
                        field="nombre"
                        expander
                    />
                    <Column
                        header={
                        <div className="flex flex-row w-full justify-content-center">
                            <div style={{ height: "48px", width: "118px", background: "#FFECD7", borderRadius: "5px", justifyContent: "center", }}                                          >
                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#EF771C", alignItems: "Center", }}                                             >
                                    Precio
                                </p>
                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#EF771C", alignItems: "Center", }}                                             >
                                    Mensual
                                </p>
                            </div>
                        </div>
                        }
                        // "Precio Mensual"
                        body={(node) => (
                        <>
                            {node?.children?.length !== 0 ? (
                                <span></span>
                            ) : (
                                <span className="text-center">
                                    S/{node?.data?.precio_referencial_mensual}
                                </span>
                            )}
                        </>
                        )}
                        className="text-center StyleCOlumn"
                    ></Column>
                    <Column
                        header={
                        <div className="flex flex-row w-full justify-content-center">
                            <div style={{ height: "48px", width: "118px", background: "#E0F5FD", borderRadius: "5px", justifyContent: "center", }}                                          >
                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#6BCDF5", alignItems: "Center", }}                                             >
                                    Precio
                                </p>
                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#6BCDF5", alignItems: "Center", }}                                             >
                                    Anual
                                </p>
                            </div>
                        </div>
                        }
                        field="data.pVenta"
                        className="text-center text-green-500"
                        body={(node) => (
                        <>
                            {node?.children?.length !== 0 ? (
                                <span></span>
                            ) : (
                                <span className="text-center">
                                    S/{node?.data?.precio_referencial_anual}
                                </span>
                            )}
                        </>
                        )}
                    ></Column>
                </TreeTable>
            </div>
            </Dialog>

        </>
    )
}

export default ModalEditarPaquete;