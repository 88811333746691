import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

export default function ComponentSintomasActuales({ audFichaAudiologica, setAudFichaAudiologica }) {
   const optionsaudición = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsoídos = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsZumbido = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsMareos = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsInfección = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsOtrosChange = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsOtoscopia = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const [sintomas, setSintomas] = useState(audFichaAudiologica.aud_fich_sintomas)
   const [isListeningaudición, setIsListeningaudición] = useState(false);
   const [isListeningoídos, setIsListeningoídos] = useState(false);
   const [isListeningZumbido, setIsListeningZumbido] = useState(false);
   const [isListeningOtros, setIsListeningOtros] = useState(false);
   const [isListeningMareos, setIsListeningMareos] = useState(false);
   const [isListeningInfección, setIsListeningInfección] = useState(false);
   const [isListeningOtoscopia, setIsListeningOtoscopia] = useState(false);

   const handleSelectChange = (index, e) => {
      let sintomas_aux = sintomas?.map((sintoma, i) => {
         if (i === index) {
            return {
               patologia_id: sintoma.patologia_id,
               resultado: e.target.value,
               observaciones: "",
            }
         }
         return sintoma;
      })
      setSintomas(sintomas_aux)
      setAudFichaAudiologica({ ...audFichaAudiologica, aud_fich_sintomas: sintomas_aux })
   };

   const handleTextareaChange = (index, e) => {
      console.log(e)
      let antecedentes_aux = sintomas?.map((sintoma, i) => {
         if (i === index) {
            return {
               patologia_id: sintoma.patologia_id,
               resultado: sintoma.resultado,
               observaciones: e,
            }
         }
         return sintoma;
      })
      setSintomas(antecedentes_aux)
      setAudFichaAudiologica({ ...audFichaAudiologica, aud_fich_sintomas: antecedentes_aux })
   };

   const startListeningaudición = () => {
      if (sintomas[0].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningaudición(true);
         };

         recognition.onend = () => {
            setIsListeningaudición(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = sintomas[0].observaciones + transcript;
            handleTextareaChange(0, comentario)
         };

         recognition.start();
      }
   };

   const startListeningoídos = () => {
      if (sintomas[1].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningoídos(true);
         };

         recognition.onend = () => {
            setIsListeningoídos(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = sintomas[1].observaciones + transcript;
            handleTextareaChange(1, comentario)
         };

         recognition.start();
      }
   };

   const startListeningZumbido = () => {
      if (sintomas[2].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningZumbido(true);
         };

         recognition.onend = () => {
            setIsListeningZumbido(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = sintomas[2].observaciones + transcript;
            handleTextareaChange(2, comentario)
         };

         recognition.start();
      }
   };

   const startListeningMareos = () => {
      if (sintomas[3].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningMareos(true);
         };

         recognition.onend = () => {
            setIsListeningMareos(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = sintomas[3].observaciones + transcript;
            handleTextareaChange(3, comentario)
         };

         recognition.start();
      }
   };

   const startListeningInfección = () => {
      if (sintomas[4].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningInfección(true);
         };

         recognition.onend = () => {
            setIsListeningInfección(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = sintomas[4].observaciones + transcript;
            handleTextareaChange(4, comentario)
         };

         recognition.start();
      }
   };

   const startListeningOtros = () => {
      if (sintomas[5].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningOtros(true);
         };

         recognition.onend = () => {
            setIsListeningOtros(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = sintomas[5].observaciones + transcript;
            handleTextareaChange(5, comentario)
         };

         recognition.start();
      }
   };

   const startListeningOtoscopia = () => {
      if (sintomas[6].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningOtoscopia(true);
         };

         recognition.onend = () => {
            setIsListeningOtoscopia(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = sintomas[6].observaciones + transcript;
            handleTextareaChange(6, comentario)
         };

         recognition.start();
      }
   };

   return (
      <div className="flex flex-column w-full gap-3">
         <div className="tabla-responsiva">
            <table className="TablaAntecedenterelacionados">
               <thead>
                  <tr>
                     <th
                        style={{
                           fontWeight: "700",
                           fontSize: "14px",
                           color: "#5555D8",
                        }}
                     >
                        Patología
                     </th>
                     <th
                        style={{
                           fontWeight: "700",
                           fontSize: "14px",
                           color: "#5555D8",
                        }}
                     >
                        Resultado
                     </th>
                     <th
                        style={{
                           fontWeight: "700",
                           fontSize: "14px",
                           color: "#5555D8",
                        }}
                     >
                        Observación
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Disminución de la audición
                     </td>
                     <td>
                        <SelectButton
                           value={sintomas[0].resultado}
                           onChange={(e) => handleSelectChange(0, e)}
                           options={optionsaudición}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-audición"
                              name="comentarios-audición"
                              rows="3"
                              cols="100"
                              disabled={sintomas[0].resultado === "no"}
                              value={sintomas[0].observaciones}
                              onChange={(e) => handleTextareaChange(0, e.target.value)}
                              style={{
                                 fontFamily: "Montserrat",
                                 padding: "6px",
                                 fontSize: "14px",
                              }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningaudición}
                              disabled={isListeningaudición || sintomas[0].resultado === "no"}
                           >
                              {isListeningaudición ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Dolor de oídos
                     </td>
                     <td>
                        <SelectButton
                           value={sintomas[1].resultado}
                           onChange={(e) => handleSelectChange(1, e)}
                           options={optionsoídos}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-oídos"
                              name="comentarios-oídos"
                              rows="3"
                              cols="100"
                              disabled={sintomas[1].resultado === "no"}
                              value={sintomas[1].observaciones}
                              onChange={(e) => handleTextareaChange(1, e.target.value)}
                              style={{
                                 fontFamily: "Montserrat",
                                 padding: "6px",
                                 fontSize: "14px",
                              }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningoídos}
                              disabled={isListeningoídos || sintomas[1].resultado === "no"}
                           >
                              {isListeningoídos ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Zumbido
                     </td>
                     <td>
                        <SelectButton
                           value={sintomas[2].resultado}
                           onChange={(e) => handleSelectChange(2, e)}
                           options={optionsZumbido}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-Zumbido"
                              name="comentarios-Zumbido"
                              rows="3"
                              cols="100"
                              disabled={sintomas[2].resultado === "no"}
                              value={sintomas[2].observaciones}
                              onChange={(e) => handleTextareaChange(2, e.target.value)}
                              style={{
                                 fontFamily: "Montserrat",
                                 padding: "6px",
                                 fontSize: "14px",
                              }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningZumbido}
                              disabled={isListeningZumbido || sintomas[2].resultado === "no"}
                           >
                              {isListeningZumbido ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Mareos
                     </td>
                     <td>
                        <SelectButton
                           value={sintomas[3].resultado}
                           onChange={(e) => handleSelectChange(3, e)}
                           options={optionsMareos}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-Mareos"
                              name="comentarios-Mareos"
                              rows="3"
                              cols="100"
                              disabled={sintomas[3].resultado === "no"}
                              value={sintomas[3].observaciones}
                              onChange={(e) => handleTextareaChange(3, e.target.value)}
                              style={{
                                 fontFamily: "Montserrat",
                                 padding: "6px",
                                 fontSize: "14px",
                              }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningMareos}
                              disabled={isListeningMareos || sintomas[3].resultado === "no"}
                           >
                              {isListeningMareos ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Infección de oído
                     </td>
                     <td>
                        <SelectButton
                           value={sintomas[4].resultado}
                           onChange={(e) => handleSelectChange(4, e)}
                           options={optionsInfección}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-Infección"
                              name="comentarios-Infección"
                              rows="3"
                              cols="100"
                              disabled={sintomas[4].resultado === "no"}
                              value={sintomas[4].observaciones}
                              onChange={(e) => handleTextareaChange(4, e.target.value)}
                              style={{
                                 fontFamily: "Montserrat",
                                 padding: "6px",
                                 fontSize: "14px",
                              }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningInfección}
                              disabled={isListeningInfección || sintomas[4].resultado === "no"}
                           >
                              {isListeningInfección ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>Otros</td>
                     <td>
                        <SelectButton
                           value={sintomas[5].resultado}
                           onChange={(e) => handleSelectChange(5, e)}
                           options={optionsOtrosChange}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-Otros"
                              name="comentarios-Otros"
                              rows="3"
                              cols="100"
                              disabled={sintomas[5].resultado === "no"}
                              value={sintomas[5].observaciones}
                              onChange={(e) => handleTextareaChange(5, e.target.value)}
                              style={{
                                 fontFamily: "Montserrat",
                                 padding: "6px",
                                 fontSize: "14px",
                              }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningOtros}
                              disabled={isListeningOtros || sintomas[5].resultado === "no"}
                           >
                              {isListeningOtros ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Otoscopia
                     </td>
                     <td>
                        <SelectButton
                           value={sintomas[6].resultado}
                           onChange={(e) => handleSelectChange(6, e)}
                           options={optionsOtoscopia}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-Otoscopia"
                              name="comentarios-Otoscopia"
                              rows="3"
                              cols="100"
                              disabled={sintomas[6].resultado === "no"}
                              value={sintomas[6].observaciones}
                              onChange={(e) => handleTextareaChange(6, e.target.value)}
                              style={{
                                 fontFamily: "Montserrat",
                                 padding: "6px",
                                 fontSize: "14px",
                              }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningOtoscopia}
                              disabled={isListeningOtoscopia || sintomas[6].resultado === "no"}
                           >
                              {isListeningOtoscopia ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
}
