import React, { useState, useEffect, useRef } from "react";
import "primeicons/primeicons.css";
import "../../../../Style/areaslocal.css";
import AuthUser from "../../../../AuthUser";
import CardAreas from "./AreasComponentes/CardAreas";
import CardListasGeneralesB from "./AreasComponentes/CardListasGeneralesB";
import TablaAreas from "./AreasComponentes/TablaAreas";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";


const AreasLocal = () => {
   const dispatch = useDispatch();
   const [areas, setAreas] = useState([]);
   const [personal, setPersonal] = useState([])
   const [source, setSource] = useState([]);
   const [target, setTarget] = useState([]);
   const [personalGeneral, setPersonalGeneral] = useState([])
   const [NombreGerente, setNombreGerente] = useState(null);
   const [NombreGerenteBD, setNombreGerenteBD] = useState(null);

   const { id } = useParams();
   const { http } = AuthUser();
   const toast = useRef(null);

   const handlePersonalGeneral = async () => {
      try {
         const response = await http
            .get("bregma/personal/get")
         setPersonalGeneral(response.data.data);

      } catch (error) {
         console.log(error);
      }
   }


   const handleCrearGerente = (data) => {
      http
         .post("bregma/local/jefelocal", data)
         .then((response) => {
            GetGerenteGeneral()
            ShowToast(
               "success",
               "Gerente asignado",
               "Gerente asignado correctamente"
            )
         })
         .catch((error) => {
            console.error("Error al asignar gerente: ", error)
         });
   };

   const GetGerenteGeneral = async () => {
      try {
         const response = await http
            .get(`bregma/local/jefe/mostrar/${id}`)
         let dato = null
         if (response?.data?.data?.bregma_personal != null) {
            dato = response?.data?.data?.empresa_personal?.persona?.apellido_paterno + " " + response?.data?.data?.empresa_personal?.persona?.apellido_materno + ", " + response?.data?.data?.empresa_personal?.persona?.nombres
         }
         setNombreGerenteBD(dato);
         console.log("gerente: ", response?.data?.data);
      } catch (error) {
         console.error(error);
      };
   }

   const obtenerAreasgenerales = async () => {
      try {
         const response = await http
            .get(`/bregma/local/${id}/areas/get`)

         let data = changeDataToTreeTable(response.data.data);

         setAreas(data);
         // console.log("Lista de Areas Generales", response.data )
         console.log("RESULTADO RECURSIVIDAD", data);
      } catch (error) {
         console.error(error);
      };

   };

   const ShowToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail
      })
   }

   const changeDataToTreeTable = (arreglo) => {
      let data = arreglo.map((area) => {
         let aux = [];
         if (area.children && area.children.length > 0) {
            console.log("entro a children");
            aux = changeDataToTreeTable(area.children);
            // data = {...data, children: aux}
         }
         return {
            key: area.key,
            data: {
               id: area.id,
               nombre: area.nombre,
            },
            children: aux,
         };
      });

      return data;
   };

   const handleagregarAreasgenerales = (a) => {
      console.log("Datos a enviar", a);
      http
         .post("bregma/area/create", a)
         .then((response) => {
            console.log("Area general  creada correctamente", a);
            obtenerAreasgenerales();
            ShowToast(
               "success",
               "Área creada",
               "Área creada correctamente"
            )
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const handleGetPersonal = async (idArea) => {
      try {
         const response = await http
            .get(`bregma/personal/area/get/${idArea}`)
         console.log("personal", response?.data?.data)
         setPersonal(response?.data?.data)
         let listTarget = response?.data?.data.filter((item) => item.bregma_area_id === idArea)
         let listSource = response?.data?.data.filter((item) => item.bregma_area_id === null)
         console.log("target", listTarget);
         console.log("Source", listSource);
         setSource(listSource);
         setTarget(listTarget)
      } catch (error) {
         console.error(error);
      };
   }

   const agregarPersonal = (data) => {
      http
         .post(`bregma/personal/asignararea`, data)
         .then((response) => {
            console.log(response);
         })
         .catch((error) => {
            console.error(error);
         });
   }

   const handleEditArea = (idArea, area) => {
      http
         .put(`bregma/area/update/${idArea}`, area)
         .then(() => {
            console.log("Area editado correctamente");
            obtenerAreasgenerales();
            ShowToast(
               "success",
               "Área actualizada",
               "Área actualizada correctamente"
            )
         })
         .catch((error) => {
            console.error(error);
         });
      console.log("EDITAREAA", idArea, area);
   };

   const handleDelete = (idArea) => {
      http
         .delete(`bregma/area/delete/${idArea}`)
         .then((response) => {
            console.log("Area eliminado correctamente");
            obtenerAreasgenerales();
            console.log(idArea);
            ShowToast(
               "success",
               "Eliminado correctamente",
               "Área eliminada correctamente"
            )
            console.log(idArea);
         })
         .catch((error) => {
            console.error(error);
         });
      // console.log("ELIMINARRRRR AREA", idArea);
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            handlePersonalGeneral(),
            obtenerAreasgenerales(),
            GetGerenteGeneral(),
            handleGetPersonal(),

         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
      // obtenerAreasgenerales();
      // handlePersonalGeneral();
      // GetGerenteGeneral()
   }, []);

   return (
      // contenedor principal

      <div className="flex flex-wrap w-full h-full px-2">
         <div className="flex flex-column h-8rem w-full">
            <h1 className="tit w-full">Mis Áreas</h1>
            <div className="flex inline-flex justify-content-between">
               <p className="w-22rem con">
                  A continuación se visualiza las áreas que usted ha definido en su
                  empresa para el local.
               </p>
               <div>
               </div>
            </div>
         </div>
         <div className="flex flex-wrap w-full " style={{ minHeight: "67.5vh" }}>
            <div className="flex  flex-column gap-2 w-3">
               <div className="" style={{/* minHeight: "17vmin" */ }}>
                  <CardAreas></CardAreas>
               </div>

               <div className="" style={{ minHeight: "57vh" }}>
                  <CardListasGeneralesB
                     personalGeneral={personalGeneral}
                     NombreGerente={NombreGerente}
                     setNombreGerente={setNombreGerente}
                     handleCrearGerente={handleCrearGerente}
                     NombreGerenteBD={NombreGerenteBD}
                     GetGerenteGeneral={GetGerenteGeneral}
                  />
               </div>
            </div>

            <div className="flex w-9 pl-3 pr-2">
               {/* <TreeTable value={areas} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="nombre" header="Nombre" expander></Column>
                    </TreeTable> */}
               <TablaAreas
                  areas={areas}
                  id={id}
                  handleagregarAreasgenerales={handleagregarAreasgenerales}
                  act={obtenerAreasgenerales}
                  handleGetPersonal={handleGetPersonal}
                  source={source}
                  target={target}
                  setSource={setSource}
                  setTarget={setTarget}
                  agregarPersonal={agregarPersonal}
                  handleEditArea={handleEditArea}
                  handleDelete={handleDelete}
               ></TablaAreas>
            </div>
         </div>
         <Toast ref={toast} />
      </div>
   );
};
export default AreasLocal;  