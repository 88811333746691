import AuthUser from "../../../../../AuthUser";

const SB2Contact = () => {
    const { http } = AuthUser();
    const convert_form=(data)=>{
        let contacto=new FormData()
        Object.keys(data)?.map((item)=>{
            console.log("item",item)
            contacto.append([item].toString(),data[item])

        })
        return contacto
        
    }
    const getContact = async (clinic_id, showToast) => {
        try {
            const response = await http.get(`clinica/contacto/getcon/${clinic_id}`);
            if (response.data.data) {
                return response.data.data;
            } else {
                showToast && showToast("warn", "Advertencia", response.data.resp);
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getContact");
        }
    };

    const getContactID = async (contact_id, setData, showToast) => {
        await http.get(`clinica/contacto/getcon/${contact_id}`).then((response) => {
            setData(response.data.data);
            console.log(response.data.data);
        }).catch((error) => {
            setData(error.response.data.resp);
            console.log(error.response.data.resp);
        }).finally(() => {
            console.log("getLocations");
        });
    };

    const postContact = async (clinic_id, formData, showToast) => {
        let contact=convert_form(formData)
      console.log("data a enviar",JSON.stringify(formData));
        await http.post(`clinica/contacto/create/${clinic_id}`, contact).then(async (response) => {
            if (response.data.error) {
                showToast("error", "Error", response.data.error);
            } else {
                showToast("success", "Completado", response.data.resp);
          
            }
            console.log(response.data);
        }).catch(async (error) => {
            console.log("postContact", error);
        }).finally(async () => {
            console.log("postContactsss");
        });
    };

    const putContact = async (clinic_id, contact_id, formData, showToast) => {
        let contact=convert_form(formData)
        await http.post(`clinica/contacto/update/${contact_id}/${clinic_id}`, contact).then(async (response) => {
            if (response.data.error) {
                showToast("error", "Error", response.data.error);
            } else {
                console.log("datos-contacto",formData)
                showToast("success", "Completado", response.data.resp);
            }
            console.log(response.data);
        }).catch(async (error) => {
            console.log(error);
        }).finally(async () => {
            console.log("putContact");
        });
    };

    const deleteContact = async (clinic_id, contact_id, setContact, setContactCopy, showToast) => {
        await http.delete(`clinica/contacto/delete/${contact_id}`).then(async (response) => {
            if (response.data.error) {
                showToast("error", "Error", response.data.error);
            } else {
                showToast("success", "Completado", response.data.resp);
            }
            console.log(response.data);
        }).catch(async (error) => {
            console.log("deleteContact", error);
        }).finally(async () => {
            console.log("deleteContact");
            await getContact(clinic_id, setContact, showToast, setContactCopy);
        });
    };

    return {
        getContact,
        getContactID,
        postContact,
        putContact,
        deleteContact
    };
};

export default SB2Contact;