import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../Style/style5.css";
import "../../../Style/Button.css";
import "../../../Style/Laboratorio/EstilosVPH.css";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import AuthUser from "../../../AuthUser";

export default function TablaVPH({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
   const { http } = AuthUser();
   const inputFile = useRef(null);

   const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
   const [labPruebas, setLabPruebas] = useState([])
   const [prueba, setPrueba] = useState({})
   const [nombreArchivo, setNombreArchivo] = useState(null);
   const [file, setFile] = useState(null);
   const [visibleDialog, setVisibleDialog] = useState(false);
   
   const handleCreate = () => {
      const formData = new FormData();
      formData.append("examen_id", tipoExamen);
      formData.append("prueba[examen_id]", tipoPrueba);
      formData.append("prueba[estado]", 1);
      formData.append("prueba[perfil]", 1);
      formData.append("prueba[cantidad_parametros]", 0);
      formData.append("prueba[parametros]", []);
      formData.append("archivo", examenesContext[1]["pruebas"][1]["archivo"] ? examenesContext[1]["pruebas"][1]["archivo"] : null);

      http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
         .then(response => {
            console.log(response)
            showToast("success","Creado Correctamente",response?.data?.resp)
         })
         .catch(error => {
            console.log(error)
            showToast("Error","Error de Guardar",error?.data?.resp)
         })
   }

   const abrirSelectorArchivos = () => {
      inputFile.current.click();
   };

   const handleArchivoCargado = (event) => {
      const archivoCargado = event.target.files[0];
      if (archivoCargado) {
         setFile(archivoCargado)
         setNombreArchivo(archivoCargado.name);
         let temporalPrueba=examenesContext
         temporalPrueba[1]["pruebas"][1]["archivo"]=archivoCargado;
         setExamenesContext(temporalPrueba)
         
      } else {
         setNombreArchivo("");
      }
   };

   const handlerdeletefile = () => {
      setNombreArchivo("")
      setFile(null)
   }
   const abrirDialog = () => {
      setVisibleDialog(true);
   };

   useEffect(() => {
      console.log(examenesContext)
      let auxPruebas = examenesContext.find(examen => {
         return examen.examen_id === tipoExamen
      });
      let auxPrueba = auxPruebas?.pruebas.find((prueba => {
         return prueba.examen_id === tipoPrueba
      }));
      if (auxPrueba?.archivo) {
        
      }
      setLabPruebas(auxPruebas);
      setPrueba(auxPrueba);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <div className="pt-3" style={{ width: "90%", margin: "auto" }} >

      {/* <div className="encabezadoColumnLab" >
         <span style={{ textAlign: "center", color: "blue" }}>
            RIESGO ALTO
         </span>
      </div>

      <div className="CuerpoColumnLab flex gap-3 ml-4 justify-content-center">
         <div className="flex flex-column w-full">
            <DataTable
               emptyMessage="No hay datos">
               <Column header="Descripción del Exámen" ></Column>
               <Column header="Resultado"></Column>
               <Column header="Unidades"></Column>
               <Column header="Valores de Referencia"></Column>
            </DataTable>
         </div>
      </div>


      <div className="encabezadoColumnLab" >
         <span style={{ textAlign: "center", color: "blue" }}>
            RIESGO MEDIO
         </span>
      </div>

      <div className="CuerpoColumnLab flex gap-3 ml-4 justify-content-center">
         <div className="flex flex-column w-full">
            <DataTable
               emptyMessage="No hay datos">
               <Column header="Descripción del Exámen" ></Column>
               <Column header="Resultado"></Column>
               <Column header="Unidades"></Column>
               <Column header="Valores de Referencia"></Column>
            </DataTable>
         </div>
      </div>


      <div className="encabezadoColumnLab" >
         <span style={{ textAlign: "center", color: "blue" }}>
            RIESGO BAJO
         </span>
      </div>

      <div className="CuerpoColumnLab flex gap-3 ml-4 justify-content-center">
         <div className="flex flex-column w-full">
            <DataTable
               emptyMessage="No hay datos">
               <Column header="Descripción del Exámen" ></Column>
               <Column header="Resultado"></Column>
               <Column header="Unidades"></Column>
               <Column header="Valores de Referencia"></Column>
            </DataTable>
         </div>
      </div> */}
      <div
         className="flex flex-row gap-2"
         style={{ alignItems: "center" }}
      >
         <input
            type="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={handleArchivoCargado}
           
         />
         <span>Nombre de Archivo</span>
         <InputText
            type="text"
            value={examenesContext?.length>0 && examenesContext[1]["pruebas"][1]["archivo"]!== null ?(typeof examenesContext[1]["pruebas"][1]["archivo"] === "string" ? examenesContext[1]["pruebas"][1]["archivo"].substring(examenesContext[1]["pruebas"][1]["archivo"].lastIndexOf('/') + 1) : examenesContext[1]["pruebas"][1]["archivo"].name):""}
            readOnly
            style={{
               width: "800px",
               height: "40px",
            }}
         />
         <Button
            icon="pi pi-upload"
            className="upload"
            onClick={abrirSelectorArchivos}
         />
         <Button icon="pi pi-trash"
            onClick={handlerdeletefile}
            className="trash" />
         <Button
            className="search"
            onClick={abrirDialog}
            icon="pi pi-search"
         >
         </Button>
      </div>
      <div className="flex pt-3 pb-3" style={{ justifyContent: 'end' }}>
         <div className="flex gap-3">
            <Button label="Grabar" onClick={handleCreate} icon="fas fa-save" />
            <Button label="Recuperar" icon="fas fa-undo" />
         </div>
      </div>
      <Dialog
         header="Vista previa del PDF"
         visible={visibleDialog}
         style={{ width: '70vw' }}
         onHide={() => setVisibleDialog(false)}
      >
         <iframe
            src={examenesContext?.length>0 && examenesContext[1]["pruebas"][1]["archivo"]!== null  ? (typeof examenesContext[1]["pruebas"][1]["archivo"]!=="string" ? URL.createObjectURL(examenesContext[1]["pruebas"][1]["archivo"]) : typeof examenesContext[1]["pruebas"][1]["archivo"]=="string"? examenesContext[1]["pruebas"][1]["archivo"] : ''):""}
            width="100%"
            height="500px"
            title="Vista previa del PDF"
         />
      </Dialog>
   </div>
   );
}
