import React, { useContext, useEffect, useState } from 'react';
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import InputCalendar from '../../form/InputCalendar';
import { tipoOptions, asocOptions } from "./TriajeAntPerDialogAdd";
import './ModalCrearAbsentismo.css';
import { MGContext } from '../../../Page/MedicinaGeneral/MGProvider';
import { InputText } from 'primereact/inputtext';

const ModalCrearAbsentismo = ({ antDialog, hideDialog }) => {

   const { antPerContext, setAntPerContext } = useContext(MGContext);
   const [Absentismo, setAbsentismo] = useState([])
   const [tipo, setTipo] = useState(null)
   const [otros, setOtros] = useState(null)
   const [tipoAsociado, setTipo_asociado] = useState(null)
   const [descripcion, setDescripcion] = useState('')
   const [fechaInicio, setFechaInicio] = useState('')
   const [fechaInicioDate, setFechaInicioDate] = useState('')
   const [fechaFinal, setFechaFinal] = useState('')
   const [fechaFinalDate, setFechaFinalDate] = useState('')
   const [diasIncapacidad, setDiasIncapacidad] = useState(null)
   const [menoscabo, setMenoscabo] = useState('')


   const limpiar = () => {
      setTipo(null)
      setOtros(null)
      setTipo_asociado(null)
      setDescripcion('')
      setFechaInicio(null)
      setFechaInicioDate(null)
      setFechaFinalDate(null)
      setFechaFinal(null)
      setDiasIncapacidad(null)
      setMenoscabo('')

   }

   const FechaInicio = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         setFechaInicio(fechaFormateada)
         setFechaInicioDate(e?.value)
         calcularDiferencia(e?.value, fechaFinalDate)
      }
   }

   const FechaFinal = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         setFechaFinal(fechaFormateada)
         setFechaFinalDate(e?.value)
         calcularDiferencia(fechaInicioDate, e?.value)
      }
   }
   const calcularDiferencia = (fechaInicio, fechaFinal) => {
      console.log(fechaInicio)
      console.log(fechaFinal)
      if (fechaInicio && fechaFinal) {
         const diferenciaTiempo = fechaFinal.getTime() - fechaInicio.getTime();
         const diferenciaDias = Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24));
         setDiasIncapacidad(diferenciaDias.toString());
      } else {
         setDiasIncapacidad('');
      }
   };

   const handleCrearAbsentismo = () => {
      let nuevoAbsentismos = [...antPerContext.med_ant_absentismo, ...Absentismo]
      setAntPerContext({ ...antPerContext, med_ant_absentismo: nuevoAbsentismos })
      setAbsentismo([])
      hideDialog()
      limpiar()
   }
   useEffect(() => {//Guarda los datos en el context
      setAbsentismo([{//
         [`tipo_absentismo_id`]: tipo,
         [`otros`]: tipo === 4 ? otros : null,
         [`asociado_trabajo`]: tipoAsociado,
         [`descripcion`]: descripcion,
         [`fecha_inicio`]: fechaInicio,
         [`fechaInicioDate`]: fechaInicioDate,
         [`fecha_final`]: fechaFinal,
         [`fechaFinalDate`]: fechaFinalDate,
         [`dias_incapacidad`]: diasIncapacidad,
         [`menoscabo`]: menoscabo,
      }]);
   }, [tipo, otros, tipoAsociado, descripcion, fechaInicio, fechaInicioDate, fechaFinal, fechaFinalDate, diasIncapacidad, menoscabo]);
   const productDialogFooter = (
      <React.Fragment>
         <div className='contenedor_De_Botones_AHP'>
            <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" onClick={() => handleCrearAbsentismo()} />
         </div>
      </React.Fragment>
   );

   return (
      <Dialog
         visible={antDialog}
         style={{ width: "600px", height: "80vh" }}
         breakpoints={{ "960px": "75vw", "641px": "90vw" }}
         modal
         className="p-fluid"
         header="Absentismo por accidente o enfermedades"
         footer={productDialogFooter}
         onHide={hideDialog}
      >
         <div className="flex flex-column gap-3">
            <div>
               <label>Tipo</label>
               <Dropdown
                  placeholder="Seleccione una opción"
                  options={tipoOptions}
                  onChange={(e) => setTipo(e.value)}
                  value={tipo}
               />
            </div>

            {
               tipo === 4 && (<div>
                  <label>Otros</label>
                  <InputText
                     placeholder="Ingrese una descripción"
                     value={otros}
                     onChange={(e) => setOtros(e.target.value)}
                     autoResize
                  />
               </div>)
            }

            <div>
               <label>Asociado al trabajo</label>
               <Dropdown
                  placeholder="Seleccione una opción"
                  options={asocOptions}
                  onChange={(e) => setTipo_asociado(e.value)}
                  value={tipoAsociado}

               />
            </div>

            <div>
               <label>Descripción</label>
               <InputTextarea
                  placeholder="Ingrese una descripción"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                  autoResize
               />
            </div>

            <div>
               <label>Fecha de inicio</label>
               <InputCalendar
                  value={fechaInicioDate}
                  onChange={(e) => FechaInicio(e)}

               />
            </div>

            <div>
               <label>Fecha final</label>
               <InputCalendar
                  value={fechaFinalDate}
                  onChange={(e) => FechaFinal(e)}

               />
            </div>

            <div>
               <label>Días de incapacidad</label>
               <InputNumber
                  value={diasIncapacidad}
                  onChange={(e) => setDiasIncapacidad(e.value)}
                  disabled={true}
               />
            </div>

            <div>
               <label>Menoscabo</label>
               <InputTextarea
                  placeholder="Ingrese una descripción"
                  value={menoscabo}
                  onChange={(e) => setMenoscabo(e.target.value)}
                  autoResize
               />
            </div>
         </div>
      </Dialog>
   );
};





export default ModalCrearAbsentismo/**/ 