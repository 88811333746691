import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { InputText } from "primereact/inputtext";
import Icon from "../../../../Images/Icon.png";

export default function ModalEditarArea({visibleEdAr, setvisibleEdAr, setArea, area, rowData, BotonEditar, handleChangeNombre}){

  
  
  return(
        <Dialog
            style={{ width: "413px", height: "403px" }}
            visible={visibleEdAr}
            onHide={() => setvisibleEdAr(false)}
          >
            <div className="flex flex-column gap-5 ">
              <div className=" flex flex-column gap-2">
                <div>
                  <img
                    src={Icon}
                    alt=""
                    className=" border-round-xl "
                    style={{
                      padding: "15px",
                      backgroundColor: " #EBEBFF",
                    }}
                  />
                </div>
                <div>
                  <p className="text-indigo-600 text-lg font-bold">
                    Editar área
                  </p>
                </div>
                <div className="">
                  <p className="text-400">
                    En esta sección usted puede editar las areas
                  </p>
                </div>
                <div className="">
                  <p className="text-700">Área</p>
                </div>
                <div className="">
                  <InputText
                    className="w-full "
                    onChange={(e) => handleChangeNombre(e)}
                    defaultValue={rowData?.data?.nombre}
                  />
                </div>
              </div>

              <div className=" flex justify-content-evenly gap-2">
                <Button
                  label="Cancelar"
                  className="flex-1"
                  onClick={() => setvisibleEdAr(false)}
                />
                <Button
                  label="Actualizar"
                  className="flex-1"
                  onClick={BotonEditar}
                    
                />
              </div>
            </div>
          </Dialog>
    )
}