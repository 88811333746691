import React from 'react'
import '../../../Style/Botones.css';
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Business.png';
import con1 from '../../../Images/Pure/MenuPrincipal/img-icons/con1.png';
import con2 from '../../../Images/Pure/MenuPrincipal/img-icons/con2.png';
import con3 from '../../../Images/Pure/MenuPrincipal/img-icons/con3.png';
import con4 from '../../../Images/Pure/MenuPrincipal/img-icons/con4.png';


function PageRecursosHumanosE() {
return (
    <div className=' flex flex-nowrap flex-column w-full' style={{height:"100%"}}>
        <div className='arriba'>
            <span className='tit'>Recursos Humanos</span><br/>
            <span className='con'>En este módulo usted podrá realizar la gestión de recursos humanos de Bregma.</span>
        </div>
        <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{height:"100%"}}>
            <Botones
            Link="/recursoshumanos/locales"
            img={con1}
            span="Mis Locales"
            button="button b2"
            />
            <Botones
            Link="/recursoshumanos/roles"
            img={con2}
            span="Roles"
            button="button b3"
            />
            <Botones
            Link="/recursoshumanos/personal"
            img={con3}
            span="Personal"
            button="button b4"
            />
            <Botones
            Link="/recursoshumanos/reclutamiento"
            img={con4}
            span="Reclutamiento"
            button="button b5"
            />
            <Botones
            Link="/recursoshumanos/puestotrabajo"
            img={con4}
            span="Puestos de Trabajo"
            button="button b6"
            />
            <Imagenes
            src={Chica}
            className="logo2"
            />
            
            <Eclipses/>
        </div>
    </div>
)
}

export default PageRecursosHumanosE