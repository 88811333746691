import React, { useEffect, useState } from "react";
import bgcontain from "../../../Images/Operaciones/Servicios/bg-contain.png";
import TableOperacionesComponent from "../../../Components/Operaciones/TableOperaciones";
import AuthUser from "../../../AuthUser";
import { transformData } from "../../../Services/Operaciones/operaciones";
// import "../../../Style/OperServicios.css";
// import "../../../Style/icons.css";
import "../../../Style/Operaciones/operaciones.css";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../Redux/isLoadingSlice";


function PageOperacionServicio() {
   const { http } = AuthUser();
   // Trayendo datos GET
   const [servicios, setServicios] = useState([]);
   const [categories, setcategories] = useState("");
   const [key, setKey] = useState(0);
   const [selectedCategory, setSelectedCategory] = useState(null);
   console.log("Accediendo a la base de datos ");

   const dispatch = useDispatch();

   const getServicios = async() => {
      try {
         const response = await http
         .get("bregma/operaciones/servicios/get")
         .then((response) => {
            console.log("Antes: ",servicios);
            setServicios(transformData([response.data]));
            console.log("Despues: ",servicios);
            setcategories(Object.keys(response.data));
         })
      }
         catch(error) {
            console.error("errrror", error);
         };
   };

   const handleClick = (key, category) => {
      setKey(key);
      // setSelectedCategory(true);
      setSelectedCategory(changue_name_category(category)); 
   };

   const changue_name_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "Áreas Médicas";
         case "capacitaciones":
            return "Capacitaciones";
         case "examenes":
            return "Examen de ayuda al diagnóstico ";
         case "laboratorios":
            return "Laboratorio Molecular";
         default:
            return "";
      }
   };

   const changue_item_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "i-plus-green";
         case "capacitaciones":
            return "i-person-blue";
         case "examenes":
            return "i-diagnostic-vector-orange";
         case "laboratorios":
            return "i-lab-genomica-purple";
         default:
            return "";
      }
   };

   const changue_bg_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "services--content-section-one-content-item-icon-green";
         case "capacitaciones":
            return "services--content-section-one-content-item-icon-blue";
         case "examenes":
            return "services--content-section-one-content-item-icon-orange";
         case "laboratorios":
            return "services--content-section-one-content-item-icon-purple";
         default:
            return "";
      }
   };

   const changue_item_am_category = (cat) => {
      switch (cat) {
         case "Triaje":
            return "i-term-green";
         case "Psicología":
            return "i-brain-green";
         case "EKG":
            return "i-brain-green";
         case "Oftalmología":
            return "i-eye-green";
         case "Audiometría":
            return "i-audiometry-green";
         case "Radiología":
            return "i-heart-break-green";
         case "Laboratorio":
            return "i-spirometry-green";
         case "Medicina General":
            return "i-medic-general-green";
         //
         case "prueba example 1":
            return "i-test-one-blue";
         case "prueba example 2":
            return "i-person-blue";
         case "prueba example 3":
            return "i-test-three-blue";
         //
         case "Examen Radiológico":
            return "i-lab-genomica-purple";
         case "RX Torax":
            return "i-rx-torax-orange";
         case "RX cervical":
            return "i-rx-cervical-orange";
         case "RX Craneo":
            return "i-diagnostic-vector-orange";
         //
         case "Hemograma":
            return "i-genetic-purple";
         case "Tiroides":
            return "i-genetic-purple";
         case "hemograma":
            return "i-genetic-purple";
         default:
            return "";
      }
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getServicios(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());

      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
   }, []);

   return (
      <div className="services-content">
         <section className="services--content-section-one">
            <div className="services--content-section-one-header">
               <h3 className="services--content-section-one-header-title">
                  Servicios
               </h3>
               <p className="services--content-section-one-header-paragraph">
                  En este módulo usted podrá operar sus servicios.
               </p>
            </div>

            <section className="services--content-section-one-body">
               <div className="services--content-section-one-body-section">
                  <h3 className="services--content-section-one-title">
                     Nuestros servicios
                  </h3>
                  <div className="services--content-section-one-content">
                     {categories &&
                        categories?.map((category, key) => (
                           <div
                              className="services--content-section-one-content-item"
                              key={category}
                              onClick={() => handleClick(key, category)}
                           >
                              <div className={changue_bg_category(category)}>
                                 <i
                                    className={changue_item_category(category)}
                                 ></i>
                              </div>
                              <p className="services--content-section-one-content-item-paragraph">
                                 {changue_name_category(category)}
                              </p>
                              <div>
                                 <i
                                    className="pi pi-ellipsis-v"
                                    style={{
                                       color: "#B6B6B6",
                                       fontWeight: "600",
                                       cursor: "pointer",
                                    }}
                                 ></i>
                              </div>
                           </div>
                        ))}
                  </div>
               </div>

               {/* IMAGEN */}
               <div className="services--content-section-one-image">
                  <img src={bgcontain} alt="contain" height={100} width={110} />
                  <p className="services--content-section-one-image-text">
                     Lista de servicios
                  </p>
               </div>
            </section>
         </section>

         <section className="services--content-section-two">
            {selectedCategory ? (
               <>
                  <div className="services--content-section-two-header">
                     <div className={changue_bg_category(selectedCategory)}>
                        <i
                           className={changue_item_category(selectedCategory)}
                        ></i>
                     </div>
                     <h2>{changue_name_category(selectedCategory)}</h2>
                  </div>
                  <div className="services--content-section-two-span">
                     <p>
                        Servicios /
                        <span>{selectedCategory}</span>
                     </p>
                  </div>
                  <div className="search flex justify-content-end">
                  <span className="p-input-icon-left">   
                     <i className="pi pi-search"></i>
                     <input
                        style={{
                           fontSize: "14px",
                           lineHeight: "17.07px",
                           fontWeight: "400",
                           color: "#7B879480",
                        }}
                        className="p-inputtext p-component input_search_AHP"
                        placeholder="Buscar área médica"
                     ></input>
                  </span>
                  </div>
                  <div className="table-responsive">
                     <TableOperacionesComponent
                        getServicios={getServicios}
                        transformedNodes={servicios[key]?.children}
                     ></TableOperacionesComponent>
                  </div>
               </>
            ) : (
               <div className="flex justify-content-center align-items-center w-full h-full">
                  Seleccione un servicio
               </div>
            )}
         </section>
      </div>
   );
}
export default PageOperacionServicio;
