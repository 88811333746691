import React, { useContext, useEffect, useRef } from 'react';
import "../../../Style/CSSContenedores.css";
import 'primeicons/primeicons.css';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import SideArea from '../../../Components/Empresa/GestionAtencion/SideArea';
import SidePaciente from '../../../Components/Empresa/GestionAtencion/SidePaciente';
import AuthUser from '../../../AuthUser';
import { useDispatch } from "react-redux"
import { startLoading, endLoading } from "../../../Redux/isLoadingSlice"
import {  GestionContext } from './services/GestionAtencionContext';

const PageGestionAtencionEmpresa = () => {
   const dispatch = useDispatch();

   const { setLocalesContext } = useContext(GestionContext);
   const toast = useRef(null);
   const { http } = AuthUser();



   const getLocales = async () => {
      await http.get("empresa/gestion/pacientes/get")
         .then((response) => {
            if (response.data.data) {
               setLocalesContext(response.data.data)
            }
         })
         .catch(error => {
            console.log(error)
         })
   }

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getLocales()
         ]);
      } catch (error) {
         console.log(error)
      } finally {
         dispatch(endLoading())
      }
   }

   useEffect(() => {
      dispatch(startLoading())
      fetchAllData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])


   return (
      <div className="table1 flex w-full flex-wrap  tabla-rol flex-row pl-7 pr-6">
         <Toast ref={toast} />
         <div className="section-one flex flex-column flex-1 pr-4 w-8">
            <h1 className="tit">Gestión de atenciones</h1>
            <p className="con">En este módulo usted podrá realizar las gestiones médicas periodicas para los miembros de su empresa.</p>
            <div className='grid'>
               <div className='col-3 Contenedor-LadoIzquierdo-AreasEmpresa'>
                  <div className='flex flex-row justify-content-between align-items-center'>
                     <span style={{ fontWeight: '600', fontSize: '16px' }}>Áreas de la empresa</span>
                     <Button icon="pi pi-ellipsis-h" className='BOTON-TRES-Puntos p-button-outlined p-button-secondary' />
                  </div>
                  <div>
                     <SideArea />
                  </div>
                  <Divider />
               </div>
               <div className='col-9 Contenedor-LadoDerecho-AreasEmpresa'>
                  <SidePaciente></SidePaciente>
               </div>
            </div>
         </div>
      </div>
   )
}

export default PageGestionAtencionEmpresa;
