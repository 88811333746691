import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import AuthUser from "../../../AuthUser";
import axios from "axios";
import iconEditar from "../../../Images/Reclutaminento/img/icon_agregarReclutamiento.svg";
import { endLoading, startLoading } from "../../../Redux/isLoadingSlice";
import InputCalendar from "../../form/InputCalendar";

export default function ModalEditar({
  visibleEdit,
  setVisibleEdit,
  personal,
  showToast,
  tipoDocumentos,
  getPersonal,
  api,
  data,
  dispatch
}) {
  console.log("ModalEditar", data)
  console.log("ModalPersonal", personal)
  const { http } = AuthUser();

  const [value, setValue] = useState("");
  const initialValues = {
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "2022-01-01",
    distrito_id: 2,
    celular: "",
    correo: "",
    direccion: "",
    empresa_cargo: ""
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: async (datos) => {
      dispatch(startLoading());

      console.log("entro a update correctamente");
      const formDataSend = new FormData();
      for (var key in datos) {
        formDataSend.append(key, datos[key]);
      }
      formDataSend.append("tipo_documento_id", datos?.tipo_documento?.id);
      datos.tipo_documento_id = datos?.tipo_documento?.id;

      formDataSend.append("empresa_cargo_id", datos?.empresa_cargo?.id);
      datos.empresa_cargo_id = datos?.empresa_cargo?.id;

      console.log("datos", formDataSend)

      try {
        const response = await http.post(`${api}/${datos.id}`, datos)
        if (response?.data?.resp) {
          await Promise.all([
            getPersonal(),
            HideDialogEdit()
          ])
          console.log("Se actualizó", response?.data?.resp)
          showToast("success", "Actualizado correctamente", "Se creo correctamente el personal");

        } else if (response?.data?.error) {
          showToast("error", "Error", response?.data?.error)
        }

      } catch (error) {
        showToast("error", "Error", "Contáctese con soporte técnico.")
        console.log(error);

      } finally {
        dispatch(endLoading());
      }
    },
  });

  const HideDialogEdit = () => {
    setVisibleEdit(false);
  };

  const paqueteCreateDialogFooter = (
    <div className="flex w-full bot">
      <Button
        type="button"
        label="Cancelar"
        className="delete w-full"
        onClick={() => {
          HideDialogEdit();
        }}
      />
      <Button
        type="submit"
        label="Actualizar"
        className="p-button-success w-full"
        style={{
          backgroundColor: "#5555D8",
          borderColor: "#5555D8",
        }}
      />
    </div>

  );

  const HeaderReclutamiento = (
    <div className="flex align-items-center">
      <div style={{ borderRadius: "10px", backgroundColor: "#EBEBFF", width: "60px", marginRight: '7px' }} className="p-3">
        <img src={iconEditar} alt="" />
      </div>
      <p style={{
        "color": "#5555D8", "fontFamily": "Montserrat", "fontSize": "18px", "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "normal",
      }}>Editar postulante</p>
    </div>
  )



  const updatePersonal = () => {
    console.log("personalllll", personal);

    formik.setValues({
      id: personal?.id,
      tipo_documento: personal?.persona?.tipo_documento,
      numero_documento: personal?.persona?.numero_documento,
      nombres: personal?.persona?.nombres,
      apellido_paterno: personal?.persona?.apellido_paterno,
      apellido_materno: personal?.persona?.apellido_materno,
      fecha_nacimiento: "",
      distrito_id: 2,
      celular: personal?.persona?.celular,
      correo: personal?.persona?.correo,
      direccion: personal?.persona?.direccion,
      empresa_cargo: personal?.empresa_cargo,
    });
  };


  useEffect(() => {
    updatePersonal();
    if (personal?.user_rol?.estado_registro == "A") {
      setValue();
      console.log("SIIII", value)
    } else {
      setValue();
      console.log("NOOO", value)
    }
  }, [personal]);


  const getNombreCompleto = (numdocumento) => {
    dispatch(startLoading());
    if (formik.values.tipo_documento !== "") {
      console.log("tipo de documento", formik.values.tipo_documento.nombre)
      if (formik.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            console.log("DATOS RENIEC", res.data.nombres, res.data.apellidoPaterno, res.data.apellidoMaterno);
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              numero_documento: numdocumento,
              nombres: res.data.nombres,
              apellido_paterno: res.data.apellidoPaterno,
              apellido_materno: res.data.apellidoMaterno,
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              empresa_cargo: formik.values.empresa_cargo
            })
            getPersonal();
          })
          .catch((error) => {
            console.log("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
          .finally(() => {
            dispatch(endLoading());
          })
      } else if (formik.values.tipo_documento.nombre === "RUC") {
        axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            console.log("DATOS RENIEC", res.data.razonSocial);
            getPersonal();
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              numero_documento: numdocumento,
              nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
              apellido_paterno: res.data.razonSocial.split(" ")[0],
              apellido_materno: res.data.razonSocial.split(" ")[1],
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              empresa_cargo: formik.values.empresa_cargo
            })
          })
          .catch((error) => {
            console.log("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
          .finally(() => {
            dispatch(endLoading());
          })
      }

    } else {
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }
  return (
    <div>
      <form
        id="personal-form-edit"
        className="personal-form-edit"
        noValidate
        onSubmit={formik.handleSubmit}

      >
        <Dialog style={{ width: "450px", margin: "5%" }} visible={visibleEdit} header={HeaderReclutamiento} appendTo={document.getElementById("personal-form-edit")}
          dismissableMask
          onHide={HideDialogEdit}
          footer={paqueteCreateDialogFooter}>
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p className=" " style={{ marginBottom: '10px' }}>En esta sección usted puede editar los datos de sus postulantes.</p>
              <p className="per1">Tipo de documento</p>
              <div className="flex w-full flex-column">
                <Dropdown
                  id="tipo_documento"
                  name="tipo_documento"
                  value={formik.values.tipo_documento}
                  onChange={formik.handleChange}
                  options={tipoDocumentos}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de documento"
                  className="w-full"
                />
              </div>
            </div>

            <div className="flex flex-1 w-full  gap-2">
              <InputText
                id="numero_documento"
                name="numero_documento"
                value={formik.values.numero_documento}
                onChange={formik.handleChange}
                className="w-10"
                required
              />

              <Button
                type="button"
                label="Validar"
                style={{ background: "#07A34F", borderColor: "#07A34F" }}
                onClick={() => getNombreCompleto(
                  formik.values.numero_documento,
                )}
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="name">Nombres Completos</label>
              <InputText
                id="nombres"
                name="nombres"
                value={formik.values.nombres}
                className="w-full"
                onChange={formik.handleChange}
                required
                autoComplete="off"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">Apellidos Paterno</label>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={formik.values.apellido_paterno}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">Apellidos Materno</label>
              <InputText
                id="apellido_materno"
                name="apellido_materno"
                value={formik.values.apellido_materno}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="">Correo</label>
              <InputText
                id="correo"
                name="correo"
                value={formik.values.correo}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="">Celular</label>
              <InputText
                id="celular"
                name="celular"
                value={formik.values.celular}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column">
              <p>Puesto de Trabajo</p>
              <Dropdown
                id="empresa_cargo"
                name="empresa_cargo"
                value={formik.values.empresa_cargo}
                onChange={formik.handleChange}
                options={data}
                optionLabel="nombre"
                placeholder="Selecciona un Puesto de trabajo"
                className="w-full"
              //disabled={!value}
              />
            </div>

            <div className="flex flex-1 w-full flex-column gap-2">
              <div className="flex flex-1 w-full gap-2">
                <div>
                  <p>Sexo</p>
                  <Dropdown
                    id="sexo"
                    name="sexo"
                    placeholder="Selecciona tu sexo"
                    className="w-full"
                  />
                </div>

                <div>
                  <p>Fecha de nacimiento</p>
                  <InputCalendar
                    id="fecha_nacimiento"
                    name="fecha_nacimiento"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}