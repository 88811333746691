import { useState, useEffect } from 'react'
import AuthUser from "../../AuthUser";
import { startLoading, endLoading, } from "../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function DataTermsConditions(UrlApi) {
   const { http } = AuthUser();
   const dispatch = useDispatch();

   const [terms, setTerms] = useState([]);

   const changeDataToTreeTable = (arreglo) => {
      let data = arreglo.map((area) => {
         console.log(area.children.length);
         let aux = [];
         if (area.children && area.children.length > 0) {
            console.log("entro a children");
            aux = changeDataToTreeTable(area.children);
         }
         return {
            key: area.path,
            data: {
               id: area.id,
               nombre: area.nombre,
               descripcion: area.descripcion,
            },
            children: aux,
         };
      });

      return data;
   };

   const getAllTerms = async () => {
      try {
         const response = await http
            .get(UrlApi)
         console.log("Datos originales:", response.data.data);
         let data = changeDataToTreeTable(response.data.data);
         console.log("Datos transformados:", data);
         setTerms(data);
      } catch (error) {
         console.log(error);
      }
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getAllTerms(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
      console.log("Componente React renderizado. Estado actual de terms:", terms);
   }, []);

   return { terms, getAllTerms }
}