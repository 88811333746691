import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import "../../Style/container.css";
import { useSelector } from "react-redux";
import SpinnerComponent from "../Pure/spinner/SpinnerComponent";

const RootStyle = ({
   display: 'flex',
   overflow: 'hidden',
});

const MainStyle = {
   overflow: 'auto',
   minHeight: '85%',
   paddingLeft: '1rem',
   paddingRight: '1rem',
   height: "100%"
};

function Container({ persona, loading, sidebar, rol, children }) {
   const [isOpen, setisOpen] = useState(true);
   const isLoading = useSelector((state) => { return state.isLoading; });

   return (
      <React.Fragment>
         {isLoading && (<SpinnerComponent />)}
         <div style={RootStyle} > {/**sidebar + navbar + page */}
            <div className={isOpen ? "custom-sidebar open" : "custom-sidebar close"}> {/**sidebar */}
               {
                  sidebar
                     ?
                     <Sidebar
                        sidebar={sidebar}
                        setOpen={setisOpen}
                        isOpen={isOpen}
                        rol={rol}
                     />
                     :
                     sidebar
               }
            </div>
            <div className="p-content"> {/** navbar + page */}
               <div className="flex justify-content-end gap-2 text-sm p-3"> {/**Navbar */}
                  <Navbar persona={persona} loading={loading} />
               </div>

               <div className="" style={MainStyle}> {/**page main_response*/}
                  <Outlet />
               </div>

            </div>
         </div>
      </React.Fragment>
   );
}



export default Container;
