import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import DialogEditar from "./Dialog/DialogEditar";
import DialogAgregar from "./Dialog/DialogAgregar";
import DialogEliminarGenEvMed from "./Dialog/DialogEliminarGenEvMed";
import "../../../Style/style8.css";
import { Dropdown } from "primereact/dropdown";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import { MGContext } from "../MGProvider";
import { RAContext } from "../../RutaAtencion/RAContext";
import AuthUser from "../../../AuthUser";
import EvaluadorTable from "../../../Components/MedicinaGeneral/examenAltura/pure/EvaluadorTable";
import EvaluadoresEvaluacionMedica from './VerEvaluadorEvaluacionMedica'
import Calendar02 from "../../../Components/form/Calendar02";

export default function EvaluacionMedica({
   setEvMed,
   EvMed,
   DiagMed,
   setDiagMed,
   EvMedicaData,
}) {
   // console.log("Prueba 01", evaluacionMedicaContext?.observaciones)
   let { stateAtencion, pageViewContext } = useContext(RAContext);
   let {
      diagMedicoContext,
      setDiagMedicoContext,
      evaluacionMedicaContext,
      setEvaluacionMedicaContext,
   } = useContext(MGContext);
   const [MedicoAuditor, setMedicoAuditor] = useState(
      evaluacionMedicaContext?.med_dx_conclusion?.medico_auditor?.nombre_completo ?? null
   );
   const [MedicoEvaluador, setMedicoEvaluador] = useState(
      evaluacionMedicaContext?.med_dx_conclusion?.medico_evaluador?.nombre_completo ?? null
   );
   const [idEvaluador, setIdEvaluador] = useState(
      evaluacionMedicaContext?.med_dx_conclusion?.medico_evaluador_id ?? null
   )
   const [idAuditor, setIdAuditor] = useState(
      evaluacionMedicaContext?.med_dx_conclusion?.medico_auditor_id ?? null
   )
   const [ColegiaturaAud, setColegiaturaAud] = useState(
      evaluacionMedicaContext?.med_dx_conclusion?.medico_evaluador?.evaluador_tipo_especialista?.cmp ?? null
   );
   const [ColegiaturaEv, setColegiaturaEv] = useState(
      evaluacionMedicaContext?.med_dx_conclusion?.medico_auditor?.evaluador_tipo_especialista?.cmp ?? null
   );

   const [VisibleEliminar, setVisibleEliminar] = useState(false);
   const [VisibleEditar, setVisibleEditar] = useState(false);
   const [DiagMedID, setDiagMedID] = useState();
   const [DiagMedData, setDiagMedData] = useState();
   const [FechaActual, setFechaActual] = useState();
   const [visibleEvaluador, setVisibleEvaluador] = useState(false);
   const [visibleAuditor, setVisibleAuditor] = useState(false);
   const [auditores, setAuditores] = useState({})
   const [data, setData] = useState([]);

   // OBTENER LAS ENFGERMEDADES ESPECIFICAS
   const getdatos = () => {
      http
         .get("medicina_general/enfermedad_especifica/get")
         .then((res) => {
            setData(res["data"]);
         })
         .catch((error) => console.log(error));
   };

   useEffect(() => {
      getdatos();
   }, []);

   const eliminarDiagnosticoPorIndice = (indice) => {
      setEvaluacionMedicaContext(prevState => {
         const newState = { ...prevState };

         newState.med_dx_conclusion.med_lista_general_diagnostico.splice(indice, 1);

         return newState;
      });
   };

   // MODAL ELIMINAR
   const HandleDelete = (index) => {
      setVisibleEliminar(true);
      setDiagMedID(index);
   };

   //MODAL EDITAR
   const HandleEditar = (item, index) => {
      setVisibleEditar(true);
      setDiagMedData(item);
      setDiagMedID(index);
   };

   // VER EVALUADORES
   const [NombreEvaluador, setNombreEvaluador] = useState('');
   const [Colegiatura, setColegiatura] = useState('');
   const [evaluadores, setEvaluadores] = useState({})
   const { http } = AuthUser();
   const [viewPop, setviewPop] = useState(false);
   const getMedicoEvas = () => {
      http
         .get("medicina_general/evaluacion_medica/evaluadores/get")
         .then((response) => {
            setEvaluadores(response.data.evaluadores)
            console.log("Evaluadores: ", response.data.evaluadores);
         })
   }
   useEffect(() => {
      getMedicoEvas();
   }, []);
   useEffect(() => {
      if (evaluadores && evaluadores.length > 0) {
         evaluadores.map((item) => {
            if (item.id == evaluacionMedicaContext.med_dx_conclusion.medico_evaluador_id) {
               setNombreEvaluador(item.nombres_completos);
               setColegiatura(item.colegiatura);
            }
         })
      }
   }, [evaluadores, evaluacionMedicaContext]);
   const ActualizarView = () => {
      if (evaluadores && evaluadores.length > 0) {
         if (evaluacionMedicaContext.med_dx_conclusion.medico_evaluador_id) {
            evaluadores.map((item) => {
               if (item.id == evaluacionMedicaContext.med_dx_conclusion.medico_evaluador_id) {
                  setNombreEvaluador(item.nombres_completos);
                  setColegiatura(item.colegiatura);
               }
            })
         }
         else {
            setNombreEvaluador("");
            setColegiatura("");
         }
      }
   }
   useEffect(() => {
      ActualizarView();
   }, [evaluacionMedicaContext])

   const ActualizarEvaluador = (ideva) => {
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            medico_evaluador_id: ideva
         }
      }));
   };

   // VER AUDITOR
   const [NombreAuditor, setNombreAuditor] = useState('');
   const [ColegiaturaAuditor, setColegiaturaAuditor] = useState('');
   const [viewPopAud, setviewPopAud] = useState(false);

   useEffect(() => {
      if (evaluadores && evaluadores.length > 0) {
         evaluadores.map((item) => {
            if (item.id == evaluacionMedicaContext.med_dx_conclusion.medico_auditor_id) {
               setNombreAuditor(item.nombres_completos);
               setColegiaturaAuditor(item.colegiatura);
            }
         })
      }
   }, [evaluadores, evaluacionMedicaContext]);

   const ActualizarViewAuditor = () => {
      if (evaluadores && evaluadores.length > 0) {
         if (evaluacionMedicaContext.med_dx_conclusion.medico_auditor_id) {
            evaluadores.map((item) => {
               if (item.id == evaluacionMedicaContext.med_dx_conclusion.medico_auditor_id) {
                  setNombreAuditor(item.nombres_completos);
                  setColegiaturaAuditor(item.colegiatura);
               }
            })
         }
         else {
            setNombreAuditor("");
            setColegiaturaAuditor("");
         }
      }
   }

   useEffect(() => {
      ActualizarViewAuditor();
   }, [evaluacionMedicaContext])

   const ActualizarAuditor = (ideva) => {
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            medico_auditor_id: ideva
         }
      }));
   };

   const ActualizarContextObservacion = (value) => {
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            observaciones: value
         }
      }));
   };

   const ActualizarContextRecomendacion = (value) => {
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            recomendaciones: value
         }
      }));
   };

   const ActualizarContextRestriccion = (value) => {
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            restricciones: value
         }
      }));
   };

   const ActualizarContextFechaValido = (value) => {
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            fecha_valido: value
         }
      }));
   };

   const [ValidoHasta, setValidoHasta] = useState(evaluacionMedicaContext?.med_dx_conclusion?.fecha_valido);

   const SetearObjeto = (array, seteo, id) => {
      array.map((index) => {
         if (index.code == id) {
            seteo(index)
         }
      })
   }

   const ActualizarContextResultado = (value) => {
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            resultado_id: value
         }
      }));
   };

   const opcionesResultado = [
      { name: 'Apto', code: '1' },
      { name: 'Apto con observación', code: '2' },
      { name: 'Apto con Recomendación', code: '3' },
      { name: 'Apto con Restricciones', code: '4' },
      { name: 'No Apto', code: '5' },
      { name: 'No Apto Temporal', code: '6' }
   ];

   const [Resultado_code, setResultado_code] = useState(evaluacionMedicaContext?.med_dx_conclusion?.resultado_id);
   const [Resultado_obje, setResultado_obje] = useState();

   useEffect(() => {
      SetearObjeto(opcionesResultado, setResultado_obje, Resultado_code)
   }, [evaluacionMedicaContext])

   useEffect(() => {
      ActualizarContextResultado(Resultado_code)
   }, [Resultado_code])

   return (
      <div>
         <div
            className="TituloHemograma flex flex-row gap-3"
            style={{
               marginBottom: "2rem",
               alignItems: "center",
               justifyContent: "space-between",
            }}
         >
            <span
               style={{ fontWeight: "500", fontSize: "14px", color: "#000000" }}
            >
               Lista de Diagnósticos
            </span>
            <div className="flex flex-row gap-2 mb-2">
               <DialogAgregar
                  DiagMed={DiagMed}
                  setDiagMed={setDiagMed}
                  data={data} />
            </div>
         </div>
         <div
            style={{
               height: "393px",
               overflowY: "auto",
               backgroundColor: "#FFFFFF",
               boxShadow: "0px 4px 15px rgba(225, 223, 223, 0.37)",
               borderRadius: "17px",
            }}
         >
            <table
               border={0}
               className="DiagnosticoTablaResponsive "
               style={{ width: "100%" }}
            >
               <thead>
                  <tr>
                     <th style={{ borderLeft: "0px", textAlign: "center" }}>Cie</th>
                     <th style={{ textAlign: "center" }}>Diagnóstico</th>
                     <th style={{ textAlign: "center" }}>Observación</th>
                     <th style={{ textAlign: "center" }}>Importante</th>
                     <th style={{ textAlign: "center" }}>Diagnóstico Ocupacional</th>
                     <th style={{ textAlign: "center" }}>Recomendación</th>
                     <th style={{ textAlign: "center" }}>Acción</th>
                  </tr>
               </thead>
               <tbody>
                  {evaluacionMedicaContext.med_dx_conclusion.med_lista_general_diagnostico?.map((item, index) => (
                     <React.Fragment>
                        <tr key={item.id}>
                           <td style={{ borderLeft: "0px" }}>
                              {item.cie ? item.cie : (item.enfermedad_especifica ? item.enfermedad_especifica.codigo : null)}
                           </td>
                           <td>
                              {item.tipo_diagnostico == "0" ? "Presuntivo" :
                                 item.tipo_diagnostico == "1" ? "Definitivo" :
                                    item.tipo_diagnostico == "2" ? "Repetitivo" :
                                       null
                              }
                           </td>
                           <td>{item.observacion}</td>
                           <td>{item.importante == "0" ? "Si" :
                              item.importante == "1" ? "No" :
                                 null}
                           </td>
                           <td>{item.importante == "0" ? "Si" :
                              item.importante == "1" ? "No" :
                                 null}
                           </td>
                           <td>{item.recomendacion}</td>
                           <td>
                              <React.Fragment>
                                 <Button
                                    icon="pi pi-pencil"
                                    className="editar1"
                                    onClick={() => HandleEditar(item, index)} />
                                 <Button
                                    icon="pi pi-trash"
                                    className="eliminar1"
                                    onClick={() => HandleDelete(index)} />
                              </React.Fragment>
                           </td>
                        </tr>
                     </React.Fragment>
                  ))}
               </tbody>
            </table>
         </div>
         <DialogEliminarGenEvMed
            VisibleEliminar={VisibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            DiagMedID={DiagMedID}
            funcion={eliminarDiagnosticoPorIndice}
            ContextEliminar={diagMedicoContext} />

         <DialogEditar
            VisibleEditar={VisibleEditar}
            setVisibleEditar={setVisibleEditar}
            DiagMedDataAll={DiagMedData}
            DataDrop={data}
            DiagMedID={DiagMedID} />

         <div className="flex flex-column gap-3" style={{ marginTop: "40px" }}>
            <div
               className="flex gap-3"
               style={{ justifyContent: "space-between" }}
            >
               <div className="flex flex-column w-full">
                  <p
                     style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000000",
                        fontFamily: "Montserrat",
                        width: "104px",
                     }}
                  >
                     Observaciones
                  </p>
                  <div>
                     <VoiceButton
                        value={evaluacionMedicaContext?.med_dx_conclusion?.observaciones}
                        onChange={ActualizarContextObservacion}
                        placeholder="Comentario"
                        disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
               </div>
               <div className="flex flex-column w-full">
                  <p
                     style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000000",
                        fontFamily: "Montserrat",
                        width: "104px",
                     }}
                  >
                     Recomendaciones
                  </p>
                  <div>
                     <VoiceButton
                        value={evaluacionMedicaContext?.med_dx_conclusion?.recomendaciones}
                        onChange={ActualizarContextRecomendacion}
                        placeholder="Comentario"
                        disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
               </div>
               <div className="flex flex-column w-full">
                  <p
                     style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000000",
                        fontFamily: "Montserrat",
                        width: "104px",
                     }}
                  >
                     Restricciones
                  </p>
                  <div>
                     <VoiceButton
                        value={evaluacionMedicaContext?.med_dx_conclusion?.restricciones}
                        onChange={ActualizarContextRestriccion}
                        placeholder="Comentario"
                        disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
               </div>
            </div>
         </div>
         <div
            className="flex flex-wrap w-auto"
            style={{ marginTop: "20px", gap: "1rem" }}
         >
            <div className="flex flex-column gap-3" >
               <div className="flex flex gap-2 w-auto" >
                  <div className="flex flex-column">
                     <span
                        style={{ fontWeight: "400", fontSize: "14px", color: "#000000", fontFamily: "Montserrat", width: "114px" }}>
                        Médico Auditor
                     </span>
                     <InputText
                        className="flex flex-1" style={{ width: '300px' }} disabled value={NombreAuditor} onChange={(e) => setNombreAuditor(e.target.value)} />
                  </div>
                  <div className="flex flex-column">
                     <span
                        style={{ fontWeight: "400", fontSize: "14px", color: "#000000", fontFamily: "Montserrat", width: "104px" }}>
                        Colegiatura
                     </span>
                     <InputText
                        className="w-7rem" disabled value={ColegiaturaAuditor} onChange={(e) => setColegiaturaAuditor(e.target.value)} />
                  </div>
                  <div className="flex align-items-end">
                     <Button className="ButonEvaluadoresRadiologiaAg flex align-center justify-content-center"
                        icon="pi pi-search" severity="success" onClick={() => { setviewPopAud(true) }}
                        style={{ width: '40px', maxWidth: '40px', height: '45px', backgroundColor: '#BFF1DF', borderColor: '#248D63' }}>
                        <style>
                           {`
                              .ButonEvaluadoresRadiologiaAg .pi-search:before{
                                 color: #248D63 !important;
                              }
                           `}
                        </style>
                     </Button>
                     <EvaluadoresEvaluacionMedica
                        evaluadores={evaluadores}
                        visible={viewPopAud}
                        setVisible={setviewPopAud}
                        id={evaluacionMedicaContext?.med_dx_conclusion?.medico_auditor_id}
                        setid={ActualizarAuditor} />
                  </div>
               </div>
               <div className="flex flex-row gap-2" >
                  <div className="flex flex-column">
                     <span
                        style={{ fontWeight: "400", fontSize: "14px", color: "#000000", fontFamily: "Montserrat", }}>
                        Médico Evaluador
                     </span>
                     <InputText
                        className="flex flex-1" style={{ width: '300px' }} disabled value={NombreEvaluador} onChange={(e) => setNombreEvaluador(e.target.value)} />
                  </div>
                  <div className="flex flex-column">
                     <span
                        style={{ fontWeight: "400", fontSize: "14px", color: "#000000", fontFamily: "Montserrat", width: "104px" }}>
                        Colegiatura
                     </span>
                     <InputText
                        className="w-7rem" disabled value={Colegiatura} onChange={(e) => setColegiatura(e.target.value)} />
                  </div>
                  <div className="flex align-items-end">
                     <Button className="ButonEvaluadoresRadiologiaAg flex align-center justify-content-center"
                        icon="pi pi-search" severity="success" onClick={() => { setviewPop(true) }}
                        style={{ width: '40px', maxWidth: '40px', height: '45px', backgroundColor: '#BFF1DF', borderColor: '#248D63' }}>
                        <style>
                           {`
                              .ButonEvaluadoresRadiologiaAg .pi-search:before{
                                 color: #248D63 !important;
                              }
                           `}
                        </style>
                     </Button>

                     <EvaluadoresEvaluacionMedica
                        evaluadores={evaluadores}
                        visible={viewPop}
                        setVisible={setviewPop}
                        id={evaluacionMedicaContext?.med_dx_conclusion?.medico_evaluador_id}
                        setid={ActualizarEvaluador} />
                  </div>
               </div>
            </div>
            <div className="flex flex-column gap-3 flex-1">
               <div className="flex flex-column">
                  <span
                     style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000000",
                        fontFamily: "Montserrat",
                     }}
                  >
                     Válido hasta
                  </span>
                  <Calendar02
                     val={evaluacionMedicaContext?.med_dx_conclusion?.fecha_valido}
                     update={ActualizarContextFechaValido} />
               </div>
               <div className="flex flex-column">
                  <p
                     style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000000",
                        fontFamily: "Montserrat",
                     }}
                  >
                     Resultado Médico
                  </p>
                  <div className="card flex justify-content-center">
                     <Dropdown
                        value={Resultado_obje}
                        onChange={(e) => { setResultado_code(e.value.code) }}
                        options={opcionesResultado}
                        optionLabel="name"
                        disabled={false}
                        className="w-full md:w-25.5rem"
                        style={{
                           width: "355px",
                        }} />
                  </div>

                  <EvaluadorTable
                     medico={"Evaluador"}
                     evaluadores={evaluadores}
                     visible={visibleEvaluador}
                     setVisible={setVisibleEvaluador}
                     setColegiatura={setColegiaturaEv}
                     setNombreMed={setMedicoEvaluador}
                     setIdEvaluador={setIdEvaluador} />

                  <EvaluadorTable
                     medico={"Auditor"}
                     evaluadores={auditores}
                     visible={visibleAuditor}
                     setVisible={setVisibleAuditor}
                     setColegiatura={setColegiaturaAud}
                     setNombreMed={setMedicoAuditor}
                     setIdEvaluador={setIdAuditor} />
               </div>
            </div>
         </div>
      </div>
   );
}
