import React from "react";
import Botones from "../../Components/Pure/MenuPrincipal/botones";
import Imagenes from "../../Components/Pure/MenuPrincipal/Imagenes";
import Eclipses from "../../Components/Pure/MenuPrincipal/Eclipses";
// import "../../Style/Botones.css";
import Productos from "../../Images/Pure/MenuPrincipal/img-icons/opera2.png";
import Servicios from "../../Images/Pure/MenuPrincipal/img-icons/opera1.png";
import Operaciones from "../../Images/Pure/MenuPrincipal/Img/Operaciones.png";
// import "../../Style/PageOperaciones.css"
import "../../Style/Operaciones/operaciones.css"
import ClinProforma from "../ClinicaRol/ProductoServicio/proforma/cliProforma";

const PageOperaciones = ({ accesoProp }) => {
   console.log("accesos en prop", accesoProp);
   const listAcceso = accesoProp.map((acc) => {
      return acc.url
   });
   const list_images = (url) => {
      switch (url) {
         case "productos":
            return Productos;
         default:
            return Servicios;
      }
   }
   return (
      <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
         <div className="arriba">
            <span className="tit">Operaciones</span>
            <br />
            <span className="con">
               En este módulo usted podrá visualizar la totalidad de los servijjjcios y
               productos que se ofertarán en Bregma.{" "}
            </span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
            {
               accesoProp.map((acceso, key) => {
                  return <>
                     <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b" + (key + 1)}
                     />
                  </>
               })
            }
         </div>

         <Imagenes className="logo2" src={Operaciones} />
         <Eclipses />
      </div>

   );
};

export default PageOperaciones;
