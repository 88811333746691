import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import AuthUser from "../../../AuthUser";
import { Skeleton } from "primereact/skeleton";
import CardCuentaComponent from "./CardCuenta";
import BilleteraElectronicaComponent from "./BilleteraElectronica";
import { Carousel } from "primereact/carousel";
import "../../../Style/stylePerfil.css";
import "../../../Style/usuario/TabViewCuentas.css";
import { Toast } from 'primereact/toast';
import RucIcon from "../../../Images/Iconperfil/Credit-Card.png"

// import { SimulacionComponent } from "../PerfilClinica/CuentasPago/CuentasSimulacion";

//sebas
export default function CrearCcomponent({
   getEntidadesElectronicas,
   entidadPagoElectronico,
   getbregma,
   entidadPagos,
   entidadesBancarias,
   entidadesElectronicas, 
   handleCreate,
   showtoast

}) {
   const { http } = AuthUser();
   const [visible, setVisible] = useState(false);
   const [visible2, setVisible2] = useState(false);
   const [dataTypeEntity, setDataTypeEntity] = useState([]);
   const [entidadPago, setEntidadPago] = useState({
      id: 0,
      numero_cuenta: "",
      cci: "",
      entidad_bancaria_id: null,
      entidad_bancaria: null,
   });

   const [entidadpagoElectronico, setEntidadpagoElectronico] = useState({
      id: 0,
      entidad_electronica_id: null,
      numero_cuenta: "",
      entidad_electronica: null,
   });




   const limpiarEntidadBancaria = () => {
      setEntidadPago({
         id: 0,
         entidad_electronica_id: null,
         numero_cuenta: "",
         entidad_electronica: null,
      })
   }

   const limpiarEntidadElectronica = () => {
      setEntidadpagoElectronico({
         id: 0,
         entidad_electronica_id: null,
         numero_cuenta: "",
         entidad_electronica: null,
      })
   }
   // "entidad_electronica/get"
   const handleGetTypeEntity = () => {
      http
         .get("entidad_electronica/get")
         .then((response) => {
            setDataTypeEntity(response.data.data);
         })
         .catch((error) => {
            console.error("error", error);
         });
   };

   //  const entity = .map(item => item)

   // Electronica create
   const handleCreateElectronica = () => {
      http
         .post("entidad_electronica_pago/create", entidadpagoElectronico)
         .then((response) => {
            getbregma();
            console.log(response)
            // entidadesElectronicas()
            setVisible2(false);
            limpiarEntidadElectronica()
            getEntidadesElectronicas()
         })
         .catch((error) => {
            setVisible2(false);
            console.error("error", error);
         });
   };

   const handleChangeNumeroCuentaBancaria = (e) => {
      setEntidadPago({ ...entidadPago, numero_cuenta: e.target.value });
   };

   // Electronica
   const handleChangeNumeroCuentaElectronica = (e) => {
      setEntidadpagoElectronico({
         ...entidadpagoElectronico,
         numero_cuenta: e.target.value,
      });
   };

   const handleChangeCCI = (e) => {
      setEntidadPago({ ...entidadPago, cci: e.target.value });
   };

   const handleChangeEntidadBancaria = (e) => {
      setEntidadPago({
         ...entidadPago,
         entidad_bancaria: e.target.value,
         entidad_bancaria_id: e.value === null ? null : e.value.id,
      });
   };

   // electronica
   const handleChangeEntidadElectronica = (e) => {
      setEntidadpagoElectronico({
         ...entidadpagoElectronico,
         entidad_electronica: e.target.value,
         entidad_electronica_id: e.value === null ? null : e.value.id,
      });
   };

   const toast = useRef(null);
   const show = () => {
      toast.current.show({ severity: 'success', summary: 'Creación exitosa', detail: 'la cuenta se creo exitosamente' });
   };

   const billeteras = entidadPagoElectronico?.map((entidadPagoElectronico) => {
      if (entidadPagoElectronico.estado_registro == "A") {
         return (
            <BilleteraElectronicaComponent
               entidadPagoElectronico={entidadPagoElectronico}
               tipo={dataTypeEntity}
               getEntidadesElectronicas={getEntidadesElectronicas}
               entidadesElectronicas={entidadesElectronicas}
            />
         );
      }
   });

   const cuentasActivas2 = billeteras?.filter(
      (entidadPagoElectronico) => entidadPagoElectronico !== undefined
   );

   const cuentas = entidadPagos?.map((entidadPago) => {
      if (entidadPago.estado_registro == "A") {
         return (
            <CardCuentaComponent
               entidadPago={entidadPago}
               getbregma={getbregma}
               entidadesBancarias={entidadesBancarias}
            />
         );
      }
   });

   const cuentasActivas = cuentas?.filter(
      (entidadPago) => entidadPago !== undefined
   );

   const [activeTab, setActiveTab] = useState(0);

   const changeTab = (index) => {
      setActiveTab(index);
   };

   const CerrarModal = () => {
      setVisible(false);
   }

   // Skeleton
   const [loading, setLoading] = useState(true);
   useEffect(() => {
      handleGetTypeEntity()
      setTimeout(() => {
         setLoading(false);
      }, 3000);
   }, []);
   const loader = () => {
      return (
         <div
            className="wrapperdb flex flex-wrap w-full "
            style={{ height: "72px" }}
         >
            <div className="right flex flex-row w-full">
               <div className="info flex flex-wrap w-full">
                  <h3
                     className="flex flex-wrap w-full "
                     style={{ marginTop: "10px", marginLeft: "8px" }}
                  >
                     <Skeleton
                        width="355px"
                        height="48px"
                        borderRadius="16px"
                     ></Skeleton>
                  </h3>
               </div>
               <div className="flex flex-wrap" style={{ marginTop: "10px" }}>
                  <Skeleton
                     width="3rem"
                     height="3rem"
                     borderRadius="16px"
                  ></Skeleton>
               </div>
            </div>
         </div>
      );
   };

   if (loading) {
      return loader();
   } else {
      return (
         <div>
            <div className="tabview22">
               <Toast ref={toast} />
               <div className="tab-header22 flex fle-row gap-3">
                  <button
                     className={`tab-button22 ${activeTab === 0 ? "active" : ""
                        }`}
                     onClick={() => changeTab(0)}
                     style={{ width: "100%" }}
                  >
                     Cuentas Bancarias
                  </button>
                  <button
                     className={`tab-button22 ${activeTab === 1 ? "active" : ""
                        }`}
                     onClick={() => changeTab(1)}
                     style={{ width: "100%" }}
                  >
                     Billeteras electrónicas
                  </button>
               </div>
                  <div className="tab-content22" style={{ maxWidth: "90vw" }}>
                     {cuentasActivas.length > 0 && (
                        <div className={`tab-pane22 ${activeTab === 0 ? "active" : ""}`}>
                              <div className="flex flex-column" style={{ maxWidth: "100%" }}>
                                 <div className="flex align-items-center justify-content-center">
                                    <Carousel
                                          className="CarruselCuentasBancarias_1"
                                          circular
                                          style={{ maxWidth: "100%" }}
                                          value={cuentasActivas}
                                          autoplayInterval={3000}
                                          numVisible={3}
                                          numScroll={1}
                                          itemTemplate={(item) => (
                                             <div className="p-d-flex p-jc-center flex">
                                                {item}
                                             </div>
                                          )}
                                    />
                                 </div>
                              </div>
                        </div>
                     )}
                     {cuentasActivas2.length > 0 && (
                        <div className={`tab-pane22 ${activeTab === 1 ? "active" : ""}`}>
                           <div className="flex flex-column" style={{ maxWidth: "100%" }}>
                              <div className="flex align-items-center justify-content-center ">
                                 <Carousel
                                    className="CarruselCuentasBancarias_1"
                                    circular
                                    style={{ maxWidth: "100%" }}
                                    value={cuentasActivas2}
                                    autoplayInterval={3000}
                                    numVisible={3}
                                    numScroll={1}
                                    itemTemplate={(item) => (
                                          <div className="p-d-flex p-jc-center flex">
                                             {item}
                                          </div>
                                    )}
                                 />
                              </div>
                           </div>
                        </div>
                     )}
                     <div className="flex flex-wrap w-full text-sm justify-content-center" style={{ marginTop: "auto" }}>
                        {activeTab === 0 && (
                              <button
                                 className="custom-button"
                                 onClick={() => setVisible(true)}
                                 style={{
                                    background: "#FFFFFF",
                                    borderRadius: "9px",
                                    border: "none",
                                    margin: "0 auto",
                                    padding: "14px 12px",
                                    cursor: "pointer",
                                    width: "100%", // Para que ocupe todo el ancho del contenedor padre
                                    maxWidth: "100rem", // Opcional: establecer un ancho máximo
                                 }}
                              >
                                 <span
                                    style={{
                                          fontWeight: "400",
                                          fontSize: "15px",
                                          color: "rgba(69, 69, 188, 0.54)",
                                    }}
                                 >
                                    Agregar nueva entidad bancaria  
                                 </span>
                                 <span
                                    style={{
                                          fontWeight: "400",
                                          fontSize: "14px",
                                          color: "rgba(69, 69, 188, 0.54)",
                                    }}
                                 >
                                        +
                                 </span>
                              </button>
                        )}
                        {activeTab === 1 && (
                              <button
                                 className="custom-button"
                                 onClick={() => setVisible2(true)}
                                 style={{
                                    background: "#FFFFFF",
                                    borderRadius: "9px",
                                    border: "none",
                                    margin: "0 auto",
                                    padding: "14px 12px",
                                    cursor: "pointer",
                                    width: "100%", // Para que ocupe todo el ancho del contenedor padre
                                    maxWidth: "100rem", // Opcional: establecer un ancho máximo
                                 }}
                              >
                                 <span
                                    style={{
                                          fontWeight: "400",
                                          fontSize: "15px",
                                          color: "rgba(69, 69, 188, 0.54)",
                                    }}
                                 >
                                    Agregar nueva entidad electrónica
                                 </span>
                                 <span
                                    style={{
                                          fontWeight: "400",
                                          fontSize: "14px",
                                          color: "rgba(69, 69, 188, 0.54)",
                                    }}
                                 >
                                    +
                                 </span>
                              </button>
                        )}
                  </div>
               </div>
            </div>

            <div className="card flex justify-content-center">
               <Dialog
                  header={
                     <>
                        <div className="flex flex-row gap-3 align-items-center">
                           <div
                              className="flex"
                              style={{
                                 width: "60px",
                                 height: "60px",
                                 background: "#EBEBFF",
                                 borderRadius: "10px",
                              }}
                           >
                              <img
                                 src={RucIcon}
                                 alt=""
                              />
                           </div>
                           <p
                              style={{
                                 fontWeight: "700",
                                 fontSize: "18px",
                                 color: "#5555D8",
                              }}
                           >
                              Crear Nuevo método de pago
                           </p>
                        </div>
                     </>
                  }
                  visible={visible}
                  className="card-buton"
                  style={{ width: "30vw", minWidth: "500px" }}
                  onHide={() => setVisible(false)}
               >
                  <div className="card">
                     <div className="flex flex-column gap-3 mb-4">
                        <div className="flex-auto">
                           <label
                              htmlFor="num"
                              className="font-bold block mb-2"
                           >
                              Entidad Bancaria
                           </label>
                           <Dropdown
                              value={entidadPago?.entidad_bancaria}
                              onChange={(e) => handleChangeEntidadBancaria(e)}
                              options={entidadesBancarias}
                              optionLabel="nombre"
                              placeholder="Seleccionar tipo de Tarjeta"
                              className="w-full"
                           />
                        </div>
                        <div className="flex-auto">
                           <label
                              htmlFor="num"
                              className="font-bold block mb-2"
                           >
                              Número de Cuenta
                           </label>
                           <textarea
                              id="num"
                              style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                              keyfilter="num"
                              className="w-full text-area"
                              value={entidadPago?.numero_cuenta}
                              onChange={(e) =>
                                 handleChangeNumeroCuentaBancaria(e)
                              }
                           />
                        </div>
                        <div className="flex-auto">
                           <label
                              htmlFor="num"
                              className="font-bold block mb-2"
                           >
                              CCI
                           </label>
                           <textarea
                              id="num"
                              style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                              keyfilter="num"
                              className="w-full text-area"
                              value={entidadPago?.cci}
                              onChange={(e) => handleChangeCCI(e)}
                           />
                        </div>
                        <div className="card flex flex-wrap justify-content-end gap-3 butons-card">
                           <Button
                              label="Guardar"
                              onClick={() => { handleCreate(entidadPago, limpiarEntidadBancaria, CerrarModal); show() }}
                              className="p-button-success p-button-raised"
                           />
                           <Button
                              label="Cancelar"
                              onClick={() => setVisible(false)}
                              severity="danger"
                              className="p-button-danger p-button-outlined"
                              aria-label="Close"
                           />
                        </div>
                     </div>
                  </div>
               </Dialog>
               <Dialog
                  header={
                     <>
                        <div className="flex flex-row gap-3 align-items-center">
                           <div
                              className="flex"
                              style={{
                                 width: "60px",
                                 height: "60px",
                                 background: "#EBEBFF",
                                 borderRadius: "10px",
                              }}
                           >
                              <img
                                 src={RucIcon}
                                 alt=""
                              />
                           </div>
                           <p
                              style={{
                                 fontWeight: "700",
                                 fontSize: "18px",
                                 color: "#5555D8",
                              }}
                           >
                              Crear Nuevo método de pago electronico
                           </p>
                        </div>
                     </>
                  }
                  visible={visible2}
                  className="card-buton"
                  style={{ width: "30vw", minWidth: "500px" }}
                  onHide={() => setVisible2(false)}
               >
                  <div className="card">
                     <div className="flex flex-column gap-3 mb-4">
                        <div className="flex-auto">
                           <label
                              htmlFor="num"
                              className="font-bold block mb-2"
                           >
                              Entidad Electronica
                           </label>
                           <Dropdown
                              value={
                                 entidadpagoElectronico?.entidad_electronica
                              }
                              onChange={(e) =>
                                 handleChangeEntidadElectronica(e)
                                 //  setDataTypeEntity(e.value)
                              }
                              options={dataTypeEntity}
                              optionLabel="nombre"
                              placeholder="Seleccionar tipo de Pago"
                              className="w-full"
                           />
                        </div>
                        <div className="flex-auto">
                           <label
                              htmlFor="num"
                              className="font-bold block mb-2"
                           >
                              Numero de Cuenta
                           </label>
                           <textarea
                              id="num"
                              style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                              keyfilter="num"
                              className="w-full text-area"
                              value={entidadPagoElectronico?.numero_cuenta}
                              onChange={(e) =>
                                 handleChangeNumeroCuentaElectronica(e)
                              }
                           />
                        </div>
                        <div className="card flex flex-wrap justify-content-end gap-3 butons-card">
                           <Button
                              label="Guardar"
                              onClick={() => { handleCreateElectronica(); show(toast.current.show) }}
                              className="p-button-success p-button-raised"
                           />
                           <Button
                              label="Cancelar"
                              onClick={() => setVisible2(false)}
                              severity="danger"
                              className="p-button-danger p-button-outlined"
                              aria-label="Close"
                           />
                        </div>
                     </div>
                  </div>
               </Dialog>
            </div>
         </div>
      );
   }
}