import Hospital2 from "../../../../../Images/Hospital2 1.png";

const CardAreas = (locales) => {
  console.log("LOCALITEM", locales)

  return (
    <div className="flex flex-column  gap-2 pl-3 pr-3 pt-3 w-full h-6 cardlocal">
      <div className="flex w-full h-full gap-2">
        <img
          src={Hospital2}
          alt=""
          className="border-round-lg"
          style={{
            height: "7vmin",
            padding: "5px",
            backgroundColor: "#EBEBFF",
          }}
        />
        <div className="flex flex-column h-full w-full">
          <div className="flex flex-column gap-1">
            <p className=" local1" style={{ fontSize: "2vh", width: "150px" }}>
              {locales?.locales?.nombre}
            </p>
            <p className=" eucap" style={{ fontSize: "1.4vh" }}>
              {locales?.locales?.direccion}
            </p>
          </div>
          {/* <div className="flex flex-column h-full justify-content-evenly">
            {/* <p className="calendario" style={{ fontSize: "1.4vh" }}>
              Fecha de creación: 14/02/2023
            </p>
            <div className="flex gap-1 align-items-baseline pt-2">
              {/* <p className="numerotra " style={{ fontSize: "1.4vh" }}>
                Número de trabajadores
              </p>
              <input type="text" className="totaltrabajadores w-3rem" />
            </div>
          </div> */}
        </div>
        <div className="flex justify-content-end pl-1 relative w-full p-0 m-0 pt-4" >
          <div
            className="flex absolute justify-content-between align-items-baseline bg-teal-100  px-2 border-round-2xl rigth-0 -mt-2 "
            style={{ width: "12vh"}}
          >
            <i
              className="pi pi-circle-fill text-cyan-300"
              style={{ fontSize: "1.3vh" }}
            ></i>
            <p className="text-cyan-300 pr-1" style={{ fontSize: "1.5vh" }}>
              Habilitado{" "}
            </p>
          </div>
        </div>
      </div>

    </div>

  );
};
export default CardAreas;
