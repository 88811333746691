//REACT
import React, { useContext, useState } from "react";
//PRIMEREACT
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
//OTHERS
import { SalesContext } from "../../SalesContext/SalesContext";
import FileInputButton from "../../../Ventas/FileInputButton/FileInputButton";
import InputCalendar from '../../../form/InputCalendar';
import axios from "axios";
import { Dropdown } from 'primereact/dropdown';


export default function S2ContactCreate({ crud, convertDate, ResetContacts, visibleCreate }) {
   // Estados para almacenar los datos del formulario y la respuesta de la API
   const [numDocumento, setNumDocumento] = useState('');
   const [nombres, setNombres] = useState('Escribir nombre completo...');
   const [apellidoPaterno, setApellidoPaterno] = useState('Escribir Apellido paterno...');
   const [apellidoMaterno, setApellidoMaterno] = useState('Escribir Materno paterno...');

   const [numDocumento_disa, setnumDocumento_disa] = useState(false);
   // Función para manejar el evento de clic en el botón "Consultar"
   const handleSubmit = () => {
      // Realiza la solicitud GET a la API con el número de documento ingresado
      axios
         .get(`https://dniruc.apisperu.com/api/v1/dni/${numDocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
         .then((res) => {
            // Actualiza los estados con los datos obtenidos de la API
            setNombres(res.data.nombres);
            setApellidoPaterno(res.data.apellidoPaterno);
            setApellidoMaterno(res.data.apellidoMaterno);
            setnumDocumento_disa(true);

            setSales(prevState => ({
               ...prevState,
               GCContacto: {
                  ...prevState.GCContacto,
                  numero_documento: numDocumento,
                  nombres: res.data.nombres,
                  apellido_paterno: res.data.apellidoPaterno,
                  apellido_materno: res.data.apellidoMaterno,
               }
            }));

         })
         .catch((error) => {
            // Manejo de errores: muestra un mensaje de error y limpia los campos
            console.error(error);

         });
   };
   // const handlefur = (e) => {
   //    if (e?.value) {
   //       let fecha = e?.value;
   //       const anio = fecha.getFullYear();
   //       const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
   //       const dia = fecha.getDate().toString().padStart(2, '0');
   //       const fechaFormateada = `${anio}-${mes}-${dia}`;

   //       setFur(fecha);
   //       setFurDate(e?.value);
   //       setAntGinContext({ ...antGinContext, fur: fechaFormateada, fur_date: e?.value })

   //    }
   // }



   //#region VARIABLES DE INSTANCIA
   const { sales, setSales } = useContext(SalesContext);
   //#endregion

   //#region VARIABLES
   const [visible, setVisible] = useState(false);
   const [selectedImage, setSelectedImage] = useState('https://i.ibb.co/4SHrqm4/user-male.png');
   //#endregion

   //#region FUNCIONES
   const onchangeInputs = (e) => {
      const { name, value } = e.target;
      // Actualiza el estado 'sales' con el valor del campo correspondiente
      setSales(prevState => ({
         ...prevState,
         GCContacto: {
            ...prevState.GCContacto,
            [name]: value
         }
      }));
   };
   const onchangeFechaNacimiento = (e) => {
      if(e?.value){
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
      
      
      setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, fecha_nacimiento: fechaFormateada } }));

      }
      
   };
   const handleFileChange = (selectedFiles) => {
      if (selectedFiles.length > 0) {
         const reader = new FileReader();
         reader.onload = () => {
            setSelectedImage(reader.result);
         };
         reader.readAsDataURL(selectedFiles[0]);
      } else {
         setSelectedImage(null);
      }
   }
   const onchangeFile = (file) => {
      setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, foto: file } }));
   }
   //#endregion

   //#region FUNCIÓN DE ENVÍO DE DATOS
   const sendCreate = async (e) => {
      e.preventDefault();
      const resp = await crud("crear");
      if (resp) {
         setVisible(false);
         ResetContacts();
         setSelectedImage('https://i.ibb.co/4SHrqm4/user-male.png');
         setNombres('');
         setApellidoPaterno('');
         setApellidoMaterno('');
      }
   }
   //#endregion

   const headerTemplate = () => {
      return (
         <div className="flex justify-content-start gap-3 align-items-center">
            <span className="flex align-items-center justify-content-center bg-indigo-200 p-2 border-round-lg">
               <img
                  style={{ height: "1.6rem", width: "1.6rem" }}
                  src="https://i.ibb.co/NZLzLsJ/Icon.png"
                  alt="img"
                  draggable={false}
               />
            </span>
            <p className="text-indigo-600 text-lg font-bold">Crear Nuevo Contacto</p>
         </div>
      );
   };

   const [selectOps, setSelectOps] = useState(null);
   const Ops = [
      { name: 'DNI', code: 1 },
      { name: 'Carnet de Extranjeria', code: null},
      { name: 'Pasaporte', code: null},
   ];

   return (
      <>
         <button
            onClick={() => {
               setVisible(true);
               ResetContacts();
               setSelectedImage('https://i.ibb.co/4SHrqm4/user-male.png');
            }}
            style={{ margin: "20px", height: "auto", width:"auto"}}
            //Effect_Shine
            className={`cursor-pointer border-2 border-dashed border-round-lg flex flex-1 flex-column bg-white p-3 gap-2 border-round-lg align-items-center justify-content-center border-indigo-500 ${visible ? `ventas_create_active` : `ventas_create`}`}>
            <i className="pi pi-plus-circle text-indigo-600 font-bold text-xl"></i>
            <p className="font-bold text-indigo-600 text-base">
               Agregar Nuevo Contacto
            </p>
         </button>

         <Dialog
            header={headerTemplate}
            visible={visible}
            style={{ width: '765px', height: '754px' }}
            onHide={() => setVisible(false)}
            className="empresas"
         >
            <form onSubmit={sendCreate}
               className="text-sm flex flex-column gap-3 w-full mt-3">
               <div className="flex flex-wrap gap-3 ventas">
                  <div className="flex-1 flex flex-column gap-3">
                     <div className="flex flex-column gap-2 w-100">
                        <label className="font-bold" htmlFor="nombres">Tipos Documento</label>
                        <Dropdown
                            value={Ops[Ops.findIndex(ele=>ele.code==sales?.GCContacto?.tipo_documento_id)]}
                            onChange={(e) => {setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, "tipo_documento_id":e.value.code} }));}}
                           options={Ops}
                           optionLabel="name"
                           placeholder="Tipo de Documento"
                           className="w-full"
                           name="tipo_documento_id"
                        />
                     </div>
                     <div className="flex flex-column gap-2 w-full">
                        <label className="font-bold" htmlFor="numero_documento">Número Documento</label>
                        <div className="flex">

                           <InputText
                              value={sales?.GCContacto?.numero_documento}
                              onChange={(e) => {setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, "numero_documento":e.target.value} }));}}
                              name="numero_documento"
                              id="numero_documento"
                              placeholder="12345678"
                              className="w-full"
                  
                           />
                        </div>
                     </div>
                     <div><Button label="Consultar" onClick={handleSubmit} type="button" disabled={sales?.GCContacto?.tipo_documento_id !== 1} /></div>
                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="nombres">Nombres</label>
                        <InputText
                           // value={nombres}
                           onChange={(e) => onchangeInputs(e)}
                           name="nombres"
                           id="nombres"
                           placeholder={nombres}
                           className="p-inputtext-sm"
                           disabled={numDocumento_disa}
                           style={{ userSelect: 'none' }}
                        />

                     </div>


                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="apellido_paterno">Apellido Paterno</label>
                        <InputText
                           // value={apellidoPaterno}
                           // onChange={(e) => onchangeInputs(e)}
                           name="apellido_paterno"
                           id="apellido_paterno"
                           placeholder={apellidoPaterno}
                           className="p-inputtext-sm"
                           disabled={numDocumento_disa}
                           style={{ userSelect: 'none' }}


                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="apellido_materno">Apellido Materno</label>
                        <InputText
                           // value={apellidoMaterno}
                           placeholder={apellidoMaterno}
                           // onChange={(e) => onchangeInputs(e)}
                           name="apellido_materno"
                           id="apellido_materno"
                           className="p-inputtext-sm"
                           disabled={numDocumento_disa}
                           style={{ userSelect: 'none' }}

                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="cargo">Cargo</label>
                        <InputText
                           value={sales.GCContacto.cargo}
                           onChange={(e) => onchangeInputs(e)}
                           name="cargo"
                           id="cargo"
                           placeholder="Escribir cargo..."
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="celular">Celular</label>
                        <InputNumber
                           value={sales.GCContacto.celular}
                           onValueChange={(e) => onchangeInputs(e)}
                           useGrouping={false}
                           name="celular"
                           id="celular"
                           placeholder="987654321"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="correo">Correo</label>
                        <InputText
                           value={sales.GCContacto.correo}
                           onChange={(e) => onchangeInputs(e)}
                           type="email"
                           name="correo"
                           id="correo"
                           placeholder="example@example.com"
                           className="p-inputtext-sm"
                        />
                     </div>




                  </div>


                  <div className="flex-1 flex flex-column gap-3">
                     <label className="font-bold" htmlFor="foto" >Foto</label>

                     <div className="flex justify-content-center">
                        <span className="flex align-items-center justify-content-center bg-indigo-200 p-2 border-circle">
                           {selectedImage && (
                              <img
                                 className="border-circle"
                                 width="202px"
                                 height="202px"
                                 style={{ objectFit: "contain" }}
                                 src={selectedImage} alt="user-male"
                                 draggable={false}
                              />
                           )}
                        </span>
                     </div>

                     <div className="flex align-items-center justify-content-center ">
                        <FileInputButton
                           onChange={(e) => handleFileChange(e)}
                           file={(e) => onchangeFile(e)}
                           accept="image/*"
                           className="bg-green-200 border-green-200 text-sm font-normal text-green-600"
                           type="button"
                           label="Cambiar imagen"
                           icon="pi pi-camera text-green-600"
                           iconPos="right"
                           inputId="foto"
                        />
                     </div>

                     <label className="flex align-items-center gap-2 font-bold mt-7" htmlFor="comentarios">
                        <p>Comentarios</p>
                        <i className="pi pi-pencil text-sm" />
                     </label>
                     <InputTextarea
                        value={sales.GCContacto.comentario}
                        onChange={(e) => onchangeInputs(e)}
                        className="ventas-scroll"
                        id="comentarios"
                        name="comentarios"
                        style={{ resize: 'none', height: "6.8rem" }}
                        placeholder="Ingresa un comentario"
                        maxLength={100}
                     />
                     <div className="flex flex-column gap-2 ">
                        <label className="font-bold" htmlFor="fecha_nacimiento">Cumpleaños</label>
                        <InputCalendar
                           value={convertDate(sales.GCContacto.fecha_nacimiento)}
                           onChange={(e) => onchangeFechaNacimiento(e)}
                           name="fecha_nacimiento"
                           id="fecha_nacimiento"
                           inputId="fecha_nacimiento"
                           showIcon
                           showButtonBar
                           placeholder="dd/mm/yy" dateFormat="dd/mm/yy"
                           className="p-inputtext-sm ventas text-sm"
                           
                        />
                     </div>
                     <div className="flex justify-content-between gap-2 mt-5">
                        <Button
                           onClick={() => {
                              setVisible(false);
                              ResetContacts();
                           }}
                           type='button'
                           label="Cancelar"
                           className="font-normal bg-white border-gray-500 flex-1 text-sm p-2 text-gray-800 "
                        />
                        <Button
                           type="submit"
                           className="flex-1 text-sm p-2"
                           label="Agregar"
                        />
                     </div>
                  </div>
               </div>
            </form>
         </Dialog>
      </>
   );
}