import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import Maletaaa from "../../../Imagenes/img/Maletaaa.png";
import { Button } from "primereact/button";
import ComponentTablaServiciCliente from "./ComponentClinica";
import { InputText } from "primereact/inputtext";
import "../../../Style/estilo3.css";
import { useNavigate } from "react-router-dom";


export default function ComponentpaqueteClinica({ servicio, setMostrarModalEliminar, setPaqueteSeleccionado }) {
   console.log("servicio",servicio);
   // Dialog editar
   const navigate = useNavigate();
   const [visible, setVisible] = useState(false);
   const footerContent = (
      <div className="flex" style={{ justifyContent: "end" }}>
         <Button
            label="Guardar"
            onClick={() => setVisible(false)}
            autoFocus
            style={{ display: "flex", fontSize: "14px", fontWeight: "500" }}
         />
      </div>
   );

   return (
      <div /* className="col-12 md:col-6 lg:col-3" */>
         {/* Contenedor */}
         <div
            className="Contenedor flex flex-column"
            style={{
               height: "271px",
               width: "100%",
               //  maxWidth: '346px',
               minWidth: "160px",
               margin: "auto",
               background: "#FFFFFF",
               borderRadius: "21px",
               boxShadow: "0px 2px 3px rgb(211, 208, 208)",
            }}
         >
            {/* Parte superior */}
            <div
               className="ContenedorParteSuperior flex gap-3"
               style={{ padding: "20px", alignItems: "center" }}
            >
               {/* img maleta */}
               <div
                  style={{
                     display: "flex",
                     width: "62.18px",
                     height: "62.18px",
                     background: "#EBEBFF",
                     borderRadius: "50px",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <img
                     src={Maletaaa}
                     alt="new img"
                     style={{ width: "48px", height: "44px" }}
                  />
               </div>
               {/* nombre del paquete mas el de la emrpesa */}
               <div>
                  <h3
                     style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#5555D8",
                     }}
                  >
                     {servicio?.paquete?.nombre}
                  </h3>
               </div>
            </div>
            {/* Parte inferior */}
            <div
               style={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingBottom: "20px",
                  borderBottom: "1px solid #B5B5B5",
               }}
            >
               {/* numero de perfiles */}
               <div
                  className="numedoperfil flex"
                  style={{ alignItems: "center" }}
               >
                  <h6
                     className="numero flex-1"
                     style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#7B8794",
                        alignItems: "center",
                     }}
                  >
                     N° de perfiles
                  </h6>
                  <h6
                     className="cantidad flex flex-1"
                     style={{
                        background: "#F6F7FB",
                        borderRadius: "25px",
                        justifyContent: "end",
                        height: "23px",
                        alignItems: "center",
                        fontSize: "14px",
                        paddingRight: "20px",
                     }}
                  >
                     {servicio?.perfil_tipos?.length ?? "0"}
                  </h6>
               </div>
               {/* numero de empresas */}
               <div
                  className="listaempresa flex"
                  style={{ alignItems: "center", marginTop: "20px" }}
               >
                  
               </div>
            </div>
            {/* Botones Eliminar, editar y detalles */}
            <div
               className="flex"
               style={{
                  justifyContent: "space-between",
                  padding: "15px 10px 0 10px",
                  width: "auto",
                  maxWidth: "340",
               }}
            >
               <div className="flex gap-1">
                  <Button
                     className="p-button-text p-button-danger"
                     icon="pi pi-trash"
                     style={{
                        background: "#FFECEC",
                        width: "40px",
                        height: "35px",
                     }}
                     onClick={() => {setMostrarModalEliminar(true)
                        setPaqueteSeleccionado(servicio)}}
                  />
                  <Button
                     className="p-button-text p-button-success"
                     icon="pi pi-pencil"
                     style={{
                        background: "#BFF1DF",
                        width: "40px",
                        height: "35px",
                     }}
                     onClick={() => {
                        navigate(`/productosyservicios/servicios/editar/${servicio.paquete.id}`)
                        /* setVisible(true) */
                     }}
                  />
                  {/* Dialog Editar */}
                  <Dialog
                     visible={visible}
                     style={{ width: "80vw" }}
                     onHide={() => setVisible(false)}
                     footer={footerContent}
                  >
                     <div>
                        {/* Titulo paquete */}
                        <header
                           className="gap-3"
                           style={{ display: "flex", alignItems: "center" }}
                        >
                           <h1
                              style={{
                                 fontWeight: "700",
                                 fontFamily: "Plus Jakarta Sans",
                                 fontSize: "17px",
                                 color: "#565656",
                              }}
                           >
                              Nombre:
                           </h1>
                           <h2
                              style={{
                                 fontWeight: "600",
                                 fontFamily: "Montserrat",
                                 fontSize: "16px",
                                 color: "#393838",
                              }}
                           >
                              Paquete Anual - MR 2023
                           </h2>
                        </header>
                        {/* Subtitulo */}
                        <div
                           className="flex"
                           style={{
                              justifyContent: "space-between",
                              paddingBottom: "20px",
                           }}
                        >
                           {/* Tipo de servicio */}
                           <div
                              className="flex gap-3"
                              style={{ alignItems: "center" }}
                           >
                              <h1
                                 style={{
                                    fontWeight: "700",
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "17px",
                                    color: "#565656",
                                 }}
                              >
                                 Tipo de servicio:
                              </h1>
                              <select
                                 style={{
                                    width: "345px",
                                    height: "37px",
                                    borderRadius: "7px",
                                    background: "rgba(235, 235, 235, 0.5)",
                                    borderColor: "transparent",
                                    fontWeight: "300",
                                    fontSize: "18px",
                                    textAlign: "center",
                                    color: "rgba(69, 69, 188, 0.54)",
                                 }}
                              >
                                 <option value="Exámenes ocupacionales">
                                    Exámenes ocupacionales
                                 </option>
                                 <option value=""></option>
                              </select>
                           </div>
                           {/* Buscador */}
                           <div className="flex">
                              <span className="p-input-icon-left">
                                 <i className="pi pi-search" />
                                 <InputText
                                    placeholder="Buscador"
                                    style={{
                                       height: "46px",
                                       background: "rgba(217, 217, 217, 0.3)",
                                       borderColor: "transparent",
                                    }}
                                 />
                              </span>
                           </div>
                        </div>
                        {/* tabla */}
                        <div style={{ paddingBottom: "20px" }}>
                           <ComponentTablaServiciCliente />
                        </div>
                        <div
                           style={{
                              borderBottom:
                                 "0.5px solid rgba(136, 136, 142, 0.6)",
                              paddingBottom: "20px",
                              fontSize: "14px",
                              fontWeight: "500",
                           }}
                        >
                           <Button
                              label="Agregar nuevo perfil"
                              className="p-button-outlined "
                           />  
                        </div>
                     </div>
                  </Dialog>
               </div>
               <div>
                  <Button
                     className="p-button-text"
                     icon="pi pi-info-circle"
                     label="Ver detalles"
                     style={{
                        background: "#F6F2FC",
                        width: "auto",
                        maxWidth: "136px",
                        height: "35px",
                        color: "#8C63E3",
                        fontWeight: "700",
                        fontSize: "13.5px",
                     }}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
