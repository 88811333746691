import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import AuthUser from "../../../AuthUser";
import "../../../Style/stylePerfil.css";
import { Skeleton } from "primereact/skeleton";
import img1 from "../../../Images/Perfil/icon/ESTADISTICAS.svg";
import { useSelector } from "react-redux";

export default function DetraccionComponent({ detracciones, getbregma }) {

  //declaracion de VARIABLES
  const isLoading = useSelector((state) => { return state.isLoading; });
  const { http } = AuthUser();
  const [visible, setVisible] = useState(false);
  const [errror, setErrror] = useState(false);
  const [detraccion, setDetraccion] = useState(null);
  //end declaracion

  // consultas API
  const handleDestroy = () => {
    http
      .delete(`detraccion/destroy/${detraccion.id}`)
      .then((response) => { 
        console.log('detraccion eliminada');
      })
      .catch((error) => {
        console.error("error", error);
        toast.current.show({ 
          severity: "error",
          summary: "Error",
          detail: "Ha ocurrido un error al eliminar",
        });
      })
  }

  const handleCreate = () => { 
    http
      .post("detraccion/create", detraccion)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Guardado correctamente",
          detail: "Se guardó la cuenta de detracción",
        });
        getbregma();
        setVisible(false);
      })
      .catch((error) => {
        console.error("error", error);
        toast.current.show({
          severity: "error",
          summary: "Error", 
          detail: "Ha ocurrido un error al crear",
        });
      })
  }

  //end consultas API

  //FUNCIONES
  const handleEdit = () => {
    if (detracciones) {
      /*crear detraccion */
      handleCreate()
    } else {
      /*eliminar y luego crear detraccion */
      handleDestroy();
      handleCreate()
    };
  };

  const handleCerrarModalEditar = () => {
    setVisible(false)
    setDetraccion({ ...detraccion, numero_cuenta: detracciones[0]?.numero_cuenta })
  }

  const handleChangeNumeroCuenta = (e) => {
    setDetraccion({ ...detraccion, numero_cuenta: e.target.value });
  };

  //end funciones

  const toast = useRef(null);

  useEffect(() => {
    setDetraccion({
      id: detracciones[0]?.id,
      numero_cuenta: detracciones[0]?.numero_cuenta
    })
  }, [detracciones]);


  const loader = () => {
    return (
      <div
        className="wrapperdb flex flex-wrap w-full pb-4"
        style={{ height: "72px" }}
      >
        <div className="right flex flex-row w-full">
          <div className="info flex flex-wrap w-full">
            <h3 className="flex flex-wrap w-full " style={{ marginTop: "10px", marginLeft: "10px" }}>
              <Skeleton width="355px" height="38px" borderRadius="16px"></Skeleton>
            </h3>
          </div>
          <div className="flex flex-wrap" style={{ marginTop: "10px" }}>
            <Skeleton width="228px" height="38px" borderRadius="16px"></Skeleton>
          </div>
        </div>
      </div>
    );
  };
  const footerContent = (
    <div className="flex flex-wrap justify-content-end gap-3">
      <Button label="Cancelar" severity="danger" outlined className="p-button-danger p-button-outlined" aria-label="Close" onClick={() => handleCerrarModalEditar()} />
      <Button label="Guardar" onClick={handleEdit} className="p-button-success p-button-raised" />
    </div>
  );

  const EstiloLetraSub = {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: "#4545BC"
  };    

  if (isLoading) {
    return loader();
  } else {
    return (
      <div style={{ backgroundColor:'white',borderRadius:'10px',height:'60px', marginBottom: "10px", display:'flex', alignItems:'center', justifyContent:'center', padding:'0'}}>
        <div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0px 5px 0px 20px'}}>
          <h3 className="flex flex-wrap w-full titulos text-base" style={{color: "#4545BC" }} >Cuenta de Detracciones</h3>
          <div className="" style={{width:'auto', height:'100%',display:'flex', alignItems:'center', justifyContent:'center'}}>
              <h6 style={{...EstiloLetraSub, marginRight:'20px'}}>{detracciones[0]?.numero_cuenta}</h6>
              <Button label="" icon="pi pi-pencil"
              className="p-button-text p-button-success"
              style={{ height: "40px", width: "40px", marginRight:'5px', background: "#BFF1DF"}}
              onClick={() => setVisible(true)} />
          </div>
        </div>

        <div>
          {/*MODAL DETRACCION */}
          <Dialog
            header={
              <>
                <div className="flex flex-column gap-3">
                  <div className="flex flex-column" style={{ width: "60px", height: "60px", borderRadius: "10px", background: "#EBEBFF", padding: "10px" }}>
                    <img src={img1} />
                  </div>
                  <h1 style={{ fontFamily: "Montserrat", fontWeight: "700", fontSize: "18px", color: "#5555D8" }}>Detracción</h1>
                </div>
              </>
            }
            visible={visible} style={{ width: "30vw", minWidth: "300px" }}
            footer={footerContent}
            onHide={() => handleCerrarModalEditar()}>
            <div className="">
              <div className="flex flex-column gap-3 mt-2">
                <div className="flex-auto">
                  <label htmlFor="num" className="font-bold block">
                    Número de Cuenta
                  </label>
                  <InputText id="num" keyfilter="num" className="w-full" value={detraccion?.numero_cuenta || ""} onChange={handleChangeNumeroCuenta} placeholder="Escribe aquí para actualizar el número de cuenta" />
                  {errror === false ? ''
                    :
                    <div>
                      error
                    </div>}
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        <Toast ref={toast} />
      </div>
    );
  }
}