import React, { useContext, useState } from 'react';
import { InputText } from "primereact/inputtext";
import InputCalendar from '../../../Components/form/InputCalendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import '../../../Style/Triaje/AntecedenteGin/PageTriajeAntGin.css'
import { MGContext } from '../../MedicinaGeneral/MGProvider';
import { RAContext } from "../../RutaAtencion/RAContext";
import Calendar02 from '../../../Components/form/Calendar02';

const PageTriajeAntGin = () => {
   let { antGinContext, setAntGinContext } = useContext(MGContext);
   let { stateAtencion, pageViewContext } = useContext(RAContext);
   //ant ginecologicos
   const [fur, setFur] = useState(antGinContext.fur ?? null)
   const [furDate, setFurDate] = useState(antGinContext.fur_date ?? null)
   const [fup, setFup] = useState(antGinContext.fup ?? null)
   const [ultPap, setUltPap] = useState(antGinContext.ultimopap ?? null)
   const [ultPapDate, setUltPapDate] = useState(antGinContext.ultimopap_date ?? null)
   //mamografia
   const [fechaMamografia, setFechaMamografia] = useState(antGinContext.mamografia.fecha ?? null)
   const [fechaMamografiaDate, setFechaMamografiaDate] = useState(antGinContext.mamografia.fecha_date ?? null)
   //Gestazzciones
   const [fechaCesaria, setFechaCesaria] = useState(antGinContext.gestaciones.fecha_cesarea ?? null)
   const [fechaCesariaDate, setFechaCesariaDate] = useState(antGinContext.gestaciones.fecha_cesarea_date ?? null)
   console.log("ag", antGinContext)

   const estados = [{ label: 'Normal', value: true },
   { label: 'Anormal', value: false }]

   let monarquias = [
      { label: 'Si', value: true },
      { label: 'No', value: false }
   ]

   const handlefur = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;

         setFur(fecha);
         setFurDate(e?.value);
         setAntGinContext({ ...antGinContext, fur: fechaFormateada, fur_date: e?.value })

      }
   }

   const handlefup = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         console.log(fechaFormateada);
         setFup(fechaFormateada);
         setAntGinContext({ ...antGinContext, fup: fechaFormateada, fup_date: e?.value })

      }
   }

   const handleUltPap = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         console.log(fechaFormateada);
         setAntGinContext({ ...antGinContext, ultimopap: fechaFormateada, ultimopap_date: e?.value })

         setUltPap(fechaFormateada);
         setUltPapDate(e?.value);
      }
   }

   const handleMamografiaChange = (value) => {
      // Si la opción seleccionada es "No", limpiamos la fecha y el estado
      if (value === false) {
         console.log("d")
         setAntGinContext({ ...antGinContext, mamografia: { estado: null, fecha: null, fecha_date: null, resultado: "", se_hizo: false } })
         // Limpiar el valor seleccionado en el campo de estado cuando se selecciona "NO"

      } else {
         setAntGinContext({ ...antGinContext, mamografia: { ...antGinContext.mamografia, se_hizo: value } })

      }
   };

   const handleMamografiaDateChange = (e) => {
      setFechaMamografiaDate(e.value);

      // Actualizamos la fecha de la mamografía
      if (e.value) {
         let fecha = e.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         console.log(fechaFormateada);
         setFechaMamografia(fechaFormateada);
         setAntGinContext({ ...antGinContext, mamografia: { ...antGinContext.mamografia, fecha: fechaFormateada, fecha_date: e.value } })

      } else {

      }
   };

   const handlecesarea = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         setFechaCesaria(fechaFormateada);
         console.log(fechaFormateada);
         setFechaCesariaDate(e?.value);
         setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext.gestaciones, fecha_cesarea: fechaFormateada, fecha_cesarea_date: e?.value } })

      }
   }
   const updateFur = (newFur) => {
      setAntGinContext(prevState => ({
         ...prevState,
         fur: newFur
      }));
   };
   const updateFup = (newFur) => {
      setAntGinContext(prevState => ({
         ...prevState,
         fup: newFur
      }));
   };
   const updateUltimopap = (newFur) => {
      setAntGinContext(prevState => ({
         ...prevState,
         ultimopap: newFur
      }));
   };
   const updateFechaMamografia = (newFecha) => {
      setAntGinContext(prevState => ({
         ...prevState,
         mamografia: {
            ...prevState.mamografia,
            fecha: newFecha
         }
      }));
   };
   const updateFechaCecaria = (newFecha) => {
      setAntGinContext(prevState => ({
         ...prevState,
         gestaciones: {
            ...prevState.gestaciones,
            fecha_cesarea: newFecha
         }
      }));
   };



   return (
      <div className='scroll-container'>
         <div className=' flex flex-wrap gap-6'>
            <div className='flex flex-wrap w-full gap-5 pt-3'>
               <div className='flex flex-wrap gap-5 flex-row w-12' style={{ flexWrap: 'wrap' }}>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>FUR</label>

                     <Calendar02
                        val={antGinContext?.fur}
                        update={updateFur} showIcon />
                     {/* <InputCalendar placeholder="dd/mm/aaaa" value={antGinContext?.fur_date} onChange={(e) => handlefur(e)} disabled={stateAtencion[pageViewContext].estado === 3} style={{ minWidth: '200px' }} /> */}
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column " style={{ minWidth: '200px' }}>
                     <label className='texto_A-Ginecologico' style={{ minWidth: '200px', justifyContent: "left" }}>RC</label>
                     <InputText value={antGinContext?.rc} onChange={(e) => setAntGinContext({ ...antGinContext, rc: e.target.value })} disabled={stateAtencion[pageViewContext].estado === 3} style={{ minWidth: '200px' }} />
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>FUP</label>
                     <Calendar02
                        val={antGinContext?.fup}
                        update={updateFup} showIcon />
                     {/* <InputCalendar value={antGinContext?.fup_date} onChange={(e) => handlefup(e)} disabled={stateAtencion[pageViewContext].estado === 3} style={{ minWidth: '200px' }} /> */}
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Menarquia</label>
                     <InputText value={antGinContext?.menarquia} onChange={(e) => setAntGinContext({ ...antGinContext, menarquia: e.target.value })} disabled={stateAtencion[pageViewContext].estado === 3} style={{ minWidth: '200px' }} />
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Último PAP</label>
                     <Calendar02
                        val={antGinContext?.ultimopap}
                        update={updateUltimopap} showIcon />
                     {/* <InputCalendar value={antGinContext?.ultimopap_date} onChange={(e) => handleUltPap(e)} disabled={stateAtencion[pageViewContext].estado === 3} style={{ minWidth: '200px' }} /> */}
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Estado</label>
                     <Dropdown value={antGinContext?.estado} onChange={(e) => setAntGinContext({ ...antGinContext, estado: e.target.value })} options={estados}
                        placeholder="Selecione un estado" disabled={stateAtencion[pageViewContext].estado === 3} style={{ minWidth: '200px' }} />
                  </div>
               </div>
            </div>
            <div className=' flex flex-wrap gap-5'>
               <div className='flex flex-wrap gap-3 w-full'>
                  <div className='flex flex-wrap w-12'>
                     <h2>Mamografía</h2>
                  </div>
                  <div className='flex flex-wrap gap-5 w-12' style={{ flexWrap: 'wrap' }}>
                     <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                        <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Mamografía</label>
                        <Dropdown
                           value={antGinContext?.mamografia?.se_hizo}
                           onChange={(e) => handleMamografiaChange(e.value)}
                           options={monarquias}
                           placeholder="Seleccione una opción"
                           disabled={stateAtencion[pageViewContext].estado === 3}
                           style={{ minWidth: '200px' }}
                        />
                     </div>
                     <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                        <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Fecha de mamografía</label>
                        {/* <InputCalendar
                           disabled={antGinContext?.mamografia?.se_hizo === false || stateAtencion[pageViewContext].estado === 3}
                           value={antGinContext?.mamografia?.fecha_date}
                           onChange={(e) => handleMamografiaDateChange(e)}
                           showIcon
                           id="spanish"
                           placeholder='dd/mm/aaaa'
                           yearRange="1900:2100"
                           locale="es"
                           dateFormat="dd/mm/yy"
                           showButtonBar
                        /> */}
                        <Calendar02
                           val={antGinContext?.mamografia?.fecha}
                           update={updateFechaMamografia} showIcon />
                     </div>
                     <div className="flex flex-wrap gap-2 flex-1 flex-column">
                        <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Estado</label>
                        <Dropdown
                           value={antGinContext?.mamografia?.estado}
                           onChange={(e) => setAntGinContext({ ...antGinContext, mamografia: { ...antGinContext?.mamografia, estado: e?.value } }
                           )}
                           options={estados}
                           placeholder="Seleccione un estado"
                           disabled={antGinContext?.mamografia?.se_hizo === false || stateAtencion[pageViewContext].estado === 3} // Deshabilitar cuando mamografia es "NO"
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className='flex flex-wrap w-full flex-column gap-2'>
               <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Resultado</label>
               <InputText disabled={antGinContext?.mamografia?.se_hizo === false || stateAtencion[pageViewContext].estado === 3} value={antGinContext?.mamografia?.resultado} onChange={(e) => { setAntGinContext({ ...antGinContext, mamografia: { ...antGinContext?.mamografia, resultado: e.target.value } }) }} />
            </div>
         </div>

         <div className=' flex flex-wrap gap-3 w-full' style={{ marginTop: '1.5rem' }}>
            <div className='flex flex-wrap gap-5 w-full'>
               <h2>Gestaciones</h2>
            </div>

            <div className='flex flex-wrap gap-5 w-full'>
               <div className='flex flex-wrap gap-5 w-full'>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column">
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Gestaciones</label>
                     <InputText value={antGinContext?.gestaciones?.gestaciones} onChange={(e) => setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext?.gestaciones, gestaciones: e.target.value } })} disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column">
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Aborto</label>
                     <InputText value={antGinContext?.gestaciones?.abortos} onChange={(e) => setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext?.gestaciones, abortos: e.target.value } })} disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
               </div>
               <div className='flex flex-wrap gap-5 w-full'>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column">
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Partos</label>
                     <InputText value={antGinContext?.gestaciones?.partos} onChange={(e) => setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext?.gestaciones, partos: e.target.value } })} disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column">
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>P. Prematuros</label>
                     <InputText value={antGinContext?.gestaciones?.p_prematuros} onChange={(e) => setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext?.gestaciones, p_prematuros: e.target.value } })} disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column">
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>P. Eutócico</label>
                     <InputText value={antGinContext?.gestaciones?.p_eutacico} onChange={(e) => setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext?.gestaciones, p_eutacico: e.target.value } })} disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column">
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>P. Distocias</label>
                     <InputText value={antGinContext?.gestaciones?.p_distocias} onChange={(e) => setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext?.gestaciones, p_distocias: e.target.value } })} disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>

                  <div className="flex flex-wrap gap-2 flex-1 flex-column">
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Cesáreas</label>
                     <InputText value={antGinContext?.gestaciones?.cesareas} onChange={(e) => setAntGinContext({ ...antGinContext, gestaciones: { ...antGinContext?.gestaciones, cesareas: e.target.value } })} disabled={stateAtencion[pageViewContext].estado === 3} />
                  </div>
                  <div className="flex flex-wrap gap-2 flex-1 flex-column" style={{ minWidth: '200px' }}>
                     <label className='texto_A-Ginecologico' style={{ justifyContent: "left" }}>Fecha de cesárea</label>
                     <Calendar02
                        val={antGinContext?.gestaciones?.fecha_cesarea}
                        update={updateFechaCecaria} showIcon />
                     {/* <InputCalendar value={antGinContext?.gestaciones?.fecha_cesarea_date} onChange={(e) => handlecesarea(e)} disabled={stateAtencion[pageViewContext].estado === 3} /> */}
                  </div>
               </div>
            </div>


         </div>
         <div className='flex flex-wrap gap-3 flex-column w-12' style={{ minWidth: '200px', marginTop: '1.5rem' }}>
            <h2 >Métodos Anticonceptivos</h2>

            <div className="flex flex-wrap gap-2 flex-column w-12"  >
               <InputTextarea autoResize rows={5} cols={25} value={antGinContext?.metodos_anticonceptivos} onChange={(e) => setAntGinContext({ ...antGinContext, metodos_anticonceptivos: e.target.value })}
                  disabled={stateAtencion[pageViewContext].estado === 3}
                  style={{ minWidth: '80px' }} />
            </div>

         </div>

      </div>
   );
};


export default PageTriajeAntGin;