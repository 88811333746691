import React, { useContext, useEffect, useState } from "react";
import "../../../Style/style5.css";
import "../../../Style/Button.css";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import LaboratorioListComponent from "../../../Components/Laboratorio/container/laboratorio_list";
import DialogArchivoComponent from "../../../Components/Laboratorio/container/dialog_archivo";
import AuthUser from "../../../AuthUser";
import { LaboratorioContext } from "../LaboratorioProvider";
import { Task } from "@mui/icons-material";

export default function Hemograma({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
   const { http } = AuthUser();

   const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);

   const [selectedConclusion, setSelectedConclusion] = useState(null);
   const conclusion = [
      { name: 'Normal', code: 'NL' },
      { name: 'Anormal', code: 'AL' },
   ];
   const [value, setValue] = useState('');
   const [prueba, setPrueba] = useState({})
   const [nombreArchivo, setNombreArchivo] = useState(null);
   const [file, setFile] = useState(null);
   const [indexSup, setIndexSup] = useState(0);
   const [indexSub, setIndexSub] = useState(0);
   const [parametros, setParametros] = useState([
      {
         codigo: 1,
         prueba: 'Hemoglobina',
         resultado: 0,
         um: 'g/dl',
         rango_final: 14,
         rango_inicial: 12,
         valor_normal: '12.00 - 14.00'
      },
      {
         codigo: 2,
         prueba: 'Hematocrito',
         resultado: 0,
         um: '%',
         rango_final: 55,
         rango_inicial: 35,
         valor_normal: '35.00 - 55.00'
      },
      {
         codigo: 3,
         prueba: 'Leucocitos totales',
         resultado: 0,
         um: '(10 * 3)',
         rango_final: 10,
         rango_inicial: 5,
         valor_normal: ' 5.00 - 10.00'
      },
      {
         codigo: 4,
         prueba: 'MID %',
         resultado: 0,
         um: '%',
         rango_final: 15,
         rango_inicial: 3,
         valor_normal: ' 3 - 15'
      },
      {
         codigo: 5,
         prueba: 'Abastonados',
         resultado: 0,
         um: '%',
         rango_final: 5,
         rango_inicial: 0,
         valor_normal: ' 0.00 - 5.00'
      },
      {
         codigo: 6,
         prueba: 'Neutrófilo Segmentados',
         resultado: 0,
         um: '%',
         rango_final: 66,
         rango_inicial: 35,
         valor_normal: '35.0 - 66.0'
      },
      {
         codigo: 7,
         prueba: 'Eosinófilos',
         resultado: 0,
         um: '%',
         rango_final: 3,
         rango_inicial: 0,
         valor_normal: '0.00 - 3.00'
      },
      {
         codigo: 8,
         prueba: 'Basófilos',
         resultado: 0,
         um: '%',
         rango_final: 2,
         rango_inicial: 0,
         valor_normal: '0.00 - 2.00'
      },
      {
         codigo: 9,
         prueba: 'Monocitos',
         resultado: 0,
         um: '%',
         rango_final: 8,
         rango_inicial: 4,
         valor_normal: '4.00 - 8.00'
      },
      {
         codigo: 10,
         prueba: 'Linfocitos',
         resultado: 0,
         um: '%',
         rango_final: 44,
         rango_inicial: 24,
         valor_normal: '24.00 - 44.00'
      },
      {
         codigo: 11,
         prueba: 'Glóbulos rojos',
         resultado: 0,
         um: '(10 * 6)',
         rango_final: 6.2,
         rango_inicial: 4,
         valor_normal: '4.0 - 6.2'
      },
      {
         codigo: 12,
         prueba: 'Plaquetas',
         resultado: 0,
         um: '(10 * 3)',
         rango_final: 400,
         rango_inicial: 150,
         valor_normal: '150.00 - 400.00'
      },
      {
         codigo: 13,
         prueba: 'VPM',
         resultado: 0,
         um: 'UM3',
         rango_final: 11,
         rango_inicial: 7,
         valor_normal: '7.00 - 11.00'
      },
      {
         codigo: 14,
         prueba: 'VCM',
         resultado: 0,
         um: 'fl',
         rango_final: 100,
         rango_inicial: 80,
         valor_normal: '80 - 100'
      },
      {
         codigo: 15,
         prueba: 'HCM',
         resultado: 0,
         um: 'pg',
         rango_final: 34,
         rango_inicial: 26,
         valor_normal: '26 - 34'
      },
      {
         codigo: 16,
         prueba: 'CHCM',
         resultado: 0,
         um: '%',
         rango_final: 35,
         rango_inicial: 31,
         valor_normal: '31.0 - 35.5'
      },
      {
         observaciones: "",
         seleccion: ""
      }

   ]);

   const handleCreate = () => {
      const formData = new FormData();
      formData.append("examen_id", tipoExamen);
      formData.append("prueba[examen_id]", tipoPrueba);
      formData.append("prueba[estado]", 1);
      formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
      formData.append("prueba[cantidad_parametros]", parametros?.length);
      formData.append("prueba[parametros]", JSON.stringify(parametros));
      formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));

      http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
         .then(response => {
            console.log(response)
            showToast("success","Creado Correctamente",response?.data?.resp)
         })
         .catch(error => {
            console.log(error)
            showToast("Error","Error de Guardar",error?.data?.resp)
         })
   }

   const changeExamenContext = (superior, inferior, auxParametros) => {
      setExamenesContext(examenesContext.map((elementoSup, index) => {
         if (index === superior) {
            let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
               if (indexAux === inferior) {
                  return {
                     ...prueba,
                     parametros: auxParametros,
                     estado: 1,
                     cantidad_parametros: auxParametros?.length,
                     perfil: auxParametros?.length > 1 ? 2 : 1
                  }
               }
               return prueba;
            })
            return { ...elementoSup, pruebas: auxPrueba };
         }
         return elementoSup;
      }))
   }

   const handleInputChange = (index, e) => {
      const tempTask = [...parametros];
      tempTask[index].resultado = e.value;
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };

   const handleInputInicial = (index, e) => {
      const tempTask = [...parametros];
      tempTask[index].rango_inicial = e;
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };
   const handleInputFinal = (index, e) => {
      const tempTask = [...parametros];
      tempTask[index].rango_final = e;
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };
   const handleObservaciones = (index, e) => {
      const tempTask = [...parametros];
      if (!tempTask[index]) {
         tempTask.push({
            observaciones: "",
            seleccion: ""
         })


      }
      tempTask[16]["observaciones"] = e;
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };
   const handleSelec = (index, e) => {
      const tempTask = [...parametros];
      if (!tempTask[index]) {
         tempTask.push({
            observaciones: "",
            seleccion: ""
         })


      }
      tempTask[index].seleccion = e;
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };

   useEffect(() => {
      console.log(examenesContext)
      let sup = 0;
      let inf = 0;
      let auxPruebas = examenesContext.find((examen, index) => {
         if (examen.examen_id === tipoExamen) {
            setIndexSup(index);
            sup = index;
            console.log(index)
         }
         return examen.examen_id === tipoExamen
      });
      let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
         if (prueba.examen_id === tipoPrueba) {
            setIndexSub(index);
            inf = index;
            console.log(index)
         }
         return prueba.examen_id === tipoPrueba
      });
      if (auxPrueba?.archivo) {
         setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
      }
      setPrueba(auxPrueba);
      console.log("PRUEBA", auxPrueba)
      console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
      let auxParametros = typeof auxPrueba?.parametros === 'string' ? JSON.parse(auxPrueba?.parametros) : auxPrueba?.parametros
      auxPrueba?.parametros && setParametros(auxParametros);
      changeExamenContext(sup, inf, auxParametros);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="flex flex-column w-full h-full" style={{ padding: '20px' }}>
         <div className="flex flex-column w-full">
            <LaboratorioListComponent
               handleInputChange={handleInputChange}
               handleInputFinal={handleInputFinal}
               handleInputInicial={handleInputInicial}
               parametros={parametros}
            />
         </div>
         <div className="flex flex-column gap-3">
            <div>
               <p style={{ fontWeight: '700', fontSize: '16px' }}>Observaciones</p>
               <div>
                  <InputTextarea value={examenesContext?.[4]?.pruebas?.[0]?.parametros?.[16]?.observaciones}
                     onChange={(e) => handleObservaciones(16, e.target.value)} rows={2} className="w-full" />

               </div>
            </div>
            <div className="flex flex-row gap-3 w-full" style={{ alignItems: 'center' }}>
               <p style={{ borderLeft: "0px", fontWeight: '700', fontSize: '16px' }}>Conclusión médica</p>
               <Dropdown
                  value={conclusion[examenesContext?.[4]?.pruebas?.[0]?.parametros?.[16]?.seleccion == "NL" ? 0 : 1]}
                  onChange={(e) => handleSelec(16, e.value.code)}
                  options={conclusion}
                  optionLabel="name"
                  placeholder="Normal" />
            </div>
         </div>
         <DialogArchivoComponent
            file={file}
            handleCreate={handleCreate}
            nombreArchivo={nombreArchivo}
            prueba={prueba}
            setFile={setFile}
            setNombreArchivo={setNombreArchivo}
         />
      </div>
   );
}
