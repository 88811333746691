import React, { useState } from 'react';
import Todonoti from './Todonoti.jsx';
import Noleido from './Noleido.jsx';
import { InputText } from 'primereact/inputtext';
import SplitButton from './SplitButton.jsx';
import { Divider } from 'primereact/divider';
import "../../Style/Card.css";

const Card = () => {
  const [activeTab, setActiveTab] = useState('todo');
  const [previousTab, setPreviousTab] = useState(null);

  const handleTabChange = (tab) => {
    setPreviousTab(activeTab);
    setActiveTab(tab);
  };

  const handleMenuOptionClick = (tab) => {
    setPreviousTab(activeTab);
    setActiveTab(tab);
  };

  const renderContent = () => {
    if (activeTab === 'todo') {
      return (
        <div>
          <Todonoti />
        </div>
      );
    } else if (activeTab === 'unread') {
      return (
        <div>
          <Noleido />
        </div>
      );
    }
  };

  const menuOptions = [
    {
      label: 'Marcar como leído',
      tab: 'option1',
    },
    {
      label: 'Descartar',
      tab: 'option2',
    },
    {
      label: 'Avisar más tarde',
      tab: 'option3',
    },
  ];

  const splitButtonOptions = {
    icon: 'pi pi-ellipsis-v',
    menuItems: menuOptions,
    onOptionClick: handleMenuOptionClick,
  };

  return (
    <div>
      <div className="flex flex-column">
        <h1 className="titulo">Notificaciones</h1>
        <p className="subtitulo">
          En este módulo usted podrá observar las alertas, enviados y comentarios.
        </p>
      </div>
      <div className="cardnoti">
        <div className="cardnoti-header">
          <div className="flex align-items-center">
            <div style={{ marginRight: "10vh" }}>
              <SplitButton options={splitButtonOptions} menuPosition="rigth" />
            </div>
            <div className="tabs">
              <button
                className={activeTab === 'todo' ? 'active' : ''}
                onClick={() => handleTabChange('todo')}
              >
                Todo
              </button>
              <button
                className={activeTab === 'unread' ? 'active' : ''}
                onClick={() => handleTabChange('unread')}
              >
                No leídas
              </button>
            </div>
          </div>
          <span className="p-input-icon-left ">
            <i className="pi pi-search" />
            <InputText placeholder="Buscador" className='buscarnoti' />
          </span>
        </div>
        <Divider type="solid" className='lineanoti' />
        <div className="cardnoti-body">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Card;
