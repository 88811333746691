import React, { useContext, useRef, useState, useEffect } from "react";
import AuthUser from "../../AuthUser";
import { Button } from "primereact/button";
import { PsicologiaContext } from "./PsicologiaContext";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import PagePsiDatOcup from "./DatosOcupacionales/PagePsiDatOcup";
import PagePsiExpLab from "./ExperienciaLaboral/PagePsiExpLab";
import PageExaMent from "./ExamenMental/PageExaMent";
import PagePsiProCog from "./ProcesoCognitivo/PagePsiProCog";
import PagePsiPruebas from "./PruebasPsicologicas/PagePsiPruebas";
import PagePsiPreguntas from "./Preguntas/PagePsiPreguntas";
import PagePsiDiag from "./Diagnostico/PagePsiDiag";
import PageOtros from "./PageOtros";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconPsicologia } from "../../Services/importIcons";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
import { useParams } from 'react-router-dom';
import { RAContext } from "../../Page/RutaAtencion/RAContext";


const PagePsicologia = () => {
   const [imprimirVisible, setImprimirVisible] = useState(false);
   const { http } = AuthUser();
   const { idPaciente } = useParams();
   const [activeIndex, setActiveIndex] = useState(0);
   console.log("id del pacienteeeeeeeeee", idPaciente);

   let { datOcupContext, setDatOcupContext } = useContext(PsicologiaContext);
   let { expLabContext, setExpLabContext } = useContext(PsicologiaContext);
   let { exaMenContext, setExaMenContext } = useContext(PsicologiaContext);
   let { proCogContext, setProCogContext } = useContext(PsicologiaContext);
   let { pruPsiContext, setPruPsiContext } = useContext(PsicologiaContext);
   let { pregContext, setPregContext } = useContext(PsicologiaContext);
   let { diagContext } = useContext(PsicologiaContext);
   // let { otrosContext } = useContext(PsicologiaContext)

   const [savingStatus, setSavingStatus] = useState(false);

   let { atencionStatus, setAtencionStatus } = useContext(PsicologiaContext)
   let { setTimeStart } = useContext(PsicologiaContext)
   let { pacienteContext, setPacienteContext, pageViewContext, stateAtencion, setStateAtencion } = useContext(RAContext)
   console.log(stateAtencion[pageViewContext].estado)


   const toast = useRef(null);
   const finallyAtencion = (st) => {
      let actualizacion = stateAtencion.map((area, index) => {
         if (pageViewContext === index) {
            return { estado: st, titulo: area.titulo, icon: area.icon }
         } else {
            return area
         }
      }
      )
      setStateAtencion(actualizacion)
   }
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const getExperiencia = (id_psicologia) => {
      http
         .get("psicologia/experiencialaboral/get")
         .then((response) => {
            if (response?.data?.resp) {
               console.log("datosssss experienciaaaa", response.data.data);
               let newData = response?.data?.data?.filter(data => data.psicologia_id === id_psicologia)
               setExpLabContext(newData);
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getDatosOcupacionales = () => {
      http
         .get("psicologia/datosocupacionales/get")
         .then((response) => {
            if (response?.data?.resp) {
               console.log("datosssss ocupacionalesss", response.data.data);
               setDatOcupContext(response.data.data[0]);
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getDatosExamenMental = () => {
      http
         .get("psicologia/psiexamenmental/get")
         .then((response) => {
            if (response?.data?.resp) {
               console.log("datosssss examen mentallll", response.data[0]);
               let newData = {
                  ...response.data[0],
                  articulacion: Number(response.data[0].articulacion),
                  espacio: Number(response.data[0].espacio),
                  coordinacion_visomotriz: Number(response.data[0].coordinacion_visomotriz),
                  persona: Number(response.data[0].persona),
                  postura: Number(response.data[0].postura),
                  presentacion: Number(response.data[0].presentacion),
                  ritmo: Number(response.data[0].ritmo),
                  tiempo: Number(response.data[0].tiempo),
                  tono: Number(response.data[0].tono)
               }
               setExaMenContext(newData)
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getPruebasPsicologicas = () => {
      http
         .get("psicologia/psipruebaspsicologicas/get")
         .then((response) => {
            console.log("datosssss Pruebas psicologicaaaas", {
               pruebas: response.data.data
                  .filter((prueba) => prueba.psicologia_id === pacienteContext.psicologia_id)
                  .map((pr) => ({ psi_prueba_id: pr.psi_prueba_id }))
            });
            setPruPsiContext({
               pruebas: response.data.data
                  .filter((prueba) => prueba.psicologia_id === pacienteContext.psicologia_id)
                  .map((pr) => ({ psi_prueba_id: pr.psi_prueba_id }))
            });
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getPreguntas = () => {
      http
         .get("psicologia/psipreguntas/get")
         .then((response) => {
            if (response?.data?.data) {
               console.log("datosssss Preguntas", response.data[0]);
               let newData = {
                  ...response.data[0],
                  evaluacion_emocional: Number(response.data[0].evaluacion_emocional),
                  evaluacion_organica: Number(response.data[0].evaluacion_organica),
                  evaluacion_psicopatologica: Number(response.data[0].evaluacion_psicopatologica),
                  sano_mentalmente: Number(response.data[0].sano_mentalmente),
                  test_fatiga: Number(response.data[0].test_fatiga),
                  test_somnolenda: Number(response.data[0].test_somnolenda),
                  tipo_estres: Number(response.data[0].tipo_estres)
               }
               setPregContext(newData);
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getPacientePisicologia = () => {
      http
         .get("psicologia/get")
         .then((response) => {
            console.log("datosssss psicologiaaa", response.data)
            // let DataGeneral = response.data.filter((datsd) => datsd.id === pacienteContext.psicologia_id)[0];
            // if (DataGeneral.datos_ocupacionales) setDatOcupContext(DataGeneral.datos_ocupacionales);
            // if (DataGeneral.examen_mental) {
            //    let newDataExamenMental = {
            //      ...DataGeneral.examen_mental,
            //      articulacion: Number(DataGeneral.examen_mental.articulacion),
            //      espacio: Number(DataGeneral.examen_mental.espacio),
            //      coordinacion_visomotriz: Number(DataGeneral.examen_mental.coordinacion_visomotriz),
            //      persona: Number(DataGeneral.examen_mental.persona),
            //      postura: Number(DataGeneral.examen_mental.postura),
            //      presentacion: Number(DataGeneral.examen_mental.presentacion),
            //      ritmo: Number(DataGeneral.examen_mental.ritmo),
            //      tiempo: Number(DataGeneral.examen_mental.tiempo),
            //      tono: Number(DataGeneral.examen_mental.tono)
            //    };
            //    setExaMenContext(newDataExamenMental);
            //  }
            //  if (DataGeneral.proceso_cognoscitivo) setProCogContext(DataGeneral.proceso_cognoscitivo)
            //  if (DataGeneral.preguntas){
            //    let newData = {
            //       ...DataGeneral.preguntas,
            //       evaluacion_emocional: Number(DataGeneral.preguntas.evaluacion_emocional),
            //       evaluacion_organica: Number(DataGeneral.preguntas.evaluacion_organica),
            //       evaluacion_psicopatologica: Number(DataGeneral.preguntas.evaluacion_psicopatologica),
            //       sano_mentalmente: Number(DataGeneral.preguntas.sano_mentalmente),
            //       test_fatiga: Number(DataGeneral.preguntas.test_fatiga),
            //       test_somnolenda: Number(DataGeneral.preguntas.test_somnolenda),
            //       tipo_estres: Number(DataGeneral.preguntas.tipo_estres)
            //    }
            //    setPregContext(newData);
            // }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      getPacientePisicologia();
      if (pacienteContext?.psicologia_id) {
         console.log(pacienteContext.psicologia_id)
         getExperiencia(pacienteContext.psicologia_id);
         getPruebasPsicologicas();
      }

   }, []);


   const datosOcupacionalesCreate = (idPsicologia) => {
      http
         .post(
            `psicologia/datosocupacionales/create/${idPsicologia}`,
            datOcupContext
         )
         .then(() => {
            console.log("Datos Ocupacionales guardado");
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Ocupacionales");
         });
   };

   const experienciaLaboralCreate = (idPsicologia) => {
      let newData = {
         experiencia_laboral: expLabContext
      };
      http
         .post(
            `psicologia/experiencialaboral/create/${idPsicologia}`,
            newData
         )
         .then(() => {
            console.log("Datos Ocupacionales guardado");
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Experiencia Laboral");
         });
   };

   const examenMentalCreate = (idPsicologia) => {
      http
         .post(`psicologia/psiexamenmental/create/${idPsicologia}`, exaMenContext)
         .then(() => {
            console.log("Datos Ocupacionales guardado");
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Examen Mental");
         });
   };
   const procesoCognoscitivoCreate = (idPsicologia) => {
      console.log("enviar Datos de procesos cognitivos", proCogContext)
      http
         .post(
            `psicologia/procesocognoscitivo/create/${idPsicologia}`,
            proCogContext
         )
         .then(() => {
            console.log("Datos Ocupacionales guardado");
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Preguntas Cog");
         });
   };
   const pruebasPsicologicas = (idPsicologia) => {
      http
         .post(
            `psicologia/psipruebaspsicologicas/create/${idPsicologia}`,
            pruPsiContext
         )
         .then(() => {
            console.log("Datos Ocupacionales guardado");
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Preguntas PSI");
         });
   };
   const preguntasCreate = (idPsicologia) => {
      http
         .post(`psicologia/psipreguntas/create/${idPsicologia}`, pregContext)
         .then(() => {
            console.log("Datos Ocupacionales guardado");
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Pregunta");
         });
   };

   const psicologiaCreate = () => {
      http
         .post(`psicologia/create`, { paciente_id: idPaciente })
         .then((response) => {

            if (response.data.Error) {
               throw new Error(response.data.Error);
            }
            console.log("Psicologia creada");
            setPacienteContext({ ...pacienteContext, psicologia_id: response.data.psicologia_id })
            showToast('success', 'Exito', response.data.resp);
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Pregunta");
            showToast('error', 'Error', error.message);
         });

   };
   const diagnosticoCreate = (idPsicologia) => {
      http
         .post(
            `psicologia/psidiagnosticofinal/create/${idPsicologia}`,
            diagContext
         )
         .then(() => {
            console.log("Datos Ocupacionales guardado");
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Diagnostico");
         });
   };

   const handleSubmit = () => {
      console.log("pacienteContext.psicologia_id", pacienteContext.psicologia_id)
      console.log("data a enviar", datOcupContext);
      console.log("data de diagnostico ", diagContext);
      console.log("data de P.Cognitivos ", proCogContext);
      console.log("data de preguntas ", pregContext);
      console.log("data de pruebasP ", pruPsiContext);
      console.log("data de examen mental ", exaMenContext);
      console.log("data de experiencia labolar ", expLabContext);


      const allFetching = [
         datosOcupacionalesCreate(pacienteContext.psicologia_id),
         experienciaLaboralCreate(pacienteContext.psicologia_id),
         examenMentalCreate(pacienteContext.psicologia_id),
         procesoCognoscitivoCreate(pacienteContext.psicologia_id),
         pruebasPsicologicas(pacienteContext.psicologia_id),
         preguntasCreate(pacienteContext.psicologia_id),
         diagnosticoCreate(pacienteContext.psicologia_id),
      ]

      Promise.allSettled(allFetching)
         .then((results) => {
            results.forEach((result) => console.log(result.status));
            showToast(
               "success",
               `Atención en Psicología guardada`,
               `Se guardó la atención correctamente`
            );
            setSavingStatus(false);
         })
         .catch(() => {
            setSavingStatus(false);
         })
   }

   const tabSubmitHeader = (options) => {
      return (
         <Button
            icon={savingStatus ? `pi pi-spin pi-spinner` : `pi pi-save`}
            label={savingStatus ? "Guardando ..." : options.titleElement}
            className="p-button-sm"
         />
      );
   };

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            <TabPanel header="Experiencia laboral">
               <PagePsiExpLab />
            </TabPanel>
            <TabPanel header="Datos Ocupacionales">
               <PagePsiDatOcup />
            </TabPanel>

            <TabPanel header="Examen mental">
               <PageExaMent />
            </TabPanel>
            <TabPanel header="Procesos Cognitivos">
               <PagePsiProCog />
            </TabPanel>
            <TabPanel header="Pruebas Psicológicas">
               <PagePsiPruebas />
            </TabPanel>
            <TabPanel header="Preguntas">
               <PagePsiPreguntas />
            </TabPanel>
            <TabPanel header="Diagnóstico">
               <PagePsiDiag />
            </TabPanel>
            <TabPanel header="Otros">
               <Accordion multiple>
                  <AccordionTab header="Pruebas">
                     <PageOtros />
                  </AccordionTab>
               </Accordion>
            </TabPanel>
         </TabView>
         <div
            style={{ borderRadius: "10px", height: "70px" }}
            className="flex justify-content-end gap-2 mt-2 bg-white"
         >
            <button
               onClick={handleSubmit}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-save"> </i>
               <span>Guardar</span>
            </button>
            <button
               style={{ color: "red" }}
               className="p-button  p-component btn-2 flex gap-1"
            >
               <i className="pi pi-times"></i>
               <span>Cancelar</span>
            </button>
            <button
               style={{ color: "black" }}
               onClick={() => setImprimirVisible(true)}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-print"></i>
               <span>Imprimir</span>
            </button>
         </div>
         <ModalImprimir
            imprimirVisible={imprimirVisible}
            setImprimirVisible={setImprimirVisible}
         />
      </>
   );
};

export default PagePsicologia;