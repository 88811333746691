import React, { useState, useEffect, useContext, useRef } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import DialogRetiro from "./Dialogs/DialogSalida";
import DialogPeriodico from "./Dialogs/DialogRutina";
import DialogIngreso from "./Dialogs/DialogEntrada";
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ServicioContext } from "../../../../Components/Clinica/ProductoServicio/Providers/ServicioProvider";
import AuthUser from "../../../../AuthUser";
import { transformData } from "../../../../Services/ProductosServicios/servicios";
import MultiLevelTreeSelectWithAPIClinica from "./SelectTipoServicioClinica";
import { Toast } from 'primereact/toast';


export default function PageAgregarServiciosB() {
   // DropDown
   const [tipo, setTipo] = useState(0)
   const [marcados, setMarcados] = useState([{ "id": 1, content: {} }])
   const toast = useRef(null);
   const navigate = useNavigate();
   const { http } = AuthUser()
   const [servicios, setServicios] = useState([])
   const { servicio, setServicio, rowData, setRowData } = useContext(ServicioContext)
   const [showInput, setShowInput] = useState(false);
   const [visibleExit, setVisibleExit] = useState(false);
   const [visibleRoutine, setVisibleRoutine] = useState(false);
   const [visibleEntrance, setVisibleEntrance] = useState(false);
   const [priceReference, setPriceReference] = useState(0)
   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [nombrePaquete, setNombrePaquete] = useState("")
   const [nombreValido, setNombreValido] = useState("")
   const [camposValidos, setCamposValidos] = useState("")
   const [nombreTipoPerfil, setNombreTipoPerfil] = useState("");
   const [IdSeleccionado, setIdSeleccionado] = useState(null)
   const [PerfilesCreados, setPerfile4sCreados]= useState([
      {
         Id:1,
         NombrePerfil:'',
         PrecioReferencial: 0,
         ItemsSeleccionados: [],
         ItemsSeleccionadosDetalles: []
      }
   ]);
   const [AbrirDialog, setAbrirDialog] = useState(false)
   const agregarNuevaSeccion = () => {
      const ultimoId = PerfilesCreados[PerfilesCreados.length - 1].Id;
   
      const nuevaSeccion = {
         Id: ultimoId + 1,
         NombrePerfil: ``,
         PrecioReferencial: 0,
         ItemsSeleccionados: []
      };
   
      setPerfile4sCreados([...PerfilesCreados, nuevaSeccion]);
   };

   const seleccionarIngreso = (rowData) => {
      return (
         <div style={{ display: 'flex', justifyContent: 'center' }}>
             <button
                 style={{ backgroundColor: Object.keys(marcados[rowData.id - 1 >= 0 ? rowData.id - 1 : 0]["content"]).length == 0 ? "white" : "rgb(235,235,255)" }}
                 className="add__container_main_content_body_table_td_btn_service"
                 onClick={() => {
                     setAbrirDialog(true);
                     setIdSeleccionado(rowData.Id);
                 }}
             >
                 Seleccionar
             </button>
         </div>
     );
 }
      const handleChangeNombreTipoPerfil = (event) => {
         setNombreTipoPerfil(event.target.value); // Aquí se actualiza el estado
         // Otra lógica que necesites...
      };
      const [NombreServicio, setNombreServicio] = useState("");

      function obtenerIndices(array) {
         const exclusiones = ["areas_medicas", "examenes"]; // Claves a excluir
         const indices = [];
         for (const key in array) {
             if (Object.hasOwnProperty.call(array, key) && !exclusiones.includes(key)) {
                 indices.push(key);
             }
         }
         return indices;
     }
     const DataEnviar = ({
      "nombre": NombreServicio,
      "clinica_servicio_id": selectedTipoServicio,
      "perfiles": PerfilesCreados.map(profile => {
        let ItemSelectKey = obtenerIndices(profile.ItemsSeleccionados);
        return {
          "nombre": profile.NombrePerfil,
          "areas_medicas": ItemSelectKey.filter(item => item.startsWith('1')).map(item => (
            {
               "id": item.slice(2), 
               "parcialmente": 1
            }
          )),
          "capacitaciones": [],
          "examenes": ItemSelectKey.filter(item => item.startsWith('3')).map(item => (
            {
               "id": item.slice(2), 
               "parcialmente": 1
            }
          ))
        };
      })
    });

   const tipoServicioId = "";
   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const handleTipoServicioSelect = tipoServicioId => {
      setSelectedTipoServicio(tipoServicioId);
      console.log("Selected Tipo Servicio in Page:", tipoServicioId);
   };



   const [perfiles, setPerfiles] = useState([{
      id: 1,
      nombre: "Nombre(click para cambiar)",
      ingreso: {},
      selectedIngreso: [],
   }
])

   // Transformar data

   const getServicios = () => {
      http.get('clinica/operaciones/servicio/get')
         .then((response) => {
            setServicios(transformData([response.data]))
            console.log("servicios", [response.data]);
            console.log("nodesTrandormados", transformData([response.data]))
         })
         .catch((error) => {
            console.error(error)
         })
   }
   const editarObjetoPorId = (id, nuevoMarcado) => {
      setMarcados((prevArrayObjetos) =>
         prevArrayObjetos.map((objeto) =>
            objeto.id === id
               ? {
                  ...objeto,
                  content: nuevoMarcado ? { ...nuevoMarcado } : {},
               }
               : objeto
         )
      );
   };
   const ordenar_datos = (a, b) => {
      if (a.id < b.id) {
         return -1;
      }
      if (a.id > b.id) {
         return 1;
      }
      return 0;
   }

   const seleccionarServicios = (e, setRowData, tipo, id) => {
      editarObjetoPorId(id, e)
      console.log("marca", e)
      let totalRefencial = 0;
      var keyNames = Object.keys(e);
      console.log("keyNames", keyNames)
      let areas_medicas = keyNames
         .filter((data) => data.charAt(0) === "1")
         .map((item) => {
            return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
         });
      let capacitaciones = keyNames
         .filter((data) => data.charAt(0) === "2")
         .map((item) => {
            return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
         });
      let examenes = keyNames
         .filter((data) => data.charAt(0) === "3")
         .map((item) => {
            return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
         });

      examenes = examenes.sort(ordenar_datos)
      capacitaciones = capacitaciones.sort(ordenar_datos)
      areas_medicas = areas_medicas.sort(ordenar_datos)
      console.log("examenes", examenes)
      console.log("areas_medicas", areas_medicas)
      console.log("clinica", selectedTipoServicio)
      const updatedPerfil = {
         ...rowData,
         clinica_servicio_id: selectedTipoServicio,
      };
      console.log("ROW", rowData)
      console.log("perfils", perfiles)
      const updatedPerfiles = perfiles.map(perfil => (perfil.id === rowData.id ? updatedPerfil : perfil));
      setRowData(updatedPerfil);
      setPerfiles(updatedPerfiles);
      setServicio({ ...servicio, clinica_servicio_id: selectedTipoServicio, perfiles: updatedPerfiles });
      console.log("stos son los servicios", servicio)
      console.log(areas_medicas, capacitaciones, examenes);
      setPriceReference(totalRefencial)
      const areas = {
         tipo_perfil_id: tipo,
         areas_medicas: areas_medicas,
         capacitaciones: capacitaciones,
         examenes: examenes,
      };
      var updatedList = {};
      console.log("tiiiipo", tipo)
      switch (tipo) {
         case '1':

            setRowData({ ...rowData, selectedIngreso: e });
            updatedList = perfiles.map((perfil) => {
               console.log(perfil.id, rowData.id)
               if (perfil.id === rowData.id) {
                  return {
                     ...perfil,

                     areas_medicas: areas_medicas,
                     capacitaciones: capacitaciones,
                     examenes: examenes,
                  }
               }
               return perfil;
            })
            break;

         default:
            break;
      }
      console.log("updatelista", updatedList);
      setPerfiles(updatedList);
      setServicio({ ...servicio, perfiles: updatedList })
   };
   const handleAgregar = () => {
      const newId = Math.max(...perfiles.map((perfil => perfil.id))) + 1
      console.log('newId: ', newId)
      const newPerfil = {
         id: newId,
         nombre: "Ingresar Nombre",
         selectedIngreso: [],
      }
      setPerfiles([...perfiles, newPerfil])
      const nuevoElemento = { "id": newId, content: {} };
      setMarcados((prevMarcados) => [...prevMarcados, nuevoElemento]);
      console.log("mas", marcados)
   };

   const handleChangeNombre = (event) => {
      setNombrePaquete(event.target.value);
      validarCampos();
      setServicio({ ...servicio, nombre: event.target.value });
   };

   const handleKeyDown = (e) => {
      if (e.key === "Escape") {
         setShowInput(false);
      }
   };

   useEffect(() => {
      getServicios();
      document.addEventListener("keydown", handleKeyDown);
      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
   }, []);


   const onCellEditComplete = (e) => {
      let { rowData, newValue, field, originalEvent: event } = e;
      rowData[field] = newValue;
      actualizarPerfil(rowData);
    };
    
    const actualizarPerfil = (perfilActualizado) => {
      setPerfiles(perfiles.map(perfil => perfil.id === perfilActualizado.id ? perfilActualizado : perfil));
    };

   const cellEditor = (options) => {
      return textEditor(options);
   };
   const textEditor = (options) => {
      return <InputText placeholder="tipo perfil" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} style={{ textAlign: 'center' }}/>;
    };



   const headerGroup = (
      <ColumnGroup >
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header="Servicios" field="thisYearSale" />
            <Column header="Precio Referencial" field="lastYearProfit" />

         </Row>
      </ColumnGroup>
   );

   const sendData = (data) => {
      console.log("verificar")
      http
         .post('clinica/paquete/particular/create', data)
         .then((response) => {
            console.log("creado correctamente", response);
            showToast('success', 'Success', 'El paquete fue creado correctamente')
            navigate('/productosyservicios/servicios')
         })
         .catch((error) => {
            console.error("algo salio mal", error);
            showToast('error', 'Error', 'El paquete no pudo ser creado correctamente')
         })
   }
   const filtrarPorPrefijo = (items, prefijo) => {
      console.log("Items recibidos:", items);
      console.log("Prefijo:", prefijo);
      
      const filteredItems = {};
      for (const key in items) {
          if (key.startsWith(prefijo)) {
              filteredItems[key] = items[key];
          }
      }
      
      console.log("Items filtrados:", filteredItems);
      
      return filteredItems;
  };
  

   const [datosEnvio, setDatosEnvio] = useState(null);
   
   const RellenarDatosEnvio = () => {
      setDatosEnvio({
        "nombre": NombreServicio,
        "clinica_servicio_id": selectedTipoServicio,
        "perfiles": PerfilesCreados.map(item => ({
          "nombre": item.NombrePerfil,
          "areas_medicas": Object.entries(item.ItemsSeleccionadosDetalles)
            .filter(([key, value]) => key.startsWith('1'))
            .reduce((obj, [key, value]) => {
              obj[key] = value;
              return obj;
            }, {}),
         //  "capacitaciones": [],
         //  "examenes": filtrarPorPrefijo(item.ItemsSeleccionadosDetalles, 3)
        }))
      });
    };
    
        

  const CrearServicio = (data) => {
    console.log("verificar");
    http
      .post('clinica/paquete/particular/create', data)
      .then((response) => {
        console.log("creado correctamente", response);
      })
      .catch((error) => {
        console.error("algo salio mal", error);
        showToast('error', 'Error', 'El paquete no pudo ser creado correctamente');
      });
  };

  useEffect(() => {
    if (datosEnvio != null) {
      CrearServicio(datosEnvio);
    }
  }, [datosEnvio]);

  const handleSubmit = () => {
   if (!NombreServicio.trim()) {
      console.log("Debe completar todos los campos obligatorios antes de enviar el formulario.")
      return; 
   }

   sendData(DataEnviar);
};
   const validarCampos = () => {
      const camposNombreValido = nombrePaquete.trim() !== '';
      // const camposServicioValido = selectedTreeSelectId !== null;
      // const camposSeleccionValida = selectedNodeKeys && typeof selectedNodeKeys === 'object' && Object.keys(selectedNodeKeys).length > 0;


      const camposSonValidos = camposNombreValido;
      // && camposServicioValido && camposSeleccionValida

      setNombreValido(camposNombreValido);
      // setServicioValido(camposServicioValido);
      // setSeleccionValida(camposSeleccionValida);
      setCamposValidos(camposSonValidos);
   };

   useEffect(() => {
      validarCampos();
   }, [nombrePaquete]);

   return (
      <div className="add__container">
         <Toast ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Nuevo Servicio - Particular</h4>
            <p className="add__container_header_p">
               Adminístralo a tu mejor manera
            </p>
         </div>
         <div className="add__container_main">
            <div className="add__container_main_content_header bg-white">
               <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Nombre:
                     </label>
                     <span className="p-input-icon-right">
                        <i className="pi pi-pencil" />
                        <InputText
                           placeholder="Paquete anual- Primax"
                           className="add__container_main_content_header_inputs_singular_input"
                           value={NombreServicio} onChange={(e) => setNombreServicio(e.target.value)}
                        />
                     </span>
                  </div>
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Tipo de servicio:
                     </label>
                     <MultiLevelTreeSelectWithAPIClinica
                        selectedTipoServicio={selectedTipoServicio}
                        onTipoServicioSelect={handleTipoServicioSelect}
                     />
                  </div>
               </div>
               <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText placeholder="Buscador" />
                     </span>
                  </div>
               </div>
            </div>
            { }
            <div className="add__container_main_content_body">
               <div style={{width:"75%", margin:"auto"}} className="add__container_main_content_body_container bg-white">
                  <ScrollPanel
                     style={{ width: "100%", maxHeight:'400px', padding: '1rem', alignItems: "center" }}
                     className="custombar2 p-scrollpanel-bar-table"
                  >
                     <div className="card p-fluid">
                        <DataTable
                           value={PerfilesCreados}
                           headerColumnGroup={headerGroup}
                           editMode="cell"
                           tableStyle={{ maxWidth: '100%' }}
                           size="small">
                           <Column className="columna_j" field="NombrePerfil" header="" style={{ width: '22%', textAlign:"center" }} 
                           editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                           <Column body={seleccionarIngreso} header="Servicios" ></Column>
                           <Column
                           field="PrecioReferencial"
                           header="Precio Referencial"
                           body={(rowData) => (
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <button
                                      className="p-button p-component p-button-text-icon-left p-button-text-icon-align-right p-button-outlined p-button-sm"
                                      style={{ marginLeft: '5px', marginRight: '5px', width: '100px', height: '30px' }}
                                      type="button"
                                  >
                                      <span className="p-button-text p-c" style={{ display: 'flex', alignItems: 'center' }}>
                                       <span style={{ marginRight: '5px' }}>S/.</span>
                                       {rowData.PrecioReferencial}
                                    </span>
                                 </button>
                              </div>
                          )}
                      />
                        </DataTable>
                     </div>


                     <DialogIngreso
                        Activador={AbrirDialog}
                        setActivador={setAbrirDialog}
                        DataActual={PerfilesCreados}
                        setDataActual={setPerfile4sCreados}
                        IdSelect={IdSeleccionado}
                        DataServicios={servicios}
                     />

                  </ScrollPanel>
               </div>
               <div style={{width:"75%", margin:"auto", marginTop:"12px"}}className="flex justify-content-between add__container_main_content_footer_two">
                  <button
                     className="add__container_main_content_footer_one_btn"
                     onClick={handleAgregar}

                  >
                     <i className="pi pi-plus bg-colo-btn"></i>
                     <p className="bg-colo-btn">Agregar nuevo perfil</p>
                  </button>
                  <div className="w-auto flex gap-2 ">
                  <button className="add__container_main_content_footer_two_btn ">
                     <Link
                        to="/productosyservicios/servicios/"
                        className="bg-colo-btn"
                        style={{ textDecoration: "none" }}
                     >
                        Cancelar
                     </Link>
                  </button>
                  <button className="button__add_new"
                     onClick={handleSubmit}>Guardar</button>
                     </div>
                  {/* <button onClick={()=>{console.log("Resultado: ", DataEnviar)}}>Ver Actual Datos tabla</button>
                  <button onClick={()=>{console.log("El dropdown es: ",selectedTipoServicio)}}>Ver el valor del drop</button> */}
                  {/* <button onClick={()=>{agregarNuevaSeccion()}}>Crear nuevo Perfil</button> */}
               </div>
            </div>
         </div>
      </div>
   );
} 