import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import AuthUser from "../../../AuthUser";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import "../../../Style/stylePerfil.css";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import zIndex from "@mui/material/styles/zIndex";
import RucIcon from "../../../Images/Iconperfil/Credit-Card.png"


export default function BilleteraElectronicaComponent({
   entidadPagoElectronico,
   tipo,
   getEntidadesElectronicas,
   getbregma
}) {
   const { http } = AuthUser();
   const [visible2, setVisible2] = useState(false);
   const [value, setValue] = useState("");
   const [visible, setVisible] = useState(false);
   const [errror, setErrror] = useState(false);
   const [detraccion, setDetraccion] = useState(null);
   const [entidadPagoUpdate, setEntidadPagoUpdate] = useState({
      id: 0,
      entidad_electronica_id: null,
      numero_cuenta: "",
      entidad_electronica: null,
   });

   const handleChangeEntidadElectronica = (e) => {
      setEntidadPagoUpdate({
         ...entidadPagoUpdate,
         entidad_electronica: e.target.value,
         entidad_electronica_id: e.target.value,
      });
   };
   const [updated, setUpdated] = useState(false);
 
   useEffect(() => {
      setEntidadPagoUpdate(entidadPagoElectronico);
      setUpdated(false);
      //console.log("entidad electronica", entidadPagoElectronico); 
   }, [entidadPagoElectronico]);

   const save = () => {
      toast01.current.show({
         severity: "succecss",
         sumary: "Success",
         detail: "Data Saved",
      });
   };
   const handleCreate = () => { 
      http
        .post("detraccion/create", detraccion)
        .then((response) => {
          toast01.current.show({
            severity: "success",
            summary: "Guardado correctamente",
            detail: "Se guardó la cuenta de detracción",
          });
          getbregma();
          setVisible(false);
        })
        .catch((error) => {
          console.error("error", error);
          toast01.current.show({
            severity: "error",
            summary: "Error", 
            detail: "Ha ocurrido un error al crear",
          });
        })
    }
  

   const numeroCuentaRef = useRef("");

   const saveCuentaElectronica = () => {
      const cuentaelectronica = {
         entidad_electronica_id: entidadPagoUpdate.entidad_electronica_id,
         numero_cuenta: numeroCuentaRef.current.value,
      };
     // console.log("cuenta a enviarrrr", cuentaelectronica);
      http
         .put(
            `entidad_electronica_pago/update/${entidadPagoElectronico.id}`,
            cuentaelectronica
         )
         .then((response) => {
            setVisible2(false);
            // getelectronica();
            //console.log("Actualización cuenta?electronica", response);
            setUpdated(true);
            getEntidadesElectronicas()
         })
         .catch((error) => {
            setVisible2(false);
            console.error(error);
         });
      showSuccessToast("Cuenta electronica guardada exitosamente ");
      setVisible2(false);
   };

   const toast01 = useRef(null);

   // Delete

   const [btndelete, setBtndelete] = useState(false);


   const showSuccessToast = (message,) => {
      toast01.current.show({
        severity: "success",
        summary: "Éxito",
        detail: message,
        
      });
    };
    

   const showErrorToast = (message) => {
      toast01.current.show({
         severity: "error",
         summary: "Error",
         detail: message,
      });
   };
   const toast = useRef(null);

   const show = () => {
      toast.current.show({ severity: 'success', summary: 'Info', detail: 'Message Content'});
  };

   const handleEliminarEntidad = () => {
      http
         .delete(`entidad_electronica_pago/delete/${entidadPagoElectronico.id}`)
         .then((response) => {
            setBtndelete(false);
            // getelectronica();
            //console.log(response.data.Resp);
            showSuccessToast("Registro eliminado exitosamente", 100); // Pasa la altura deseada como argumentos
            setUpdated(true);
            getEntidadesElectronicas()
         })
         .catch((error) => {
            setBtndelete(false);
            console.error(error);
            showErrorToast("Error al eliminar el registro");
         });
    };
    

   //console.log("logooooo", entidadPagoElectronico?.entidad_electronica?.logo)
   // Skeleton
   const [loading, setLoading] = useState(true);
   useEffect(() => {
      setTimeout(() => {
         setLoading(false);
      }, 2200);
   }, []);
   const loader = () => {
      return (
         <div>
            <div>
               <Skeleton
                  width="281px"
                  height="226px"
                  borderRadius="16px"
               ></Skeleton>
            </div>
         </div>
      );
   };

   if (loading) {
      return loader();
   } else {
      return (
         <div className="wrapperdbb p-1 px-3 m-3 max-with:2000px">
<Toast ref={toast01} />
<div className="flex flex-column justify-content-center align-items-center">
  <div className="flex flex-wrap justify-content-between gap-1 linea">
    <div className="flex w-8 flex-column justify-content-center align-items-center">
      <img
        src={entidadPagoElectronico?.entidad_electronica?.logo}
        alt="Entidad electronic"
        style={{
          padding: "20px",
          height: "110px",
          width: "130px",
          marginLeft: "-14px",
          marginTop: "-10px",
        }}
      />
    </div>
    <Button
      icon="pi pi-trash"
      onClick={() => setBtndelete(true)}
      severity="danger"
      outlined="true"
      className="p-button-danger p-button-text "
      style={{
        width: "30px",
        height: "30px",
        marginTop: "10px",
        marginRight: "-5px",
        backgroundColor: "#FFECEC",
      }}
    />
    <Button
      icon="pi pi-pencil"
      onClick={() => setVisible2(true)}
      className="p-button-success p-button-text "
      style={{
        width: "30px",
        height: "30px",
        marginTop: "10px",
        marginRight: "-5px",
        backgroundColor: "#BFF1DF",
      }}
    />
  </div>
  <div>
    <div>
      <div
        className="linea"
        style={{ borderBottom: "1px solid transparent" }}
      >
        <h4 className="tituloCuentas text-base">
          Número de celular
          <br />
        </h4>
        <h6 className="numero text-sm">
          {entidadPagoElectronico?.numero_cuenta}
        </h6>
      </div>
    </div>
  </div>
</div>


            <div className="card flex justify-content-center">
               <Dialog
                  visible={btndelete}
                  onHide={() => setBtndelete(false)}
                  className="card-buton"
                  // footer={footerContent}
                  header={
                     <>
                        <div className="flex flex-row gap-3 align-items-center titulo-eliminar">
                           <div
                              className="flex"
                              style={{
                                 width: "60px",
                                 height: "60px",
                                 background: "#EBEBFF",
                                 borderRadius: "10px",
                              }}
                           >
                              <img
                                 src={RucIcon}
                                 alt=""
                              />
                           </div>
                           <p
                              style={{
                                 fontWeight: "700",
                                 fontSize: "24px",
                                 color: "#5555D8",
                              }}
                           >
                              Eliminar Tarjeta
                           </p>
                        </div>
                     </>
                  }
                  style={{ width: "30vw", minWidth: "500px", maxWidth: "446px",}}
               >
                  <div className="h-full card mb-0">
                     <div className="flex justify-content-between h-full flex-column gap-3">
                        <div className="flex ">
                           <label htmlFor="num" className="flex justify-content-center" ></label>
                        </div>
                        <div className="flex w-full bot gap-3 justify-content-center butons-card">
                           <Button
                              onClick={() => handleEliminarEntidad() }
                              className="p-button-danger"
                              label="Borrar"
                              style={{
                                 color: "#5555D8",
                                 backgroundColor: "#EBEBFF",
                                 borderColor: "#5555D8",
                                 // width: "50%"
                              }}
                           ></Button>

                           <Button
                              onClick={() => setBtndelete(false)}
                              severity="danger"
                              label="Cancelar"
                              style={{
                                 color: "#344054",
                                 backgroundColor: "#fff",
                                 border: "1px solid #d0d5dd",
                                 // width: "50%"
                              }}
                              outlined="true"
                              className="p-button-outlined p-button-success"
                           ></Button>
                        </div>
                     </div>
                  </div>
               </Dialog>
            </div>
            <div className="card flex justify-content-center">
               <Dialog
                  onHide={() => setVisible2(false)}
                  visible={visible2}
                  className="card-buton"
                  header={
                     <>
                        <div className="flex flex-row gap-3 align-items-center">
                           <div
                              className="flex"
                              style={{
                                 width: "60px",
                                 height: "60px",
                                 background: "#EBEBFF",
                                 borderRadius: "10px",
                              }}
                           >
                              <img
                                 src={RucIcon}
                                 alt=""
                              />
                           </div>
                           <p
                              style={{
                                 fontWeight: "700",
                                 fontSize: "18px",
                                 color: "#5555D8",
                              }}
                           >
                              Editar datos de la tarjeta
                           </p>
                        </div>
                     </>
                  }
                  style={{ width: "25vw", minWidth: "500px", maxWidth: "446px" }}
               >
                  <div className="card">
                     <div className="flex flex-column gap-3 mb-4">
                        <div className="flex-auto">
                           <label
                              htmlFor="num"
                              className="font-bold block mb-2"
                           >
                              Entidad Bancaria
                           </label>
                           <Dropdown
                              value={entidadPagoUpdate?.entidad_electronica}
                              onChange={(e) =>
                                 handleChangeEntidadElectronica(e)
                              }
                              options={tipo}
                              optionLabel="nombre"
                              placeholder="Seleccionar tipo de Tarjeta"
                              className="w-full"
                           />
                        </div>
                        <div className="flex-auto">
                           <label
                              htmlFor="integer"
                              className="font-bold block mb-2"
                           >
                              Número de Celular
                           </label>
                           <InputText
                              id="name"
                              style={{width:"100%", height:"50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px"}} 
                              ref={numeroCuentaRef}
                              defaultValue={
                                 entidadPagoElectronico.numero_cuenta
                              }
                              keyfilter="num"
                              className="w-full text-area"
                           />
                        </div>
                        <div className="card flex flex-wrap justify-content-end gap-3 pt-3 butons-card">
                           <Button
                              onClick={() => saveCuentaElectronica() }
                              label="Guardar"
                              className="p-button-success p-button-raised "
                              
                           />
                           <Button
                              onClick={() => setVisible2(false)}
                              label="Cancelar"
                              severity="danger"
                              outlined="true"
                              className="p-button-danger p-button-outlined"
                           />
                        </div>
                     </div>
                  </div>
               </Dialog>
            </div>
          
         </div>
      );
   }
}
