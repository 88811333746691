import React, { useEffect, useState } from "react";
import "../../../../Style/styless.css";
import { Button } from "primereact/button";
import bgcontain from "../../../../Images/Operaciones/Servicios/bg-contain.png";
import { InputText } from "primereact/inputtext";
import FileExcel from "../../../../Images/File-new.svg"
import TablaCoorporativa from "./Tabla";

import AuthUser from "../../../../AuthUser";
import { ScrollPanel } from 'primereact/scrollpanel';
// styles
const btn = {
    width: "42px",
    height: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.07)",
    borderRadius: "10px",
};

const background_btn_primary = {
    ...btn,
    background: "#E0F5FD",
};

const background_btn_secondary = {
    ...btn,
    background: "#F4ECFF",
};

const background_btn_third = {
    ...btn,
    background: "#FEE6BF",
};


const font = {
    fontSize: '15px',
    fontWeight: 'bold'
}


const color_green = {
    ...font,
    color: '#0581AC'
}


const color_blue = {
    ...font,
    color: '#7A7AEE'
}

const color_orange = {
    ...font,
    color: '#FF9F38'
}


const content_void = (
    <div
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
            width: "100%",
        }}
    >
        <p>Seleccione un servicio</p>
    </div>
);

export const HookOperaServicios = (data) => {
    const [selectedId, setSelectedId] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(null);

    const handleButtonClick = (id, index) => {
        setSelectedId(id);
        setSelectedIndex(index);
    };

    //const selectedData = data.find((item) => item.paquete === selectedId);
    const selectedData = data?.[selectedIndex];
    console.log('paquete seleccionado', selectedData)
    console.log('indice seleccionado', selectedIndex)

    return { selectedId, handleButtonClick, selectedData }
}


export default function PageServiciosE() {

    const { http } = AuthUser()
    const [dataPaquetes, setDataPaquetes] = useState(null);
    const { selectedData, selectedId, handleButtonClick } = HookOperaServicios(dataPaquetes)

    const [datosBusqueda, setBusqueda] = useState(dataPaquetes)

    //Buscar perfiles
    const busqueda = (e) => {
        if (e.target.value !== '') {
            let datosFiltrados = dataPaquetes.flatMap(item =>
                item.perfil_tipos.filter(data =>
                    data?.perfil.nombre.toLowerCase().includes(e.target.value.toLowerCase())
                )
            );
            setBusqueda(datosFiltrados);
        } else {
            // Si el campo de búsqueda está vacío, mostrar todos los perfiles de trabajadores
            setBusqueda(dataPaquetes.flatMap(item => item.perfil_tipos));
        }
    }


    //Apartado de integración de APIS
    const GetPaqueteEmpresa = () => {
        http.get('empresa/paquetes/get_paquetes')
            .then((response) => {
                setDataPaquetes(response.data.data)
                console.log('paquete', response.data.data)
            })
            .catch((err) => {
                console.error(err.resp)
            })
    }
    //Fin del apartado

    useEffect(() => {
        GetPaqueteEmpresa();
    }, [])

    return (
        <div className="default__content p-6">
            <div className="flex flex-column" style={{ position: 'fixed', marginTop: '-80px' }}>
                <span style={{ fontWeight: '700', fontSize: '35px', color: '#4545BC' }}>Servicios</span>
                <span style={{ fontWeight: '300', fontSize: '14px', color: 'rgba(69, 69, 188, 0.54)' }}>En este módulo usted podrá ver sus servicios adquiridos.</span>
            </div>

            <section className="section-one" style={{ width: '600px', height: '100%' }}>
                <div className="default-content-section ">
                    <div className="default-title flex flex-row">
                        <span className="de_ti_">Nuestros servicios adquiridos</span>
                    </div>
                    <div className="default-header">
                        <ScrollPanel className="custombar1" style={{ width: '100%', height: '22rem' }} >
                            <div className="default-buttons">
                                {dataPaquetes?.map((item, index) => (
                                    <button
                                        icon="pi pi-ellipsis-v"
                                        className="button-nft"
                                        key={item.id}
                                        onClick={() => handleButtonClick(item.paquete, index)}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: '7px', height: '64px', width: '300px' }}>
                                            <div style={index === 1 ? background_btn_primary : index === 2 ? background_btn_secondary : background_btn_third}>
                                                {/* <img src={item.logo} alt="Logo" height="24" width="24" /> */}
                                                <i className="pi pi-user"></i>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", gap: '3px' }}>
                                                <p style={item.service === "1" ? color_green : item.service === "2" ? color_blue : color_orange}>{item.paquete.nombre}</p>
                                                <p style={{ fontSize: '13.5px', fontWeight: '400', color: '#393838' }}>{item.details}</p>
                                            </div>
                                        </div>
                                    </button>
                                ))}

                            </div>
                        </ScrollPanel>
                    </div>
                    <img src={bgcontain} alt="contain" className="contain-supper" />

                </div>
            </section>

            <div className="flex flex-column w-full">
                <div className="flex flex-row w-full gap-3" style={{ justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                    <span className="p-input-icon-left flex bg-transparent" style={{ alignItems: 'center' }}>
                        <i className="px-2 pi pi-search" />
                        <InputText placeholder="Buscar..." style={{ paddingRight: '300px' }} onChange={(e) => { busqueda(e) }} />
                    </span>
                    {/* <div className="flex flex-row gap-3">
                        <Button icon="pi pi-spin pi-cog" className="p-button-outlined p-button-success" label="Configuración de alertas" style={{ background: 'white', height: '52px', borderRadius: '13px' }} />
                    </div> */}
                </div>


                <section className="section-two w-full">
                    {selectedId ? (
                        <div className="default-section-node">
                            <div className="flex flex-column" style={{ paddingLeft: "25px", paddingTop: "25px" }}                        >
                                <div className="flex flex-row gap-3">
                                    <>
                                        <div className="flex" style={selectedData.service === "1" ? background_btn_primary : selectedData.service === "2" ? background_btn_secondary : background_btn_third}  >
                                            <i className="pi pi-user"></i>
                                        </div>
                                        <div className="flex" style={{ marginTop: "10px" }}>
                                            <h1 style={{ fontWeight: "600", fontSize: "20px" }}>
                                                {selectedData.paquete.nombre}
                                            </h1>
                                        </div>
                                    </>
                                </div>
                                <div
                                    className="flex" style={{ background: "#F8F8F8", height: "34px", borderRadius: "17px", justifyContent: "center", marginTop: "15px", marginRight: 'auto' }}                            >

                                </div>
                            </div>
                            {/* Contenido */}

                            <TablaCoorporativa dataPerfiles={datosBusqueda ? datosBusqueda : selectedData.perfil_tipos} />
                        </div>
                    ) : (
                        content_void
                    )
                    }
                </section>
            </div>
        </div>
    );
}
