import React, { useState, useEffect, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import AuthUser from "../../../../AuthUser";
import CardLocal from "../../../../Components/RecursosHumanos/Local/LocalComponentes/CardLocal"
import ModalEliminarLocal from "../../../../Components/RecursosHumanos/Local/Modals/ModalEliminarLocal";
import ModalCrearLocal from "../../../../Components/RecursosHumanos/Local/Modals/ModalCrearLocal";
import { Toast } from "primereact/toast";
import '../../../../Style/misLocales/misLocales.css'
import PageMisAreasE from "../MisAreas/PageMisAreasE";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

function PageMisLocalesE() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const { http } = AuthUser();
    const [visible1, setVisible1] = useState({
        status: false,
        nombre: ''
    });
    const [departamentos, setDepartamentos] = useState(null);
    const [locales, setLocales] = useState([])
    const [datosbusqueda, setBusqueda] = useState(locales)
    const [visibleDelete, setVisibleDelete] = useState({
        status: false,
        id: null
    });


    const [url, setUrl] = useState("./areas")

    const [local, setLocal] = useState({
        id: null,
        nombre: '',
        direccion: '',
        latitud: null,
        longitud: null,
        departamento_id: null,
        departamento: null,
        distrito_id: null,
        distrito: null,
        provincia_id: null,
        provincia: null,
    })


    // BUSCAR LOCALES

    const showToast = (type, title, detail) => {
        toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
        });
    }

    const busqueda = (e) => {
        if (e.target.value !== '') {
            let datosFiltrados = locales.filter(
                locales => locales.nombre.toLowerCase().includes(e.target.value.toLowerCase()) || locales?.departamento?.departamento.toLowerCase().includes(e.target.value.toLowerCase())
                    || locales?.provincia?.provincia.toLowerCase().includes(e.target.value.toLowerCase()) || locales?.distrito?.distrito.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setBusqueda(datosFiltrados)
            console.log("DATOS FILTRADOS", datosFiltrados)
        }
        else {
            setBusqueda(locales)
        }
    };
    const handleEditarlocal = async (id, datos) => {
        dispatch(startLoading());
        try {
            const response = await http.put(`empresa/local/update/${id}`, datos)
            console.log("LOCALES", response)
            if (response?.data?.resp) {
                await Promise.all([
                    getLocales()
                ]);
                showToast("success", "Editado correctamente", "El local se editó correctamente")
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            showToast('error', 'Error', 'Contáctese con soporte técnico.')
            console.log(error)
        } finally {
            dispatch(endLoading());
        }
    }


    const handleDelete = async (id) => {
        dispatch(startLoading());
        try {
            const response = await http.delete(`empresa/local/delete/${id}`)
            console.log("LOCALES", response)
            if (response?.data?.resp) {
                await Promise.all([
                    getLocales()
                ]);
                showToast("success", "Eliminado correctamente", response?.data?.resp)
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            showToast('error', 'Error', 'Contáctese con soporte técnico.')
            console.log(error)
        } finally {
            dispatch(endLoading());
        }
    }

    // CREAR LOCAL

    const handleCreate = async (datosLocal) => {
        dispatch(startLoading());
        try {
            const response = await http.post('empresa/local/create', datosLocal)
            if (response?.data?.resp) {
                await Promise.all([
                    getLocales()
                ])
                setVisible1(false);
                console.log('Local creado correctamente', response?.data?.resp)
                showToast("success", "Se creó correctamente", "El local se creó correctamente")
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
            console.error(error)
        } finally {
            dispatch(endLoading());
        }
    }


    const getLocales = async () => {
        try {
            const response = await http.get('empresa/local/show')
            if (response?.data?.data) {
                console.log('Localeeees', response?.data?.data)
                setLocales(response?.data?.data)
                setBusqueda(response?.data?.data)
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            console.error("error", error)
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
        }

    }
    const getAllUbicaciones = async () => {
        try {
            const response = await http.get("/ubicacion")
            if (response?.data?.data) {
                setDepartamentos(response?.data?.data);
                console.log('departamentosssss', response?.data?.data);
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }

        } catch (error) {
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
            console.log(error);
        };
    };

    const fetchAllData = async () => {
        try {
            await Promise.all([
                getLocales(),
                getAllUbicaciones(),
            ]);
        } catch (error) {
            console.error('Error al cargar datos:', error);
        } finally {
            dispatch(endLoading());
        }
    }

    useEffect(() => {
        dispatch(startLoading());
        fetchAllData()
    }, []);

    return (
        <div className="flex flex-wrap flex-column gap-5 px-6">

            <div className="flex flex-wrap flex-column  ">
                <span className="text-indigo-600 text-3xl font-bold">Mis Locales</span>
                <br />
                <p className="w-full text-indigo-200 text-sm">A continuación visualiza los locales previamente creados para poder colocar las áreas que usted a definido en cada unos de sus locales.</p>

            </div>

            <div className=" w-full"  >
                <ScrollPanel className="scroll" >

                    <div className=" p-input-icon-left w-full mb-3  flex justify-content-center " style={{ background: "#F6F7FB", boxShadow: "none" }}>
                        <span className="p-input-icon-left  bg-transparent" style={{ width: '70%', background: "#F6F7FB", boxShadow: "none" }}>
                            <i className=" px-2 pi pi-search" style={{ color: "#ccd2d8" }} />
                            <InputText placeholder="Busca tu local" style={{ background: 'white', width: '100%', height: '43px', top: '251px', left: '774px', borderRadius: '13px' }} onChange={(e) => busqueda(e)} />
                        </span>
                    </div>

                    <div className="px-8  contenedorcard ">
                        <button
                            className="relative overflow-hidden cursor-pointer border-2 border-dashed border-round-2xl flex flex-1 flex-column bg-purple-50 p-3 gap-2 border-round-lg align-items-center 
                            justify-content-center border-indigo-500"

                            style={{ width: "370px", height: "361px", }}
                            onClick={() => setVisible1({ ...visible1, status: true, nombre: 'Crear local' })}
                        >
                            <i className="pi pi-plus-circle text-indigo-600 font-bold text-xl"></i>
                            <p className="font-bold text-indigo-600 text-base">Crear Nuevo Local</p><span className="StepsV_shine"></span>
                        </button>

                        {
                            datosbusqueda.map((localitem, key) => {
                                return (
                                    <CardLocal
                                        key={key}
                                        localitem={localitem}
                                        local={local}
                                        setLocal={setLocal}
                                        setVisible1={setVisible1}
                                        visible1={visible1}
                                        visibleDelete={visibleDelete}
                                        setVisibleDelete={setVisibleDelete}
                                        url={url}
                                        provincia={localitem?.distrito?.provincia?.provincia}
                                    />
                                )
                            })
                        }

                    </div>
                </ScrollPanel>
            </div>
            { }
            <ModalCrearLocal
                local={local}
                setLocal={setLocal}
                handleCreate={handleCreate}
                setVisible1={setVisible1}
                visible1={visible1}
                departamentos={departamentos}
                handleEditarlocal={handleEditarlocal}

            />
            { }
            <ModalEliminarLocal
                handleDelete={handleDelete}
                setVisibleDelete={setVisibleDelete}
                visibleDelete={visibleDelete}
            />

            <Toast ref={toast} />
        </div>

    )
}

export default PageMisLocalesE