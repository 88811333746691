import TablaComponent from "./TablaExamenOrina/TablaExamenOrina";
import { Button } from "primereact/button";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import LaboratorioListComponent from "../Laboratorio/container/laboratorio_list";
import DialogArchivoComponent from "../Laboratorio/container/dialog_archivo";
import "../../Style/EstiloGlucosa.css"

import AuthUser from "../../AuthUser";
import { LaboratorioContext } from "../../Page/Laboratorio/LaboratorioProvider";

import React, { useContext, useEffect, useState } from "react";


export default function ExamenCompletoDeOrina({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) { //===================== #00
   const { http } = AuthUser();

   const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);

   const [prueba, setPrueba] = useState({})
   const [nombreArchivo, setNombreArchivo] = useState(null);
   const [file, setFile] = useState(null);
   const [indexSup, setIndexSup] = useState(0);
   const [indexSub, setIndexSub] = useState(0);
   const [parametroscopy, setParametrosCopy] = useState //===================== #01
      ([
         {
            prueba: 'Color',
            resultado: "",

         },
         {
            prueba: 'Aspecto',
            resultado: "",
         },
         {
            prueba: 'Sangre en Orina',
            resultado: "",
         }, {
            prueba: 'Leucocitos',
            resultado: "",
         }, {
            prueba: 'Glucosa en orina',
            resultado: "",
         },
         {
            prueba: 'Urobilinógeno',
            resultado: "",
         }, {
            prueba: 'Bilirrubina en orina',
            resultado: "",
         }, {
            prueba: 'Proteínas en orina',
            resultado: "",
         }, {
            prueba: 'Nitritos en orina',
            resultado: "",
         }, {
            prueba: 'Cetonas',
            resultado: "",
         }, {
            prueba: 'Gérmenes',
            resultado: "",
         }, {
            prueba: 'PH',
            resultado: "",
         }, {
            prueba: 'Células epiteliales',
            resultado: "",
         }, {
            prueba: 'Leucocitos Sedimento',
            resultado: "",
         }, {
            prueba: 'Hematíes',
            resultado: "",
         }, {
            prueba: 'Densidad',
            resultado: "",
         }, {
            observaciones: "",
            conclusion: ""
         }
      ])
   const [parametros, setParametros] = useState //===================== #01
      ([
         {
            prueba: 'Color',
            resultado: "",

         },
         {
            prueba: 'Aspecto',
            resultado: "",
         },
         {
            prueba: 'Sangre en Orina',
            resultado: "",
         }, {
            prueba: 'Leucocitos',
            resultado: "",
         }, {
            prueba: 'Glucosa en orina',
            resultado: "",
         },
         {
            prueba: 'Urobilinógeno',
            resultado: "",
         }, {
            prueba: 'Bilirrubina en orina',
            resultado: "",
         }, {
            prueba: 'Proteínas en orina',
            resultado: "",
         }, {
            prueba: 'Nitritos en orina',
            resultado: "",
         }, {
            prueba: 'Cetonas',
            resultado: "",
         }, {
            prueba: 'Gérmenes',
            resultado: "",
         }, {
            prueba: 'PH',
            resultado: "",
         }, {
            prueba: 'Células epiteliales',
            resultado: "",
         }, {
            prueba: 'Leucocitos Sedimento',
            resultado: "",
         }, {
            prueba: 'Hematíes',
            resultado: "",
         }, {
            prueba: 'Densidad',
            resultado: "",
         }, {
            observaciones: "",
            conclusion: ""
         }
      ])


   // =========================================================================================================================================
   //===================== #02

   const handleCreate = () => {
      const formData = new FormData();
      formData.append("examen_id", tipoExamen);
      formData.append("prueba[examen_id]", tipoPrueba);
      formData.append("prueba[estado]", 1);
      formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
      formData.append("prueba[cantidad_parametros]", parametros?.length);
      formData.append("prueba[parametros]", JSON.stringify(parametros));
      formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));

      http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
         .then(response => {
            console.log(response)
            showToast("success","Creado Correctamente",response?.data?.resp)
         })
         .catch(error => {
            console.log(error)
            showToast("Error","Error de Guardar",error?.data?.resp)
         })
   }

   const changeExamenContext = (superior, inferior, auxParametros) => {
      setExamenesContext(examenesContext.map((elementoSup, index) => {
         if (index === superior) {
            let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
               if (indexAux === inferior) {
                  return {
                     ...prueba,
                     parametros: auxParametros,
                     estado: 1,
                     cantidad_parametros: auxParametros?.length,
                     perfil: auxParametros?.length > 1 ? 2 : 1
                  }
               }
               return prueba;
            })
            return { ...elementoSup, pruebas: auxPrueba };
         }
         return elementoSup;
      }))
   }

   const handleInputChange = (index, e, modo = 1) => {

      const tempTask = [...parametros];
      // if(typeof tempTask[index]=="undefined"){
      //    tempTask[index]=parametroscopy[index]
      // }
      if (modo == 1) {
         tempTask[index].resultado = e;

      } else {
         if (modo == 2) {
            tempTask[index].observaciones = e;

         } else {
            tempTask[index].conclusion = e;

         }
      }
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };
   const handleInputInicial = (index, e) => {
      console.log(e)
      const tempTask = [...parametros];
      tempTask[index] = e;
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };
   const handleInputFinal = (index, e) => {
      const tempTask = [...parametros];
      tempTask[index].rango_final = e;
      setParametros(tempTask);
      changeExamenContext(indexSup, indexSub, tempTask);
   };

   useEffect(() => {
      console.log(examenesContext)
      let sup = 0;
      let inf = 0;
      let auxPruebas = examenesContext.find((examen, index) => {
         if (examen.examen_id === tipoExamen) {
            setIndexSup(index);
            sup = index;
            console.log(index)
         }
         return examen.examen_id === tipoExamen
      });
      let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
         if (prueba.examen_id === tipoPrueba) {
            setIndexSub(index);
            inf = index;
            console.log(index)
         }
         return prueba.examen_id === tipoPrueba
      });
      if (auxPrueba?.archivo) {
         setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
      }
      setPrueba(auxPrueba);
      console.log("PRUEBA", auxPrueba)
      console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
      let auxParametros = typeof auxPrueba?.parametros === 'string' ? JSON.parse(auxPrueba?.parametros) : auxPrueba?.parametros
      let newParams = []
      if (auxParametros?.length == 1 || auxParametros?.length == 0) {
         newParams = [...parametroscopy]
         if (auxPrueba?.parametros?.[0]) {
            newParams[0] = auxPrueba?.parametros?.[0]
         }
         changeExamenContext(sup, inf, newParams);
         setParametros(newParams)
      } else {
         if (auxParametros?.length == 16) {
            newParams = [...auxParametros]
            newParams.push(parametroscopy[16])
            changeExamenContext(sup, inf, newParams);
            setParametros(newParams)
         } else {
            changeExamenContext(sup, inf, auxParametros);
            setParametros(auxParametros)

         }

      }


      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // =========================================================================================================================================


   return (
      //Contenedor
      <div>
         <div style={{ width: '100%' }}>
            {/* Movilidad */}
            <div style={{ overflow: 'auto' }}>
               <h2 style={{ marginTop: '20px' }}>
                  Examen de orina
               </h2>
               <TablaComponent
                  valueParametros={parametros}
                  handleChange={handleInputChange}
                  column1patologia='Prueba'
                  column2patologia='Resultado'
                  column3patologia='UM'
                  column4patologia='Rango'
                  column5patologia='Valor normal'
                  fila1patologia='Color'
                  fila2patologia='Aspecto'
                  fila3patologia='Sangre en Orina'
                  fila4patologia='Leucocitos'
                  fila5patologia='Glucosa en orina'
                  fila6patologia='Urobilinógeno'
                  fila7patologia='Bilirrubina en orina'
                  fila8patologia='Proteínas en orina'
                  fila9patologia='Nitritos en orina'
                  fila10patologia='Cetonas'
                  fila11patologia='Gérmenes'
                  fila12patologia='PH'
                  fila13patologia='Células epiteliales'
                  fila14patologia='Leucocitos Sedimento'
                  fila15patologia='Hematíes'
                  fila16patologia='Densidad'
                  fila17patologia='Observaciones'
                  fila18patologia='Conclución Médica' />

            </div>

         </div>

         <div className="flex flex-column w-full h-full" style={{ padding: '20px', marginTop: '50px' }}>


            <DialogArchivoComponent
               file={file}
               handleCreate={handleCreate}
               nombreArchivo={nombreArchivo}
               prueba={prueba}
               setFile={setFile}
               setNombreArchivo={setNombreArchivo}
            />
         </div>
      </div>




   );
}
