import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Tree } from 'primereact/tree';
import note from "../../../Images/roles/note.svg";
import { ListBox } from "primereact/listbox";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";

const LocalesModalComponent = ({
   visibleLocales,
   setVisibleLocales,
   rol,
   setRol,
   locales,
   GetLocales,
   setGetLocales,
   AsignarLocales,
   LocalAsignadoBD
}) => {

   console.log("rooool", rol);
   
   // const [selectedLocales, setSelectedLocales] = useState(null);
   const [SeleccionarLocal, setSeleccionarLocal] = useState(null);
   const [LocalAsignado, setLocalAsignado] = useState({
      "local_id": 0
   })
   const opciones =
      GetLocales?.map((item) => (
         { name: item.nombre, code: item.id }
      ))



   const hideDialogLocales = () => {
      setVisibleLocales(false);
   };

   const ChangeLocal = (e) => {
      setSeleccionarLocal(e.value)
      setLocalAsignado({ local_id: e.value.code })
   }

   const GuardarCambios = () => {
      AsignarLocales(rol.id, LocalAsignado);
      setVisibleLocales(false);
      setSeleccionarLocal(null);
   }
   const localesDialogFooter = (
      <div>
         <Button
            label="Cancelar"
            style={{
               backgroundColor: "#FFFFFF",
               color: "black",
               borderColor: "#CBC9C9",
               marginRight: "25px",
            }}
            onClick={() => {
               setVisibleLocales(false);
               setSeleccionarLocal(null);
            }}
         />
         <Button
            label="Guardar"
            style={{ backgroundColor: "#5555D8" }}
            onClick={() => {
               GuardarCambios()
            }}
         />
      </div>
   );

   // // Función para asignar locales
   // const handleAssignLocales = () => {
   //    let data = {
   //       rol_id: rol.id,
   //       locales: selectedLocales,
   //    };
   //    console.log(data);
   //    asignarLocales(data);
   //    setVisibleLocales(false);
   // };

   // useEffect(() => {
   //    // setSelectedLocales(rol?.locales);
   // }, [rol]);

   return (
      <div className="tabla-rol">
         <Dialog
            visible={visibleLocales}
            style={{ width: "500px", height: "470px", borderRadius: "20px" }}
            header={
               <>
                  <img className="store-icon" src={note} alt="store" />
                  <i className="pi-file-edit" />
                  <p
                     style={{
                        color: "#5555D8",
                        fontFamily:
                           "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                     }}
                  >
                     Administrar Locales
                  </p>
               </>
            }
            modal
            className="p-fluid tabla-rol"
            onHide={() => {
               hideDialogLocales();
            }}
            footer={localesDialogFooter}
         >
            <div className="field">
               <label htmlFor="razon_social">
                  En esta sección usted puede administrar y gestionar los datos
                  de los <span style={{ fontWeight: "bold" }}>locales.</span>
               </label>
               <h3>{rol.nombre}</h3>
               <br></br>
               <p
                  style={{
                     color: "#5555D8",
                     fontSize: "20px",
                     fontWeight: "bold",
                     fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                  }}
               >
                  Seleccionar Locales
               </p>
               {/* <Tree 
                  value={locales} 
                  selectionMode="checkbox" 
                  selectionKeys={selectedLocales} 
                  onSelectionChange={(e) => setSelectedLocales(e.value)} 
                  className="w-full md:w-30rem" /> */}
            </div>
            <div>
               <Dropdown
                  value={SeleccionarLocal}
                  onChange={(e) => ChangeLocal(e)}
                  options={opciones}
                  optionLabel="name"
                  placeholder="Seleccionar Local"
               // onChange={setSelectedLocales()}
               />
            </div>
            <div className="flex flex-wrap pt-1">
               <p className="flex flex-wrap pt-1 align-items-end">Local Asignado actualmente:  <span className="pl-2 font-semibold">{LocalAsignadoBD == null ? " No hay local asignado"  : LocalAsignadoBD}</span></p>
            </div>
         </Dialog>
      </div>
   );
};

export default LocalesModalComponent;
