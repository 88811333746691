import React, { useState, useEffect } from 'react';

//COMPONENTES DE PRIMEREACT
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../../../../Services/ProductService';
import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { InputText } from "primereact/inputtext";


//MODALS
import ModalGenerarPdf from './ModalGenerarPdf';
import ModalVerPdf from './ModalVerPdf';

//HOJAS DE ESTILO
import '../../../../Style/modales.css'
import 'primeicons/primeicons.css';
import AuthUser from '../../../../AuthUser';
import { useParams } from 'react-router-dom';

function ModalImprimir({ imprimirVisible, setImprimirVisible, showToast, documentos }) {
   const { http } = AuthUser();
   const { idPaciente } = useParams();

   const [selectedProducts, setSelectedProducts] = useState(null);
   const [rowClick, setRowClick] = useState(true);
   const [products, setProducts] = useState([]);
   const [rowId, setRowId] = useState(null);
   const [visibleGenerarPdf, setVisibleGenerarPdf] = useState(false);
   const [visibleVerPdf, setVisibleVerPdf] = useState(false);
   const [pdfUrl, setPdfUrl] = useState(null);
   const [initialDocumentos, setInitialDocumentos] = useState([]);
   const [filteredDocumentos, setFilteredDocumentos] = useState([]);
   const onInputChange = (e) => {
      const { value } = e.target;
      const filteredDocs = initialDocumentos.filter(doc => doc.nombre.toLowerCase().includes(value.toLowerCase()));
      setFilteredDocumentos(filteredDocs);
   };

   const [selectionproducts, setSelectionproducts] = useState([




      {
         id: '1',
         code: '1',
         seleccionado: false,
         estado: 'No firmado',
         name: 'INFORME PSICOLOGICO',
         ruta:
            'https://api.bregma.com.pe/public/api/reporte/informe_psicologico/',
      },
      {
         id: '2',
         code: '2',
         seleccionado: false,
         estado: 'No firmado',
         name: 'FICHA PSICOLOGICA',
         ruta:
            'https://api.bregma.com.pe/public/api/reporte/ficha_psicologica/',
      },
      /* {
          id: '3',
          code: '3',
          name: 'CERTIFICADO APTITUD OPERATIVO CON DIAGNÓSTICO',
          seleccionado: false,
          estado:'No firmado',
          ruta:
            'http://api.bregma.com.pe/storage/app/public/pdf/FICHA_OCUPACIONAL_ANEXO_16.pdf',
        }, */
   ]);

   const generarPdf = () => {
      let data_send = {
         paciente_id: idPaciente,
         documento_id: rowId.id,
      }
      http
         .post(`${rowId.url}${idPaciente}`, data_send)
         .then((response) => {
            if (response.data.resp) {
               console.log(response.data.resp);
               showToast('success', 'Generado correctamente', "Los datos se han generado correctamente");
            }
         })
         .catch((error) => {
            console.log(error);
         })
   }

   const handleGenerarPDF = (rowData) => {
      setVisibleGenerarPdf(true)
      setRowId(rowData)
   }

   const openVerPdf = (rowData) => {
      setPdfUrl(rowData.url);
      setVisibleVerPdf(true);
      setRowId(rowData.id)
   };

   const IconoVisualizar = (rowData) => {
      return (
         <div>
            <Button onClick={() => openVerPdf(rowData)} className='pi pi-file-o text-indigo-500 font-bold bg-white border-transparent text-indigo-500'>
               PDF
            </Button>
         </div>
      );
   }

   const IconoGenerar = (rowData) => {
      return (
         <div>
            <Button onClick={() => handleGenerarPDF(rowData)} className='pi pi-file-o text-indigo-500 font-bold bg-white border-transparent' />
         </div>
      );
   }


   const handleFirmaClick = (id) => {
      // Encuentra el elemento en el array por su id
      const updatedSelectionProducts = selectionproducts.map((item) =>
         item.id === id ? { ...item, estado: 'Firmado' } : item
      );

      setSelectionproducts(updatedSelectionProducts);
      //setVisibleVerPdf();
   };

   const handleAgregarFirmaClick = () => {
      handleFirmaClick(rowId) // Cambia el estado a "Firmado"
      setVisibleVerPdf(false); // Cierra el popup
   };

   useEffect(() => {
      ProductService.getProductsMini().then((data) => setProducts(data));
   }, []);

   useEffect(() => {
      setFilteredDocumentos(documentos);
      setInitialDocumentos(documentos);
      ProductService.getProductsMini().then((data) => setProducts(data));
   }, [documentos]);


   return (
      <>
         <Dialog
            visible={imprimirVisible}
            style={{ height: '90%', width: '80%' }}
            header={
               <div className='flex align-items-center'>
                  <span className="pi pi-file-pdf text-5xl bg-indigo-100 p-1 text-indigo-500 font-bold border-round-lg"></span>
                  <h2 className='font-bold text-3xl text-indigo-500 pl-6'> Informe del Paciente</h2>
               </div>}
            onHide={() => setImprimirVisible(false)}
            className='contenedor-imprimir'
         >
            <Divider />
            <div className='flex flex-column gap-5 pt-2 content-l'>
               <div className='flex flex-column text-center' >
                  <label htmlFor="" className='font-bold text-700'>Datos del paciente</label>
                  <div className='contenedor-principal'>
                     <div className='contenedor-imprimir'>
                        <span className="p-input-icon-left">
                           <i className="pi pi-search" />
                           <InputText
                              type="search"
                              placeholder="Buscar..."
                              onChange={onInputChange} // Agregar el manejador de cambio para filtrar
                           />
                        </span>
                     </div>
                  </div>

               </div>
               {/* <div className='flex w-11 text-center' style={{ marginLeft: "4%" }}>
                  <div className='flex flex-column w-full' >
                     <label htmlFor="" className='popup_inf_dat border-round-left-md text-sm'>Nombres y apellidos</label>
                     <span className='popup_inf_dat_2'>Sánchez Gaona, Irma Maria</span>

                  </div>
                  <div className='flex flex-column w-full'>
                     <label htmlFor="" className='popup_inf_dat text-sm'>Tipo de doc.</label>
                     <span className='popup_inf_dat_2'>DNI</span>
                  </div>
                  <div className='flex flex-column w-full'>
                     <label htmlFor="" className='popup_inf_dat text-sm'>Nro.Documento</label>
                     <span className='popup_inf_dat_2'>74391606</span>
                  </div>
                  <div className='flex flex-column w-full'>
                     <label htmlFor="" className='popup_inf_dat text-sm'>Nº de atención</label>
                     <span className='popup_inf_dat_2'>1 - 81114</span>
                  </div>
                  <div className='flex flex-column w-full'>
                     <label htmlFor="" className='popup_inf_dat border-round-right-md text-sm'>Empresa</label>
                     <span className='popup_inf_dat_2'>Primax</span>
                  </div>
               </div> */}

               {/* <Table header={false} paginator={false} value={ProductService} tableStyle={{ minWidth: '30rem'}}>
                <Column field="code" header="Nro. Doc"></Column>
                <Column field="name" header="Apellidos y Nombres"></Column>
                <Column field="category" header="Empresa"></Column>
                <Column field="quantity" header="Nro. Atención"></Column>
            </Table> */}

               <div className="w-full">
                  {/* <DataTable
                     value={selectionproducts}
                     selectionMode={rowClick ? null : 'checkbox'}
                     selection={selectedProducts}
                     onSelectionChange={(e) => setSelectedProducts(e.value)}
                     dataKey="id"
                     tableStyle={{ maxWidth: '90%' }}
                     showGridlines
                  >
                     <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                     <Column field="id" header="Item" headerStyle={{ width: '3rem' }}></Column>
                     <Column field="name" header="Documento" headerStyle={{ paddingLeft: '4rem', paddingRight: "2rem" }}></Column>
                     <Column body={IconoGenerar} header="Generar" />
                     <Column field="estado" header="Estado" style={{ textAlign: 'center' }} />
                     <Column body={IconoVisualizar} header="Previsualizar" style={{ textAlign: 'center' }} />
                  </DataTable> */}

                  <DataTable
                     value={filteredDocumentos} // Utilizar los documentos filtrados
                     selectionMode={rowClick ? null : 'checkbox'}
                     selection={selectedProducts}
                     onSelectionChange={(e) => setSelectedProducts(e.value)}
                     dataKey="id"
                     tableStyle={{ maxWidth: '90%' }}
                     showGridlines
                  >
                     {/* <Column field="id" header="Item" headerStyle={{ width: '3rem' }}></Column> */}
                     <Column field="nombre" header="Nombre" headerStyle={{ paddingLeft: '4rem', paddingRight: "2rem" }}></Column>
                     <Column body={IconoGenerar} header="Generar" />
                     <Column field="estado" header="Estado" style={{ textAlign: 'center' }} />
                     <Column body={IconoVisualizar} header="Previsualizar" style={{ textAlign: 'center' }} />
                  </DataTable>
               </div>

               <div style={{ borderRadius: '10px', height: "70px" }} className='flex justify-content-between mt-1 bg-white'>
                  <div className='flex gap-3 w-full justify-content-between' style={{ marginTop: '30px' }}>
                     {/* <button 
                        style={{ color: 'red' }} 
                        className='p-button  p-component btn-2 flex gap-1'
                        onClick={() => setImprimirVisible(false)} >
                            <i className='pi pi-times'></i>
                            <span>Cerrar</span>
                        </button> */}
                     <div className='flex surface-300 align-items-center w-21rem justify-content-evenly border-round-2xl' >
                        <i className='pi pi-info-circle'></i>
                        <label htmlFor="" className=''>Seleccione el informe que desea unir</label>
                     </div>
                     <button style={{ color: '#5555D8' }} className='bg-indigo-100 p-button p-component btn-2 flex gap-1 border-indigo-400 border-round-lg'>
                        <i className='pi pi-copy'></i>
                        <span>Unir documentos</span>
                     </button>

                     {/* <button style={{ color: 'gold' }} className='p-button p-component btn-2 flex gap-1'>
                            <i className='pi pi-print'></i>
                            <span>Visualizar</span>
                        </button> */}
                  </div>
               </div>
            </div>
         </Dialog>

         <ModalGenerarPdf
            show={visibleGenerarPdf}
            setShow={setVisibleGenerarPdf}
            generarPdf={generarPdf}
         />

         <ModalVerPdf
            show={visibleVerPdf}
            setShow={setVisibleVerPdf}
            pdfUrl={pdfUrl}
            handleAgregarFirmaClick={handleAgregarFirmaClick}
         />

      </>
   )

}

export default ModalImprimir;
