import React, { useContext, useEffect, useState } from 'react';
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from 'primereact/inputtextarea';

const TriajeAntFamAdd = ({ header, data, setData, patologias, id_familiar, selectedPatologias, setSelectedPatologias }) => {

   let { stateAtencion, pageViewContext } = useContext(RAContext);
   // let listPatologias = [
   //   {id: 1, nombre: 'Alergias'},
   //   {id: 2, nombre: 'Diabetes'},
   //   {id: 3, nombre: 'Asma'},
   //   {id: 4, nombre: 'TBC'},
   //   {id: 5, nombre: 'Respiratorio'},
   //   {id: 6, nombre: 'Cardiovascular'},
   //   {id: 7, nombre: 'Reumatismo'},
   //   {id: 8, nombre: 'Cáncer'},
   //   {id: 9, nombre: 'Alcoholismo'},
   //   {id: 10, nombre: 'Hiperlipidemia'},
   //   {id: 11, nombre: 'ACV/DCV'},
   //   {id: 12, nombre: 'DM'},
   //   {id: 13, nombre: 'HTA'},
   //   {id: 14, nombre: 'CBT'},
   //   {id: 15, nombre: 'Otras enfermedades'},
   //   // {id: 16, nombre: 'Cáncer de mama'},
   //   {id: 17, nombre: 'Dislipidemia'},
   //   {id: 18, nombre: 'Cardiopatía'},
   //   {id: 19, nombre: 'Otros cánceres'},
   // ]

   const handleChange = (e) => {
      let _dataInput = [...selectedPatologias];
      console.log("PATOLOGIAS SELECCIONADAS", selectedPatologias)
      console.log("DATA", _dataInput)
      if (e.checked) {
         _dataInput.push({
            patologia_id: e.value.id,
            comentario: '',
            nombre: e.value.nombre
         });
      }
      else {
         _dataInput = _dataInput.filter(item => item.patologia_id !== e.value.id)
      }
      setSelectedPatologias(_dataInput)

      let dataInput = { tipo_familiar_id: id_familiar, med_familiar_patologia: _dataInput, comentario: "" }

      data.familiar.some(item => item.tipo_familiar_id === id_familiar) ?
         (setData({
            ...data,
            familiar: data.familiar.map(antecedente => antecedente.tipo_familiar_id === id_familiar ? dataInput : antecedente)
         })) :
         (setData({
            ...data,
            familiar: [...data.familiar, dataInput]
         }));
   }

   const handleChangeText = (index, e) => {
      let updatedSelectedPatologias = selectedPatologias.map((item, i) =>
         i === index ? { ...item, comentario: e?.target?.value } : item
      );
      setSelectedPatologias(updatedSelectedPatologias);

      let dataInput = {
         tipo_familiar_id: id_familiar,
         med_familiar_patologia: updatedSelectedPatologias,
         comentario: ""
      };
      console.log("DATAINPUT", dataInput)
      data.familiar = data.familiar.map(
         (item) => item.tipo_familiar_id === id_familiar ? dataInput : item
      );

      // data.familiar.push(dataInput);
      setData({ ...data });
   };

   const webkitSpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
   // manejo de inputs de voz
   const [isDictatingArray, setIsDictatingArray] = useState(Array(patologias.length).fill(false));

   const handleDictation = (index) => {
      if ('webkitSpeechRecognition' in window) {
         const recognition = new webkitSpeechRecognition();

         recognition.lang = 'es-ES'; // Establece el idioma de reconocimiento de voz (puedes cambiarlo según tus necesidades).
         recognition.continuous = false; // Configura el reconocimiento para que se detenga después de una única frase.

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;

            // Actualiza el estado 'comentario' de la patología correspondiente en 'selectedPatologias'
            const updatedSelectedPatologias = selectedPatologias.map((item, i) =>
               i === index ? { ...item, comentario: transcript } : item
            );
            setSelectedPatologias(updatedSelectedPatologias);
         };

         recognition.onend = () => {
            setIsDictatingArray((prev) => {
               const newState = [...prev];
               newState[index] = false; // Marcar que no se está dictando para este botón específico
               return newState;
            });
         };

         recognition.start();
         setIsDictatingArray((prev) => {
            const newState = [...prev];
            newState[index] = true; // Marcar que se está dictando para este botón específico
            return newState;
         }); // Marca que se está dictando.
      } else {
         // El navegador no admite la API de reconocimiento de voz.
         alert('Tu navegador no admite el reconocimiento de voz.');
      }

   };

   return (
      <>
         <div className="flex justify-content-between align-items-center">
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
               <h3>{header}</h3>
            </div>
         </div>

         <div className="taf_form_add_af_container">
            <div className="taf_form_add_af_checkbox">
               {
                  patologias?.map((item) => {
                     return (
                        <div key={item?.id}>
                           <Checkbox
                              name="patologia_id"
                              value={item}
                              onChange={handleChange}
                              checked={selectedPatologias?.length > 0 && selectedPatologias?.some((i) => i?.patologia_id === item?.id)}
                              disabled={stateAtencion[pageViewContext].estado === 3}
                           />
                           <label>{item?.nombre}</label>
                        </div>
                     )
                  })
               }
            </div>
            <div className='taf_form_add_af_checkbox'>
               <div className="taf_form_add_af_textarea">
                  {
                     selectedPatologias?.length > 0 && selectedPatologias?.map((item, index) => {
                        console.log("PATOLOGÍAS", item)
                        return (
                           <div key={item?.patologia_id}>
                              <div className='input_micro_label'>
                                 <span>{item?.patologias?.nombre ?? item?.nombre}</span>

                              </div>
                              <div className="flex flex-row w-full">
                                 <div className="flex flex-column w-full">
                                    <InputTextarea
                                       value={item?.comentario}
                                       onChange={e => handleChangeText(index, e)}
                                       autoResize
                                       placeholder="Agregue un comentario"
                                       disabled={stateAtencion[pageViewContext].estado === 3}
                                    />
                                 </div>
                                 <div className="flex flex-column justify-content-end" style={{ marginLeft: "-40px", marginBottom: "5px" }}>
                                    <button onClick={() => handleDictation(index)}
                                       style={{ background: "rgb(85, 85, 216)", color: "white", width: "36px", borderRadius: "6px", border: "1px solid rgb(99, 102, 241)", cursor: "pointer" }}
                                    >

                                       {isDictatingArray[index] ? 'Off' : 'On'}
                                    </button>
                                 </div>
                              </div>
                           </div>


                        )
                     })

                  }

               </div>
            </div>
         </div>


      </>

   );
};

export default TriajeAntFamAdd;