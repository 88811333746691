import React, { useContext, useState } from 'react'
import { Tree } from 'primereact/tree';
import "./style/side-area.css"
import { changeDataToTreeTable } from "./services/funciones";
import { GestionContext } from '../../../Page/EmpresaRol/GestionAtencion/services/GestionAtencionContext';
import { useDispatch } from "react-redux"
import { startLoading, endLoading } from "../../../Redux/isLoadingSlice"
import AuthUser from '../../../AuthUser';

export default function SideArea() {
   const { http } = AuthUser();
   const dispatch = useDispatch();

   const { localesContext, setPacientes } = useContext(GestionContext);

   const [selectedKey, setSelectedKey] = useState(null);

   const auxProbando = changeDataToTreeTable(localesContext);

   const changeSelection = (e) => {
      console.log(e.value);
      setSelectedKey(e.value);
      let aux = (e.value + "").split("-")
      console.log(aux);
      if (aux.length < 2) {
         console.log("menosr que 2", aux.length);
         dispatch(startLoading());
         getPacienteLocal(aux[0]);
      }
      else {
         dispatch(startLoading());
         getPacienteArea(aux[1]);
      }
   }

   const getPacienteArea = async (idArea) => {
      await http.get(`empresa/gestion/pacientes/area/get/${idArea}`)
         .then(response => {
            if (response.data.data) {
               setPacientes(response.data.data)
            }
            dispatch(endLoading());
         })
         .catch(error => {
            console.error(error);
            dispatch(endLoading());
         })
   }

   const getPacienteLocal = async (idArea) => {
      await http.get(`empresa/gestion/pacientes/local/get/${idArea}`)
         .then(response => {
            if (response.data.data) {
               setPacientes(response.data.data)
            }
            dispatch(endLoading());
         })
         .catch(error => {
            console.error(error);
            dispatch(endLoading());
         })
   }

   return (
      <>
         <Tree
            value={auxProbando}
            selectionMode="single"
            selectionKeys={selectedKey}
            onSelectionChange={(e) => changeSelection(e)}
         />
      </>
   );
}
