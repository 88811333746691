import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AuthUser from '../../AuthUser';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import "../../Style/Recepcion/Recepcion.css";
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { ScrollPanel } from 'primereact/scrollpanel';
import { TreeTable } from 'primereact/treetable';
import './CodigoBarras.css';
import PopUpArchivoZLP from './PopUpArchivoZLP';

const CodigoBarrasDialog = ({ generateBarCodes, visible, setVisible }) => {
   const StyleSubtitulo = {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '500',
      color: '#344054',
      marginBotton: '10px'
   };
   const { http } = AuthUser();

   const [datosGeneralesParticular, setDatosGeneralesParticular] = useState('');
   const [datosGeneralesParticularPerfil, setDatosGeneralesParticularPerfil] = useState('');
   const [localSelecciomado, setlocalSelecciomado] = useState(null);
   const [opcionesLocales, setopcionesLocales] = useState([]);
   const [opcionesprotocolo, setOpcionesProtocolo] = useState([])
   const [opcionesPerfil, setOpcionesPerfil] = useState([])
   const [PopUpZLP, setPopUpZLP] = useState(false);
   const [valorEnviarPost, setValorEnviarPost] = useState({
      perfil_particular_id: "",
      perfiles_particular_examen: [],
      cantidad: 1,
      clinica_local_id: ""
   });
   const [nodes, setNodes] = useState();
   const [value, setValue] = useState(1);
   const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);

   const GetLocal = () => {
      http
         .get("clinica/recursoshumanos/local/get")
         .then((response) => {
            const localesData = response.data.data;

            const opciones = localesData.map((local) => ({
               label: local.nombre,
               value: local.id,
            }));
            setopcionesLocales(opciones);

            console.log('Locales disponibles: ', localesData)
            console.log('Opciones Locales disponibles: ', opciones)
         })
         .catch((error) => {
            console.error(error);
         });
   };
   const GetProtocolo = () => {
      http
         .get('clinica/recepcion/paquete/particular/get')
         .then((response) => {
            setOpcionesProtocolo(response?.data?.data)
         })
   }

   const handleChangePerfil = (e) => {
      setSelectedNodeKeys(null)
      setDatosGeneralesParticularPerfil(e.value);
      setValorEnviarPost({ ...valorEnviarPost, perfil_particular_id: e.value?.perfil.id });
   }

   const handleChangeLocal = (e) => {
      setlocalSelecciomado(e.value);
      setValorEnviarPost({ ...valorEnviarPost, clinica_local_id: e.value });
   }
   const handleChangeCantidad = (e) => {
      setValue(e.value);
      setValorEnviarPost({ ...valorEnviarPost, cantidad: e.value });
   }
   const handleChangeSelectServices = (e) => {
      console.log("aux_servicios", e);
      setSelectedNodeKeys(e.value);
      let aux_servicios = Seleccion_Datos(e.value);
      console.log("aux_servicios", aux_servicios);
      setValorEnviarPost({
         ...valorEnviarPost,
         perfiles_particular_examen: aux_servicios.map((dato) => {
            return {
               "id": dato
            };
         })
      });
   }
   const ImprimePerfilesBase = () => {
      if (datosGeneralesParticularPerfil) {
         setNodes(
            datosGeneralesParticularPerfil.examenes.map((area) => {
               if (area.children && area.children.length > 0) {
                  return {
                     key: area.perfil_particular_examen_id,
                     data: {
                        name: area.nombre,
                     },

                     children: area.children.map((areaSec) => {
                        if (areaSec.children.length > 0) {
                           return {
                              key: areaSec.perfil_particular_examen_id,
                              data: {
                                 name: areaSec.nombre,
                              },

                              children: areaSec.children.map((areaTerc) => {
                                 return {
                                    key: areaTerc.perfil_particular_examen_id,
                                    data: {
                                       name: areaTerc.nombre,
                                    },
                                 }
                              })
                           };
                        }
                        else {
                           return {
                              key: areaSec.perfil_particular_examen_id,
                              data: {
                                 name: areaSec.nombre,
                              },
                           };
                        }
                     }),
                  };
               } else {
                  return {
                     key: area.perfil_particular_examen_id,
                     data: {
                        name: area.nombre,
                     },
                  };
               }
            })
         );
      }
   }

   const Stylecuadro = {
      width: '60px',
      height: '60px',
      borderRadius: '10px',
      backgroundColor: '#EBEBFF'
   };
   const StyleLetracuadro = {
      fontFamily: 'Montserrat',
      fontSize: '20px',
      fontWeight: '700',
      color: '#5555D8',
   };
   const headerDialog = (
      <div className='w-full flex justify-content-start align-items-center'>
         <div className='flex justify-content-center align-items-center' style={Stylecuadro}>
            <i className="pi pi-qrcode" style={{ fontSize: '35px', color: '#5555D8' }}></i>
         </div>
         <p className='ml-2' style={StyleLetracuadro}>
            Impresión masiva de códigos
         </p>
      </div>
   )

   const Seleccion_Datos = (selectedNodeKeys) => {
      const ValoresEnviar = [];
      for (let index = 0; index < 50; index++) {
         if (selectedNodeKeys[index] !== undefined) {
            ValoresEnviar.push(index)
         }
      }
      return ValoresEnviar;
   }
   const EliminarRegistroAvanzado = () => {
      setDatosGeneralesParticular('');
      setOpcionesPerfil('');
      setSelectedNodeKeys(null)
      setNodes();
      setlocalSelecciomado(null);
      setValue(1);
   }


   const footerDialog = (
      <div className='w-full h-auto flex justify-content-start'>
         <Button label="Generar"
            //  disabled={Cod_impresora == ''} 
            onClick={() => {
               setPopUpZLP(true);
            }} icon='pi pi-print' severity="help" />
         <Button label="Cancelar"
            onClick={() => {
               setVisible(false);
               EliminarRegistroAvanzado();
            }}
            style={{ backgroundColor: 'red' }} />
         <Button
            label="Aceptar"
            onClick={() => {
               generateBarCodes(valorEnviarPost);
               console.log("Valor final:", valorEnviarPost);
               // setPopUpZLP(true); // Si es necesario activar setPopUpZLP, puedes descomentarlo aquí
            }}
            severity="danger"
         />

      </div>
   )

   useEffect(() => {
      ImprimePerfilesBase();
   }, [datosGeneralesParticularPerfil]);

   useEffect(() => {
      GetLocal();
      GetProtocolo();
   }, []);



   return (
      <Dialog header={headerDialog} visible={visible} style={{ width: '750px' }} onHide={() => { setVisible(false); EliminarRegistroAvanzado() }} footer={footerDialog}>
         <div className='content_Codig_Barras_AHP w-full h-auto flex align-items-center justify-content-between gap-3' >
            <div className='flex flex-column align-items-center justify-content-end gap-3' style={{ width: '340px', height: '370px' }}>
               <div className="flex flex-column w-full">
                  <p className="m-2" style={StyleSubtitulo}>Protocolo</p>
                  <div>
                     <Dropdown
                        optionLabel="paquete.nombre"
                        options={opcionesprotocolo}
                        value={datosGeneralesParticular}
                        onChange={(e) => {
                           setDatosGeneralesParticularPerfil(null);
                           setNodes(null);
                           setDatosGeneralesParticular(e.value);
                           setOpcionesPerfil(e.value.perfiles);
                        }}
                        className="w-full"
                        placeholder="Selecciona el protocolo"
                     />
                  </div>
               </div>
               <div className="flex flex-column w-full">
                  <p className="m-2" style={StyleSubtitulo}>Perfil</p>
                  <div>
                     <Dropdown
                        optionLabel="perfil.nombre"
                        options={opcionesPerfil}
                        value={datosGeneralesParticularPerfil}
                        onChange={(e) => {
                           handleChangePerfil(e);
                        }}
                        className="w-full"
                        placeholder="Selecciona un perfil"
                     />
                  </div>
               </div>
               <div className="flex flex-column w-full">
                  <p className="m-2" style={StyleSubtitulo}>Cantidad a generar</p>
                  <div>
                     <InputNumber className='w-full' value={value} onValueChange={(e) => handleChangeCantidad(e)} showButtons buttonLayout="horizontal" style={{ width: '4rem' }}
                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                  </div>
               </div>
               <div className="flex flex-column w-full">
                  <p className="m-2" style={StyleSubtitulo}>Seleccionar Local</p>
                  <div>
                     <Dropdown
                        optionLabel="label"
                        options={opcionesLocales}
                        value={localSelecciomado}
                        onChange={(e) => {
                           handleChangeLocal(e)
                        }}
                        className="w-full"
                        placeholder="Selecciona un perfil"
                     />
                  </div>
               </div>
            </div>
            <div className='h-auto pt-1 ' style={{ width: '340px' }}>
               <ScrollPanel className='border-round-xl' style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)', width: '100%', height: '370px', padding: '0' }}>
                  <TreeTable
                     style={{ width: '100%', overflowX: 'hidden' }}
                     value={nodes}
                     selectionMode="checkbox"
                     selectionKeys={selectedNodeKeys}
                     onSelectionChange={(e) => { handleChangeSelectServices(e); }}
                     tableStyle={{ Width: '100%' }}
                  >
                     <Column field="name" header="Servicios" expander></Column>
                  </TreeTable>
               </ScrollPanel>
            </div>
         </div>
         <PopUpArchivoZLP
            metSet={setPopUpZLP}
            valorSet={PopUpZLP}
         />

      </Dialog>
   )
}
export default CodigoBarrasDialog;