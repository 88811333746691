import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import pencil from "../../../Images/roles/pencil.svg";
import { InputText } from "primereact/inputtext";
import React from "react";

const EditRolModalComponent = ({
   rol,
   setRol,
   rolUpdate,
   visibleEdit,
   setVisibleEdit,
   getRoles,
}) => {
   const hideDialogEdit = () => {
      setVisibleEdit(false);
   };
   const handleEditRol = () => {
      rolUpdate(rol);
      getRoles();
      setVisibleEdit(false);
   };

   return (
      <div className="tabla-rol">
         <Dialog
            className="tabla-rol"
            draggable={false}
            style={{ width: "507px", height: "432px"}}
            visible={visibleEdit}
            onHide={hideDialogEdit}
            header={
                <>
                    <img className="pencil1  bg-indigo-100 border-round-lg p-2 mb-2" src={pencil} alt="pencil" />
                    <p style={{ color: "#5555D8" }} className="mb-3" >Editar Rol</p>
                </>
            }
            footer={
               <div className="flex justify-content-center gap-2">
                  <Button
                     label="Cancelar"
                     className="p-button-text"
                     onClick={hideDialogEdit}
                     style={{
                        backgroundColor: "#FFFFFF",
                        color: "black",
                        borderColor: "#CBC9C9",
                        width: "-webkit-fill-available",
                        margin: "0"
                     }}
                  />
                  <Button
                     label="Guardar"
                     className="p-button-success"
                     onClick={handleEditRol}
                     style={{
                        backgroundColor: "#5555D8",
                        borderColor: "#5555D8",
                        width: "-webkit-fill-available",
                        margin: "0"
                     }}
                  />
               </div>
            }
         >
            <div className="flex flex-column gap-4">
                  <label htmlFor="razon_social">
                     En esta sección usted puede edita el rol.
                  </label>
                  <InputText
                     id="edit-rol-nombre"
                     name="edit-rol-nombre"
                     defaultValue={rol?.nombre}
                     onChange={(e) =>
                        setRol({ ...rol, nombre: e.target.value })
                     }
                  />
               </div>
         </Dialog>
      </div>
   );
};

export default EditRolModalComponent;
