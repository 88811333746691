import React, { useEffect, useRef, useState } from "react";
import AuthUser from "../../../../AuthUser";
import { Button } from "primereact/button";
import ModalCreate from "../../../../Components/RecursosHumanos/Personal/ModalCreatePaciente";
import ModalEditar from "../../../../Components/RecursosHumanos/Personal/ModalEditar";
import ModalCredenciales from "../../../../Components/RecursosHumanos/Personal/ModalCredenciales";
import ModalEliminar from "../../../../Components/RecursosHumanos/Personal/ModalEliminar";
import "../../../../Style/CSS_tabla.css";
import AsignarServicioDialog from "./Dialog/DialogAsignarServicio";
import "../../../../Style/Botones.css";
import { Toast } from "primereact/toast";
import CargarData from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarData";
import CargarDatosDNI from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarDatosDNI";
import TablaCrud from "../../../../Components/Table/TablaCrud";
import { Column } from "primereact/column";
import AsignarServicioGrupal from './Dialog/AsignarServicioGrupal';
function PagePersonalPacientes() {
   const { http } = AuthUser();

   const toast = useRef(null);
   const [tipoDocumentos, setTipoDocumentos] = useState([]);
   const [personal, setPersonal] = useState([]);
   const [personaleliminar, setPersonaleliminar] = useState([]);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleCredenciales, setVisibleCredenciales] = useState(false);
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [visibleAsignar, setVisibleAsignar] = useState(false);
   const [visibleAsignarGrupal, setVisibleAsignarGrupal] = useState(false);
   const [locales, setLocales] = useState([]);
   const [visibleDialogDNI, setVisibleDialogDNI] = useState(false);
   const [visibleDialog, setVisibleDialog] = useState(false);
   const [roles, setRoles] = useState([]);
   const [data, setData] = useState([]);
   const [contratos, setContratos] = useState([])
   const [selectedPersonal, setSelectedPersonal] = useState(null);
   const [rowClick, setRowClick] = useState(null)
   const [allLocales, setAllLocales] = useState([])
   const [selectPersonal, setSelectPersonal] = useState({
      id: 0,
      tipo_documento: "",
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "2022-01-01",
      distrito_id: 2,
      celular: "",
      correo: "",
      usuario: "",
      rol: "",
   });

   //Fin de Paginacion

   const getAllTipoDocumentos = () => {
      http
         .get("/tipodocumentos/get")
         .then((response) => {
            if (response?.data?.data) {
               setTipoDocumentos(response.data.data);
            } else if (response?.data?.error) {
               showToast('error', 'Error', response.data.error)
            }
         })
         .catch((error) => {
            showToast('error', 'Error', 'Contáctese con soporte técnico.')
         });
   };

   const getRoles = () => {
      http
         .get("roles/get")
         .then((response) => {
            setRoles(response?.data?.data);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const getPersonal = () => {
      http
         .get("empresa/personal/get")
         .then((response) => {
            setPersonal(response.data?.data);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const asignarPaquetes = (data) => {
      http
         .post("empresa/personal/asignar/perfil", data)
         .then((response) => {
            showToast(
               "success",
               "Asignado correctamente",
               "Se asignó correctamente al personal"
            );
         })
         .catch((error) => {
            console.log(error);
            showToast(
               "error",
               "Fallo al asignar",
               "No se asignó correctamente al personal"
            );
         });
   };

   const asignarPaqueteGrupal = (data) => {
      http
         .post("empresa/personal/asignar/servicio", data)
         .then((response) => {
            showToast("success", "Envío grupal", "Grupo asignado correctamente")
         })
         .catch((error) => {
            console.log(error);
            showToast(
               "error",
               "Fallo al asignar",
               "No se asignó correctamente al personal"
            );
         });
   }

   const getLocales = (id) => {
      http
         .get(`empresa/local/get/${id}`)
         .then((response) => {
            setLocales(response?.data?.data)
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const subirArchivoDNI = (data) => {
      const formData = new FormData();
      formData.append("file", data);
      http
         .post("/empresa/personal/import/dni", formData)
         .then((response) => {
            setTipoDocumentos(response.data.data);
         })
         .catch((error) => {
            console.log(error);
         });
   };
   const subirArchivo = (data) => {
      const formData = new FormData();
      formData.append("file", data);
      http
         .post("/empresa/personal/import", formData)
         .then((response) => {
            setTipoDocumentos(response);
            getPersonal()
            setVisibleDialog(false);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const getContract = () => {
      http
         .get("tipocontrato/show")
         .then((response) => {
            setContratos(response?.data)
         })
   }

   const handleObtenerPuesto = () => {
      http
         .get("/empresa/cargo/get1")
         .then((response) => {
            setData(response.data.data);
         })
         .catch((error) => {
            console.log("error", error);
         })
   };

   const showCredencialesModal = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleCredenciales(true);
   };

   const actionBodyCredenciales = (rowData) => {
      return (
         <Button
            icon="pi pi_tamaño pi-id-card"
            className="p-button-text"
            onClick={() => showCredencialesModal(rowData)}
         />
      )
   }

   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   const actionBodyTemplateAsignar = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-send"
               className="p-button-text"
               onClick={() => asignarServicio(rowData)}
               style={{ height: "32px" }}
            />
         </React.Fragment>
      );
   };

   const actionBodyTemplate = (rowData) => {
      return (

         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => editPaquete(rowData)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />

         </React.Fragment>
      );
   };

   const asignarServicio = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleAsignar(true);
   };

   const editPaquete = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleEdit(true);
   };

   const deletePaquete = (rowData) => {
      setPersonaleliminar(rowData);
      setVisibleEliminar(true);
   };



   const openDialog = () => {
      setVisibleDialog(true)
   };
   const openDialogDNI = () => {
      setVisibleDialogDNI(true)
   };


   useEffect(() => {
      getAllTipoDocumentos();
      getPersonal();
      getRoles();
      handleObtenerPuesto();
      getContract();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      getLocales(selectedPersonal?.[0]?.locales[0]?.clinica_id)
   }, [selectedPersonal])

   return (
      <div className="flex flex-column gap-2">
         <Toast ref={toast} />
         <div className="flex flex-column">
            <h1 className="titulo w-full">Mis Pacientes</h1>
            <p className="subtitulo" style={{ marginBottom: "10px" }}>Adminístralo de la mejor forma</p>
         </div>

         {/* <div className="flex flex-wrap justify-content-between ">
            <div className="boton-personal-empresa">
               <div className="aten-areas flex flex-wrap justify-content-between gap-2">
                  <button
                     onClick={() => setVisibleCreate(true)}
                     style={{
                        borderRadius: "11px",
                        border: "1px solid #248D63",
                        height: "43px",
                        color: "#fff",
                        background: "#07a34f",
                        fontSize: "16px",
                        cursor: "pointer",
                     }}
                  ><i className="pi pi-plus"></i>
                     <span>
                        Crear
                     </span>
                  </button>

                  <button
                     style={{
                        borderRadius: "11px",
                        border: "1px solid #5555D8",
                        height: "43px",
                        color: "#5555D8",
                        background: "#fff",
                        fontSize: "16px",
                        cursor: "pointer",
                     }}
                     onClick={openDialog}
                  ><i className="pi pi-file"></i>
                     <span>
                        Cargar datos
                     </span>
                  </button>

                  <button
                     onClick={() => setVisibleAsignar(true)}
                     style={{
                        borderRadius: "11px",
                        border: "1px solid #5555D8",
                        height: "43px",
                        width: "175px",
                        background: "#5555D8",
                        color: "#fff",
                        fontSize: "16px",
                        cursor: "pointer",
                     }}
                  >
                     <i className="pi pi-file"></i>
                     <span>Asignar servicios</span>
                  </button>

                  <button
                     style={{
                        borderRadius: "11px",
                        border: "1px solid #5555D8",
                        height: "43px",
                        width: "200px",
                        color: "#5555D8",
                        background: "#fff",
                        fontSize: "16px",
                        cursor: "pointer",
                     }}
                     onClick={openDialogDNI}
                  ><i className="pi pi-file"></i>
                     <span>
                        Carga de datos DNI
                     </span>
                  </button>

               </div>
            </div>
            <span className="p-input-icon-left corporativo" style={{ display: "flex", alignItems: "center", padding: "10px 0" }}>
               <i className="pi pi-search" />
               <InputText placeholder="Buscar..." style={{ width: "100%" }} />
            </span>



         </div>
         <div className="ConenedorTabla_Personal" style={{ height: "550px" }}>
            <ScrollPanel style={{ width: '99%', height: '100%' }} className="custombar1">
               <table className="Tabla_Servicios_Breegma" style={{ background: "#fff", width: "100%" }}>
                  <thead>
                     <tr>
                        <th style={{ whiteSpace: "wrap" }}>Item</th>
                        <th style={{ whiteSpace: "wrap" }}>Estado</th>
                        <th style={{ whiteSpace: "wrap" }}>Tipo_Doc</th>
                        <th style={{ whiteSpace: "wrap" }}>N° de Doc.</th>
                        <th style={{ whiteSpace: "wrap" }}>Nombre Completos</th>
                        <th style={{ whiteSpace: "wrap" }}>Contrato</th>
                        <th style={{ whiteSpace: "wrap" }}>Detalles</th>
                        <th style={{ whiteSpace: "wrap" }}>Enviar credenciales</th>
                        <th style={{ whiteSpace: "wrap" }}>Enviar servicio</th>
                        <th style={{ whiteSpace: "wrap" }}>Acciones</th>
                     </tr>
                  </thead>
                  <tbody>
                     {currentPersonal?.map((item) => (
                        <tr key={item?.id}>
                           <td
                              data-label="Item"
                              style={{ borderLeft: "0px solid transparent" }}
                           >
                              <div className="flex flex-row gap-2 justify-content-center">
                                 {actionBodyTemplateAsignarcheck(item)}
                                 {item?.id}
                              </div>
                           </td>
                           <td style={{ whiteSpace: "wrap" }}
                              data-label="Estado">
                              <BotonEstado estado="Programado" />
                           </td>
                           <td style={{ whiteSpace: "wrap" }} data-label="Tipo de Documento">
                              {item?.persona?.tipo_documento?.nombre}
                           </td>
                           <td style={{ whiteSpace: "wrap" }} data-label="N° de Doc.">
                              {item?.persona?.numero_documento}
                           </td>
                           <td style={{ whiteSpace: "wrap" }}
                              data-label="Nombre Completo">
                              {item?.persona?.nombres}{" "}
                              {item?.persona?.apellido_paterno}{" "}
                              {item?.persona?.apellido_materno}

                           </td>
                           <td data-label="Contrato">

                           </td>
                           <td data-label="Detalles">
                              <DialogDetallesPersonal />
                           </td>
                           <td style={{ whiteSpace: "wrap" }} data-label="Asignar credenciales">
                              {actionBodyCredenciales(item)}
                           </td>
                           <td style={{ whiteSpace: "wrap" }} data-label="Asignar servicio">
                              {actionBodyTemplateAsignar(item)}
                           </td>
                           <td style={{ whiteSpace: "wrap" }} data-label="Acciones">
                              {actionBodyTemplate(item)}
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </ScrollPanel>

         </div> */}
         <div className="content-table-rol flex w-12 justify-content-end table centrar">
            <TablaCrud
               selectionMode={rowClick ? 'checkbox' : null}
               selection={selectedPersonal}
               onSelectionChange={(e) => setSelectedPersonal(e.value)}
               dataKey="id"
               name={"personal"}
               value={personal}
               leftLabelButton1={"Crear"}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
            // leftLabelButton3={"Asignar servicios"}
            // onClickLeftButton3={() => { setVisibleAsignarGrupal(true) }}
            // leftLabelButton5={"Cargar datos"}
            // onClickLeftButton5={() => openDialog()}
            // leftLabelButton6={"Cargar datos DNI"}
            // onClickLeftButton6={() => openDialogDNI()}

            >
               <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
               <Column
                  className='ccc1'
                  field="persona.tipo_documento.nombre"
                  header={<span className="header-rol" >Documento</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc2'
                  field="persona.numero_documento"
                  header={<span className="header-accesos">Num. Documento</span>}
                  style={{ minWidth: "60%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

               <Column
                  className='ccc4'
                  field="persona.nombres"
                  header={<span className="header-locales">Nombres completos</span>}
                  style={{ minWidth: "60%", whiteSpace: "wrap", textAlign: "center" }}>
               </Column>
               {/* <Column
                  className='ccc3'
                  header={<span className="header-acciones">Contrato</span>}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>
               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Detalles</span>}
                  // body={actionBodyCredenciales}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column> */}
               {/*
               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Envio Credenciales</span>}
                  body={actionBodyCredenciales}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>
                */   }
               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Envio Servicios</span>}
                  body={actionBodyTemplateAsignar}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>
               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Acciones</span>}
                  body={actionBodyTemplate}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

            </TablaCrud>
         </div>
         {/* <div className="footer-personal-empresa">
            <div className="flex justify-content-between h-full w-full" style={{ margin: "20px 0 20px" }}>
               <ParteInferior
                  estado="En  proceso"
                  estado2="Programado"
                  estado3="Sin programar"
               />

               <div className="flex flex-wrap justify-content-evenly gap-5 pagination-component" style={{ flexDirection: "row" }}>
                  <button
                     style={{ cursor: "pointer" }}
                     className="btn-current-next"
                     onClick={() => handlePageChange(currentPage - 1)}
                     disabled={currentPage === 1}
                  >
                     <i className="pi pi-angle-left"></i>
                  </button>

                  <button
                     style={{ cursor: "pointer" }}
                     className="btn-current-back"
                     onClick={() => handlePageChange(currentPage + 1)}
                     disabled={currentPage === totalPages}
                  >
                     <i className="pi pi-angle-right"></i>
                  </button>
               </div>

            </div>
         </div> */}
         {/* Modales */}
         <AsignarServicioDialog
            visibleAsignar={visibleAsignar}
            setVisibleAsignar={setVisibleAsignar}
            item={selectPersonal}
            getLocales={getLocales}
            locales={locales}
            asignarPaquetes={asignarPaquetes}
            data={data}
         />

         <AsignarServicioGrupal
            visibleAsignar={visibleAsignarGrupal}
            setVisibleAsignar={setVisibleAsignarGrupal}
            item={selectedPersonal}
            examenes={selectedPersonal}
            locales={locales}
            getLocales={getLocales}
            // tipoServicio={tipoServicio}
            asignarPaquetes={asignarPaqueteGrupal}
         // data={data}
         />

         <ModalCreate
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api={"/empresa/personal/create/paciente"}
            data={data}
            contractOptions={contratos}
         />

         <ModalEditar
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            personal={selectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api={"empresa/personal/update"}
            data={data}
            contractOptions={contratos}
         />

         <ModalCredenciales
            visibleCredenciales={visibleCredenciales}
            setVisibleCredenciales={setVisibleCredenciales}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />

         <ModalEliminar
            visibleEliminar={visibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            personal={personal}
            setPersonal={personaleliminar}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api={"/empresa/personal/delete"}
         />

         <CargarDatosDNI
            visible={visibleDialogDNI}
            setVisible={setVisibleDialogDNI}
            subirArchivo={subirArchivoDNI}
         />

         <CargarData
            visible={visibleDialog}
            setVisible={setVisibleDialog}
            subirArchivo={subirArchivo}
         />

      </div>
   );
}

export default PagePersonalPacientes;