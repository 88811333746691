import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState, } from "react";

import { useFormik } from "formik";
import AuthUser from "../../../../AuthUser";
import axios from "axios";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { endLoading, startLoading } from "../../../../Redux/isLoadingSlice";

export default function ModalCreatePuestos({
  visibleCreate,
  setVisibleCreate,
  showToast,
  api,
  tipoRiesgos,
  getCargos,
  nombresPuestosTrabajo, // Recibe la lista de nombres
  setNombresPuestosTrabajo, // Recibe la función para actualizar la lista
  dispatch
}) {

  const { http } = AuthUser();
  const TipoRiesgo = [{ name: "Alto Riesgo" }, { name: "Medio Riesgo" }, { name: "Bajo Riesgo" }];

  const [selectriesgo, setselectriesgo] = useState(null);

  let options = [
    { label: 'Es usuario', value: true },
    { label: 'No es usuario', value: false }
  ]

  const [value, setValue] = useState("");
  const initialValues = {
    id: 0,
    nombre: "",
    tipo_riesgo_id: "",

  };

  const formik = useFormik({
    initialValues: initialValues,//Raíz
    onSubmit: async (data) => {
      console.log("data", data)

      let data_send = {
        nombre: data.nombre,
        tipo_riesgo_id: data.tipo_riesgo_id.id
      }
      console.log("data_send", data_send)
      dispatch(startLoading());

      try {
        const response = await http.post(api, data_send)
        if (response?.data?.resp) {
          console.log(response?.data?.resp);
          await Promise.all([
            getCargos()
          ])
          HideDialogCreate();
          showToast("success", "Personal creado", "Se creo correctamente el personal");
        } else if (response?.data?.error) {
          showToast("error", "Error", response.data.error)
        }
      } catch (error) {
        console.log("Hay un error", error)
        HideDialogCreate();
        showToast("error", "Error", "Contáctese con Soporte Técnico.")
      } finally {
        dispatch(endLoading());
      }
    },
  });

  const HideDialogCreate = () => {
    setVisibleCreate(false);
    formik.resetForm();
    setValue(null);

  };

  const paqueteCreateDialogFooter = (
    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="flex w-full bot">
      <Button style={{ width: "35%" }}
        type="button"
        label="Cancelar"
        className="delete"
        onClick={() => {
          HideDialogCreate();
        }}
      />
      <Button
        type="submit"
        label="Crear"
        onClick={formik.handleSubmit}
        className="p-button-success"
        style={{
          width: "65%",
          backgroundColor: "#5555D8",
          borderColor: "#5555D8"
        }}
      />
    </div>
  );

  useEffect(() => {
    // console.log("USUARIOOOO", value);
    // if (value==="Si soy usuario"){
    //   formik.setFieldValue('usuario', "1")
    // } else if (value==="No soy usuario"){
    //   formik.setFieldValue('usuario', "0")
    // }

  }, [value]);

  return (
    <div>

      <form
        id="persona-form"
        className="persona-form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog
          visible={visibleCreate}
          style={{
            width: "450px",
            height: "auto",
            borderRadius: '25px'
          }}
          appendTo={document.getElementById("persona-form")}
          header={
            <>
              <div className="flex align-items-center">
                <div className="flex justify-content-center align-items-center"
                  style={{ backgroundColor: "#EBEBFF", width: "60px", height: "60px", borderRadius: "10px", marginRight: '7px' }}
                >
                  <div className="flex justify-content-center align-items-center">
                    <i className="pi pi-briefcase" style={{ color: '#5555d8', fontSize: '2.5rem' }}></i>
                  </div>
                </div>
                <h1 className="per" style={{ fontSize: '22px' }}>Crear Puesto de Trabajo</h1>
              </div>
            </>
          }
          dismissableMask
          className="p-fluid "
          footer={paqueteCreateDialogFooter}
          onHide={() => {
            HideDialogCreate();
          }}
        >
          <div>
            <div className="flex flex-column w-full">
              <p className="m-0">
                En esta sección usted puede registrar los
                puestos de trabajo.
              </p>

              <div style={{ margin: "15px 0px", flexWrap: "wrap" }} className="flex w-full flex-column">
                <p style={{ margin: "0px 0px 8px 0px" }} className="per1">Puesto de Trabajo</p>
                <InputText id="nombre" name="nombre" value={formik.values.nombre} onChange={formik.handleChange} style={{ width: "100%" }} />
              </div>
              <div style={{ margin: "15px 0px" }} className="flex w-full flex-column">
                <p>Potencial de Riesgo</p>
                <Dropdown
                  id="tipo_riesgo_id"
                  name="tipo_riesgo_id"
                  value={formik.values.tipo_riesgo_id}
                  onChange={(e) => { formik.setFieldValue("tipo_riesgo_id", e.value) }}
                  options={tipoRiesgos}
                  optionLabel="nombre"
                  placeholder="Seleccionar Tipo de Riesgo"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
