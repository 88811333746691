import React, { useState, useEffect, useContext } from "react";
import { Tag } from "primereact/tag";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { TreeTable } from "primereact/treetable";
import { Button } from "primereact/button";
import { SalesContext } from "../../SalesContext/SalesContext";
import '../../../../Style/ventas_psicologia.css';

export default function DialogServicios({ show, setShow, data, priceType, tipoData, packDoc, sendPaquetecopy, setSendPaquetecopy, }) {

   console.log("los documentos son", packDoc)
   console.log("datos_envio",data)
   const { sendPaquete, setSendPaquete } = useContext(SalesContext);
   const [rowData, setRowData] = useState(false)
   const [visible, setVisible] = useState(false)
   const [precioEdit, setPrecioEdit] = useState(null);
   const [NombrePrecio, setNombrePrecio] = useState(null);
   console.log(data)
   console.log("sendPaquete",sendPaquete)
   console.log("tipo",tipoData)
   console.log("copy",sendPaquetecopy)
   console.log(packDoc);
 
   //BUSCAR AREAS CON CHILDREN

   let areasPadre = getNamesWithNonEmptyChildren(data)

   function eliminarSegundoRegistro(array) {
      // Verificamos si el argumento pasado es un array y tiene al menos dos elementos
      if (Array.isArray(array) && array.length >= 2) {
         // Creamos una copia del segundo elemento
         const segundoRegistro = array[1];

         // Eliminamos el segundo elemento del array original
         const newArray = array.filter((_, index) => index !== 1);

         // Retornamos el nuevo array sin el segundo elemento y el segundo elemento eliminado
         return [newArray, segundoRegistro];
      } else {
         // Si no se cumple la condición, retornamos undefined para ambos valores
         return [undefined, undefined];
      }
   }

   const [nuevoArray, segundoRegistroEliminado] = eliminarSegundoRegistro(data);

   function getNamesWithNonEmptyChildren(items, hijo) {
      let names = hijo ? [] : ["areas_medicas", "capacitaciones", "examenes"];

      items?.forEach(item => {
         if (item.children.length > 0) {
            names.push(item.data.nombre);
            names.push(...getNamesWithNonEmptyChildren(item.children, true));
         }
      });

      return names;
   }


   //FUNCION EDITAR PRECIO VENTA ANUAL/MENSUAL
   const editarPrecioVenta = (p, salePrice) => {

      let updatedPaquete = null;

      if (sendPaquetecopy === null) {
         updatedPaquete = BuscarHijo(data, p, salePrice)

      } else {
         updatedPaquete = BuscarHijo(sendPaquetecopy, p, salePrice)
      };

      setSendPaquetecopy(updatedPaquete);
      console.log("paquete actualizado", updatedPaquete)
      setVisible(false);
      setPrecioEdit(null)
   };

   //BUSCAR HIJO PARA EDITAR PRECIO
   function BuscarHijo(data, keyEdit, salePrice) {

      return data.map((item) => {

         const isPrecioMensual = salePrice === 'precio_mensual';
         const isPrecioAnual = salePrice === 'precio_anual';
         const dataEdit = item.key === keyEdit.key

         const node = {
            key: item.key,
            data: {
               nombre: item.data.nombre,
               precio_referencial_anual: item.data.precio_referencial_anual || 0,
               precio_referencial_mensual: item.data.precio_referencial_mensual || 0,
               precio_mensual: dataEdit && isPrecioMensual ? precioEdit || 0 : item.data.precio_mensual || 0,
               precio_anual: dataEdit && isPrecioAnual ? precioEdit || 0 : item.data.precio_anual || 0,
            },
            children: [],
         };

         if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            node.children = BuscarHijo(item.children, keyEdit, salePrice);
         }

         return node;
      });
   }

   //GUARDAR DATOS
   const GuardarData = () => {
      let newDataServicio = sendPaquete
      let dataFiltrada = newDataServicio.map(servicio => {
         if (servicio.nombre === tipoData.nombreServicio) {
            return {...servicio,
               id: servicio.id,
               nombre: servicio.nombre,
               servicios: {
                  ingreso: tipoData.tipo === 1 ? sendPaquetecopy : servicio?.servicios?.ingreso,
                  periodico: tipoData.tipo === 2 ? sendPaquetecopy : servicio?.servicios?.periodico,
                  retiro: tipoData.tipo === 3 ? sendPaquetecopy : servicio?.servicios?.retiro
               },
               precios: {
                  ingreso: tipoData.tipo === 1 ? sumarPrecioVenta() : servicio?.precios?.ingreso,
                  periodico: tipoData.tipo === 2 ? sumarPrecioVenta() : servicio?.precios?.periodico,
                  retiro: tipoData.tipo === 3 ? sumarPrecioVenta() : servicio?.precios?.retiro
               }
               
            }
         } else {
            return servicio
         }

      });
      setSendPaquete(dataFiltrada)
   }

   useEffect(() => {
      console.log("datos_inicio")
      setSendPaquetecopy(data)
   }, [data])
   const header = (
      <div className="flex flex-wrap w-full gap-3 pt-2">
         <div className="dialogs_header_around_icon">
            <i className="i-icon-header-dialog-blue"></i>
         </div>
         <div className="dialogs_header_subtitle" style={{ marginTop: "15px" }}>
         Áreas seleccionadas en este paquete - {tipoData.tipo === 1 ? 'ingreso' : (tipoData.tipo === 2 ? 'periodico' : 'retiro')}
         </div>
      </div>
   );
   const [globalFilter, setGlobalFilter] = useState('');
   const getHeader = () => {
      return (
         <div className="flex justify-content-end">
            <div className="p-input-icon-left">
               <i className="pi pi-search" style={{}}></i>
               <InputText style={{ textAlign: 'left', marginLeft: "50px" }}
                  type="search"
                  onInput={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Buscar área médica" />
            </div>
         </div>
      );
   };

   let headerTable = getHeader();

   const sumarPrecioVenta = () => {
      let suma = 0;

      const calcularSuma = (data) => {
         if (data) {
            data.forEach((item) => {
               const precioVenta = parseFloat(item.data.precio_mensual);
               suma += !isNaN(precioVenta) ? precioVenta : 0;

               if (item.children && item.children.length > 0) {
                  calcularSuma(item.children);
               }
            })
         };
      };

      calcularSuma(sendPaquetecopy);
      
      return suma.toFixed(2);
   };

   // Suma Precio Referencial
   const sumarPrecioReferencial = () => {
      let suma = 0;

      const calcularSuma = (data) => {
         if (data) {
            data.forEach((item) => {
               suma += parseFloat(item.data.precio_referencial_mensual || 0);
               if (item.children && item.children.length > 0) {
                  calcularSuma(item.children);
               }
            })
         };
      };

      calcularSuma(sendPaquetecopy || nuevoArray);
      return suma.toFixed(2);
   };

   const [searchTerm1, setSearchTerm1] = useState("");

  // Función para filtrar los documentos según el término de búsqueda
  const filteredDocs = packDoc?.filter(doc =>
    doc.nombre.toLowerCase()?.includes(searchTerm1.toLowerCase())
  );




   const content = (
      <>
         <div className="flex flex-block">
            <div style={{ width: "50vw" }}>
               <TreeTable
                  //value={nuevaTransformacion(data)}
                  globalFilter={globalFilter}
                  header={headerTable}
                  value={sendPaquetecopy || nuevoArray}
                  className="sin_capacitaciones"
                  // selectionMode="checkbox"
                  // selectionKeys={selectedItems}
                  // onSelectionChange={(e)=> selectedOnChange(e.value, setSelectedItems, tipo )}
                  style={{
                     height: "100%",
                     marginRight: "20px",

                     // overflow: "scroll",
                  }}
               >
                  <Column field="nombre" expander className="psicologia" />
                  <Column
                     field="data.pVenta"
                     styleClass="data-pVenta"
                     header="Precio Referencial"
                     className="text-center text-green-500 flex-column p-27" // Agregar la clase flex-column
                     body={(node) => (
                        <>
                           {areasPadre.includes(node.data.nombre) || areasPadre.includes(node.key) ? (
                              <span></span>
                           ) : (
                              <Tag className="text-center">
                                 S/ {priceType === 'precio_mensual' ? node.data.precio_referencial_mensual : node.data.precio_referencial_anual}
                              </Tag>
                           )}
                        </>
                     )}
                  ></Column>
                  <Column
                     field="precio_mensual"
                     header="Precio de Venta"
                     styleClass="precio_mensual"

                     className="text-center text-green-500 flex-column p-27" // Agregar la clase flex-column
                     body={(node) => (
                        <>
                           {areasPadre.includes(node.data.nombre) || areasPadre.includes(node.key) ? (
                              <span></span>
                           ) : (
                              <Tag onClick={() => {
                                 setRowData(node);
                                 setVisible(true);
                                 setPrecioEdit(node.data.precio_mensual);
                                 setNombrePrecio(node.data.nombre);
                              }} className="text-center">
                                 S/ {priceType === 'precio_mensual' ? node.data.precio_mensual : node.data.precio_anual}
                                 {/* S/ {priceType === 'precio_mensual' ? node.data.precio_referencial_mensual : node.data.precio_referencial_anual} */}

                              </Tag>
                           )}
                        </>
                     )}
                  />
               </TreeTable>
                              
            </div>
            <div className="flex flex-column bg-gray-300 border-gray-300 align-items-start"
               style={{ paddingLeft: "20px", paddingTop: "20px", border: "20px", borderRadius: "5px", width: "20vw" }}
            >
               <div className="flex justify-content-center">
                  <h1 className="text-center" style={{ paddingTop:"0px", color: "#5555d8"}}>Documentos</h1>
               </div>
               <InputText
                  type="text"
                  placeholder="Buscar documentos..."
                  value={searchTerm1}
                  onChange={(e) => setSearchTerm1(e.target.value)}
                  className=" border rounded-md"
                  style={{ width: "16vw" ,borderRadius:"50px"}}
                  />
               <div className="flex flex-block align-items-center" style={{ width: "16vw" }}>
                  <div className="flex flex-column" style={{ width: "100%" }}>
                        {filteredDocs?.map((doc, index) => (
                           <div key={index} className="w-100 py-1 flex border-round-md " style={{ width: "100%" }}>
                           <strong style={{background:"white",border:"10px"}}>✓ {doc?.nombre}</strong>
                           </div>
                        ))}
                  </div>
               </div>
            </div>
         </div>
      </>
   );

   const footer = (
      <div className="flex w-full align-items-center justify-content-end" style={{ paddingTop: "20px" }}>
         <div className="flex  justify-content-between mr-5 ml-4 gap-6 border-round-md p-2">

            <strong>Total</strong>
            <strong>
               S/{sumarPrecioReferencial()}
            </strong>

            
            <strong className="mr-6">
               S/{sumarPrecioVenta()}
               {/* S/{sumarPrecioReferencial()} */}

            </strong>
         </div>
         <button
            className="dialogs_butons_default_secondary"
            onClick={() => setShow(false)}
            style={{ color: "#6366F1", border: "1px solid #6366F1" }}
         >
            Cancelar
         </button>
         <button className="dialogs_butons_default_primary"
            onClick={() => { GuardarData(); setShow(false) }}
         >Guardar</button>
      </div>
   );

   return (
      <>
         <Dialog
            header={header}
            footer={footer}
            style={{ width: "70vw", height: "600px" }}
            visible={show}
            onHide={() => setShow(false)}
         >
            {content}
         </Dialog>

         <Dialog
            visible={visible}
            header={<p style={{ color: "#6366F1" }} className="pr-4">Nuevo precio {NombrePrecio} </p>}
            onHide={() => setVisible(false)}
         >
            <div className="flex flex-column">
               <InputText defaultValue={precioEdit} onChange={(e) => setPrecioEdit(e.target.value)}></InputText>

            </div>
            <div className="flex justify-content-end" style={{ marginTop: "20px" }}>
               <Button
                  style={{ marginRight: "5px" }}
                  onClick={() => editarPrecioVenta(rowData, priceType)}
                  label="Actualizar" ></Button>
               <Button onClick={() => setVisible(false)} label="Cancelar" ></Button>
            </div>
         </Dialog>

      </>
   );
}
