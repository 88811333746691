import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { useParams } from "react-router-dom";
//ORIGINALS
import S1Customer from "../../../Components/Sales/NewClient/S1Customer/S1Customer";
import S2Contact from "../../../Components/Sales/NewClient/S2Contact/S2Contact";
import S3Product from "../../../Components/Sales/NewClient/S3Product/S3Product";
import S4Credentials from "../../../Components/Sales/NewClient/S4Credentials/S4Credentials";
//SKELETONS
import SS1Customer from "../../../Components/Sales/SkeletonSales/SS1Customer/SS1Customer";
import SS2Contact from "../../../Components/Sales/SkeletonSales/SS2Contact/SS2Contact";
import SS3Product from "../../../Components/Sales/SkeletonSales/SS3Product/SS3Product";
import SS4Credentials from "../../../Components/Sales/SkeletonSales/SS4Credentials/SS4Credentials";
//SERVICES
import SalesCommon from "../../../Components/Sales/Services/SalesCommon/SalesCommon";
import SB1Customer from "../../../Components/Sales/Services/Bregma/SB1Customer/SB1Customer";
import SB2Contact from "../../../Components/Sales/Services/Bregma/SB2Contact/SB2Contact";
//OTROS COMPONENTES DE VENTAS
import StepsV from "../../../Components/Ventas/StepsV/StepsV";
import { SalesContext } from "../../../Components/Sales/SalesContext/SalesContext";
import SB3Product from "../../../Components/Sales/Services/Bregma/SB3Product/SB3Product";

import { startLoading,endLoading } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

const PageNewClient = () => {
    //#region VARIABLES DE INSTANCIAS
    const { sales, setSales } = useContext(SalesContext);
    const { getLocations, getTypesOfCustomers, getSectors } = SalesCommon();
    const { postClinic, putClinic, getPackages, getClinicID } = SB1Customer();
    const { getContact, getContactID, postContact, putContact, deleteContact } = SB2Contact();
    const { postPackage } = SB3Product();
    const { id } = useParams();
    const dispatch =useDispatch()
    //#endregion

    //#region VARIABLES

    const [items, setItems] = useState([
        {
            label: 'Empresa',
            estado: false,
        },
        {
            label: 'Contacto',
            estado: false,
        },
        // {
        //     label: 'Producto',
        //     estado: false,
        // },
        // {
        //     label: 'Credenciales',
        //     estado: false,
        // },
    ]);
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState(null);
    const [typesOfCustomers, setTypesOfCustomers] = useState(null);
    const [sectors, setSectors] = useState(null);
    const [packages, setPackages] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef(null);

    const getContent = (index) => {
        switch (index) {
            case 0:
                return !loading ? <S1Customer ubicaciones={locations} tipos_clientes={typesOfCustomers} rubros={sectors} showToast={showToast} validateForm={validateForm} postClinic={postClinic} putClinic={putClinic} /*getID={getClinicID}*/ /> : <SS1Customer />;
            case 1:
                return !loading ? <S2Contact getContact={getContact} getContactID={getContactID} postContact={postContact} putContact={putContact} deleteContact={deleteContact} validateForm={validateForm} showToast={showToast} /> : <SS2Contact />;
            // case 2:
            //     return !loading ? <S3Product packages={packages} postPackages={postPackage} showToast={showToast} /> : <SS3Product />;
            // case 3:
            //     return !loading ? <S4Credentials getContact={getContact} showToast={showToast} /> : <SS4Credentials />;
            default:
                return null;
        }
    };
    //#endregion

    //#region FUNCIONES
    const handleBack = () => {
        const updatedItems = [...items];
        updatedItems[activeIndex].estado = false;
        updatedItems[activeIndex - 1].estado = false;
        setItems(updatedItems);
        setActiveIndex(activeIndex - 1);
    };
    const handleNext = () => {
        //FALTAN MÁS CONDICIONES POR CADA PASO
        if (sales.GCEmpresa.clinica_id) {
            const updatedItems = [...items];
            updatedItems[activeIndex].estado = true;
            setItems(updatedItems);
            setActiveIndex(activeIndex + 1);
        }
    };
    const validateForm = (formulario) => {
        for (const propiedad in formulario) {
            if (formulario[propiedad] === "") {
                return false;
            }
        }
        return true;
    };
    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity: severity, summary: summary, detail: detail });
    };

    //#endregion

    useEffect(() => {
        const fetchData = async () => {
            const promises = [
                getTypesOfCustomers(),
                getLocations(),
                getSectors(),
                getPackages(),
            ];

            try {
                const results = await Promise.allSettled(promises);

                const valueTipoClientes = results[0].status === "fulfilled" ? results[0].value : null;
                const valueUbicaciones = results[1].status === "fulfilled" ? results[1].value : null;
                const valueRubros = results[2].status === "fulfilled" ? results[2].value : null;
                const valuePaquetes = results[3].status === "fulfilled" ? results[3].value : null;

                setTypesOfCustomers(valueTipoClientes);
                setLocations(valueUbicaciones);
                setSectors(valueRubros);
                setPackages(valuePaquetes);

                if (id) {
                    setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, clinica_id: id } }));
                    await getClinicID(id, valueUbicaciones, setSales);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error en la llamada a la API:', error);
            }finally{
                dispatch(endLoading())
            }
        }
        dispatch(startLoading())
        fetchData();
    }, []);


    const Ver = () => {
        console.log(sales);
    };
    return (
        <div className="text-sm p-2 h-full flex flex-column justify-content-center">
            <Toast style={{ zIndex: "100" }} ref={toast} />
            <div className="flex gap-2 p-2 flex-wrap w-full">
                <div className="flex-1 flex flex-column gap-2">
                    <p className="text-2xl text-indigo-500 font-bold" onClick={() => Ver()}>Nuevo cliente</p>
                    <p className="flex align-items-center text-indigo-300">Registra tu cliente potencial.5</p>
                </div>
                <StepsV model={items} activeIndex={activeIndex} />
            </div>
      
            <div className="w-full flex flex-column gap-3 h-full p-1 ventas-scroll">
                {getContent(activeIndex)}

                <div className="flex justify-content-end gap-3">
                    <Button
                        className={`bg-gray-300 border-gray-300 ${activeIndex === 0 ? 'hidden' : ''}`}
                        onClick={() => handleBack()}
                        icon="pi pi-chevron-left text-gray-500"
                    />
                    <Button
                        className={`bg-gray-300 border-gray-300 ${activeIndex < items.length - 1 ? '' : 'hidden'}`}
                        onClick={() => handleNext()}
                        icon="pi pi-chevron-right text-gray-500"
                    />
                </div>
           
            </div>
        </div>
    );
}

export default PageNewClient;