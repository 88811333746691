import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import AuthUser from "../../../AuthUser";
import axios from "axios";

export default function ModalCreatePaciente({
   visibleCreate,
   setVisibleCreate,
   setPersonal,
   showToast,
   tipoDocumentos,
   roles,
   getPersonal,
   api,
   data,
   contractOptions
}) {
   const { http } = AuthUser();

   // const options = [
   //   {name: 'Es usuario', value:'0'},
   //   {name: 'No es usuario', value:'1'},
   // ];
   // const options = ['No es usuario', 'Es usuario'];
   const [selectedContract, setSelectedContract] = useState(null)
   const [tipoContrato, setTipoContrato] = useState([]);

   let options = [
      { label: 'Es usuario', value: true },
      { label: 'No es usuario', value: false }
   ]
   const [value, setValue] = useState("");
   const initialValues = {
      id: 0,
      tipo_documento: tipoDocumentos[0],
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      distrito_id: "",
      celular: "",
      correo: "",
      direccion: "",
      rol: "",
      empresa_cargo: "",
      usuario: "",
   };

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (data) => {
         console.log("dataaaaaaaaaa", data);
         const formDataSend = new FormData();
         for (var key in data) {
            formDataSend.append(key, data[key]);
         }
         formDataSend.append("tipo_documento_id", data?.tipo_documento?.id)

         formDataSend.append("tipo_contrato_id", data?.tipo_contrato?.id)

         formDataSend.append("empresa_cargo_id", data?.empresa_cargo?.id)

         formDataSend.append("rol_id", data?.rol?.id)
         /* console.log("rol", formDataSend.data.rol.id)
         console.log('fooormmrm', ...formDataSend) */
         http
            .post(api, formDataSend)
            .then((response) => {
               HideDialogCreate();
               getPersonal();
               setPersonal();
               console.log("CREADO", response)
               showToast(
                  "success",
                  "Personal creado",
                  response?.data?.resp
               );
            })
            .catch((error) => {
               HideDialogCreate();
               showToast(
                  "error",
                  "Error de creación",
                  "Algo salió mal, inténtelo de nuevo"
               );
            })
      },

   });

   const HideDialogCreate = () => {
      setVisibleCreate(false);
      formik.resetForm();
      setValue(null);

   };

   const paqueteCreateDialogFooter = (
      <div className="flex w-full bot">
         <Button
            type="button"
            label="Cancelar"
            className="delete w-full"
            onClick={() => {
               HideDialogCreate();
            }}
         />
         <Button
            type="submit"
            label="Crear Usuario"
            className="p-button-success w-full"
            style={{
               backgroundColor: "#5555D8",
               borderColor: "#5555D8"
            }}
         />
      </div>
   );

   const getNombreCompleto = (numdocumento, tipoDocumento, tipo_contrato, correo, celular, rol, usuario, empresa_cargo) => {
      if (formik.values.tipo_documento !== "") {
         if (formik.values.tipo_documento.nombre === "DNI") {
            axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)

               .then(res => {
                  formik.setValues({
                     tipo_documento: tipoDocumento,
                     numero_documento: numdocumento,
                     tipo_contrato: tipo_contrato,
                     nombres: res.data.nombres,
                     apellido_paterno: res.data.apellidoPaterno,
                     apellido_materno: res.data.apellidoMaterno,
                     fecha_nacimiento: "2022-01-01",
                     distrito_id: 2,
                     celular: correo,
                     correo: celular,
                     direccion: "",
                     rol: rol,
                     usuario: usuario,
                     empresa_cargo: empresa_cargo,
                     tipo_contrato: tipo_contrato,
                  })
               })
               .catch((error) => {
                  showToast(
                     "error",
                     "Número de documento no encontrado",
                     `No se encontró ningun dato`
                  );
               })
         } else if (formik.values.tipo_documento.nombre === "RUC") {
            axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)

               .then(res => {

                  formik.setValues({
                     tipo_documento: tipoDocumento,
                     numero_documento: numdocumento,
                     nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
                     apellido_paterno: res.data.razonSocial.split(" ")[0],
                     apellido_materno: res.data.razonSocial.split(" ")[1],
                     fecha_nacimiento: "2022-01-01",
                     distrito_id: 2,
                     celular: celular,
                     correo: correo,
                     direccion: "",
                     rol: rol,
                     usuario: "",
                     empresa_cargo: empresa_cargo,
                     tipo_contrato: tipo_contrato,
                  })


               })
               .catch((error) => {
                  showToast(
                     "error",
                     "Número de documento no encontrado",
                     `No se encontró ningun dato`
                  );
               })
         }


      } else {
         showToast(
            "error",
            "Tipo de documento",
            `No se eligió un tipo de documento`
         );
      }
   }



   useEffect(() => {
      console.log("USUARIOOOO", value);
      console.log(tipoDocumentos[0]);
      if (value) {
         formik.setFieldValue('usuario', "1")
      } else {
         formik.setFieldValue('usuario', "0")
      }

      // axios.get('https://api.bregma.com.pe/public/api/tipocontrato/show')
      // .then(response => {
      //    const tipoContratoData = response.data.map(item => ({
      //       label: item.nombre,
      //       value: item.id
      //    }));
      //    setTipoContrato(tipoContratoData); 
      // })
      // .catch(error => {
      //    console.error('Error al obtener los datos del tipo de contrato:', error);
      // });

   }, [value]);

   const handleChangeContract = (e) => {
      setSelectedContract(e.value)
      console.log("FORMIK", formik.values)
   }

   return (
      <div>

         <form
            id="persona-form"
            className="persona-form"
            noValidate
            onSubmit={formik.handleSubmit}
         >
            <Dialog
               visible={visibleCreate}
               style={{
                  width: "450px",
                  // height: "878px",
                  // borderRadius: '25px',
                  margin: "5%"
               }}
               appendTo={document.getElementById("persona-form")}
               header={
                  <>
                     <h1 className="per">Crear Paciente</h1>
                  </>
               }
               dismissableMask
               className="p-fluid "
               footer={paqueteCreateDialogFooter}
               onHide={() => {
                  HideDialogCreate();
               }}
            >
               <div className="flex flex-column gap-3">
                  <div className="flex flex-column w-full">
                     <p style={{ marginBottom: '1rem' }}>
                        En esta seccion usted puede registrar a sus pacientes
                     </p>

                     <p className="per1"></p>
                     <div className="flex w-full flex-column">
                        <Dropdown
                           id="tipo_documento"
                           name="tipo_documento"
                           value={formik.values.tipo_documento}
                           onChange={formik.handleChange}
                           options={tipoDocumentos}
                           optionLabel="nombre"
                           placeholder="Selecciona un tipo de documento"
                           className="w-full"
                        />
                     </div>
                  </div>

                  <div className="flex flex-1 w-full flex-column gap-2">
                     <div className="flex flex-1 w-full gap-2">
                        <InputText
                           id="numero_documento"
                           name="numero_documento"
                           value={formik.values.numero_documento}
                           onChange={formik.handleChange}
                           required
                        />
                        <Button
                           label="Validar"
                           type="button"
                           style={{ background: "#07A34F", borderColor: "#07A34F" }}
                           onClick={() => getNombreCompleto(
                              formik.values.numero_documento,
                              formik.values.tipo_documento,
                              formik.values.tipo_contrato,
                              formik.values.correo,
                              formik.values.celular,
                              formik.values.rol,
                              formik.values.empresa_cargo,
                              formik.values.usuario,
                           )}
                        />
                     </div>
                  </div>

                  <div className="flex flex-column gap-2">
                     <label>Nombres Completos
                        <InputText
                           id="nombres"
                           name="nombres"
                           value={formik.values.nombres}
                           className="w-full"
                           onChange={formik.handleChange}
                           required
                           autoComplete="off"
                        />
                     </label>
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="lastname">Apellidos Paterno</label>
                     <InputText
                        id="apellido_paterno"
                        name="apellido_paterno"
                        value={formik.values.apellido_paterno}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="lastname">Apellidos Materno</label>
                     <InputText
                        id="apellido_materno"
                        name="apellido_materno"
                        value={formik.values.apellido_materno}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Correo</label>
                     <InputText
                        id="correo"
                        name="correo"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Celular</label>
                     <InputText
                        type="number"
                        id="celular"
                        name="celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>
                  {/*
                  <div className="flex justify-content-center align-content-center">
                     <SelectButton
                        className="flex-1"
                        value={value}
                        onChange={(e) => (setValue(e.value))}
                        options={options}
                     ></SelectButton>


                  </div>

                  <div className="flex w-full flex-column">
                     <p className="per1">Tipo de contrato</p>
                     <Dropdown
                        id="tipo_contrato"
                        name="tipo_contrato"
                        value={formik.values.tipo_contrato}
                        onChange={formik.handleChange}
                        options={contractOptions}
                        optionLabel="nombre"
                        placeholder="Selecciona un tipo de contrato"
                        className="w-full"
                        disabled={!value}
                     />
                  </div>

                  <div className="flex flex-column">
                     <p>Rol</p>
                     <Dropdown
                        id="rol"
                        name="rol"
                        value={formik.values.rol}
                        onChange={formik.handleChange}
                        options={roles}
                        optionLabel="nombre"
                        placeholder="Selecciona un Rol"
                        className="w-full"
                        disabled={!value}
                     />
                  </div>
                  <div className="flex flex-column">
                     <p>Puesto de trabajo</p>
                     <Dropdown
                        id="empresa_cargo"
                        name="empresa_cargo"
                        value={formik.values.empresa_cargo}
                        onChange={formik.handleChange}
                        options={data}
                        optionLabel="nombre"
                        placeholder="Selecciona un Puesto de trabajo"
                        className="w-full"
                        disabled={!value}
                     />
                  </div>
                  
                                  */}

               </div>
            </Dialog>
         </form>
      </div>
   );
}