import React, {useState} from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import DialogDetallesLiquidacion from '../CostoAtencion/DialogDetallesLiquidacionB';
import "../CostoAtencion/mainB.css";

function PageMisPagosB() {
   return (
      <div>
         <div className="container-header flex justify-content-between align-items-center mb-5">
            <div className="liquidacion-l justify-content-left">
               <span className="titulo">Mis Pagos</span>
               <br />
               <span className="con">
                  En este módulo usted podrá generar el costo por atención y la
                  facturación de sus productos y/o servicios.
               </span>
            </div>
            <div className='div_botones_AHP'>
              <a href="../contabilidad/costoatencion"><Button className='botones_Facturacion' label="Costo por Atención" /></a>
              <a href="../contabilidad/facturacion"><Button className='botones_Facturacion ' label="Facturación" /></a>
              <a href=""><Button className='botones_Facturacion boton_selecccionado' label="Mis pagos" /></a>
            </div>
         </div>
         
         <div className="clientes mb-3 flex justify-content-between align-items-center">
            <div className="clinicaButtons">
               
            </div>

            <div className="reporte-buscar flex align-items-center justify-content-between">
               <div className="">
                  <button className="btnReport">
                     <i className="pi pi-file"></i>
                     <span>Reporte</span>
                  </button>
               </div>
               <div className="buscador">
                  <span className="p-input-icon -left corporativo">
                     <i className="pi pi-search" />
                     <InputText placeholder="Buscador" className="input" />
                  </span>
               </div>
            </div>
         </div>
         {/* TABLA */}
         <div className="contenedor-tabla">
            <ScrollPanel
               style={{ width: "100%", height: "100%" }}
               className="custombar1"
            >
               <table
                  className="Tabla_Servicios_Breegma"
                  style={{ background: "#fff", width: "100%" }}
               >
                  <thead>
                     <tr>
                        {/* <th></th> */}
                        <th style={{ whiteSpace: "wrap" }}>Item</th>
                        <th style={{ whiteSpace: "wrap" }}>Orden de Pago</th>
                        <th style={{ whiteSpace: "wrap" }}>Capacitador</th>
                        <th style={{ whiteSpace: "wrap" }}>RUC</th>
                        <th style={{ whiteSpace: "wrap" }}>Documento</th>
                        <th style={{ whiteSpace: "wrap" }}>Monto acumulado (S/.)</th>
                        <th style={{ whiteSpace: "wrap" }}>Monto restante (S/.)</th>
                        <th style={{ whiteSpace: "wrap" }}>Documento</th>
                        <th style={{ whiteSpace: "wrap" }}>Estado</th>
                        <th style={{ whiteSpace: "wrap" }}>Días de pago</th>
                        <th style={{ whiteSpace: "wrap" }}>Pagar</th>
                        <th style={{ whiteSpace: "wrap" }}>Comprobante</th>
                     </tr>
                  </thead>
                  <tbody>
                     {/* {currentPersonal?.map((item) => ( */}
                     {/* key={item.id} */}
                     <tr>
                        <td
                           data-label="Item"
                           style={{ borderLeft: "0px solid transparent" }}
                        >
                           <div className="flex flex-row gap-2 justify-content-center">
                              {/* {actionBodyTemplateAsignarcheck(item)}
                                 {item.id} */}
                           </div>{" "}
                        </td>
                        <td
                           style={{ whiteSpace: "wrap" }}
                           data-label="Orden de Pago"
                        >
                           {/* <BotonEstado estado="Programado" /> */}
                        </td>
                        <td style={{ whiteSpace: "wrap" }} data-label="Capacitador">
                           {/* {item.persona.tipo_documento.nombre} */}
                        </td>
                        <td
                           style={{ whiteSpace: "wrap" }}
                           data-label="RUC"
                        >
                           {/* {item.persona.numero_documento} */}
                        </td>

                        <td style={{ whiteSpace: "wrap" }} data-label="Documento">
                           {/* <BotonResultadooo resultado="Observado" /> */}
                        </td>

                        <td data-label="Monto Acumulado"></td>

                        <td
                           style={{ whiteSpace: "wrap" }}
                           data-label="Monto Restante"
                        >
                           {/* {actionBodyTemplateAsignar(item)} */}
                        </td>

                        <td data-label="Documento">
                           {/* {<DialogDetallesLiquidacion />} */}
                        </td>

                        <td data-label="Estado"></td>

                        <td data-label="Días de Pago"></td>

                        <td data-label="Pagar"></td>

                        <td data-label="Comprobante"></td>
                     </tr>
                     {/* ))} */}
                  </tbody>
               </table>

               <div style={{ display: "flex", justifyContent: "right" }}>
                  <div
                     className="footer-contador"
                  >
                     <div
                        className="flex flex-wrap justify-content-evenly gap-5 pagination-component"
                        style={{ flexDirection: "row" }}
                     >
                        <button
                           style={{ cursor: "pointer" }}
                           className="btn-current-next"
                        //  onClick={() => handlePageChange(currentPage - 1)}
                        //  disabled={currentPage === 1}
                        >
                           <i className="pi pi-angle-left"></i>
                        </button>

                        <button
                           style={{ cursor: "pointer" }}
                           className="btn-current-back"
                        //  onClick={() => handlePageChange(currentPage + 1)}
                        //  disabled={currentPage === totalPages}
                        >
                           <i className="pi pi-angle-right"></i>
                        </button>
                     </div>
                  </div>
               </div>
            </ScrollPanel>
         </div>
      </div>
   )
}

export default PageMisPagosB