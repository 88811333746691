import React, { useEffect, useState } from "react";
import bgcontain from "../../../../Images/Operaciones/Servicios/bg-contain.png";
import AuthUser from "../../../../AuthUser";
import TableOperacionesClinicaComponent from "../../../../Components/Operaciones/TableOperacionesClinica";
import { transformData } from "../../../../Services/Operaciones/operaciones";


export default function PageOperServiciosB() {
   const { http } = AuthUser();
   // Trayendo datos GET
   const [servicios, setServicios] = useState([]);
   const [categories, setcategories] = useState("");
   const [key, setKey] = useState(0);
   const [selectedCategory, setSelectedCategory] = useState(null);

   const getServicios = () => {
      http
         .get("clinica/operaciones/servicio/get")
         .then((response) => {
            setServicios(transformData([response.data]));
            setcategories(Object.keys(response.data));
            console.log("ESTA ES LA INFO:", response.data)
            console.log("BUSCO A NOMBRE", response.data.data)

         })
         .catch((e) => {
            console.error(e);
         });
   };

   // const handleClick = (key, category) => {
   //    setKey(key)
   //    setSelectedCategory(true);
   // };
   const handleClick = (key, category) => {
      setKey(key);
      setSelectedCategory(changue_name_category(category));
   };


   const changue_name_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "Áreas Médicas";

         case "examenes":
            return "Examen de ayuda al diagnóstico ";
         case "laboratorios":
            return "Laboratorio Molecular";
         default:
            return "";
      }
   };

   const changue_item_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "i-plus-green";
         case "capacitaciones":
            return "";
         case "examenes":
            return "i-diagnostic-vector-orange";
         case "laboratorios":
            return "i-lab-genomica-purple";
         default:
            return "";
      }
   };

   const changue_bg_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "services--content-section-one-content-item-icon-green";
         case "capacitaciones":
            return "";
         case "examenes":
            return "services--content-section-one-content-item-icon-orange";
         case "laboratorios":
            return "services--content-section-one-content-item-icon-purple";
         default:
            return "";
      }
   };

   const changue_item_am_category = (cat) => {
      switch (cat) {
         case "Triaje":
            return "i-term-green";
         case "Psicología":
            return "i-brain-green";
         case "EKG":
            return "i-brain-green";
         case "Oftalmología":
            return "i-eye-green";
         case "Audiometría":
            return "i-audiometry-green";
         case "Radiología":
            return "i-heart-break-green";
         case "Laboratorio":
            return "i-spirometry-green";
         case "Medicina General":
            return "i-medic-general-green";
         //
         case "prueba example 1":
            return "i-test-one-blue";
         case "prueba example 2":
            return "i-person-blue";
         case "prueba example 3":
            return "i-test-three-blue";
         //
         case "RX Torax":
            return "i-rx-torax-orange";
         case "RX cervical":
            return "i-rx-cervical-orange";
         case "RX Craneo":
            return "i-diagnostic-vector-orange";
         //
         case "Hemograma":
            return "i-genetic-purple";
         case "Tiroides":
            return "i-genetic-purple";
         case "hemograma":
            return "i-genetic-purple";
         default:
            return "";
      }
   };

   useEffect(() => {
      getServicios();
   }, []);

   return (
      <div className="services-content">
         <section className="services--content-section-one">
            <div className="services--content-section-one-header">
               <h3 className="services--content-section-one-header-title">
                  Servicios
               </h3>
               <p className="services--content-section-one-header-paragraph">
                  En este módulo usted podrá operar sus servicios.
               </p>
            </div>

            <section className="services--content-section-one-body">
               <div className="services--content-section-one-body-section">
                  <h3 className="services--content-section-one-title">
                     Nuestros servicios
                  </h3>
                  <style>
                     {`
            .clasedejosue div:nth-child(2) {
                        display: none;
            }
            `}
                  </style>
                  <div className="services--content-section-one-content clasedejosue">
                     {categories &&
                        categories?.map((category, key) => (
                           <div
                              className="services--content-section-one-content-item"
                              key={category}
                              onClick={() => handleClick(key, category)}
                           >
                              <div className={changue_bg_category(category)}>
                                 <i
                                    className={changue_item_category(category)}
                                 ></i>
                              </div>
                              <p className="services--content-section-one-content-item-paragraph">
                                 {changue_name_category(category)}
                              </p>
                              <div>
                                 <i
                                    className="pi pi-ellipsis-v"
                                    style={{
                                       color: "#B6B6B6",
                                       fontWeight: "600",
                                       cursor: "pointer",
                                    }}
                                 ></i>
                              </div>
                           </div>
                        ))}
                  </div>
               </div>

               {/* IMAGEN */}
               <div className="services--content-section-one-image">
                  <img src={bgcontain} alt="contain" height={100} width={110} />
                  <p className="services--content-section-one-image-text">
                     Lista de servicios
                  </p>
               </div>
            </section>
         </section>

         <section className="services--content-section-two">
            {selectedCategory ? (
               <>
                  <div className="services--content-section-two-header">
                     <div className={changue_bg_category(selectedCategory)}>
                        <i
                           className={changue_bg_category(selectedCategory)}
                        ></i>
                     </div>
                     <h2>{selectedCategory}</h2>
                  </div>

                  <div className="services--content-section-two-span">
                     <p>
                        Servicios /
                        <span>{selectedCategory}</span>
                     </p>
                  </div>

                  <div className="table-responsive">
                     <TableOperacionesClinicaComponent
                        getServicios={getServicios}
                        data={servicios}
                        transformedNodes={servicios[key]?.children}
                     ></TableOperacionesClinicaComponent>
                  </div>
               </>
            ) : (
               <div className="flex justify-content-center align-items-center w-full h-full">
                  Seleccione un servicio
               </div>
            )}
         </section>
      </div>
   );
}