import React, { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { saveAs } from 'file-saver';
export default function DialogPDF({ prop01, prop02, documentos }) {

   // useEffect(() => {
   //    setProducts(productos);
   // }, []);

   // const handleDownload = (pdfUrl, pdfName) => {
   //     fetch(pdfUrl)
   //         .then(response => {
   //             if (!response.ok) {
   //                 throw new Error('Network response was not ok');
   //             }
   //             return response.blob();
   //         })
   //         .then(blob => {
   //             const url = window.URL.createObjectURL(blob);
   //             const a = document.createElement('a');
   //             a.style.display = 'none';
   //             a.href = url;
   //             a.download = pdfName;
   //             document.body.appendChild(a);
   //             a.click();
   //             window.URL.revokeObjectURL(url);
   //         })
   //         .catch(error => {
   //             console.error('Error downloading PDF:', error);
   //         });
   // };

   // const pdfPath = '/Enfermedades.pdf'; // Ruta relativa del archivo PDF en la carpeta public

   // Función para descargar el archivo PDF directamente
   const handleDownloadDirect = (ruta, nombre) => {
      const originalText = nombre;
      const textWithUnderscores = originalText.replace(/\s+/g, '_');
      saveAs("Escala de Somnolencia Epworth1.pdf", textWithUnderscores + '.pdf');
      console.log(textWithUnderscores + '.pdf', ruta)
   };

   const footerContent = (
      <div>
         <Button label="Cancelar" onClick={() => prop02(false)} className="p-button-text" style={{ border: "1px solid #4545bc" }} />
      </div>
   );

   return (
      <Dialog header="Informe del paciente" visible={prop01} style={{ width: '50vw' }} onHide={() => prop02(false)} footer={footerContent}>
         <table className="tabla">
            <thead>
               <tr>
                  <th className="encabezado">PDF</th>
                  <th className="encabezado text-center">Visualizar</th>
                  <th className="encabezado text-center">Descargar</th>
               </tr>
            </thead>
            <tbody>
               {documentos.map(item => {
                  return (
                     <tr key={item.id}>
                        <td>{item.nombre}</td>
                        <td className="justify-content-center text-center"><a href={item.ruta} target="_blank" rel="noopener noreferrer"><i className="pi pi-eye"></i></a></td>
                        <td className="justify-content-center text-center"><Button onClick={() => handleDownloadDirect(item.ruta, item.nombre)} className="p-button-text" ><i className="pi pi-download" /></Button></td>
                     </tr>
                  );
               })}
            </tbody>
         </table>
      </Dialog>
   );
}
