import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'primereact/treeselect';
import AuthUser from '../../../AuthUser';

const MultiLevelTreeSelectWithAPI = ({ setSelectedTreeSelectId, setSelectedService,selectedService }) => {
    const { http } = AuthUser();
    const [groupedServices, setGroupedServices] = useState([]);

    useEffect(() => {
        getServicios();
    }, []);

    const getServicios = () => {
        http
            .get('/bregma/servicio/get')
            .then(response => {
                const processedData = response.data.data.map(item => ({
                    key: item.id.toString(),
                    label: item.nombre,
                    data: item.nombre,
                    icon: '', // Puedes ajustar esto según tus necesidades
                    children: item.children.map(subItem => ({
                        key: subItem.id.toString(),
                        label: subItem.nombre,
                        data: subItem.nombre,
                        icon: '', // Puedes ajustar esto según tus necesidades
                        children: subItem.children.map(subSubItem => ({
                            key: subSubItem.id.toString(),
                            label: subSubItem.nombre,
                            data: subSubItem.nombre,
                            icon: '' // Puedes ajustar esto según tus necesidades
                        }))
                    }))
                }));
                console.log('processeddata', processedData)
                setGroupedServices(processedData);
            })
            .catch(error => {
                // Mostrar una notificación o mensaje de error
                console.error('Error fetching services:', error);
            });
    };

    const handleNodeSelect = event => {
        console.log(event.value)
        setSelectedService(event.value);
        setSelectedTreeSelectId(event.value);
    };

    return (
        <>
            <TreeSelect
                value={selectedService}
                options={groupedServices}
                selectionMode="single"
                placeholder="Tipo de Servicio"
                onChange={handleNodeSelect}
                className="md:w-20rem w-full flex flex-row align-items-center"
                style={{ height: '36px' }}
            />
        </>
    );
};

export default MultiLevelTreeSelectWithAPI;
