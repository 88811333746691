import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import DialogDetalles from './Dialogs/DialogCorporativo';
import { DialogValidaciones } from "./Dialogs/DialogValidaciones";
import AuthUser from "../../../../AuthUser";
import MultiLevelTreeSelectWithAPIClinica from "./SelectTipoServicioClinica";
import { transformData } from "../../../../Services/ProductosServicios/servicios";
import Paquete from "../../../../Components/Ventas/GVentasParticular/NuevoClienteParticular/2NCPSeleccionPlan/Paquete";
import './DiseñoTabla.css'
import { useDispatch } from "react-redux";

export default function PageAgregarServiciosB() {

   // DropDown
   const toast = useRef(null);
   const { http } = AuthUser();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { id: idServicio } = useParams()

   const [servicios, setServicios] = useState([]);
   const [paquetes, setPaquetes] = useState(null);
   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [visibleDetalle, setVisibleDetalle] = useState(false)
   const [documentos, setDocumentos] = useState([]);
   const [NombreServicio, setNombreServicio] = useState();
   // DATOS Y FUNCIONES DEL POP UP
   const [VerDialog, setVerDialog] = useState(false);
   const [RowDataActual, setRowDataActual] = useState(null);
   const [TipoDialog, setTipoDialog] = useState(0)
   const [filtro, setFiltro] = useState(null)
   const [NowPerfiles, setNowPerfiles] = useState(
      [
         {
            Id: 1,
            NameTipoPerfil: "Tipo 01",
            Entrada: [
               {
                  ItemsSeleccionados: [],
                  OnlyKeys: []
               }
            ],
            documentosEntrada: [],
            PrecioReferencialEntrada: 0,
            Rutina: [
               {
                  ItemsSeleccionados: [],
                  OnlyKeys: []
               }
            ],
            documentosRutina: [],
            PrecioReferencialRutina: 0,
            Salida: [
               {
                  ItemsSeleccionados: [],
                  OnlyKeys: []
               }
            ],
            documentosSalida: [],
            PrecioReferencialSalida: 0,
         }
      ]
   );

   const ArraySimplificado = obtenerElementosConChildrenVacios(servicios);
   const ArraySimplificadoPAdres = obtenerElementosConChildrenLlenos(servicios);

   let nextId = 1; // Variable para almacenar el próximo Id

   function Ver_items(paquete) {
      let perfiles = paquete.perfil_tipos.map(profile => {

         // Incrementar el Id y luego usarlo
         const newId = nextId++;

         let Items1 = obtenerElementos(profile.perfil_tipo_items[0]);
         let itemsVacios1 = buscarElementosConChildrenVacios(Items1);
         let ItemsSeleccionados1 = compararArrays(ArraySimplificado, itemsVacios1, ArraySimplificado.length, ArraySimplificadoPAdres);
         let Items2 = obtenerElementos(profile.perfil_tipo_items[1]);
         let itemsVacios2 = buscarElementosConChildrenVacios(Items2);
         let ItemsSeleccionados2 = compararArrays(ArraySimplificado, itemsVacios2, ArraySimplificado.length, ArraySimplificadoPAdres);
         let Items3 = obtenerElementos(profile.perfil_tipo_items[2]);
         let itemsVacios3 = buscarElementosConChildrenVacios(Items3);
         let ItemsSeleccionados3 = compararArrays(ArraySimplificado, itemsVacios3, ArraySimplificado.length, ArraySimplificadoPAdres);


         let Entrada = [{ ItemsSeleccionados: ItemsSeleccionados1, OnlyKeys: itemsVacios1 }];
         let Rutina = [{ ItemsSeleccionados: ItemsSeleccionados2, OnlyKeys: itemsVacios2 }];
         let Salida = [{ ItemsSeleccionados: ItemsSeleccionados3, OnlyKeys: itemsVacios3 }];

         return {
            Id: newId,
            NameTipoPerfil: profile.perfil.nombre,
            Entrada: Entrada,
            documentosEntrada: profile.perfil_tipo_items[0].documentos,
            PrecioReferencialEntrada: retornarTotal(profile.perfil_tipo_items[0]),
            Rutina: Rutina,
            documentosRutina: profile.perfil_tipo_items[1].documentos,
            PrecioReferencialRutina: retornarTotal(profile.perfil_tipo_items[1]),
            Salida: Salida,
            documentosSalida: profile.perfil_tipo_items[2].documentos,
            PrecioReferencialSalida: retornarTotal(profile.perfil_tipo_items[2]),
         };

      });

      console.log("perfilleeeees", perfiles)
      setNowPerfiles(perfiles)
      // for (let a = 0; a < paquete?.perfil_tipos?.length; a++) {
      //    actualizarItemsSeleccionados(a, paquete);
      // }

   }

   function obtenerElementos(item, prefix = '') {
      if (!item) {
         return [];
      }

      const areasMedicas = item.areas_medicas || [];
      const examenes = item.examenes || [];

      const elementosConPrefijo = [
         ...areasMedicas.map(elemento => ({ ...elemento, id: `${prefix}1-${elemento.id}` })),
         ...examenes.map(elemento => ({ ...elemento, id: `${prefix}3-${elemento.id}` }))
      ];

      return elementosConPrefijo;
   }


   function obtenerElementosConChildrenVacios(array) {
      const elementosConChildrenVacios = [];

      function buscarElementosConChildrenVacios(arr) {
         for (let i = 0; i < arr.length; i++) {
            const elemento = arr[i];
            if (elemento.children && Array.isArray(elemento.children) && elemento.children.length === 0) {
               elementosConChildrenVacios.push(elemento);
            } else if (elemento.children && Array.isArray(elemento.children) && elemento.children.length > 0) {
               buscarElementosConChildrenVacios(elemento.children);
            }
         }
      }

      buscarElementosConChildrenVacios(array);

      return elementosConChildrenVacios;
   }



   function obtenerElementosConChildrenLlenos(array) {
      const elementosConChildrenLlenos = [];

      function buscarElementosConChildrenLlenos(arr) {
         for (let i = 0; i < arr.length; i++) {
            const elemento = arr[i];
            if (elemento.children && Array.isArray(elemento.children) && elemento.children.length !== 0) {
               const hijos = elemento.children.map(child => child.key); // Obtener los Ids de los hijos
               elementosConChildrenLlenos.push({ Id: elemento.key, cant: elemento.children.length, hijos: hijos });
               buscarElementosConChildrenLlenos(elemento.children);
            }
         }
      }

      buscarElementosConChildrenLlenos(array);

      return elementosConChildrenLlenos;
   }


   function buscarElementosConChildrenVacios(arr, parentId = null) {
      const elementosConChildrenVacios = [];
      for (let i = 0; i < arr.length; i++) {
         const elemento = arr[i];
         if (elemento.children && Array.isArray(elemento.children)) {
            if (elemento.children.length === 0) {
               const idConPadre = parentId ? `${parentId}.${elemento.id}` : elemento.id;
               elementosConChildrenVacios.push(idConPadre);
            } else {
               const hijosConIds = buscarElementosConChildrenVacios(elemento.children, parentId ? `${parentId}.${elemento.id}` : elemento.id);
               elementosConChildrenVacios.push(...hijosConIds);
            }
         }
      }
      if (parentId) {
         elementosConChildrenVacios.push(parentId);
      }
      return elementosConChildrenVacios;
   }

   function compararArrays(arr1, arr2, cant, ArrayPadre) {
      const resultado = {};

      arr2.forEach((item) => {
         if (item.startsWith("1")) {
            resultado["areas_medicas"] = { checked: false, partialChecked: true };
         }
         // Verificar si el item comienza con "3"
         else if (item.startsWith("3")) {
            resultado["examenes"] = { checked: false, partialChecked: true };
         }
         const foundExactMatch = arr1.some(obj => obj.key === item);

         if (foundExactMatch) {
            // Si hay coincidencia exacta, establece los valores correspondientes
            resultado[item] = { checked: true, partialChecked: false };
         } else {
            resultado[item] = { checked: false, partialChecked: true };
         }
      });
      const ArrayPadreOrdenado = ordenarElementosPorId(ArrayPadre);
      for (let i = 0; i < ArrayPadreOrdenado.length; i++) {
         let medidor = true;

         ArrayPadreOrdenado[i].hijos.forEach((item) => {
            if (resultado[item] && resultado[item].checked) {
            } else {
               medidor = false;
               // Si algún hijo no está chequeado, no necesitas seguir verificando
               return;
            }
         });

         if (medidor) {
            resultado[ArrayPadreOrdenado[i].Id] = { checked: true, partialChecked: false };
         }
      }
      return resultado;
   }
   function ordenarElementosPorId(array) {
      const elementosNumericos = [];
      const elementosConLetras = [];

      // Separar los elementos numéricos de los que tienen letras
      array.forEach(elemento => {
         if (/^\d/.test(elemento.Id)) {
            elementosNumericos.push(elemento);
         } else {
            elementosConLetras.push(elemento);
         }
      });

      // Ordenar elementos numéricos por la longitud del ID de manera descendente
      elementosNumericos.sort((a, b) => b.Id.length - a.Id.length);

      // Concatenar elementos numéricos ordenados con elementos con letras
      const resultado = elementosNumericos.concat(elementosConLetras);

      return resultado;
   }



   const actualizarPreciosReferenciales = (perfiles, paquete, setPerfiles) => {
      console.log(paquete)
      if (paquete && paquete.perfil_tipos) { // Verificar que paquete y paquete.perfil_tipos no sean nulos
         const nuevosPerfiles = [...perfiles];

         // Actualizamos solo el primer perfil
         for (let i = 0; i < perfiles.length; i++) {
            nuevosPerfiles[i] = {
               ...nuevosPerfiles[i], // Mantenemos las propiedades existentes del primer perfil
               PrecioReferencialEntrada:
                  retornarTotal(paquete.perfil_tipos[i].perfil_tipo_items[0])
               , PrecioReferencialRutina:
                  retornarTotal(paquete.perfil_tipos[i].perfil_tipo_items[1])
               , PrecioReferencialSalida:
                  retornarTotal(paquete.perfil_tipos[i].perfil_tipo_items[2])
            };
         }
         setPerfiles(nuevosPerfiles);
      } else {
         console.error("Paquete o perfil_tipos es nulo.");
         // Manejar el caso en que paquete o perfil_tipos sea nulo, por ejemplo, mostrar un mensaje de error
      }
   };

   const retornarTotal = (array) => {
      let vartotal = 0;
      for (let i = 0; i < array.areas_medicas.length; i++) {
         vartotal += array.areas_medicas[i].precio_referencial_mensual_venta
         if (array.areas_medicas[i].children.length > 0) {
            for (let e = 0; e < array.areas_medicas[i].children.length; e++) {
               vartotal += array.areas_medicas[i].children[e].precio_referencial_mensual_venta
               if (array.areas_medicas[i].children[e].children.length > 0) {
                  for (let a = 0; a < array.areas_medicas[i].children[e].children.length; a++) {
                     vartotal += array.areas_medicas[i].children[e].children[a].precio_referencial_mensual_venta
                  }
               }
            }
         }
      }
      for (let i = 0; i < array.examenes.length; i++) {
         vartotal += array.examenes[i].precio_referencial_mensual_venta
         if (array.examenes[i].children.length > 0) {
            for (let e = 0; e < array.examenes[i].children.length; e++) {
               vartotal += array.examenes[i].children[e].precio_referencial_mensual_venta
               if (array.examenes[i].children[e].children.length > 0) {
                  for (let a = 0; a < array.examenes[i].children[e].children.length; a++) {
                     vartotal += array.examenes[i].children[e].children[a].precio_referencial_mensual_venta
                  }
               }
            }
         }
      }
      return vartotal
   }





   // FUNCION PARA INCREMENTAR EL ARRAY
   const agregarElemento = () => {
      quitarClaseDisable("Cabezera_Tabla_AHP", "TablaDisabled");

      const nuevoElemento = {
         Id: NowPerfiles.length + 1,
         NameTipoPerfil: `Tipo ${NowPerfiles.length + 1 < 10 ? '0' + (NowPerfiles.length + 1) : NowPerfiles.length + 1}`,
         Entrada: [
            {
               ItemsSeleccionados: [],
               OnlyKeys: []
            }
         ],
         PrecioReferencialEntrada: 0,
         documentosEntrada: [],
         Rutina: [
            {
               ItemsSeleccionados: [],
               OnlyKeys: []
            }
         ],
         PrecioReferencialRutina: 0,
         documentosRutina: [],
         Salida: [
            {
               ItemsSeleccionados: [],
               OnlyKeys: []
            }
         ],
         PrecioReferencialSalida: 0,
         documentosSalida: [],
      };

      setNowPerfiles(prevPerfiles => [...prevPerfiles, nuevoElemento]);
   };

   function quitarClaseDisable(clase, claseDelete) {
      // Seleccionar todos los elementos con la clase "ElementoSeleccionarBTNN"
      const elementos = document.querySelectorAll('.' + clase);

      // Iterar sobre cada elemento y quitar la clase "Disableddd"
      elementos.forEach(elemento => {
         elemento.classList.remove(claseDelete);
      });
   }

   // Llamar a la función para quitar la clase "Disableddd" de los elementos






   const AbrirDialog = (rowData, Tipo, PrecioReferencial) => {
      return (
         <div style={{ gap: '5px' }}>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <button
                  className="ElementoSeleccionarBTNN Disableddd"
                  onClick={() => {
                     setVerDialog(true);
                     setRowDataActual(rowData);
                     setTipoDialog(Tipo)
                  }}
               >
                  Seleccionar
               </button>
            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
            <p className="ElementoPrecioReferencial"> S/. {NowPerfiles[rowData.Id - 1][PrecioReferencial].toFixed(2)}</p>
            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <Button className="bg-white border-none" icon="pi pi-check-square text-blue-700 text-xl" onClick={() => setVisibleDetalle(true)}></Button>
            </div>
         </div>
      );
   };

   const sendData = async (data) => {
      http
         .put('clinica/paquete/aux_update/' + idServicio, data)
         .then((response) => {
            showToast('success', 'Success', 'El paquete fue creado correctamente')
            navigate('/productosyservicios/servicios')
         })
         .catch((error) => {
            console.error("algo salio mal", error);
            showToast('error', 'Error', 'El paquete no pudo ser creado correctamente')
         })
         .finally(() => {
            dispatch(endLoading());
         })
   }

   const handleKeyDown = (e) => {
      if (e.key === "Escape") {

      }
   };
   // DropDown
   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const handleTipoServicioSelect = tipoServicioId => {
      setSelectedTipoServicio(tipoServicioId);
   };

   const onCellEditComplete = (e) => {
      let { rowData, newValue, field } = e;
      rowData[field] = newValue
   };

   const cellEditor = (options) => {
      return textEditor(options);
   };
   const textEditor = (options) => {
      return <InputText
         style={{ maxHeight: '28px' }}
         placeholder="Ingrese un nombre"
         type="text" value={options.value}
         onChange={(e) => options.editorCallback(e.target.value)}
      />;
   };

   const TituloServicioPrecio = (
      <div className="flex align-items-center justify-content-around" style={{ width: '100%', height: '100%' }}>
         <div className="w-6 h-auto flex align-items-center justify-content-center">
            <p className="text-center mb-0">Servicios</p>
         </div>
         <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
            <p className="text-center mb-0">Precio Referencial</p>
         </div>
         <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
            <p className="text-center mb-0">Validaciones</p>
         </div>
      </div>

   )
   const headerGroup = (
      <ColumnGroup>
         <Row style={{ width: '100%' }}>
            <Column header="" field="lastYearSale" />
            <Column header="Entrada" colSpan={2} />
            <Column header="Rutina" colSpan={2} />
            <Column header="Salida" colSpan={2} />
         </Row>
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header={TituloServicioPrecio} field="thisYearSale" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
         </Row>
      </ColumnGroup>
   );



   const ActualizarTodo = (perfiles) => {

      let DataEnvioActulizar = (
         {
            "nombre": NombreServicio,
            "clinica_servicio_id": selectedTipoServicio ? selectedTipoServicio : null,
            "tiempo_venta": 1,
            "perfiles": perfiles.map(profile => ({
               "id": profile.Id,
               "nombre": profile.NameTipoPerfil,
               "entrada": {
                  "areas_medicas": profile.Entrada[0].OnlyKeys.filter(item => item.startsWith('1')).map(item => (
                     {
                        "id": item.slice(2), // Elimina los primeros dos caracteres
                        "parcialmente": 1
                     }
                  )),
                  "capacitaciones": [],
                  "examenes": profile.Entrada[0].OnlyKeys.filter(item => item.startsWith('3')).map(item => (
                     {
                        "id": item.slice(2), // Elimina los primeros dos caracteres
                        "parcialmente": 1
                     }
                  )),
                  "documentos": profile.documentosEntrada,
               },
               "rutina": {
                  "areas_medicas": profile.Rutina[0].OnlyKeys.filter(item => item.startsWith('1')).map(item => (
                     {
                        "id": item.slice(2), // Elimina los primeros dos caracteres
                        "parcialmente": 1
                     }
                  )),
                  "capacitaciones": [],
                  "examenes": profile.Rutina[0].OnlyKeys.filter(item => item.startsWith('3')).map(item => (
                     {
                        "id": item.slice(2), // Elimina los primeros dos caracteres
                        "parcialmente": 1
                     }
                  )),
                  "documentos": profile.documentosRutina,
               },
               "salida": {
                  "areas_medicas": profile.Salida[0].OnlyKeys.filter(item => item.startsWith('1')).map(item => (
                     {
                        "id": item.slice(2), // Elimina los primeros dos caracteres
                        "parcialmente": 1
                     }
                  )),
                  "capacitaciones": [],
                  "examenes": profile.Salida[0].OnlyKeys.filter(item => item.startsWith('3')).map(item => (
                     {
                        "id": item.slice(2), // Elimina los primeros dos caracteres
                        "parcialmente": 1
                     }
                  )),
                  "documentos": profile.documentosSalida,
               }
            }))
         });
      dispatch(startLoading());
      sendData(DataEnvioActulizar)
   }



   //Buscador
   const busqueda = (e) => {
      if (e.target.value !== '') {
         let datosFiltrados = NowPerfiles.filter(data => data.NameTipoPerfil.toLowerCase().includes(e.target.value.toLowerCase()))
         setFiltro(datosFiltrados)
      } else {
         setFiltro(NowPerfiles)
      }
   }
   // Transformar data
   const getServicios = () => {
      http.get('clinica/operaciones/servicio/get')
         .then((response) => {
            setServicios(transformData([response.data]))
         })
         .catch((error) => {
            console.error(error)
         })
   }

   const getPaquetes = async () => {
      await http.get(`clinica/paquete/get/${idServicio}`)
         .then(response => {
            if (response.data.resp) {
               console.log(response.data.resp)
               let _paquete = response.data.resp
               setPaquetes(_paquete);
               setNombreServicio(_paquete?.paquete?.nombre)
               // actualizarPreciosReferenciales(NowPerfiles, _paquete, setNowPerfiles);
               Ver_items(_paquete);
               console.log(_paquete)
            }
         })
         .catch(err => {
            console.error("salio algo mal", err);
         })
   }

   const getDocumentos = async () => {
      await http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getPaquetes(),
            getDocumentos(),
            getServicios(),
         ])
      } catch (error) {

      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
      document.addEventListener("keydown", handleKeyDown);
      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
   }, []);

   return (
      <div className="add__container">
         <Toast ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Editar Servicio - Coorporativo</h4>
            <p className="add__container_header_p">
               Adminístralo a tu mejor manera
            </p>
         </div>
         <div className="add__container_main">
            <div className="add__container_main_content_header bg-white ContenedorWhiteAHP">
               <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Nombre:
                     </label>
                     <span className="p-input-icon-right">
                        <i className="pi pi-pencil" />

                        <InputText
                           placeholder="Paquete anual- Primax"
                           className="add__container_main_content_header_inputs_singular_input"
                           value={NombreServicio}
                           onChange={(e) => setNombreServicio(e.target.value)}
                        />
                     </span>
                  </div>
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Tipo de servicio:
                     </label>
                     <MultiLevelTreeSelectWithAPIClinica
                        selectedTipoServicio={selectedTipoServicio}
                        onTipoServicioSelect={handleTipoServicioSelect}
                     />
                  </div>
               </div>
               <div className="add__container_main_content_header_group">
                  <div className="contenedor-buscador flex flex-row w-full gap-3" style={{ justifyContent: 'space-between', marginTop:'25px' }}>
                     <span className=" p-input-icon-left flex bg-transparent" style={{ alignItems: 'center' }}>
                        <i className=" px-2 pi pi-search" />
                        <InputText placeholder="Buscar..." style={{ paddingRight: '300px', width:"420px"}} onChange={(e) => { busqueda(e) }} />
                     </span>
                  </div>
               </div>
            </div>
            { }
            <div className="add__container_main_content_body">
               <div className="add__container_main_content_body_container">
                  <ScrollPanel
                     style={{ width: "100%", padding: '1rem', alignItems: "center" }}
                     className="custombar2 p-scrollpanel-bar-table"
                  >
                     <div className="card p-fluid" style={{ height: 'auto', backgroundColor: 'transparent' }}>
                        <DataTable
                           className="Cabezera_Tabla_AHP TablaDisabled"
                           value={NowPerfiles}
                           headerColumnGroup={headerGroup}
                           editMode="cell"
                           tableStyle={{ width: '100%', height: 'auto' }}
                           size="small"
                        >
                           <Column field="NameTipoPerfil" header="Tipo Perfil:" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                           <Column header="Entrada:" body={(rowData) => AbrirDialog(rowData, 1, "PrecioReferencialEntrada")}></Column>
                           <Column header="Rutina:" body={(rowData) => AbrirDialog(rowData, 2, "PrecioReferencialRutina")} ></Column>
                           <Column header="Salida:" body={(rowData) => AbrirDialog(rowData, 3, "PrecioReferencialSalida")}></Column>
                        </DataTable>
                        <div className="ParteInferiorTablar">
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                        </div>

                        <DialogDetalles
                           visible={VerDialog}
                           setVisible={setVerDialog}
                           rowData={RowDataActual}
                           TipoDialog={TipoDialog}
                           DataGeneral={NowPerfiles}
                           setDataGeneral={setNowPerfiles}
                           DataServicios={servicios}
                           documentos={documentos}
                        />
                     </div>
                  </ScrollPanel>
               </div>
               <div className="add__container_main_content_footer_one mt-4 md:mt-2 mb-3 md:mb-1">
                  <button
                     className="add__container_main_content_footer_one_btn BotonAgregar BotonAgregarDesabihilitado"
                     style={{ width: '220px' }}
                     onClick={agregarElemento}
                  >
                     <i className="pi pi-plus bg-colo-btn"></i>
                     <p className="bg-colo-btn">Agregar nuevo perfil</p>
                  </button>
               </div>
               <div className="add__container_main_content_footer_two">
                  <button className="add__container_main_content_footer_two_btn ">
                     <Link
                        to="/productosyservicios/servicios/"
                        className="bg-colo-btn"
                        style={{ textDecoration: "none" }}
                     >
                        Cancelar
                     </Link>
                  </button>
                  <button className="button__add_new" onClick={() => { console.log("Data ", NowPerfiles); ActualizarTodo(NowPerfiles) }}>Actualizar</button>

                  {/* <button onClick={() => { console.log("Profile: ", ArraySimplificado) }}>Ver profile</button>
                  <button onClick={() => { console.log("Profile: ", NowPerfiles) }}>Ver array</button>
                  <button onClick={() => { actualizarPreciosReferenciales(NowPerfiles, paquetes, setNowPerfiles) }}>Ver resultado</button>
                  <button onClick={() => { Ver_items(paquetes) }}>Ver Items</button> */}

               </div>
            </div>
         </div>
         <DialogValidaciones visible={visibleDetalle} setVisible={setVisibleDetalle} />
      </div>
   );
} 