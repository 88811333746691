import React, { useState, useContext, useEffect } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import VoiceButton from '../../../Components/ButtonVoice/VoiceButton';
import { Checkbox } from "primereact/checkbox";
import { RAContext } from "../../RutaAtencion/RAContext";
import { MGContext } from '../MGProvider';
import { DataTable } from 'primereact/datatable';

export default function BasicDemo() {
  let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
  let { anexo16Context, setAnexo16Context } = useContext(MGContext)

  const [aptitud, setAptitud] = useState(anexo16Context?.aptitud);
  const estados = [
    { name: 'Apto', code: 1 },
    { name: 'No Apto', code: 2 },
  ];

  const [observaciones, setObservaciones] = useState(anexo16Context?.observaciones || "");
  const [medicamentos, setMedicamentos] = useState(anexo16Context?.uso_medicamentos || "");
  const [medAnexo, setMedAnexo] = useState(anexo16Context?.med_anexo_preguntas || "");

  const handlePresentCheckboxChange = (node) => {
    const updatedNodes = [...medAnexo];
    node.presente = !node.presente;
    setMedAnexo(updatedNodes);
  };

  const [checked, setChecked] = useState(false);

  const indice = estados.findIndex(option => option.code === Number(aptitud))

  console.log("ESTADOS APTITUD", estados.filter(option => option?.code === aptitud)[0])
  // const presentCheckboxTemplate = (node) => {
  //   return (
  //     //<input
  //     //   type="checkbox"
  //     //   checked={node.data.present}
  //     //   onChange={() => handlePresentCheckboxChange(node)}
  //     // />
  //     <div className="card flex justify-content-center">
  //           <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
  //     </div>
  //     // <input
  //     //   type="checkbox"
  //     //   checked={node.data.present}
  //     //   onChange={() => handlePresentCheckboxChange(node)}
  //     // />
  //   );
  // };
  const presentCheckboxTemplate = (node) => {
    return (
      <Checkbox
        checked={node?.presente == 1 ? true : false}
        onChange={() => handlePresentCheckboxChange(node)}
        disabled={stateAtencion[pageViewContext].estado === 3}
        style={{ width: '2rem' }} // Personaliza el estilo según tus necesidades
      />
    );
  };

  useEffect(() => {
    console.log("Anexo 16", medAnexo)
    setAnexo16Context({
      ...anexo16Context,
      observaciones: observaciones,
      uso_medicamentos: medicamentos,
      aptitud: aptitud,
      med_anexo_preguntas: medAnexo
    })
  }, [observaciones, medicamentos, aptitud, medAnexo])


  return (
    <div className="card pb-3">
      <div>
        <DataTable
          value={medAnexo}
          style={{ height: '20rem', overflowY: 'scroll' }}
        >
          <Column field="number" header="N°" style={{ width: '100px', textAlign: 'center' }}></Column>
          <Column field="pregunta" header="Pregunta"></Column>
          <Column
            body={presentCheckboxTemplate}
            header="Presente"
            style={{ textAlign: 'center' }}
          ></Column>
        </DataTable>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="flex gap-3 flex-wrap">
            <span>Observaciones</span>
            <VoiceButton
              value={observaciones}
              onChange={setObservaciones}
              placeholder="Comentario"
              disabled={stateAtencion[pageViewContext].estado === 3}
              className="w-100" // Hacer que Observaciones ocupe todo el ancho
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="flex gap-3 flex-column">
            <span>Uso medicamentos</span>
            <VoiceButton
              value={medicamentos}
              onChange={setMedicamentos}
              placeholder="Comentario"
              disabled={stateAtencion[pageViewContext].estado === 3}

            />
            <span>Aptitud</span>
            <Dropdown
              value={estados.filter(option => option?.code === aptitud)[0] ?? estados[indice]}
              onChange={(e) => setAptitud(e.value.code)}
              options={estados}
              optionLabel="name"
              placeholder="Aptitud"
              disabled={stateAtencion[pageViewContext].estado === 3}
              className="w-full "
            />
          </div>
        </div>
      </div>

    </div>
  );
}
