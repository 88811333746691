import React, { useContext, useState, useRef, useEffect } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import AuthUser from "../../AuthUser";
import { RadContext } from "./RadProvider";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconRadiologia } from "../../Services/importIcons";
import { Toast } from "primereact/toast";
import RayosX from "./RayosX";
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";
import { useParams } from "react-router-dom";
import Column from "../../Components/Radiologia/columna/columna";
import Torax from "../../Components/Radiologia/columna/torax";
import { RAContext } from "../RutaAtencion/RAContext";
import ModalCancelarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion";
import ModalGuardarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion";
import BottomsFootComponent from "../../Components/Clinica/AtencionMedica/pure/BottomsFoot";

const PageRad = ({ revision }) => {
   const { http } = AuthUser();
   const { idPaciente } = useParams();
   const toast = useRef(null);

   let { ToraxOIT, setToraxOIT, LumboSacraContext, setLumboSacraContext, RayTorax, setRayTorax, setAtencionStatus, setTimeStart, dataRayosXToraxOIT, setDataRayosXToraxOIT, RayXToraxEstandar, setRayXToraxEstandar } = useContext(RadContext);
   let { stateAtencion, setStateAtencion, pageViewContext, radiologiaAreasContext, radiologiaDetalleId, setRadiologiaDetalleId } = useContext(RAContext)


   const [visibleImprimir, setVisibleImprimir] = useState(false);
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [visibleCancelar, setVisibleCancelar] = useState(false);
   const [activeIndex, setActiveIndex] = useState(0);
   const [estado, setEstado] = useState(false)
   const [idRadiologia, setIdRadiologia] = useState(null);
   const [documentos, setDocumentos] = useState([])
   const IdPac = { paciente_id: idPaciente };

   const showToast = (type, title, detail) => {
      if (toast.current) {
         toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
         });
      } else {
         console.error("Toast no inicializado");
      }
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };

   //INICIAR ATENCION
   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }

      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            setRadiologiaDetalleId({
               id: radiologiaDetalleId.id,
               estado: 1,
               hora_inicio: radiologiaDetalleId.hora_inicio,
               fecha_inicio: radiologiaDetalleId.fecha_inicio,
               hora_fin: radiologiaDetalleId.hora_fin,
               fecha_fin: radiologiaDetalleId.fecha_fin,
            })
         })

   }

   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setRadiologiaDetalleId({
               id: radiologiaDetalleId.id,
               estado: 2,
               hora_inicio: radiologiaDetalleId.hora_inicio,
               fecha_inicio: radiologiaDetalleId.fecha_inicio,
               hora_fin: radiologiaDetalleId.hora_fin,
               fecha_fin: radiologiaDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setRadiologiaDetalleId({
                  id: radiologiaDetalleId.id,
                  estado: 2,
                  hora_inicio: radiologiaDetalleId.hora_inicio,
                  fecha_inicio: radiologiaDetalleId.fecha_inicio,
                  hora_fin: radiologiaDetalleId.hora_fin,
                  fecha_fin: radiologiaDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }

   //CREAR EL ID DE Radiologia
   const RadiologiaCreate = () => {
      http
         .post("radiologia/radiologia/create", { paciente_id: idPaciente })
         .then((response) => {
            //SETEAR EL Radiologia
            setIdRadiologia(response.data.radiologia_id);
            showToast('success', 'Éxito', response.data.resp);

         }).catch((error) => {
            console.log(error);
            showToast('error', 'Error', error.message);
         });
   }

   const getRadiologia = () => {
      http
         .get(`radiologia/get/${idPaciente}`)
         .then((response) => {
            console.log("idRadiologia", response)
            if (response?.data?.resp) {
               setIdRadiologia(response?.data?.resp?.id);
               getRadiologiaTorax();
               getRadiologiaColumnaLumbo();
               getRadiologiaToraxOIT();
            }
         })
         .catch((error) => {
            console.log(error)
         })
   }
   const handleSubmit = () => {
      createRadiologiaEstandar(RayXToraxEstandar);
      createHabitosLumboSacraContext(LumboSacraContext);
      createHabitosRayTorax(RayTorax);
      showToast(
         "success",
         "Guardado correctamente",
         "Datos del paciente en medicina general guardados"
      )
   }

   // const handleGetBD = () => {
   //    showToast('success', 'Borrado correctamente', "Datos borrados correctamente");
   //    getAntOcup();
   //    getPestall();
   //    getSigVit();
   // }

   const createRadiologiaEstandar = (data) => {
      const formData = new FormData();

      formData.append('observaciones', data.observaciones);

      data.rad_detalles.forEach((detalle, index) => {
         formData.append(`rad_detalles[${index}][descripcion]`, detalle.descripcion);
         formData.append(`rad_detalles[${index}][opcion]`, detalle.opcion);
         formData.append(`rad_detalles[${index}][rad_partes_id]`, index + 1);
      });


      formData.append(`lectura_oit[primer_dato]`, data.lectura_oit.primer_dato);
      formData.append(`lectura_oit[segundo_dato]`, data.lectura_oit.segundo_dato);
      formData.append(`lectura_oit[tercer_dato]`, data.lectura_oit.tercer_dato);
      formData.append(`lectura_oit[cuarto_dato]`, data.lectura_oit.cuarto_dato);
      formData.append(`lectura_oit[simbolo]`, data.lectura_oit.simbolo);
      formData.append(`lectura_oit[calidad_rx]`, data.lectura_oit.calidad_rx);
      // if (typeof data.lectura_oit.imagen == "object") {
      //    formData.append(`lectura_oit[imagen]`, data.lectura_oit.imagen);
      // }
      formData.append(`lectura_oit[imagen]`, data.lectura_oit.imagen);



      formData.append(`rad_columna[observaciones]`, data.rad_columna.observaciones);
      formData.append(`rad_columna[conclusiones]`, data.rad_columna.conclusiones);
      // if (typeof data.rad_columna.archivo == "object") {
      //    formData.append(`rad_columna[archivo]`, data.rad_columna.archivo);
      // }
      formData.append(`rad_columna[archivo]`, data.rad_columna.archivo);

      // Realizar la solicitud HTTP POST
      http
         .post(`radiologia/torax/create/${idRadiologia}`, formData)
         .then((response) => {
         })
         .catch(error => {
            console.log(error)
         });
   };

   // const Toraxoitreplas = {
   //    "observaciones": "observaciones",
   //    "rad_detalles": [
   //       {
   //          "rad_partes_id": 1,
   //          "descripcion": "descripcion",
   //          "opcion": "normal"
   //       }
   //    ],
   //    "lectura_oit": {
   //       "primer_dato": "primero",
   //       "segundo_dato": "segundo",
   //       "tercer_dato": "tercero",
   //       "cuarto_dato": "cuarto",
   //       "simbolo": "simbolo",
   //       "calidad_rx": "calidad_rx",
   //       "imagen": "imagen.png"
   //    },
   //    "rad_columna": {
   //       "observaciones": "observaciones",
   //       "conclusiones": "conclusiones",
   //       "archivo": "archivo.pdf"
   //    },
   //    "informe_oit": {
   //       "fecha": "15/03/2024",
   //       "nro_placa": "675",
   //       "medico_evaluador": "nombre del medico",
   //       "colegiatura": "154",
   //       "r_n_e": "548",
   //       "comentario": "comentario",
   //       "observaciones": "observaciones",
   //       "archivo": "archivo.pdf"
   //    },
   //    "calidad_rx": 1,
   //    "causa": 1,
   //    "descripcion": "Calidad Grafica",
   //    "zona_afectada": {
   //       "derecha": "ojo derecho afectado",
   //       "izquierda": "ojo izquierdo afectado"
   //    },
   //    "forma_tamano": {
   //       "primaria": "p",
   //       "secundaria": "q"
   //    },
   //    "opacidad_grande": 1,
   //    "opacidad_pequena": 1,
   //    "neu_pared_toraxica": {
   //       "sitio": 1,
   //       "calificacion": 1
   //    },
   //    "neu_frente": {
   //       "sitio": 1,
   //       "calificacion": 1
   //    },
   //    "neu_diagrama": {
   //       "sitio": 1,
   //       "calificacion": 1
   //    },
   //    "neu_otros_sitios": {
   //       "sitio": 1,
   //       "calificacion": 1
   //    },
   //    "neu_extension": {
   //       "derecha": 1,
   //       "izquierda": 1
   //    },
   //    "neu_ancho": {
   //       "derecha": 1,
   //       "izquierda": 1
   //    },
   //    "neu_obliteracion": "1",
   //    "eng_perfil": {
   //       "pared_toraxica": 1,
   //       "calificacion": 1
   //    },
   //    "eng_frente": {
   //       "pared_toraxica": 1,
   //       "calificacion": 1
   //    },
   //    "eng_extension": {
   //       "derecha": 1,
   //       "izquierda": 1
   //    },
   //    "eng_ancho": {
   //       "derecha": 1,
   //       "izquierda": 1
   //    },
   //    "simbolos": [
   //       {
   //          "nombre_simbolo": "aa"
   //       }
   //    ]
   // }


   const createHabitosLumboSacraContext = (data) => {
      const formData = new FormData()
      formData.append("sintomas_hallasgos", data?.sintomas_hallasgos)
      formData.append("observaciones", data?.observaciones)
      formData.append("recomendaciones", data?.recomendaciones)
      formData.append("conclusiones", data?.conclusiones)
      formData.append("fecha", data?.fecha);
      if (typeof data?.archivo == "object") {
         formData.append("archivo", data?.archivo);
      }
      formData.append("resultado", data?.resultado);
      formData.append("evaluador_id", data?.colegiatura);
      http
         .post(`radiologia/columna-lumbo/create/${idRadiologia}`, formData)
         .then((response) => {
         })
         .catch(error => {
            console.log(error)
         })
   }
   const createHabitosRayTorax = (data) => {
      const formData = new FormData();

      // Agregar observaciones
      formData.append('observaciones', data.observaciones);

      // Agregar rad_detalles
      data.rad_detalles.forEach((detalle, index) => {
         formData.append(`rad_detalles[${index}][rad_partes_id]`, detalle.rad_partes_id);
         formData.append(`rad_detalles[${index}][descripcion]`, detalle.descripcion);
         formData.append(`rad_detalles[${index}][opcion]`, detalle.opcion);
      });

      // Agregar lectura_oit
      const { primer_dato, segundo_dato, tercer_dato, cuarto_dato, simbolo, calidad_rx, imagen } = data.lectura_oit;
      formData.append('lectura_oit[primer_dato]', primer_dato);
      formData.append('lectura_oit[segundo_dato]', segundo_dato);
      formData.append('lectura_oit[tercer_dato]', tercer_dato);
      formData.append('lectura_oit[cuarto_dato]', cuarto_dato);
      formData.append('lectura_oit[simbolo]', simbolo);
      formData.append('lectura_oit[calidad_rx]', calidad_rx);
      formData.append('lectura_oit[imagen]', imagen);

      // Agregar rad_columna
      formData.append('rad_columna[observaciones]', "");
      formData.append('rad_columna[conclusiones]', "");
      formData.append('rad_columna[archivo]', null);

      // Agregar informe_oit
      const { fecha, nro_placa, medico_evaluador, colegiatura, r_n_e, comentario, archivo: informe_oit_archivo } = data.informe_oit;
      formData.append('informe_oit[fecha]', fecha);
      formData.append('informe_oit[nro_placa]', nro_placa);
      formData.append('informe_oit[medico_evaluador]', medico_evaluador);
      formData.append('informe_oit[colegiatura]', colegiatura);
      formData.append('informe_oit[r_n_e]', r_n_e);
      formData.append('informe_oit[comentario]', comentario);
      formData.append('informe_oit[observaciones]', data.informe_oit.observaciones);
      formData.append('informe_oit[archivo]', data.informe_oit.archivo);

      // Agregar otros datos
      formData.append('calidad_rx', data.calidad_rx);
      formData.append('causa', data.causa);
      formData.append('descripcion', data.descripcion);

      // Agregar zona_afectada
      formData.append('zona_afectada[derecha]', data.zona_afectada.derecha);
      formData.append('zona_afectada[izquierda]', data.zona_afectada.izquierda);

      // Agregar forma_tamano
      formData.append('forma_tamano[primaria]', data.forma_tamano.primaria);
      formData.append('forma_tamano[secundaria]', data.forma_tamano.secundaria);
      formData.append('opacidad_grande', data.opacidad_grande);
      formData.append('opacidad_pequena', data.opacidad_pequena);



      // Agregar neu_pared_toraxica, neu_frente, neu_diagrama, neu_otros_sitios, neu_extension, neu_ancho, neu_obliteracion
      const {
         sitio: sitio_neu_pared_toraxica,
         calificacion: calificacion_neu_pared_toraxica,
      } = data.neu_pared_toraxica;
      const { sitio: sitio_neu_frente, calificacion: calificacion_neu_frente } = data.neu_frente;
      const {
         sitio: sitio_neu_diagrama,
         calificacion: calificacion_neu_diagrama,
      } = data.neu_diagrama;
      const {
         sitio: sitio_neu_otros_sitios,
         calificacion: calificacion_neu_otros_sitios,
      } = data.neu_otros_sitios;
      const { derecha: neu_extension_derecha, izquierda: neu_extension_izquierda } = data.neu_extension;
      const { derecha: neu_ancho_derecha, izquierda: neu_ancho_izquierda } = data.neu_ancho;

      formData.append('neu_pared_toraxica[sitio]', sitio_neu_pared_toraxica);
      formData.append('neu_pared_toraxica[calificacion]', calificacion_neu_pared_toraxica);
      formData.append('neu_frente[sitio]', sitio_neu_frente);
      formData.append('neu_frente[calificacion]', calificacion_neu_frente);
      formData.append('neu_diagrama[sitio]', sitio_neu_diagrama);
      formData.append('neu_diagrama[calificacion]', calificacion_neu_diagrama);
      formData.append('neu_otros_sitios[sitio]', sitio_neu_otros_sitios);
      formData.append('neu_otros_sitios[calificacion]', calificacion_neu_otros_sitios);
      formData.append('neu_extension[derecha]', neu_extension_derecha);
      formData.append('neu_extension[izquierda]', neu_extension_izquierda);
      formData.append('neu_ancho[derecha]', neu_ancho_derecha);
      formData.append('neu_ancho[izquierda]', neu_ancho_izquierda);
      formData.append('neu_obliteracion', data.neu_obliteracion);

      // Agregar eng_perfil, eng_frente, eng_extension, eng_ancho
      const { pared_toraxica: pared_toraxica_eng_perfil, calificacion: calificacion_eng_perfil } = data.eng_perfil;
      const { pared_toraxica: pared_toraxica_eng_frente, calificacion: calificacion_eng_frente } = data.eng_frente;
      const { derecha: eng_extension_derecha, izquierda: eng_extension_izquierda } = data.eng_extension;
      const { derecha: eng_ancho_derecha, izquierda: eng_ancho_izquierda } = data.eng_ancho;

      formData.append('eng_perfil[pared_toraxica]', pared_toraxica_eng_perfil);
      formData.append('eng_perfil[calificacion]', calificacion_eng_perfil);
      formData.append('eng_frente[pared_toraxica]', pared_toraxica_eng_frente);
      formData.append('eng_frente[calificacion]', calificacion_eng_frente);
      formData.append('eng_extension[derecha]', eng_extension_derecha);
      formData.append('eng_extension[izquierda]', eng_extension_izquierda);
      formData.append('eng_ancho[derecha]', eng_ancho_derecha);
      formData.append('eng_ancho[izquierda]', eng_ancho_izquierda);

      // Agregar simbolos
      data.simbolos.forEach((simbolo, index) => {
         formData.append(`simbolos[${index}][nombre_simbolo]`, simbolo);
      });

      // Enviar la solicitud
      http.post(`radiologia/torax-oit/create/${idRadiologia}`, formData)
         .then((response) => {
            // Manejar la respuesta aquÃ­
            console.log(response)
         })
         .catch((error) => {
            // Manejar cualquier error
            console.error(error);
         });
   };

   const getRadiologiaTorax = () => {
      http
         .get(`radiologia/torax/get/${idPaciente}`)
         .then((response) => {
            console.log("Datos de radiología torax:", response);

            if (response?.data) {
               // Procesar la respuesta del servidor según tus necesidades
               console.log("Datos obtenidos:", response?.data);
               setRayXToraxEstandar(
                  {
                     //Radiografia
                     observaciones: response?.data?.resp?.radiografia?.observaciones,
                     rad_detalles: [
                        {
                           rad_partes_id: 1,
                           descripcion: response?.data?.resp?.radiografia?.rad_detalles[0]?.descripcion,
                           opcion: response?.data?.resp?.radiografia?.rad_detalles[0]?.opcion,
                        },
                        {
                           rad_partes_id: 2,
                           descripcion: response?.data?.resp?.radiografia?.rad_detalles[1]?.descripcion,
                           opcion: response?.data?.resp?.radiografia?.rad_detalles[1]?.opcion,
                        },
                        {
                           rad_partes_id: 3,
                           descripcion: response?.data?.resp?.radiografia?.rad_detalles[2]?.descripcion,
                           opcion: response?.data?.resp?.radiografia?.rad_detalles[2]?.opcion,
                        },
                        {
                           rad_partes_id: 4,
                           descripcion: response?.data?.resp?.radiografia?.rad_detalles[3]?.descripcion,
                           opcion: response?.data?.resp?.radiografia?.rad_detalles[3]?.opcion,
                        },
                        {
                           rad_partes_id: 5,
                           descripcion: response?.data?.resp?.radiografia?.rad_detalles[4]?.descripcion,
                           opcion: response?.data?.resp?.radiografia?.rad_detalles[4]?.opcion,
                        },
                        {
                           rad_partes_id: 6,
                           descripcion: response?.data?.resp?.radiografia?.rad_detalles[5]?.descripcion,
                           opcion: response?.data?.resp?.radiografia?.rad_detalles[5]?.opcion,
                        },
                        {
                           rad_partes_id: 7,
                           descripcion: response?.data?.resp?.radiografia?.rad_detalles[6]?.descripcion,
                           opcion: response?.data?.resp?.radiografia?.rad_detalles[6]?.opcion,
                        }
                     ],
                     lectura_oit: {
                        primer_dato: response?.data?.resp?.radiografia?.rad_lectura_oit?.primer_dato,
                        segundo_dato: response?.data?.resp?.radiografia?.rad_lectura_oit?.segundo_dato,
                        tercer_dato: response?.data?.resp?.radiografia?.rad_lectura_oit?.tercer_dato,
                        cuarto_dato: response?.data?.resp?.radiografia?.rad_lectura_oit?.cuarto_dato,
                        simbolo: response?.data?.resp?.radiografia?.rad_lectura_oit?.simbolo,
                        calidad_rx: response?.data?.resp?.radiografia?.rad_lectura_oit?.calidad_rx,
                        imagen: response?.data?.resp?.radiografia?.rad_lectura_oit?.imagen ? response?.data?.resp?.radiografia?.rad_lectura_oit?.imagen.replace("/app", "") : ""
                     },
                     //Columna
                     rad_columna: {
                        observaciones: response?.data?.resp?.rad_columna?.observaciones,
                        conclusiones: response?.data?.resp?.rad_columna?.conclusiones,
                        archivo: response?.data?.resp?.rad_columna?.archivo ? response?.data?.resp?.rad_columna?.archivo.replace("/app", "") : ""
                     }
                  }
               );
            }
         })
         .catch((error) => {
            console.error("Error al obtener datos de radiología torax:", error.message);
         });
   };

















   const getRadiologiaToraxOIT = () => {
      http
         .get(`radiologia/torax-oit/get/${idPaciente}`)
         .then((response) => {
            console.log("Datos de radiología torax OIT:", response);

            setRayTorax({
               observaciones: response.data.resp.radiografia?.observaciones,
               rad_detalles: [
                  {
                     rad_partes_id: response.data.resp.radiografia.rad_detalles[0].rad_partes_id,
                     descripcion: response.data.resp.radiografia.rad_detalles[0].descripcion,
                     opcion: response.data.resp.radiografia.rad_detalles[0].opcion,
                  },
                  {
                     rad_partes_id: response.data.resp.radiografia.rad_detalles[1].rad_partes_id,
                     descripcion: response.data.resp.radiografia.rad_detalles[1].descripcion,
                     opcion: response.data.resp.radiografia.rad_detalles[1].opcion,
                  },
                  {
                     rad_partes_id: response.data.resp.radiografia.rad_detalles[2].rad_partes_id,
                     descripcion: response.data.resp.radiografia.rad_detalles[2].descripcion,
                     opcion: response.data.resp.radiografia.rad_detalles[2].opcion,
                  },
                  {
                     rad_partes_id: response.data.resp.radiografia.rad_detalles[3].rad_partes_id,
                     descripcion: response.data.resp.radiografia.rad_detalles[3].descripcion,
                     opcion: response.data.resp.radiografia.rad_detalles[3].opcion,
                  },
                  {
                     rad_partes_id: response.data.resp.radiografia.rad_detalles[4].rad_partes_id,
                     descripcion: response.data.resp.radiografia.rad_detalles[4].descripcion,
                     opcion: response.data.resp.radiografia.rad_detalles[4].opcion,
                  },
                  {
                     rad_partes_id: response.data.resp.radiografia.rad_detalles[5].rad_partes_id,
                     descripcion: response.data.resp.radiografia.rad_detalles[5].descripcion,
                     opcion: response.data.resp.radiografia.rad_detalles[5].opcion,
                  },
                  {
                     rad_partes_id: response.data.resp.radiografia.rad_detalles[6].rad_partes_id,
                     descripcion: response.data.resp.radiografia.rad_detalles[6].descripcion,
                     opcion: response.data.resp.radiografia.rad_detalles[6].opcion,
                  },
               ],
               lectura_oit: {
                  imagen: response.data.resp.radiografia.rad_lectura_oit.imagen ? response.data.resp.radiografia.rad_lectura_oit.imagen.replace("radiologia/torax/", "").replace("/app", "") : ""
               },
               // Informe oit
               informe_oit: {
                  fecha: response?.data?.resp?.informe_oit?.fecha,
                  nro_placa: response?.data?.resp?.informe_oit?.nro_placa,

                  // VARIABLE PARA ALMACENAR NO PARA ENVIAR
                  objeto_medico: null,
                  // -----
                  medico_evaluador: null,
                  colegiatura: response?.data?.resp?.informe_oit?.colegiatura,
                  r_n_e: response?.data?.resp?.informe_oit?.r_n_e,
                  comentario: "null",
                  observaciones: response?.data?.resp?.informe_oit?.observaciones,
                  archivo: response?.data?.resp?.informe_oit?.archivo ? response?.data?.resp?.informe_oit?.archivo.replace("/app", "") : "",
               },
               calidad_rx: response?.data?.resp?.informe_oit?.calidadgrafica?.calidad_rx,
               causa: response?.data?.resp?.informe_oit?.calidadgrafica?.causa,
               descripcion: response?.data?.resp?.informe_oit?.calidadgrafica?.descripcion,

               zona_afectada: {
                  derecha: response?.data?.resp?.informe_oit?.anormalidadesparenquimatosas?.zonaafectada?.derecha,
                  izquierda: response?.data?.resp?.informe_oit?.anormalidadesparenquimatosas?.zonaafectada?.izquierda,
               },
               forma_tamano: {
                  primaria: response?.data?.resp?.informe_oit?.anormalidadesparenquimatosas?.forma_tamaño?.primaria,
                  secundaria: response?.data?.resp?.informe_oit?.anormalidadesparenquimatosas?.forma_tamaño?.secundaria,
               },
               opacidad_pequena: response?.data?.resp?.informe_oit?.anormalidadesparenquimatosas?.opacidad_pequena,
               opacidad_grande: response?.data?.resp?.informe_oit?.anormalidadesparenquimatosas?.opacidad_grande,
               neu_pared_toraxica: {
                  sitio: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_pared_toraxica?.sitio,
                  calificacion: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_pared_toraxica?.calificacion,
               },
               neu_frente: {
                  sitio: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_frente?.sitio,
                  calificacion: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_frente?.calificacion,
               },
               neu_diagrama: {
                  sitio: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_diagrama?.sitio,
                  calificacion: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_diagrama?.calificacion,
               },
               neu_otros_sitios: {
                  sitio: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_otros_sitio?.sitio,
                  calificacion: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_otros_sitio?.calificacion,
               },
               neu_extension: {
                  derecha: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_extension?.derecha,
                  izquierda: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_extension?.izquierda,
               },
               neu_ancho: {
                  derecha: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_ancho?.derecha,
                  izquierda: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_ancho?.izquierda,
               },
               neu_obliteracion: response?.data?.resp?.informe_oit?.anormalidadespleurales?.neu_obliteracion,
               
               eng_perfil: {
                  pared_toraxica: response?.data?.resp?.informe_oit?.engrosamiento?.eng_perfil?.pared_toraxica,
                  calificacion: response?.data?.resp?.informe_oit?.engrosamiento?.eng_perfil?.calificacion,
               },
               eng_frente: {
                  pared_toraxica: response?.data?.resp?.informe_oit?.engrosamiento?.eng_frente?.pared_toraxica,
                  calificacion: response?.data?.resp?.informe_oit?.engrosamiento?.eng_frente?.calificacion,
               },
               eng_extension: {
                  derecha: response?.data?.resp?.informe_oit?.engrosamiento?.eng_extension?.derecha,
                  izquierda: response?.data?.resp?.informe_oit?.engrosamiento?.eng_extension?.izquierda,
               },
               eng_ancho: {
                  derecha: response?.data?.resp?.informe_oit?.engrosamiento?.eng_ancho?.derecha,
                  izquierda: response?.data?.resp?.informe_oit?.engrosamiento?.eng_ancho?.izquierda,
               },

               simbolos: response?.data?.resp?.informe_oit?.simbolo.map(item => item.simbolo_detalle.nombre),

               rad_columna: {
                  observaciones: "null para mas",
                  conclusiones: "null para mas 234",
                  archivo: null,
               },
            });
         }
         )
         .catch((error) => {
            console.error("Error al obtener datos de radiología torax OIT:", error.message);
         });
   };















   const getRadiologiaColumnaLumbo = () => {
      http
         .get(`radiologia/columna-lumbo/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               // Procesar la respuesta del servidor según tus necesidades
               console.log("Datos de radiología columna lumbo:", response?.data);
               setLumboSacraContext(
                  {
                     sintomas_hallasgos: response?.data?.resp?.sintomas_hallasgos,
                     observaciones: response?.data?.resp?.observaciones,
                     recomendaciones: response?.data?.resp?.recomendaciones,
                     conclusiones: response?.data?.resp?.conclusiones,
                     fecha: response?.data?.resp?.fecha,
                     colegiatura: response?.data?.resp?.evaluador_id,
                     resultado: response?.data?.resp?.resultado,
                     archivo: response?.data?.resp?.archivo ? response?.data?.resp?.archivo.replace("/app", "") : ""
                  }
               )
            }
         })
         .catch((error) => {
            console.error("Error al obtener datos de radiología columna lumbo:", error.message);
         });
   };

   const handleGetBD = () => {
      showToast('success', 'Borrado correctamente', "Datos borrados correctamente");
      getRadiologiaTorax();
      // getRadiologiaToraxOIT();
      getRadiologiaColumnaLumbo();
   }

   useEffect(() => {
      getRadiologia();
      getDocumentos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const renderTabs = (area) => {
      const optionMapping = {
         2: (
            <TabPanel header="Rayos X – Tórax - OIT">
               <RayosX />
               {/* <Torax /> */}
            </TabPanel>
         ),
         3: (
            <TabPanel header="Rayos X - Columna - Lumbo sacra">
               <Column />
            </TabPanel>
         ),
         4: (
            <TabPanel header="Rayos X - Tórax estándar">
               <Torax />
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               radiologiaAreasContext?.map(area => {
                  return renderTabs(area)
               })
            }
         </TabView>
         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
            showToast={showToast}
         />
         <ModalCancelarAtencion
            visible={visibleCancelar}
            Atencion="Radiología"
            onNo={() => setVisibleCancelar(false)}
            onYes={() => { handleGetBD(); setVisibleCancelar(false) }}
            onHide={() => setVisibleCancelar(false)}
         />
         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="Radiología"
            onNo={() => setVisibleGuardar(false)}
            onYes={() => { handleSubmit(); setVisibleGuardar(false) }}
            onHide={() => setVisibleGuardar(false)}
         />

         {radiologiaDetalleId.estado === '0' && (
            <RATimeView
               title="Radiologia"
               AreaDetalleId={radiologiaDetalleId?.id}
               icon={iconRadiologia}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={RadiologiaCreate}
               textButton='Iniciar atención'
            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title="Radiologia"
               AreaDetalleId={radiologiaDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar revisión'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={radiologiaDetalleId?.hora_final}
               VerHoraInicio={radiologiaDetalleId?.hora_inicio}
               VerfechaInicio={radiologiaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}

         {estado && revision !== 1 && (
            <RATimeView
               title="Radiologia"
               AreaDetalleId={radiologiaDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={radiologiaDetalleId?.hora_final}
               VerHoraInicio={radiologiaDetalleId?.hora_inicio}
               VerfechaInicio={radiologiaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PageRad;
