import { Button } from "primereact/button";
import { useEffect, useState } from 'react';
import { InputText } from "primereact/inputtext";
import '../../../Style/EstilosSoporteBregma/TutorialesB.css'
import { Skeleton } from 'primereact/skeleton';
import PopUp_Videos_Tut from './Componente_tutorialesBregma.jsx';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';

// import "./estilo.css";


export default function TutorialesB({ SendTutorialesB, vistaselected,TutorialItems ,formTutorialesBreg,setFormTutorialesBreg}) {
	
	
	const [loading, setLoading] = useState(true);
	const [VistaDialog, setVistaDialog] = useState(false);

	function MostrarPopUp() {
		setVistaDialog(true);
		console.log(VistaDialog);
	};

	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 3000);
	}, [])

	function OnchangeInputs(e) {
		console.log(e)

		const { name, value } = e.target;

		setFormTutorialesBreg((prevForm) => ({
			...prevForm,
			[vistaselected]: {
				...prevForm[vistaselected],
				[name]: value,
			},
		}));
	}

	const toast = useRef(null);
	const SendInfo = () => {
		toast.current.show({ severity: 'info', summary: 'Info', detail: 'Se guardo correctamente' });
		SendTutorialesB(formTutorialesBreg);

		/*alert('Se guardó correctamente.');*/
	};

	const [buttonState, setButtonState] = useState('edit');

	const handleClick = () => {
		if (buttonState === 'edit') {
			setButtonState('save');
		} else {
			setFormTutorialesBreg(formTutorialesBreg);
			setButtonState('edit');
			SendInfo();

		}
	};

	const ArrayProducServic = [
		{ name: 'Servicios', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Productos', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Terminos y Condiciones', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' }];
	const ArrayOperaciones = [
		{ name: 'Servicios', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Productos', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' }];
	const ArrayVentas = [
		{ name: 'Gestión ventas', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Gestión del cliente', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' }];
	const ArrayRecursosHumanos = [
		{ name: 'Mis Locales', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Roles', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Mi personal', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Reclutamiento', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' }];
	const ArrayContabilidad = [
		{ name: 'Costo por Atención', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Facturación', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' },
		{ name: 'Mis Pagos', link: 'https://www.youtube.com/embed/4ZmzGj6gQhw' }];

	return (
		<div className="Contenedor_principal_tutoB">
			<div className='Contenedor_iconosAH_tutoB'>
				<div className='contenedor_icono_princ_tutoB'>
					<i className="pi pi-star" style={{ fontSize: '27px', color: '#5555d8', fontWeight: '600' }}></i>
				</div>
				<Toast ref={toast} />
				<Button
					style={{ backgroundColor: 'rgb(207, 225, 252)', border: '0' }}
					// onClick={SendInfo}
					onClick={() => {
						console.log("sas", vistaselected)

					}}
					icon={`${'pi pi-save text-blue-500'} font-bold text-base p-1`}
				/>
			</div>
			<div className='Descripcion_sectionAH_tutoB'>
				<h2 className='titulo_componente_tutoB'>Tutoriales Bregma</h2>
				<h4 className='subtitulo_componente_tutoB'>Administrador</h4>
				<p className='letra_Descripcion_componente_tutoB'>Encuentra los link para cada área referente de tu menu en bregma.</p>
			</div>
			{
				TutorialItems[vistaselected].map((element,i) => {
					return(<div className='Inputs_Dataa_tutoB' key={element}>
					<div className="contenedor_titu_boton_anexo">
						<p className='content_data_input_tutoB'><i className="pi pi-whatsapp"></i>Perfil</p>
						<PopUp_Videos_Tut
							ArrayComponent=''
							TituloPopUp='Perfíl'
							LinkPrincipal={formTutorialesBreg?.[vistaselected]?.[element.label]  || ''}
							setInputs={OnchangeInputs}
							
							
						/>
					</div>
					<div className='Contenedor_input_skeleton_tutoB'>
						{
							loading ? <Skeleton className="border-round-lg" height="41px" /> :
								<InputText
									name={element.label}
									onChange={OnchangeInputs}
									// placeholder="Ingrese su Perfil"
									placeholder="https://www.youtube.com/"
									className="p-inputtext-sm"
									value={formTutorialesBreg?.[vistaselected]?.[element.label] || ''}
									style={{ width: '100%' }}
								/>
						}
					</div>
				</div>)
					
					
					

				})

			}




			{/* <div className='Inputs_Dataa_tutoB'>
				<div className="contenedor_titu_boton_anexo"> 
					<p className='content_data_input_tutoB'><i className="pi pi-box"></i>Productos Servicios</p>
					<PopUp_Videos_Tut 
					ArrayComponent={ArrayProducServic}
					TituloPopUp='Productos Servicios'
					LinkPrincipal={formTutorialesBreg[vistaselected]?.productos_servicios || ''}
					onChange={OnchangeInputs}
					name="productos_servicios"

					
					/>	
					</div>
				<div className='Contenedor_input_skeleton_tutoB'>
				{
					loading ? <Skeleton className="border-round-lg" height="41px" /> :
					<InputText
						name="productos_servicios"
						onChange={OnchangeInputs}
						// placeholder="Ingrese su Productos y Servicios"
						placeholder="https://www.youtube.com/"
						className="p-inputtext-sm"
						style={{width:'100%'}}
						value={formTutorialesBreg[vistaselected]?.productos_servicios || ''}
					/>
					}
				</div>
			</div>

			<div className='Inputs_Dataa_tutoB'>
				<div className="contenedor_titu_boton_anexo"> 
					<p className='content_data_input_tutoB'><i className="pi pi-cog"></i>Operaciones</p>
					<PopUp_Videos_Tut 
					ArrayComponent={ArrayOperaciones}
					TituloPopUp='Operaciones'
					LinkPrincipal={formTutorialesBreg[vistaselected]?.operaciones || ''}
					onChange={OnchangeInputs}
					name="operaciones"
					/>	
				</div>
				<div className='Contenedor_input_skeleton_tutoB'>
				{
					loading ? <Skeleton className="border-round-lg" height="41px" /> :
					<InputText
						name="operaciones"
						onChange={OnchangeInputs}
						// placeholder="Ingrese sus Operaciones"
						placeholder="https://www.youtube.com/"
						className="p-inputtext-sm"
						style={{width:'100%'}}
						value={formTutorialesBreg[vistaselected]?.operaciones || ''}
					/>
					}
				</div>
			</div>

			<div className='Inputs_Dataa_tutoB'>
				<div className="contenedor_titu_boton_anexo"> 
					<p className='content_data_input_tutoB'><i className="pi pi-shopping-bag"></i>Ventas</p>
					<PopUp_Videos_Tut 
					ArrayComponent={ArrayVentas}
					TituloPopUp='Ventas'
					LinkPrincipal={formTutorialesBreg[vistaselected]?.ventas || ''}
					onChange={OnchangeInputs}
					name="ventas"
					/>	
				</div>
				
				<div className='Contenedor_input_skeleton_tutoB'>
				{
					loading ? <Skeleton className="border-round-lg" height="41px" /> :
					<InputText
						name="ventas"
						onChange={OnchangeInputs}
						// placeholder="Ingrese sus Ventas"
						placeholder="https://www.youtube.com/"
						className="p-inputtext-sm"
						style={{width:'100%'}}
						value={formTutorialesBreg[vistaselected]?.ventas || ''}
					/>
					}
				</div>
			</div>

			<div className='Inputs_Dataa_tutoB'>

				<div className="contenedor_titu_boton_anexo"> 
					<p className='content_data_input_tutoB'><i className="pi pi-shopping-bag"></i>Recursos Humanos</p>
					<PopUp_Videos_Tut 
					ArrayComponent={ArrayRecursosHumanos}
					TituloPopUp='Recursos Humanos'
					LinkPrincipal=''
					value={formTutorialesBreg[vistaselected]?.recursos_humanos || ''}
					onChange={OnchangeInputs}
					name="recursos_humanos"
					/>	
				</div>
				<div className='Contenedor_input_skeleton_tutoB'>
				{
					loading ? <Skeleton className="border-round-lg" height="41px" /> :
					<InputText
						name="recursos_humanos"
						onChange={OnchangeInputs}
						// placeholder="Ingrese su Recurso Humano"
						placeholder="https://www.youtube.com/"
						className="p-inputtext-sm"
						style={{width:'100%'}}
						value={formTutorialesBreg[vistaselected]?.recursos_humanos || ''}
					/>
					}
				</div>
			</div>

			<div className='Inputs_Dataa_tutoB'>
				<div className="contenedor_titu_boton_anexo"> 
					<p className='content_data_input_tutoB'><i className="pi pi-database"></i>Contabilidad</p>
					<PopUp_Videos_Tut 
					ArrayComponent={ArrayContabilidad}
					TituloPopUp='Contabilidad'
					LinkPrincipal={formTutorialesBreg[vistaselected]?.contabilidad || ''}
					onChange={OnchangeInputs}
					name="contabilidad"
					/>	
				</div>
				<div className='Contenedor_input_skeleton_tutoB'>
				{
					loading ? <Skeleton className="border-round-lg" height="41px" /> :
					<InputText
						name="contabilidad"
						onChange={OnchangeInputs}
						// placeholder="Ingrese su Contabilidad"
						placeholder="https://www.youtube.com/"
						className="p-inputtext-sm"
						style={{width:'100%'}}
						value={formTutorialesBreg[vistaselected]?.contabilidad || ''}
					/>
					}
				</div>
			</div>

			<div className='Inputs_Dataa_tutoB'>
				<div className="contenedor_titu_boton_anexo"> 
					<p className='content_data_input_tutoB'><i className="pi pi-chart-line"></i>Estadística</p>
					<PopUp_Videos_Tut 
					ArrayComponent=''
					TituloPopUp='Estadistica'
					LinkPrincipal={formTutorialesBreg[vistaselected]?.estadistica || ''}
					onChange={OnchangeInputs}
					name="estadistica"
		
					/>	
				</div>

				<div className='Contenedor_input_skeleton_tutoB'>
				{
					loading ? <Skeleton className="border-round-lg" height="41px" /> :
					<InputText
						name="estadistica"
						onChange={OnchangeInputs}
						// placeholder="Ingrese su Estadistica"
						placeholder="https://www.youtube.com/"
						className="p-inputtext-sm"
						style={{width:'100%'}}
						value={formTutorialesBreg[vistaselected]?.estadistica || ''}
					/>
					}
				</div>
			</div>

			<div className='Inputs_Dataa_tutoB'>

				<div className="contenedor_titu_boton_anexo"> 
					<p className='content_data_input_tutoB'><i className="pi pi-flag"></i>Soporte Técnico</p>
					<PopUp_Videos_Tut 
					ArrayComponent=''
					TituloPopUp='Soporte Técnico'
					LinkPrincipal={formTutorialesBreg[vistaselected]?.soporte_tecnico|| ''}
					onChange={OnchangeInputs}
					name="soporte_tecnico"
					/>	
				</div>
				<div className='Contenedor_input_skeleton_tutoB'>
				{
					loading ? <Skeleton className="border-round-lg" height="41px" /> :
					<InputText
						name="soporte_tecnico"
						onChange={OnchangeInputs}
						// placeholder="Ingrese su Soporte Tecnico"
						placeholder="https://www.youtube.com/"
						className="p-inputtext-sm"
						style={{width:'100%'}}
						value={formTutorialesBreg[vistaselected]?.soporte_tecnico|| ''}

					/>
					}
				</div>
			</div> */}
		</div>
	);
}
