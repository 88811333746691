import React, { useState, useContext, useRef } from "react";
//PARTES
import S3ProductPackageSelection from "./S3ProductPackageSelection";
import S3ProductSalesTreeTable from "./S3ProductSalesTreeTable";
import S3ProductType from "./S3ProductType";
import { Button } from "primereact/button";
import { SalesContext } from "../../SalesContext/SalesContext";
import iconProforma from '../../../../Images/gestionVentas/icon-proforma.svg'
import { InputText } from 'primereact/inputtext';
        
import { Dialog } from 'primereact/dialog';
import ModalCarga from "./ModalCarga";
import S4Credentials from "../S4Credentials/S4Credentials";

import imgSeleccionarPaquete from '../../../../Images/icono-seleccionar-paquete.png';

import { Toast } from 'primereact/toast';

const S3Product = ({ packages, postPackages, showToast, setVisible, visible, getProforma}) => {

    const toast = useRef(null);
    //#region VARIABLES DE INSTANCIA
    

    const { sales,sendPaquete,selectedPackageIndex,} = useContext(SalesContext);
    const [currentView, setCurrentView] = useState("main"); // Inicialmente, muestra la vista principal
    const changeView = (viewName) => {
        setCurrentView(viewName);
      };
      

    //#endregion

    //#region VARIABLES
    const [value, setValue] = useState('');
    const [checkedIndex, setCheckedIndex] = useState(null); 
    const [estadoEnvio, setEstadoEnvio] = useState(false);
    const [envio, setEnvio] = useState([]);
    const [priceType, setPriceType] = useState("precio_mensual");
    const [productType, setProductType] = useState("servicio");
    const [dataErrors, setDataErrors] = useState({
        codigo : false,
        selectPaquete : false
    });
    //#endregion
    
    //#region FUNCIONES
    
    const transformData = (paquetes) => {
        if (!paquetes[0]) {
            return [];
        } else {
            const areas = ['areas_medicas', 'capacitaciones', 'examenes'];
    
            return areas.map((area, index) => {
                const data = {
                    key: `node-${index}`,
                    data: {
                        nombre: area.replace('_', ' ').toUpperCase(),
                        description: `This is node ${index}`,
                    },
                    children: [],
                };
    
                const areaData = paquetes.flatMap((paquete) => paquete[area]);
    
                const areaNodes = transformDataChild(areaData, `node-${index}`);

                data.children = areaNodes;
                return data;
            });
        }
    };
    
    function transformDataChild(data, parentKey) {
        const children = ['Psicología','Triaje','Examen de Laboratorio', 'Examen Radiológico', 'Laboratorio Molecular', 'Laboratorio Clínico', 'Oftalmología'];
        return data.map((item, index) => {
            const isChild = children.includes(item.nombre);
    
            const node = isChild
                ? {
                    key: `${parentKey}-${index}`,
                    data: {
                        nombre: item.nombre.replace('_', ' ').toUpperCase(),
                        precio_referencial_anual:0,
                        precio_referencial_mensual:0,
                        precio_mensual:0,
                        precio_anual:0,
                    },
                    children: [],
                }
                : {
                    key: `${parentKey}-${index}`,
                    data: {
                        nombre: item.nombre,
                        precio_referencial_anual: item.precio_referencial_anual || 0,
                        precio_referencial_mensual: item.precio_referencial_mensual || 0,
                        precio_mensual: item.precio_mensual || 0,
                        precio_anual: item.precio_anual || 0,
                    },
                    children: [],
                };
    
            if (item.children && item.children.length > 0) {
                node.children = transformDataChild(item.children, `${parentKey}-${index}`);
            }
    
            return node;
        });
    }
    //#endregion

    //#region API
    const Enviar = () => {

      
        let form = {
          bregma_paquete_id: envio[0]?.paquete?.id,
          tipo_venta_tiempo: priceType === "precio_mensual" ? 1 : 2,
          bregma_paquete: sendPaquete || transformData(envio),
          descripcion: "descripcion de leads",
          codigo: value
          
        }

      //   console.log('form',form, checkedIndex)
        let status = true;

        let newErrors = {
            codigo : false,
            selectPaquete : false
        }
        if (!checkedIndex) {
            newErrors.selectPaquete = true
            status = false
        }
        if (!form.codigo || form.codigo === "") {
            newErrors.codigo = true
            status = false
        }
        setDataErrors(newErrors)

        if (dataErrors?.codigo && !value) {
            toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Ingrese un código de proforma',
            life: 3000, 
            });
        } else if(dataErrors?.selectPaquete && !checkedIndex) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Seleccione un paquete',
                life: 3000, 
            });
        } else {
            // Manejar alguna otra accion
        }

        if (status) {
            postPackages(sales.GCEmpresa.contrato_id, form, showToast, () => {
       
                setVisible(false); 
                getProforma();
                setValue("");
                setCheckedIndex(null);
              });
        } else {
            setCheckedIndex(null);
        }
        
       /*  postPackages(sales.GCEmpresa.contrato_id, form, showToast, () => {
       
            setVisible(false); 
            getProforma();
            setValue("");
            
          }); */

        
    }


    const headerContent = () => {
        return(
            <div className="flex align-items-center gap-5 pl-3">
                <div 
                    className="flex align-items-center justify-content-center" 
                    style={{
                    height: "60px",
                    width: "60px",
                    background: "#dadafc",
                    borderRadius: "10px" }}
                >
                    <img src={iconProforma} alt="icono-proforma" />
                </div>
                
                <span>Crear proforma</span>
            </div>
        )
    }

    /*<Dialog header="Crear proforma" className="dialog-s3" visible={visible} style={{ width: '1530px', height: "940px", marginLeft: '220px' }} onHide={() => {setVisible(false); setEnvio([]);}} draggable={false}>*/ 
return(
<div className="gap-3 flex flex-wrap w-full content-dialog-s3">

        <Dialog header={headerContent} className="dialog-s3" visible={visible} style={{ width: '1530px', height: "940px", marginLeft: '220px' }} onHide={() => setVisible(false)} draggable={false}>
        {currentView === "main" && (  
<>      
        <div className="gap-3 flex flex-auto flex-row pt-4" style={{ minHeight: '100%' }}>

            <div className="gap-3 flex flex-auto flex-column" style={{ maxWidth: '350px' }}>

                <div className="flex flex-column p-3 bg-white border-round-2xl relative"> 

                    <div className="flex justify-content-start align-items-center gap-3">
                        <span className="ventas-button bg-indigo-100 border-round-lg">
                            <img
                                src="https://i.ibb.co/CJ4qNzy/briefcase.png"
                                alt="img"  
                            />
                        </span>
                        <p className="text-indigo-600 font-bold">Código de Proforma</p>
                    </div>

                    <InputText className="mt-3" value={value} onChange={(e) => setValue(e.target.value)} />
                    {dataErrors?.codigo && !value && <span className="text-red-500 absolute" style={{top : 'calc(100% - 1rem)'}}>Ingrese un código de proforma</span>}
                </div>


                <div className="flex flex-1 gap-3 p-3 flex-column bg-white border-round-2xl" style={{ maxHeight: '122px' }}>
                
                    <div className="flex justify-content-start align-items-center gap-3">
                        <span className="ventas-button bg-indigo-100 border-round-lg">
                            <img
                                src="https://i.ibb.co/CJ4qNzy/briefcase.png"
                                alt="img"
                            />
                        </span>
                        <p className="text-indigo-600 font-bold">Selección de producto</p>
                    </div>
                    <S3ProductType type={productType} onChangeType={setProductType} valueButton1="bien" valueButton2="servicio" valueDefault="servicio" labelButton1="Bien" labelButton2="Servicio" disabledButton="bien"/>
                </div>

                <div className="flex-1 gap-3 p-3 flex flex-column bg-white border-round-2xl " style={{ maxHeight: '122px' }}>
                    <div className="flex justify-content-start align-items-center gap-3">
                        <span className="ventas-button bg-indigo-100 border-round-lg">
                            <img
                                src="https://i.ibb.co/CJ4qNzy/briefcase.png"
                                alt="img"  
                            />
                        </span>
                        <p className="text-indigo-600 font-bold">Periodo y selección de paquete</p>
                    </div>

                    <S3ProductType type={priceType} onChangeType={setPriceType} valueButton1="precio_anual" valueButton2="precio_mensual" valueDefault="precio_mensual" labelButton1="Anual" labelButton2="Mensual" />
                    
                </div>
                    

                    
                
                <div className="flex-1 p-3 flex flex-column bg-white border-round-2xl ">
                    <S3ProductPackageSelection onSelectionPackage={setEnvio} packages={packages} checkedIndex={checkedIndex} setCheckedIndex={setCheckedIndex} origin={0}
                     /*styleListPackeges={{height: "20rem"}}*/ />
                    {//<Button label={estadoEnvio !== false ? "Actualizar" : "Asignar"} className={estadoEnvio !== false ? "bg-green-500 border-green-500" : ""} onClick={() => Enviar()} />
                    }
                    {dataErrors?.selectPaquete && <span className="text-red-500">Seleccione un paquete</span>}
                </div>
            </div>

            <div className="flex-1 bg-white border-round-lg flex flex-column gap-3 py-3">
            
            {//<Button label={"Crear"} className={"bg-white border-gray-400"} style={{width: "170px"}} onClick={() => console.log("Esto se envia: ", envio, typeof(envio), packages, packages[selectedPackageIndex], (packages[selectedPackageIndex] !== undefined ? (packages[selectedPackageIndex].paquete.nombre) : "no hay nada", selectedPackageIndex, envio[0]?.paquete?.nombre))} />
            
            }
            
            {Object.keys(envio).some(key => envio[key] !== undefined) ?
                (
                <div className="flex flex-column gap-3 ">
                    <div className="flex flex-row justify-content-start p-3" style={{ background: "#F8F9FF" }}>
                        <div className="flex h-4rem w-4rem align-items-center bg-white border-round-lg">
                            <img
                                className="aling-items-center h-4rem w-4rem "
                                src={imgSeleccionarPaquete}
                                alt="img"
                            />
                        </div>
                        <div className="flex flex-column justify-content-center">
                            <p className="text-indigo-600 px-3 font-bold text-lg">
                                {envio[0]?.paquete?.nombre}
                            </p>
                            <p className="text-gray-500 px-3">Contenido total de pruebas habilitadas.</p>
                        </div>
                    </div>
                    
                    <div className="flex-1 pl-3 pr-3">
                        <S3ProductSalesTreeTable
                            salePrice={priceType}
                            style={{ width: "100%" }}
                            paquete={transformData(envio)}
                            scrollHeightTable="300px"
                            scrollable
                        />
                    </div>
                </div>
                )
                :
                    <div className="flex flex-column w-full h-full align-items-center justify-content-center">
                        <img
                            className="h-9rem w-9rem"
                            src={imgSeleccionarPaquete}
                            alt="img"
                        />
                        <div className="flex flex-column align-items-center justify-content-center">
                            <p className="text-indigo-600 px-3 font-bold text-xl">
                                Selecciona un Paquete
                            </p>
                            <p className="text-gray-500 px-3">
                                Contenido total de pruebas habilitadas.
                            </p>
                        </div>
                    </div>
                }
                <div className="content-options justify-content-end flex gap-4 mx-3 mt-2">
                    {/*<Button label="Cancelar" className={"bg-white border-gray-400 text-gray-900"} style={{width: "170px"}} onClick={() => setVisible(false)} />
                    <Button label={estadoEnvio !== false ? "Actualizar" : "Guardar Datos"} style={{width: "170px"}}  className={estadoEnvio !== false ? "bg-green-500 border-green-500" : ""} onClick={() => Enviar()} />*/}
                    <Button label={"Cancelar"} className={"bg-white border-gray-400 color-gray-400"} style={{width: "170px"}} onClick={() => {setVisible(false); setEnvio([]);}} />
                    <Button label={estadoEnvio !== false ? "Actualizar" : "Guardar Datos"} style={{width: "170px"}}  className={estadoEnvio !== false ? "bg-green-500 border-green-500" : ""} onClick={() => {
                        Enviar();
                        setEnvio([]);
                    }}
                    />
                    
                </div>
            </div>
        </div>
            </>
                )}
            <Toast ref={toast} />
        </Dialog>
            
        </div>
    )
}
export default S3Product;