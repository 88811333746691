//REACT
import React, { useState, useEffect, useContext } from "react";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
//OTHERS
import { SalesContext } from "../../SalesContext/SalesContext";
import SS2Contact from "../../SkeletonSales/SS2Contact/SS2Contact";
import TextTrimmer from "../../../Ventas/TextTrimmer/TextTrimmer";
import S2ContactCreate from "./S2ContactCreate";
import S2ContactShow from "./S2ContactShow";
import S2ContactEdit from "./S2ContactEdit";
import S2ContactDelete from "./S2ContactDelete";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import "../../NewClientC/S3ProductC/Responvidad.css";

export default function S2Contact({
   getContact,
   postContact,
   putContact,
   deleteContact,
   validateForm,
   showToast,
}) {
   //#region VARIABLES DE INSTANCIA
   const { sales, setSales } = useContext(SalesContext);
   //#endregion   

   const navigate = useNavigate();

   //#region VARIABLES
   const [contactos, setContactos] = useState(null);
   const [contactosCopy, setContactosCopy] = useState(null);
   const [search, setSearch] = useState("");
   //#endregion

   //#region FUNCIONES DE CAPTURA DE DATOS
   const handleChange = (e) => {
      setSearch(e.target.value);
      const filteredData = contactosCopy.filter((contacto) =>
         contacto.persona.nombres
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setContactos(filteredData);
   };
   function convertDate(texto) {
      let r = null;
      if (texto) {
         const convert = texto.split("-");
         r = new Date(convert[0] + "/" + convert[1] + "/" + convert[2]);
      }
      return r;
   }
   //#endregion

   //#region FUNCIONES DE CRUD
   const ResetContacts = () => {
      setSales((prevState) => ({
         ...prevState,
         GCContacto: {
            ...prevState.GCContacto,
            contacto_id: "",
            tipo_documento_id: 2, //Sin input pero requerido
            numero_documento: "", //Sin input pero requerido
            nombres: "",
            apellido_paterno: "",
            apellido_materno: "",
            cargo: "",
            fecha_nacimiento: null,
            hobbies: "",
            celular: "",
            telefono: "",
            correo: "",
            comentarios: "",
            foto: "",
         },
      }));
   };
   const extractpartOfURL = (url) => {
      if (url) {
         console.log("esta es la url", url)
         let newUrl = url.replace("/perfil/", "/");
         return newUrl
      } else {
         return url
      }

   }
   const setValueContact = (data) => {
      console.log("data-c", data)
      let newData = data?.map(el => {
         console.log("data-i", el)
         let urlContact = extractpartOfURL(el?.persona?.foto)
         let contact = { ...el, persona: { ...el.persona, foto: urlContact } }
         return contact
      })
      return newData;
   }

   const fetchData = async () => {
      const allFetching = [getContact(sales.GCEmpresa.clinica_id, showToast)];
      try {
         const results = await Promise.allSettled(allFetching);
         console.log(
            "fetchData", setValueContact(results[0].value)
         );
         setContactos(
            results[0].status === "fulfilled" ? setValueContact(results[0].value) : []
         );
         setContactosCopy(
            results[0].status === "fulfilled" ? setValueContact(results[0].value) : []
         );
      } catch (error) {
         console.log(error);
      }
   };

   const crud = async (action, Dvisible = false) => {
      const form = {
         tipo_documento_id: sales.GCContacto.tipo_documento_id, //Sin input pero requerido
         numero_documento: sales.GCContacto.numero_documento, //Sin input pero requerido
         nombres: sales.GCContacto.nombres,
         apellido_paterno: sales.GCContacto.apellido_paterno,
         apellido_materno: sales.GCContacto.apellido_materno,
         cargo: sales.GCContacto.cargo,
         fecha_nacimiento: sales.GCContacto.fecha_nacimiento || "",
         hobbies: sales.GCContacto.hobbies,
         celular: sales.GCContacto.celular,
         telefono: sales.GCContacto.telefono,
         correo: sales.GCContacto.correo,
         foto: sales.GCContacto.foto,
         comentarios: sales.GCContacto.comentarios,
      };

      const formData = new FormData();
      let validado = false;

      if (validateForm(form)) {
         for (const [key, value] of Object.entries(form)) {
            formData.append(key, value);
            validado = true;
         }
      }

      switch (action) {
         case "crear":
            // if (validado) {
            await postContact(sales.GCEmpresa.clinica_id, form, showToast);
            await fetchData();
            return true;

         case "editar":
            console.log("entra")
            // if (validado) {
            const response = await putContact(
               sales.GCEmpresa.clinica_id,
               sales.GCContacto.contacto_id,
               form,
               showToast,
               Dvisible,

            );
            await fetchData();
            // } else {
            //    showToast("error", "Error", "Complete el formulario");
            // }

            return response;
         case "eliminar":
            console.log("co", sales.GCContacto.contacto_id)
            await deleteContact(
               sales.GCEmpresa.clinica_id,
               sales.GCContacto.contacto_id,
               showToast
            );
            await fetchData();
            return true;
         default:
            return false;
      }
   };

   useEffect(() => {
      if (sales.GCEmpresa.clinica_id) {
         fetchData();
      }
   }, []);

   return (
      <>
         {!contactos ? (
            <SS2Contact />
         ) : (
            <div className="flex flex-column gap-3 text-sm ml-6 mr-6 h-auto relative contactGeneral"
               style={{ marginBottom: "250px" }}>
               <div className="flex flex-column bg-white p-3 border-round-lg ContactoDiv" style={{ height: "auto" }}>
                  <div className="flex justify-content-start">
                     <span className="flex align-items-center justify-content-center bg-indigo-200 p-2 border-round-lg">
                        <img
                           className="w-1rem"
                           src="https://i.ibb.co/TDGB8Z2/iconcontc.png"
                           alt="img"
                           draggable={false}
                        />
                     </span>
                  </div>

                  <div className="flex justify-content-between align-items-center gap-2 flex-wrap pb-4 responsiveC">
                     <div>
                        <p className="font-bold text-base text-indigo-500">
                           Cantidad de Contacto
                        </p>
                        <p className="text-gray-500">
                           Aquí se guardan los datos que vayas creando para un
                           nuevo Lead
                        </p>
                     </div>

                     <div className="flex-1 flex justify-content-end">
                        <span
                           className="xl:w-auto lg:w-auto md:w-full sm:w-full w-full p-input-icon-left"
                           style={{ minWidth: "168px" }}
                        >
                           <i className="pi pi-search" />
                           <InputText
                              value={search}
                              onChange={(e) => handleChange(e)}
                              placeholder="Buscar Contacto"
                              className="xl:w-auto lg:w-auto md:w-full sm:w-full w-full"
                           ></InputText>
                        </span>
                     </div>
                  </div>
               </div>
               <div className="CuerpoContact" >

                  <S2ContactCreate
                     className="Card"
                     crud={crud}
                     convertDate={convertDate}
                     ResetContacts={ResetContacts}

                  />

                  {contactos.map((c, index) => {
                     const itemWidth = `${100 / 3}%`;
                     return (
                        <div
                           key={index}
                           className="Card"
                           style={{ width: "auto", height: "auto", margin: "20px" }}
                        >
                           <div className="flex flex-1 flex-column justify-content-between bg-white p-3 gap-3 border-round-lg" >
                              <div className="flex flex-column gap-2">
                                 <div className="flex justify-content-between gap-2">
                                    <div className="flex gap-1 justify-content-between">
                                       <img
                                          width="58px"
                                          height="58px"
                                          className="border-circle"
                                          src={c.persona.foto ? c.persona.foto : "https://i.ibb.co/4SHrqm4/user-male.png"} //fotoo
                                          alt=""
                                       />
                                       <div className="flex flex-column gap-2 justify-content-between">
                                          <TextTrimmer text={(c.persona.nombres || "") + " " + (c.persona.apellido_paterno || "") + " " + (c.persona.apellido_materno || "")} maxLength={10} />
                                          <TextTrimmer text={c.persona.cargo || ""} maxLength={9} />

                                       </div>
                                    </div>
                                    <div className="flex flex-column gap- justify-content-between text-right align-items-end">
                                       <div className="flex gap-2">
                                          <i className="pi pi-calendar" />
                                          <p>{c.fecha_creacion}</p>
                                       </div>
                                       <p className="white-space-nowrap">
                                          Última actualización
                                       </p>
                                    </div>
                                 </div>
                                 <p className="font-bold">Contacto {index + 1}</p>
                                 <div className="flex justify-content-between">
                                    {/* <p>{c.comentarios || ""}</p> */}
                                    <p></p>
                                    <div className="flex gap-2 align-items-center p-1  border-round-lg relative pr-3">
                                       <p>{c.comentarios ? `${c.comentarios.slice(0, 30)}${c.comentarios.length > 30 ? '...' : ''}` : ''}</p>

                                    </div>
                                 </div>

                              </div>
                              <div className="flex gap-2 border-top-1 border-gray-400 pt-2 justify-content-center TreeButtonCrud">
                                 <S2ContactShow data={c} />
                                 <S2ContactEdit
                                    data={c}
                                    crud={crud}
                                    convertDate={convertDate}
                                 />
                                 <S2ContactDelete
                                    contacto_id={c.id}
                                    nombre={c.persona.nombres || "" + " " + c.persona.apellido_paterno || "" + " " + c.persona.apellido_materno || ""}
                                    crud={crud}
                                 />
                              </div>
                           </div>
                        </div>
                     );
                  })}
               </div>
               <div style={{ bottom: '-315px' }} className="w-full h-auto flex justify-content-end absolute footer">

                  <Button
                     label="Gestión proformas"
                     className="p-button-success btnVentas"
                     onClick={() => {
                        navigate(`/ventas/gestiondeventas/empresa/${sales?.GCEmpresa?.empresa_id}/credenciales`);
                     }}
                     style={{
                        marginRight: '15px'
                     }}
                  />
                  <Button
                     label="Regresar a gestión de ventas"
                     className="p-button-success btnVentas"
                     onClick={() => {
                        navigate("/ventas/gestiondeventas");
                     }}
                     style={{
                        marginRight: '15px'
                     }}
                  />
               </div>
            </div>
         )}
      </>
   );
}