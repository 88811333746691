import React from "react";
import "../../../Style/Operaciones/operaciones.css";
import Botones from "../../../Components/Pure/MenuPrincipal/botones";
import Imagenes from "../../../Components/Pure/MenuPrincipal/Imagenes";
import Eclipses from "../../../Components/Pure/MenuPrincipal/Eclipses";
import Chica from "../../../Images/Pure/MenuPrincipal/Img/Operaciones.png";
import Servicios from "../../../Images/Pure/MenuPrincipal/img-icons/opera1.png";
import Productos from "../../../Images/Pure/MenuPrincipal/img-icons/opera2.png";

function PageOperacionesB({ accesoProp }) {
   console.log("accesos en prop", accesoProp);

   const list_images = (url) => {
      switch (url) {
         case "productos":
            return Productos;
         default:
            return Servicios;
      }
   }

   return (
      <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
         <div className="arriba">
            <span className="tit">Operaciones</span>
            <br />
            <span className="con">
               En este módulo usted podrá operar los Productos y Servicios.
            </span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
            {
               accesoProp.filter(acceso => acceso.url !== "productos").map((acceso, key) => (
                  <Botones
                     key={key}
                     Link={acceso.url}
                     img={list_images(acceso.url)}
                     span={acceso.label}
                     button={"button b"+ (key+1)}
                  />
               ))

               /* codigo sin filtro de botones
               accesoProp.map((acceso, key) => {
                  return <>
                     <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b"+ (key+1)}
                     />
                  </>
               }) */
            }   
            <Imagenes src={Chica} className="logo2" />
            <Eclipses />
         </div>
      </div>
   );
}

export default PageOperacionesB;