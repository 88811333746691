import React, { useContext, useEffect, useRef, useState } from 'react'
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AuthUser from '../../../../AuthUser';
import { iconCANCELADO, iconNO_REALIZADO, iconPROCESO, iconREALIZADO, } from "../../../../Services/importIcons";
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import MultiLevelTreeSelectWithAPIClinica from '../../ProductoServicio/Servicios/SelectTipoServicioClinica';
import ModalFinalizar from '../../../../Components/Recepcion/ModalFinalizar';
import { GestionContext } from '../services/GestionContext';
import ResultadosOverlay from '../../../../Components/Clinica/GestionAtencion/components/ResultadosOverlay';
import FinalizarRevisionComponent from '../../../../Components/Clinica/GestionAtencion/Revision/pure/FinalizarRevision';

export default function PageAuditoria() {
   const { http } = AuthUser();
   const toast = useRef(null);
   const showToast = (severity, summary, detail) => {
      toast.current.show({ severity: severity, summary: summary, detail: detail });
   };

   const [pacientes, setPacientes] = useState([]);
   const [paciente, setPaciente] = useState({});
   const [locales, setLocales] = useState([]);
   const [visibleFinalizar, setVisibleFinalizar] = useState(false);
   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [originalPacientes, setOriginalPacientes] = useState([]);
   const [selectEstablecimiento, setselectEstablecimiento] = useState(null);
   const [selectEvaluaciones, setSelecEvaluaciones] = useState(null);
   const [key, setKey] = useState(0);

   const limpiarCalendarios = () => {
      setFechaInicio(null);
      setFechaFin(null);
      setFechaValida(true); // limpia los calendarios
      getPacientes();// Actualiza la lista de pacientes
      setselectEstablecimiento(null); // Limpia la selección de establecimiento
      setSelecEvaluaciones(null); // Limpia la selección de evaluaciones
      setSelectedTipoServicio(null); // Limpia la selección de servicios

   };
   const getStatusService = (status) => {
      status = parseInt(status, 10);
      switch (status) {
         case 0:
            return iconNO_REALIZADO;
         case 1:
            return iconPROCESO;
         case 2:
            return iconREALIZADO;
         case 3:
            return iconCANCELADO;
         case 4:
            return null;
         default:
            return null;
      }
   };

   const optionTipoEvaluaciones = [
      { nombre: "Ingreso", id: 1 },
      { nombre: "Periódico", id: 2 },
      { nombre: "Retiro", id: 3 },
   ];
   const [fechaInicio, setFechaInicio] = useState(null);
   const [fechaFin, setFechaFin] = useState(null);

   const handleTipoServicioSelect = (tipoServicioId) => {
      setSelectedTipoServicio(tipoServicioId);
   };

   const [fechaValida, setFechaValida] = useState(true);

   //#region filter
   const [globalFilterValue, setGlobalFilterValue] = useState("");


   // const [filtroSeleccionado, setFiltroSeleccionado] = useState(false);

   const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
   });

   const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
   };

   //filtro BOTON

   const handleFiltrar = () => {

      // Verifica si ambas fechas están vacías

      // Verifica si solo una de las fechas está vacía y si no se han seleccionado opciones de establecimiento o evaluaciones
      if (((!fechaInicio && fechaFin) || (fechaInicio && !fechaFin)) && (!selectEstablecimiento || !selectEvaluaciones || !selectedTipoServicio)) {
         // Si solo una de las fechas está vacía y no se han seleccionado opciones de establecimiento o evaluaciones
         setFechaValida(false); // Establecer fecha válida como false para mostrar el mensaje
         return;
      }

      setFechaValida(true); // Establece fecha válida como true si no se cumple ninguna de las condiciones anteriores

      let filteredPacientes = originalPacientes;

      if (selectEstablecimiento) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente.clinica_local_id === selectEstablecimiento.id
         );
      }

      if (selectEvaluaciones) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente?.hoja_ruta?.con_perfil_tipo?.tipo_perfil_id ===
               selectEvaluaciones.id
         );
      }

      if (selectedTipoServicio) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente?.hoja_ruta?.tipo_servicio_id === selectedTipoServicio.id
         );
      }

      if (fechaInicio && fechaFin) {
         const fechaInicioSinHora =
            new Date(
               fechaInicio.getFullYear(),
               fechaInicio.getMonth(),
               fechaInicio.getDate()
            ).getTime() - 100000000;
         const fechaFinSinHora = new Date(
            fechaFin.getFullYear(),
            fechaFin.getMonth(),
            fechaFin.getDate()
         ).getTime();

         filteredPacientes = filteredPacientes.filter((paciente) => {
            const fechaPaciente = new Date(paciente.fecha).getTime();
            return (
               fechaPaciente >= fechaInicioSinHora &&
               fechaPaciente <= fechaFinSinHora
            );
         });
      }
      console.log("pacientes finales", filteredPacientes)

      setPacientes(filteredPacientes);
   };

   // Verifica si algún filtro está seleccionado
   // if (selectEstablecimiento || selectEvaluaciones) {
   //    setFiltroSeleccionado(true);
   // } else {
   //    setFiltroSeleccionado(false);
   // }

   //    function addDays(date, days) {
   //       const result = new Date(date);
   //       result.setDate(result.getDate() + 1);
   //       return result;
   //   }

   //#endregion filter

   //#region apis

   const getPacientes = () => {
      http
         .get("clinica/recepcion/pacientes/auditoria")
         .then((response) => {
            setPacientes(response.data.data);
            setOriginalPacientes(response.data.data);
            setKey(key + 1);
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   const GetLocales = () => {
      http
         .get("clinica/recursoshumanos/local/get")
         .then((response) => {
            setLocales(response.data.data); //Asignar o almacenar datos en "locales"
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   const handleFinalizar = (rowData) => {
      setPaciente(rowData);
      setVisibleFinalizar(true);
   };

   const terminarAtencion = (rowData) => {
      http
         .put(`/clinica/recepcion/pacientes/finalizar_revision/${paciente.id}`)
         .then((response) => {
            if(response.data.resp){
               getPacientes();
               showToast("success", "Atención Finalizada", "La atención ha sido marcada como finalizada correctamente.");
            }
         })
         .catch((err) => {
            console.error(err);
            showToast("error", "Error", "Hubo un error al intentar finalizar la atención.");
         });
   };


   const actionState = (rowData) => {
      let text = "";
      switch (rowData?.hoja_ruta?.estado_revision) {
         case "0":
            text = "No revisado";
            break;
         case "1":
            text = "Revisado";
            break;
         default:
            text = "No atendido";
      }
      return <Tag value={text} severity={getEstado(rowData)}></Tag>;
   };

   //#endregion


   const getEstado = (paciente) => {
      switch (paciente?.hoja_ruta?.estado_revision) {
         case "0":
            return "warning";

         case "1":
            return "success";

         default:
            return "danger";
      }
   };

   //Recorrido locales
   const OpcionesLocales = locales?.map((item) =>
      //Seleccionar datos requeridos
      ({ nombre: item.nombre, id: item.id })
   );


   const acciones = (rowData) => {
      return (
         <div className="flex flex-wrap justify-content-around">
            {/*Cambiar estado a terminado*/}
            <Button
               icon={"pi pi-check-circle"}
               className="p-button-rounded "
               onClick={() => handleFinalizar(rowData)}
               style={{ height: "37px", width: "37px" }}
               tooltip="Terminar Atención"
               tooltipOptions={{ position: "bottom" }}
            />
         </div>
      );
   };

   useEffect(() => {
      GetLocales();
      getPacientes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   //datos principales de UPDATE

   const { listRutasContext, setAtencionContext, setPacienteContext } = useContext(GestionContext);
   const [visibleDialog, setVisibleDialog] = useState(false);

   const handleRuta = (rowData) => {
      let data = rowData?.hoja_ruta?.areas_medicas.concat(rowData?.hoja_ruta?.examenes)
      setAtencionContext(data);
      setPacienteContext(rowData);
      setVisibleDialog(true);
   };

   const bodyhojaRuta = (rowData) => {
      // console.log("rowData:", rowData);
      return (
         <div
            className="end_ruta"
            style={{
               display: "flex",
               width: "100%",
               alignItems: "center",
               flexWrap: "wrap",
               justifyContent: "center",
            }}
         >
            <div className="table-icon-link-atenciones-ruta">
               {rowData?.hoja_ruta?.areas_medicas?.map((item, index) => {
                  let hojaRuta = listRutasContext?.findIndex((v) => v?.nombre === item?.nombre);
                  if (hojaRuta !== -1) return (
                     <div
                        className="table-icon-link-item-atenciones-ruta"
                        style={{ position: "relative" }}
                        key={index}
                     >
                        <img
                           src={listRutasContext[hojaRuta]?.icon}
                           width={17}
                           height={17}
                           alt="ruta_icons"
                           title={item.nombre}
                        />
                        {
                           <img
                              src={getStatusService(
                                 item.estado_ruta
                              )}
                              width={18}
                              height={18}
                              style={{
                                 position: "absolute",
                                 right: "-12px",
                                 top: "-5px",
                              }}
                              alt="ruta_icons"
                           />
                        }
                     </div>)
                  return <></>
               })}
               {rowData?.hoja_ruta?.examenes?.map((item, index) => {
                  let hojaRuta = listRutasContext?.findIndex((v) => v?.nombre === item?.nombre);
                  return (
                     <div
                        className="table-icon-link-item-atenciones-ruta"
                        style={{ position: "relative" }}
                        key={index}
                        title={item.nombre}
                     >
                        <img
                           src={listRutasContext[hojaRuta]?.icon}
                           width={17}
                           height={17}
                           alt="ruta_icons"
                        />
                        {
                           <img
                              src={getStatusService(
                                 item.estado_ruta
                              )}
                              width={18}
                              height={18}
                              style={{
                                 position: "absolute",
                                 right: "-12px",
                                 top: "-5px",
                              }}
                              alt="ruta_icons"
                           />
                        }
                     </div>)
               })}
            </div>
            <div className="rar_table_user_action_buttons mt-2">
               <Button
                  icon="fa-solid fa-briefcase-medical"
                  className="p-button-rounded"
                  onClick={() => handleRuta(rowData)}
                  title="Detalles"
               />
            </div>
         </div>
      );
   };

   return (
      <div className="flex flex-nowrap flex-column w-full">
         <div className="arriba mb-6">
            <span className="tit">Auditoría clínica</span>
            <br />
            <span className="con">
               En este módulo usted podrá auditar las atenciones
            </span>
         </div>
         <div className="flex flex-wrap flex-column gap-6">
            <Toast ref={toast} />
            {/* Contenido para ingresar una búsqueda, establecimiento, servicio, tipos de evaluación */}
            <div className="flex flex-wrap flex-row gap-2 w-12 contenedorB">
               <div className="flex flex-wrap flex-column pt-5 flex-1">
                  <div className="p-input-icon-left">
                     <InputText
                        placeholder="Ingresa busqueda"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        style={{ fontSize: "100%", width: "100%" }}
                     />
                     <i className="pi pi-search" />
                  </div>
               </div>

               <div className="flex flex-wrap flex-column gap-3 flex-1">
                  <div>
                     <p className="nombre" style={{ marginLeft: "-65px" }}>
                        Establecimiento
                     </p>
                  </div>
                  <div className="card flex justify-content-center">
                     <Dropdown
                        value={selectEstablecimiento}
                        onChange={(e) => setselectEstablecimiento(e.value)}
                        options={OpcionesLocales}
                        optionLabel="nombre"
                        placeholder="Seleccione"
                        className="w-full"
                        showClear
                     />
                  </div>
               </div>

               <div className="flex flex-wrap flex-column gap-3 flex-1">
                  <p className="nombre" style={{ marginLeft: "-65px" }}>
                     Servicio
                  </p>
                  <MultiLevelTreeSelectWithAPIClinica
                     selectedTipoServicio={selectedTipoServicio}
                     onTipoServicioSelect={handleTipoServicioSelect}
                  />
               </div>

               <div className="flex flex-wrap flex-column gap-3 flex-1">
                  <div>
                     <p
                        className="nombre"
                        style={{ marginLeft: "-65px", whiteSpace: "nowrap" }}
                     >
                        Tipos de evaluaciones
                     </p>
                  </div>
                  <div className="card flex justify-content-center">
                     <Dropdown
                        value={selectEvaluaciones}
                        onChange={(e) => setSelecEvaluaciones(e.value)}
                        options={optionTipoEvaluaciones}
                        optionLabel="nombre"
                        placeholder="Seleccione"
                        className="w-12"
                        showClear
                     />
                  </div>
               </div>
               <div className="flex flex-wrap flex-column gap-3 mt-5">
                  <Button
                     className="h-3rem"
                     onClick={handleFiltrar}
                     label="Filtrar"
                  />

               </div>
            </div>

            {/* Creacion de calendario y funcionalidad de validacion. */}

            <div className="p-field" style={{ marginTop: "-155px", marginLeft: "32px" }}>
               <div className="flex flex-wrap flex gap-2">
                  <div className="flex flex-column gap-1">
                     <p className="p-mb-1 mb-2">Fecha Inicial</p>

                     <Calendar
                        style={{ width: "314px" }}
                        value={fechaInicio}
                        onChange={(e) => { setFechaInicio(e.value); console.log("FechaInicio", fechaInicio); }}
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/aaaa"
                        showIcon
                        className={!fechaValida && !fechaInicio ? "p-invalid" : ""}
                     />

                     {!fechaValida && !fechaInicio && <small className="p-error">Fecha requerida</small>}

                  </div>
                  <div className="flex flex-column gap-1 " >
                     <p className="p-mb-1 ">Fecha Final</p>

                     <Calendar
                        style={{ width: "314px", marginTop: "9px" }}
                        value={fechaFin}
                        onChange={(e) => { setFechaFin(e.value); }}
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/aaaa"
                        showIcon
                        className={!fechaValida && !fechaFin ? "p-invalid" : ""}
                     />

                     {!fechaValida && !fechaFin && <small className="p-error">Fecha requerida</small>}

                  </div>
                  <div className="flex flex-wrap flex-column gap-3 mt-5">

                     <Button
                        className="h-3rem"
                        onClick={limpiarCalendarios}
                        label="Limpiar"
                     />

                  </div>
               </div>
            </div>

            <DataTable
               className="TablaRecepcionCSSNew"
               paginator
               value={pacientes}
               key={key}
               filters={filters}
               responsiveLayout="stack"
               rows={5}
               rowsPerPageOptions={[5, 20, 50]}
               tableStyle={{ width: "100%" }}
               style={{ width: "100%", overflowX: "scroll" }}
            >
               <Column
                  //field="id"
                  header="Ítem"
                  align="center"
                  body={(rowData, rowIndex) => {
                     return (
                        <div>{rowIndex.rowIndex + 1}</div>
                     );
                  }}
                  style={{ whiteSpace: "wrap", width: "2rem" }}
               />
               <Column
                  field="persona.tipo_documento.nombre"
                  header="Tipo de Documento"
                  align="center"
                  style={{ whiteSpace: "wrap", width: "4rem" }}
               />
               <Column
                  field="persona.numero_documento"
                  header="Nro. de Documento"
                  align="center"
                  style={{ whiteSpace: "wrap", width: "5rem" }}
               />
               <Column
                  field="nombres_completos"
                  header="Nombres Completos"
                  style={{ whiteSpace: "wrap", width: "9rem" }}
               />
               <Column
                  field="fecha"
                  header="Fecha de atención"
                  align="center"
                  style={{ whiteSpace: "wrap", width: "6rem" }}
               />

               <Column
                  body={bodyhojaRuta}
                  header="Hoja de Ruta"
                  align="center"
                  style={{ width: "15%" }}
               />

               <Column
                  body={actionState}
                  header="Estado"
                  align="center"
                  style={{ whiteSpace: "wrap", width: "4rem" }}
               />

               <Column
                  body={acciones}
                  header="Acciones"
                  align="center"
                  style={{ whiteSpace: "wrap", width: "3rem" }}
               />

            </DataTable>


            <FinalizarRevisionComponent
               visible={visibleFinalizar}
               setVisible={setVisibleFinalizar}
               terminarAtencion={terminarAtencion}
               getPacientes={getPacientes}
               estadoPaciente={paciente}
            />

            <ResultadosOverlay
               visible={visibleDialog}
               setVisible={setVisibleDialog}
            />
         </div>
      </div>
   );
};
