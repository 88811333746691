import React, { useRef, useCallback } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";

const libraries = ["places"];

const SInputMapAutocomplet = ({ onPlaceChanged, restrictions, children }) => {
    const inputRef = useRef(null);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyCsCf38reBoC31fo0enpAT6cESVohjRSwk",
        libraries: libraries,
    });

    const handlePlaceChanged = useCallback(() => {
        try {
            if (inputRef.current !== null) {
                if (inputRef.current.getPlace()) {
                    const place = inputRef.current.getPlace();
                    const { formatted_address, geometry } = place;
                    const { lat, lng } = geometry.location;
                    const coordinates = { lat: lat(), lng: lng() };

                    onPlaceChanged(formatted_address, coordinates);
                }

            } else {
                console.log("Autocomplete is not loaded yet!");
            }
        } catch (error) {
            console.log(error);
        }
    }, [onPlaceChanged]);

    return isLoaded ? (
        <Autocomplete
            onLoad={(ref) => (inputRef.current = ref)}
            onPlaceChanged={handlePlaceChanged}
            restrictions={restrictions || { country: ["PE"] }}
        >
            {children}
        </Autocomplete>
    ) : null;
};

export default SInputMapAutocomplet;