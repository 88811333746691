import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Logo from "../../../../../Images/GroupSVG.svg";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";

export default function AsignarServicioGrupal({
   showToast,
   visibleAsignar,
   setVisibleAsignar,
   item,
   examenes,
   tipoServicio,
   getLocales,
   locales,
   asignarPaquetes,
   data,
}) {

   const [tipoExamen, setTipoExamen] = useState();
   const [idEvaluacion, setIdEvaluacion] = useState();
   const [clinica_id, setClinica_id] = useState()
   const [clinicaLocal_id, setClinicaLocal_id] = useState()
   const [perfiles, setPerfiles] = useState("")
   const [idPersonal, setIdPersonal] = useState(0)

   const tipoEvaluacion = [
      {
         id: 2,
         nombre: "Periódico"
      },
      {
         id: 3,
         nombre: "Retiro"
      }
   ]

   const empleadosMap = () => {
      let aux_nombre = ""
      let aux_id = 0
      item?.map((empleado) => {
         aux_nombre = aux_nombre + empleado?.perfil?.perfil?.nombre + ", "
         return true
      })
      setPerfiles(aux_nombre)
   }

   const initialValues = {
      clinica_local_id: 0,
      fecha: "",
      perfil_tipo_id: {},
      perfil_id: 0,
      tipo_perfil: 0,
      empresa_personales: [
         {
            empresa_personal_id: 0,
         },
      ],
      clinica_id: 0,
   };

   const HideCloseAsign = () => {
      setVisibleAsignar(true);
   }

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (data) => {
         console.log("DATOS", data)
         let data_send = {
            clinica_local_id: data?.clinica_local_id?.id,
            fecha: data.fecha,
            tipo_evaluacion_id: data?.perfil_tipo_id?.id, //tipo_perfil: Rutina2, Salida3
            empresa_personales: data?.empresa_personales //datos de personal
         }
         if (data.fecha !== "") {
            const fecha = new Date(data.fecha);
            const formattedFecha = fecha.toISOString().split("T")[0];
            data_send.fecha = formattedFecha;
         }

         console.log("DATOS", data_send)
         asignarPaquetes(data_send);
         setVisibleAsignar(false);
      },
   });

   const ids = item?.map(empleado => empleado);

   const idPorEvaluacion = () => {
      console.log("FORMIIIIIIIIIIIIIIIIK", formik.values.perfil_tipo_id)
      console.log("IDS", ids)
      if (formik.values.perfil_tipo_id?.id === 2) {
         formik.setValues({
            ...formik.values,
            empresa_personales: ids?.map(id => ({ empresa_personal_id: id.id, tipo_perfil_id: id.perfil?.perfil_tipo_items[0]?.id }))
         })
      }else if(formik.values.perfil_tipo_id?.id === 3){
         formik.setValues({
            ...formik.values,
            empresa_personales: ids?.map(id => ({ empresa_personal_id: id.id, tipo_perfil_id: id.perfil?.perfil_tipo_items[1]?.id }))
         })
      }
   }

   const updateValues = () => {
      formik.setValues({
         clinica_local_id: 0,
         fecha: "",
         perfil_id: 0,
         perfil_tipo_id: 0,
         empresa_personales: ids?.map(id => ({ empresa_personal_id: id.id, tipo_perfil_id: 0 })),
         clinica_id: 0,
      });
   };

   useEffect(() => {
      idPorEvaluacion();
   }, [formik.values.perfil_tipo_id])

   useEffect(() => {
      updateValues();
      let Servicio = item?.[0]?.perfil?.perfil_tipo_items?.map(tipoPerfiles => {
         console.log("tipoo", tipoPerfiles)
         return tipoPerfiles;
      })
      setTipoExamen(Servicio)

      let Locales = item?.[0]?.locales?.map(id => {
         return id;
      })
      setClinica_id(Locales)

      let ClinicaID = item?.locales?.map(clinica_local_id => {
         return clinica_local_id;
      })
      setClinicaLocal_id(ClinicaID)

   }, [item]);

   useEffect(() => {
      empleadosMap();
   })

   const footerContent = (
      <div className="flex flex-wrap w-full gap-2">
         <Button
            type="button"
            onClick={() => setVisibleAsignar(false)}
            className="p-button-text flex flex-1 flex-column justify-content-center align-items-center w-full"
            style={{ border: "1px solid #D0D5DD" }}

         >
            <span style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Cancelar</span>
         </Button>
         <Button
            type="submit"
            autoFocus
            className="flex flex-1 flex-column justify-content-center align-items-center w-full"
         >
            <span style={{ fontWeight: "500", fontSize: "14px", color: "#FFFFFF" }}>Enviar</span>
         </Button>
      </div>
   );

   return (
      <div className="flex justify-content-center">
         <form
            id="asignar-personal-paquete-masivo"
            className="asignar-personal-paquete-masivo flex flex-column gap-3"
            noValidate
            onSubmit={formik.handleSubmit}
         />

         <Dialog
            appendTo={document.getElementById("asignar-personal-paquete-masivo")}
            visible={visibleAsignar}
            style={{ width: "50vw", maxWidth: "424px" }}
            onHide={() => setVisibleAsignar(false)}
            header={
               <>
                  <div className="flex  align-items-center">
                     <div className="flex  justify-content-center align-items-center"
                        style={{ height: "60px", width: "60px", background: "#EBEBFF", borderRadius: "10px", marginRight:'7px'}}
                     >
                        <img src={Logo} alt="" />
                     </div>
                     <div>
                        <h1 style={{ fontWeight: "700", fontSize: "18px", color: "#5555D8"}}>Asignar servicio</h1>
                     </div>
                  </div>
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#7B8794", paddingTop: "7px" }}>
                     Elige el tipo de examen que corresponde a tu empleado{" "}</p>

               </>
            }
            footer={footerContent}

         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column gap-3 mt-3">
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Perfil:</p>
                  <InputText
                     id="nombre"
                     name="nombre"
                     value={perfiles}
                     className="w-full "
                     disabled
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Tipo de examen:</p>
                  <Dropdown
                     id="perfil_tipo_id"
                     name="perfil_tipo_id"
                     value={formik.values.perfil_tipo_id}
                     onChange={(e) => { formik.setFieldValue('perfil_tipo_id', e.value); }}
                     options={tipoEvaluacion}
                     optionLabel="nombre"
                     placeholder="Selecciona un examen"
                     className="w-full "
                  />

                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Fecha de Atención:</p>
                  <Calendar
                     value={formik.values.fecha}
                     dateFormat="dd-mm-yy"
                     onChange={(e) => formik.setFieldValue("fecha", e.target.value)}
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Local:</p>
                  <Dropdown
                     id="clinica_local_id"
                     value={formik.values.clinica_local_id}
                     onChange={formik.handleChange}
                     options={clinica_id}
                     optionLabel="nombre"
                     placeholder="Selecciona un local"
                     className="w-full "
                  />
               </div>
            </div>
         </Dialog>
      </div>
   );
}