import React from "react";
import { Skeleton } from "primereact/skeleton";

const SS3Product = () => {

    return (
        <div className="flex gap-3 flex-wrap">
            <div className="xl:flex-initial lg:flex-1 md:flex-1 sm:flex-1 flex-1 flex flex-column gap-3 justify-content-between">
                <div className="bg-white flex flex-column p-3 gap-2 border-round-lg">
                    <Skeleton width="40px" height="40px" />
                    

                    <Skeleton height="20px" />
                    {/* <p className="text-indigo-500 font-bold">Seleccion de producto</p> */}

                    <div className="flex gap-3" style={{ marginTop: "19px" }}>

                        <Skeleton height="39px" />
                        

                        <Skeleton height="39px" />
                        
                    </div>
                </div>
                {" "}
                <div className="bg-white flex flex-column w-20rem p-3 gap-3 border-round-lg">
                    <Skeleton height="40px" width="40px" />
                    

                    <Skeleton height="20px" />
                    {/* <p className="text-indigo-500 font-bold white-space-nowrap">
                        Periodo y selección de paquete
                    </p> */}

                    <div className="flex flex-column gap-2" style={{ marginTop: "19px" }}>
                        <div className="flex gap-3">
                            <Skeleton height="39px" />
                            

                            <Skeleton height="39px" />
                            
                        </div>
                        <div className="flex-1 flex">

                            <Skeleton height="39px" />
                            
                        </div>
                        <div className=" bg-gray-200 p-2 border-round-lg">
                            <div className="flex flex-column gap-1 h-13rem contactList pr-3">
                                <div className="border-bottom-1 border-gray-300 p-1">
                                    <Skeleton height="300px" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-column justify-content-between bg-white border-round-lg p-3 flex-1">
                <div className="flex flex-column gap-2">
                    <Skeleton width="40px" height="40px" />
                    
                    <div className="flex flex-column gap-2 ">
                        <div className="flex gap-2">
                            <Skeleton height="20px" />
                            {/* <p className="text-indigo-500 text-lg font-bold"> Paquete </p> */}
                            <Skeleton height="20px" />
                            {/* <p className="text-indigo-400">{selectedButton}-MR 2023 </p> */}
                        </div>

                        <Skeleton height="20px" style={{ marginTop: "19px" }} />
                        {/* <p className="text-sm pb-3 border-bottom-1 border-gray-300">
                            Contenido total de pruebas habilitadas
                        </p> */}
                    </div>

                    <div>
                        <div className="flex   ml-4 mr-3 gap-6 ">
                            <Skeleton height="20px" />
                            {/* <p className="flex-1 text-indigo-500">Pruebas habilitadas</p> */}
                            <div className="flex flex-1 justify-content-center  align-items-center">
                                <Skeleton height="20px" />
                                {/* <p className=" mr-4 pr-3 pl-3 text-cyan-300 bg-cyan-100  border-round-lg">
                                    Precio referencial
                                </p> */}
                            </div>
                            <div className="flex flex-1 justify-content-center align-items-center">
                                <Skeleton height="20px" />
                                {/* <p className="mr-6 pr-3 pl-3 bg-orange-100 text-orange-300 text-center border-round-lg ">
                                    Precio de Venta
                                </p> */}
                            </div>
                        </div>

                        <div className="GCProducto ">
                            <Skeleton height="100px" width="100%" />
                        </div>
                    </div>
                </div>
                
                <div className="flex-1 flex flex-column justify-content-between">
                    <div className="flex justify-content-end gap-3 p-3">
                        <Skeleton height="39px" />
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SS3Product;