import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload'
import { Tag } from 'primereact/tag';

export default function CargarDatosDNI({ visible, setVisible, subirArchivo, isLoading }) {
   const [totalSize, setTotalSize] = useState(0);
   const [file, setFile] = useState(null);
   const onTemplateClear = () => {
      setTotalSize(0);
      setFile(null);
   };
   const headerTemplate = (options) => {
      const { className, chooseButton } = options;
      return (
         <div
            className={className}
            style={{
               backgroundColor: "transparent",
               display: "flex",
               alignItems: "center",
            }}
         >
            {chooseButton}
         </div>
      );
   };
   const itemTemplate = (file, props) => {
      return (<>
         <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <div style={{ display: "flex", alignItems: "center", width: "65%" }}>
               <i className="pi pi-file-excel" />
               {/*<img
              alt={file.name}
              role="presentation"
              src={file.objectURL}
              width={100}
      />*/}
               <span
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     textAlign: "left",
                     marginLeft: "1rem",
                  }}
               >
                  {file.name}
                  <small>{new Date().toLocaleDateString()}</small>
               </span>
            </div>
            <div style={{ width: "35%" }}>
               <Tag
                  value={props.formatSize}
                  severity="warning"
                  style={{ padding: "1rem .5rem" }}
               />
               <Button
                  type="button"
                  icon="pi pi-times"
                  style={{ width: "48px", margin: "0 1rem" }}
                  className="p-button-outlined p-button-rounded p-button-danger"
                  onClick={() => onTemplateRemove(file, props.onRemove)}
               />
            </div>
            {isLoading &&
               <div className='montserrat'>Enviando personal, espere...</div>}
         </div>
      </>);
   };
   const onTemplateRemove = (file, callback) => {
      setTotalSize(totalSize - file.size);
      setFile(null);
      callback();
   };

   const emptyTemplate = () => {
      return (<>
         <div
            style={{
               display: "flex",
               alignItems: "center",
               flexDirection: "column",
            }}
         >
            <i
               className="pi pi-file-excel"
               style={{
                  fontSize: "2em",
                  borderRadius: "50%",
                  backgroundColor: "var(--surface-b)",
                  color: "var(--surface-d)",
                  marginTop: "1rem",
                  padding: "1rem",
               }}
            ></i>
            <span
               style={{
                  fontSize: "1.0em",
                  color: "var(--text-color-secondary)",
                  margin: "1rem 2rem",
               }}
            >
               Arrastre y suelte el archivo aquí.
            </span>
         </div>
      </>);
   };
   const chooseOptions = {
      icon: "pi pi-file-excel",
      iconOnly: false,
      label: "Seleccionar un archivo",
      className: "custom-choose-btn p-button-rounded p-button-outlined",
   };
   const onChangeFile = (e) => {
      setFile(e?.files[0]);
      // setAtencion({ ...atencion, file: e?.files[0] });
      console.log(file);
   };

   const sendData = () => {
      console.log("entro");
      subirArchivo(file)
   }

   const excelFile = () => {
      let fileUrl = '../../../../../plantillas/personal_dni_1_aux.xlsx';

      const link = document.createElement('a');
      link.href = fileUrl;

      link.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      link.download = 'plantilla_personal_dni.xlsx';

      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
   }

   const footerContent = (
      <div className='flex justify-content-between'>
         <Button label="Descargar plantilla" icon="pi pi-download" className='btn-1' onClick={excelFile} />
         <div>
            <Button label="Cancelar" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => sendData()} autoFocus />
         </div>
      </div>
   );

   return (
      <>
         <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={<h1>Cargar archivo DNI</h1>}
            footer={footerContent}
         >
            <p>Recuerde que esta opción solo funciona cuando el tipo de documento es DNI, no para carné de extranjería o pasaporte.</p><br></br>
            <FileUpload
               name="excel"
               accept=".xlsx"
               maxFileSize="1000000"
               customUpload
               auto
               uploadHandler={(e) => onChangeFile(e)}
               onError={onTemplateClear}
               onClear={onTemplateClear}
               headerTemplate={headerTemplate}
               itemTemplate={itemTemplate}
               emptyTemplate={emptyTemplate}
               chooseOptions={chooseOptions}
            //mode="basic"
            />
         </Dialog>
      </>
   )
}
