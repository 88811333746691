import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useRef, useState } from "react";
import SB1Customer from "../../../Components/Sales/Services/Bregma/SB1Customer/SB1Customer";
import Search from "../../../Components/Ventas/Empresas/Search";
import Empresas from "../../../Components/Ventas/Empresas/Empresas";
import SkeletonEmpresa from "../../../Components/Ventas/Empresas/SkeletonEmpresa";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import './estilo.css';
import { useDispatch } from "react-redux";
import { startLoading,endLoading } from "../../../Redux/isLoadingSlice";

const PageSalesMagnament = () => {
    //#region VARIABLES DE INSTANCIA
    const { getClinic, deleteClinic } = SB1Customer();
    const dispatch=useDispatch()
    //#endregion

    //#region VARIABLES
    const [empresas, setEmpresas] = useState(null);
    const [empresasCopy, setEmpresasCopy] = useState(null);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(null);
    const header = (
        
        <p className="pi pi-trash text-2" style={{ color: "#5555D8", fontSize: '30px', fontWeight: "700"}}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Empresa</i></p>
    );
    const btnEdit = (e) => {
        return (
            <Link to={`empresa/${e.id}/editar`}>
                <Button
                    icon="pi pi-pencil text-green-500"
                    className="bg-green-100 border-green-100"
                />
            </Link>
        );
    };

    
    const btnDelete = (e) => (
        <Button
            onClick={() => {
                setData(e);
                setVisible(true)
            }}
            icon="pi pi-trash text-red-500"
            className="bg-red-100 border-red-100"
        />
    );
    const toast = useRef(null);
    //#endregion

    //#region FUNCIONES
    const filtrarDatos = (searchQuery) => {
        const filteredData = empresasCopy.filter((empresa) =>
            empresa.empresa.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setEmpresas(filteredData);
    }
    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity: severity, summary: summary, detail: detail });
    };
    //#endregion

    //#region API
    const eliminarClinica = async () => {
        console.log(data);
        await deleteClinic(data.id, showToast);
        await fetchData();
        setVisible(false)
    }
    const fetchData = async () => {
        const promises = [
            getClinic()
        ];

        try {
            const results = await Promise.allSettled(promises);
            const valueClinica = results[0].status === "fulfilled" ? results[0].value : [];
            setEmpresas(valueClinica);
            setEmpresasCopy(valueClinica)
        } catch (error) {
            console.error('Error en la llamada a la API:', error);
        }finally{
            dispatch(endLoading());
        }
    }
    //#endregion

    useEffect(() => {
        dispatch(startLoading());
        fetchData();
    }, []);


    const handleDeleteConfirmation = (id_eliminar) => (
        <React.Fragment>
          <div className="flex w-full bot">
            <Button
              type="button"
              label="Cancelar"
              className="delete w-full"
              onClick={() => {
                setVisible(false);
              }}
            />
            <Button
              type=""
              label="Eliminar"
              className="p-button-success w-full"
              style={{
                color: "#5555D8",
                backgroundColor: "#EBEBFF",
                borderColor: "#5555D8",
              }}  onClick={() => {
                eliminarClinica();
              }}
            /></div>
        </React.Fragment>
      );
    
    return (
        <div className="flex flex-column gap-5" style={{ padding: "0 50px" }}>
            <Toast style={{ zIndex: "100" }} ref={toast} />
            <div className="flex flex-column gap-1 pb-7">
                <p className="text-4xl text-indigo-500 font-bold">Gestión de Venta</p>
                <p className="flex-1 flex align-items-center text-indigo-300">Crea y gestiona tus Leadss.</p>
            </div>

            <div className="text-sm flex flex-column gap-2">
                <div className="flex flex-column gap-5">
                    <div className="flex gap-2 justify-content-between flex-wrap">

                        <Search extraFunction={filtrarDatos} />

                        <div className="flex-1 flex gap-2 justify-content-end">
                            <Link to={"empresanuevocliente"} className="no-underline">
                                <Button
                                    className="p-2 text-sm bg-green-600 border-green-700 h-full border-round-lg"
                                    icon="pi pi-plus text-sm"
                                    label="Crear"
                                />
                            </Link>
                            <Button
                                className="p-2 text-sm bg-white border-green-700 text-green-600 border-green-700 border-round-lg"
                                icon="pi pi-file-excel text-sm"
                                label="Reporte"
                            />
                        </div>
                    </div>
                    {
                        empresas
                            ? <Empresas data={empresas} pageSize={6} btnEdit={btnEdit} btnDelete={btnDelete} />
                            : <SkeletonEmpresa pageSize={6} />
                    }
                    <Dialog
                        visible={visible}
                        sizable={false}
                        style={{ width: "550px", height: "260px" }}
                        className="p-fluid eliminarLocal"
                        header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Empresa</i></p>}

                        footer={handleDeleteConfirmation()}

                        onHide={() => {
                            setVisible(false);
                        }}

                        >
                          { data && (
                        <div className="flex flex-column gap-3">
                            <div className="flex flex-column w-full">
                            <p style={{ fontSize: "20px" }} className=" m-0 pt-5">¿Desea eliminar a <b>{data.empresa}</b>?</p>
                            </div>
                        </div> )
                            }
                    </Dialog>
                </div>
            </div>
        </div>
    )
};

export default PageSalesMagnament;
