import React, { useEffect, useState } from "react";
import RecursosHumanosPrinc from '../../../Components/RecursosHumanos/RecursosHumanosPrinc';
import '../../../Style/Botones.css';
import Sombra from "../../../Images/Pure/MenuPrincipal/Img/sombra.png";
import Business from "../../../Images/Pure/MenuPrincipal/Img/Business.png";
import Eclipses from "../../../Components/Pure/MenuPrincipal/Eclipses";
import Imagenes from "../../../Components/Pure/MenuPrincipal/Imagenes";
import Botones from "../../../Components/Pure/MenuPrincipal/botones";
import Icon1 from "../../../Images/Pure/MenuPrincipal/img-icons/con1.png";
import Icon2 from "../../../Images/Pure/MenuPrincipal/img-icons/con2.png";
import Icon3 from "../../../Images/Pure/MenuPrincipal/img-icons/con3.png";
import Icon4 from "../../../Images/Pure/MenuPrincipal/img-icons/con4.png";
import { HookPositionDemo } from "../../../Components/ModalPaginaEnProceso/ModalDesarrollo";

const RecHumBregma = ({ accesoProp }) => {
   const list_images = (url) => {
      switch (url) {
         case "locales":
            return Icon1;
         case "roles":
            return Icon2;
         case "personal":
            return Icon3;
         case "reclutamiento":
            return Icon4;
         default:
            return Icon4;
      }
   };

   const [isResponsive, setIsResponsive] = useState(false); // State to track responsiveness

   const handleResize = () => {
      if (window.innerWidth < 768) {
         setIsResponsive(true);
      } else {
         setIsResponsive(false);
      }
   };

   // Add event listener for window resize
   useEffect(() => {
      window.addEventListener("resize", handleResize);

      // Cleanup event listener on component unmount
      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);

   return (
      <div style={{ height: "100%" }} className='flex flex-nowrap flex-column w-full'>
         <div className="arriba">
            <span className="tit">Recursos Humanos</span>
            <br />
            <span className="con">En este módulo usted podrá realizar la gestión de recursos humanos de Bregma</span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "70%" }}>
            {accesoProp.map((acceso, key) => {
               return (
                  <>
                     <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b" + (key + 1)}
                     />
                  </>
               );
            })}
            <Eclipses />
            {!isResponsive && (
               <Imagenes
                  src={Business}
                  className="logo2"
               />
            )}
            <Imagenes src={Sombra} className="som" />
         </div>
      </div>
   );
}

export default RecHumBregma; 