import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Logo from "../../../../../Images/GroupSVG.svg";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";

export default function AsignarServicioDialog({
   showToast,
   visibleAsignar,
   setVisibleAsignar,
   item,
   tipoServicio,
   locales,
   getLocales,
   asignarPaquetes,
   data,
}) {

   const [tipoExamen, setTipoExamen] = useState()
   const [clinica_id, setClinica_id] = useState()
   const [clinicaLocal_id, setClinicaLocal_id] = useState()

   const initialValues = {
      clinica_local_id: 0,
      fecha: "",
      perfil_tipo_id: {},
      perfil_id: 0,
      empresa_personales: [
         {
            empresa_personal_id: 0,
         },
      ],

      clinica_id: 0,


   };

   const HideCloseAsign = () => {
      setVisibleAsignar(true);
   }

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (data) => {
         console.log("first data2", data)
         let data_send = {
            clinica_local_id: data.clinica_local_id?.id,
            fecha: data.fecha,
            perfil_tipo_id: data?.perfil_tipo_id?.id,
            empresa_personales: data.empresa_personales
         }
         if (data.fecha !== "") {
            const fecha = new Date(data.fecha);
            const formattedFecha = fecha.toISOString().split("T")[0];
            data_send.fecha = formattedFecha;
         }
         console.log("data to send", data_send)
         asignarPaquetes(data_send);
         setVisibleAsignar(false);
      },
   });

   const updateValues = () => {
      formik.setValues({
         clinica_local_id: 0,
         fecha: "",
         perfil_id: 0,
         perfil_tipo_id: 0,
         empresa_personales: [
            {
               empresa_personal_id: item?.id,
            },
         ],
         clinica_id: 0,

      });
   };

   console.log("ITEM", item)

   useEffect(() => {
      updateValues();
      let Servicio = item?.perfil?.perfil_tipo_items?.map(tipoPerfiles => {
         console.log("tipoo", tipoPerfiles)
         return tipoPerfiles;
      })
      setTipoExamen(Servicio)
      console.log("Servicio", Servicio)

      let Locales = item?.locales?.map(id => {
         return id;
      })
      setClinica_id(Locales)
      console.log("Locales", Locales)

      let ClinicaID = item?.locales?.map(clinica_local_id => {
         return clinica_local_id;
      })
      setClinicaLocal_id(ClinicaID)
      console.log("ClinicaID", ClinicaID)

   }, [item]);

   const footerContent = (
      <div className="flex flex-wrap w-full gap-2">
         <Button
            type="button"
            onClick={() => setVisibleAsignar(false)}
            className="p-button-text flex flex-1 flex-column justify-content-center align-items-center w-full"
            style={{ border: "1px solid #D0D5DD" }}

         >
            <span style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Cancelar</span>
         </Button>
         <Button
            type="submit"
            autoFocus
            className="flex flex-1 flex-column justify-content-center align-items-center w-full"
         >
            <span style={{ fontWeight: "500", fontSize: "14px", color: "#FFFFFF" }}>Enviar</span>
         </Button>
      </div>
   );

   return (
      <div className="flex justify-content-center">
         <form
            id="asignar-personal-paquete"
            className="asignar-personal-paquete flex flex-column gap-3"
            noValidate
            onSubmit={formik.handleSubmit}
         />

         <Dialog
            appendTo={document.getElementById("asignar-personal-paquete")}
            visible={visibleAsignar}
            style={{ width: "50vw", maxWidth: "424px" }}
            onHide={() => setVisibleAsignar(false)}
            header={
               <>
                  <div className="flex align-items-center">
                     <div className="flex justify-content-center align-items-center"
                        style={{ height: "60px", width: "60px", background: "#EBEBFF", borderRadius: "10px", marginRight: '7px' }}
                     >
                        <img src={Logo} alt="" />
                     </div>
                     <div>
                        <h1 style={{ fontWeight: "700", fontSize: "18px", color: "#5555D8" }}>Asignar servicio</h1>
                     </div>
                  </div>

                  <div className="">
                     <p style={{ fontWeight: "500", fontSize: "14px", color: "#7B8794", paddingTop: "7px" }}>
                        Elige el tipo de examen que corresponde a tu empleado{" "}</p>
                  </div>

               </>
            }
            footer={footerContent}

         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column gap-3 mt-3">
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Perfil:</p>
                  <InputText
                     id="perfil_id"
                     name="perfil_id"
                     value={item?.perfil?.perfil?.nombre}
                     className="w-full "
                     disabled
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Tipo de examen:</p>
                  <Dropdown
                     id="perfil_tipo_id"
                     name="perfil_tipo_id"
                     value={formik.values.perfil_tipo_id}
                     onChange={(e) => { console.log(e.value); formik.setFieldValue('perfil_tipo_id', e.value) }}
                     options={tipoExamen}
                     optionLabel="nombre"
                     placeholder="Selecciona una examen"
                     className="w-full "
                  />

                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Fecha de Atención:</p>
                  <Calendar
                     value={formik.values.fecha}
                     dateFormat="dd-mm-yy"
                     onChange={(e) => formik.setFieldValue("fecha", e.target.value)}
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Local:</p>
                  <Dropdown
                     id="clinica_local_id"
                     value={formik.values.clinica_local_id}
                     onChange={formik.handleChange}
                     options={clinica_id}
                     optionLabel="nombre"
                     placeholder="Selecciona un local"
                     className="w-full "
                  />
               </div>
            </div>
         </Dialog>
      </div>
   );
}