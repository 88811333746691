import React, { useState, useContext } from "react";
import { Dialog } from "primereact/dialog";
import FileDropzone from "../../../Ventas/FileDropZone/FileDropZone";
import { SalesContext } from "../../SalesContext/SalesContext";
import { Button } from "primereact/button";
import AuthUser from "../../../../AuthUser";
import { InputText } from "primereact/inputtext";
import "./Credenciales.css";

const ModalCarga = ({ showToast }) => {
   const { sales, setSales } = useContext(SalesContext);
   const [archivosSeleccionados, setArchivosSeleccionados] = useState([]);
   const { http } = AuthUser();

   const [datasend, setDatasend] = useState({
      documento_proforma: null,
      valores_aptitud: null,
      documento_adicional: null,
   });

   const handleFile = (file, fileType) => {
      console.log(`${fileType} file selected:`, file);
      setArchivosSeleccionados([...archivosSeleccionados, file]);
      updateSalesState(fileType, file);
   };

   const updateSalesState = (fileType, file) => {
      const updatedSales = {
         ...datasend,
         [fileType]: file,
      };
      setDatasend(updatedSales);
      console.log("Datasend actualizado:", updatedSales);
   };

   console.log(datasend);

   const cargarArchivos = async (event) => {
      event.preventDefault();

      const filesInputProforma = document.getElementById("documento_proforma");
      const filesInputAptitud = document.getElementById("valores_aptitud");
      const filesInputAdicional = document.getElementById(
         "documento_adicional"
      );

      if (
         !filesInputProforma.files.length &&
         !filesInputAptitud.files.length &&
         !filesInputAdicional.files.length
      ) {
         showToast(
            "error",
            "Error",
            "Por favor, selecciona archivos para cargar"
         );
         return;
      }

      try {
         const formData = new FormData(event.target);

         formData.append("contrato_id", sales.GCEmpresa.contrato_id);

         const response = await http.post(
            `bregma/ventas/uploadFiles/${sales.GCEmpresa.contrato_id}`,
            formData
         );

         if (response.data.resp) {
            showToast("success", "Carga Exitosa", response.data.resp);
         } else if (response.data.error) {
            showToast("error", "Error en la Carga", "Error al cargar archivos");
         }
      } catch (error) {
         console.error("Error al cargar archivos:", error);
         showToast("error", "Error", "Ocurrió un error al cargar archivos");
      }
   };

   return (
      <div className="h-full">
         <div className="flex flex-column w-full">
            <div className="flex-1 flex flex-wrap justify-content-center gap-3">
               <div
                  className="flex-1 bg-white border-round-lg flex flex-column gap-2"
                  style={{ minWidth: "360px" }}
               >
                  <div className="flex gap-3">
                     <img
                        width="40px"
                        height="40px"
                        className="bg-indigo-100 border-round-lg p-2"
                        src="https://i.ibb.co/gjvBxZS/Icon.png"
                        alt=""
                     />

                     <div className="flex justify-content-between align-items-center mb-3">
                        <p
                           className="font-bold text-base"
                           style={{ color: "#565656 !important" }}
                        >
                           Documentos adicionales
                           <br />
                           <p
                              style={{
                                 fontFamily: "Montserrat",
                                 color: "#344054",
                                 fontWeight: "400",
                              }}
                           >
                              Adjunta los documentos adicionales.
                           </p>
                        </p>
                     </div>
                  </div>

                  {/* <p>Proforma</p>
               <FileDropzone
                  onFileDrop={handleProformaFile}
                  label="Arrastre y coloque su archivo aquí"
                  className="gap-2 p-4"
                  style={{ background: "#F6F6FF" }}
               />

               <p>Valores de Aptitud</p>
               <FileDropzone
                  onFileDrop={handleActitudFile}
                  label="Arrastre y coloque su archivo aquí"
                  className="gap-2 p-4"
                  style={{ background: "#F6F6FF" }}
               />

               <p>Documentos adicionales</p>
               <FileDropzone
                  onFileDrop={handleAdicionalFile}
                  label="Arrastre y coloque su archivo aquí"
                  className="gap-2"
               /> */}
                  <form encType="multipart/form-data" onSubmit={cargarArchivos}>
                     <div className="flex flex-column gap-4 ">
                        <div className="file-input-container flex flex-column gap-3">
                           <p className="Colortext">Proforma</p>
                           <label
                              htmlFor="documento_proforma"
                              className="file-label"
                           >
                              <span className="file-text" id="proforma-label">
                                 Arrastra y coloca tu archivo aquí{" "}
                                 <i className="pi pi-upload"></i>
                              </span>
                              <input
                                 type="file"
                                 id="documento_proforma"
                                 name="documento_proforma"
                                 className="file-input"
                                 onChange={(e) => {
                                    const fileName =
                                       e.target.files[0]?.name || "";
                                    const proformaLabel =
                                       document.getElementById(
                                          "proforma-label"
                                       );

                                    if (proformaLabel && fileName) {
                                       proformaLabel.textContent = fileName;
                                    }
                                 }}
                              />
                           </label>
                        </div>

                        <div className="file-input-container flex flex-column gap-3">
                           <p className="Colortext">Valores de Aptitud</p>
                           <label
                              htmlFor="valores_aptitud"
                              className="file-label"
                           >
                              <span className="file-text" id="aptitud-label">
                                 Arrastra y coloca tu archivo aquí{" "}
                                 <i className="pi pi-upload"></i>
                              </span>
                              <input
                                 type="file"
                                 id="valores_aptitud"
                                 name="valores_aptitud"
                                 className="file-input"
                                 onChange={(e) => {
                                    const fileName =
                                       e.target.files[0]?.name || "";
                                    const aptitudLabel =
                                       document.getElementById("aptitud-label");

                                    if (aptitudLabel && fileName) {
                                       aptitudLabel.textContent = fileName;
                                    }
                                 }}
                              />
                           </label>
                        </div>

                        <div className="file-input-container flex flex-column gap-3">
                           <p className="Colortext">Otros documentos</p>
                           <label
                              htmlFor="documento_adicional"
                              className="file-label"
                           >
                              <span className="file-text" id="adicional-label">
                                 Arrastra y coloca tu archivo aquí{" "}
                                 <i className="pi pi-upload"></i>
                              </span>
                              <input
                                 type="file"
                                 id="documento_adicional"
                                 name="documento_adicional"
                                 className="file-input"
                                 onChange={(e) => {
                                    const fileName =
                                       e.target.files[0]?.name || "";
                                    const adicionalLabel =
                                       document.getElementById(
                                          "adicional-label"
                                       );

                                    if (adicionalLabel && fileName) {
                                       adicionalLabel.textContent = fileName;
                                    }
                                 }}
                              />
                           </label>
                        </div>

                        <Button
                           type="submit"
                           className="mt-4 p-button-success centradooo gap-3"
                        >
                           Cargar Archivos <i className="pi pi-upload"></i>
                        </Button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   );
};
export default ModalCarga;
