import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import icon from "../../../../../Images/Icon.svg";
import "../../../../../Style/CSS_tablaa.css";
import { InputText } from "primereact/inputtext";
import Nota from "../../../../../Images/IconoNota.svg";
import email from "../../../../../Images/Mail.svg";
import whatsapp from "../../../../../Images/Vector.svg";

export default function DialogDetallesPersonal() {
  const [visible, setVisible] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedButton2, setSelectedButton2] = useState(null);
  const [selectedButton3, setSelectedButton3] = useState(null);

  const handleButtonClick = (buttonNumber) => {
    setSelectedButton(buttonNumber);
  };

  const handleButtonClick2 = (buttonNumber) => {
    setSelectedButton2(buttonNumber);
  };
  const handleButtonClick3 = (buttonNumber) => {
    setSelectedButton3(buttonNumber);
  };

  return (
    <div className="flex justify-content-center">
      <Button
        className="flex flex-row gap-2 align-items-center p-button-text"
        onClick={() => setVisible(true)}
        style={{ height: "32px", fontWeight: "450", fontSize: "12px", color: "#00a15c", padding: 0 }}
      >
        <i className="pi pi-info-circle" />
        <span>Ver detalles</span>
      </Button>
      <Dialog
        visible={visible}
        style={{
          width: "90vw",
          maxWidth: "731px",
          minHeight: "90%",
          borderRadius: "25px",
        }}
        header={
          <>
            <div className="flex flex-row">
              <div
                className="flex justify-content-center align-items-center"
                style={{
                  background: "#EBEBFF",
                  width: "60px",
                  height: "60px",
                  borderRadius: "10px",
                }}
              >
                <img src={icon} alt="" />
              </div>
            </div>
            <div className="flex flex-column w-full gap-1">
              <span
                style={{ fontWeight: "700", fontSize: "18px", color: "#5555D8" }}
              >
                Detalles
              </span>
              <span
                style={{ fontWeight: "500", fontSize: "14px", color: "#7B8794" }}
              >
                Al personal se le asignará los siguientes servicios
              </span>
            </div>
          </>
        }
        onHide={() => setVisible(false)}
      >
        <div className="flex flex-column w-full gap-3">

          <div className="tab-container">
            <div className="tab-header flex flex-wrap justify-content-between gap-3">
              <button
                className={`tab-button ${activeTab === 0 ? "active" : ""
                  } flex-1`}
                onClick={() => changeTab(0)}
              >
                Capacitaciones
              </button>
              <button
                className={`tab-button ${activeTab === 1 ? "active" : ""
                  } flex-1`}
                onClick={() => changeTab(1)}
              >
                EMO’s
              </button>
              <button
                className={`tab-button ${activeTab === 2 ? "active" : ""
                  } flex-1`}
                onClick={() => changeTab(2)}
              >
                Información y Credenciales
              </button>
            </div>
            <div
              style={{
                borderBottom: "0.5px solid rgba(69, 69, 188, 0.6)",
                marginTop: "-10px",
              }}
            />
            <div className="tab-content">
              <div className={`tab-panel ${activeTab === 0 ? "active" : ""}`}>
                <div className="flex flex-column gap-3">
                  <span>
                    Recuerde que según la ley______________________ se indica
                    que todos sus trabajadores tienen que recibir 4
                    capacitaciones como mínimo.
                  </span>
                  <div className="flex flex-wrap gap-3">
                    <div className="ConetenedorCapacitaciones flex flex-column flex-1 gap-3">
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Capacitación N°1</span>
                        <InputText style={{ height: "40px" }} />
                      </div>
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Fecha de asignación del servicio</span>
                        <InputText style={{ height: "40px" }} />
                      </div>
                    </div>
                    <div className="ConetenedorCapacitaciones flex flex-column flex-1 gap-3">
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Estado</span>
                        <div className="flex flex-row">
                          <div className="button-container flex flex-row gap-3">
                            <button
                              className={`buttonN ${selectedButton === 1 ? "selected" : ""
                                }`}
                              onClick={() => handleButtonClick(1)}
                            >
                              Culminado
                            </button>
                            <button
                              className={`buttonN ${selectedButton === 2 ? "selected" : ""
                                }`}
                              onClick={() => handleButtonClick(2)}
                            >
                              No culminado
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Nota del certificado</span>
                        <div className="flex flex-row gap-2 align-items-center">
                          <InputText style={{ height: "40px" }} />
                          <Button
                            className="p-button-text flex justify-content-center"
                            style={{
                              background: "#F6F2FC",
                              width: "42px",
                              height: "42px",
                              padding: "0px",
                            }}
                          >
                            <img src={Nota} alt="" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-3">
                    <div className="ConetenedorCapacitaciones flex flex-column flex-1 gap-3">
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Capacitación N°2</span>
                        <InputText style={{ height: "40px" }} />
                      </div>
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Fecha de asignación del servicio</span>
                        <InputText style={{ height: "40px" }} />
                      </div>
                    </div>
                    <div className="ConetenedorCapacitaciones flex flex-column flex-1 gap-3">
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Estado</span>
                        <div className="flex flex-row">
                          <div className="button-container flex flex-row gap-3">
                            <button
                              className={`buttonN ${selectedButton2 === 1 ? "selected" : ""
                                }`}
                              onClick={() => handleButtonClick2(1)}
                            >
                              Culminado
                            </button>
                            <button
                              className={`buttonN ${selectedButton2 === 2 ? "selected" : ""
                                }`}
                              onClick={() => handleButtonClick2(2)}
                            >
                              No culminado
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-column gap-1 flex-1">
                        <span>Nota del certificado</span>
                        <div className="flex flex-row gap-2 align-items-center">
                          <InputText style={{ height: "40px" }} />
                          <Button
                            className="p-button-text flex justify-content-center"
                            style={{
                              background: "#F6F2FC",
                              width: "42px",
                              height: "42px",
                              padding: "0px",
                            }}
                          >
                            <img src={Nota} alt="" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`tab-panel ${activeTab === 1 ? "active" : ""}`}>
                <div className="flex flex-column gap-3">
                  <div className="flex flex-column gap-2">
                    <h1>Examen Médico Ocupacional</h1>
                    <span>
                      Recuerde que según la ley___________________________ se
                      indica que todos sus trabajadores tienen que realizar 1
                      examen médico ocupacional.
                    </span>
                  </div>
                  <div
                    className="flex flex-column gap-2"
                    style={{ marginTop: "30px" }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#344054",
                      }}
                    >
                      Fecha de asignación del servicio
                    </span>
                    <InputText style={{ width: "50%", height: "40px" }} />
                  </div>
                  <div className="flex flex-column gap-2">
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#344054",
                      }}
                    >
                      Detalles del exámen
                    </span>
                    <Button
                      className="p-button-text flex justify-content-star gap-2"
                      style={{
                        background: "#F6F2FC",
                        width: "50%",
                        height: "42px",
                        padding: "5px",
                      }}
                    >
                      <img src={Nota} alt="" />
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#7A7AEE",
                        }}
                      >
                        Descargar PDF
                      </p>
                    </Button>
                  </div>
                </div>
              </div>
              <div className={`tab-panel ${activeTab === 2 ? "active" : ""}`}>
                <div className="flex flex-wrap gap-3">
                  <div className="ContenedorInformeyCredenciales flex flex-column flex-1 gap-3">
                    <span>Información</span>
                    <div className="ContenedorForm flex flex-column gap-2">
                      <label htmlFor="email">Correo</label>
                      <InputText style={{ height: "40px" }} />
                    </div>
                    <div className="ContenedorForm flex flex-column gap-2">
                      <label htmlFor="usuario">Usuario</label>
                      <div className="button-container flex flex-row gap-3">
                        <button
                          className={`buttonN ${selectedButton3 === 1 ? "selected" : ""
                            }`}
                          onClick={() => handleButtonClick3(1)}
                        >
                          Si es usuario
                        </button>
                        <button
                          className={`buttonN ${selectedButton3 === 2 ? "selected" : ""
                            }`}
                          onClick={() => handleButtonClick3(2)}
                        >
                          No es usuario
                        </button>
                      </div>
                    </div>
                    <div className="ContenedorForm flex flex-column gap-2">
                      <label htmlFor="phone">Celular</label>
                      <InputText style={{ height: "40px" }} />
                    </div>
                    <div className="ContenedorForm flex flex-column gap-2">
                      <label htmlFor="rol">Rol</label>
                      <InputText style={{ height: "40px" }} />
                    </div>
                  </div>
                  {/* <div className="ContenedorInformeyCredenciales flex flex-column flex-1 gap-3">
                    <span>Envio de Credenciales</span>
                    <div>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#7B8794",
                        }}
                      >
                        Enviar las credenciales por:
                      </p>
                    </div>
                    <div className="flex flex-column gap-3">
                      <Button className="p-button-outlined flex justify-content-center gap-2">
                        <img src={email} alt="" />
                        <p style={{ fontWeight: "400", fontSize: "14px" }}>
                          Correo
                        </p>
                      </Button>
                      <Button className="p-button-outlined flex justify-content-center gap-2">
                        <img src={whatsapp} alt="" />
                        <p style={{ fontWeight: "400", fontSize: "14px" }}>
                          Whatsapp
                        </p>
                      </Button>
                    </div>
                    <div
                      className="flex flex-column gap-3"
                      style={{ padding: "0px 20%" }}
                    >
                      <Button
                        onClick={() => setVisible(false)}
                        className="p-button-text flex w-full justify-content-center"
                        style={{ border: "1px solid #D0D5DD" }}
                      >
                        <p
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "#393838",
                          }}
                        >
                          Cancelar
                        </p>
                      </Button>
                      <Button
                        onClick={() => setVisible(false)}
                        autoFocus
                        className="flex w-full justify-content-center"
                      >
                        <p
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "#fff",
                          }}
                        >
                          Enviar
                        </p>
                      </Button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
