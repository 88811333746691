import React, { useEffect, useState } from "react";
import "../../../../Style/EstiloTabla.css"
import BotonIngreso from "./Buttons/DetalleEntrada";
import BotonPeriodico from "./Buttons/DetalleRutina";
import BotonRetiro from "./Buttons/DetalleSalida";
import { Button } from "primereact/button";

export default function TablaCoorporativa({ dataPerfiles, datosBusqueda }) {

    const [visibleDetalle, setVisibleDetalle] = useState(false)

    return (
        <div style={{ margin: '20px 10px 0px 10px' }}>
            <table className="tabla">
                {/* TITULOS DE COLUMNAS */}
                <tr>
                    <th className="encabezado" style={{ fontSize: '16px', border: '0px solid white' }}>Item</th>
                    <th className="encabezado" style={{ fontSize: '16px' }}>Perfiles de trabajadores</th>
                    <th className="encabezado" style={{ fontSize: '16px' }}>Detalles de ingreso</th>
                    <th className="encabezado" style={{ fontSize: '16px' }}>Detalles de periódico</th>
                    <th className="encabezado" style={{ fontSize: '16px' }}>Detalles de retiro</th>
                </tr>
                {/* CONTENIDO */}
                {
                    dataPerfiles.map((perfil, index) => {
                        return (
                            <tr key={index}>
                                <td className="dato" style={{ border: '0px solid white' }}>{index + 1}</td>
                                <td className="dato">{perfil.perfil.nombre}</td>
                                <td className="dato">
                                    <div className="flex">
                                        <BotonIngreso dataIngreso={perfil.perfil_tipo_items[0]} nombrePaquete={perfil.perfil.nombre} />
                                    </div>
                                </td>
                                <td className="dato">
                                    <div className="flex">
                                        <BotonPeriodico dataPeriodico={perfil.perfil_tipo_items[1]} nombrePaquete={perfil.perfil.nombre} />
                                    </div>
                                </td>
                                <td className="dato">
                                    <div className="flex">
                                        <BotonRetiro dataRetiro={perfil.perfil_tipo_items[2]} nombrePaquete={perfil.perfil.nombre} />
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </table>
        </div>
    )
}