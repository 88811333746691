import React, { useState, useEffect, useRef } from "react";
import logo from "../../Images/Logo-icono.svg";
import logo2 from "../../Images/IMGLogin/LogoBregmaLogin.png";
import { Galleria } from "primereact/galleria";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import imagen1 from "../../Images/PrimeraImgLogin.png";
import imagen2 from "../../Images/SegundaImgLogin.png";
import "../../Style/loginInput.css";
import { Toast } from 'primereact/toast';

function LoginInput(props) {
  const [imagenes] = useState([
    {
      itemImageSrc: imagen1,
      thumbnailImageSrc: "../../src/Images/PrimeraImgLogin.png",
      alt: "Description for Image 1",
      title: "Title 1",
      style: {
        width: "100%",
        height: "40%",
      },
    },
    {
      itemImageSrc: imagen2,
      thumbnailImageSrc: "../../src/Images/SegundaImgLogin.png",
      alt: "Description for Image 2",
      title: "Title 2",
      style: {
        width: "100%",
        height: "40%",
      },
    },
  ]);
  
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];
  const [isLoginDisabled, setIsLoginDisabled] = useState(true);


  const validateInputs = () => {
    setIsLoginDisabled(!props.valueUsuario || !props.valuePassword);
  };

  const handlePasswordKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      props.OnClickIngresar();
    }
  };

  useEffect(() => {
    validateInputs();

    document.addEventListener("keydown", handlePasswordKeyDown);
    return () => {
      document.removeEventListener("keydown", handlePasswordKeyDown);
    };
  }, [props.valueUsuario, props.valuePassword]);

  const itemTemplate = (item) => {
    //console.log(item);
    return (
      <img
        src={item.itemImageSrc}
        onError={(e) =>
        (e.target.src =
          "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={item.alt}
        style={item.style}
      />
    );
  };
  

  const footer = () => {
    return (
      <>
        <div className="footer-bienvenidos">
          <h1 className="footer-bienvenidos-h1" style={{ color: "white" }}>
            Bienvenidos a{" "}
            <span className="footer-bienvenidos-span">Bregma</span>
          </h1>
          <p className="footer-bienvenidos-p">
            Todos tus problemas de administración resueltos
          </p>
          <p className="footer-bienvenidos-p">con una única plataforma</p>
        </div>
      </>
    );
  };

  
  return (
    <React.Fragment>
      <div className="container-login w-full">
        <div className="container-info">
          <div>
            <img src={logo} alt="logo" className="IMGLOGOMIN" />
          </div>
          <div className="galleria-imagenes">
            <Galleria value={imagenes} responsiveOptions={responsiveOptions} numVisible={imagenes.size} style={{ maxWidth: "808px" }} showThumbnails={false} showIndicators item={itemTemplate} footer={footer()} autoPlay circular />
          </div>
        </div>
        <div className="container-form">
          <div className=" flex flex-nowrap flex-column login-card card justify-content-center align-items-center">
            <div className="login-registro-img">
              <img src={logo2} width="152px" alt="logo" />
            </div>
            <div className="IniciarSesion-Login">
              <h2>Iniciar Sesión</h2>
            </div>
            <div className="BienvenidaLoginAbajoSesion text-center">
              <h6>Bienvenido de nuevo, inicia sesión para comenzar.</h6>
            </div>
            <div className="contenedorinp">
              <div className="field">
                <label htmlFor="Usuario" className="Usuariooo">Usuario</label>
                <InputText 
                id="username1" 
                aria-describedby="username1-help" 
                placeholder="Nombre de usuario" 
                className="InputTextUsuarioLogin" 
                value={props.valueUsuario}
                onChange={props.onChangeValueUsuario}
                //onKeyDown={handlePasswordKeyDown}
                />
                
              </div>
              <div className="field password">
                <label htmlFor="Password" className="Contraaa">Contraseña</label>
                <Password 
                toggleMask 
                className="InputTExtContraLogin" 
                placeholder="Contraseña" 
                feedback={false} 
                value={props.valuePassword} 
                onChange={props.onChangeValuePassword}
                //onKeyDown={handlePasswordKeyDown}
                id="password"/>
              </div>
              <div className="login-button">
                <Button 
                label="Ingresar" 
                className="p-button-rounded" 
                onClick={props.OnClickIngresar}
                id="login-button"
                disabled={isLoginDisabled}/>
              </div>{/* Mostrar mensajes de error aquí */}
            </div>
            <div>
              <a className="OlvidoSuContraaa">
                ¿Has olvidado tu contraseña?
              </a>
            </div>
            <div className="copyright text-center ">
              <p className="copyright-p">
                &copy; Copyright Getbyte. All Rights Reserveds
              </p>
            </div>
          </div>
        </div>
      </div>
      
      
    </React.Fragment>
    
    
  );
}

export default LoginInput;
