   import React, { useState } from "react";
   import { TabView, TabPanel } from "primereact/tabview";
   import { Button } from "primereact/button";
   import ComponentpaqueteClinica from "../ServiciosClinica/ComponentClinica";
   import { Link, useNavigate } from "react-router-dom";
   import ModalEliminar from "../../ClinicaRol/ProductoServicio/Servicios/Dialogs/ModalEliminar";

   export default function ComponentServiciosClinica({eliminar, paquetes, Habilitar}) {

      const [mostrarModalEliminar, setMostrarModalEliminar] = useState(false);
      const [deletePackage, setdeletePackage] = useState(null);
      const navigate = useNavigate();


      const tab1HeaderTemplate = (options) => {
         return (
            <Button
               icon="pi pi pi-list"
               onClick={options.onClick}
               className="px-2 gap-3 p-button-text flex-wrap"
               style={{
                  margin: "10px",
                  fontSize: "27px",
                  width: "38px",
                  height: "38px",
                  background: "white",
                  borderRadius: "8px",
               }}
            ></Button>
         );
      };

      const tab2HeaderTemplate = (options) => {
         return (
            <Button
               icon="pi pi pi-th-large"
               onClick={options.onClick}
               className="px-2 gap-3 p-button-text flex-wrap"
               style={{
                  width: "34px",
                  fontSize: "27px",
                  height: "38px",
                  background: "white",
                  borderRadius: "8px",
               }}
            ></Button>
         );
      };


      return (
         <div style={{ width: "100%" }}>
            <div>
               <div className="">
                  <Link
                     className="button__add_new"
                     to="/productosyservicios/servicios/agregarserviciocoorporativo"
                     >
                     <i className="pi pi-plus"></i>
                     <p>Crear Nuevo</p>
                  </Link>
                  <TabView className="Conedor_de_Servicios_vistas">

                     <TabPanel
                        headerTemplate={tab1HeaderTemplate}
                        headerClassName="flex align-items-center"
                     >
                     <table className="Tabla_Servicios_Breegma bg-white align-items-center ">
                        <thead>
                           <tr>
                              <th className="px-4 py-2">Item</th>
                              <th className="px-4 py-2">Paquetes</th>
                              <th className="px-4 py-2">Perfiles</th>
                              <th className="px-4 py-2">Precio Mensual</th>
                              {/* <th className="px-4 py-2">Precio Anual</th> */}
                              {Habilitar && <th className="px-4 py-2">N° de Clientes</th>}
                              <th className="px-4 py-2">Fecha de creación</th>
                              <th className="px-4 py-2">Acciones</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              paquetes?.map((servicio, key) => {
                                 return (
                                    <>
                                    <tr key={key}>
                                       <td data-label="Item" style={{ borderLeft: "0px" }}>
                                          {key + 1}
                                       </td>
                                       <td data-label="Paquetes" className="flex text-left pl-4" >
                                          <label className="white-space-nowrap overflow-hidden text-overflow-ellipsis align-items-center" style={{ width: '270px' }}>
                                             {servicio.paquete.nombre}
                                          </label>
                                       </td>
                                       <td data-label="Perfiles" className="">
                                          <label className="bg-orange-100 text-orange-500 font-bold text-sm py-1 px-1 border-round-sm">
                                             {servicio.perfil_tipos.length}
                                          </label>
                                       </td> 
                                       <td data-label="Precio Mensual" className="">
                                          <label className="bg-orange-100 text-orange-500 font-bold text-sm py-1 px-1 border-round-sm">
                                             S/ {servicio.paquete.precio_mensual}
                                          </label>
                                       </td>
                                       {/* <td data-label="Precio Anual" className="">
                                          <label className="bg-cyan-100 text-cyan-500 font-bold text-sm py-1 px-1 border-round-sm">
                                             S/ {servicio.paquete.precio_anual}
                                          </label>
                                       </td> */}
                                       {Habilitar &&<td data-label="N° de Clientes" className="">
                                          <span className=" bg-purple-100 align-items-center border-round-sm p-1" >00 trabajadores</span>
                                       </td>}
                                       <td data-label="Fecha de creación ">
                                          <div className="Fecha_de_creación flex flex-row gap-1">
                                             <i className="pi pi-calendar"></i>
                                             {/* 09/02/2022 */}
                                             {new Date(servicio.paquete.fecha_creacion).toLocaleDateString()}

                                          </div>
                                       </td>
                                       <style>
                                       {`
                                          .BotonEditarServicios{
                                                width:40px;
                                                height: 35px;
                                                border-radius: 5px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                background-color: #BFF1DF;
                                                color: #248D63;
                                                border: 0px solid transparent;
                                             }
                                             .BotonEliminarServicios{
                                                width: 40px;
                                                height: 35px;
                                                border-radius: 5px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                background-color: #FFECEC;
                                                color: #FF6767;
                                                border: 0px solid transparent;
                                             }
                                          `}
                                       </style>
                                       <td data-label="Acción">
                                          <div className="Fecha_de_creación flex flex-row gap-2">
                                             <Button 
                                                className="BotonEditarServicios"
                                                onClick={() => {
                                                console.log('accion editar')
                                                navigate(`/productosyservicios/servicios/editar/${servicio.paquete.id}`)
                                                /* setVisibleEditar(true);
                                                setAccionPaquete(servicio); */
                                             }}><i className="pi pi-pencil"></i></Button>
                                             <Button 
                                                className="BotonEliminarServicios"
                                                onClick={() => {setMostrarModalEliminar(true);
                                                setdeletePackage(servicio)}}>
                                                <i className="pi pi-trash" ></i>
                                             </Button>
                                          </div>
                                       </td>
                                    </tr>
                                    </>
                                 )
                              })}
                           </tbody>
                        </table>
                     </TabPanel>

                     <TabPanel
                        headerTemplate={tab2HeaderTemplate}
                        headerClassName="flex align-items-center"
                     >
                        <div
                           className="flex flex-row"
                           style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                           }}
                        >
                        </div>
                        <div className="grid gap-3" style={{ marginTop: "50px" }}>
                           {paquetes?.map((servicio, key) => {
                              return (
                                 <ComponentpaqueteClinica key={key} servicio={servicio} setMostrarModalEliminar={setMostrarModalEliminar} setPaqueteSeleccionado={setdeletePackage}/>
                              )
                           })}
                        </div>
                     </TabPanel>
                  </TabView>
               </div>
            </div>
            <ModalEliminar estado={mostrarModalEliminar} close={setMostrarModalEliminar} eliminar={eliminar} paquete={deletePackage} />
         </div>
      );
   }