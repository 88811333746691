import { Dialog } from "primereact/dialog";
import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Tree } from 'primereact/tree';
import note from "../../../Images/roles/note.svg";
import { Button } from "primereact/button";

const AccesosModalComponent = ({
   visibleAccess,
   setVisibleAccess,
   rol,
   asignarRoles,
   accesos,
   listaAccesos,
}) => {
   // Mantén un estado local para las selecciones de cada rol
   const [tempSelectedAccesos, setTempSelectedAccesos] = useState([]);
   //const [selectedAccesos, setSelectedAccesos] = useState([]);
   
   useEffect(() => {
      if (listaAccesos) {
         setTempSelectedAccesos(listaAccesos)
         //setSelectedAccesos(listaAccesos)
      } else {
         setTempSelectedAccesos([]); // Si no hay selecciones guardadas, inicializa el estado local vacío
         //setSelectedAccesos([]); // También actualiza el estado global
      }
   }, [rol?.id]);



   const hideDialogAccess = () => {

      setVisibleAccess(false);

   };

   const handleNodeSelect = (e) => {
      setTempSelectedAccesos(e.value);
   }; 

   const handleAssignRol = () => {
      let data = {
         rol_id: rol.id,
         accesos: tempSelectedAccesos,
         //accesos: selectedAccesos,
      };
      console.log(data);
      asignarRoles(data);
      hideDialogAccess();
   };

   const handleCancel = () => {
      // Restablece las selecciones locales a las selecciones globales
      setTempSelectedAccesos(listaAccesos);
      hideDialogAccess();
   };

   const setear = () => {
   }

   const paqueteAsignarDialogFooter = (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
         <Button
            label="Cancelar"
            style={{
               backgroundColor: "#FFFFFF",
               color: "black",
               borderColor: "#CBC9C9",
               width: '45%',
               marginLeft: '7px'
            }}
            onClick={handleCancel}
         />
         <Button
            label="Crear"
            style={{ backgroundColor: "#5555D8", width: '45%', marginRight: '10px' }}
            onClick={handleAssignRol}
         />
      </div>
   );

   return (
      <div className="tabla-rol">
         <Dialog
            visible={visibleAccess}
            style={{ width: "620px", height: "70vh", borderRadius: "20px", margin: "25px"}}
            header={
               <div className="header-accesos" style={{ marginLeft: '20px', marginRight: '20px', display:'flex', alignItems:'center'}}>
                  <img className="pencil1" src={note} alt="pencil" />
                  <i className="pi-file-edit" />
                  <p
                     style={{
                        color: "#5555D8",
                        fontFamily:
                           "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                     }}
                  >
                     Administrar Accesos
                  </p>
               </div>
            }
            modal
            className="p-fluid tabla-rol"
            onHide={handleCancel}
            footer={paqueteAsignarDialogFooter}
         >
            <div className="field" style={{ marginLeft: '20px', marginRight: '20px' }}>
               <label htmlFor="razon_social">
                  En esta sección usted puede administrar y gestionar los datos
                  de <span style={{ fontWeight: "bold" }}>{rol.nombre}.</span>
               </label>
               <br></br>
               <p
                  style={{
                     color: "#5555D8",
                     fontSize: "16px",
                     fontWeight: "bold",
                     fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                  }}
               >
                  Seleccionar accesos
               </p>
               <br></br>
               <Tree
                  // display="chip"
                  value={accesos} //testvalue
                  selectionMode="checkbox"
                  selectionKeys={tempSelectedAccesos} //test
                  onSelectionChange={handleNodeSelect}
                  triState={true} />

            </div>
         </Dialog>
      </div>
   );
};

export default AccesosModalComponent;