import React, { useContext, useState, useEffect } from "react";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import './TriajeAntPerAdd.css';
import './TriajeAntPerAdd_Responsive2.css';
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";


const TriajeAntPerAdd = ({ header, data, patologias }) => {
   const { antPerContext, setAntPerContext } = useContext(MGContext);
   let { stateAtencion, pageViewContext } = useContext(RAContext);

   //CREATE AN USESTATE
   const [patologiasantper, setpatologiasantper] = useState(patologias);
   const [selectedCategories, setSelectedCategories] = useState([]);

   console.log("Antecedetne personal", patologiasantper)

   const onCategoryChange = (e) => {
      console.log("categori",e.value.key)
      let _selectedCategories = [...selectedCategories];

      if (e.checked) _selectedCategories.push({"patologia_id":e.value.id,id:e.value.id,"comentario":""});
      else
         _selectedCategories = _selectedCategories.filter(
            (category) => category.key !== e.value.key
         );
      setSelectedCategories(_selectedCategories);
      setAntPerContext({ ...antPerContext, med_ant_inmunizaciones_personal: _selectedCategories })
   };

   const handleChange = (e) => {
      let _dataInput = [...data];
      console.log("DATA ANTPER", _dataInput)
      if (e.checked) {
         console.log("entro if")
         _dataInput.push({
            patologia_id: e.value.id,
            enfermedad: e.value.nombre,
            comentario: "",
         });
         console.log(_dataInput)
      } else {
         console.log("entro else")
         for (let i = 0; i < _dataInput.length; i++) {
            const selectedPat = _dataInput[i];

            if (selectedPat.patologia_id === e.value.id) {
               _dataInput.splice(i, 1);
               break;
            }
         }
      }
      console.log(_dataInput);
      setAntPerContext({ ...antPerContext, med_ant_patologia_personal: _dataInput })
   };

   const handleChangeText = (index, e) => {
      let _dataInput = [...data];
      _dataInput[index][`comentario`] = e?.target?.value;
      console.log(_dataInput)
      setAntPerContext({ ...antPerContext, med_ant_patologia_personal: _dataInput })
   };

   //contenedor de los stados de los inputs de cada patologia
   const [conjuntoestados, setConjuntoEstados] = useState(Array(patologias.length).fill(false));
   const [dictatedText, setDictatedText] = useState(Array(patologias.length).fill("")); // Nuevo estado para el texto dictado
   // Estado para controlar el dictado
   const webkitSpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
   const recognition = new webkitSpeechRecognition();

   const handleDictation = (index) => {
      if (!conjuntoestados[index]) {
         recognition.lang = 'es-ES'; // Establece el idioma de reconocimiento de voz (puedes cambiarlo según tus necesidades).
         recognition.continuous = false;

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setDictatedText((textos) => {
               const newtext = [...textos]
               newtext[index] = transcript
               return newtext
            }
            ); // Actualiza el estado 'dictatedText' con el texto obtenido.

            // Actualiza el comentario en la patología correspondiente
            const updatedComments = [...antPerContext.med_ant_patologia_personal];
            updatedComments[index].comentario = transcript;
            setAntPerContext({
               ...antPerContext,
               med_ant_patologia_personal: updatedComments,
            });
         };

         recognition.onend = () => {
            setConjuntoEstados(
               (datos) => {
                  const newestado = [...datos];
                  newestado[index] = false;
                  return newestado

               }
            )
         };

         recognition.start();
         setConjuntoEstados((datos) => {
            const newestados = [...datos]
            newestados[index] = true
            return newestados

         })
      } else {
         recognition.stop();
         setConjuntoEstados((datos) => {
            const newestados = [...datos]
            newestados[index] = false
            return newestados

         })
      }
   };

   //CREATE AN USEEFFECT
   useEffect(() => {
      setpatologiasantper(patologias);
   }, [patologias]);

   return (
      <>
         <div
            style={{
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
               <h3 style={{ marginBottom: "10px" }}>{header}</h3>
            </div>
         </div>

         {header === "Paciente" ? (
            <div className="taf_form_add_af_container">
               <div className="taf_form_add_af_checkbox Cuadro_1_checkboxs">
                  {patologiasantper.map((item) => {
                     
                     return (
                        <div className='cuadro_contenedor_checkbox' key={item?.id}>
                           <Checkbox
                              className="checkbox_primero"
                              name="patologia_id"
                              value={item}
                              onChange={handleChange}
                              checked={data?.length > 0 && data?.some((i) => i?.patologia_id === item?.id)}
                              disabled={stateAtencion[pageViewContext].estado === 3}
                           />
                           <label>{item?.nombre}</label>
                        </div>
                     );
                  })}
               </div>
               <div className="taf_form_add_af_checkbox Cuadro_2_comentario">
                  <div className="taf_form_add_af_textarea">
                     {antPerContext?.med_ant_patologia_personal?.length > 0 &&
                        antPerContext?.med_ant_patologia_personal?.map((item, index) => {
                           return (
                              <div key={item?.id}>
                                 <div className="input_micro_label">
                                    <span>
                                       {item?.patologico?.nombre}
                                    </span>

                                 </div>
                                 <div className="flex flex-row w-full">
                                    <div className="flex flex-column w-full">
                                       <InputTextarea
                                          value={conjuntoestados[index] ? dictatedText[index] : item?.comentario}
                                          onChange={(e) => handleChangeText(index, e)}
                                          autoResize
                                          placeholder="Agregue un comentario"
                                          disabled={stateAtencion[pageViewContext].estado === 3}
                                       />
                                    </div>
                                    <div className="flex flex-column justify-content-end" style={{ marginLeft: "-40px", marginBottom: "5px" }}>
                                       <button onClick={() => handleDictation(index)}
                                          style={{ background: "rgb(85, 85, 216)", color: "white", width: "36px", borderRadius: "6px", border: "1px solid rgb(99, 102, 241)", cursor: "pointer" }}
                                       >
                                          {conjuntoestados[index] ? 'Off' : 'On'}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           );
                        })}
                  </div>
               </div>
            </div>
         ) : (
            <div className="Contenedor_Checkboxs_2An">
               {patologias.map((category) => {
                  return (
                     <div key={category.id} className="flex align-items-center Cuadro_3_checkboxs">
                        <Checkbox
                           inputId={category.id}
                           className="mb-2"
                           name="category"
                           value={category}
                           onChange={onCategoryChange}
                           checked={antPerContext?.med_ant_inmunizaciones_personal.some(
                              (item) => item.id === category.id
                           )}
                           disabled={stateAtencion[pageViewContext].estado === 3}
                        />
                        <label htmlFor={category.key} className="ml-2 mb-2" placeholder="Agregue un comentario">
                           {category.name}
                        </label>
                     </div>
                  );
               })}
            </div>
         )}
      </>
   );
};

export default TriajeAntPerAdd;
