import React, { useContext, useEffect, useRef, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { AutoComplete } from 'primereact/autocomplete';
import VoiceButton from '../../../Components/ButtonVoice/VoiceButton';
import { RAContext } from '../../RutaAtencion/RAContext';
import { EspiContext } from '../EspiProvider';
import { Toast } from 'primereact/toast'
import EnvioArchivoEspriometria from '../EnvioArchivoEspiro';
import { InputText } from 'primereact/inputtext';

export default function PageEspDatos() {
   const toast = useRef(null);
   let { datosContext, setDatosContext } = useContext(EspiContext)
   const { pageViewContext, stateAtencion } = useContext(RAContext)

   const [fvc_real, setFvcReal] = useState(datosContext?.datos_detalles?.valor_real)
   const [fvc_porcentaje, setFvcPorcentaje] = useState(0)
   const [fev_porcentaje, setFevPorcentaje] = useState(0)
   const [fev1_real, setFev1Real] = useState(0)
   const [fev1_teorico, setFev1Teorico] = useState(0)
   const [fev1_porcentaje, setFev1Porcentaje] = useState(0)
   const [fef_porcentaje, setFefPorcentaje] = useState(0)
   const [archivo, setarchivo] = useState(datosContext ? datosContext.archivo : null)
   const [visible, setVisible] = useState(false);
   const [items, setItems] = useState([]);
   const [items02, setItems02] = useState([]);

   const updateArchivoFlexibilidad = (nuevoValor) => {
      setDatosContext((prevMuscEsqueletico) => ({
         ...prevMuscEsqueletico,
         archivo: nuevoValor
      }));
   };
   const [gridData, setGridData] = useState([
      [datosContext?.datos_detalles[0]?.valor_real || 0, datosContext?.datos_detalles[0]?.valor_teorico || 0, datosContext?.datos_detalles[0]?.porcentaje || 0],
      [datosContext?.datos_detalles[1]?.valor_real || 0, datosContext?.datos_detalles[1]?.valor_teorico || 0, datosContext?.datos_detalles[1]?.porcentaje || 0],
      [datosContext?.datos_detalles[2]?.valor_real || 0, datosContext?.datos_detalles[2]?.valor_teorico || 0, datosContext?.datos_detalles[2]?.porcentaje || 0],
      [datosContext?.datos_detalles[3]?.valor_real || 0, datosContext?.datos_detalles[3]?.valor_teorico || 0, datosContext?.datos_detalles[3]?.porcentaje || 0],
   ]);

   const columnTitles = ['V. Real', 'V. Teórico', '%'];
   const rowTitles = ['FVC', 'FEV1', 'FEV1/FVC', 'FEF 25-75%'];
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };
   const showDialog = () => {
      setVisible(true);
   };
   const hideDialog = () => {
      setVisible(false);
   };
   const handleChange = (value, rowIndex, colIndex) => {
      const updatedGridData = [...gridData];
      updatedGridData[rowIndex][colIndex] = value;
      setGridData(updatedGridData);
   };
   const handleSave = () => {
      setDatosContext({
         ...datosContext,
         datos_detalles: [{
            esp_datos_detalles_tipo_id: 1,
            valor_real: fvc_real,
            valor_teorico: fvc_teorico,
            porcentaje: fvc_porcentaje
         },
         {
            esp_datos_detalles_tipo_id: 2,
            valor_real: fev_real,
            valor_teorico: fev_teorico,
            porcentaje: fev_porcentaje
         },
         {
            esp_datos_detalles_tipo_id: 3,
            valor_real: fev1_real,
            valor_teorico: fev1_teorico,
            porcentaje: fev1_porcentaje
         },
         {
            esp_datos_detalles_tipo_id: 4,
            valor_real: fef_real,
            valor_teorico: fef_teorico,
            porcentaje: fef_porcentaje
         }]
      })
      showToast('success', 'Éxito', 'Datos guardados correctamente.')
      hideDialog()
   };
   const handleRecover = () => {
      const initialGridData = [
         [0, 0, 0],
         [0, 0, 0],
         [0, 0, 0],
         [0, 0, 0],
      ];
      setGridData(initialGridData);
   };

   //FVC (3)
   const calcularVacío = () => {
      const vacio = gridData[0][0] / gridData[1][0];
      const updatedGridData = [...gridData];
      updatedGridData[2][0] = vacio;
      setFev1Real(vacio)
   };

   //FEV1
   const calcularVacío2 = () => {
      const vacio2 = gridData[0][1] / gridData[1][1];
      const updatedGridData = [...gridData];
      updatedGridData[2][1] = vacio2;
      setFev1Teorico(vacio2)
   };

   //FEF 25-75%
   const calcularPorcentaje = () => {
      const porcentaje = (gridData[0][0] * 100) / gridData[0][1];
      const updatedGridData = [...gridData];
      updatedGridData[0][2] = porcentaje;
      setFvcPorcentaje(porcentaje)
   };


   const calcularPorcentaje2 = () => {
      const porcentaje2 = (gridData[1][0] * 100) / gridData[1][1];
      const updatedGridData = [...gridData];
      updatedGridData[1][2] = porcentaje2;
      setFevPorcentaje(porcentaje2)
   };
   const calcularPorcentaje3 = () => {
      const porcentaje3 = (gridData[2][0] * 100) / gridData[2][1];
      const updatedGridData = [...gridData];
      updatedGridData[2][2] = porcentaje3;
      setFev1Porcentaje(porcentaje3)
   };

   const calcularPorcentaje4 = () => {
      const porcentaje4 = (gridData[3][0] * 100) / gridData[3][1];
      const updatedGridData = [...gridData];
      updatedGridData[3][2] = porcentaje4;
      setFefPorcentaje(porcentaje4)
   };
   const actualizarValores = () => {
      calcularVacío();
      calcularVacío2();
      calcularPorcentaje();
      calcularPorcentaje2();
      calcularPorcentaje3();
      calcularPorcentaje4();
   };
   const search = (event) => {
      let _items = ['Dentro de Límites Normales', 'No aceptables, Múltiples intentos', 'No cumple con criterios de aceptabilidad ni repetibilidad para la ', 'No cumple con criterios de aceptabilidad para la interpretación', 'No cumple con criterios de repetibilidad para  la interpretación', 'Patrón  Mixto (obstructivo y restrictivo)', 'Patrón Obstructivo', 'Patrón Obstructivo Grave', 'Patrón Obstructivo Leve', 'Patrón Obstructivo Leve vs Variante fisiológico normal', 'Patrón Obstructivo Moderadamente Grave', 'Patrón Obstructivo Moderado', 'Patrón Obstructivo muy Grave', 'Patrón sugestivo de restricción Pulmonal muy grave'];

      setItems(event.query ? _items : _items);
   }
   const search02 = (event) => {
      let _items = ['Sibel', 'Winspiro', 'Easy', 'Spirotrac', 'Spirometrypc'];
      setItems02(event.query ? _items : _items);
   }

   const fvc_teorico = gridData[0][1]

   const fev_real = gridData[1][0]
   const fev_teorico = gridData[1][1]

   const fef_real = gridData[3][0]
   const fef_teorico = gridData[3][1]



   const handleFocus = (event) => {
      // Selecciona todo el contenido del input cuando se enfoca
      event.target.select();
   };

   const handleChangeObservacion = (e) => {
      console.log(e);
      setDatosContext({
         ...datosContext,
         observaciones: e,
      })
   }

   // useEffect(() => {
   //    setFvcReal(gridData[0][0])
   //    setFvcPorcentaje(gridData[0][2])
   //    setFevPorcentaje(gridData[1][2])
   //    setFev1Porcentaje(gridData[2][2])
   //    setFev1Real(gridData[2][0])
   //    setFev1Teorico(gridData[2][1])
   //    setFefPorcentaje(gridData[3][2])
   // }, [gridData])

   // useEffect(() => {
   //    setGridData([
   //       [datosContext?.datos_detalles[0]?.valor_real, datosContext?.datos_detalles[0]?.valor_teorico, datosContext?.datos_detalles[0]?.porcentaje],
   //       [datosContext?.datos_detalles[1]?.valor_real, datosContext?.datos_detalles[1]?.valor_teorico, datosContext?.datos_detalles[1]?.porcentaje],
   //       [datosContext?.datos_detalles[2]?.valor_real, datosContext?.datos_detalles[2]?.valor_teorico, datosContext?.datos_detalles[2]?.porcentaje],
   //       [datosContext?.datos_detalles[3]?.valor_real, datosContext?.datos_detalles[3]?.valor_teorico, datosContext?.datos_detalles[3]?.porcentaje],
   //    ])
   // }, [datosContext])

   // useEffect(() => {
   //    actualizarValores();
   // }, [gridData]);

   const editarValorAllContext = (nuevoValor, indice, propiedad) => {
      setDatosContext(prevState => {
         const nuevosDatosDetalles = [...prevState.datos_detalles];
         // Comprueba que hay al menos tres registros en datos_detalles
         if (nuevosDatosDetalles.length >= 3) {
            // Actualiza el valor_real del tercer registro
            nuevosDatosDetalles[indice][propiedad] = nuevoValor;
         }
         return { ...prevState, datos_detalles: nuevosDatosDetalles };
      });
   };
   const editarValorRealTercerRegistro = (nuevoValorReal) => {
      setDatosContext(prevState => {
         const nuevosDatosDetalles = [...prevState.datos_detalles];
         // Comprueba que hay al menos tres registros en datos_detalles
         if (nuevosDatosDetalles.length >= 3) {
            // Actualiza el valor_real del tercer registro
            nuevosDatosDetalles[2].valor_real = nuevoValorReal;
         }
         return { ...prevState, datos_detalles: nuevosDatosDetalles };
      });
   };
   const editarValorTeoricoTercerRegistro = (nuevoValorReal) => {
      setDatosContext(prevState => {
         const nuevosDatosDetalles = [...prevState.datos_detalles];
         if (nuevosDatosDetalles.length >= 3) {
            nuevosDatosDetalles[2].valor_teorico = nuevoValorReal;
         }
         return { ...prevState, datos_detalles: nuevosDatosDetalles };
      });
   };

   const [value01, setvalue01] = useState()
   const [value02, setvalue02] = useState()
   const [value03, setvalue03] = useState(0)
   useEffect(() => {
      if (value01 && value02) {
         setvalue03(value01 / value02)
      }
   }, [value01, value02])
   useEffect(() => {
      setvalue01(datosContext?.datos_detalles[0]?.valor_real / datosContext?.datos_detalles[1]?.valor_real)
      setvalue02(datosContext?.datos_detalles[0]?.valor_teorico / datosContext?.datos_detalles[1]?.valor_teorico)
   }, [datosContext])
   useEffect(() => {
      editarValorAllContext(value01, 2, "valor_real");
      editarValorAllContext(value02, 2, "valor_teorico")
   }, [value01, value02])

   const LimpiarContext = () => {
      editarValorAllContext(0, 0, "valor_real");
      editarValorAllContext(0, 0, "valor_teorico");
      editarValorAllContext(0, 1, "valor_real");
      editarValorAllContext(0, 1, "valor_teorico");
      editarValorAllContext(0, 3, "valor_real");
      editarValorAllContext(0, 3, "valor_teorico");
   }

   return (
      <>
         <Toast ref={toast} />
         <style>{`
         .p-disabled, .p-component:disabled {
            background-color: #DDDDDD;
         }
         .tablaNumericAHM{
            display: flex !important;
            flex-direction: column;
            gap: 7px !important;
         }
         .tablaNumericAHM tbody{
            width: 100% !important;
            height: auto !important;
            display: flex !important;
            flex-direction: column;
            gap: 7px !important;
         }
         .tablaNumericAHM tbody tr{
            width: 100% !important;
            height: auto !important;
            display: flex !important;
            gap: 7px !important;
         }
         .tablaNumericAHM tbody tr > th{
            padding: 0px 0px 0px 20px; 
            display: flex;
            align-items:center;
            justify-content: start;
            width: 31% !important;
            background-color: #F6F2FC;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 700;
            border-radius: 6px;
            color: #5555D8;
            overflow: hidden;
         }
         .tablaNumericAHM tbody tr > td{
            width: 23% !important;
            padding: 0px !important
         }
         .tablaNumericAHM tbody tr > td input{
            width: 100% !important;
            min-width: 0px !important
         }
         .tablaNumericAHM thead tr{
            width: 100% !important;
            display: flex !important;
            gap: 7px !important;
         }
         .tablaNumericAHM thead tr th:nth-child(1) {
            width: 31% !important;
            border-color: transparent 
         }
         .tablaNumericAHM thead tr th {
            border-color: transparent 
         }
         .tablaNumericAHM tbody tr th {
            border-color: transparent 
         }
         .tablaNumericAHM thead tr th:nth-child(2),
         .tablaNumericAHM thead tr th:nth-child(3),
         .tablaNumericAHM thead tr th:nth-child(4) {
            height: 50px;
            display: flex;
            align-items:center;
            justify-content: center;
            background-color: #F6F2FC;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 700;
            width: 23% !important;
            border-radius: 6px;
            color: #5555D8;
         }
         `}</style>
         <style>{`
         .SubTittleEspiroDatos{
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 700;
            color: #5555D8;
            overflow: hidden;
            padding: 0px 0px 3px 3px;
         }
         .tablaNumericAHMSECbuttons button{
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 700;
            overflow: hidden;
            border-radius: 10px;
         }
         .tablaNumericAHMSECbuttons button:nth-child(1){
            color: #6BCDF5;
            border: 1px solid #6BCDF5;
            background-color: #E0F5FD;
            transition: .5s;
         }
         .tablaNumericAHMSECbuttons button:nth-child(1):hover{
            color: #E0F5FD;
            background-color: #6BCDF5;
         }
         .ParteEnvioEspiroDatos > div {
            width: 100%;
         }
         @media (max-width:851px){
            .ContendorCuadroPrinndEspiroDatos{
               flex-wrap: wrap;
               gap: 5px !important;
            }
            .ContendorCuadroPrinndEspiroDatos > div:nth-child(1) {
               width: 100% !important;
            }
            .ContendorCuadroPrinndEspiroDatos > div:nth-child(2) {
               width: 100% !important;
            }
         }
         @media (max-width:450px){
            .tablaNumericAHMSECbuttons{
               flex-direction: row !important;
            }
            .tablaNumericAHMSECbuttons > button:nth-child(1) {
               width: 50% !important;
               flex-direction: row !important;
            }
            .tablaNumericAHMSECbuttons > button:nth-child(2) {
               width: 50% !important;
               flex-direction: row !important;
            }
         }
         `}</style>
         <div className='ContendorCuadroPrinndEspiroDatos w-hull h-auto flex align-items-start justify-content-between gap-3'>
            <div className="w-6 h-auto flex flex-column p-2" style={{ borderRadius: '15px' }}>
               <table className='tablaNumericAHM w-full h-auto'>
                  <thead>
                     <tr>
                        <th></th>
                        {columnTitles.map((title, index) => (
                           <th key={index}>{title}</th>
                        ))}
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <th>FVC</th>
                        <td>
                           <InputNumber value={datosContext?.datos_detalles[0]?.valor_real}
                              onValueChange={(e) => {
                                 const newData = [...datosContext.datos_detalles];
                                 newData[0] = { ...newData[0], valor_real: e.value };
                                 setDatosContext(prevState => ({ ...prevState, datos_detalles: newData }));
                              }}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber value={datosContext?.datos_detalles[0]?.valor_teorico}
                              onValueChange={(e) => {
                                 const newData = [...datosContext.datos_detalles];
                                 newData[0] = { ...newData[0], valor_teorico: e.value };
                                 setDatosContext(prevState => ({ ...prevState, datos_detalles: newData }));
                              }}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber disabled className=''
                              value={(datosContext?.datos_detalles[0]?.valor_real * 100) / datosContext?.datos_detalles[0]?.valor_teorico}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                     </tr>
                     <tr>
                        <th>FEV1</th>
                        <td>
                           <InputNumber value={datosContext?.datos_detalles[1]?.valor_real}
                              onValueChange={(e) => {
                                 const newData = [...datosContext.datos_detalles];
                                 newData[1] = { ...newData[1], valor_real: e.value };
                                 setDatosContext(prevState => ({ ...prevState, datos_detalles: newData }));
                              }}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber value={datosContext?.datos_detalles[1]?.valor_teorico}
                              onValueChange={(e) => {
                                 const newData = [...datosContext.datos_detalles];
                                 newData[1] = { ...newData[1], valor_teorico: e.value };
                                 setDatosContext(prevState => ({ ...prevState, datos_detalles: newData }));
                              }}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber disabled className=''
                              value={(datosContext?.datos_detalles[1]?.valor_real * 100) / datosContext?.datos_detalles[1]?.valor_teorico}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                     </tr>
                     <tr>
                        <th>FEV1/FVC</th>
                        <td>
                           <InputNumber disabled className=''
                              value={value01}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber disabled className=''
                              value={value02}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber disabled className=''
                              value={value03}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                     </tr>
                     <tr>
                        <th>FEF 25-75%</th>
                        <td>
                           <InputNumber value={datosContext?.datos_detalles[3]?.valor_real}
                              onValueChange={(e) => {
                                 const newData = [...datosContext.datos_detalles];
                                 newData[3] = { ...newData[3], valor_real: e.value };
                                 setDatosContext(prevState => ({ ...prevState, datos_detalles: newData }));
                              }}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber value={datosContext?.datos_detalles[3]?.valor_teorico}
                              onValueChange={(e) => {
                                 const newData = [...datosContext.datos_detalles];
                                 newData[3] = { ...newData[3], valor_teorico: e.value };
                                 setDatosContext(prevState => ({ ...prevState, datos_detalles: newData }));
                              }}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                        <td>
                           <InputNumber disabled className=''
                              value={(datosContext?.datos_detalles[3]?.valor_real * 100) / datosContext?.datos_detalles[3]?.valor_teorico}
                              useGrouping={false} minFractionDigits={0} maxFractionDigits={2} locale="en-US" />
                        </td>
                     </tr>
                  </tbody>
               </table>
               <div className="tablaNumericAHMSECbuttons w-full flex h-auto pt-2 justify-content-end gap-2">
                  <button className='w-4 h-3rem p-2 flex justify-content-center gap-2 align-items-center' onClick={LimpiarContext}>
                     <i className="pi pi-replay" />
                     <span>Recuperar</span>
                  </button>
               </div>
            </div>
            <div className="w-6 h-auto flex flex-column p-2" style={{ borderRadius: '15px' }}>
               <div className='w-full h-auto flex flex-column gap-1'>
                  <p className='SubTittleEspiroDatos'>Interpretación:</p>
                  <AutoComplete className='pb-3'
                     value={datosContext.interpretacion}
                     suggestions={items}
                     completeMethod={search}
                     onChange={(e) => setDatosContext({ ...datosContext, interpretacion: e.value })}
                     dropdown style={{ width: '100%' }}
                     disabled={stateAtencion[pageViewContext].estado === 3} />
               </div>
               <div className='w-full h-auto flex flex-column gap-1 mb-3'>
                  <p className='SubTittleEspiroDatos'>Observaciones:</p>
                  <VoiceButton
                     value={datosContext.observaciones}
                     onChange={handleChangeObservacion}
                     placeholder="Observaciones"
                     disabled={stateAtencion[pageViewContext].estado === 3} />
               </div>
               <div className='w-full h-auto flex flex-column gap-1'>
                  <p className='SubTittleEspiroDatos'>Equipo:</p>
                  <InputText disabled className=''
                     value={datosContext.esp_equipo ? datosContext.esp_equipo : "Winspiro"}
                     onChange={(e) => setDatosContext({ ...datosContext, esp_equipo: e.value })} />
               </div>
            </div>
         </div>
         <div className='ParteEnvioEspiroDatos w-full h-auto flex align-items-start justify-content-center'>
            <EnvioArchivoEspriometria
               FuncionSetEnvio={setarchivo}
               FuncionUpData={updateArchivoFlexibilidad}
               ValorSet={archivo} />
         </div>
      </>
   )
}
