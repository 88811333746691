import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
//import "./navbar.css";

import { Menu } from "primereact/menu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/authSlice";
import Play from '../../Images/Navbar/play.svg';
import { useLocation } from "react-router-dom";
import AvatarMap from "../../assets/36.png"
import { Skeleton } from "primereact/skeleton";
import TextTrimmer from "../Ventas/TextTrimmer/TextTrimmer";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
// import {imagenCambiarCon} from '../../Images/Perfil/icon/ActualizarUsuario.svg';

import './Navvar_02_AHN.css';

import './estilo.css';

function Navbar({ persona, loading }) {
   // #region Usando Redux
   const { first_name, last_name } = useSelector((state) => state.data);
   const dispatch = useDispatch();
   const tutoriales = [
      {
         id: 1,
         path: "/recursoshumanos",
         url: "https://www.youtube.com/watch?v=4ZmzGj6gQhw"
      },
      {
         id: 2,
         path: "/operaciones",
         url: "https://www.youtube.com/watch?v=4ZmzGj6gQhw"
      },
      {
         id: 3,
         path: "/perfil",
         url: "https://www.youtube.com/watch?v=4ZmzGj6gQhw"
      },
      {
         id: 4,
         path: "/productosyservicios",
         url: "https://www.youtube.com/watch?v=4ZmzGj6gQhw"
      },
      {
         id: 5,
         path: "/ventas",
         url: "https://www.youtube.com/watch?v=4ZmzGj6gQhw"
      },
      {
         id: 6,
         path: "/contabilidad",
         url: "https://www.youtube.com/watch?v=4ZmzGj6gQhw"
      },
      {
         id: 7,
         path: "/estadisticas",
         url: ""
      },
      {
         id: 8,
         path: "/soportetecnico",
         url: ""
      }
   ]
   const location = useLocation();
   const activeUrl = tutoriales.filter(url => url.path === location.pathname)[0];
   const handleLogout = () => {
      dispatch(logout());
   }


   const menu = useRef(null);

   const itemsMenu = [
      {
         label: 'Perfil',
         icon: 'pi pi-user',
         url: "/perfil",
         command: () => {
            // lógica para redirigir al perfil del usuario
         },
      },
      {
         label: 'Notificaciones',
         icon: 'pi pi-bell',
         command: () => {
            //
         },
         className: 'xl:hidden lg:hidden md:hidden sm:hidden block',
      },
      {
         label: "Cerrar Sesión",
         icon: "pi pi-sign-out",
         command: () => {
            handleLogout()
         },
      },
      {
         label: "Cambiar Contraseña",
         icon: "pi pi-lock",
         command: () => {
            setVisibled(true)
         },
      },
   ];


   const [visibled, setVisibled] = useState(false);

   //------------------------------------------------------------------
   const [input100Value, setInput100Value] = useState('');
   const [input200Value, setInput200Value] = useState('');
   const [input200Disabled, setInput200Disabled] = useState(false);

   // Función para manejar el cambio del input1
   const handleInputChange100 = (event) => {
      const value = event.target.value;
      setInput100Value(value);

      // Si el valor del input1 es "valor-especifico", deshabilita el input2
      // setInput200Disabled(value === contraseña_ACtual);
   }
   //------------------------------------------------------------------

   // Función para manejar el cambio del input2
   const handleInputChange200 = (event) => {
      const value = event.target.value;
      setInput200Value(value);
   };

   //------------------------------------------------------------------
   const footerContent = (
      <div>
         <Button label="Confirmar" icon="pi pi-check" disabled={!input200Disabled} onClick={() => { setVisibled(false) }} autoFocus />
         <Button label="Cancelar" icon="pi pi-times" onClick={() => { setVisibled(false) }} className="p-button-text" />
      </div>
   );

   const estiloDiv01 = { backgroundColor: 'rgb(235, 235, 255)', borderRadius: '10px', width: '60px', height: '60px', };
   const estiloParrafo02 = { fontWeight: '500', fontSize: '14px', color: 'rgb(123, 135, 148)', };
   const estiloParrafo023 = { fontWeight: '650', fontSize: '14px', color: 'rgb(60, 60, 60)', margin: '5px 0px', };
   const estiloParrafo03 = { fontWeight: '700', fontSize: '16px', color: 'rgb(85, 85, 216)', };

   const BodyyyContent = (
      <div style={{ width: '100%', padding: '1rem 0rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
         <div className='w-full flex flex-column justify-content-start align-items-start' style={{ padding: '6px' }}>
            <p style={estiloParrafo023}>Ingrese su contraseña actual:</p>
            <Password toggleMask style={{ width: '100%' }} value={input100Value}
               onChange={handleInputChange100} className="input_password_AHNN" autocomplete="off" />
         </div>
         {input200Disabled && (
            <div className='w-full flex flex-column justify-content-start align-items-start mt-2' style={{ border: '2px solid rgb(235, 235, 255)', borderRadius: '13px', padding: '6px' }}>
               <p style={estiloParrafo023} disabled={input200Disabled}>Ingrese su nueva contraseña:</p>
               <Password toggleMask style={{ width: '100%' }} value={input200Value}
                  onChange={handleInputChange200} className="input_password_AHNN" />
            </div>
         )}
      </div>
   );

   const headerrrContent = (
      <div id="pr_id_2_header" className="p-dialog-title">
         <div className="flex flex-column gap-2">
            <div className="flex flex-column justify-content-center align-items-center" style={estiloDiv01}>
               {/* <img src={imagenCambiarCon} alt="" style={{ width: '25px', height: '31.25px' }}/> */}
               <svg width="29" height="35" viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.75 20.75V27M20.75 27V33.25M20.75 27H27M20.75 27L14.5 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
                  <path d="M14.5 17.625C18.8147 17.625 22.3125 14.1272 22.3125 9.8125C22.3125 5.49778 18.8147 2 14.5 2C10.1853 2 6.6875 5.49778 6.6875 9.8125C6.6875 14.1272 10.1853 17.625 14.5 17.625ZM14.5 17.625C7.59644 17.625 2 21.8223 2 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
               </svg>
            </div>
            <div>
               <h3 style={estiloParrafo03}>Contraseña</h3>
               <p style={estiloParrafo02}>Modifique o actualice su contraseña actual.</p>
            </div>
         </div>
      </div>
   );

   const [notificaciones, setNotificaciones] = useState([]);

   // useEffect(() => {
   // Obtiene las notificaciones cuando se monta el componente
   // obtenerNotificaciones();

   // Configura un intervalo para obtener nuevas notificaciones cada 30 segundos (ajusta el intervalo según las necesidades)
   // const interval = setInterval(obtenerNotificaciones, 5000); // 30000 ms = 30 segundos

   // // Limpia el intervalo cuando se desmonta el componente para evitar fugas de memoria
   // return () => {
   // 	clearInterval(interval);
   // };
   // }, []);



   return (
      <>

         <div className="card flex justify-content-center">
            <Dialog header={headerrrContent} visible={visibled} style={{ width: 'auto' }} onHide={() => setVisibled(false)} footer={footerContent}>
               {BodyyyContent}
            </Dialog>
         </div>


         <div className="xl:flex lg:flex md:flex sm:flex hidden">
            <span className=" border-round-lg flex align-items-center">
               {//<Link to="EmpresaRol/Perfil">
               }
                  <button className="BOTONCAMPANITA p-button  border-none" style={{ background: '#FFFFFF', position: 'relative' }}>
                     <span style={{ backgroundColor: "red", color: "white", borderRadius: "50%", width: "15px", position: 'absolute', top: '8px', right: '19px', fontSize: '12px' }}>
                        {
                           Array.isArray(notificaciones)
                              ? notificaciones.filter(notificacion => notificacion.estado_visto === "0").length
                              : "0"
                        }
                     </span>

                     <i className="pi pi-bell text-indigo-500 det" style={{ fontSize: '1.5rem' }}></i>
                  </button>
              {//</Link> 
              }
            </span>
         </div>


         {activeUrl && (
            <div className="xl:flex lg:flex md:flex sm:flex hidden">
               <span className=" border-round-lg flex align-items-center">
                  <button className="BOTONCAMPANITA p-button border-none pr-1 pl-1 pt-1 pb-1 campanita" img={Play} /* onClick={() => window.open(activeUrl.url, "video", "width=600,height=400")} */ >
                     <i
                        className="fas fa-play text-indigo-500 p-overlay-badge flex align-items-center justify-content-center"
                        style={{
                           fontSize: '1.2rem',
                           width: '4vmin',
                           height: '4vmin',
                        }}
                     >
                     </i>
                  </button>
               </span>
            </div>

         )
         }
         <div className="flex align-items-center gap-1 bg-white p-1 border-round-lg xl:w-13rem lg:w-13rem md:w-13rem sm:w-auto w-auto">
            <div className="flex-1 xl:flex lg:flex md:flex sm:hidden hidden flex-column gap-1 align-items-end">
               {
                  loading ? (
                     <>
                        <Skeleton className="border-round-lg" style={{ width: "9rem", height: "18px" }} />
                        <Skeleton className="border-round-lg" style={{ width: "9rem", height: "18px" }} />
                     </>
                  ) : (
                     <>
                        <TextTrimmer className="" text={`${first_name} ${last_name === "null" || last_name === null ? "" : last_name}`} maxLength={15} />
                        <TextTrimmer className="text-gray-500" text={"Usuario"} maxLength={15} />
                     </>
                  )
               }

            </div>

            {
               loading ? (
                  <Skeleton className="border-circle w-3rem h-3rem" />
               ) : (
                  <img
                     onClick={(e) => menu.current.toggle(e)}
                     style={{ objectFit: "cover" }}
                     className="bg-blue-100 border-circle cursor-pointer w-3rem h-3rem"
                     src={persona?.data?.persona?.foto || AvatarMap}
                     alt="Sin img"
                  />
               )
            }



            <Menu
               model={itemsMenu}
               popup
               ref={menu}

               style={{ zIndex: 10, marginTop: '.8rem' }}
            />

         </div>
         {/*<InboxComponent disminuir={DisminuirCampanita} />*/}
      </>
   );
}

export default Navbar;