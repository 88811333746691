import React, { useState, useEffect, useRef, useContext } from "react";
//import { InputTextarea } from 'primereact/inputtextarea';
import data from "../../../Services/dataExamenFisico2";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
//import VoiceInputTextarea from "../../../Components/ButtonVoice/VoiceInputTextarea";
import { Dropdown } from 'primereact/dropdown';
import { MGContext } from "../MGProvider";

export default function ExamenFisico() {
   const { examenFisicoContext, setExamenFisicoContext } = useContext(MGContext);
   const [Anmnesis, setAnmnesis] = useState(examenFisicoContext?.med_examen?.anamnesis || '');

   const opciones = [
      { name: 'No realizada', code: 'NR' },
      { name: 'Normal', code: 'N' },
      { name: 'Anormal', code: 'A' },
   ];
   const [Examen, setExamen] = useState(((examenFisicoContext?.med_examen_detalle?.length > 0) && (examenFisicoContext?.med_examen_detalle)) ||
      data.map((Examenes, item) => {
         return {
            patologia_id: Examenes.id,
            resultado: Examenes.name ?? opciones[1].name,
            observaciones: Examenes.observaciones,
         }
      }))

   // Función para actualizar la observación de una fila específica
   const handleExamenChange = (index, value, atributo) => {
      console.log("valor", value)
      const updatedExamen = [...Examen];
      updatedExamen[index] = {
         ...updatedExamen[index],
         [atributo]: value
      };
      setExamen(updatedExamen);
   };

   console.log("CONTENIDO", Examen)
   
   useEffect(() => {
      setExamenFisicoContext({
         ...examenFisicoContext,
         med_examen: {
            anamnesis: Anmnesis
         },
         med_examen_detalle: Examen
      })
   }, [Anmnesis, Examen]);

   return (
      <div className="flex flex-column gap-3">
         <div className="flex flex-column">
            <span>Anamnesis</span>
            <VoiceButton
               value={Anmnesis=="null"?null:Anmnesis}
               onChange={setAnmnesis}
               placeholder="Comentario"
            />
         </div>
         <div>
            {/*  */}
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
               <div>
               </div>
               <table border={0} className="Hemogramatabla">
                  <thead>
                     <tr>
                        <th style={{ borderLeft: '0px', width: '25%' }}>Patología</th>
                        <th style={{ width: '25%' }}>Resultado</th>
                        <th>Observaciones</th>
                     </tr>
                  </thead>
                  <tbody>
                     {data.map((item, index) => (
                        <React.Fragment>
                           <tr key={item.id}>
                              <td style={{ borderLeft: '0px' }}>{item.prueba}</td>
                              <td>
                                 <Dropdown
                                    value={examenFisicoContext?.med_examen_detalle[index]?.resultado == "No realizada" ? opciones[0] : examenFisicoContext?.med_examen_detalle[index]?.resultado == "Anormal" ? opciones[2] : opciones[1]}

                                    onChange={(e) => handleExamenChange(index, e.value.name, 'resultado')}
                                    options={opciones}
                                    optionLabel="name"
                                    className="w-full md:w-14rem"
                                 />
                              </td>
                              <td>{item.um}
                                 <VoiceButton
                                    value={Examen[index]?.observaciones=="null"?null:Examen[index]?.observaciones}
                                    onChange={(newText) => handleExamenChange(index, newText, 'observaciones')}
                                    placeholder="Hable aquí"
                                 />
                              </td>
                           </tr>
                        </React.Fragment>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )
}