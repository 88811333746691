import React, { useEffect, useState } from 'react';
import Http from "../../AuthUser";
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Button } from "primereact/button";
import { Divider } from '@mui/material';
import SideArea from '../../Components/Empresa/GestionAtencion/SideArea'

import { Dropdown } from 'primereact/dropdown';

const CercoAreas = (props) => {

  
  const { http } = Http();
  let superAreasOptions = [
    {
      name: 'Administrativo',
      areasOptions: [
        {
          name: 'Finanzas',
          subAreaOptions: [
            { name: 'Cobranzas' },
            { name: 'Contabilidad' },
          ]
        },
        {
          name: 'Venta',
          subAreaOptions: [
            { name: 'Postventa' },
            { name: 'Venta' },
          ]
        },
        {
          name: 'Recursos Humanos',
          subAreaOptions: [
            { name: 'Reclutamiento' },
            { name: 'Bienestar Social' },
          ]
        },
      ]
    },
    {
      name: 'Operativo',
      areasOptions: [
        {
          name: 'Producción',
          subAreaOptions: [
            { name: 'Recepción' },
            { name: 'Molienda' },
            { name: 'Envasado' },
          ]
        },
        {
          name: 'Calidad',
          subAreaOptions: [
            { name: 'Aseguramiento de la Calidad' },
            { name: 'Gestion de la Calidad' },
          ]
        },
        {
          name: 'Logística',
          subAreaOptions: [
            { name: 'Transportista' },
            { name: 'Operativo de Carga' },
          ]
        },
      ]
    },
  ];
  let sexos = [
   {
      name: 'Hombre'
   },
   {
      name: 'Mujer'
   }
  ]

  

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);

  const [superAreas, setSuperAreas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [edad, setEdad] = useState(null);

  //Estilos Item Multiselect
  const selectedSuperAreas = (option) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.nombre}</div>
        </div>
      );
    }
    return "Seleccione un Superárea";
  }
  const selectedAreas = (option) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.nombre}</div>
        </div>
      );
    }
    return "Seleccione un Área";
  }
  const selectedSubAreas = (option) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.nombre}</div>
        </div>
      );
    }
    return "Seleccione un Subárea";
  }

  const panelFooterSuperAreas = () => {
    const selectedSuperAreas = props.areaN1;
    const length = selectedSuperAreas ? selectedSuperAreas.length : 0;
    return (
      <div className="py-2 px-3" style={{ padding: '1rem 1.5rem' }}>
        <b>{length}</b> item{length > 1 ? 's' : ''} seleccionados.
      </div>
    );
  }
  const panelFooterAreas = () => {
    const selectedAreas = props.areaN2;
    const length = selectedAreas ? selectedAreas.length : 0;
    return (
      <div className="py-2 px-3" style={{ padding: '1rem 1.5rem' }}>
        <b>{length}</b> item{length > 1 ? 's' : ''} seleccionados.
      </div>
    );
  }
  const panelFooterSubAreas = () => {
    const selectedSubAreas = props.areaN3;
    const length = selectedSubAreas ? selectedSubAreas.length : 0;
    return (
      <div className="py-2 px-3" style={{ padding: '1rem 1.5rem' }}>
        <b>{length}</b> item{length > 1 ? 's' : ''} seleccionados.
      </div>
    );
  }

  const getAllSuperAreas = () => {
    http
      .get("empresas/superareas/get")
      .then((response) => {
        setSuperAreas(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllAreas = () => {
    if (props.areaN1.length !== 0) {
      http
        .get("empresas/areas/get")
        .then((response) => {
          setAreas(
            props.areaN1?.map(data => {
              return response.data.data.filter(area => area.superarea_id === data.id)
            }).flat()
          )
        })
        .catch((error) => {
          console.log(error);
        });

    } else {
      setAreas([])
      props.setAreaN2([])
    }
  };


  const getAllSubAreas = () => {
    if (props.areaN2.length !== 0) {
      http
        .get("empresas/subareas/get")
        .then((response) => {
          setSubAreas(
            props.areaN2?.map(data => {
              return response.data.data.filter(subarea => subarea.area_id === data.id)
            }).flat()
          )
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSubAreas([])
      props.setAreaN3([])
    }
  };


  useEffect(() => {
    getAllSuperAreas();
  }, [])

  useEffect(() => {
    getAllAreas();
  }, [props.areaN1])

  useEffect(() => {
    getAllSubAreas();
  }, [props.areaN2])


  const handleValueArea = (e, setState, state, setValueState) => {
    setState(e.checked)
    if (state) {
      setValueState([])
    }
  }

  const handleResetAreas = () => {
    setChecked1(false)
    setChecked2(false)//estas aqui?
    setChecked3(false)
    setChecked4(false)
    setChecked5(false)
    setChecked6(false)
  }

  let edades = [
    { name: '18-25'},
    { name: '26-35'},
    { name: '36-50'},
    { name: '51-65'},
];


  let tiempoPermanencia = [
    {
      años: "1"
    },
    {
      años: "2"
    },
    {
      años: "3"
    },
    {
      años: "4"
    },
    {
      años: "5"
    }
  ];

  return (
     <>
        {/* <div className="flex flex-column">
        <label className="text-xs">Áreas</label>
        <div className='relative'>
        <label className='text-xs absolute z-5 bg-white mt-2' style={{marginLeft:'0.75rem'}}>Nivel 1</label>
        </div>
        <div className="flex align-items-center gap-3 mt-3">
          <MultiSelect
            value={props.areaN1}
            options={superAreasOptions}
            onChange={(e) => {props.setAreaN1(e.value)
              console.log(e.value)
            }}
            optionLabel="name"
            placeholder="Selecione un área"
            // filter
            // showClear
            className="flex w-full text-sm"
            display="chip"
            // selectedItemTemplate={selectedSuperAreas}
            panelFooterTemplate={panelFooterSuperAreas}
            disabled={!checked1}
          />
          <Checkbox checked={checked1} onChange={e => handleValueArea(e, setChecked1, checked1, props.setAreaN1)} className='mr-3'/>
        </div>

        <div className='flex bg-indigo-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3  mr-2'>
          <label className='flex text-xs text-indigo-600 align-items-center'>
            Administrativo
            <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '4px' }}></i>
          </label>
        </div>

        <div className='relative'>
        <label className='text-xs absolute z-5 bg-white' style={{marginLeft:'0.75rem', marginTop:'-0.50rem'}}>Nivel 2</label>
        </div>
        <div className="flex align-items-center gap-3">
          <MultiSelect
            value={props.areaN2}
            options={areas}
            onChange={(e) => props.setAreaN2(e.value)}
            optionLabel="nombre"
            placeholder="Selecione un área"
            // filter
            // showClear
            className="flex w-full text-sm"
            display="chip"
            // selectedItemTemplate={selectedAreas}
            panelFooterTemplate={panelFooterAreas}
            disabled={!checked2 || !checked1}
          />
          <Checkbox checked={checked2} onChange={e => handleValueArea(e, setChecked2, checked2, props.setAreaN2)} disabled={!checked1} className='mr-3'/>
        </div>

        <div className='flex'>
          <div className='flex bg-indigo-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3  mr-2'>
            <label className='flex text-xs text-indigo-600 align-items-center'>
              Cobransa
              <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '3px' }}></i>
            </label>
          </div>

          <div className='flex bg-cyan-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3  mr-2'>
            <label className='flex text-xs text-cyan-600 align-items-center'>
              Ventas
              <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '3px' }}></i>
            </label>
          </div>
        </div>

        <div className='relative'>
        <label className='text-xs absolute z-5 bg-white' style={{marginLeft:'0.75rem', marginTop:'-0.50rem'}}>Nivel 3</label>
        </div>
        <div className="flex align-items-center gap-3">
          <MultiSelect
            value={props.areaN3}
            options={subAreas}
            onChange={(e) => props.setAreaN3(e.value)}
            optionLabel="name"
            placeholder="Selecione un área"
            filter
            className="flex w-full text-sm "
            display="chip"
            panelFooterTemplate={panelFooterSubAreas}
            disabled={!checked3 || !checked2 || !checked1}
          />
          <Checkbox checked={checked3} onChange={e => handleValueArea(e, setChecked3, checked3, props.setAreaN3)} disabled={!checked1 || !checked2} className='mr-3'/>
        </div>

        <div className='flex'>
          <div className='flex bg-pink-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3 mr-2'>
            <label className='flex text-xs text-pink-600 align-items-center'>
              Reclutamiento
              <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '3px' }}></i>
            </label>
          </div>

          <div className='flex bg-green-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3 mr-2'>
            <label className='flex text-xs text-green-600 align-items-center white-space-nowrap'>
              Bienestar social
              <i className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1" style={{ fontSize: '10px', paddingTop: '3px' }}></i>
            </label>
          </div>
        </div>
      </div> */}

{/* CAMBIOS QUE HAGO HOY 04/10/*/}

        {/* <div className="flex flex-row justify-content-between align-items-center">
           <span style={{ fontWeight: "600", fontSize: "16px" }}>
              Áreas de la empresa
           </span>
           <Button
              icon="pi pi-ellipsis-h"
              className="BOTON-TRES-Puntos p-button-outlined p-button-secondary"
           />
        </div> */}
        {/* <div className="flex align-items-center gap-3 mt-3">
          <MultiSelect
            value={props.sexo}
            options={sexos}
            onChange={(e) => props.setSexo(e.value)}
            optionLabel="name"
            placeholder="Selecione un Género"
            // filter
            // showClear
            className="flex w-full text-sm"
            display="chip"
            panelFooterTemplate={panelFooterSuperAreas}
            disabled={!checked4}
          />
          <Checkbox checked={checked4} onChange={e => handleValueArea(e, setChecked4, checked4, props.setSexo)} className='mr-3'/>
        </div> */}
        

        {/* <div className="flex flex-column mt-3">
           <label className="text-xs">Otras categorías</label>
           {/* <div className="relative">
              <label
                 className="text-xs absolute z-5 bg-white mt-2"
                 style={{ marginLeft: "0.75rem" }}
              >
                 Sexo
              </label>
           </div> */}
           {/* <div className="flex align-items-center gap-3 mt-3">
              <MultiSelect
                 value={props.areaN1}
                 options={superAreas}
                 onChange={(e) => props.setAreaN1(e.value)}
                 optionLabel="nombre"
                 placeholder="Selecione un área"
                 // filter
                 // showClear
                 className="flex w-full text-sm"
                 display="chip"
                 // selectedItemTemplate={selectedSuperAreas}
                 panelFooterTemplate={panelFooterSuperAreas}
                 disabled={!checked1}
              />
              <Checkbox
                 checked={checked4}
                 onChange={(e) => handleValueArea(e, setChecked4, checked4)}
                 className="mr-3"
              />
           </div> */} 

           {/* <div className="flex">
              <div className="flex bg-indigo-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3 mr-2">
                 <label className="flex text-xs text-indigo-600 align-items-center">
                    Varones
                    <i
                       className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1"
                       style={{ fontSize: "10px", paddingTop: "4px" }}
                    ></i>
                 </label>
              </div>
        </div> */}
        {/* <div className='relative'>
        <label className='text-xs absolute z-5 bg-white' style={{marginLeft:'0.75rem', marginTop:'-0.50rem'}}>Rango de edad</label>
        </div> */}
        {/* <div className="flex align-items-center gap-3">
          <MultiSelect
            value={props.rangoEdad}
            options={edades}
            onChange={(e) => props.setRangoEdad(e.value)}
            optionLabel="name"
            placeholder="Selecione un rango"
            // filter
            // showClear
            className="flex w-full text-sm"
            display="chip"
            panelFooterTemplate={panelFooterAreas}
            disabled={!checked5}
          />
          <Checkbox checked={checked5} onChange={e => handleValueArea(e, setChecked5, checked5, props.setRangoEdad)} className='mr-3'/>
            </div>  */}

           {/* <div className="relative">
              <label
                 className="text-xs absolute z-5 bg-white"
                 style={{ marginLeft: "0.75rem", marginTop: "-0.50rem" }}
              >
                 Rango de edad
              </label>
           </div> */}
           {/* <div className="flex align-items-center gap-3">
              <MultiSelect
                 value={props.areaN2}
                 options={areas}
                 onChange={(e) => props.setAreaN2(e.value)}
                 optionLabel="nombre"
                 placeholder="Selecione un área"
                 // filter
                 // showClear
                 className="flex w-full text-sm"
                 display="chip"
                 // selectedItemTemplate={selectedAreas}
                 panelFooterTemplate={panelFooterAreas}
                 disabled={!checked2 || !checked1}
              />
              <Checkbox
                 checked={checked5}
                 onChange={(e) => handleValueArea(e, setChecked5, checked5)}
                 disabled={!checked1}
                 className="mr-3"
              />
           </div> */}

        {/* <div className='relative'>
        <label className='text-xs absolute z-5 bg-white' style={{marginLeft:'0.75rem', marginTop:'-0.50rem'}}>tiempo de permanencia</label>
        </div> */}
        {/* <div className="flex align-items-center gap-3">
          <MultiSelect
            value={props.permanencia}
            options={tiempoPermanencia}
            onChange={(e) => props.setPermanencia(e.value)}
            optionLabel="años"
            placeholder="Selecione un área"
            //filter
            // showClear
            className="flex w-full text-sm "
            display="chip"
            panelFooterTemplate={panelFooterSubAreas}
            disabled={!checked6}
          />
          <Checkbox checked={checked6} onChange={e => handleValueArea(e, setChecked6, checked6, props.setPermanencia)} className='mr-3'/>
        </div> */}

           {/* <div className="relative">
              <label
                 className="text-xs absolute z-5 bg-white"
                 style={{ marginLeft: "0.75rem", marginTop: "-0.50rem" }}
              >
                 tiempo de permanencia
              </label>
           </div>
           <div className="flex align-items-center gap-3">
              <MultiSelect
                 //value={props.areaN3}
                 //options={subAreas}
                 //onChange={(e) => props.setAreaN3(e.value)}
                 optionLabel="nombre"
                 placeholder="Selecione un área"
                 filter
                 // showClear
                 className="flex w-full text-sm "
                 display="chip"
                 // selectedItemTemplate={selectedSubAreas}
                 panelFooterTemplate={panelFooterSubAreas}
                 disabled={!checked3 || !checked2 || !checked1}
              />
              <Checkbox
                 checked={checked6}
                 onChange={(e) => handleValueArea(e, setChecked6, checked6)}
                 disabled={!checked1 || !checked2}
                 className="mr-3"
              />
           </div> */}

           {/* <div className="flex">
              <div className="flex bg-pink-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3 mr-2">
                 <label className="flex text-xs text-pink-600 align-items-center white-space-nowrap">
                    3 años de trabajo
                    <i
                       className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1"
                       style={{ fontSize: "10px", paddingTop: "3px" }}
                    ></i>
                 </label>
              </div>

              <div className="flex bg-green-100 align-items-center w-min border-round-xl pl-2 pr-2 pt-1 pb-1 mt-3 mb-3 mr-2">
                 <label className="flex text-xs text-green-600 align-items-center white-space-nowrap">
                    2 años de trabajo
                    <i
                       className="flex pi pi-times-circle pi-lg text-indigo-600 pl-1"
                       style={{ fontSize: "10px", paddingTop: "3px" }}
                    ></i>
                 </label>
              </div>
           </div> */}



           {/* AGRAGADO POR MI XD*/}
           <div className=''>
                    <div className='flex flex-row justify-content-between align-items-center'>
                        <span style={{ fontWeight: '600', fontSize: '16px' }}>Selecione un Local</span>
                        <Button icon="pi pi-ellipsis-h" className='BOTON-TRES-Puntos p-button-outlined p-button-secondary' />
                        
                    </div>
                    <div>
                        <SideArea/>
                    </div>
                    <Divider />
                </div>
        
      
    </>
  );
};

export default CercoAreas;