import AuthUser from "../../../../../AuthUser";

const SB1Customer = () => {
    const { http } = AuthUser();

    const getClinic = async () => {
        try {
            const response = await http.get("bregma/contratos/get");
            if (response.data.error) {
                return [];
            } else {
                const transformedData = response.data.data.map((item) => ({
                    id: item.clinica_id,
                    logo: item.clinica.logo,
                    empresa: item.clinica.razon_social,
                    nombre: item.clinica.responsable,
                    sevicio_1: 'Áreas médicas',
                    sevicio_2: 'Capacitaciones',
                    estado: item.estado_registro === 'A' ? 1 : 2,
                }));

                return transformedData
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getClinic");
        }
    };

    const deleteClinic = async (id, showToast) => {
        try {
            const response = await http.delete(`clinica/delete/${id}`);
            if (response.data.resp) {
                showToast("success", "Completado", response.data.resp);
                return response.data.data;
            } else {
                showToast("error", "Error", response.data.error);
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getPackages");
        }
    }

    const getClinicID = async (id, ubicaciones, setData) => {
        //FALTA SINPLIFICAR LA CONDICIÓN GENERAL
        try {
            const response = await http.get(`clinica/get/${id}`);
            if (Object.keys(response.data).length > 0) {
                const empresa = response.data;
                const departamentoSeleccionado = ubicaciones.find(
                    (departamento) => departamento.id === empresa?.distrito?.provincia?.departamento?.id
                );
                const provinciaSeleccionada = departamentoSeleccionado?.provincias.find(
                    (provincia) => provincia.id === empresa?.distrito?.provincia?.id
                );
                const distritoSeleccionado = provinciaSeleccionada?.distritos.find(
                    (distrito) => distrito.id === empresa?.distrito?.id
                );
                setData(prevState => (
                    {
                        ...prevState,
                        GCEmpresa: {
                            ...prevState.GCEmpresa,
                            contrato_id: response.data.contrato[0].id,
                            tipo_cliente: response.data.contrato[0].tipo_cliente_id,
                            numero_documento: response.data.numero_documento,
                            razon_social: response.data.razon_social,
                            responsable: response.data.responsable || "",
                            nombre_comercial: response.data.nombre_comercial,
                            latitud: parseFloat(response.data.latitud) || -16.389571123416584,
                            longitud: parseFloat(response.data.longitud) || -71.55339135054068,
                            tipo_documento_id: response.data.tipo_documento.id,
                            departamento: departamentoSeleccionado,
                            provincia: provinciaSeleccionada,
                            distrito: distritoSeleccionado,
                            direccion: response.data.direccion,
                            ubicacion_mapa: response.data.ubicacion_mapa,
                            aniversario: response.data.aniversario,
                            rubro: response.data.rubro_id,
                            cantidad_trabajadores: response.data.cantidad_trabajadores,
                            actividad: response.data.años_actividad,
                            logo: response.data.logo,
                            estado_registro: response.data.estado_registro,
                        }
                    }
                ));
            }
            return false;
        } catch (error) {
            throw error;
        } finally {
            console.log("getClinicID");
        }
    };

    const getPackages = async () => {
        try {
            const response = await http.get("bregma/paquete/get");
            if (response.data.data) {
                return response.data.data;
            } else {
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getPackages");
        }
    };


    const postClinic = async (data, setData, showToast, navigate) => {
        await http.post("clinica/create", data).then((response) => {
            setData(prevState => ({
                ...prevState,
                GCEmpresa: {
                    ...prevState.GCEmpresa,
                    clinica_id: response.data.clinica_id || null,
                    contrato_id: response.data.contrato_id || null
                }
            }));
            if (response.data.resp) {
                showToast("success", "Completado", response.data.resp);
                navigate(`../ventas/gestiondeventas/empresa/${response.data.clinica_id}/editar`);
            } else {
                showToast("error", "Error", response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            console.log("postClinic");
        });
    }

    const putClinic = async (contrato_id, data, setData, showToast) => {
        await http.post(`clinica/update/${contrato_id}`, data).then((response) => {
            showToast(response.data.resp ? "success" : "error", response.data.resp ? "Completado" : "Error", response.data.resp || response.data.error)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            console.log("putClinic", data);
        });
    }

    return {
        getClinic,
        getClinicID,
        postClinic,
        putClinic,
        deleteClinic,

        getPackages,
    }
}

export default SB1Customer;