import React, { useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

const { webkitSpeechRecognition } = window;
const VoiceOFT = ({ onChange, placeholder, value, disabled }) => {
    const [recognition, setRecognition] = useState(null);

    const startRecognition = () => {
        const recognition = new webkitSpeechRecognition();
        recognition.lang = window.navigator.language;
        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onresult = (event) => {
            let tempTranscript = "";

            for (let i = event.resultIndex; i < event.results.length; ++i) {
                tempTranscript += event.results[i][0].transcript;
            }

            onChange((value ? value + " " : "") + tempTranscript);
        };

        recognition.onend = () => {
            console.log("El reconocimiento de voz se detuvo.");
            setRecognition(null);
        };

        recognition.start();
        setRecognition(recognition);
    };

    const stopRecognition = () => {
        if (recognition) {
            recognition.stop();
        }
    };

    return (
        <div className="flex flex-row w-full relative">
            <div className="flex flex-col w-full flex-1">
                <style>
                    {`
                  .Inputkjbjgksjigpsaoi{
                     width: 100%;
                     height: 50px;
                     padding: 10px 45px 10px 10px;
                  }
               `}
                </style>
                <InputTextarea
                    className="Inputkjbjgksjigpsaoi"
                    autoResize
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>
            <div
                className="flex justify-content-end absolute"
                style={{ bottom: '5px', right: '5px' }}
            >
                {recognition == null && (
                    <Button
                        style={{ height: "36px", width: '36px', padding: "5px" }}
                        label={"On"}
                        onClick={startRecognition}
                        disabled={disabled}
                    />
                )}
                {recognition !== null && (
                    <Button
                        style={{ height: "36px", width: '36px', padding: "5px" }}
                        label={"Off"}
                        onClick={stopRecognition}
                        disabled={disabled}
                    />
                )}

            </div>
        </div>
    );
};
export default VoiceOFT;
