import { Dropdown } from 'primereact/dropdown';
import React, { useRef, useState, useEffect } from 'react'
import AuthUser from '../../../AuthUser';

export default function CreateEmpresa({ data, setBregma, departamentos}) {
    const { http } = AuthUser();
    console.log("dep",departamentos);
    //const [ubicaciones, setUbicaciones] = useState([]);
    const [departamento, setDepartamento] = useState(null);
    const [provincia, setProvincia] = useState(null);
    const [provincias, setProvincias] = useState(null);
    const [distritos, setDistritos] = useState(null);
    const [distrito, setDistrito] = useState(null);
    const [prueba, setprueba] = useState([]);



    const handleChangeDepartamento = (e) => {
        setDepartamento(e.target.value);
        setProvincias(e.target.value === null ? null : e.target.value.provincias);
        setDistritos(null);
    };

    const handleChangeProvincia = (e) => {
        setProvincia(e.target.value);
        setDistritos(e.target.value === null ? null : e.target.value.distritos);
    };

    const handleChangeDistrito = (e) => {
        setDistrito(e.target.value);
        setBregma({ ...data, distrito: e.target.value, distrito_id: e.value === null ? null : e.value.id })
        console.log('daotsss taget ' , e.target.value)
        console.log('daotsss taget ' , e.value.id)
    };


    useEffect(() => {
        console.log("departamentos",departamentos)
        if (data?.distrito != null && departamentos != undefined) {
            let _departamento = departamentos?.filter(
                (departamento) =>
                    
                        //console.log(departamento.departamento, data?.distrito.provincia.departamento.departamento); 
                        departamento.departamento === data?.distrito.provincia.departamento.departamento
                    
            
                
            );
            
            setProvincias(_departamento[0]?.provincias);
            setDepartamento(_departamento[0]);
            let _provincia = _departamento[0]?.provincias.filter(
                (provincia) =>
                    provincia.provincia === data?.distrito.provincia.provincia
            );
            setProvincia(_provincia[0]);
            setDistritos(_provincia[0]?.distritos);
            let _distrito = _provincia[0]?.distritos.filter(
                (distrito) => distrito.distrito === data?.distrito.distrito
            );
            setDistrito(_distrito[0]);
        }
    }, [departamentos]);


    return (
        <div style={{width:'100%'}}>
            <div className="flex flex-column gap-3 mb-2 mt-1">
                <label htmlFor="departamento" className='font-bold block'>Departamento</label>
                <Dropdown
                    id="departamento"
                    className="w-full"
                    options={departamentos}
                    value={departamento}
                    onChange={handleChangeDepartamento}
                    optionLabel="departamento"
                    placeholder="Seleccione un Departamento"
                    filter
                />
            </div>
            <div className="flex flex-column gap-3 mb-2">
                <label htmlFor="provincia" className='font-bold block'>Provincia</label>
                <Dropdown
                    id="provincia"
                    className="w-full"
                    value={provincia}
                    options={provincias}
                    onChange={handleChangeProvincia}
                    optionLabel="provincia"
                    placeholder="Seleccine una Provincia"
                    filter
                />
            </div>
            <div className="flex flex-column gap-3 mb-2">
                <label htmlFor="distrito" className='font-bold block'>Distrito</label>
                <Dropdown
                    id="distrito"
                    className="w-full"
                    value={distrito}
                    options={distritos}
                    onChange={handleChangeDistrito}
                    optionLabel="distrito"
                    placeholder="Selecciona distrito"
                    filter
                />
            </div>
        </div>
    );
}