import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Table from '../../Table/Table';
import { Column } from 'primereact/column';
import imagencita from '../../../Images/Other 12.png'
import '../../../Style/GClientes.css'
import ModalGClientes from './ComponentesGClientes/ModalGClientes';

export default function GClientes() {
  const [visibleModal, setVisibleModal] = useState(false)
  const [tipoCliente, setTipoCliente] = useState('')
  const [tituloModal, setTituloModal] = useState('')
  const [cliente, setCliente] = useState(1)

  /* DATOS ESTATICOS */
  const [datosCorporativo, setDatosCorporativo] = useState(
    [{
    id: 1,
    TipoDoc: 'RUC',
    NumDoc: 45678901,
    RazonS: 'Alicorp',
    CodProforma: '160932AO025',
    Contacto: 'contacto',
    Locales: 'locales',
    Actividad: 'Pendiente',
    Detalles: 'detalles'
    },
    {
    id: 2,
    TipoDoc: 'RUC',
    NumDoc: 45678901,
    RazonS: 'Alicorp',
    CodProforma: '160932AO025',
    Contacto: 'contacto',
    Locales: 'locales',
    Actividad: 'Pendiente',
    Detalles: 'detalles'
      }]
  )
  const [datosClinica, setDatosClinica] = useState(
    [{
      id: 1,
      TipoDoc: 'RUC',
      NumDoc: 45678901,
      RazonS: 'Medical Roma',
      CodProforma: '160932AO025',
      Contacto: 'contacto',
      Locales: 'locales',
      Actividad: 'En espera',
      Detalles: 'detalles'
    }]
  )
  const [datosParticular, setDatosParticular] = useState(
    [{
      id: 1,
      TipoDoc: 'DNI',
      NumeroDoc: 45678901,
      Nombres: 'Jorge Tito',
      APaterno: 'Carranzas',
      AMaterno: 'Campo',
      CodProforma: '160932AO025',
      Contacto: 'contacto',
      Actividad: 'Pendiente',
      Detalles: 'Detalles',
    },
    {
      id: 2,
      TipoDoc: 'DNI',
      NumeroDoc: 45678901,
      Nombres: 'Sara',
      APaterno: 'Flores',
      AMaterno: 'Palma',
      CodProforma: '160932AO025',
      Contacto: 'contacto',
      Actividad: 'Pendiente',
      Detalles: 'Detalles'
    }]
  )
  const [datos, setDatos] = useState(datosCorporativo)

  /* TITULO MODAL-GCLIENTES */
  const handleLocales = () => {

    if (cliente === 1) {
      setTipoCliente('corporativo')
    } else {
      setTipoCliente('clinica')
    }
    setTituloModal('Locales')
    setVisibleModal(true)
  }
  const handleContacto = () => {

    if (cliente === 1) {
      setTipoCliente('corporativo')
    } else if (cliente === 2) {
      setTipoCliente('clinica')
    } else {
      setTipoCliente('particular')
    }
    setTituloModal('Contactos')
    setVisibleModal(true)
  }

  const saltoLTabla = (L1, L2) => {
    return (
      <span className='flex text-center' dangerouslySetInnerHTML={{ __html: `${L1}<br>${L2}` }} />
    )
  }

  return (
    <div className="flex flex-1 gap-3 h-full">

      <div className="flex flex-column gap-5 bg-white border-round-xl p-3 min-h-full">
        <div className="flex gap-2 align-items-center justify-content-between">
          <label htmlFor="" className="font-semibold text-base">
            Lista de clientes
          </label>
          <Button
            label=" Reporte"
            className="pi pi-file border-round-xl surface-0 text-green-500 border-green-500"
          />
        </div>
        <div className='flex flex-column gap-2'>
          {/* CLIENTE CORPOTARIVO */}
          <div
            className={`flex gap-3 py-2 px-3 align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-purple-300 ${cliente === 1 ? 'border-purple-100' : ''}`}
            onClick={() => {
              setCliente(1)
              setDatos(datosCorporativo)
            }}>
            <i className="pi pi-user text-xl bg-purple-100 border-round-lg p-2 text-purple-500" />
            <div>
              <label htmlFor="" className="text-purple-500 font-bold">Cliente corporativo</label>
              <p className='white-space-nowrap overflow-hidden text-overflow-ellipsis' style={{ width: '180px' }}>Alicorp, Grudas, Primax...</p>
            </div>
            {cliente === 1 ?
              <i className="pi pi-ellipsis-v text-400 font-semibold" /> : <div className='w-1rem'></div>
            }
          </div>
          {/* CLIENTE CLINICA */}
          <div
            className={`flex gap-3 py-2 px-3  align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-blue-300 ${cliente === 2 ? 'border-blue-100' : ''}`}
            onClick={() => {
              setCliente(2)
              setDatos(datosClinica)
            }}>
            <i className="pi pi-user text-xl bg-blue-100 border-round-lg p-2 text-blue-500" />
            <div>
              <label htmlFor="" className="text-blue-500 font-bold">Cliente clinica</label>
              <p className='white-space-nowrap overflow-hidden text-overflow-ellipsis' style={{ width: '180px' }}>Medical Roma, San Pablo...</p>
            </div>
            {cliente === 2 ?
              <i className="pi pi-ellipsis-v text-400 font-semibold" /> : <div className='w-1rem'></div>
            }

          </div>
          {/* CLIENTE PARTICULAR */}
          <div
            className={`flex gap-3 py-2 px-3  align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-green-300 ${cliente === 3 ? 'border-green-100' : ''}`}
            onClick={() => {
              setCliente(3)
              setDatos(datosParticular)
            }}
          >
            <i className="pi pi-user text-xl bg-green-100 border-round-lg p-2 text-green-500" />
            <div>
              <label htmlFor="" className="text-green-500 font-bold">Cliente particular</label>
              <p className='white-space-nowrap overflow-hidden text-overflow-ellipsis' style={{ width: '180px' }}>Pedro Casas Palma, Sara Flores</p>
            </div>
            {cliente === 3 ?
              <i className="pi pi-ellipsis-v text-400 font-semibold" /> : <div className='w-1rem'></div>
            }
          </div>
        </div>
        <div className='flex flex-column flex-1 align-items-center justify-content-center'>
          <img src={imagencita} alt="imagencita" />
          <label>lista de clientes</label>
        </div>
      </div>


      <div className="border-round-xl tablaGClientes w-9">
        {/* buscador */}
        <div className="flex-1 flex justify-content-end">
          <span className="xl:w-auto lg:w-auto md:w-full sm:w-full w-full p-input-icon-left " style={{ minWidth: "168px", background:"#F6F7FB",boxShadow:"none"}}>
            <i className="px-2 pi pi-search text-indigo-500" />
            <InputText
              placeholder="Buscador"
              className="border-round-xl xl:w-auto lg:w-auto md:w-full sm:w-full w-full"
            />
          </span>
        </div>
        {/* tabla */}
        {cliente !== 3 ?
          <Table className="mt-3" data={datos} paginator={false} header={false} headerEnabled={false}>
            <Column field='id' header="item" />
            <Column field='TipoDoc' header="Tipo de Doc." />
            <Column field='NumDoc' header="N. de Doc." />
            <Column field='RazonS' header="Razón Social" />
            <Column field='CodProforma' header={saltoLTabla('Código', 'Proforma')} />
            <Column header="Contacto" body={<i className="pi pi-info-circle text-green-500 text-xl" onClick={() => handleContacto()} />} />
            <Column header="Locales" body={<div className='flex flex-column' onClick={() => handleLocales()}> <i className="pi pi-map-marker text-purple-500 text-xs " /><i className="pi pi-map text-purple-500 text-sm" /></div>} />
            <Column field='Actividad' header="Actividad" />
            <Column header="Detalles" body={<i className="pi pi-folder-open text-purple-500 text-xl" />} />
          </Table>
          :
          <Table className="mt-3" data={datos} paginator={false} header={false} headerEnabled={false}>
            <Column field='id' header="item" />
            <Column field='TipoDoc' header={saltoLTabla('Tipo de', 'Doc.')} />
            <Column field='NumeroDoc' header="N. de Doc." />
            <Column field='Nombres' header="Nombres" />
            <Column field='APaterno' header={saltoLTabla('Apellido', 'Paterno')} />
            <Column field='AMaterno' header={saltoLTabla('Apellido', 'Materno')} />
            <Column field='CodProforma' header={saltoLTabla('Código', 'Proforma')} />
            <Column header="Contacto" body={<i className="pi pi-info-circle text-green-500 text-xl" onClick={() => handleContacto()} />} />
            <Column field='Actividad' header="Actividad" />
            <Column header="Detalles" body={<i className="pi pi-folder-open text-purple-500 text-xl" />} />
          </Table>
        }

      </div>
      <ModalGClientes visibleModal={visibleModal} setVisibleModal={setVisibleModal} tituloModal={tituloModal} tipoCliente={tipoCliente} />
    </div>

  );
}