import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import carpeta from "../../../../../Images/Icons/Mis_Locales/Areas/carpeta.svg";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";
import "../../../../../Style/modalAgregar.css"

const ModalAgregar = ({ setvisibleAgPersonal, visibleAgPersonal, setSource, source, target, setTarget, agregarPersonal, dataAsignar, area, rowData }) => {
   const [checked, setChecked] = useState(false);
   const [globalFilter, setGlobalFilter] = useState(null);

   const HideDialogAgregar = () => {
      setvisibleAgPersonal(false);
   };

   const paqueteCreateDialogFooter = (
      <div className="flex flex-wrap w-12 justify-content-end">
         <div className="flex w-5 bot   flex flex-nowrap flex-row">
            <Button
               type="button"
               label="Cancelar"
               className="delete w-full"
               onClick={() => {
                  HideDialogAgregar();
               }}
            />
            <Button
               type="button"
               label="Guardar"
               className="p-button-success w-full"
               onClick={() => {
                  let data = {
                     bregma_area_id: dataAsignar.bregma_area_id,
                     bregma_personales: target,

                     clinica_area_id: dataAsignar.clinica_area_id,
                     clinica_personales: target,

                     empresa_area_id: dataAsignar.empresa_area_id,
                     empresa_personales: target
                  }
                  console.log("data para enviar", data)
                  agregarPersonal(data);
                  setvisibleAgPersonal(false)
               }}
               style={{
                  backgroundColor: "#5555D8",
                  borderColor: "#5555D8",
               }}
            />
         </div>
      </div>
   );

   const onChange = (event) => {

      setSource(event.source);
      setTarget(event.target);
      console.log("source: ", source);
      console.log("targe: ", target);
   };
   const sourceHeader = () => {
      return (
         <div
            className="flex flex-wrap flex-column"
            style={{ padding: "0px", margin: "0px" }}
         >
            <p>Empleados sin asignación</p>
            <div className="w-full">
               <span className="p-input-icon-left w-full">
                  <i className="pi pi-search" />
                  <InputText
                     type="search"
                     onInput={(e) => setGlobalFilter(e.target.value)}
                     placeholder="Buscar nombre de trabajador"
                     className="w-full"
                  />
               </span>
            </div>
         </div>
      );
   };

   const targetHeader = () => {
      return (
         <div
            className="flex flex-wrap flex-column w-full"
            style={{ padding: "0px", margin: "0px" }}
         >
            <p>Empleados asignados a esta área:</p>
            <div className="w-full">
               <span className="p-input-icon-left w-full">
                  <i className="pi pi-search" />
                  <InputText
                     type="search"
                     onInput={(e) => setGlobalFilter(e.target.value)}
                     placeholder="Buscar nombre de trabajador              "
                     className="w-full"
                  />
               </span>
            </div>
         </div>
      );
   };

   const itemTemplate = (item) => {
      return (
         <p>
            {item.persona.nombres} {item.persona.apellido_paterno} {item.persona.apellido_materno}
         </p>
      );
   };

   return (
      <div className="card flex justify-content-center">
         <Dialog
            visible={visibleAgPersonal}
            style={{ width: '50vw' }}            
            className="scrollResponsive"
            footer={paqueteCreateDialogFooter}
            onHide={() => {
               HideDialogAgregar();
            }}
         >
            <div className="flex flex-wrap flex-column gap-2">
                     <div
                        className="flex justify-content-center align-items-center"
                        style={{
                           backgroundColor: "#EBEBFF",
                           width: "60px",
                           height: "60px",
                           borderRadius: "10px",
                        }}
                     >
                        
                        <div className="flex justify-content-center align-items-center claseEncabezado"
                        style={{marginLeft:"145px"}}>
                           <img src={carpeta} alt="" />
                           <h1 style={{marginLeft:"20px"}} className="per">Trabajadores</h1>
                        </div>
                     </div>
                     
                     <p className="tituloAgPer01">
                        Ordena a tu personal de la mejor manera.
                     </p>
                     <p className="tituloAgPer02">Área:</p>
                     <div className="soporte-tecnico-modal">
                     <p className="tituloAgPer03">
                     {rowData?.data?.nombre}
                     </p>
                     </div>
                     <div className="card">
                        <PickList
                           source={source}
                           target={target}
                           onChange={onChange}
                           itemTemplate={itemTemplate}
                           breakpoint="400px"
                           sourceHeader={sourceHeader()}
                           targetHeader={targetHeader()}
                           sourceStyle={{ height: '8rem' }}
                           targetStyle={{ height: '8rem' }}
                           sourceFilterPlaceholder="Buscar nombre de trabajador"
                           targetFilterPlaceholder="Buscar nombre de trabajador"
                        // onMoveToTarget={onMoveToTarget}
                        // onMoveToSource={onMoveToSource}
                        />
                     </div>
                  </div>
         </Dialog>
      </div>
   );
};
export default ModalAgregar;
