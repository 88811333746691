import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

const ModalRestablecerDatos = ({show, setShow, restablecer}) => {

    const headerContent = () => {
        return(
            <>
                Restablecer datos
            </>
        )
    }

    const footerContent = () => {
        return(
            <div className="flex justify-content-end">
                <Button label="cancelar" onClick={() => setShow(false)}/>
                <Button label="restablecer" onClick={() => {restablecer();setShow(false)}} />
            </div>
        )
    }

    return(
        <Dialog
            visible={show}
            onHide={setShow}
            header={headerContent}
            footer={footerContent}
        >
            Se restableceran todos los datos a la ultima informacion guardada
        </Dialog>
    )
}

export default ModalRestablecerDatos;