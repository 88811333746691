import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';

function ModalGenerarPdf({ show, setShow, generarPdf }) {

   const footer = () => {
      return (<>
         <Button
            onClick={() => setShow(false)}
            label="Cancelar" />
         <Button
            onClick={() => {
               generarPdf();
               console.log('Aceptado')
               setShow(false)
            }}
            label="Aceptar" />
      </>
      )
   }

   return (
      <Dialog
         header={
            <>
               <i className="pi pi-briefcase icon-create-proveedor" style={{ marginRight: '.5em' }}></i>
               <span>Generar</span>
            </>
         }
         visible={show}
         onHide={() => setShow(false)}
         footer={footer}>
         <div>¿Estás seguro que deseas generar el pdf?</div>
      </Dialog>
   )
}

export default ModalGenerarPdf;