import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import React, { useEffect, useState, useRef } from "react";
import AuthUser from "../../../../AuthUser";
import ModalCreateRecluta from "../../../../Components/RecursosHumanos/Reclutamiento/ModalCreateRecluta";
import ModalCredenciales from "../../../../Components/RecursosHumanos/Personal/ModalCredenciales";
import ModalEditarRecluta from "../../../../Components/RecursosHumanos/Reclutamiento/ModalEditarRecluta";
import ModalEliminar from "../../../../Components/RecursosHumanos/Personal/ModalEliminar";
import TablaCrud from "../../../../Components/Table/TablaCrud";
import ModalCarga from "../../../../Components/RecursosHumanos/Personal/ModalCarga";
import ModalReporte from "../../../../Components/RecursosHumanos/Personal/ModalReporte";
import "../../../../Style/CSS_tabla.css";

import CargarDatosDNI from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarDatosDNI";
import CargarData from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarData";
import AsignarServicioDialog from "../Personal/Dialog/DialogAsignarServicio";
import ModalTransferir from "../../../../Components/RecursosHumanos/Reclutamiento/ModalTransferir";
import AsignarServicioGrupal from "../Personal/Dialog/AsignarServicioGrupal";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";


function PageReclutamientoEmpresa() {
   const dispatch = useDispatch();

   const { http } = AuthUser();

   const toast = useRef(null);
   const [tipoDocumentos, setTipoDocumentos] = useState([]);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [personal, setPersonal] = useState([]);
   const [personaleliminar, setPersonaleliminar] = useState([]);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleCredenciales, setVisibleCredenciales] = useState(false);
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [visibleTransferir, setvisibleTransferir] = useState(false);
   const [visibleDialogDNI, setVisibleDialogDNI] = useState(false);
   const [visibleAsingar, setVisibleAsingar] = useState(false)
   const [visibleDialog, setVisibleDialog] = useState(false);
   const [visibleCarga, setVisibleCarga] = useState(false);
   const [visibleReporte, setVisibleReporte] = useState(false);
   const [tipoServicio, setTipoServicio] = useState([])
   const [roles, setRoles] = useState([]);
   const [visibleAsignarGrupal, setVisibleAsignarGrupal] = useState(false)
   const [rowClick, setRowClick] = useState('')
   const [selectedPersonal, setSelectedPersonal] = useState([])
   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [locales, setLocales] = useState([])
   const [sexos, setSexos] = useState([])
   const [selectPersonal, setSelectPersonal] = useState({
      id: 0,
      tipo_documento: "",
      numero_documento: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "2022-01-01",
      distrito_id: 2,
      celular: "",
      correo: "",
      rol: "",

   });

   /* const reject = () => {
      toast.current.show({
         severity: "info",
         summary: "Rechazada",
         detail: "No se realizo ninguna acción",
         life: 3000,
      });
   }; */

   /* INTEGRACIONES GET */

   // const getLocales = async (id) => {
   //    try {
   //       const response = await http.get(`empresa/local/get/${id}`)
   //       if(response?.data?.data){
   //          setLocales(response?.data?.data)
   //       }else if(response?.data?.error){
   //          showToast('error', 'Error', response?.data?.error)
   //       }
   //    } catch (error) {
   //       showToast('error', 'Error', error)
   //    };
   // };

   const getAllTipoDocumentos = async () => {
      try {
         const response = await http.get("/tipodocumentos/get")
         if (response?.data?.data) {
            setTipoDocumentos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      };
   };

   const subirArchivo = async (data) => {
      setIsLoading(true);
      console.log("entro a archivo", data);
      const formData = new FormData();
      formData.append("file", data);
      dispatch(startLoading())
      try {
         const response = await http.post("/empresa/personal/reclutamiento/import", formData)
         if (response?.data?.resp) {
            await Promise.all([
               setTipoDocumentos(response),
               getPersonal(),
               setVisibleDialog(false),
               setIsLoading(false)
            ])

            showToast("success", "Éxito", response?.data?.resp)

         } else if (response?.data?.error) {
            showToast('error', "Error", response.data.error)
         }
      } catch (error) {
         setIsLoading(false)
         setVisibleDialogDNI(false)
         showToast('error', "Error", "Contáctese con soporte técnico")
      } finally {
         dispatch(endLoading());
      };
   };

   const subirArchivoDNI = async (data) => {
      setIsLoading(true);
      console.log("entro a archivo DNI", data);
      const formData = new FormData();
      formData.append("file", data);
      dispatch(startLoading())
      try {
         const response = await http.post("/empresa/personal/reclutamiento/import/dni", formData)
         if (response?.data?.resp) {
            await Promise.all([
               setTipoDocumentos(response.data.data),
               getPersonal(),
               setVisibleDialogDNI(false),
               setIsLoading(false),
            ])

            showToast("success", "Éxito", "Personal asignado correctamente")

         } else if (response?.data?.error) {
            showToast("error", "Error", response?.data?.error)
         }
      } catch (error) {
         setVisibleDialogDNI(false)
         setIsLoading(false)
         showToast("error", "Fallo al asignar", "Contáctese con soporte técnico");
      } finally {
         dispatch(endLoading());
      };
   };

   const getRoles = async () => {
      try {
         const response = await http.get("roles/get")
         if (response?.data?.data) {
            setRoles(response?.data?.data);
         } else if (response?.data?.error) {
            showToast("error", 'Error', response?.data?.error)
         }
      } catch (error) {
         showToast("error", 'Error', error)
      };
   };

   const getRutinaEntrada = async () => {
      try {
         const response = await http.get("empresa/paquetes/servicios/entrada")
         if (response?.data?.data) {
            setTipoServicio(response.data?.data);
         } else if (response?.data?.error) {
            showToast("error", 'error', response?.data?.error)
         }
      } catch (error) {
         showToast("error", 'error', "Contáctese con soporte técnico")
      };
   };

   const getPersonal = async () => {
      try {
         const response = await http.get("empresa/personal/reclutamiento/get")
         if (response?.data?.data) {
            setPersonal(response.data?.data);
         } else if (response?.data?.error) {
            showToast("error", 'error', response?.data?.error)
         }
      } catch (error) {
         showToast("error", 'Error', "Contáctese con soporte técnico")
      };
   };

   const getGenero = async () => {
      try {
         const response = await http.get("sexos/get");
         if (response?.data?.data) {
            setSexos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      };
   };

   const asignarPaqueteGrupal = (data) => {
      dispatch(startLoading())
      http
         .post("empresa/personal/asignar/servicio", data)
         .then((response) => {
            console.log("RESPOSNE", response)
            if (response?.data?.resp) {
               showToast("success", "Envío grupal", "Grupo asignado correctamente")
            } else if (response?.data?.error) {
               showToast('error', 'Error', response.data.error)
            }
         })
         .catch((error) => {
            showToast("error", 'Error', "Contáctese con soporte técnico")
         })
         .finally(() => {
            dispatch(endLoading());
         });
   }

   const asignarPaquetes = (data) => {
      dispatch(startLoading())
      http
         .post("empresa/personal/asignar/perfil", data)
         .then((response) => {
            if (response?.data?.resp) {
               showToast("success", "Asignado correctamente", "Se asignó correctamente al personal");
               setVisibleAsingar(false);
            } else if (response?.data?.error) {
               showToast('error', 'Error', response?.data?.error)
            }
         })
         .catch((error) => {
            showToast('error', 'Error', "Contáctese con soporte técnico")
         })
         .finally(() => {
            dispatch(endLoading());
         });
   };

   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   const hideDialogPaqueteEdit = () => {
      setVisibleEdit(false);
   };

   const actionBodyAsignar = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-send"
               className="editar1"
               onClick={() => AsignarServicio(rowData)}
            />
         </React.Fragment>
      );
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => editPaquete(rowData)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />
         </React.Fragment>
      );
   };
   const transferir = (rowData) => {
      setvisibleTransferir(true);
      setSelectPersonal(rowData);
   };
   const actionBodyTransferir = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-qrcode"
               className="editar1"
               onClick={() => transferir(rowData)}
            />
         </React.Fragment>
      );
   };
   const AsignarServicio = (rowData) => {
      setVisibleAsingar(true);
      setSelectPersonal(rowData);
   };

   const editPaquete = (rowData) => {

      setSelectPersonal(rowData);
      setVisibleEdit(true);
      setSelectPersonal(rowData)
   };
   const actionBodyTemplateCredenciales = (rowData) => {
      return (
         <React.Fragment>

            <Button label="Enviar"
               icon="pi pi-send"
               className="enviar"
               onClick={() => credencialesPaquete(rowData)}
               link />
         </React.Fragment>
      );
   };
   const credencialesPaquete = (rowData) => {
      setPersonal(rowData);
      setVisibleCredenciales(true);
   };

   const deletePaquete = (rowData) => {
      console.log({ rowData });
      setPersonaleliminar(rowData);
      setVisibleEliminar(true);
   };

   const action = (rowData) => {
      return (
         <React.Fragment>
            <Button
               label="Enviar"
               icon="pi pi-send"
               className="enviar"
               onClick={() => credencialesPaquete(rowData)}
            />
         </React.Fragment>
      );
   };

   const openDialog = () => {
      setVisibleDialog(true)
   }
   const openDialogDNI = () => {
      setVisibleDialogDNI(true)
   }

   const handleObtenerPuesto = async () => {
      try {
         const response = await http
            .get("/empresa/cargo/get1")
         setData(response.data.data);
      } catch (error) {
         console.log("error", error);
      }

   };

   const handleItem = (rowData, index) => {
      return <div>{index.rowIndex + 1}</div>
   }

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getAllTipoDocumentos(),
            getPersonal(),
            getRoles(),
            getRutinaEntrada(),
            handleObtenerPuesto(),
            getGenero()
            // getLocales()
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
   }, []);

   // useEffect(() => {
   //    getLocales(selectedPersonal?.[0]?.locales[0]?.clinica_id);
   // }, [selectedPersonal])

   return (
      <div>
         <div className="flex flex-column">
            <h1 className="titulo">Reclutamiento</h1>
            <p className="subtitulo">En este módulo usted puede gestionar los postulantes a bregma</p>
         </div>

         <div className="movil_reclu_corp mt-4">
            <Toast ref={toast} />
            <TablaCrud
               style={{ border: "100px solid black" }}
               selectionMode={rowClick ? 'checkbox' : null}
               selection={selectedPersonal}
               onSelectionChange={(e) => setSelectedPersonal(e.value)}
               isHeader={true} // Muestra la barra de búsqueda en el encabezado
               name="Personal"
               tableNombre={`Lista de Personal`}
               descripcion={`A continuación, se visualiza la lista de personal.`}
               onInputSearch={(e) => setGlobalFilter(e.target.value)}
               valueGlobalFilter={globalFilter}
               value={personal}
               onClickLeftButton1={() => { setVisibleCreate(true); }}
               leftLabelButton1={" Crear"}
               // leftLabelButton4={" Reporte"}
               leftLabelButton2={" Asignar Servicio"}
               onClickLeftButton2={() => setVisibleAsignarGrupal(true)}
               leftLabelButton6={" Cargar de Datos DNI"}
               onClickLeftButton6={() => openDialogDNI()}
               leftLabelButton5={" Cargar datos"}
               onClickLeftButton5={() => openDialog()}
            >
               <Column selectionMode="multiple"></Column>
               <Column className="c1" field={"id"} header="Item" body={handleItem} style={{ borderLeft: "none" }} ></Column>
               <Column className="c1" field={"persona.tipo_documento.nombre"} header="Tipo_Doc"></Column>
               <Column className="c1" field={"persona.numero_documento"} header="# de Documento"></Column>
               <Column className="c1"
                  body={(rowData) => `${rowData.persona.nombres} ${rowData.persona.apellido_paterno} ${rowData.persona.apellido_materno}`}
                  header="Nombre Completos" ></Column>
               <Column className="c1" field={"persona.correo"} header="Correo"></Column>
               <Column className="c1" field={"persona.celular"} header="Celular"></Column>
               <Column className="c1" header="Enviar Servicio" body={actionBodyAsignar}></Column>
               <Column className="c1" field={"persona.tranferir"} header="Transferir" body={actionBodyTransferir} ></Column>
               <Column className="c1" header="Acciones" style={{ maxWidth: "8rem" }} body={actionBodyTemplate}></Column>
            </TablaCrud>

         </div>

         <ModalCreateRecluta
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            sexos={sexos}
            getPersonal={getPersonal}
            api="empresa/personal/reclutamiento/create"
            data={data}
         />


         <ModalEditarRecluta
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            data={data}
            api="empresa/personal/reclutamiento/update"
            dispatch={dispatch}
         />
         <ModalTransferir
            setvisibleTransferir={setvisibleTransferir}
            visibleTransferir={visibleTransferir}
            showToast={showToast}
            item={selectPersonal}
            data={data}
            getPersonal={getPersonal}
            api="empresa/personal/reclutamiento/estadoreclutamiento"
         />

         <ModalCredenciales
            visibleCredenciales={visibleCredenciales}
            setVisibleCredenciales={setVisibleCredenciales}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />

         <ModalEliminar
            visibleEliminar={visibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            personal={personal}
            setPersonal={personaleliminar}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api={"empresa/personal/reclutamiento/delete"}
         />

         <ModalCarga
            visibleCarga={visibleCarga}
            setVisibleCarga={setVisibleCarga}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />

         <ModalReporte
            visibleReporte={visibleReporte}
            setVisibleReporte={setVisibleReporte}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />
         <CargarDatosDNI
            visible={visibleDialogDNI}
            setVisible={setVisibleDialogDNI}
            subirArchivo={subirArchivoDNI}
            isLoading={isLoading}
         >
         </CargarDatosDNI>

         <CargarData
            visible={visibleDialog}
            setVisible={setVisibleDialog}
            subirArchivo={subirArchivo}
            isLoading={isLoading}
         >
         </CargarData>

         <AsignarServicioDialog
            /* visibleAsignar={visibleAsingar}
            setVisibleAsignar={setVisibleAsingar}
             */
            visibleAsignar={visibleAsingar}
            setVisibleAsignar={setVisibleAsingar}
            showToast={showToast}
            item={selectPersonal}
            /* tipoServicio={tipoServicio}
            getLocales={getLocales}
            locales={locales} */
            data={data}
            asignarPaquetes={asignarPaquetes}

         >
         </AsignarServicioDialog>

         <AsignarServicioGrupal
            visibleAsignar={visibleAsignarGrupal}
            setVisibleAsignar={setVisibleAsignarGrupal}
            asignarPaquetes={asignarPaqueteGrupal}
            locales={locales}
            item={selectedPersonal}
            examenes={selectedPersonal}
         />
      </div>

   );
}

export default PageReclutamientoEmpresa;