import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "primeicons/primeicons.css";
import React from "react";

const DeleteRolModal = ({
   rol,
   rolDelete,
   visibleDelete,
   setVisibleDelete,
   getRoles,
}) => {
   const handleDeleteRol = (id) => {
      rolDelete(rol.id);
      getRoles();
      setVisibleDelete(false);
   };

   const paqueteDeleteDialogFooter = (id) => (
      <div className="flex justify-content-evenly">
         <Button
            label="Cancelar"
            className="p-button-danger"
            style={{
               backgroundColor: "#FFFFFF",
               color: "blacK",
               borderColor: "#CBC9C9",
               width: "-webkit-fill-available"
            }}
            onClick={() => {
               setVisibleDelete(false);
            }}
         />
         <Button
            label="Eliminar"
            className="p-button-success"
            style={{ backgroundColor: "#5555D8", borderColor: "#5555D8",width: "-webkit-fill-available" }}
            onClick={() => {
               handleDeleteRol(id);
            }}
         />
      </div>
   );

   return (
      <div>
         <Dialog
            visible={visibleDelete}
            resizable={false}
            style={{ width: "500px", height: "340px" }}
            className="p-fluid eliminarLocal"
            header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Rol</i></p>}
            footer={paqueteDeleteDialogFooter(rol?.id)}
            onHide={() => {
               setVisibleDelete(false);
            }} 
         >
            <div className="flex flex-column gap-3">
                      <div className="flex flex-column w-full">
                            <p style={{ fontSize: "20px" }} className=" m-0 pt-5">¿Esta seguro de querer eliminar el Rol?</p>
                     </div>
            </div>
         </Dialog>
      </div>
   );
};

export default DeleteRolModal;
