import React, { useContext, useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { SalesContext } from "../../SalesContext/SalesContext";
//NO TOCAR EL CODIGO DE WALDO SIN ANTES SABER COMO FUNCIONA
export default function S2ContactDelete({ contacto_id, nombre, crud }) {
    console.log("contact",contacto_id)
    const { sales, setSales } = useContext(SalesContext);
    const [visible, setVisible] = useState(false);
    const sendDelete = async (e) => {
        e.preventDefault();
        const resp = await crud("eliminar");
        if(resp){
            setVisible(false);
        }
    }
    const handleClickDelete=()=>{
        setSales({
            ...sales,GCContacto:{...sales.GCContacto,"contacto_id":contacto_id
            }
        })
        setVisible(true)
    }
    return (
        <>
            <Button
                icon="pi pi-trash text-red-500" 
                className="bg-red-100 border-red-100 crudButon"
                onClick={() => handleClickDelete()}
            />
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar</i></p>}
                style={{ width: "550px" }}
                className=" empresas"
            >
                <form onSubmit={sendDelete} className="flex flex-column gap-7 mt-2">
                    <div className="flex flex-column w-full">
                        <p style={{ fontSize: "20px" }} className=" m-0 pt-3">¿Desea eliminar a <b>{nombre}</b>?</p>
                    </div>

                    <div className="flex w-full gap-5">
                        <Button
                            type="button"
                            onClick={() => setVisible(false)}
                            label="Cancelar"
                            icon="text-gray-500"
                            className="bg-gray-100 border-gray-100 text-gray-500 w-6"
                        />
                        <Button
                            type="submit"
                            icon="text-red-500"
                            className="bg-red-100 border-red-100 text-red-500 w-6"
                            label="Eliminar"
                        />
                        
                    </div>
                </form>
            </Dialog>
            {/* <Dialog
                        visible={visible}
                        sizable={false}
                        style={{ width: "550px", height: "260px" }}
                        className="p-fluid eliminarLocal"
                        header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar</i></p>}

                        footer={handleDeleteConfirmation()}

                        onHide={() => {
                            setVisible(false);
                        }}

                        >
                          
                        <div className="flex flex-column gap-3">
                            <div className="flex flex-column w-full">
                            <p style={{ fontSize: "20px" }} className=" m-0 pt-5">¿Desea eliminar a <b>{nombre}</b>?</p>
                            </div>
                        </div> 
                            
                    </Dialog> */}
        </>
    );
}


{/*


*/}