import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import AuthUser from "../../../AuthUser";
import axios from "axios";
import iconAgregar from "../../../Images/Reclutaminento/img/icon_agregarReclutamiento.svg";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";
import InputCalendar from "../../form/InputCalendar";
import { classNames } from 'primereact/utils';

export default function ModalCreateRecluta({
  visibleCreate,
  setVisibleCreate,
  showToast,
  tipoDocumentos,
  getPersonal,
  sexos,
  api,
  data,
}) {
  const { http } = AuthUser();
  const dispatch = useDispatch();
  // const options = [
  //   {name: 'Si soy Usuario', value:'0'},
  //   {name: 'No soy Usuario', value:'1'},
  // ];
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [value, setValue] = useState("");
  const initialValues = {
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "2022-01-01",
    distrito_id: 2,
    celular: "",
    correo: "",
    direccion: "",
    rol: "",
    empresa_cargo: "",
    sexo_id: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: async (data) => {
      dispatch(startLoading());

      const formDataSend = new FormData();
      for (var key in data) {
        formDataSend.append(key, data[key]);
      }
      formDataSend.append("tipo_documento_id", data?.tipo_documento?.id)
      formDataSend.append("rol_id", data?.rol?.id)
      formDataSend.append("empresa_cargo_id", data?.empresa_cargo?.id)
      console.log('fooormmrm', ...formDataSend)

      try {
        const response = await http.post(api, formDataSend)
        if (response?.data?.resp) {
          await Promise.all([
            HideDialogCreate(),
            getPersonal(),
          ]);
          console.log("creado correctamente", response?.data?.resp);
          showToast("success", "Personal creado", "Se creo correctamente el personal");

        } else if (response?.data?.error) {
          showToast("error", "Error", response?.data?.error)
        }

      } catch (error) {
        console.log("Hay un error", error)
        HideDialogCreate();
        showToast("error", "Error", "Contáctese con soporte técnico.")

      } finally {
        dispatch(endLoading());
      }
    },
    validate: (data) => {
      let errors = {};

      if (!data.sexo_id) {
        errors.sexo_id = "Sexo es requerido.";
      }
      if (!data.numero_documento) {
        errors.numero_documento = "Número de documento es requerido"
      }
      if (!data.tipo_documento) {
        errors.tipo_documento = "Tipo de documento requerido.";
      }
      if (!data.apellido_paterno) {
        errors.apellido_paterno = "Apellido paterno requerido"
      }
      if (!data.apellido_materno) {
        errors.apellido_materno = "Apellido materno requerido"
      }
      if (!data.nombres) {
        errors.nombres = "Nombres requeridos"
      }

      return errors;
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const HideDialogCreate = () => {
    setVisibleCreate(false);
    formik.resetForm();
    setValue(null);

  };

  const paqueteCreateDialogFooter = (
    <div className="flex w-full bot">
      <Button
        type="button"
        label="Cancelar"
        className="delete w-full"
        onClick={() => {
          HideDialogCreate();
        }}
      />
      <Button
        type="submit"
        label="Crear Usuario"
        className="p-button-success w-full"
        style={{
          backgroundColor: "#5555D8",
          borderColor: "#5555D8"
        }}
      />
    </div>
  );

  const getNombreCompleto = (numdocumento, tipoDocumento, correo, celular, rol, empresa_cargo) => {
    if (formik.values.tipo_documento !== "") {
      console.log("tipo de documento", formik.values.tipo_documento.nombre)
      if (formik.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)

          .then(res => {
            console.log("DATOS RENIEC", res.data.nombres, res.data.apellidoPaterno, res.data.apellidoMaterno);
            formik.setValues({
              tipo_documento: tipoDocumento,
              numero_documento: numdocumento,
              nombres: res.data.nombres,
              apellido_paterno: res.data.apellidoPaterno,
              apellido_materno: res.data.apellidoMaterno,
              fecha_nacimiento: "2022-01-01",
              distrito_id: 2,
              celular: correo,
              correo: celular,
              direccion: "",
              rol: rol,
              empresa_cargo,
              empresa_cargo: empresa_cargo,
            })

          })
          .catch((error) => {
            console.log("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
      } else if (formik.values.tipo_documento.nombre === "RUC") {
        axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)

          .then(res => {
            console.log("DATOS RENIEC", res.data.razonSocial);

            formik.setValues({
              tipo_documento: tipoDocumento,
              numero_documento: numdocumento,
              nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
              apellido_paterno: res.data.razonSocial.split(" ")[0],
              apellido_materno: res.data.razonSocial.split(" ")[1],
              fecha_nacimiento: "2022-01-01",
              distrito_id: 2,
              celular: celular,
              correo: correo,
              direccion: "",
              rol: rol,
              empresa_cargo: empresa_cargo,
            })


          })
          .catch((error) => {
            console.log("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
      }


    } else {
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }

  const handleFechaNacimientoChange = (date) => {
    console.log("Fecha seleccionada:", date);
    setFechaNacimiento(date.value);
    if (date.value instanceof Date) {
      const año = date.value.getFullYear();
      const mes = ('0' + (date.value.getMonth() + 1)).slice(-2); // Se agrega 1 al mes porque los meses van de 0 a 11
      const dia = ('0' + date.value.getDate()).slice(-2);

      // Formatear la fecha en el formato deseado (yyyy-dd-mm)
      const fechaFormateada = `${año}-${dia}-${mes}`;
      formik.setFieldValue("fecha_nacimiento", fechaFormateada);
    }
  };

  useEffect(() => {
    console.log("USUARIOOOO", value);
    if (value === "Si soy usuario") {
      formik.setFieldValue('usuario', "1")
    } else if (value === "No soy usuario") {
      formik.setFieldValue('usuario', "0")
    }

  }, [value]);

  return (
    <div>

      <form
        id="persona-form"
        className="persona-form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog
          visible={visibleCreate}
          style={{
            width: "450px",
            margin: "20px",
            height: "878px",
            borderRadius: '25px'
          }}
          appendTo={document.getElementById("persona-form")}
          header={
            <>
              <div className="flex align-items-center">
                <div style={{ borderRadius: "10px", backgroundColor: "#EBEBFF", width: "60px", marginRight: '7px' }} className="p-3">
                  <img src={iconAgregar} alt="" />
                </div>
                <h1 className="per">Crear Postulante</h1>
              </div>

            </>
          }
          dismissableMask
          className="p-fluid "
          footer={paqueteCreateDialogFooter}
          onHide={() => {
            HideDialogCreate();
          }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p className=" " style={{ marginBottom: '10px' }}>
                En esta seccion usted puede registrar tus datos para crear su
                personal
              </p>

              <p className="per1">Tipo de documento</p>
              <div className="flex w-full flex-column">
                <Dropdown
                  id="tipo_documento"
                  name="tipo_documento"
                  value={formik.values.tipo_documento}
                  onChange={formik.handleChange}
                  options={tipoDocumentos}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de documento"
                  className={classNames({ 'p-invalid': isFormFieldValid('tipo_documento') })}
                />
                {getFormErrorMessage('tipo_documento')}
              </div>
            </div>

            <div className="flex flex-1 w-full flex-column gap-2">
              <div className="flex flex-1 w-full gap-2">
                <InputText
                  id="numero_documento"
                  name="numero_documento"
                  value={formik.values.numero_documento}
                  onChange={formik.handleChange}
                  required
                  className={classNames({ 'p-invalid': isFormFieldValid('numero_documento') })}
                />
                <Button
                  label="Validar"
                  type="button"
                  style={{ background: "#07A34F", borderColor: "#07A34F" }}
                  onClick={() => getNombreCompleto(
                    formik.values.numero_documento,
                    formik.values.tipo_documento,
                    formik.values.correo,
                    formik.values.celular,
                    formik.values.rol,
                    formik.values.empresa_cargo,
                  )}
                  disabled={formik.values.tipo_documento?.id === 2}
                />
              </div>
              {getFormErrorMessage('numero_documento')}
            </div>

            <div>
              <label htmlFor="name">Nombres Completos</label>
              <InputText
                id="nombres"
                name="nombres"
                value={formik.values.nombres}
                className={classNames({ 'p-invalid': isFormFieldValid('nombres') })}
                onChange={formik.handleChange}
                required
                autoComplete="off"
              />
              {getFormErrorMessage('nombres')}
            </div>

            <div>
              <label htmlFor="lastname">Apellidos Paterno</label>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={formik.values.apellido_paterno}
                onChange={formik.handleChange}
                className={classNames({ 'p-invalid': isFormFieldValid('apellido_paterno') })} />
              {getFormErrorMessage('apellido_paterno')}
            </div>

            <div>
              <label htmlFor="lastname">Apellidos Materno</label>
              <InputText
                id="apellido_materno"
                name="apellido_materno"
                value={formik.values.apellido_materno}
                onChange={formik.handleChange}
                className={classNames({ 'p-invalid': isFormFieldValid('apellido_materno') })} />
              {getFormErrorMessage('apellido_materno')}
            </div>

            <label htmlFor="">Correo</label>
            <InputText
              id="correo"
              name="correo"
              value={formik.values.correo}
              onChange={formik.handleChange}
              className="w-full" />

            <label htmlFor="">Celular</label>
            <InputText
              id="celular"
              name="celular"
              value={formik.values.celular}
              onChange={formik.handleChange}
              className="w-full" />

            <p>Puesto de trabajo</p>
            <Dropdown
              id="empresa_cargo"
              name="empresa_cargo"
              value={formik.values.empresa_cargo}
              onChange={formik.handleChange}
              options={data}
              optionLabel="nombre"
              placeholder="Selecciona un Puesto de trabajo"
              className="w-full"
            //disabled={!value}
            />
          </div>
          <div className="flex flex-1 w-full flex-column gap-2">
            <div className="flex flex-1 w-full gap-2">

              <div>
                <p>Sexo</p>
                <Dropdown
                  id="sexo_id"
                  name="sexo_id"
                  value={formik.values.sexo_id}
                  onChange={formik.handleChange}
                  options={sexos}
                  optionLabel="nombre"
                  optionValue="id"
                  placeholder="Selecciona tu sexo"
                  className={classNames({ 'p-invalid': isFormFieldValid('sexo_id') })}
                />
                {getFormErrorMessage('sexo_id')}
              </div>

              <div>
                <p>Fecha de nacimiento</p>
                <InputCalendar
                  value={fechaNacimiento} onChange={handleFechaNacimientoChange}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
