import React, { useState, useEffect } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from "primereact/checkbox";
import './CSSTreTable.css';
import iconServiciosPS from '../../../../../Images/Operaciones/Servicios/icon-servicioPS.svg'
import { InputTextarea } from 'primereact/inputtextarea';

export default function DialogCoorporativo(props) {

   const [TipoDialog, setTipoDialog] = useState("");
   const [TipoPrecioReferencial, setTipoPrecioReferencial] = useState("");
   const [tipoValidacion, setTipoValidacion] = useState("");
   const [tipoDocumento, setTipoDocumento] = useState("");
   const [globalFilter, setGlobalFilter] = useState('');
   const [comentarios, setComentarios] = useState([]);
   const [sendData, setSendData] = useState(props.DataEnvio)
   const [busqueda, setBusqueda] = useState('');
   const [selectedDocumentos, setSelectedDocumentos] = useState([]);
   const [selectedNodeId, setSelectedNodeId] = useState(null);
   const [selectedNodeNombre, setSelectedNodeNombre] = useState(null);
   const [selectedNodeKeys, setSelectedNodeKeys] = useState([]);

   const handleBusquedaChange = (event) => {
      setBusqueda(event.target.value);
   };

   const filteredDocumentos = props.documentos?.filter((documento) =>
      documento.nombre.toLowerCase().includes(busqueda.toLowerCase())
   );



   const onDocumentoChange = (e) => {
      let _selectedDocumentos = [...selectedDocumentos];

      if (e.checked)
         _selectedDocumentos.push(e.value.id);
      else
         _selectedDocumentos = _selectedDocumentos?.filter(documento => documento !== e.value.id);

      setSelectedDocumentos(_selectedDocumentos);
   };

   function obtenerElementosConChildrenLlenosPrimerNivel(array) {
      const elementosConChildrenLlenos = array?.filter(elemento => elemento.children && elemento.children.length > 0);
      return elementosConChildrenLlenos;
   }
   const ArraySincapacitaciones = obtenerElementosConChildrenLlenosPrimerNivel(props.DataServicios);

   function obtenerElementosConChildrenVacios(array) {
      const elementosConChildrenVacios = [];

      function buscarElementosConChildrenVacios(arr) {
         for (let i = 0; i < arr.length; i++) {
            const elemento = arr[i];
            if (elemento.children && Array.isArray(elemento.children) && elemento.children.length === 0) {
               elementosConChildrenVacios.push(elemento);
            } else if (elemento.children && Array.isArray(elemento.children) && elemento.children.length > 0) {
               buscarElementosConChildrenVacios(elemento.children);
            }
         }
      }

      buscarElementosConChildrenVacios(array);

      return elementosConChildrenVacios;
   }
   const ArraySimplificado = obtenerElementosConChildrenVacios(ArraySincapacitaciones);

   function sumarPrecioReferencial(array1, selectedNodeKeys) {
      let total = 0;

      // Iterar sobre las claves del objeto selectedNodeKeys
      for (let key in selectedNodeKeys) {
         // Verificar si la clave existe en el array1
         const matchingItem = array1.find(item => item && item.key === key);
         if (matchingItem) {
            total += parseFloat(matchingItem.data.precio_referencial_mensual_venta);
         }
      }

      return total;
   }
   const Total = selectedNodeKeys ? sumarPrecioReferencial(ArraySimplificado, selectedNodeKeys) : 0;

   function obtenerIndices(array) {
      const exclusiones = ["areas_medicas", "examenes"]; // Claves a excluir
      const indices = [];
      for (const key in array) {
         if (Object.hasOwnProperty.call(array, key) && !exclusiones.includes(key)) {
            indices.push(key);
         }
      }
      return indices;
   }
   const ArraySoloIndices = obtenerIndices(selectedNodeKeys);

   // OBTENER VALORES DE LOS ARRAY
   const GuardarValores = (nuevosItemsSeleccionados, soloindices) => {
      const nuevosPerfiles = [...props.DataGeneral];

      const perfilEncontrado = nuevosPerfiles.find(perfil => perfil.Id === selectedNodeId);

      if (perfilEncontrado) {
         if (perfilEncontrado.hasOwnProperty(TipoDialog)) {
            perfilEncontrado[TipoDialog][0].ItemsSeleccionados = nuevosItemsSeleccionados;
            perfilEncontrado[TipoDialog][0].OnlyKeys = soloindices;
            perfilEncontrado[TipoDialog][0].comentario = comentarios;
            if (perfilEncontrado[tipoValidacion].length > 0) {
               let auxValidaciones = perfilEncontrado[tipoValidacion].map(auxValidacion => {
                  const clavesFiltradas = Object.keys(auxValidacion.selectedNodeKeys).filter(clave => perfilEncontrado[TipoDialog][0].OnlyKeys.includes(clave));
                  const nuevoObjeto = {};
                  clavesFiltradas.forEach(clave => {
                     nuevoObjeto[clave] = auxValidacion.selectedNodeKeys[clave];
                  });
                  auxValidacion.selectedNodeKeys = nuevoObjeto
                  return auxValidacion;
               })
               perfilEncontrado[tipoValidacion] = auxValidaciones;
            }

         } else {
            console.log("El campo especificado no existe en el perfil");
         }
      } else {
         console.log("Perfil no encontrado");
      }

      props.setDataGeneral(nuevosPerfiles);
      props.setServiciosValidacion(props.filtrarDatosSeleccionados(props.DataServicios, soloindices))

   };
   const GuardarPrecioReferencial = (NuevoTotal) => {
      const nuevosPerfiles = [...props.DataGeneral];

      const perfilEncontrado = nuevosPerfiles.find(perfil => perfil.Id === selectedNodeId);

      if (perfilEncontrado) {
         if (perfilEncontrado.hasOwnProperty(TipoPrecioReferencial)) {
            perfilEncontrado[TipoPrecioReferencial] = NuevoTotal;
         } else {
            console.log("El campo especificado no existe en el perfil");
         }
      } else {
         console.log("Perfil no encontrado");
      }
      props.setDataGeneral(nuevosPerfiles);
   }

   const GuardarDocumentos = (documentos) => {
      const nuevosPerfiles = [...props.DataGeneral];

      const perfilEncontrado = nuevosPerfiles.find(perfil => perfil.Id === selectedNodeId);

      if (perfilEncontrado) {
         if (perfilEncontrado.hasOwnProperty(tipoDocumento)) {
            perfilEncontrado[tipoDocumento] = documentos;
         } else {
            console.log("El campo especificado no existe en el perfil");
         }
      } else {
         console.log("Perfil no encontrado");
      }
      props.setDataGeneral(nuevosPerfiles);
   }
   const renderPrecioColumnBody = (node) => (
      <>
         {node?.children?.length !== 0 || (node?.data?.precio_mensual === '' || node?.data?.precio_anual === '') ? (
            <span>--</span>
         ) : (
            <span className="text-center">
               S/{node?.data?.precio_referencial_mensual_venta}
            </span>
         )}
      </>
   );
   const bodyTemplate = (e, rowData) => {
      return (
         <div className="custom-scrollbar">
            <InputTextarea
               value={sendData?.[rowData?.rowIndex]}
               onChange={(e) => { handleComentarioChange(e, rowData.rowIndex) }}
               autoResize
               placeholder="Comente aquí"
               rows={1} cols={25}
            />
         </div>
      );
   };


   const handleComentarioChange = (e, rowIndex) => {
      setSendData(prevState => ({
         ...prevState,
         [rowIndex]: e.target.value // Asigna la descripción a la fila correspondiente
      }));
      const filteredData = Object.entries(sendData)
         .filter(([key, value]) => !isNaN(parseInt(key)))
         .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
         }, {});
      setComentarios(filteredData);
   }

   const HeaderDialog = (
      <div className="p-d-flex p-jc">
         <div className="barras-espaciado h-auto p-d-flex p-flex-column p-jc-start p-flex-wrap" style={{ fontFamily: 'Montserrat', width: '100%', display: 'flex' }}>
            <div className="icono-superior" style={{ width: '70px', height: '60px', borderRadius: '10px', backgroundColor: '#EBEBFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <img className="icon-01" src={iconServiciosPS} alt="Logo" />
            </div>
            <div className="w-full h-auto p-d-flex p-ai-start p-jc-start" style={{ marginTop: '13px', fontSize: '18px', color: '#5555D8', display: 'flex', alignItems: 'center' }}>
               <p style={{ fontWeight: '700', marginLeft: '7px' }}>Seleccionar servicios - {TipoDialog}</p>
            </div>
         </div>
         <div className="w-full columna01">
            <div className="w-full h-auto p-d-flex p-ai-start p-jc-start" style={{ marginTop: '10px', fontSize: '14px', color: '#344054', fontWeight: '500' }}>Perfil:</div>
            <div className="w-full p-d-flex p-ai-start p-jc-start" style={{ marginTop: '3px', height: '39px', borderRadius: '7px', backgroundColor: '#F6F2FC', fontSize: '14px', color: '#5555D8', fontWeight: '600', padding: '11px' }}>{selectedNodeNombre}</div>
         </div>

      </div>
   );



   const StyleBotonCancelar = {
      width: '168px',
      height: '44px',
      borderRadius: '5px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #D0D5DD',
      color: '#344054',
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: '500'
   }

   const StyleBotonGuardar = {
      width: '168px',
      height: '44px',
      borderRadius: '5px',
      backgroundColor: '#5555D8',
      border: '1px solid #5555D8',
      color: '#ffffff',
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: '500'
   }
   const FooterDialog = (
      <div className="w-full h-auto flex align-items-center justify-content-end gap-2">
         <div className="flex align-items-center" style={{ marginRight: 'auto' }}>
            <p className="text-right" style={{ width: 'auto', fontWeight: '600', margin: '0' }}>
            <span style={{ marginRight: '0.5rem', fontWeight: '400' }}>Total: S/. {Total.toFixed(2)}</span>
            </p>
         </div>
         <div className="botones-container" style={{ display: 'flex', marginTop: '1rem' }}>
            <button
               style={{ ...StyleBotonCancelar, marginRight: '0.5rem' }}
               onClick={() => {
                  props.setVisible(false);
                  setSelectedNodeKeys(null);
                  GuardarValores([], []);
                  GuardarPrecioReferencial(0);
               }}
            >
               Cancelar
            </button>
            <button
               style={{ ...StyleBotonGuardar }}
               onClick={() => {
                  props.setVisible(false);
                  GuardarValores(selectedNodeKeys, ArraySoloIndices);
                  GuardarPrecioReferencial(Total);
                  GuardarDocumentos(selectedDocumentos);
               }}
            >
               Guardar
            </button>
         </div>
      </div>

   )
   useEffect(() => {
      if (props.TipoDialog) {
         switch (props.TipoDialog) {
            case 1:
               setTipoDialog("Entrada");
               setTipoPrecioReferencial("PrecioReferencialEntrada");
               setTipoDocumento("documentosEntrada")
               setTipoValidacion("validacionesEntrada")
               break;
            case 2:
               setTipoDialog("Rutina");
               setTipoPrecioReferencial("PrecioReferencialRutina");
               setTipoDocumento("documentosRutina")
               setTipoValidacion("validacionesRutina")
               break;
            case 3:
               setTipoDialog("Salida");
               setTipoPrecioReferencial("PrecioReferencialSalida");
               setTipoDocumento("documentosSalida")
               setTipoValidacion("validacionesSalida")
               break;
            default:
               setTipoDialog("");
         }
      }
      if (props.rowData && props.visible) {
         setSelectedNodeId(props.rowData.Id);
         setSelectedNodeNombre(props.rowData.NameTipoPerfil);
      }

      if (TipoDialog && (props.DataGeneral[selectedNodeId - 1]?.[TipoDialog]?.[0]?.ItemsSeleccionados)) {
         setSelectedNodeKeys(props.DataGeneral[selectedNodeId - 1][TipoDialog][0].ItemsSeleccionados);
      } else {
         setSelectedNodeKeys(null); // Establecer selectedNodeKeys como null si TipoDialog no está definido o si no hay entrada correspondiente en props.DataGeneral
      }
      if (TipoDialog && (props.DataGeneral[selectedNodeId - 1]?.[tipoDocumento]?.length > 0)) {
         setSelectedDocumentos(props.DataGeneral[selectedNodeId - 1][tipoDocumento]);
      } else {
         setSelectedDocumentos([])
      }
   }, [TipoDialog, props.DataGeneral, props.TipoDialog, props.rowData, props.visible, selectedNodeId, tipoDocumento]);



   return (
      <div>
         <Dialog
            header={HeaderDialog}
            footer={FooterDialog}
            visible={props.visible}
            style={{ width: '90%', height: '90%' }}
            onHide={() => props.setVisible(false)}
         >
            <div className="responsive-container">
               <div className="flex-container">
                  {/* Columna izquierda */}
                  <div className="column-left">
                     <h1>Servicios:</h1>
                     <div className="search-input-container">
                        <span className="p-input-icon-left">
                           <i className="pi pi-search" />
                           <InputText
                              className="search-input"
                              placeholder="Buscar Servicio"
                              onInput={(e) => setGlobalFilter(e.target.value)}
                           />
                        </span>
                     </div>

                     <div className="p-3 mt-2 mb-2 flex justify-content-around bg-gold font-type">Seleccione los elementos de la tabla para ingresar alguna RESTRICCIÓN</div>

                     <div className="tree-table-container">
                        <TreeTable
                           className="custom-tree-table"
                           globalFilter={globalFilter}
                           value={ArraySincapacitaciones}
                           selectionMode="checkbox"
                           selectionKeys={selectedNodeKeys}
                           onSelectionChange={(e) => setSelectedNodeKeys(e.value)}
                        >
                           <Column field="nombre" header="Nombre" expander></Column>
                           <Column field="data.pVenta" header="Precio Referencial" body={renderPrecioColumnBody} bodyStyle={{ textAlign: 'center' }} style={{ textAlign: 'center' }}></Column>
                           <Column field="comentario" header="Comentario" body={(e, rowData) => bodyTemplate(e, rowData)}></Column>
                        </TreeTable>
                     </div>
                  </div>

                  {/* Columna derecha */}
                  <div className="column-right">
                     <section>
                        <h1>Documentos:</h1>
                        <div className="search-document-container">
                           <span className="p-input-icon-left">
                              <i className="pi pi-search" />
                              <InputText
                                 className="search-input-document"
                                 placeholder="Buscar Documento"
                                 value={busqueda}
                                 onChange={handleBusquedaChange}
                              />
                           </span>
                        </div>
                        <ScrollPanel className="scroll-panel">
                           {filteredDocumentos?.map((documento) => (
                              <div key={documento.id} className="checkbox-container">
                                 <Checkbox
                                    inputId={documento.id}
                                    name="documento"
                                    value={documento}
                                    onChange={onDocumentoChange}
                                    checked={selectedDocumentos?.some((item) => item === documento.id)}
                                    className="checkbox-item" // Agrega una clase para el checkbox
                                 />
                                 <label htmlFor={documento.id} className="ml-2 documento-label">
                                    {documento.nombre}
                                 </label>
                              </div>
                           ))}
                        </ScrollPanel>
                     </section>
                  </div>
               </div>
            </div>
         </Dialog>

      </div>
   );
}