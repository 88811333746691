import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import AuthUser from '../../../../AuthUser';
import ModalExamenComponent from '../../../../Components/RecursosHumanos/Reclutamiento/ModalExamen';
import '../../../../Style/TablaCrudd2.css';
import Logo from '../../../../Images/ICONOFOLDER.svg';
import BotonDescargar from '../../../../Components/RecursosHumanos/Reclutamiento/BotonDecargar';
import BotonEnviar from '../../../../Components/RecursosHumanos/Reclutamiento/BotonEnviar';
import BotonEstado from '../../../../Components/RecursosHumanos/Reclutamiento/BotonEstado';
import BotonPlan from '../../../../Components/RecursosHumanos/Reclutamiento/BotonPlan';
import { Checkbox } from 'primereact/checkbox';
import comments from '../../../../Images/Comments.svg';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import atendido from '../../../../Images/atendido.png';
import programado from '../../../../Images/programado.png';
import enproceso from '../../../../Images/enproceso.png';
import upload from '../../../../Images/upload.svg';
import { Dropdown } from 'primereact/dropdown';

const PageIngreso = () => {
  const { http } = AuthUser();
  const [personal, setPersonal] = useState([]);
  const [visibleExamen, setVisibleExamen] = useState(false);
  const [observado, setObservado] = useState(false);

  useEffect(() => {
    getPersonal();
  }, []);

  useEffect(() => {
    const hasObservado = personal.some((item) => item.estado === 'Observado');
    setObservado(hasObservado);
  }, [personal]);

  const getPersonal = () => {
    http
      .get('empresa/personal/show/get')
      .then((response) => {
        console.log('personal', response.data?.data);
        setPersonal(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionTemplateExamen = (rowData) => (
    <Button
      icon="pi pi-send"
      label="Enviar exámen médico"
      onClick={() => setVisibleExamen(true)}
      className="BOTON_EnVIARExaMEN p-button-text"
      style={{ height: '32px' }}
    />
  );

  /*Subida de aarchivos */
  const [visible, setVisible] = useState(false);

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const onUpload = () => {
    // Lógica para manejar la subida de archivos
  };




  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const toggleSelectItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const toggleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedItems([]);
    } else {
      const selectedIds = [1, 2, 3, 4, 5, 6, 7, 8]; // Agrega los IDs que desees
      setSelectedItems(selectedIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };
  const [selectedPlan, setSelectedPlan] = useState(null);
  const plan = [
      { name: 'Plan gen familia', code: 'pgfa' },
      { name: 'Plan gen prime', code: 'pgpr' },
      { name: 'Plan gen plus', code: 'pgpl' },
      { name: 'Plan gen ultra', code: 'pgul' }
  ];

  return (
    <div className="gestion-ingreso">
      <div className="flex flex-column" style={{ width: "477px", marginBottom: "34px" }}>
        <h1 className="titulo" style={{ marginBottom: "10px" }}>Gestion de trazabilidad</h1>
        <p className="subtitulo">Gestiona la trazabilidad de tus pacientes.</p>
      </div>
      <div className="CONTENEDORDELATABLAINGRESO flex flex-column">

        <div className='Contenedor-encabezado'>
          <div className="flex flex-wrap justify-content-between align-items-center">
            <div>
              <div className="CONTENEDORICONOINGRESO flex">
                <img src={Logo} alt="" />
              </div>

              <div>
                <span style={{ fontWeight: '700', fontSize: '18px', color: '#5555D8' }}>Lista de Pacientes</span>
              </div>
            </div>

            <div className="flex flex-row">
              <div>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" style={{ fontSize: '19px', color: '#4545BC' }} />
                  <InputText
                    placeholder="Buscador"
                    style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(69, 69, 188, 0.8)', height: '43px', borderRadius: '13px', width: '620px', fontFamily: 'Montserrat', border: '1px solid #eeeeee' }}
                  />
                </span>
              </div>

            </div>
          </div>
          <div className='contenedor-botones flex justify-content-between'>

            {/* <div className='botones-acciones flex gap-2'>
              <div>
                <Button label="Enviar examen médico" className="BOTONAPLCIARFILTROS" style={{ width: '200px' }} />
              </div>
              <div>
                <Button label="Seleccionar todos" className="BOTONAPLCIARFILTROS" onClick={toggleSelectAll} />
              </div>
            </div> */}

            <div className='botones-filtros flex gap-2' style={{marginLeft:'57%'}}>
              {/* <div>
                <Button label="Ver observados" className="BOTONAPLCIARFILTROS" />
              </div>
              <div>
                <Button label="Ver por resultado" className="BOTONAPLCIARFILTROS" />
              </div> */}
              <div className="card flex justify-content-center">
                <Dropdown value={selectedPlan} onChange={(e) => setSelectedPlan(e.value)} options={plan} optionLabel="name" 
                placeholder="Plan gen plus" className="w-full md:w-14rem" />
              </div>
              <div className="card flex justify-content-center">
                <Dropdown value={selectedPlan} onChange={(e) => setSelectedPlan(e.value)} options={plan} optionLabel="name" 
                placeholder="Ver por plan" className="w-full md:w-14rem" />
              </div>
              <div className='aplicar-filtros flex p-l-1'>
                <div>
                  <Button label="Aplicar filtros" className="BOTONAPLCIARFILTROS" />
                </div>
              </div>
            </div>

          </div>
        </div>

        <table className="TABLACRUDINGRESO">
          <thead>
            <tr>
              <th className='item' style={{width:'90px'}}>Item</th>
              <th className='reclutado'>Nombre y Apellidos</th>
              <th className='comentario'>DNI</th>
              <th className='estado'>Fecha de atención</th>
              <th className='estado-resultado'>Plan Elegido</th>
              <th className='interconsulta'>Estado</th>
              <th className='resultados'>Acción</th>
            </tr>
          </thead>
          <tbody>
            {personal.map((item) => (
              <tr key={item.id}>
                <td className='id' style={{ borderLeft: '0px' }}><Checkbox
                  style={{ marginRight: '20px' }}
                  checked={selectedItems.includes(item.id)}
                  onChange={() => toggleSelectItem(item.id)}
                />
                  {item.id}</td>
                <td className='nombre'>{item.persona.nombres}</td>
                <td><img src={comments} alt="" /></td>
                <td><BotonDescargar /></td>
                <td>{actionTemplateExamen(item)}</td>
                <td>
                  <BotonEstado estado="Apto" />
                </td>
                <td>
                  {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}
                </td>
              </tr>
            ))}
            <tr key={1}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(1)}
                onChange={() => toggleSelectItem(1)} />  */}
                1</td>
              <td className='nombre' >Arturo Juan Ruiz</td>
              <td> 75298463 </td>
              <td>15/05/2023</td>
              <td>
                <BotonPlan plan="Plan gen plus" />
              </td>              
              <td><div><img src={enproceso} alt="" style={{ width: '120px' }} /></div>{/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>


            <tr key={2}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(2)}
                onChange={() => toggleSelectItem(2)} />  */}
                2</td>
              <td className='nombre' >Marcelo Piero Zalazar</td>
              <td> 75298463</td>
              <td>15/05/2023</td>
              
              <td>
                <BotonPlan plan="Plan gen prime" />
              </td>
              <td>              
                <div><img src={programado} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}
              </td>
              <td>
              <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>


            <tr key={3}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(3)}
                onChange={() => toggleSelectItem(3)} /> */}
                 3</td>
              <td className='nombre' >Enrique Peña nieto</td>
              <td> 75298463</td>
              <td>15/05/2023</td>
              <td>
                <BotonPlan plan="Plan gen plus" />
              </td>
              <td><div><img src={atendido} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              
              <td>
                <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>

            <tr key={4}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(4)}
                onChange={() => toggleSelectItem(4)} />  */}
                4</td>
              <td className='nombre' >Susana Ortega</td>
              <td> 75298463</td>
              <td>15/05/2023</td>
              <td>
                <BotonPlan plan="Plan gen familia" />
              </td>
              <td><div><img src={enproceso} alt="" style={{ width: '120px' }} /></div>{/* codigo orginal {actionTemplateExamen} */}</td>
              
              <td>
                <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>


            <tr key={5}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(5)}
                onChange={() => toggleSelectItem(5)} /> */}
                 5</td>
              <td className='nombre' >Lucia Esmeralda Guizado</td>
              <td> 75298463</td>
              <td>15/05/2023</td>
              <td>
                <BotonPlan plan="Plan gen ultra" />
              </td>              
              <td>              
                <div><img src={programado} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}
              </td>
              <td>
              <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>


            <tr key={6}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(6)}
                onChange={() => toggleSelectItem(6)} /> */}
                 6</td>
              <td className='nombre' >Ester Maldonado Pereira</td>
              <td> 75298463</td>
              <td>15/05/2023</td>
              <td>
                <BotonPlan plan="Plan gen plus" />
              </td>
              <td><div><img src={atendido} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>

            <tr key={7}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(7)}
                onChange={() => toggleSelectItem(7)} />  */}
                7</td>
              <td className='nombre' >Ester Maldonado Pereira</td>
              <td> 75298463</td>
              <td>15/05/2023</td>
              <td>
              <BotonPlan plan="Plan gen familia" />
              </td>
              <td>
              <div><img src={atendido} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}
              </td>
              <td>
              <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>


            <tr key={8}>
              <td className='id' style={{ borderLeft: '0px' }}>
                {/* <Checkbox style={{ marginRight: '20px' }} checked={selectedItems.includes(8)}
                onChange={() => toggleSelectItem(8)} />  */}
                8</td>
              <td className='nombre' >Arturo Juan</td>
              <td> 22222222</td>
              <td>15/05/2023</td>
              <td>
                <BotonPlan plan="Plan gen prime" />
              </td>
              <td>
              <div><img src={enproceso} alt="" style={{ width: '120px' }} /></div>{/* codigo orginal {actionTemplateExamen} */}
              </td>
              <td>
              <Button className='abrir_popup' onClick={showDialog} style={{ backgroundColor: 'transparent', border: '0px', boxShadow: '0 0 0 white' }}>
                  <div>
                  <BotonEnviar />
                  </div>
                </Button>
                {/* codigo original 
                    {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}*/}
              </td>
            </tr>


          </tbody>
        </table>
        <style>
          .id{
            
          }
        </style>
      </div>
      <ModalExamenComponent
        visibleExamen={visibleExamen}
        setVisibleExamen={setVisibleExamen}
      />
      <Dialog
        header="Subir Archivos"
        visible={visible}
        onHide={hideDialog}

        modal
      >
        <div>
          <h4>Selecciona un archivo para subir:</h4>
          <FileUpload
            name="demo"
            url="/upload" // Cambia esto a la URL de tu servidor de carga de archivos
            onUpload={onUpload}
            multiple
          />
        </div>
      </Dialog>
    </div>
  );
}
export default PageIngreso;