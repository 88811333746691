

export const DatosEmpresa = {
    getEmpresaData(dataShow) {
        return [
            {
                image: 'https://cdn.discordapp.com/attachments/943890590641303642/1152019519611613214/Casita.png',
                name: 'Razón Social',
                description: dataShow?.razon_social || 'No hay razon social',
            },
            {
                image: 'https://cdn.discordapp.com/attachments/943890590641303642/1152019519368339466/Credit-Card.png',
                name: 'RUC',
                description: dataShow?.numero_documento || 'No hay documento',
            },
            {
                image: 'https://cdn.discordapp.com/attachments/943890590641303642/1152019519083118632/Mobile.png',
                name: 'Celular',
                description: dataShow?.celulares != null ? dataShow?.celulares[0]?.celular || 'Sin número' : 'Sin número',
            },
            {
                image: 'https://cdn.discordapp.com/attachments/943890590641303642/1152015621278670848/whatsapp.png',
                name: 'WhatSapp',
                description: dataShow?.whatsapp || 'No hay whatSapp',
            },
            {
                image: 'https://cdn.discordapp.com/attachments/943890590641303642/1152019518693060618/Vector.png',
                name: 'Sitio Web',
                description: dataShow?.sitio_web || 'No hay sitio web',
            },
        ];
    },

    getDatos(datashow) {
        return Promise.resolve(this.getEmpresaData(datashow));
    },
};

