import React, { useContext, useEffect, useState } from 'react';
import { InputNumber } from "primereact/inputnumber";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { MGContext } from '../../MedicinaGeneral/MGProvider';
import TriajeAntFamAdd from "../../../Components/Triaje/AntFam/TriajeAntFamAdd";
import AuthUser from "../../../AuthUser";
import '../../../Style/Triaje/AntecedenteFam/triaje_ant_fam.css'

const PageTriajeAntFam = () => {

   const { http } = AuthUser();

   const { antFamContext, setAntFamContext, selectedPatologiasP, setSelectedPatologiasP,
      selectedPatologiasM, setSelectedPatologiasM,
      selectedPatologiasH, setSelectedPatologiasH,
      selectedPatologiasAP, setSelectedPatologiasAP,
      selectedPatologiasAM, setSelectedPatologiasAM } = useContext(MGContext)
   
   console.log("patologias p", selectedPatologiasP)
   // Creado las variables donde se van a almacenar las patologias
   const [patologias, setPatologias] = useState([]);
   //Funcion para obtener las patologias desde la base de datos mediante la API
   const getPatologias = () => {
      http
         .get('patologias1/get')
         .then((response) => {
            setPatologias(response.data.resp)
            console.log(response.data.resp)
         })
         .catch(() => {
            console.log('Algo salio mal')
         })
   }

   const handleChangeFallecidos = (e) => {
      setAntFamContext({ ...antFamContext, numero_hijos_fallecidos: e.value })
   }

   const handleChangeVivos = (e) => {
      setAntFamContext({ ...antFamContext, numero_hijos_vivos: e.value })
   }

   //Para llamar a la función
   useEffect(() => {
      getPatologias();
   }, [])

   return (
      <>
         <Accordion>
            <AccordionTab header="Padre">
               <TriajeAntFamAdd
                  indice={1}
                  id_familiar={1}
                  header=""
                  data={antFamContext}
                  setData={setAntFamContext}
                  patologias={patologias}
                  selectedPatologias={selectedPatologiasP}
                  setSelectedPatologias={setSelectedPatologiasP}
               />
            </AccordionTab>
         </Accordion>


         <Accordion>
            <AccordionTab header="Madre">
               <TriajeAntFamAdd

                  indice={2}
                  id_familiar={2}
                  header=""
                  data={antFamContext}
                  setData={setAntFamContext}
                  patologias={patologias}
                  selectedPatologias={selectedPatologiasM}
                  setSelectedPatologias={setSelectedPatologiasM}
               />
            </AccordionTab>
         </Accordion>


         <Accordion>
            <AccordionTab header="Hermanos">
               <TriajeAntFamAdd
                  indice={3}
                  id_familiar={5}
                  header=""
                  data={antFamContext}
                  setData={setAntFamContext}
                  patologias={patologias}
                  selectedPatologias={selectedPatologiasH}
                  setSelectedPatologias={setSelectedPatologiasH}
               />
            </AccordionTab>
         </Accordion>


         <Accordion>
            <AccordionTab header="Abuelos Paternos">
               <TriajeAntFamAdd
                  indice={4}
                  id_familiar={6}
                  header=""
                  data={antFamContext}
                  setData={setAntFamContext}
                  patologias={patologias}
                  selectedPatologias={selectedPatologiasAP}
                  setSelectedPatologias={setSelectedPatologiasAP}
               />
            </AccordionTab>
         </Accordion>



         <Accordion>
            <AccordionTab header="Abuelos Maternos">
               <TriajeAntFamAdd

                  indice={5}
                  id_familiar={7}
                  header=""
                  data={antFamContext}
                  setData={setAntFamContext}
                  patologias={patologias}
                  selectedPatologias={selectedPatologiasAM}
                  setSelectedPatologias={setSelectedPatologiasAM}
               />
            </AccordionTab>
         </Accordion>


         <div className="taf_form_title">
            <h3>Preguntas Adicionales</h3>
         </div>

         <div className="taf_form_textarea_container">
            <div className="taf_form_textarea_input">
               <label>Número de hijos vivos</label>
               <InputNumber
                  type='text'

                  value={antFamContext?.numero_hijos_vivos}
                  onChange={(e) => handleChangeVivos(e)}
               // disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
            <div className="taf_form_textarea_input">
               <label>Número de hijos fallecidos</label>
               <InputNumber
                  type='text'

                  name="numero_hijos_fallecidos"
                  value={antFamContext?.numero_hijos_fallecidos}
                  onChange={(e) => handleChangeFallecidos(e)}
               // disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
         </div>

      </>
   );
};

export default PageTriajeAntFam;