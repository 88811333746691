import React, { useEffect, useState } from 'react';
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import InputCalendar from '../../form/InputCalendar';
import { tipoOptions, asocOptions } from "./TriajeAntPerDialogAdd";
import './ModalCrearAbsentismo.css';
import { InputText } from 'primereact/inputtext';

const ModalEditarAbsentismo = ({ editDialog, hideEditDialog, absentismoToEdit, onSave, data }) => {
   console.log("dsds", absentismoToEdit)

   const [tipo, setTipo] = useState('');
   const [otros, setOtros] = useState(null)
   const [tipoAsociado, setTipoAsociado] = useState('');
   const [descripcion, setDescripcion] = useState('');
   const [fechaInicioDate, setFechaInicioDate] = useState(null);
   const [fechaFinalDate, setFechaFinalDate] = useState(null);
   const [diasIncapacidad, setDiasIncapacidad] = useState(null);
   const [menoscabo, setMenoscabo] = useState('');
   const [fecha_inicio, setFechaInicio] = useState("")
   const [fecha_final, setFechaFinal] = useState("")




   const handleEditarAbsentismo = () => {

      // Aquí puedes agregar la lógica para actualizar el elemento en tu estado global o donde lo almacenes
      // Por ejemplo, podrías buscar el elemento correspondiente y reemplazarlo con editedAbsentismo.

      onSave(tipo, otros, tipoAsociado, descripcion, fechaInicioDate, fechaFinalDate, diasIncapacidad, menoscabo, fecha_inicio, fecha_final);
      hideEditDialog();
   };

   const editDialogFooter = (
      <div className='contenedor_De_Botones_AHP'>
         <Button label="Cancelar" icon="pi pi-times" onClick={hideEditDialog} />
         <Button label="Guardar" icon="pi pi-check" onClick={handleEditarAbsentismo} />
      </div>
   );

   const FechaInicio = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         setFechaInicio(fechaFormateada)
         setFechaInicioDate(e?.value)
         calcularDiferencia(e?.value, fechaFinalDate)
      }
   }

   const FechaFinal = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;
         setFechaFinal(fechaFormateada)
         setFechaFinalDate(e?.value)
         calcularDiferencia(fechaInicioDate, e?.value)
      }
   }
   const calcularDiferencia = (fechaInicio, fechaFinal) => {
      console.log(fechaInicio)
      console.log(fechaFinal)
      if (fechaInicio && fechaFinal) {
         const diferenciaTiempo = fechaFinal.getTime() - fechaInicio.getTime();
         const diferenciaDias = Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24));
         setDiasIncapacidad(diferenciaDias.toString());
      } else {
         setDiasIncapacidad('');
      }
   };

   useEffect(() => {
      if (absentismoToEdit) {
         setTipo(absentismoToEdit.tipo_absentismo_id);
         setOtros(absentismoToEdit.otros);
         setTipoAsociado(absentismoToEdit.asociado_trabajo);
         setDescripcion(absentismoToEdit.descripcion);
         setFechaInicioDate(absentismoToEdit.fechaInicioDate);
         setFechaFinalDate(absentismoToEdit.fechaFinalDate);
         setDiasIncapacidad(absentismoToEdit.dias_incapacidad);
         setMenoscabo(absentismoToEdit.menoscabo);
         setFechaInicio(absentismoToEdit.fecha_inicio);
         setFechaFinal(absentismoToEdit.fecha_final);
      }
   }, [absentismoToEdit]);

   return (
      <Dialog
         visible={editDialog}
         style={{ width: "600px", height: "80vh" }}
         breakpoints={{ "960px": "75vw", "641px": "90vw" }}
         modal
         className="p-fluid"
         header="Editar Absentismo por accidente o enfermedades"
         footer={editDialogFooter}
         onHide={hideEditDialog}
      >
         <div className="flex flex-column gap-3">
            <div>
               <label>Tipo</label>
               <Dropdown
                  placeholder="Seleccione una opción"
                  options={tipoOptions}
                  onChange={(e) => setTipo(e.value)}
                  value={tipo}
               />
            </div>
            {
               tipo === 4 && (<div>
                  <label>Otros</label>
                  <InputText
                     placeholder="Ingrese una descripción"
                     value={otros}
                     onChange={(e) => setOtros(e.target.value)}
                     autoResize
                  />
               </div>)
            }
            <div>
               <label>Asociado al trabajo</label>
               <Dropdown
                  placeholder="Seleccione una opción"
                  options={asocOptions}
                  onChange={(e) => setTipoAsociado(e.value)}
                  value={tipoAsociado}
               />
            </div>

            <div>
               <label>Descripción</label>
               <InputTextarea
                  placeholder="Ingrese una descripción"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                  autoResize
               />
            </div>

            <div>
               <label>Fecha de inicio</label>
               <InputCalendar
                  value={fechaInicioDate}
                  onChange={(e) => { FechaInicio(e) }}
               />
            </div>

            <div>
               <label>Fecha final</label>
               <InputCalendar
                  value={fechaFinalDate}
                  onChange={(e) => { FechaFinal(e) }}
               />
            </div>

            <div>
               <label>Días de incapacidad</label>
               <InputNumber
                  value={diasIncapacidad}
                  onChange={(e) => setDiasIncapacidad(e.value)}
                  disabled={true}
               />
            </div>

            <div>
               <label>Menoscabo</label>
               <InputTextarea
                  placeholder="Ingrese una descripción"
                  value={menoscabo}
                  onChange={(e) => setMenoscabo(e.target.value)}
                  autoResize
               />
            </div>
         </div>
      </Dialog>
   );
};

export default ModalEditarAbsentismo;
