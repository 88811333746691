import React from "react";
import Botones from "../../Components/Pure/MenuPrincipal/botones";
import Imagenes from "../../Components/Pure/MenuPrincipal/Imagenes";
import Eclipses from "../../Components/Pure/MenuPrincipal/Eclipses";
import Sombra from "../../Images/Pure/MenuPrincipal/Img/sombra.png";
import P_S from '../../Images/Pure/MenuPrincipal/Img/P_S.png'
import productos from '../../Images/Pure/MenuPrincipal/img-icons/productos.png'
import servicios from '../../Images/Pure/MenuPrincipal/img-icons/servicios.png'
import terminos from '../../Images/Pure/MenuPrincipal/img-icons/terminos.svg'
import profesionales from "../../Images/Pure/MenuPrincipal/Img/profesionales.png";
import "../../Style/Botones.css";
import 'primeicons/primeicons.css';
import { Dialog } from "primereact/dialog";
import { HookPositionDemo } from "../../Components/ModalPaginaEnProceso/ModalDesarrollo";

const ContabilidadComponent = ({accesoProp}) => {

    //Obtener Lista de Accesos

  const listAcceso = accesoProp.map((acc) =>{
    return acc.url
  });
  console.log(listAcceso)


  const { visible, setVisible, show, position, setPosition } =
    HookPositionDemo();
  const header = (
    <img
      alt="Card"
      src="https://primefaces.org/cdn/primereact/images/usercard.png"
    />
  );
  return (
    <div className=' flex flex-nowrap flex-column w-full h-full'>
      <div className='arriba'>
        <span className='tit'>Contabilidad</span><br />
        <span className='con'>En este módulo usted podrá generar el costo por atención y la facturación de sus productos y/o servicios.</span>
      </div>
      <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center h-full'>
        <Eclipses />
        <Imagenes
          src={P_S}
          className="logo3"
        />
        <Imagenes src={profesionales} className="logo1" />
        <Imagenes src={Sombra} className="som" />
        <Botones
          hidden={!listAcceso.includes('costoatencion')}
          onclick={() => show("bottom")}
          Link="/contabilidad/costoatencion"
          icons="pi pi-map-marker"
          img={productos}
          span="Liquidación"
          button="button b3"
        />
        <Botones
          hidden={!listAcceso.includes('facturacion')}
          onclick={() => show("bottom")}
          Link="/contabilidad/facturacion"
          icons="pi pi-shield"
          img={servicios}
          span="Facturación"
          button="button b4"
        />
        <Botones
          hidden={!listAcceso.includes('pagos')}
          onclick={() => show("bottom")}
          Link="/contabilidad/pagos"
          icons="pi pi-map-marker"
          img={terminos}
          span="Mis Pagos"
          button="button b5"
        />
        
        <Dialog
          visible={visible}
          position={position}
          onHide={() => setVisible(false)}
          draggable={false}
          resizable={false}
          style={{ width: "400px", textAlign: "center" }}
        >
          <p className="mt-2 pr-2">
            Este módulo se encuentra aún en desarrollo.
          </p>
        </Dialog>
      </div>
    </div>
  );
};

export default ContabilidadComponent;