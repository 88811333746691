import React from 'react'

function OperProductos() {
return (
    <div>
        <p>Productos</p>

    </div>
)
}

export default OperProductos