import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useContext } from "react";
import { TriajeContext } from "../../../../Page/Triaje/TriajeContext";

export default function ModalSigVitEliminar({ IdSignosVitales, setVisibleDelete, visibleDelete }) {
  const { sigVitContext, setSigVitContext } = useContext(TriajeContext)

  const ConfirmarDeletaSigVit = () =>{
    sigVitContext.splice(IdSignosVitales, 1);
    setVisibleDelete(false);
    console.log("sigVitContext", sigVitContext)
  } 

  // BOTONES DE CONFIMACION (ELIMINAR)
  const handleDeleteConfirmation = (id_eliminar) => (
    <React.Fragment>
      <div className="flex justify-content-between">
        <Button
          label="Cancelar"
          className="p-button p-component delete w-full"
          style={{
          }}
          onClick={() => {
            setVisibleDelete(false);
          }}
        />
        <Button
          label="Eliminar"
          className="p-button-success w-full"
          style={{
            color: "#5555D8",
            backgroundColor: "#EBEBFF",
            borderColor: "#5555D8"
          }}
          onClick={() => {
            ConfirmarDeletaSigVit();
          }}
        />
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visibleDelete}
      style={{ width: "450px", height: "230px" }}
      className="p-fluid"
      header={<p style={{ color: "#5555D8", fontSize: "32px", marginBottom: "15px" }}>Eliminar</p>}

      footer={handleDeleteConfirmation(visibleDelete.id)}

      onHide={() => {
        setVisibleDelete(false);
      }}

    >
      <p style={{ fontSize: "20px" }}>¿Está seguro que desea eliminar?</p>
    </Dialog>

  )
}