import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../../../Style/ANTECEDENTE.css";
import ICONO from "../img/ICONODEANTECEDENTE.svg";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import InputCalendar from "../../../../Components/form/InputCalendar";
import { TablaRiesgo } from "./TablaRiesgo";
import {TableEditarAntLabRiesgos} from "./TableEditarAntLabRiesgos"
import DialogAgregarRiesgo from "./DialogAgregarRiesgo";
import { TriajeContext } from "../../../Triaje/TriajeContext";
import { InputNumber } from "primereact/inputnumber";
import { RAContext } from "../../../RutaAtencion/RAContext";

export default function DialogAgregarAntLab({showToast}) {
   const { AntOcupContext, setAntOcupContext } = useContext(TriajeContext);
   const { antLabContext, setAntLabContext } = useContext(TriajeContext);
   let { antLabRiesgoContext, setAntLabRiesgoContext } = useContext(TriajeContext);

   const [AntecedenteLaboral, setAntecedenteLaboral] = useState([]);
   let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
   /* DATOS DENTRO DE ANTECEDENTE LABORAL */
   const [TrabajoActual, setTrabajoActual] = useState(antLabContext?.trabajo_actual ?? null);
   const [Empresa, setEmpresa] = useState(antLabContext?.empresa ?? null);
   const [ActividaEmp, setActividaEmp] = useState(antLabContext?.actividad_empresarial ?? null);
   const [Ocupacion, setOcupacion] = useState(antLabContext?.ocupacion ?? null);
   const [PuestoTrab, setPuestoTrab] = useState(antLabContext?.puesto_trabajo ?? null);
   const [AreaTrabajo, setAreaTrabajo] = useState(antLabContext?.area_trabajo ?? null);
   const [AlturaLabor, setAlturaLabor] = useState(antLabContext?.altura_labor ?? null);
   const [FIngreso, setFIngreso] = useState(antLabContext?.fecha_ingreso ?? " ");
   const [FSalida, setFSalida] = useState(antLabContext?.fecha_salida ?? " ");
   const [Tiempo, setTiempo] = useState(antLabContext?.tiempo ?? null);
   const [HabilitarinpAreaTab, setHabilitarinpAreaTab] = useState(false); // Modificado
   const [HabilitarinpAltTab, setHabilitarinpAltTab] = useState(false); // Modificado
   const [AreaTrabajoInput, setAreaTrabajoInput] = useState(antLabContext?.area_trabajo ?? null);
   const [AlturaTrabajoInput, setAlturaTrabajoInput] = useState(antLabContext?.altura_labor ?? null);
   const [popupClosed, setPopupClosed] = useState(true); // Nuevo estado para controlar si el popup ha sido cerrado previamente

   const ChangeTrabajoActual = (e) => {
      setTrabajoActual(e);
      if (e.name === "Si") {
         if (AntOcupContext && AntOcupContext.empresa_actual && AntOcupContext.puesto_ocupacional) {
            setEmpresa(AntOcupContext.empresa_actual);
            setOcupacion(AntOcupContext.puesto_ocupacional);
            setPuestoTrab(AntOcupContext.puesto_ocupacional);
            setAntecedenteLaboral({
               ...AntecedenteLaboral,
               empresa: AntOcupContext.empresa_actual,
               ocupacion: AntOcupContext.puesto_ocupacional,
               puesto_trabajo: AntOcupContext.puesto_ocupacional,
               trabajo_actual: "Si"
            });
         } else {
            setEmpresa(null);
            setOcupacion(null);
            setPuestoTrab(null);
            setAntecedenteLaboral({
               ...AntecedenteLaboral,
               empresa: null,
               ocupacion: null,
               puesto_trabajo: null,
               trabajo_actual: "Si"
            });
         }
      } else {
         // Si se selecciona "No", mantén los datos visibles pero no los sobrescribas
         setAntecedenteLaboral({
            ...AntecedenteLaboral,
            trabajo_actual: "No"
         });
      }
   };

   const ChangeEmpresa = (e) => {
      setEmpresa(e);
      setAntecedenteLaboral({ ...AntecedenteLaboral, empresa: e });
   };
   const ChangeActividaEmp = (e) => {
      setActividaEmp(e);
      setAntecedenteLaboral({ ...AntecedenteLaboral, actividad_empresarial: e, });
   };
   const ChangeOcupacion = (e) => {
      setOcupacion(e);
      setAntecedenteLaboral({ ...AntecedenteLaboral, ocupacion: e });
   };
   const ChangePuestoTrab = (e) => {
      setPuestoTrab(e);
      setAntecedenteLaboral({ ...AntecedenteLaboral, puesto_trabajo: e });
   };
   const ChangeAreaTrabajo = (e) => {
      setAreaTrabajo(e);
      if (e?.name == "Otros") {
         setHabilitarinpAreaTab(false);
      } else {
         setHabilitarinpAreaTab(true);
         setAreaTrabajoInput("");
         setAntecedenteLaboral({ ...AntecedenteLaboral, area_trabajo: e.name });
      }
   };
   const ChangeAreaTrabajoInput = (e) => {
      if (!HabilitarinpAreaTab) { // Solo permite la entrada de datos si la opción es "Otros"
         setAreaTrabajoInput(e);
         setAntecedenteLaboral({ ...AntecedenteLaboral, area_trabajo: e });
      } else {
         setAreaTrabajoInput(""); // Limpia el campo si la opción seleccionada no es "Otros"
      }
   };

   const ChangeAlturaLabor = (e) => {
      setAlturaLabor(e);
      if (e?.name == "Otros") {
         setHabilitarinpAltTab(false)
      } else {
         setHabilitarinpAltTab(true)
         setAlturaTrabajoInput("")
         setAntecedenteLaboral({ ...AntecedenteLaboral, altura_labor: e.name });
      }
   };
   const ChangeAlturaLaborInput = (e) => {
      if (!HabilitarinpAltTab) { // Solo permite la entrada de datos si la opción es "Otros"
         setAlturaTrabajoInput(e)
         setAntecedenteLaboral({ ...AntecedenteLaboral, altura_labor: e });
      } else {
         setAlturaTrabajoInput(""); // Limpia el campo si la opción seleccionada no es "Otros"
      }
   };

   const ChangeFIngreso = (e) => {
      let formattedFecha = null;
      if (e !== "") {
         const fecha = new Date(e);
         formattedFecha = fecha.toISOString().split("T")[0];
      }
      setFIngreso(e); // Solo actualizamos el estado local aquí
      setAntecedenteLaboral({ ...AntecedenteLaboral, fecha_ingreso: formattedFecha });
   };



   const ChangeFSalida = (e) => {
      let formattedFecha01 = null;
      if (e !== "") {
         const fecha = new Date(e);
         formattedFecha01 = fecha.toISOString().split("T")[0];
      }
      setFSalida(e);
      setAntecedenteLaboral({ ...AntecedenteLaboral, fecha_salida: formattedFecha01 });
   };
   // const ChangeTiempo = (e) => {
   //    setTiempo(e);
   //    console.log("tiempo", e)
   //    setAntecedenteLaboral({ ...AntecedenteLaboral, tiempo: e });
   // };
   const limpiar = () => {
      setTrabajoActual(null)
      setEmpresa(null)
      setActividaEmp(null)
      setOcupacion(null)
      setPuestoTrab(null)
      setAreaTrabajo(null)
      setAlturaLabor(null)
      setFIngreso(" ")
      setFSalida(" ")
      setTiempo(null)
      setHabilitarinpAreaTab(false) // Modificado
      setHabilitarinpAltTab(false) // Modificado
      setAreaTrabajoInput(null)
      setAlturaTrabajoInput(null)
      setAntecedenteLaboral([])
      setAntLabRiesgoContext([])
   }

   useEffect(() => {
      setAntecedenteLaboral({
         item: null,
         empresa: null,
         actividad_empresarial: null,
         ocupacion: null,
         trabajo_actual: "No",
         puesto_trabajo: null,
         area_trabajo: null,
         altura_labor: null,
         fecha_ingreso: null,
         fecha_salida: null,
         tiempo: null,
         antecedentes_riesgo: []
      })
   }, [antLabContext])

   /**BOTÓN DE CREAR ANTECEDENTE LABORAL */
   const CreateAntOcup = () => {
      let AntLab_Riesgo = [{ item: null, ...AntecedenteLaboral, antecedentes_riesgo: [...antLabRiesgoContext] }]
      let DatosAntLab = [...AntLab_Riesgo, ...antLabContext]
      setAntLabContext(DatosAntLab)
      setAntOcupContext({ ...AntOcupContext, antecedente_laboral: [...DatosAntLab] })
      setVisible(false)
      limpiar()
      showToast(
         "success",
         "Creado correctamente",
         "Se creó el antecedente laboral"
      )
   }

   useEffect(() => {
      if (FSalida != " " && FIngreso != " ") {
         let dia01 = new Date(FSalida)
         let dia02 = new Date(FIngreso)

         let diferenciaEnMeses = (dia01.getFullYear() - dia02.getFullYear()) * 12 + (dia01.getMonth() - dia02.getMonth());
         let años = Math.floor(diferenciaEnMeses / 12);
         let meses = diferenciaEnMeses % 12;
         let tiempo = años + " años " + meses + " meses"
         setAntecedenteLaboral({ ...AntecedenteLaboral, tiempo: tiempo });
         setTiempo(tiempo);
         console.log("fecha", tiempo)
      }
   }, [FIngreso, FSalida]);

   const areatrabajo = [
      { name: "Mina", code: "0" },
      { name: "Superficie", code: "1" },
      { name: "Planta", code: "2" },
      { name: "Administración", code: "3" },
      { name: "Producción", code: "4" },
      { name: "Mantenimiento", code: "5" },
      { name: "Otros", code: "6" },
   ];

   const alturalabor = [
      { name: "Hasta 2500 mt", code: "0" },
      { name: "2501 a 3000 mt", code: "1" },
      { name: "3001 a 3500 mt", code: "2" },
      { name: "3501 a 4000 mt", code: "3" },
      { name: "4001 a 4500 mt", code: "4" },
      { name: "Ninguno", code: "5" },
      { name: "Otros", code: "6" },
   ];

   const TrabajoActualOpcion = [
      { name: "Si", code: "0" },
      { name: "No", code: "1" },
   ];

   const [visible, setVisible] = useState(false);
   const footerContent = (
      <div>
         <Button
            label="Aceptar"
            onClick={() => CreateAntOcup()}
            autoFocus
         />
         <Button
            label="Cancelar"
            onClick={() => { setVisible(false); limpiar() }}
            className="p-button-text"
         />
      </div>
   );
   return (
      <div
         className="flex justify-content-start"
         style={{ marginTop: "20px", marginBottom: "10px" }}
      >
         <Button
            label="Agregar"
            icon="pi pi-plus"
            onClick={() => { setVisible(true); setAntLabRiesgoContext([]); setPopupClosed(false);}}
            disabled={stateAtencion[pageViewContext].estado === 3}
         />
         <Dialog
            visible={visible}
            style={{ width: "70vw" }}
            onHide={() => {setVisible(false); limpiar(); setPopupClosed(true);}}
            footer={footerContent}
         >
            <div className="flex flex-column gap-3">
               <div className="CONTENEDORTITULOCONICONO gap-3">
                  <div className="CONTENEDOR_ICONO">
                     <img src={ICONO} />
                  </div>
                  <span>Crear Nuevo Antecedente Laboral</span>
               </div>
               <div
                  className="CONTENEDOR_ITEM_HASTA_ALTURA flex flex-wrap gap-3"
                  style={{ marginTop: "20px" }}
               >
                  {/* Lado izquierdo */}
                  <div className="CONTENEDOR_IZQUIERDO_ANTECEDENTE gap-3 flex-1">
                     {/* <div className="INPUTTEXTIZQUIERDA">
                                <span>Item</span>
                                <InputText className="flex w-9" />
                            </div> */}
                     <div className="INPUTTEXTIZQUIERDA">
                        <span>Empresa</span>
                        <InputText
                           className="flex w-9"
                           value={Empresa}
                           onChange={(e) => ChangeEmpresa(e.target.value)}
                        />
                     </div>
                     <div className="INPUTTEXTIZQUIERDA">
                        <span>Ocupación</span>
                        <InputText
                           className="flex w-9"
                           value={Ocupacion}
                           onChange={(e) => ChangeOcupacion(e.target.value)}
                        />
                     </div>
                     <div className="INPUTTEXTIZQUIERDA">
                        <span>Área de Trabajo</span>
                        <div className="flex flex-row justify-content-end gap-2 w-9">
                        <Dropdown
                           value={AreaTrabajo}
                           onChange={(e) => ChangeAreaTrabajo(e.value)}
                           options={areatrabajo}
                           optionLabel="name"
                           placeholder=""
                           className="w-full"
                        />
                        <InputText
                           className="w-5"
                           onChange={(e) => ChangeAreaTrabajoInput(e.target.value)}
                           value={AreaTrabajoInput}
                           disabled={!(AreaTrabajo && AreaTrabajo.name === "Otros")} // Cambiado aquí
                        />
                        </div>
                     </div>
                  </div>
                  {/* Lado Derecho */}
                  <div className="CONTENEDOR_DERECHO_ANTECEDENTE gap-3 flex-1">
                     <div className="INPUTTEXTDERECHA">
                        <span>Trabajo Actual: </span>
                        <Dropdown
                           className="flex w-9"
                           options={TrabajoActualOpcion}
                           placeholder="No"
                           optionLabel="name"
                           value={TrabajoActual}
                           onChange={(e) => ChangeTrabajoActual(e.target.value)}
                        />
                     </div>
                     <div className="INPUTTEXTDERECHA">
                        <span>Actividad Empresarial:</span>
                        <InputText
                           className="flex w-9"
                           value={ActividaEmp}
                           onChange={(e) => ChangeActividaEmp(e.target.value)}
                        />
                     </div>
                     <div className="INPUTTEXTDERECHA">
                        <span>Puesto Trab./Proyec</span>
                        <InputText
                           className="flex w-9"
                           value={PuestoTrab}
                           onChange={(e) => ChangePuestoTrab(e.target.value)}
                        />
                     </div>
                     <div className="INPUTTEXTDERECHA">
                        <span>Altura Labor</span>
                        <div className="flex flex-row justify-content-end gap-2 w-9">
                        <Dropdown
                           value={AlturaLabor}
                           options={alturalabor}
                           onChange={(e) => ChangeAlturaLabor(e.value)}
                           optionLabel="name"
                           placeholder=""
                           className="w-full"
                        />
                        <InputText
                           className="w-5"
                           value={AlturaTrabajoInput}
                           onChange={(e) => ChangeAlturaLaborInput(e.target.value)}
                           disabled={!(AlturaLabor && AlturaLabor.name === "Otros")} // Cambiado aquí
                        />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="flex flex-wrap gap-3">
                  {/* Fecha ingreso */}
                  <div className="flex flex-column gap-2 w-full flex-1">
                     <span>F.Ingreso</span>
                     <InputCalendar
                        value={FIngreso}
                        onChange={(e) => ChangeFIngreso(e.target.value)}
                        showIcon
                     />
                  </div>
                  {/* Fecha Salida */}
                  <div className="flex flex-column gap-2 w-full flex-1">
                     <span>F.Salida</span>
                     <InputCalendar
                        value={FSalida}
                        onChange={(e) => ChangeFSalida(e.target.value)}
                        showIcon
                     />
                  </div>
                  {/* Tiempo */}
                  <div className="flex flex-column gap-2 w-full flex-1">
                     <span>Tiempo</span>
                     <InputText
                        placeholder="0"
                        value={Tiempo}
                        // onChange={(e) => ChangeTiempo(e.target.value)}
                        disabled={true}
                     />
                  </div>
               </div>
               <div>
                  <DialogAgregarRiesgo  showToast={showToast}/>
                  <TablaRiesgo showToast={showToast}/>
               </div>
            </div>
         </Dialog>
      </div>
   );
}
