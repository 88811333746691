import PersonaComponent from "../../../Components/Perfil/usuario/Persona";
import EmpresaComponent from "../../../Components/Perfil/usuario/Empresa";
import PermisosComponent from "../../../Components/Perfil/usuario/Permisos";
import Detraccion from "../../../Components/Perfil/usuario/Detraccion";
import CrearCcomponent from "../../../Components/Perfil/usuario/CrearC";
import AuthUser from "../../../AuthUser";
import './MediaQueryPerfilClinica.css';
import { useState, useRef, useEffect } from "react";
import { Toast } from 'primereact/toast';
// import "../../Perfil/CSSResponsiv.css";

const PagePerfil = () => {
    const toast = useRef();
    const { http } = AuthUser();
    const [tipoDcoumentos, setTipoDcoumentos] = useState([]);
    const [clinica, setClinica] = useState([]);
    const [bregmaUpdate, setClinicaUpdate] = useState({
        id: 0,
        tipo_documento: null,
        numero_documento: null,
        razon_social: null,
        direccion: null,
        celular: null,
        correo: null,
        distrito: null,
        distrito_id: null
    })
    const [persona, setPersona] = useState([])
    const [personaUpdate, setPersonaUpdate] = useState([])
    const [entidadPagos, setEntidadPagos] = useState([])
    const [detracciones, setDetracciones] = useState([])
    const [entidadPagoElectronico, setEntidadPagoElectronico] = useState([])
    const [entidadesBancarias, setEntidadesBancarias] = useState([])

    const showToast = (tipo, titulo, detalle) => {
        toast.current.show({
          severity: tipo,
          summary: titulo,
          detail: detalle,
          life: 3000,
        });
      };

    const getClinica = () => {

        http
            .get("clinica/show")
            .then((response) => {
                console.log("entrooo clinica", response.data.data);
                setClinica(response?.data?.data);
                setClinicaUpdate({
                    ...bregmaUpdate,
                    id: response?.data?.data?.id,
                    tipo_documento: response?.data?.data?.tipo_documento,
                    tipo_documento_id: response?.data?.data?.tipo_documento_id,
                    numero_documento: response?.data?.data?.numero_documento,
                    razon_social: response?.data?.data?.razon_social,
                    direccion: response?.data?.data?.direccion,
                    celular: response?.data?.data?.celulares[0]?.celular,
                    correo: response?.data?.data?.correos[0]?.correo,
                    distrito: response?.data?.data?.distrito,
                    distrito_id: response?.data?.data?.distrito_id
                });
                setEntidadPagos(response?.data?.data?.entidad_pagos)
                setDetracciones(response?.data?.data?.detracciones)
            })
            .catch((error) => {
                console.error("errrror", error);
            });
    };

    const getAllTipoDocumentos = () => {
        http
            .get("/tipodocumentos/get")
            .then((response) => {
                if (response?.data?.data) {
                    setTipoDcoumentos(response.data.data);
                } else if (response?.data?.error) {
                    showToast('error', 'Error', response.data.error)
                }
            })
            .catch((error) => {
                showToast('error', 'Error', 'Contáctese con soporte técnico.')
            });
    };
    const getEntidadesBancarias = () => {
        http
            .get('entidad_bancaria/get')
            .then((response) => {
                setEntidadesBancarias(response.data.data);
                console.log("entidades bancarias", response.data.data)
            })
            .catch((error) => {
                console.error("error", error);
            })
    }

    const getEntidadesElectronicas = () => {
        http
            .get('entidad_electronica_pago/get')
            .then((response) => {
                setEntidadPagoElectronico(response.data.data);
                console.log(response.data.data.entidad_electronica)
            })
            .catch((error) => {
                console.error("error", error);
            })
    }

    const getPersona = () => {
        http
            .get('persona/show')
            .then((response) => {
                console.log("personaaa", response?.data?.data?.persona)
                setPersona(response?.data?.data?.persona);
                setPersonaUpdate(response?.data?.data?.persona);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleSubmitUpdate = (id, data) => {
        console.log("data antes de enviar", data)
        http
            .put(`clinica/updatebylogin`, data)
            .then((response) => {
                console.log("se actualizo")
                getClinica();
            })
            .catch((error) => {
                console.error(error);
            })
    }
    const handleCreateClinica = (entidadPago, limpiarEntidadBancaria, CerrarModal) => {
        http
            .post("entidad_pago/create/clinica", entidadPago)
            .then((response) => {
                getClinica();
                CerrarModal();
                limpiarEntidadBancaria();
            })
            .catch((error) => {
                CerrarModal();
                console.error("error", error);
            });
    };
    //const toast = 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getClinica();
        getAllTipoDocumentos();
        getPersona();
        getEntidadesBancarias();
        getEntidadesElectronicas();
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [])


    return (

        <div className="ContenedorGeneralPerfil flex flex-wrap flex-column gap-3 mt-3 pr-7 pl-6">
            <Toast ref={toast}/>
            <div>
                <p className="tit "> Mi Perfil </p>
                <p className="con">Adminí­stralo a tu mejor manera</p>
            </div>
            <div className="gap-3 perfil-res ContenedorGeneralComponente" style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className=" flex flex-wrap ContenedorComponentePersona">
                    <PersonaComponent
                        persona={persona}
                        personaUpdate={personaUpdate}
                        setPersonaUpdate={setPersonaUpdate}
                        getPersona={getPersona}
                        institucion={"Clínica"}


                    />
                </div>
                <div className=" flex flex-wrap ContenedorComponenteEmpresa">
                    <EmpresaComponent
                        persona={persona}
                        dataShow={clinica}
                        data={bregmaUpdate}
                        setBregma={setClinicaUpdate}
                        getBregma={getClinica}
                        tiposDocumentos={tipoDcoumentos}
                        handleSubmitUpdate={handleSubmitUpdate}
                    />
                </div>

            </div>
            <div className="CONEDORINFEERIORPERFIL w-12 flex gap-3">
                <div className="CONEDORINFEERIORPERMISOS flex gap-3" style={{ maxWidth: '100%', margin: 'auto' }}>
                    <PermisosComponent namefull={persona?.nombres + " " + (persona?.apellido_paterno ?? "") + " " + (persona?.apellido_materno ?? "")} />
                </div>
                <div className="CONEDORINFEERIORCREAR w-9 flex flex-column gap-1">
                    <div>
                        <Detraccion
                            detracciones={detracciones}
                            getbregma={getClinica} />
                    </div>
                    <div>
                        <CrearCcomponent
                            getEntidadesElectronicas={getEntidadesElectronicas}
                            entidadPagoElectronico={entidadPagoElectronico}
                            getbregma={getClinica}
                            entidadPagos={entidadPagos}
                            entidadesBancarias={entidadesBancarias}
                            handleCreate={handleCreateClinica}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PagePerfil;