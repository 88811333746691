import React, { useContext, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import AuthUser from "../../AuthUser";
import { RadContext } from "./RadProvider";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconEkg, iconTriaje } from "../../Services/importIcons";
import RayosX from "./RayosX";
import GroupDemo from "./RadioContext";
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";
const PageRad = () => {
   const [imprimirVisible, setImprimirVisible] = useState(false);

   let { datosContext } = useContext(RadContext);
   //  console.log(datosContext);

   const { atencionStatus, setAtencionStatus } = useContext(RadContext);
   const { setTimeStart } = useContext(RadContext);

   const { http } = AuthUser();

   const [activeIndex, setActiveIndex] = useState(0);

   const auth = AuthUser();

   const [formData, setFormData] = useState({
      paciente_id: 1, // pasarle le id del paciente desde la tabla
   });

   const handleSave = async (e) => {
      e.preventDefault();
      try {
         const response = await auth.http.post(
            "/radiologia/radiologia/create",
            formData
         );
         console.log(response);
         if (response.status === 201) {
            console.log("Elemento de radiología creado exitosamente");
            // Puedes redirigir a otra página si es necesario
            // auth.navigate("/radiologia");
         }
      } catch (error) {
         console.error("Error al crear elemento de radiología:", error);
      }
   };

   const toast = useRef(null);

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   return (
      <>
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            <TabPanel header="Radiografía">
               <RayosX />
            </TabPanel>
            <TabPanel header="Informe OIT">
               <GroupDemo />
            </TabPanel>
         </TabView>
         <div
            style={{ borderRadius: "10px", height: "70px" }}
            className="flex justify-content-end gap-2 mt-2 bg-white"
         >
            <button
               className="p-button p-component btn-2 flex gap-1"
               onClick={handleSave}
            >
               <i className="pi pi-save"> </i>
               <spam>Guardar</spam>
            </button>
            <button
               style={{ color: "red" }}
               className="p-button  p-component btn-2 flex gap-1"
            >
               <i className="pi pi-times"></i>
               <span>Cancelar</span>
            </button>
            <button
               style={{ color: "black" }}
               onClick={() => setImprimirVisible(true)}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-print"></i>
               <span>Imprimir</span>
            </button>
         </div>
         <ModalImprimir
            imprimirVisible={imprimirVisible}
            setImprimirVisible={setImprimirVisible}
         />

         {/* {atencionStatus === 0 && (
            <RATimeView
               title="Radiología"
               icon={iconEkg}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
            />
         )} */}
      </>
   );
};

export default PageRad;
