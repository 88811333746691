import React, {useState, useEffect} from "react";
import AuthUser from "../../AuthUser";
import RAResultadosTable from "../../Components/RutaAtencion/Resultados/RAResultadosTable";

const PageMenuAud = () => {
    const { http } = AuthUser();
    const [pacientes, setPacientes] = useState([]);

    const getPacientes = () => {
       http
          .get("/atencion/paciente/area/5")
          .then((response) => {
             console.log("personal paciente", response.data.data);
             setPacientes(response.data.data);
          })
          .catch((error) => {
             console.log(error);
          });
    };
 
    useEffect(() => {
       getPacientes();
    }, [])
 
    return (
       <div>
          <RAResultadosTable
             pacientes={pacientes}
             nombre={"Audiometría"}
          ></RAResultadosTable>
       </div>
    )
}

export default PageMenuAud;