import React, { useContext, useEffect, useState } from 'react';
import { OftContext } from "../OftContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { iconOft } from "../../../Services/importIcons";
import { Dropdown } from 'primereact/dropdown';
import '../../../Style/oft_test.css'
// import './PageOftTest.css';

const PageOftTestColor = () => {

  let { testColorContext, setTestColorContext } = useContext(OftContext)
  let { pageViewContext, stateAtencion } = useContext(RAContext)
  const options = [
    { name: "Normal", code: 1 },
    { name: "Anormal", code: 2 },
  ]
  const [ojoIzq, setOjoIzq] = useState(testColorContext?.ojo_izquierdo ?? options[0]);
  const [ojoDer, setOjoDer] = useState(testColorContext?.ojo_derecho ?? options[0]);
  const [check1, setCheck1] = useState(testColorContext?.reconoce_colores);
  const [check2, setCheck2] = useState(testColorContext?.dificultad_color);

  useEffect(() => {
    setTestColorContext({

      [`ojo_derecho`]: ojoDer,
      [`ojo_izquierdo`]: ojoIzq,
      [`reconoce_colores`]: check1,
      [`dificultad_color`]: check2,
    })
  }, [ojoIzq, ojoDer, check1, check2])

  const handleCheck = (data, item) => {
    console.log("data", data)
    let optionData = data == true ? 1 : 0
    console.log("Optiondata", optionData)
    if (item == "1") {
      setCheck1(optionData)
      setCheck2(0)
    } else {
      setCheck2(optionData)
      setCheck1(0)

    }

  }
  return (
    <>
      <div className="oft_ant_titles">
        <div>
          <img src={iconOft} alt="Icon" />
        </div>
        <h3>Test de colores</h3>
      </div>

      <div className='desktop-oft-test'>
        <div className="oft_test_ctable_container">
          {/*HEADER*/}
          <div className="oft_test_ctable_header1" style={{ backgroundColor: '#EBF0FF' }}>
            <span>Visión de colores (T. Ishihara)</span>
          </div>
          {/*SUB-HEADERS*/}
          <div className="oft_test_ctable_header2">
            <span>Ojo Derecho</span>
          </div>
          <div className="oft_test_ctable_header2">
            <span>Ojo Izquierdo</span>
          </div>
          {/*BODY*/}
          <div className="oft_test_ctable_body derecho">
            {ojoDer === 1 && <span></span>}
            <Dropdown showClear value={ojoDer == 1 ? options[0] : ojoDer == 2 ? options[1] : null} onChange={(e) => { setOjoDer(e.target?.value?.code); console.log("sa", e.target.value) }} options={options} disabled={stateAtencion[pageViewContext].estado === 3} optionLabel="name" className="w-full " placeholder='Seleccionar' style={{ marginLeft: "17px" }} />
          </div>
          <div className="oft_test_ctable_body">
            {ojoIzq === 1 && <span></span>}
            <Dropdown showClear value={ojoIzq == 1 ? options[0] : ojoIzq == 2 ? options[1] : null} onChange={(e) => { setOjoIzq(e.target?.value?.code); console.log("sa", e.target.value) }} options={options} optionLabel="name" className="w-full " placeholder='Seleccionar' />
          </div>

        </div>
      </div>

      <div className='movil-oft-test' style={{ minWidth: "150px" }}>
        <div className="oft_test_ctable_container">
          <div className="oft_test_ctable_header1" style={{ backgroundColor: '#EBF0FF' }}>
            <span className='span_oft_test'>Visión de colores (T. Ishihara)</span>
          </div>
          <div className="oft_test_ctable_content1">
            <span>Ojo Derecho</span>
          </div>
          <div className="oft_test_ctable_content2">
            <InputText value={ojoDer} onChange={e => setOjoDer(e.target.value)} placeholder='Normal' disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
          <div className="oft_test_ctable_content1" >
            <span>Ojo Izquierdo</span>
          </div>
          <div className="oft_test_ctable_content2">
            <InputText value={ojoIzq} onChange={e => setOjoIzq(e.target.value)} placeholder='Normal' disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
        </div>
      </div>

      <div className="oft_test_checkbox_container">
        <div>
          <Checkbox onChange={(e) => handleCheck(e.checked, "1")} checked={check1 == 1 ? true : false} disabled={stateAtencion[pageViewContext].estado === 3} />
          <label>Paciente reconoce colores enteros (rojo/verde/azul)</label>
        </div>
        <div>
          <Checkbox onChange={(e) => handleCheck(e.checked, "2")} checked={check2 == 1 ? true : false} disabled={stateAtencion[pageViewContext].estado === 3} />
          <label>Paciente tiene dificultad en reconocer el color entero</label>
        </div>
      </div>
    </>
  );
};

export default PageOftTestColor;