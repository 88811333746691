import React, { useRef, useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import AuthUser from '../../../AuthUser';

function NuevoGenerar({ visibleGenerar, setVisibleGenerar, paciente, pdfFile, consentimiento, setEmbarazo, setRadiografia, embarazo, radiografia }) {
  const toast = useRef(null);
  const { http } = AuthUser();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showEmbarazoRadiografia, setShowEmbarazoRadiografia] = useState(false);

  const showToast = (type, title, detail) => {
    if (toast.current) {
      toast.current.show({
        severity: type,
        summary: title,
        detail: detail,
        life: 3000,
      });
    } else {
      console.error("Toast no inicializado");
    }
  };

  const cancelarDocumento = () => {
    setVisibleGenerar(false);
  }

  const aceptarDocumento = () => {
    if (!paciente.huella) {
      setShowErrorMessage(true)
    } else {
      let data = {
        doc_consentimiento_id: pdfFile.id,
        paciente_id: paciente.id,
      }
      console.log("data", data)

      http
        .post(`consentimiento/paciente/aceptar`, data)
        .then((response) => {
          console.log("RELACIÓN", response.data)
          setVisibleGenerar(false);
          showToast(
            "success",
            "PDF",
            "Documento generado correctamente"
          )
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const hideErrorMessage = () => {
    setShowErrorMessage(false)
  }

  const acceptFooter = () => {
    setVisibleGenerar(false);
    aceptarDocumento();
  }

  const footer = () => {
    return (
      <>
        <Button
          onClick={() => cancelarDocumento()}
          label="Cancelar" />
        <Button
          onClick={() => acceptFooter()}
          label="Aceptar" />
      </>
    )
  }

  const header = () => {
    return (
      <h1 className='titulo-consentimiento' style={{ marginBottom: '10px' }}>Firma y huella</h1>
    )
  }

  useEffect(() => {
    if (consentimiento === 'Consentimiento de RX (Mujeres)' && pdfFile && pdfFile.id === '1') {
      setShowEmbarazoRadiografia(true);
    } else {
      setShowEmbarazoRadiografia(false);
    }
  }, [consentimiento, pdfFile]);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={showErrorMessage}
        onHide={() => hideErrorMessage()}
        header={<div style={{ color: "red" }}><i className="pi pi-ban" style={{ fontSize: "1.2rem" }}></i><p>Error</p></div>}
        footer={<Button onClick={() => hideErrorMessage()} label="Cerrar" />}
        style={{ width: "430px" }}
      >
        <div>Firma y/o huella inexistentes. <p>Identifíquese para continuar</p></div>
      </Dialog>

      <Dialog
        visible={visibleGenerar}
        onHide={() => setVisibleGenerar(false)}
        footer={footer}
        header={header}>
        <div >
          <p style={{ marginBottom: '18px' }}>Datos adicionales:</p>
          {showEmbarazoRadiografia && (
            <>
              <div style={{ marginBottom: '10px' }}>
                <label style={{ marginBottom: '14px' }}>¿Se encuentra embarazada?</label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop:'15px'  }}>
                  <RadioButton inputId="embarazadaSI" name="embarazada" value="SI" onChange={(e) => setEmbarazo(e.value === 'SI')} checked={embarazo} />
                  <label htmlFor="embarazadaSI" className="ml-2">SI</label>
                  <RadioButton className="ml-5" inputId="embarazadaNO" name="embarazada" value="NO" onChange={(e) => setEmbarazo(e.value === 'SI')} checked={!embarazo} />
                  <label htmlFor="embarazadaNO" className="ml-2">NO</label>
                </div>
              </div>
              <div style={{ marginBottom: '10px', marginTop:'15px'  }}>
                <label style={{ marginBottom: '5px' }}>¿Acepta la toma de la radiografía?</label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop:'15px' }}>
                  <RadioButton inputId="radiografiaSI" name="radiografia" value="SI" onChange={(e) => setRadiografia(e.value === 'SI')} checked={radiografia} />
                  <label htmlFor="radiografiaSI" className="ml-2">SI</label>
                  <RadioButton className="ml-5" inputId="radiografiaNO" name="radiografia" value="NO" onChange={(e) => setRadiografia(e.value === 'SI')} checked={!radiografia} />
                  <label htmlFor="radiografiaNO" className="ml-2" >NO</label>
                </div>
                <div >
                  <p style={{ marginBottom: '18px' , marginTop:'15px' }}>¿Insertar firma y huella en el documento?</p>
                </div>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default NuevoGenerar;
