import React, { useState, useEffect, useRef, useContext } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import { DialogValidaciones } from "./Dialogs/DialogValidaciones";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";
import { transformData } from "../../../../Services/ProductosServicios/servicios";
import MultiLevelTreeSelectWithAPIClinica from "./SelectTipoServicioClinica";
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import './DiseñoTabla.css'

import DialogCoorporativo from './Dialogs/DialogCorporativo'
import { useDispatch } from "react-redux";
import { ServicioContext } from "../../../../Components/Clinica/ProductoServicio/Providers/ServicioProvider";


export default function PageAgregarServiciosCoorporativo() {

   const { http } = AuthUser()
   const toast = useRef(null);

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const { perfilesContext, setPerfilesContext, agregarElemento, serviciosContext, setServiciosContext
      , setServiciosValidacionContext, filtrarDatosSeleccionados, serviciosValidacionContext,borrarElemento} = useContext(ServicioContext);

   const [showInput, setShowInput] = useState(false);
   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [visibleDetalle, setVisibleDetalle] = useState(false)
   const [documentos, setDocumentos] = useState([]);

   // MIS VARIABLES (Guardan los datos que luego se deben enviar)
   const [NombreServicio, setNombreServicio] = useState();


   // FUNCION PARA INCREMENTAR EL ARRAY

   // DATOS Y FUNCIONES DEL POP UP
   const [VerDialog, setVerDialog] = useState(false);
   const [RowDataActual, setRowDataActual] = useState(null);
   const [TipoDialog, setTipoDialog] = useState(0)

   const AbrirDialog = (rowData, Tipo, PrecioReferencial) => {
      return (
         <div style={{ gap: '5px' }}>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <button
                  style={{ backgroundColor: "rgb(235,235,255)", maxWidth: '120px' }}
                  className="ElementoSeleccionarBTNN w-full"
                  onClick={() => {
                     setVerDialog(true);
                     setRowDataActual(rowData);
                     setTipoDialog(Tipo)
                  }}
               >
                  Seleccionar
               </button>
            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
            <p className="ElementoPrecioReferencial">S/. {perfilesContext[rowData.Id - 1][PrecioReferencial].toFixed(2)}</p>

            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <Button className="bg-white border-none"
                  icon="pi pi-check-square text-blue-700 text-xl"
                  onClick={() => {
                     setVisibleDetalle(true);
                     setRowDataActual(rowData);
                     setTipoDialog(Tipo)
                  }}></Button>
            </div>
         </div>
      );
   };


   const listarComentarios = () => {
      let ListaComentarios = ""
      perfilesContext.map(item => ListaComentarios = item.Entrada?.[0]?.comentario)
   }

   const DataEnvio = ({
      "nombre": NombreServicio,
      "clinica_servicio_id": selectedTipoServicio,
      "tiempo_venta": 1,
      "perfiles": perfilesContext.map(profile => ({
         "nombre": profile.NameTipoPerfil,
         "entrada": {
            "areas_medicas": profile?.Entrada?.[0]?.OnlyKeys?.filter(item => item.startsWith('1')).map(item => (
               {
                  "id": item.slice(2), // Elimina los primeros dos caracteres
                  "parcialmente": 1,
                  "comentario": "",
               }
            )),
            "capacitaciones": [],
            "examenes": profile?.Entrada[0]?.OnlyKeys?.filter(item => item.startsWith('3')).map(item => (
               {
                  "id": item.slice(2), // Elimina los primeros dos caracteres
                  "parcialmente": 1
               }
            )),
            "documentos": profile.documentosEntrada,
            "validaciones": profile.validacionesEntrada.length > 0 ? profile.validacionesEntrada : []
         },
         "rutina": {
            "areas_medicas": profile.Rutina[0].OnlyKeys.filter(item => item.startsWith('1')).map(item => (
               {
                  "id": item.slice(2), // Elimina los primeros dos caracteres
                  "parcialmente": 1
               }
            )),
            "capacitaciones": [],
            "examenes": profile.Rutina[0].OnlyKeys.filter(item => item.startsWith('3')).map(item => (
               {
                  "id": item.slice(2), // Elimina los primeros dos caracteres
                  "parcialmente": 1
               }
            )),
            "documentos": profile.documentosRutina,
            "validaciones": profile.validacionesRutina > 0 ? profile.validacionesRutina : []
         },
         "salida": {
            "areas_medicas": profile.Salida[0].OnlyKeys.filter(item => item.startsWith('1')).map(item => (
               {
                  "id": item.slice(2), // Elimina los primeros dos caracteres
                  "parcialmente": 1
               }
            )),
            "capacitaciones": [],
            "examenes": profile.Salida[0].OnlyKeys.filter(item => item.startsWith('3')).map(item => (
               {
                  "id": item.slice(2), // Elimina los primeros dos caracteres
                  "parcialmente": 1
               }
            )),
            "documentos": profile.documentosSalida,
            "validaciones": profile.validacionesSalida > 0 ? profile.validacionesSalida : []
         }
      }))
   });

   const sendData = (aux) => {
      console.log(perfilesContext)
      const data = ({
         "nombre": NombreServicio,
         "clinica_servicio_id": selectedTipoServicio,
         "tiempo_venta": 1,
         "perfiles": perfilesContext.map(profile => ({
            "nombre": profile.NameTipoPerfil,
            "entrada": {
               "areas_medicas": profile?.Entrada?.[0]?.OnlyKeys?.filter(item => item.startsWith('1')).map(item => (
                  {
                     "id": item.slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     "comentario": "",
                  }
               )),
               "capacitaciones": [],
               "examenes": profile?.Entrada[0]?.OnlyKeys?.filter(item => item.startsWith('3')).map(item => (
                  {
                     "id": item.slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1
                  }
               )),
               "documentos": profile.documentosEntrada,
               "validaciones": profile.validacionesEntrada.length > 0 ? profile.validacionesEntrada : []
            },
            "rutina": {
               "areas_medicas": profile.Rutina[0].OnlyKeys.filter(item => item.startsWith('1')).map(item => (
                  {
                     "id": item.slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1
                  }
               )),
               "capacitaciones": [],
               "examenes": profile.Rutina[0].OnlyKeys.filter(item => item.startsWith('3')).map(item => (
                  {
                     "id": item.slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1
                  }
               )),
               "documentos": profile.documentosRutina,
               "validaciones": profile.validacionesRutina.length > 0 ? profile.validacionesRutina : []
            },
            "salida": {
               "areas_medicas": profile.Salida[0].OnlyKeys.filter(item => item.startsWith('1')).map(item => (
                  {
                     "id": item.slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1
                  }
               )),
               "capacitaciones": [],
               "examenes": profile.Salida[0].OnlyKeys.filter(item => item.startsWith('3')).map(item => (
                  {
                     "id": item.slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1
                  }
               )),
               "documentos": profile.documentosSalida,
               "validaciones": profile.validacionesSalida.length > 0 ? profile.validacionesSalida : []
            }
         }))
      });
      console.log(data)
      http
         .post('clinica/paquete/create', data)
         .then((response) => {
            showToast('success', 'Success', 'El paquete fue creado correctamente')
            navigate('/productosyservicios/servicios')
         })
         .catch((error) => {
            console.error("algo salio mal", error);
            showToast('error', 'Error', 'El paquete no pudo ser creado correctamente')
         })
   }

   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const handleTipoServicioSelect = tipoServicioId => {
      setSelectedTipoServicio(tipoServicioId);
   };


   // Transformar data

   const getServicios = async () => {
      await http.get('clinica/operaciones/servicio/get')
         .then((response) => {
            setServiciosContext(transformData([response.data]))
         })
         .catch((error) => {
            console.error(error)
         })
   }

   const getDocumentos = async () => {
      await http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const handleKeyDown = (e) => {
      if (e.key === "Escape") {
         setShowInput(false);
      }
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getServicios(),
            getDocumentos()
         ])
      } catch (error) {

      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
      document.addEventListener("keydown", handleKeyDown);
      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
   }, []);


   const onCellEditComplete = (e) => {
      let { rowData, newValue, field, originalEvent: event } = e;
      rowData[field] = newValue
   };

   const cellEditor = (options) => {
      return textEditor(options);
   };
   const textEditor = (options) => {
      return(<div >
         <InputText
         style={{ maxHeight: '28px' }}
         placeholder="Ingrese un nombre"
         type="text" value={options.value}
         onChange={(e) => options.editorCallback(e.target.value)}
         
      />;
       
      </div>) 
   };

   const TituloServicioPrecio = (
      
      <div className="contenedor-tarjetas">
         <div className="flex align-items-center justify-content-around" style={{ width: '100%', height: '100%' }}>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <p style={{ padding: '0px 0px 0px 8px' }}>Servicios</p>
            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
               <p style={{ textAlign: 'center', padding: '0px 8px 0px 0px' }}>Precio Referencial</p>
            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
               <p style={{ textAlign: 'center', padding: '0px 8px 0px 0px' }}>Validaciones</p>
            </div>
         </div>
      </div>


   )
   const headerGroup = (
      <ColumnGroup>
         <Row style={{ width: '100%' }}>
            <Column header="" field="lastYearSale" />
            <Column header="Entrada" colSpan={2} />
            <Column header="Rutina" colSpan={2} />
            <Column header="Salida" colSpan={2} />
         </Row>
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header={TituloServicioPrecio} field="thisYearSale" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
         </Row>
      </ColumnGroup>
   );

   const [filtro, setFiltro] = useState(null)

   const busqueda = (e) => {
      if (e.target.value !== '') {
         let datosFiltrados = perfilesContext.filter(data => data.NameTipoPerfil.toLowerCase().includes(e.target.value.toLowerCase()))
         setFiltro(datosFiltrados)
      } else {
         setFiltro(perfilesContext)
      }
   }

   const handleSubmit = () => {
      sendData(DataEnvio);
      listarComentarios()
   }
   return (
      <div className="add__container">
         <Toast ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Nuevo Servicio - Coorporativo</h4>
            <p className="add__container_header_p">
               Adminístralo a tu mejor manera
            </p>
         </div>
         <div className="add__container_main">
            <div className="add__container_main_content_header bg-white ContenedorWhiteAHP">
               <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Nombre:
                     </label>
                     <span className="p-input-icon-right">
                        <i className="pi pi-pencil" />
                        <InputText
                           placeholder="Paquete anual- Primax"
                           className="add__container_main_content_header_inputs_singular_input"
                           value={NombreServicio}
                           onChange={(e) => setNombreServicio(e.target.value)}
                        />
                     </span>
                  </div>
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Tipo de servicio:
                     </label>
                     <MultiLevelTreeSelectWithAPIClinica
                        selectedTipoServicio={selectedTipoServicio}
                        onTipoServicioSelect={handleTipoServicioSelect}
                     />
                  </div>
               </div>
               <div className="add__container_main_content_header_group">
                  <div className="contenedor-buscador flex flex-row w-full gap-3" style={{ justifyContent: 'space-between', marginTop: '20px' }}>
                     <span className=" p-input-icon-left flex bg-transparent w-full justify-content-center">
                        <i className="pi pi-search" />
                        <InputText placeholder="Buscar..." className="w-full" onChange={(e) => { busqueda(e) }} />
                     </span>
                  </div>
               </div>
            </div>
            { }
            <div className="add__container_main_content_body">
               <div className="add__container_main_content_body_container">
                  <ScrollPanel
                     style={{ width: "100%", padding: '1rem', alignItems: "center" }}
                     className="custombar2 p-scrollpanel-bar-table"
                  >
                     <div className="card p-fluid" style={{ height: 'auto', backgroundColor: 'transparent' }}>
                        <div className="Cabezera_Tabla_AHP-2">
                           <DataTable
                              className="Cabezera_Tabla_AHP"
                              value={filtro ? filtro : perfilesContext}
                              headerColumnGroup={headerGroup}
                              editMode="cell"
                              tableStyle={{ width: '100%', height: 'auto' }}
                              size="small"
                           >
                              <Column field="NameTipoPerfil" header="Tipo Perfil:" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={(rowData) => (
                                 <>
                                    {rowData.NameTipoPerfil}
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" onClick={() => borrarElemento(rowData.Id)} />
                                 </>
                              )} ></Column>
                              <Column header="Entrada:" body={(rowData) => AbrirDialog(rowData, 1, "PrecioReferencialEntrada")}></Column>
                              <Column header="Rutina:" body={(rowData) => AbrirDialog(rowData, 2, "PrecioReferencialRutina")} ></Column>
                              <Column header="Salida:" body={(rowData) => AbrirDialog(rowData, 3, "PrecioReferencialSalida")}></Column>
                           </DataTable>
                           {/* NO ELIMINAR */}
                           <div className="">
                              <div className="ParteInferiorTablar">
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                              </div>
                           </div>
                           {/* NO ELIMINAR */}

                           <DialogCoorporativo
                              className=""
                              visible={VerDialog}
                              setVisible={setVerDialog}
                              rowData={RowDataActual}
                              TipoDialog={TipoDialog}
                              DataGeneral={perfilesContext}
                              setDataGeneral={setPerfilesContext}
                              DataServicios={serviciosContext}
                              documentos={documentos}
                              DataEnvio={DataEnvio}
                              setServiciosValidacion={setServiciosValidacionContext}
                              filtrarDatosSeleccionados={filtrarDatosSeleccionados}
                           />
                        </div>
                     </div>
                  </ScrollPanel>
               </div>
               <div className="add__container_main_content_footer_one mt-4 md:mt-2 mb-3 md:mb-1">
                  <button
                     className="add__container_main_content_footer_one_btn"
                     onClick={agregarElemento}
                  >
                     <i className="pi pi-plus bg-colo-btn"></i>
                     <p className="bg-colo-btn">Agregar nuevo perfil</p>
                  </button>
               </div>
               <div className="add__container_main_content_footer_two">
                  <button className="add__container_main_content_footer_two_btn ">
                     <Link
                        to="/productosyservicios/servicios/"
                        className="bg-colo-btn"
                        style={{ textDecoration: "none" }}
                     >
                        Cancelar
                     </Link>
                  </button>
                  <button className="button__add_new" onClick={handleSubmit}>Guardar</button>
               </div>
            </div>
         </div>
         <DialogValidaciones
            visible={visibleDetalle}
            setVisible={setVisibleDetalle}
            serviciosValidacionContext={serviciosValidacionContext}
            TipoDialog={TipoDialog}
            rowData={RowDataActual}
            DataGeneral={perfilesContext}
            setDataGeneral={setPerfilesContext}
            filtrarDatosSeleccionados={filtrarDatosSeleccionados}
            DataServicios={serviciosContext}
         // DataEnvio={DataEnvio}
         />
      </div>
   );
} 