import React, { useEffect, useState } from 'react'
import { Column } from 'primereact/column'
import { TreeTable } from 'primereact/treetable'
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";

export default function TablaServicios({ data, visible, setVisible, validaciones, changeValidaciones }) {
   const [globalFilter, setGlobalFilter] = useState('');
   const [selectedNodeKeys, setSelectedNodeKeys] = useState([]);

   const handleChangeGuardar = () => {
      changeValidaciones(selectedNodeKeys);
      setVisible(false);
   }
   const handleChangeCancelar = () => {
      setVisible(false);
      setSelectedNodeKeys(null);
   }

   const HeaderDialog = (
      <div className="p-d-flex p-jc">
         <div className="barras-espaciado h-auto p-d-flex p-flex-column p-jc-start p-flex-wrap" style={{ fontFamily: 'Montserrat', width: '100%', display: 'flex' }}>
            <div className="icono-superior" style={{ width: '70px', height: '60px', borderRadius: '10px', backgroundColor: '#EBEBFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            </div>
            <div className="w-full h-auto p-d-flex p-ai-start p-jc-start" style={{ marginTop: '13px', fontSize: '18px', color: '#5555D8', display: 'flex', alignItems: 'center' }}>
               <p style={{ fontWeight: '700', marginLeft: '7px' }}>Seleccionar servicios</p>
            </div>
         </div>

      </div>
   );

   const FooterDialog = (
      <div className="w-full h-auto flex align-items-center justify-content-end gap-2">
         <div className="flex align-items-center" style={{ marginRight: 'auto' }}>
         </div>
         <div className="botones-container" style={{ display: 'flex', marginTop: '1rem' }}>
            <Button
               onClick={handleChangeCancelar}
            >
               Cancelar
            </Button>
            <Button
               onClick={handleChangeGuardar}
            >
               Guardar
            </Button>
         </div>
      </div>
   )

   useEffect(() => {
      setSelectedNodeKeys(validaciones)
   }, [validaciones])

   return (
      <div className="tree-table-container">
         <Dialog
            header={HeaderDialog}
            footer={FooterDialog}
            visible={visible}
            style={{ width: '30vw', height: '90%' }}
            onHide={() => setVisible(false)}
         >
            <TreeTable
               // className="custom-tree-table"
               selectionMode="multiple"
               selectionKeys={selectedNodeKeys}
               onSelectionChange={(e) => setSelectedNodeKeys(e.value)}
               globalFilter={globalFilter}
               value={data}
               metaKeySelection={false}
            >
               <Column field="nombre" header="Nombre" expander></Column>
            </TreeTable>
         </Dialog>
      </div>
   )
}
