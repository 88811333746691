import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Pruebas from './Pruebas';
import AntecedentesH from './Antecedentes';
import Conclusion from './Conclusion';
import AuthUser from '../../../AuthUser';

export default function PageAltura() {
   const { http } = AuthUser();

   // const [evaluadores, setEvaluadores] = useState([]);
   // const getEvaluadores = () => {
   //    http.get(`medicina_general/examen_altura/evaluadores/get`)
   //       .then((response) => {
   //          console.log(response);
   //          if (response?.data?.data) {
   //             setEvaluadores(response.data.data)
   //          }
   //       })
   //       .catch((error) => {
   //          console.error(error);
   //       })
   // }

   // useEffect(() => {
   //    getEvaluadores();
   // }, [])


   return (
      <div >

         <Accordion className='Acordeon7u7Bonito'>
            <AccordionTab header={
               <div className='flex flex-row gap-2 align-items-center'>
                  <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" alt='img_1' />
                  <div className='flex flex-column gap-1'>
                     <span>Antecedentes / Hallazgos</span>
                     <h6 style={{ fontWeight: "400", fontSize: "14px", color: "#393838" }}>Antecedentes personales, Hallazgos positivos al externo físico</h6>
                  </div>
               </div>
            } >
               <AntecedentesH />
            </AccordionTab>

            <AccordionTab header={
               <div className='flex flex-row gap-2 align-items-center'>
                  <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" alt='img_2' />
                  <div className='flex flex-column gap-1'>
                     <span>Pruebas Específicas</span>
                     <h6 style={{ fontWeight: "400", fontSize: "14px", color: "#393838" }}>Pruebas específicas de enfermedad vestibular</h6>
                  </div>
               </div>
            } >
               <Pruebas />
            </AccordionTab>

            <AccordionTab header={
               <div className='flex flex-row gap-2 align-items-center'>
                  <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" alt='img_3' />
                  <div className='flex flex-column gap-1'>
                     <span>Conclusiones y recomendaciones</span>
                     <h6 style={{ fontWeight: "400", fontSize: "14px", color: "#393838" }}>Conclusiones</h6>
                  </div>
               </div>
            } >
               <Conclusion />
            </AccordionTab>
         </Accordion>
      </div>
   )
}