import React, { useState ,useEffect} from "react";
import { InputText } from "primereact/inputtext";
import LaboratorioTabla from "../../../Page/Laboratorio/LaboratorioTabla";
import { InputNumber } from 'primereact/inputnumber';
import AuthUser from "../../../AuthUser";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
import { useContext } from "react";
export default function ViewTriglicerido({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
    const { http } = AuthUser();

    const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
 
    const [prueba, setPrueba] = useState({})
    const [nombreArchivo, setNombreArchivo] = useState(null);
    const [file, setFile] = useState(null);
    const [indexSup, setIndexSup] = useState(0);
    const [indexSub, setIndexSub] = useState(0);






    const estiloA = {
        background: 'green',
        color: 'white'
    }
    const estiloB = {
        background: 'red',
        color: 'white'
    }

    const [parametros, setParametros] = useState
        ([
            {
                prueba: 'Triglicerido',
                resultado: 0,
                um: 'mg/dl',
                rango_inicial: 70,
                rango_final: 150,
                valor_normal: '70 - 150',
                observaciones:""
            }
        ])
        const handleCreate = () => {
            const formData = new FormData();
            formData.append("examen_id", tipoExamen);
            formData.append("prueba[examen_id]", tipoPrueba);
            formData.append("prueba[estado]", 1);
            formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
            formData.append("prueba[cantidad_parametros]", parametros?.length);
            formData.append("prueba[parametros]", JSON.stringify(parametros));
            formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));
      
            http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
               .then(response => {
                  console.log(response)
                  showToast("success","Creado Correctamente",response?.data?.resp)
               })
               .catch(error => {
                  console.log(error)
                  showToast("Error","Error de Guardar",error?.data?.resp)
               })
         }
    
      
         const changeExamenContext = (superior, inferior, auxParametros) => {
            setExamenesContext(examenesContext.map((elementoSup, index) => {
               if (index === superior) {
                  let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
                     if (indexAux === inferior) {
                        return {
                           ...prueba,
                           parametros: auxParametros,
                           estado: 1,
                           cantidad_parametros: auxParametros?.length,
                           perfil: auxParametros?.length > 1 ? 2 : 1
                        }
                     }
                     return prueba;
                  })
                  return { ...elementoSup, pruebas: auxPrueba };
               }
               return elementoSup;
            }))
         }

    const [inputValue, setInputValue] = useState('');
      
    useEffect(() => {
        console.log(examenesContext)
        let sup = 0;
        let inf = 0;
        let auxPruebas = examenesContext.find((examen, index) => {
           if (examen.examen_id === tipoExamen) {
              setIndexSup(index);
              sup = index;
              console.log(index)
           }
           return examen.examen_id === tipoExamen
        });
        let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
           if (prueba.examen_id === tipoPrueba) {
              setIndexSub(index);
              inf = index;
              console.log(index)
           }
           return prueba.examen_id === tipoPrueba
        });
        if (auxPrueba?.archivo) {
           setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
        }
        setPrueba(auxPrueba);
        console.log("PRUEBA", auxPrueba)
        console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
        let auxParametros = typeof auxPrueba?.parametros === 'string' ?  JSON.parse(auxPrueba?.parametros): auxPrueba?.parametros
       
        if (auxPrueba && auxParametros?.length > 0 ) {
            console.log("si entron ")
            setParametros(auxParametros);
        }
        changeExamenContext(sup, inf, auxParametros);
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);


    const handleInputChange = (index, e) => {
        setInputValue(e.value);

        const tempTask = [...parametros];
        tempTask[index].resultado = e.value;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
    };
    const handleInputInicial = (index, e) => {
        setInputValue(e.value);

        const tempTask = [...parametros];
        tempTask[index].rango_inicial = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
    };
    const handleInputFinal = (index, e) => {
        setInputValue(e.value);

        const tempTask = [...parametros];
        tempTask[index].rango_final = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
    };
    const handleObservaciones = (index, e) => {
        const tempTask = [...parametros];
        tempTask[index].observaciones= e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
    return (
        <div className="flex flex-column w-full h-full" style={{ padding: '20px' }}>
            <div className="flex flex-column w-full">
                <table className="tabla">
                    <tr>
                        <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>Prueba</th>
                        <th className="encabezado" style={{ width: '25%', textAlign: "center" }}>Resultado</th>
                        <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>U.M</th>
                        <th className="encabezado">Rango</th>
                        <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>Valor Normal</th>
                    </tr>
                    {
                        parametros.map((parametro, index) => {
                            return (
                                <tr>
                                    <td className="dato">{parametro.prueba}</td>
                                    <td className="dato">
                                        <InputText
                                            inputStyle={
                                                parametro.resultado >= parametro.rango_inicial &&
                                                    parametro.resultado <= parametro.rango_final ?
                                                    estiloA : estiloB
                                            }
                                            placeholder="Resultado"
                                            value={parametro.resultado}
                                            onChange={(e) => handleInputChange(index, e.target)}
                                            minFractionDigits={2} maxFractionDigits={5}
                                        />
                                    </td>
                                    <td className="dato">{parametro.um}</td>
                                    <td className="dato">
                                        <InputText className='w-3' value={parametro.rango_inicial} 
                                        onChange={(e) => handleInputInicial(index, e.target.value)}/>
                                        <span> - </span>
                                        <InputText className='w-3' value={parametro.rango_final} 
                                        onChange={(e) => handleInputFinal(index, e.target.value)}/>
                                    </td>
                                    <td className="dato">{parametro.valor_normal}</td>
                                </tr>
                            )
                        })
                    }
                    {/* <tr>
                        <td className="dato">Triglicéridos</td>
                        <td className="dato">
                            <InputNumber
                                placeholder="Resultado"
                                value={inputValue}
                                onChange={handleInputChange}
                                className={inputClassName} 
                                minFractionDigits={2} maxFractionDigits={5}
                            /></td>
                        <td className="dato">mg/dl</td>
                        <td className="dato">
                            <InputText className='w-3' />
                            <span> - </span>
                            <InputText className='w-3' /></td>
                        <td className="dato">Nivel deseable: 70 - 150</td>
                    </tr>
                    <tr>
                        <td className="dato"></td>
                        <td className="dato"></td>
                        <td className="dato"></td>
                        <td className="dato"></td>
                        <td className="dato"></td>
                    </tr> */}
                </table>
            </div>
            <LaboratorioTabla value={examenesContext?.[2]?.pruebas?.[3]?.parametros?.[0]?.observaciones} setValue={handleObservaciones} create={handleCreate}/>
        </div>
    );
}
