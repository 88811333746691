import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import Icon2 from '../../../../../Images/IconAz.png';
import IconGerente from '../../../../../Images/iconGerenteGeneral.svg';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { P } from "react-flaticons";
import { useParams } from "react-router-dom";
// import { width } from '@mui/system';


const AreasGenerales = ({ NombreGerente, setNombreGerente, personalGeneral, handleCrearGerente, NombreGerenteBD, HandleGetGerente
    //GerenteGeneral, setGerenteGeneral
   
}) => {
    
    const { id } = useParams();
    console.log("imprimir", NombreGerente)
    const [GerenteGeneral, setGerenteGeneral] = useState({
        local_id: id,
        personal_id: 0
     })
    const [openColocarNombre, setOpenColocarNombre] = useState(false)
    const options =
        personalGeneral?.map((item) => (
            { name: item.persona.apellido_paterno + " " + item.persona.apellido_materno + ", " + item.persona.nombres, code: item.id }
        ))

    const HandleChangeGerente = (e) => {
        setNombreGerente(e.value);
        setGerenteGeneral({ ...GerenteGeneral, personal_id: e.value.code })
        console.log("eee", e)
    }
    const dialogFooter = (
        <div className="flex justify-content-center pt-5">
            <Button
                label="Cancelar"
                style={{
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    width: "-webkit-fill-available",
                }}
                onClick={() => {
                    setOpenColocarNombre(false);
                }}
            />
            <Button
                label="Guardar"
                style={{
                    backgroundColor: "#5555D8",
                    width: "-webkit-fill-available",
                }}
                onClick={() => {
                    
                    handleCrearGerente(GerenteGeneral);
                    setOpenColocarNombre(false);
                }}

            />
        </div>
    );


    const dialogHeader = (
        <img src={IconGerente} alt="" icon="text-indigo-500" className=" border-round-xl  bg-indigo-100 p-2" />
    )


    return (

        <>
            <div onClick={() => {setOpenColocarNombre(true)}} className="flex align-items-center gap-3 border-round-2xl border-2 border-gray-100 p-2 hover:border-cyan-300">
                <div className=" p-2 border-round-lg">
                    <img src={Icon2} alt="" icon="text-cyan-300" className=" border-round-xl "
                        style={{
                            padding: "10px",
                            backgroundColor: " #BBEBFF"
                        }} />
                </div>
                <div className="flex-1">
                    <p className="text-cyan-300 font-bold text-sm">Gerente General</p>
                    <div className='flex'>
                        <i className="pi pi-pencil text-cyan-300 "></i>
                        <p className="text-sm text-cyan-300">{NombreGerenteBD == null ? "Colocar nombre" : NombreGerenteBD}</p>
                    </div>
                </div>
                <div>
                    <div className=" flex justify-content-end gap-3">
                        <i className="pi pi-plus text-400 text-cyan-300 font-semibold" />
                        <i className="pi pi-ellipsis-v text-400 font-semibold" />
                    </div>
                </div>
            </div>
            <Dialog style={{ minWidth: '400px' }} header={dialogHeader} footer={dialogFooter} visible={openColocarNombre} onHide={() => {
                    setOpenColocarNombre(false);
                }}>
                <h3 className="text-indigo-500">Gerente general</h3>
                <p className="font-light">Coloque al jefe encargado del local</p>
                <div className="flex flex-column px-2 pt-4 gap-2">
                    <label className="font-bold" htmlFor="colocarNombre">Gerente general</label>
                    <Dropdown
                        value={NombreGerente}
                        options={options}
                        onChange={(e) => HandleChangeGerente(e)}
                        optionLabel="name"
                        placeholder="Seleccionar Gerente General"
                        filter
                        className="w-full" />
                </div>
            </Dialog>
        </>

    );  
}
export default AreasGenerales;

