import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Logo from "../../../../../Images/Icon-folder.svg"
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import '../../../../../Style/ScrollPanelDemo.css';
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";

export default function BotonPeriodico({ dataPeriodico, nombrePaquete }) {


   const transformData = (paquetes) => {
      if (!paquetes[0]) {
         return [];
      } else {
         const areas = ['areas_medicas', 'capacitaciones', 'examenes'];

         return areas.map((area, index) => {
            const data = {
               key: `node-${index}`,
               data: {
                  nombre: (index === 0 && "Áreas Médicas") || (index === 1 && "Capacitaciones") || (index === 2 && "Exámenes de ayuda al diagnóstico"),
                  description: `This is node ${index}`,
               },
               children: [],
            };

            const areaData = paquetes.flatMap((paquete) => paquete[area]);

            console.log(areaData)

            const areaNodes = transformDataChild(areaData, `node-${index}`);

            data.children = areaNodes;
            return data;
         });
      }
   };

   function transformDataChild(data, parentKey) {
      const children = ['Psicología', 'Triaje', 'Examen de Laboratorio', 'Examen Radiológico', 'Laboratorio Molecular', 'Laboratorio Clínico', 'Oftalmología'];
      return data.map((item, index) => {
         const isChild = children.includes(item.nombre);

         const node = isChild
            ? {
               key: `${parentKey}-${index}`,
               data: {
                  nombre: item.nombre.replace('_', ' '),
               },
               children: [],
            }
            : {
               key: `${parentKey}-${index}`,
               data: {
                  nombre: item.nombre,
               },
               children: [],
            };

         if (item.children && item.children.length > 0) {
            node.children = transformDataChild(item.children, `${parentKey}-${index}`);
         }

         return node;
      });
   }


   console.log(transformData([dataPeriodico]))

   const DateForTreeTable = transformData([dataPeriodico])
   DateForTreeTable?.splice(1, 1);

   const [visible, setVisible] = useState(false);
   const footerContent = (
      <div
         className="flex flex-column"
         style={{ justifyContent: "center", alignItems: "center" }}
      >
         <Button
            label="Aceptar"
            onClick={() => setVisible(false)}
            autoFocus
            style={{ width: "180px" }}
         />
      </div>
   );

   const Subtitulos = {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#5555D8",
   };

   const Subtitulos2 = {
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "18px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#ABABAB",
   };

   const Subtitulos3 = {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "17px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#344054",
   };

   const Option = {
      background: "white",
      height: "40px",
      background: "#F8F8F8",
      border: "0.5px solid #D0D5DD",
      borderRadius: "7px",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "17px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#7B8794",
   };

   return (
      <div className="card flex justify-content-center align-items-center mb-0">
         <Button
            className="p-button-text"
            label="Ver detalles"
            icon="pi pi-info-circle"
            onClick={() => setVisible(true)}
            style={{ height: "4.123711340206185vh" }}
         />
         <Dialog
            visible={visible}
            style={{ width: "611px" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
         >
            <div>
               {/* Logo */}
               <div
                  className="flex"
                  style={{
                     background: "#EBEBFF",
                     width: "60px",
                     height: "60px",
                     borderRadius: "10px",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <img src={Logo} alt="" />
               </div>
               {/* Detalle de servicio hasta periodo de tiempo */}
               <div
                  className="flex flex-column gap-2"
                  style={{ margin: "10px 10px 20px 0px" }}
               >
                  <span style={Subtitulos}>Detalle de periódico</span>
                  <span style={Subtitulos2}>{nombrePaquete}</span>
                  {/* <span style={Subtitulos3}>Periodo de tiempo</span>
                  <select className="" style={Option} disabled >
                     <option value="Anual">Anual</option>
                  </select> */}
               </div>
               {/* Detalle de perfil Buscador */}
               <div className="flex flex-column gap-3">
                  {/* <span style={Subtitulos}>Detalle de perfil</span> */}
                  <span className="p-input-icon-left flex w-full">
                     <i className="pi pi-search " />
                     <InputText
                        placeholder="Buscador"
                        className="flex w-full"
                     />
                  </span>
               </div>
               <div
                  className="card scrollpanel-demo"
                  style={{ marginTop: "20px" }}
               >
                  <div className="flex flex-column md:flex-row gap-5">
                     <div className="flex-auto">
                        <ScrollPanel
                           style={{ width: "100%", height: "380px" }}
                           className="custombar1"
                        >
                           <div
                              className="middle"
                              style={{ padding: "10px 10px 10px 10px" }}
                           >
                              <TreeTable
                                 value={DateForTreeTable}
                              >
                                 <Column field="nombre" expander></Column>
                              </TreeTable>
                           </div>
                        </ScrollPanel>
                     </div>
                  </div>
               </div>
            </div>
         </Dialog>
      </div>
   );
}
