import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useState,useContext,useEffect } from "react";
import AuthUser from '../../../../AuthUser';
import { LaboratorioContext } from '../../LaboratorioProvider';
export default function ViewMarihuana({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {

    const { http } = AuthUser();
    const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
    const [prueba, setPrueba] = useState({})
    const [nombreArchivo, setNombreArchivo] = useState(null);
    const [file, setFile] = useState(null);
    const [indexSup, setIndexSup] = useState(0);
    const [indexSub, setIndexSub] = useState(0);
    const [value, setValue] = useState('');
    const [parametros, setParametros] = useState([
        {
            prueba: 'Hepatitis A - IGG',
            resultado: "",
        },

    ])
    const [selectedMarihuana, setSelectedMarihuana] = useState(null);
    const marihuana = [
        { name: 'Negativo', code: 'NG' },
        { name: 'Positivo', code: 'PS' },
    ];
    const handleSelec = (index, e) => {
        const tempTask = [...parametros];
        tempTask[index].resultado = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
    };
    const handleCreate = () => {
        const formData = new FormData();
        formData.append("examen_id", tipoExamen);
        formData.append("prueba[examen_id]", tipoPrueba);
        formData.append("prueba[estado]", 1);
        formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
        formData.append("prueba[cantidad_parametros]", parametros?.length);
        formData.append("prueba[parametros]", JSON.stringify(parametros));
        formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));

        http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
            .then(response => {
                console.log(response)
                showToast("success","Creado Correctamente",response?.data?.resp)
            })
            .catch(error => {
                console.log(error)
                showToast("Error","Error de Guardar",error?.data?.resp)
            })
    }

    const changeExamenContext = (superior, inferior, auxParametros) => {
        setExamenesContext(examenesContext.map((elementoSup, index) => {
            if (index === superior) {
                let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
                    if (indexAux === inferior) {
                        return {
                            ...prueba,
                            parametros: auxParametros,
                            estado: 1,
                            cantidad_parametros: auxParametros?.length,
                            perfil: auxParametros?.length > 1 ? 2 : 1
                        }
                    }
                    return prueba;
                })
                return { ...elementoSup, pruebas: auxPrueba };
            }
            return elementoSup;
        }))
    }

    useEffect(() => {
        console.log(examenesContext)
        let sup = 0;
        let inf = 0;
        let auxPruebas = examenesContext.find((examen, index) => {
            if (examen.examen_id === tipoExamen) {
                setIndexSup(index);
                sup = index;
                console.log(index)
            }
            return examen.examen_id === tipoExamen
        });
        let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
            if (prueba.examen_id === tipoPrueba) {
                setIndexSub(index);
                inf = index;
                console.log(index)
            }
            return prueba.examen_id === tipoPrueba
        });
        if (auxPrueba?.archivo) {
            setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
        }
        setPrueba(auxPrueba);
        console.log("PRUEBA", auxPrueba)
        console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
        let auxParametros = typeof auxPrueba?.parametros === 'string' ? JSON.parse(auxPrueba?.parametros) : auxPrueba?.parametros
        auxPrueba?.parametros && setParametros(auxParametros);
        changeExamenContext(sup, inf, auxParametros);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="flex flex-column w-full h-full">
            <div className="flex flex-column w-full" /*style={{ margin: '20px 10px 0px 10px' }}*/>
                <table className="tabla">
                    <tr>
                        <th className="encabezado" style={{ width: "50%" }}>Prueba</th>
                        <th className="encabezado" style={{ width: "50%" }}>Resultado</th>
                    </tr>
                    <tr>
                        <td className="dato">Hepatitis A - IGG</td>
                        <td className="dato">
                            <Dropdown
                                value={marihuana[examenesContext?.[5]?.pruebas?.[3]?.parametros?.[0]?.resultado == "NG" ? 0 : 1]}
                                onChange={(e) => handleSelec(0, e.value.code)}
                                options={marihuana}
                                optionLabel="name"
                                placeholder="Seleccione" />
                        </td>
                    </tr>
                </table>
            </div>
            <div className="flex flex-row gap-3 mt-2" style={{ justifyContent: 'end' }}>
                <Button label="Grabar" icon="fas fa-save" onClick={handleCreate}/>
                <Button label="Recuperar" icon="fas fa-undo" />
            </div>
        </div>
    );
}