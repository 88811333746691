import React, { useContext } from "react";
import {
   iconCANCELADO,
   iconNO_REALIZADO,
   iconPROCESO,
   iconREALIZADO,
} from "../../../../Services/importIcons";
import { GestionContext } from "../../../../Page/ClinicaRol/GestionAtencion/services/GestionContext";
import { useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";

const ResultadosTimeLine = () => {
   let navigate = useNavigate();
   const { pacienteContext, listRutasContext, atencionContext, setPageViewContext } = useContext(GestionContext);


   console.log("listaaa", atencionContext);

   const getStatusService = (status) => {

      const icons = {
         "-1": iconCANCELADO,
         0: iconNO_REALIZADO,
         1: iconPROCESO,
         2: iconREALIZADO,
         3: iconCANCELADO,
      };
      return icons[status] || null;
   };
   const getEstadoRevision = (status) => {

      const icons = {
         0: iconNO_REALIZADO,
         1: iconREALIZADO,
      };
      return icons[status] || null;
   };
   const switchFunction = (nombre) => {
      const optionMapping = {
         //[Nombre de area] : [indice de pageViewContext]
         Triaje: 0,
         Psicología: 1,
         EKG: 2,
         Oftalmología: 3,
         Audiometría: 4,
         "Examen Radiológico": 5,
         "Examen de Laboratorio": 6,
         Odontología: 7,
         Espirometría: 8,
         "Medicina General": 9,
         default: 0,
      };

      setPageViewContext(optionMapping[nombre] || optionMapping.default);
   };

   const goRevision = (data) => {
      console.log("dataaaa", data)
      switchFunction(data.nombre)
      navigate(`/atencion/revision/${pacienteContext.id}`);

   }
   return (
      <>
         <div className="rar_hr_time_line">
            {listRutasContext?.map((item, index) => {
               let hojaRuta = atencionContext?.find((v) => v?.nombre === item?.nombre);
               const formatDate = (dateString) => {
                  const date = new Date(dateString);
                  const day = date.getDate();
                  const month = date.getMonth() + 1;
                  const year = date.getFullYear();
                  const formattedDate = `${day}/${month}/${year}`;

                  return formattedDate;
               };
               const fechaInicioFormateada = formatDate(hojaRuta?.fecha_inicio);
               const fechaFinFormateada = formatDate(hojaRuta?.fecha_fin);

               const validarFecha = (dataString) => {
                  if (dataString === "31/12/1969") {
                     const vacío = "--";
                     return vacío;
                  }
                  else {
                     const fechaValida = dataString;
                     return fechaValida;
                  }
               }

               const fechaFinValida = validarFecha(fechaFinFormateada);
               const fechaInicioValida = validarFecha(fechaInicioFormateada);

               const validarHora = (dataString) => {
                  if (dataString === null) {
                     const vacío = "--";
                     return vacío;
                  }
                  else {
                     const horaValida = dataString;
                     return horaValida;
                  }
               }
               const horaInicio = validarHora(hojaRuta?.hora_inicio);
               const horaFin = validarHora(hojaRuta?.hora_fin);


               if (hojaRuta !== undefined) {
                  return (
                     <React.Fragment key={item.id}>
                        <div className="rar_hr_time_line_timeline">
                           <>
                              <div className="rar_hr_time_line_timeline_circle" />
                              <div className="rar_hr_time_line_timeline_lineal" />
                           </>

                        </div>

                        <div className="rar_hr_time_line_content">
                           {hojaRuta?.nombre !== "En proceso" ? (
                              <div className="rar_hr_time_line_content_ruta">
                                 <img src={item?.icon} alt="icon_ruta" />
                                 <label>{item?.nombre}</label>
                                 <br />
                              </div>
                           ) : (
                              <div className="rar_hr_time_line_content_ruta_active">
                                 <img src={item?.icon} alt="icon_ruta" />
                                 <label>{item?.nombre}</label>
                                 <br />
                              </div>
                           )}
                           {/* NOMBRE CON ICONO */}
                           <div className="rar_hr_time_line_content_status">
                              <span>{hojaRuta?.nombre}</span>
                              {hojaRuta?.nombre !== "En espera" && (
                                 <img
                                    src={getStatusService(hojaRuta?.estado_ruta)}
                                    alt="icon_status"
                                    style={{ height: "18px" }}
                                 />
                              )}
                              <br />
                           </div>
                           {/* FECHA */}
                           <div className="grid">
                              <div className="col-12 pb-0">
                                 {hojaRuta?.nombre !== "No realizado" &&
                                    hojaRuta?.nombre !== "En espera" &&
                                    hojaRuta?.nombre !== "No corresponde" && (
                                       <div className="rar_hr_time_line_content_fecha">
                                          <span>Inicio: {horaInicio + " - " + fechaInicioValida}</span>

                                          <br />
                                          <span>Fin: {horaFin + " - " + fechaFinValida}</span>
                                       </div>
                                    )}
                              </div>
                              <div className="col-6 pt-1">
                                 <Button disabled={hojaRuta.estado_ruta !== "2"} style={{ backgroundColor: "#EBEBFF", padding: "0" }} onClick={(e) => goRevision(hojaRuta)}>
                                 {hojaRuta?.estado_revision === "1" ?  (<Tag severity="success" >Revisado</Tag>) : (<Tag >Revisar</Tag>)}
                                 </Button>
                              </div>
                           </div>
                        </div>
                     </React.Fragment>
                  );

               }
               return <></>

            })}
         </div>
      </>
   );
};

export default ResultadosTimeLine;
