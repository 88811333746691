import React, { useState,useContext } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { SalesContext } from '../../SalesContext/SalesContext';

export default function S3ProductSalesTreeTable(props) {
    //Varibles de contexto
    const { sendPaquete, setSendPaquete} = useContext(SalesContext);
    //#region Varibles
    const [precioEdit, setPrecioEdit] = useState(null);
    const [visible, setVisible] = useState(true);

    //#endregion

    const imageTemplate = (node) => {
        const areaImageMap = {
            'node-0': (
                <img
                    className="p-1 bg-green-100 border-round-sm"
                    width="18px"
                    height="20px"
                    src="https://i.ibb.co/pf8gHP1/Icon.png"
                    alt="Imagen"
                />
            ),
            'node-1': (
                <img
                    className="p-1 bg-blue-100 border-round-sm"
                    width="18px"
                    height="20px"
                    src="https://i.ibb.co/b2qBVNG/Rectangle-1648.png"
                    alt="Imagen"
                />
            ),
            'node-2': (
                <img
                    className="p-1 bg-orange-100 border-round-sm"
                    width="18px"
                    height="20px"
                    src="https://i.ibb.co/gjW4TdM/Vector-1.png"
                    alt="Imagen"
                />
            ),
            'node-3': (
                <img
                    className="p-1 bg-pink-100 border-round-sm"
                    width="18px"
                    height="20px"
                    src="https://i.ibb.co/kD4BJBP/Vector.png"
                    alt="Imagen"
                />
            ),
        };

        const areaImageSrc = areaImageMap[node.key];

        if (areaImageSrc) {
            return (
                <div className="Ventas_TreeTable flex gap-1 align-items-center">
                    {areaImageSrc}
                    <p>{node.data.nombre}</p>
                </div>
            );
        } else {
            return <p className="ml-8">{node.data.nombre}</p>;
        }
    };


    const precioRefTemplate = (salePrice,node) => {
        const tipoPre =
            salePrice === 'precio_mensual'
                ? node.data.precio_referencial_mensual
                : node.data.precio_referencial_anual;
        if (!tipoPre) {
            return null;
        }
        return (
            <p className="text-center">S/.{tipoPre}</p>
        );
    };

    //#region ACCIONES
    const editarPrecioVenta = (p, salePrice) => {

        let updatedPaquete = null;

        if (sendPaquete === null) {
            updatedPaquete = BuscarHijo(props.paquete,p,salePrice)
            
        }else {
            updatedPaquete = BuscarHijo(sendPaquete,p,salePrice)
        };

        setSendPaquete(updatedPaquete);
        console.log('paquete editado',updatedPaquete);
        
        
    };


    function BuscarHijo(data, keyEdit,salePrice) {

        return data.map((item) => {

            const isPrecioMensual = salePrice === 'precio_mensual';
            const isPrecioAnual = salePrice === 'precio_anual';
            const dataEdit = item.key === keyEdit.key

            const node = {
                key: item.key,
                data: {
                    nombre: item.data.nombre,
                    precio_referencial_anual: item.data.precio_referencial_anual || 0,
                    precio_referencial_mensual: item.data.precio_referencial_mensual || 0,
                    precio_mensual:dataEdit && isPrecioMensual ? precioEdit || 0 : item.data.precio_mensual || 0,
                    precio_anual: dataEdit && isPrecioAnual ? precioEdit || 0 : item.data.precio_anual || 0,
                },
                children: [],
            };
            
            if (item.children && Array.isArray(item.children) && item.children.length > 0) {
                node.children = BuscarHijo(item.children, keyEdit, salePrice);
            }
    
            return node;
        });
    }
    
    
    //#endregion

    //Region Funcionalidad

    const precioVentTemplate = (salePrice, node) => {
        const tipoPre =
            salePrice === 'precio_mensual'
                ? node.data.precio_mensual
                : node.data.precio_anual;
        if (!tipoPre) {
            return null;
        }
        return (
            <div className="text-sm flex gap-1 align-items-center justify-content-center">
                <i
                    onClick={() => setVisible(node.data.nombre)}
                    className="flex align-items-center w-1rem h-1rem border-circle text-sm pi pi-pencil text-green-500"
                />
                <Dialog
                    header="Cambiar Precio"
                    visible={visible === node.data.nombre}
                    style={{width: '300px', height: '260px'}}
                    onHide={() => setVisible(false)}
                >
                    <p style={{margin: '1rem 1rem 1rem 0'}}>¿Desea editar a {tipoPre}?</p>
                    <InputText onChange={(e) => setPrecioEdit(parseFloat(e.target.value))} style={{width: '100%', marginBottom: '1rem'}} />
                    
                    <Button
                        label="Editar"
                        severity="info"
                        onClick={() => {setVisible(false);
                            editarPrecioVenta(node,salePrice);}}
                        style={{width: '100%'}}
                    />
                </Dialog>
                <p className="text-center">S/.{tipoPre}</p>
            </div>
        );
    };

    function sumarPrecios(data, campoPrecio, ref) {
        
        let suma = 0;
        
        if (ref) {
            let precioref = campoPrecio === 'precio_mensual' ? 'precio_referencial_mensual' : 'precio_referencial_anual'
            data.forEach((node) => {
                if (node.data && node.data[precioref]) {
                    suma += parseInt(node.data[precioref]);
                }
    
                if (node.children && node.children.length > 0) {
                    suma += sumarPrecios(node.children, precioref);
                }
            });
        }else{
            data.forEach((node) => {
                if (node.data && node.data[campoPrecio]) {
                    suma += parseInt(node.data[campoPrecio]);
                }
    
                if (node.children && node.children.length > 0) {
                    suma += sumarPrecios(node.children, campoPrecio);
                }
            });
        }
        return suma;
    }

    //end region



    //region Render

    return (

        <TreeTable
            value={sendPaquete || props.paquete}
            //tableStyle={{minWidth: "50rem"}}
            style={props.style}
            className={"Ventas text-sm" + props.className || ""}
            scrollHeight={props.scrollHeightTable || ""}
            scrollable={props.scrollable || false}
        >
            <Column
                field="nombre"
                style={{ display: "flex"}}
                header={
                    <p className="p-1 text-indigo-500 text-base">Prueba habilitada</p>
                }
                footer={
                    <p>Precio de venta total</p>
                }
                expander
                body={imageTemplate}
            ></Column>
            <Column
                field="precio_mensual"
                header={
                    <p className="bg-blue-100 p-1 text-blue-400 border-round-md text-base text-center">
                        Precio Referencial
                    </p>
                }
                footer={
                    <p className="text-center">S/.{sumarPrecios(props.paquete || [], props.salePrice,true)}</p>
                }
                /* body={precioRefTemplate} */
                body={(node)=>precioRefTemplate(props.salePrice, node)}
            ></Column>
            <Column
                header={
                    <p className="bg-orange-100 p-1 text-orange-400 border-round-md text-base text-center">
                        Precio de Venta
                    </p>
                }
                footer={
                    <p className="text-center">S/.{sumarPrecios(sendPaquete || (props.paquete || []), props.salePrice)}</p>
                }
                body={(node)=>precioVentTemplate(props.salePrice, node)}
            ></Column>
        </TreeTable>
    );
}