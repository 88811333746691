import React, { useEffect, useState, useRef } from 'react'
import ComponentServiciosClinica from '../../../ProductosServicios/ServiciosClinica/ServiciosClinica';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import AuthUser from '../../../../AuthUser';
import "../../../../Style/PageServiciosB.css";
import ServicioCLinicaParticular from '../../../ProductosServicios/ServiciosClinica/ServicioCLinicaParticular';

const PageServiciosB = () => {
   const { http } = AuthUser();
   const [paquetes, setPaquetes] = useState([]);
   const [paquetesP, setPaquetesP] = useState([]);
   
   const toast = useRef(null);
   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const getPaquetes = () => {
      http
         .get("clinica/paquete/get")
         .then((response) => {
            setPaquetes(response.data.data);
            console.log(response.data.data)
         })
         .catch((e) => {
            console.error(e);
         });
   };

   const getPaquetesparticular = () => {
      http
         .get("clinica/paquete/particular/get")
         .then((response) => {
            setPaquetesP(response.data.data);
            console.log(response.data.data)
         })
         .catch((e) => {
            console.error(e);
         });
   };
   const deletePaquete = (paquete_id) => {
      http
         .delete(`clinica/paquete/delete/${paquete_id}`)
         .then((response) => {
            console.log(response.data)
            showToast('error', 'Exito', 'paquete eliminado correctamente');
            getPaquetes();
            getPaquetesparticular();
         })
         .catch((error) => {
            console.log(error);
         });
   };
   useEffect(() => {
      getPaquetesparticular();
      getPaquetes();
   }, []);
   return (

      <>
         <Toast ref={toast} />
         <div className='add__container_header'>
            <span className='add__container_header_h4'>Servicios</span>
            <span className='add__container_header_p mb-2'>Adminístralo a tu mejor manera</span>

         </div>
         <div>
            <TabView>
               <TabPanel header="Coorporativo">
                  <ComponentServiciosClinica paquetes={paquetes} update={getPaquetes} eliminar={deletePaquete} showToast={showToast} />
               </TabPanel>
               <TabPanel header="Particular">
                  <ServicioCLinicaParticular paquetes={paquetesP} update={getPaquetesparticular} eliminar={deletePaquete} showToast={showToast}/>
               </TabPanel>
            </TabView>

         </div>
      </>
   )

}

export default PageServiciosB;