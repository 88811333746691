import React from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import "../../Style/InputMapAutocompletMapaLocal.css";

const libraries = ["places"];
function InputMapAutocomplet(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCsCf38reBoC31fo0enpAT6cESVohjRSwk",
    //googleMapsApiKey: "AIzaSyC6KKz5ECwpUopPfQEQhuHe9y57FTJgdUk",
    libraries: libraries,
  });

  return isLoaded ? (
    <Autocomplete
      restrictions={props.restrictions}
      onPlaceChanged={props.onPlaceChanged}
      onLoad={props.onLoad}
    >
      {props.children}
    </Autocomplete>
  ) : (
    <React.Fragment></React.Fragment>
  );
}

export default InputMapAutocomplet;