import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import AuthUser from "../../../AuthUser";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function ModalEliminar({
  visibleEliminar,
  showToast,
  setVisibleEliminar,
  setPersonal,
  getPersonal,
  api
}) {

  const dispatch = useDispatch();
  const { http } = AuthUser();
  const HideDialogEliminar = () => {
    setVisibleEliminar(false);
  };

  const DeleteMiPersonal = async (id) => {
    dispatch(startLoading())
    try {
      const response = await http.delete(`${api}/${id}`)
      if (response?.data?.resp) {
        await Promise.all([
          HideDialogEliminar(),
          getPersonal()
        ]);
        showToast("success", "Eliminado correctamente", "Se eliminó correctamente el personal", response?.data?.resp);

      } else if (response?.data?.error) {
        showToast("error", "Error", response?.data?.error)
      }

    } catch (error) {
      showToast("error", "Fallo al Eliminar", "No se pudo eliminar correctamente",)
      console.log("error", error)
    }
    finally {
      dispatch(endLoading());
    }
  }

  const paqueteDelateDialogFooter = (id) => (
    <div className="flex w-full bot">
      <Button
        type="button"
        label="Cancelar"
        className="delete w-full"
        onClick={() => {
          HideDialogEliminar();
        }}
      />
      <Button
        type=""
        label="Eliminar"
        className="p-button-success w-full"
        style={{
          color: "#5555D8",
          backgroundColor: "#EBEBFF",
          borderColor: "#5555D8",
        }}
        onClick={() => {
          DeleteMiPersonal(id);
        }}
      />
    </div>
  );

  return (
    <div>
      <form
        id="personal-form-delete"
        className="personal-form-delete"
        noValidate
      >
        <Dialog
          resizable={false}
          visible={visibleEliminar}
          style={{ width: "450px", margin: "5%" }}
          header={
            <>
              <h1 style={{
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18",
                lineHeight: "3",
                color: "#5555D8",
              }}></h1>
              <div className="flex justify-content-left align-items-left">
                <i className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}><i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Personal</i></i>
              </div><div
                className="flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "",
                  width: "30px",
                  height: "30px",
                  borderradius: "10px",
                }}
                onClick={() => {
                  HideDialogEliminar();
                }}
              >
              </div>
            </>
          }
          footer={paqueteDelateDialogFooter(setPersonal.id)}
          onHide={() => {
            HideDialogEliminar();
          }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p style={{ fontSize: "20px" }} className=" m-0" >¿Está seguro que desea eliminar personal?</p>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}