import React, { useEffect, useState, useRef } from "react";
import ComponentContenedorServicvio from "./ContenedorServicios";
import AuthUser from "../../../AuthUser";
import { Toast } from 'primereact/toast';
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function Servicios() {
   const dispatch = useDispatch();

   const { http } = AuthUser();
   const [paquetes, setPaquetes] = useState([]);
   const toast = useRef(null);

   const showToast = (severity, summary, message) => {
      toast.current.show({
         severity: severity,
         summary: summary,
         detail: message
      });
   };

   const getPaquetes = async () => {
      try {
         const response = await http
            .get("bregma/paquete/get")
         setPaquetes(response.data.data);
         console.log(response.data.data)
      } catch (error) {
         console.error(error);
      };
   };

   const deletePaquete = (paquete_id) => {
      http
         .delete(`bregma/paquete/delete/${paquete_id}`)
         .then((response) => {
            console.log(response.data)
            showToast('success', 'Eliminado correctamente', 'El paquete se eliminó correctamente');
            getPaquetes();
         })
         .catch((error) => {
            console.log(error);
            showToast('error', 'Error', 'No se pudo eliminar el paquete');
         });
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getPaquetes(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      // getPaquetes();
      dispatch(startLoading());
      fetchAllData()
   }, []);

   return (
      <div>
         <Toast ref={toast} />
         <div>
            <span className="tit">Servicios</span>
            <br />
            <span className="con">Adminístralo a tu mejor manera</span>
         </div>
         <div>
            <ComponentContenedorServicvio paquetes={paquetes} update={getPaquetes} deletePaquete={deletePaquete} showToast={showToast} />
         </div>

      </div>
   );
}
