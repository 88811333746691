import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

export default function ModalEliminar({ eliminar, close, estado, paquete }) {
    console.log(paquete);
    const handleDeleteConfirmation = (id_paquete) => (
        <div className="flex justify-content-end">    
            <Button
                label="Cancelar"
                className="p-button-secondary"
                style={{ background: "white", color: "#344054" }}
                onClick={() => {
                    close(false);
                }} 
            />
            <Button
                label="Eliminar"
                className="p-button-danger "
                onClick={() => { 
                    close(false);
                    eliminar(id_paquete)
                }}
            />
        </div>
    );


    return (
        <Dialog
            visible={estado}
            style={{ width: "350px", height: "auto" }}
            className="p-fluid"
            header={<p className="text-red-500">Eliminar Paquete</p>}
            footer={handleDeleteConfirmation(paquete?.paquete?.id)}
            onHide={() => close(false)}
        >
            <div className="flex flex-column w-full justify-content-center align-items-center" style={{ marginTop: "20px" }}>
                <p style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "16px" }}>¿Está seguro qué desea eliminar    ?</p>
            </div>
        </Dialog>

    )
}