import React, { useContext, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TabPanel, TabView } from "primereact/tabview";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
import ModalGuardarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion";
import ModalCancelarAtencion from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion';
import { RAContext } from '../RutaAtencion/RAContext';
import { Toast } from 'primereact/toast';
import AuthUser from '../../AuthUser';
import PageEspDatos from './Datos/PageEspDatos';
import PageEspCuestionario from './Cuestionario/PageEspCuestionario';
import RATimeView from '../../Components/RutaAtencion/Atencion/RATimeView';
import { iconEspirometria } from '../../Services/importIcons';
import { EspiContext } from './EspiProvider';
import BottomsFootComponent from '../../Components/Clinica/AtencionMedica/pure/BottomsFoot';

const PageEspi = ({ revision }) => {
   const { http } = AuthUser();
   const { idPaciente } = useParams();

   let { setTimeStart, setAtencionStatus, preguntasContext, setPreguntasContext, datosContext, setDatosContext } = useContext(EspiContext)
   let { pageViewContext, stateAtencion, setStateAtencion, espirometriaDetalleId, setEspirometriaDetalleId } = useContext(RAContext)

   const [idEspirometria, setIdEspirometria] = useState(null);
   const [visibleImprimir, setVisibleImprimir] = useState(false);
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [visibleCancelar, setVisibleCancelar] = useState(false);
   const [estado, setEstado] = useState(false)
   const [documentos, setDocumentos] = useState([])

   const [activeIndex, setActiveIndex] = useState(0);
   let areas = [1, 2];

   const toast = useRef(null);
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };

   //INICIAR ATENCION
   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setEspirometriaDetalleId({
               id: espirometriaDetalleId.id,
               estado: 1,
               hora_inicio: espirometriaDetalleId.hora_inicio,
               fecha_inicio: espirometriaDetalleId.fecha_inicio,
               hora_fin: espirometriaDetalleId.hora_fin,
               fecha_fin: espirometriaDetalleId.fecha_fin,
            })
         })
   }

   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setEspirometriaDetalleId({
               id: espirometriaDetalleId.id,
               estado: 2,
               hora_inicio: espirometriaDetalleId.hora_inicio,
               fecha_inicio: espirometriaDetalleId.fecha_inicio,
               hora_fin: espirometriaDetalleId.hora_fin,
               fecha_fin: espirometriaDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setEspirometriaDetalleId({
                  id: espirometriaDetalleId.id,
                  estado: 2,
                  hora_inicio: espirometriaDetalleId.hora_inicio,
                  fecha_inicio: espirometriaDetalleId.fecha_inicio,
                  hora_fin: espirometriaDetalleId.hora_fin,
                  fecha_fin: espirometriaDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }

   //CREAR ID DE ESPIROMETRÍA
   const espirometriaCreate = () => {
      http
         .post("espirometria/create", { paciente_id: idPaciente })
         .then((response) => {
            console.log("Espirometría", response)
            if (response?.data?.resp) {
               setIdEspirometria(response.data.espirometria_id)
               showToast('success', 'Éxito', response?.data?.resp);
            }
         }).catch((error) => {
            console.log(error);
            showToast('error', 'Error', error.message);
         });
   }

   //CREAR datos y CUESTIONARIO DE ESPIROMETRÍA
   const postCuestionario = (data) => {
      http
         .post(`espirometria/cuestionario/create/${idEspirometria}`, data)
         .then((response) => {
         })
   }

   const createDatos = (data) => {
      const formData = new FormData();
      formData.append('interpretacion', data.interpretacion);
      formData.append('observaciones', data.observaciones);
      // formData.append('esp_equipo_id', null);
      if (typeof data.archivo === 'object') {
         formData.append('archivo', data.archivo);
      }
      // formData.append('archivo', data.archivo);

      data.datos_detalles.forEach((detalle, index) => {
         formData.append(`datos_detalles[${index}][esp_datos_detalles_tipo_id]`, detalle.esp_datos_detalles_tipo_id);
         formData.append(`datos_detalles[${index}][valor_real]`, detalle.valor_real);
         formData.append(`datos_detalles[${index}][valor_teorico]`, detalle.valor_teorico);
         formData.append(`datos_detalles[${index}][porcentaje]`, detalle.porcentaje);
      });

      http.post(`espirometria/datos/create/${idEspirometria}`, formData)
         .then((response) => {
            console.log(response);
         })
         .catch((error) => {
            console.log(error);
         });
   }

   //Guardar cambios
   const handleSubmit = () => {
      postCuestionario(preguntasContext);
      createDatos(datosContext);
      setVisibleGuardar(false);
      showToast("success", "Espirometría", "Datos creados satisfactoriamente.");
   }

   //TRAER ÚLTIMAS MODIFICACIONES
   const handleGetBD = () => {
      getCuestionario();
      getDatos();
      showToast('success', 'Borrado correctamente', "Datos borrados correctamente");
   }

   //Obtener espirometría del paciente
   const getPaciente = () => {
      http
         .get(`espirometria/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.id) {
               setIdEspirometria(response?.data?.id)
               getCuestionario()
               getDatos()
               console.log("DatosCOntext", datosContext)
            }
            if (response?.data?.hora_final) {
               CambiarEstado(4)
            } else if (response?.data?.hora_inicio) {
               CambiarEstado(1)
            }

         })
         .catch((error) => {
            console.log(error)
         })
   }

   const getCuestionario = () => {
      http
         .get(`espirometria/cuestionario/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               console.log(response?.data?.resp?.esp_preguntas_1?.antecedentes_pulmonares > 0)
               console.log(response?.data?.resp?.esp_preguntas_2?.usuarios_esp > 0)
               console.log(response?.data?.resp?.esp_preguntas_3.length > 0)
               setPreguntasContext({
                  // esp_preguntas_1: response?.data?.resp?.esp_preguntas_1?.antecedentes_pulmonares > 0 ? response?.data?.resp?.esp_preguntas_1 : preguntasContext?.esp_preguntas_1,
                  // esp_preguntas_2: response?.data?.resp?.esp_preguntas_2?.usuarios_esp > 0 ? response?.data?.resp?.esp_preguntas_2 : preguntasContext?.esp_preguntas_2,
                  // esp_preguntas_3: response?.data?.resp?.esp_preguntas_3?.length > 0 ? response?.data?.resp?.esp_preguntas_3 : preguntasContext?.esp_preguntas_3
                  esp_preguntas_1: response?.data?.resp?.esp_preguntas_1,
                  esp_preguntas_2: response?.data?.resp?.esp_preguntas_2,
                  esp_preguntas_3: response?.data?.resp?.esp_preguntas_3
               })
            }
         })
   }

   const getDatos = () => {
      http
         .get(`espirometria/datos/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               console.log("Obteniendo...", response?.data?.resp)
               console.log("Paciente: ", idPaciente);
               setDatosContext(response?.data?.resp)
            }
         })
         .catch((error) => {
            showToast('error', 'Error', error?.response?.data?.Error)
         })

   }

   // const FinalizarAtencion = (st, time) => {

   //    let data = {
   //       hoja_ruta_detalle_id: pacienteData?.paciente?.hoja_ruta_id,
   //       espirometria_id: idEspirometria,
   //       hora_fin: time
   //    }

   //    console.log("DATA", data)

   //    http
   //       .put('espirometria/finalizar-atencion', data)
   //       .then((response) => {
   //          console.log('FINALIZAR', response.data)
   //          CambiarEstado(st)
   //       })
   //       .catch((error) => {
   //          showToast('error', 'Error', error.error)
   //          console.log(error)
   //       })

   // }

   const renderTabs = (area) => {
      const optionMapping = {
         1: (
            <TabPanel header="Datos">
               <PageEspDatos />
            </TabPanel>
         ),
         2: (
            <TabPanel header="Cuestionario">
               <PageEspCuestionario />
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      getPaciente();
      console.log("PREGUNTAS", preguntasContext)
   }, [])

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               areas?.map(area => {
                  return renderTabs(area)
               })
            }
         </TabView>

         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
            showToast={showToast}
         />

         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="Espirometría"
            onNo={() => setVisibleGuardar(false)}
            onYes={() => handleSubmit()}
            onHide={() => setVisibleGuardar(false)}
         />

         <ModalCancelarAtencion
            visible={visibleCancelar}
            Atencion="Espirometría"
            onNo={() => setVisibleCancelar(false)}
            onYes={() => { handleGetBD(); setVisibleCancelar(false) }}
            onHide={() => setVisibleCancelar(false)}
         />
         {espirometriaDetalleId.estado === '0' && (
            <RATimeView
               title="Espirometría"
               AreaDetalleId={espirometriaDetalleId?.id}
               icon={iconEspirometria}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={espirometriaCreate}
               textButton='Iniciar atención'
            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title="Espirometría"
               AreaDetalleId={espirometriaDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar revisión'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={espirometriaDetalleId?.hora_final}
               VerHoraInicio={espirometriaDetalleId?.hora_inicio}
               VerfechaInicio={espirometriaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}

         {estado && revision !== 1 && (
            <RATimeView
               title="Espirometría"
               AreaDetalleId={espirometriaDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={espirometriaDetalleId?.hora_final}
               VerHoraInicio={espirometriaDetalleId?.hora_inicio}
               VerfechaInicio={espirometriaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PageEspi;
