import React, { useContext, useEffect, useState } from 'react';
import Table from "../../../Components/Table/Table";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from 'primereact/inputtextarea';
import { PsicologiaContext } from "../PsicologiaContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import "../../../Style/psi_preguntas.css"

const PagePsiPreguntas = () => {

   let { pregContext, setPregContext} = useContext(PsicologiaContext)
   let { pageViewContext, stateAtencion } = useContext(RAContext)
   //console.log("respuesta de preguntas", pregContext)
   let booleanOptions = [
      { label: 'Si', value: "1" },
      { label: 'No', value: "2" }
   ]

   let testOptions = [
      { label: 'Normal', value: "1" },
      { label: 'Anormal', value: "2" },
      { label: 'No realizada', value: "3" }
   ]

   const [preguntas, setPreguntas] = useState(
      [
         { id: 1, nombre: 'evaluacion_psicopatologica', patologia: 'Evaluación Psicopatológica: Evidencia indicadores significativos de  psicopatología', resultado_id: pregContext?.evaluacion_psicopatologica || "1", comentario: pregContext?.comentario_ep || "" },
         { id: 2, nombre: 'evaluacion_organica', patologia: 'Evaluación Orgánica: Evidencia indicadores de comportamiento orgánico cerebral', resultado_id: pregContext?.evaluacion_organica || "1", comentario: pregContext?.comentario_eo || "" },
         { id: 3, nombre: 'evaluacion_emocional', patologia: 'Evaluación Emocional', resultado_id: pregContext?.evaluacion_emocional || "1", comentario: pregContext?.comentario_ee || "" },
         { id: 4, nombre: 'sano_mentalmente', patologia: 'Sano mentalmente', resultado_id: pregContext?.sano_mentalmente || "1", comentario: pregContext?.comentario_sm || "" },
         { id: 5, nombre: 'tipo_estres', patologia: 'Tipo de estrés', resultado_id: pregContext?.tipo_estres || "1", comentario: pregContext?.comentario_te || "" },
         { id: 6, nombre: 'test_somnolenda', patologia: 'Test Somnolenda', resultado_id: pregContext?.test_somnolenda || "1", comentario: pregContext?.comentario_ts || "" },
         { id: 7, nombre: 'test_fatiga', patologia: 'Test Fatiga', resultado_id: pregContext?.test_fatiga || "1", comentario: pregContext?.comentario_tf || "" }
      ]
   )

   const handleUpdate = (rowData, e) => {
      const newData = preguntas?.map((item) => {
         if (item?.id === rowData?.id) {
            setPregContext({ ...pregContext, [item.nombre]: e.value })
            return {
               ...item,
               resultado_id: e.value
            }
         }
         return item
      })
      setPreguntas(newData)

   }

   const resultBody = (rowData) => {
      return (
         <>
            <Dropdown
               placeholder="Seleccione una opción"
               value={rowData?.resultado_id}
               options={rowData?.id === 1 || rowData?.id === 2 || rowData?.id === 4 ? booleanOptions : rowData?.id === 6 || rowData?.id === 7 ? testOptions : null}
               disabled={stateAtencion[pageViewContext].estado === 3 || rowData?.id === 3 || rowData?.id === 5}
               onChange={e => handleUpdate(rowData, e)}
               style={{ width: '80%', background: stateAtencion[pageViewContext].estado === 3 || rowData?.id === 3 || rowData?.id === 5 ? '#DDD' : 'white' }} />
         </>
      )
   }

   const comentUpdate = (rowData, e) => {
      const newData = preguntas?.map((item) => {
         if (item?.id === rowData?.id) {
            switch (item.id) {
               case 1:
                  return { ...item, comentario: e.target.value };
               case 2:
                  return { ...item, comentario: e.target.value };
               case 3:
                  return { ...item, comentario: e.target.value };
               case 4:
                  return { ...item, comentario: e.target.value };
               case 5:
                  return { ...item, comentario: e.target.value };
               case 6:
                  return { ...item, comentario: e.target.value };
               case 7:
                  return { ...item, comentario: e.target.value };
               default:
                  return item;
               }
            }
         return item
      })
      setPreguntas(newData)
   }

   const comentBody = (rowData) => {
      return (
         <>
            <InputTextarea 
               autoResize 
               className='w-full textarea-hidden' 
               type="text" 
               style={{
                  background: stateAtencion[pageViewContext].estado === 3 || rowData?.id === 3 || rowData?.id === 5 ? '#DDD' : 'white'
               }}
               value={rowData?.comentario} 
               onChange={(e) => {
                  comentUpdate(rowData, e);
               }}
               onTextAreaClick={console.log("Se clickeo el textarea")}
               disabled={stateAtencion[pageViewContext].estado === 3 || rowData?.id === 3 || rowData?.id === 5}
            />
         </>
      )
   }

   useEffect(() => {
      setPreguntas([
         { id: 1, nombre: 'evaluacion_psicopatologica', patologia: 'Evaluación Psicopatológica: Evidencia indicadores significativos de  psicopatología', resultado_id: pregContext?.evaluacion_psicopatologica || "1", comentario: pregContext?.comentario_ep || "" },
         { id: 2, nombre: 'evaluacion_organica', patologia: 'Evaluación Orgánica: Evidencia indicadores de comportamiento orgánico cerebral', resultado_id: pregContext?.evaluacion_organica || "1", comentario: pregContext?.comentario_eo || "" },
         { id: 3, nombre: 'evaluacion_emocional', patologia: 'Evaluación Emocional', resultado_id: pregContext?.evaluacion_emocional || "1", comentario: pregContext?.comentario_ee || "" },
         { id: 4, nombre: 'sano_mentalmente', patologia: 'Sano mentalmente', resultado_id: pregContext?.sano_mentalmente || "1", comentario: pregContext?.comentario_sm || "" },
         { id: 5, nombre: 'tipo_estres', patologia: 'Tipo de estrés', resultado_id: pregContext?.tipo_estres || "1", comentario: pregContext?.comentario_te || "" },
         { id: 6, nombre: 'test_somnolenda', patologia: 'Test Somnolenda', resultado_id: pregContext?.test_somnolenda || "1", comentario: pregContext?.comentario_ts || "" },
         { id: 7, nombre: 'test_fatiga', patologia: 'Test Fatiga', resultado_id: pregContext?.test_fatiga || "1", comentario: pregContext?.comentario_tf || "" }
      ])
      console.log("inicializando contexto", preguntas)
   }, [])

   useEffect(() => {
      console.log("se uso el contexto: ", pregContext, preguntas, pregContext)

   }, [pregContext, preguntas])

   useEffect(() => {
         if (
            pregContext?.comentario_ep !== preguntas[0]?.comentario ||
            pregContext?.comentario_eo !== preguntas[1]?.comentario ||
            pregContext?.comentario_ee !== preguntas[2]?.comentario ||
            pregContext?.comentario_sm !== preguntas[3]?.comentario ||
            pregContext?.comentario_te !== preguntas[4]?.comentario ||
            pregContext?.comentario_ts !== preguntas[5]?.comentario ||
            pregContext?.comentario_tf !== preguntas[6]?.comentario
         ) {
            const updatedContext = {
               ...pregContext,
               comentario_ep: preguntas[0]?.comentario,
               comentario_eo: preguntas[1]?.comentario,
               comentario_ee: preguntas[2]?.comentario,
               comentario_sm: preguntas[3]?.comentario,
               comentario_te: preguntas[4]?.comentario,
               comentario_ts: preguntas[5]?.comentario,
               comentario_tf: preguntas[6]?.comentario,
            };
            setPregContext(updatedContext);
         }
   }, [pregContext, preguntas]);

   return (
      <>
         <Table
            title="Preguntas"
            createButtonEnabled={false}
            headerEnabled={false}
            data={preguntas}
         >
            <Column field="patologia" header="Patología" sortable style={{ width: '40%', overflow: 'hidden', whiteSpace: 'normal', maxWidth: '40%', textOverflow: 'ellipsis'}} />
            <Column field="resultado" header="Resultado" sortable style={{ width: '30%' }}
               body={resultBody} />
            <Column field='comentario' header="Comentario" style={{ width: '30%' }} body={comentBody}/>
         </Table>
      </>
   );
};

export default PagePsiPreguntas;