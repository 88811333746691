import React, { useEffect, useRef, useState } from "react";
import PersonaComponent from "../../Components/Perfil/usuario/Persona";
import EmpresaComponent from "../../Components/Perfil/usuario/Empresa";
import PermisosComponent from "../../Components/Perfil/usuario/Permisos";
import Detraccion from "../../Components/Perfil/usuario/Detraccion";
import CrearCcomponent from "../../Components/Perfil/usuario/CrearC";
import AuthUser from "../../AuthUser";
import "../../Style/Perfil/perfil-style.css";
import { Toast } from "primereact/toast";
import { startLoading, endLoading, } from "../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

const PagePerfil = ({ getAllPersona }) => {

   const dispatch = useDispatch();

   const toast = useRef(null);
   const { http } = AuthUser();
   const [tipoDcoumentos, setTipoDcoumentos] = useState([]);
   const [bregma, setBregma] = useState([]);
   const [bregmaUpdate, setBregmaUpdate] = useState({
      id: null,
      tipo_documento: null,
      numero_documento: null,
      razon_social: null,
      direccion: null,
      celular: null,
      correo: null,
      distrito: null,
      distrito_id: null
   })
   const [persona, setPersona] = useState({})
   const [personaUpdate, setPersonaUpdate] = useState({})
   const [entidadPagos, setEntidadPagos] = useState([])
   const [entidadPagoElectronico, setEntidadPagoElectronico] = useState([])
   const [detracciones, setDetracciones] = useState([])
   const [entidadesBancarias, setEntidadesBancarias] = useState([])

   const getBregma = async () => {
      try {
         const response = await http
            .get("bregma/show");
         setBregma(response.data);
         setBregmaUpdate({
            ...bregmaUpdate,
            id: response?.data?.id,
            tipo_documento: response?.data?.tipo_documento,
            tipo_documento_id: response?.data?.tipo_documento_id,
            numero_documento: response?.data?.numero_documento,
            razon_social: response?.data?.razon_social,
            direccion: response?.data?.direccion,
            celular: response?.data?.celulares?.celular,
            correo: response?.data?.correos?.correo,
            distrito: response?.data?.distrito,
            distrito_id: response?.data?.distrito_id
         });
         setEntidadPagos(response.data.entidad_pagos);
         setDetracciones(response.data.detracciones);
      } catch (error) {
         console.error("errrror", error);
      }
   };

   const getAllTipoDocumentos = async () => {
      try {
         const response = await http.get("/tipodocumentos/get");
         if (response?.data?.data) {
            setTipoDcoumentos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      }
   };
   const getEntidadesBancarias = async () => {
      try {
         const response = await http
            .get('entidad_bancaria/get');
         setEntidadesBancarias(response.data.data);
      } catch (error) {
         console.error("error", error);
      }
   }

   const getEntidadesElectronicas = async () => {
      try {
         const response = await http
            .get('entidad_electronica_pago/get');
         console.log("termino entidadesElectronicas");
         setEntidadPagoElectronico(response.data.data);
      } catch (error) {
         console.error("error", error);
      }
   }



   const getPersona = () => {
      http
         .get('persona/show')
         .then((response) => {
            setPersona(response?.data?.data?.persona);
            setPersonaUpdate(response?.data?.data?.persona);
            getAllPersona();
         })
         .catch((error) => {
            console.log(error);
         })
   }

   const handleSubmitUpdate = (id, data) => {
      http
         .put(`bregma/update/${id}`, data)
         .then((response) => {
            showToast(
               "success",
               "Actualizado correctamente",
               "Se actualizó los datos de la empresa"
            )
            getBregma();
         })
         .catch((error) => {
            console.error(error);
         })
   }

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const handleCreate = (entidadPago, limpiarEntidadBancaria, CerrarModal) => {
      http
         .post("entidad_pago/create-bregma", entidadPago)
         .then((response) => {
            getBregma();
            CerrarModal();
            limpiarEntidadBancaria();
         })
         .catch((error) => {
            CerrarModal();
            console.error("error", error);
         });
   };


   const fetchAllData = async () => {
      try {
         await Promise.all([
            getBregma(),
            getAllTipoDocumentos(),
            getEntidadesBancarias(),
            getEntidadesElectronicas(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
         // setTimeout(() => {
         // }, 1000);
      }
   }

   // Skeleton
   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])



   return (
      <>
         {/* {
            isLoading ? (
               <div className="card flex justify-content-center justify-aligne-center">
                  <ProgressSpinner />
               </div>

            ) : ( */}
         <>
            <Toast ref={toast} />
            <div className="ContenedorGeneralPerfil flex flex-column gap-3 mt-3 pr-6 pl-6">

               <div>
                  <p className="tit "> Mi Perfil </p>
                  <p className="con">Adminí­stralo a tu mejor manera </p>
               </div>
               <div className="gap-3 perfil-res ContenedorGeneralComponente" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                  <div className=" flex flex-wrap ContenedorComponentePersona">

                     <PersonaComponent
                        getAllPersona={getAllPersona}
                        persona={persona}
                        personaUpdate={personaUpdate}
                        setPersonaUpdate={setPersonaUpdate}
                        getPersona={getPersona}
                        institucion={"Bregma"}
                        showToast={showToast}
                     />
                  </div>
                  <div className="flex flex-wrap ContenedorComponenteEmpresa ">
                     <EmpresaComponent
                        dataShow={bregma}
                        data={bregmaUpdate}
                        setBregma={setBregmaUpdate}
                        getBregma={getBregma}
                        tiposDocumentos={tipoDcoumentos}
                        handleSubmitUpdate={handleSubmitUpdate}
                     />
                  </div>
               </div>
               <div className="CONEDORINFEERIORPERFIL w-12 flex gap-3">
                  <div className="CONEDORINFEERIORPERMISOS w-3 " >

                     <PermisosComponent namefull={persona?.nombres + " " + (persona?.apellido_paterno ?? "") + " " + (persona?.apellido_materno ?? "")} />

                  </div>
                  <div className="CONEDORINFEERIORCREAR w-9 flex flex-column gap-3">
                     <div>
                        <Detraccion
                           detracciones={detracciones}
                           getbregma={getBregma} />
                     </div>
                     <div>
                        {/* getelectronica */}
                        <CrearCcomponent
                           getEntidadesElectronicas={getEntidadesElectronicas}
                           getbregma={getBregma}
                           entidadPagos={entidadPagos}
                           entidadPagoElectronico={entidadPagoElectronico}
                           entidadesBancarias={entidadesBancarias}
                           handleCreate={handleCreate}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </>
         {/* )
         } */}
      </>
   )

}
export default PagePerfil;