import React from 'react'
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import "../../../Style/ProductoyServicios/EstilosP.css";
function Productos() {
    return (
        <div className=" cont1 flex flex align-items-center h-full">

            <div className="contenido-producto">

                <div className=" cont3 ">

                    <img src="https://i.ibb.co/ChrstV7/Background-Complete.png" className="icono-producto" alt="" />
                    <h1 className="titulo-producto">Área en mantenimiento</h1>

                    <div className=" conti">
                        <h2 className="vertical-align texto">Lamentamos la molestia</h2>

                        <p className=" parrafo-producto" style={{ color: '#4545BC 54%' }}>
                            Esta área no se encuentra disponible por el
                            momento, seguimos trabajando para darles una
                            mayor experiencia.
                        </p>
                    </div>

                </div>

                <div className="cont4">
                    <Link to={"/productosyservicios"}>
                        <Button className="pb-2 pt-2 pl-5 pr-5 boton-producto" label="Volver" />
                    </Link>
                </div>

            </div>

            <div className=" imagen">
                <img src="https://i.ibb.co/8Nb80Fg/imagen-mantenimeinto-2.png" className="m-auto  " style={{ maxWidth: "100%" }} alt='' />
            </div>
        </div>
    )
}

export default Productos