import React from "react";
import { Route, Routes, useLocation, useNavigate, } from "react-router-dom";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import PageLogin from "./Page/Login/PageLogin";
import PageRecepcionAtenciones from "./Page/Recepcion/Atencion/PageAtencion";
import PageRecepcionPacientes from "./Page/Recepcion/Paciente/PagePaciente";
import PageRAResultados from "./Page/RutaAtencion/Resultados/PageRAResultados";
import PageRAHistorial from "./Page/RutaAtencion/Historial/PageRAHistorial";
import PageRAEstadisticas from "./Page/RutaAtencion/Estadisticas/PageRAEstadisticas";
import { RAProvider } from "./Page/RutaAtencion/RAContext";
import PageRAAtencion from "./Page/RutaAtencion/Atencion/PageRAAtencion";
import PageGestionClientes from "./Page/Ventas/GestionClientes/PageGestionClientes";
import PageReclutamiento from "./Components/RecursosHumanos/Reclutamiento/PageReclutamiento";
import PageOperacionesB from "./Page/ClinicaRol/Operaciones/PageOperacionesB";
import PageMiPersonalB from "./Page/ClinicaRol/RecursosHumano/MiPersonal/PageMiPersonalB.jsx";
import PageCostoAtencionB from "./Page/ClinicaRol/Contabilidad/CostoAtencion/PageCostoAtencionB.jsx";
import PageFacturacionB from "./Page/ClinicaRol/Contabilidad/Facturacion/PageFacturacionB";
import PageMisPagosB from "./Page/ClinicaRol/Contabilidad/MisPagos/PageMisPagosB";
import PageServiciosB from "./Page/ClinicaRol/ProductoServicio/Servicios/PageServiciosB.jsx";
import TermCondi from "./Page/ClinicaRol/ProductoServicio/TernCondiB";
import PageEstadisticaContaB from "./Page/ClinicaRol/Estadisticas/EstadisticaConta/PageEstadisticaContaB";
import PageEstadisticaPSB from "./Page/ClinicaRol/Estadisticas/EstadisticaPS/PageEstadisticaPSB";
import PageEstadisticaRHB from "./Page/ClinicaRol/Estadisticas/EstadisticaRH/PageEstadisticaRHB";
import PageEstadisticaVentasB from "./Page/ClinicaRol/Estadisticas/EstadisticaVentas/PageEstadisticaVentasB";
import PageEstadisticaMedica from "./Page/ClinicaRol/Estadisticas/EstadisticaMedica/PageEstadisticaMedica";

// Clinica ROL

import PageProductosServiciosB from "./Page/ClinicaRol/ProductoServicio/PageProductosServiciosB";
import PageRecursosHumanosB from "./Page/ClinicaRol/RecursosHumano/PageRecursosHumanosB";
import PageContabilidadB from "./Page/ClinicaRol/Contabilidad/PageContabilidadB";
import PagePerfilClinica from "./Page/ClinicaRol/Perfil/PagePerfilClinica";
import PageEstadisticaB from "./Page/ClinicaRol/Estadisticas/PageEstadisticaB";
import PageSoporteTecnicoB from "./Page/ClinicaRol/SoporteTecnico/PageSoporteTecnicoB";
import PageProductosB from "./Page/ClinicaRol/ProductoServicio/Productos/PageProductosB";
import PageGestionVentasClinica from "./Page/ClinicaRol/Ventas/GestionVentas/PageGestionVentasClinica";
import EnvioCredenciales from "./Components/Ventas/Empresas/EnvioCredenciales";
import GestionProforma from "./Components/Ventas/Empresas/GestionProforma.jsx";
// import PageClientesB from "./Page/ClinicaRol/Ventas/Clientes/PageClientesB";
import PageAtencionesB from "./Page/ClinicaRol/Atenciones/PageAtencionesB";
import PageGestionClientesClinica from "./Page/ClinicaRol/Ventas/GestionClientes/PageGestionClientesClinica";
import PageProforma from "./Page/ClinicaRol/Ventas/GestionClientes/PageProforma";

import Soporte from "./Components/Soporte copy/Soporte";
import PageAceptacionesE from "./Page/EmpresaRol/Estadisticas/EncuestaAceptacion/PageAceptacionesE";

//Empresa Rutas
import PagePerfilEmpresa from "./Page/EmpresaRol/Perfil/PagePerfilEmpresa";
import PagePerfilProveedor from "./Page/EmpresaRol/Perfil/PagePerfilProveedor";
import PageContabilidadEmpresa from "./Page/EmpresaRol/Contabilidad/PageContabilidadE";
import PageProductosServiciosEmpresa from "./Page/EmpresaRol/ProductosServicios/PageProductosServiciosE";
import PageRecursosHumanosEmpresa from "./Page/EmpresaRol/RecursosHumanos/PageRecursosHumanosE";
import PageSoporteTecnicoEmpresa from "./Page/EmpresaRol/SoporteTecnico/PageSoporteTecnicoE";
import PageEstadisticasEm from "./Page/EmpresaRol/Estadisticas/PageEstadisticasEm";

import PageMisPagosEmpresa from "./Page/EmpresaRol/Contabilidad/MisPagos/PageMisPagosE";
import PageGestionPlataformasEmpresa from "./Page/EmpresaRol/ProductosServicios/GestionPlataformas/PageGestionPlataformasE";
import PageProductosEmpresa from "./Page/EmpresaRol/ProductosServicios/Productos/PageProductosE";
import PageServiciosEmpresa from "./Page/EmpresaRol/ProductosServicios/Servicios/PageServiciosE";
import PageMisAreasEmpresa from "./Page/EmpresaRol/RecursosHumanos/MisAreas/PageMisAreasE";
import PageMisLocalesEmpresa from "./Page/EmpresaRol/RecursosHumanos/MisLocales/PageMisLocalesE";
import PagePersonalEmpresa from "./Page/EmpresaRol/RecursosHumanos/Personal/PagePersonalEmpresa";
import PagePacientes from "./Page/EmpresaRol/RecursosHumanos/Personal/PagePacientes";

import PageRolesEmpresa from "./Page/EmpresaRol/RecursosHumanos/Roles/PageRolesEmpresa";
import PageListaEvaluadores from "./Page/ClinicaRol/RecursosHumano/ListaEvaluadores/PageListaEvaluadores";
import PagiaPuestoTrabajoB from "./Page/EmpresaRol/RecursosHumanos/PuestosTrabajo/PagePuestoTrabajo";
import Notificaciones from "./Page/Notificaciones/Notificaciones";
// import PageServiciosPrestadosEmpresa from './Page/EmpresaRol/RecursosHumanos/ServiciosPrestados/PageServiciosPrestadosE'

// Pages admin
import PageRecursosHumanosBregma from "./Page/RecursosHumanos/RecursosHumanosBregma/PageRecursosHumanosBregma";
import PageProductosServicios from "./Page/ProductosServicios/PageProductosServicios";
import PageContabilidad from "./Page/Contabilidad/PageContabilidad";
import Productos from "./Page/ProductosServicios/Productos/Productos";
import Servicios from "./Page/ProductosServicios/Servicios/Servicios";
import Personal from "./Page/RecursosHumanos/Personal/PagePersonal";
import Facturacion from "./Page/Contabilidad/Facturacion/Facturacion";
import GestionAtencion from "./Page/Contabilidad/GestionAtencion/GestionAtencion";
import Pagos from "./Page/Contabilidad/MisPagos/Pagos";
import Operaciones from "./Page/Operaciones/PageOperaciones";
import OperProductos from "./Page/Operaciones/OperaProductos/OperProductos";
import OperServicios from "./Page/Operaciones/OperaServicios/OperServicios";
import TermConditions from "./Page/Operaciones/TermConditions/TermConditions";
//
import Container from "./Components/Container/Container";
import PagePerfil from "./Page/Perfil/PagePerfil";
import { history } from "./Services/history";
import { PrivateRoute } from "./Routes/PrivateRoute";
import { useSelector } from "react-redux";

import PageSoporte from "./Page/SoporteTecnico/PageSoporte";

import Locales from "./Page/RecursosHumanos/Locales";
import PageAreasLocales from "./Page/RecursosHumanos/PageAreasLocales";
import CercoPrueba from "./Page/CercoPrueba/CercoPrueba";
import { SendVentasProvider } from "./Components/Ventas/SendVentasContext";
import { VentasProvider } from "./Components/Ventas/VentasContext";

import PageGestionAtencion from "./Page/ClinicaRol/GestionAtencion/PageGestionAtencion";
import PageGestionAtencionEmpresa from "./Page/EmpresaRol/GestionAtencion/PageGestionAtencionEmpresa.jsx";
// import PageSoporteCorporativo from "./Page/SoporteTecnico/PageSoporteCoorporativo";
import PageGestionProformaB from "./Page/ClinicaRol/ProductoServicio/GestionProforma/PageGestionProformaB";
import PageReclutamientoEmpresa from "./Page/EmpresaRol/RecursosHumanos/Reclutamiento/PageReclutamientoC";
// import PageParticular from "./Page/ClinicaRol/Ventas/GestionVentas/Particular/PagePartCli";
import PageNClientePartCli from "./Page/ClinicaRol/Ventas/GestionVentas/Particular/NuevoClienteParticularClinica/PageNClientePartCli";
import PageMantenimiento from "./Page/Mantenimiento/PageMantenimiento";
import PageMantenimientoCorporativo from "./Page/Mantenimiento/PageMantenimientoCorporativo";
import PageRecepcion from "./Page/ClinicaRol/Atenciones/Recepcion/PageRecepcion";
import PageMisLocalesB from "./Page/ClinicaRol/RecursosHumano/MisLocales/PageMisLocalesB";
import PageOperServiciosB from "./Page/ClinicaRol/Operaciones/Servicios/PageOperServiciosB";
import PageAgregarServiciosB from "./Page/ClinicaRol/ProductoServicio/Servicios/PageAgregarServiciosB";
import PageAgregarServiciosCoorporativo from "./Page/ClinicaRol/ProductoServicio/Servicios/PageAgregarServicioCoorporativo.jsx"
import PageEditarServiciosB from "./Page/ClinicaRol/ProductoServicio/Servicios/PageEditarServiciosB";
import PageEvaluadores from "./Page/ClinicaRol/RecursosHumano/Evaluadores/PageEvaluadores";
import HCNavigator from "./Components/HistoriaClinica/HCNavigator";


// Cuarto Perfil que solo muestra los datos del usuario
import PagePerfilPerfilSoloDatosUsuario from "./Page/Perfil/PerfilSoloDatosUsuario/PagePerfilDatosUsuario";

import PageAreaslocalClinica from "./Page/ClinicaRol/RecursosHumano/MisLocales/PageAreaslocalClinica.jsx";
import PageMisAreasE from "./Page/EmpresaRol/RecursosHumanos/MisAreas/PageMisAreasE.jsx";
import PageSales from "./Page/Sales/PageSales";
import PageSalesMagnament from "./Page/Sales/SalesManagement/PageSalesManagement";
import PageNewClient from "./Page/Sales/SalesManagement/PageNewClient";
import { SalesProvider } from "./Components/Sales/SalesContext/SalesContext";
import PageSalesC from "./Page/ClinicaRol/Sales/PageSalesC";
import PageSalesMagnamentC from "./Page/ClinicaRol/Sales/SalesManagement/PageSalesManagementC";
import PageNewClientCPbl from "./Page/ClinicaRol/Sales/SalesManagement/Public/PageNewClientCPbl";
import { ServicioProvider } from "./Components/Clinica/ProductoServicio/Providers/ServicioProvider";
import EstadiGeneralesE from "./Page/EmpresaRol/Estadisticas/EstadiGeneralesE";
import TermConditionsB from "./Page/Operaciones/TermConditions/TermConditionsB";
import PageHistoriaClinica from "./Page/HistoriaClinica/PageHistoriaClinica";
import PageHistoriaE from "./Page/HistoriaClinica/PageHistoriaE";
import PageMenuTriaje from "./Page/Triaje/PageMenuTriaje";
import PageMenuMedicinaGeneral from "./Page/MedicinaGeneral/PageMenuMedicinaGeneral.jsx"
import PageMenuOdontologia from "./Page/Odontologia/PageMenuOdontologia.jsx";
import PageMenuOftalmologia from "./Page/Oftalmologia/PageMenuOftalmologia.jsx";
import PageMenuLaboratorio from "./Page/Laboratorio/PageMenuLaboratorio.jsx";
import PageMenuPsicologia from "./Page/Psicologia/PageMenuPsicologia";
import PageMenuEkg from "./Page/Ekg/PageMenuEKG";
import PageMenuEspirometria from "./Page/Espirometria/PageMenuEspirometria";
import PageMenuAud from "./Page/Audiometria/PageMenuAud.jsx";
import PageMenuRad from "./Page/Radiologia/PageMenuRad.jsx";
import PageEditarServiciosCampaña from "./Page/ClinicaRol/ProductoServicio/Servicios/PageEditarServiciosCampaña.jsx";
import ClinProforma from "./Page/ClinicaRol/ProductoServicio/proforma/cliProforma.jsx";
import { GestionAtencionProvider } from "./Page/EmpresaRol/GestionAtencion/services/GestionAtencionContext.jsx";

//PAGE EMPRESA ROL
import PageAuditoria from "./Page/ClinicaRol/GestionAtencion/AuditoriaClinica/PageAuditoria.jsx";
import PageLiquidacion from "./Page/ClinicaRol/GestionAtencion/Liquidacion/PageLiquidacion.jsx";
import "./App.css";
import { GestionContext, GestionProvider } from "./Page/ClinicaRol/GestionAtencion/services/GestionContext.jsx";
import PageRevision from "./Page/ClinicaRol/GestionAtencion/Revision/PageRevision.jsx";

function App() {
   const { tipo_acceso, accesos } = useSelector((data) => data.data);
   history.navigate = useNavigate();
   history.location = useLocation();

   console.log(accesos)

   function RouteWrapper({ Component, ...restProps }) {
      return <Component {...restProps} />;
   }

   // Rutas para Bregma
   const accesoComponentBregma = {
      perfil: PagePerfil,
      operaciones: Operaciones,
      "operaciones/servicios": OperServicios,
      "operaciones/mantenimiento": PageMantenimiento,
      productosyservicios: PageProductosServicios,
      "productosyservicios/productos": Productos,
      "productosyservicios/servicios": Servicios,
      "productosyservicios/terminosycondiciones": TermConditions,
      recursoshumanos: PageRecursosHumanosBregma,
      ventas: PageSales,
      "recursoshumanos/reclutamiento": PageReclutamiento,
      "recursoshumanos/locales": Locales,
      "recursoshumanos/personal": Personal,
      "recursoshumanos/roles": PageRolesEmpresa,
      contabilidad: PageContabilidad,
      "contabilidad/facturacion": Facturacion,
      "contabilidad/pagos": Pagos,
      "contabilidad/costoatencion": GestionAtencion,
      estadisticas: PageSales,
      soportetecnico: PageSoporte,
      "ventas/gestiondeventas": PageSalesMagnament,
      "ventas/clientes": PageGestionClientes,
   };
   // Rutas para Clinica
   const accesoComponentClinica = {
      perfil: PagePerfilClinica,
      operaciones: PageOperacionesB,
      "operaciones/servicios": PageOperServiciosB,
      productosyservicios: PageProductosServiciosB,
      "productosyservicios/productos": PageMantenimiento,
      "productosyservicios/servicios": PageServiciosB,
      "productosyservicios/terminosycondiciones": TermConditionsB,
      "productosyservicios/gestiondeproforma": PageGestionProformaB,
      ventas: PageSalesC,
      "ventas/gestiondeventas": PageSalesMagnamentC,
      recursoshumanos: PageRecursosHumanosB,
      "recursoshumanos/personal": PageMiPersonalB,
      "recursoshumanos/evaluadores": PageListaEvaluadores,
      "recursoshumanos/locales": PageMisLocalesB,
      "recursoshumanos/reclutamiento": PageReclutamiento,
      "recursoshumanos/roles": PageRolesEmpresa,
      "recursoshumanos/serviciosprestados": PageListaEvaluadores,
      contabilidad: PageContabilidadB,
      estadisticas: PageEstadisticaB,
      soportetecnico: PageSoporteTecnicoB,
      atencion: PageAtencionesB,
      "atencion/triaje": PageMenuTriaje,
      "atencion/psicologia": PageMenuPsicologia,
      "atencion/ekg": PageMenuEkg,
      "atencion/odontologia": PageMenuOdontologia,
      "atencion/oftalmologia": PageMenuOftalmologia,
      "atencion/medicinageneral": PageMenuMedicinaGeneral,
      "atencion/espirometria": PageMenuEspirometria,
      "atencion/audiometria": PageMenuAud,
      "atencion/radiologia": PageMenuRad,
      "atencion/laboratorio": PageMenuLaboratorio,
      getsiondeatenciones: PageGestionAtencion,
      "getsiondeatenciones/recepcion": PageRecepcion,
      "getsiondeatenciones/auditoria": PageAuditoria,
      "getsiondeatenciones/historiaclinica": PageHistoriaE,
      "getsiondeatenciones/liquidacion": PageLiquidacion,
   };
   // Rutas para Empresa
   const accesoComponentEmpresa = {
      perfil: PagePerfilEmpresa,
      productosyservicios: PageProductosServiciosEmpresa,
      "productosyservicios/productos": PageProductosB,
      "productosyservicios/servicios": PageServiciosEmpresa,
      "productosyservicios/gestiondeproforma": PageGestionPlataformasEmpresa,
      recursoshumanos: PageRecursosHumanosEmpresa,
      "recursoshumanos/locales": PageMisLocalesEmpresa,
      "recursoshumanos/local/:id/areas": PageMisAreasE,
      "recursoshumanos/roles": PageRolesEmpresa,
      "recursoshumanos/personal": PagePersonalEmpresa,
      "recursoshumanos/reclutamiento": PageReclutamientoEmpresa,
      "recursoshumanos/puestotrabajo": PagiaPuestoTrabajoB,
      gestionatenciones: PageGestionAtencionEmpresa,
      historiasclinicas: PageHistoriaClinica,
      estadisticas: PageEstadisticasEm,
      "estadisticas/estadisticasgenerales": EstadiGeneralesE,
      "estadisticas/cercoepidemiologico": CercoPrueba,
      contabilidad: PageContabilidadEmpresa,
      soportetecnico: Soporte,
      "contabilidad/pagos": PageMisPagosEmpresa,
      soportetecnicoempresa: PageSoporteTecnicoEmpresa,
      "estadisticas/encuestaAceptacion": PageAceptacionesE,
      mantenimiento: PageMantenimientoCorporativo,
      notificaciones: Notificaciones,
   };
   // Rutas para Proveedor - Asociado
   const accesoComponentProveedor = {
      "pacientes": PagePacientes,
      "perfil": PagePerfilProveedor,
      //"roles": PageRolesEmpresa,
   };

   //console.log(accesos);

   return (
      <div className="App">
         <Routes>
            <Route path="/" element={<PageLogin />} />
            <Route path="login" element={<PageLogin />} />
            {(tipo_acceso === 1 && (
               <Route
                  element={
                     <Container
                        // persona={dataPersona}
                        // loading={loadingPersona}
                        sidebar={accesos}
                        rol={tipo_acceso}
                     />
                  }
               >

                  {accesos.map((acceso) => (
                     <>
                        <Route
                           key={acceso.slug}
                           path={acceso.slug}
                           element={
                              <RouteWrapper
                                 Component={
                                    accesoComponentBregma[acceso.slug] ||
                                    PageMantenimiento
                                 }
                                 accesoProp={acceso.children}
                              />
                           }
                        />
                        {acceso.children.map((accesoChildren) => (
                           <>
                              <Route
                                 key={accesoChildren.slug}
                                 path={accesoChildren.slug}
                                 element={
                                    <RouteWrapper
                                       Component={
                                          accesoComponentBregma[
                                          accesoChildren.slug
                                          ] || PageMantenimiento
                                       }
                                    />
                                 }
                              />
                              {(accesoChildren.slug ===
                                 "recursoshumanos/locales" && (
                                    <Route
                                       path="recursoshumanos/local/:id/areas"
                                       element={<PageAreasLocales />}
                                    />
                                 )) || (accesoChildren.slug ===
                                    "ventas/gestiondeventas" && (
                                       <>
                                          <Route
                                             path="ventas/gestiondeventas/empresanuevocliente"
                                             element={
                                                <SalesProvider>
                                                   <PageNewClient />
                                                </SalesProvider>
                                             }
                                          />
                                          <Route
                                             path="ventas/gestiondeventas/empresa/:id/editar"
                                             element={
                                                <SalesProvider>
                                                   <PageNewClient />
                                                </SalesProvider>
                                             }
                                          />
                                          <Route
                                             path="ventas/gestiondeventas/empresa/:id/credenciales"
                                             element={
                                                <SalesProvider>
                                                   <EnvioCredenciales />
                                                </SalesProvider>
                                             }
                                          />
                                       </>
                                    ))}
                           </>
                        ))}
                     </>
                  ))}
               </Route>
            )) ||
               (tipo_acceso === 2 && (
                  <Route
                     element={
                        <Container
                           // persona={dataPersona}
                           // loading={loadingPersona}
                           sidebar={accesos}
                           rol={tipo_acceso}
                        />
                     }
                  >
                     {accesos.map((acceso) =>
                        acceso.slug === "atencion" ? (
                           <>
                              <Route
                                 key={acceso.slug}
                                 path={acceso.slug}
                                 element={
                                    <RAProvider>
                                       <RouteWrapper
                                          Component={
                                             accesoComponentClinica[
                                             acceso.slug
                                             ] || PageMantenimiento
                                          }
                                          accesoProp={acceso.children}
                                       />
                                    </RAProvider>
                                 }
                              />
                              {acceso.children.map((accesoChildren, key) => (
                                 <>
                                    <Route
                                       key={key + accesoChildren.slug}
                                       path={accesoChildren.slug}
                                       element={
                                          <RAProvider>
                                             <RouteWrapper
                                                Component={
                                                   accesoComponentClinica[
                                                   accesoChildren.slug
                                                   ] || PageMantenimiento
                                                }
                                             />
                                          </RAProvider>
                                       }
                                    />
                                 </>
                              ))}
                           </>
                        ) : (
                           acceso.slug === "getsiondeatenciones" ? (
                              <>

                                 <Route
                                    key={acceso.slug}
                                    path={acceso.slug}
                                    element={
                                       <GestionProvider>
                                          <RouteWrapper
                                             Component={
                                                accesoComponentClinica[acceso.slug] ||
                                                PageMantenimiento
                                             }
                                             accesoProp={acceso.children}
                                          />
                                       </GestionProvider>
                                    }
                                 />
                                 {acceso.children.map((accesoChildren) => (
                                    <>
                                       <Route
                                          key={accesoChildren.slug}
                                          path={accesoChildren.slug}
                                          element={
                                             <GestionProvider>
                                                <RouteWrapper
                                                   Component={
                                                      accesoComponentClinica[
                                                      accesoChildren.slug
                                                      ] || PageMantenimiento
                                                   }
                                                />
                                             </GestionProvider>
                                          }
                                       />
                                    </>
                                 ))}
                              </>
                           ) : (
                              <>
                                 <Route
                                    key={acceso.slug}
                                    path={acceso.slug}
                                    element={
                                       <RouteWrapper
                                          Component={
                                             accesoComponentClinica[acceso.slug] ||
                                             PageMantenimiento
                                          }
                                          accesoProp={acceso.children}
                                       />
                                    }
                                 />
                                 {acceso.children.map((accesoChildren) => (
                                    <>
                                       <Route
                                          key={accesoChildren.slug}
                                          path={accesoChildren.slug}
                                          element={
                                             <RouteWrapper
                                                Component={
                                                   accesoComponentClinica[
                                                   accesoChildren.slug
                                                   ] || PageMantenimiento
                                                }
                                             />
                                          }
                                       />
                                       {(accesoChildren.slug ===
                                          "ventas/gestiondeventas" && (
                                             <>
                                                <Route
                                                   path="ventas/gestiondeventas/empresanuevocliente"
                                                   element={
                                                      <SalesProvider>
                                                         <PageNewClientCPbl />
                                                      </SalesProvider>
                                                   }
                                                />
                                                <Route
                                                   path="ventas/gestiondeventas/empresa/:id/editar"
                                                   element={
                                                      <SalesProvider>
                                                         <PageNewClientCPbl />
                                                      </SalesProvider>
                                                   }
                                                />
                                                <Route
                                                   path="ventas/gestiondeventas/particularnuevocliente"
                                                   element={
                                                      <SalesProvider>
                                                         <PageNClientePartCli />
                                                      </SalesProvider>
                                                   }
                                                />
                                                <Route
                                                   path="ventas/gestiondeventas/empresa/:id/credenciales"
                                                   element={
                                                      <SalesProvider>
                                                         <GestionProforma />
                                                      </SalesProvider>
                                                   }
                                                />
                                             </>
                                          )) ||
                                          (accesoChildren.slug ===
                                             "recursoshumanos/locales" && (
                                                <>
                                                   <Route
                                                      path="recursoshumanos/local/:id/areas"
                                                      element={
                                                         <PageAreaslocalClinica />
                                                      }
                                                   />
                                                </>
                                             )) ||
                                          (accesoChildren.slug ===
                                             "productosyservicios/servicios" && (
                                                <>
                                                   <Route
                                                      path="productosyservicios/servicios/editar/:id"
                                                      element={
                                                         <ServicioProvider>
                                                            {" "}
                                                            <PageEditarServiciosB />
                                                         </ServicioProvider>
                                                      }
                                                   />
                                                   <Route
                                                      path="productosyservicios/servicios/editarCampaña/:id"
                                                      element={
                                                         <ServicioProvider>
                                                            {" "}
                                                            <PageEditarServiciosCampaña />
                                                         </ServicioProvider>
                                                      }
                                                   />
                                                   <Route
                                                      path="gestiondeventas/proformas"
                                                      element={
                                                         <ServicioProvider>
                                                            <ClinProforma />
                                                         </ServicioProvider>}
                                                   />
                                                   <Route
                                                      path="productosyservicios/servicios/agregarserviciocoorporativo"
                                                      element={
                                                         <ServicioProvider>
                                                            <PageAgregarServiciosCoorporativo />
                                                         </ServicioProvider>
                                                      }
                                                   />
                                                   <Route
                                                      path="productosyservicios/servicios/agregarparticular"
                                                      element={
                                                         <ServicioProvider>
                                                            <PageAgregarServiciosB />
                                                         </ServicioProvider>
                                                      }
                                                   />
                                                </>
                                             )

                                          )
                                       }
                                    </>
                                 ))}
                              </>)
                        )
                     )}

                     {/* Provicional!! */}
                     {/* <Route path="getsiondeatenciones/liquidacion" element={<PageLiquidacion/>}/> */}
                     <Route
                        key="getsiondeatenciones/liquidacion"
                        path="getsiondeatenciones/liquidacion"
                        element={
                           <GestionProvider>
                              <RouteWrapper
                                 Component={PageLiquidacion}
                                 accesoProp={[]} // Puedes pasar datos adicionales si es necesario
                              />
                           </GestionProvider>
                        }
                     />
                     {/*  Fin provisional */}
                     <Route
                        path="estadisticas/estadisticasconta"
                        element={<PageEstadisticaContaB />}
                     />
                     <Route
                        path="gestiondeventas/Proformas"
                        element={<ClinProforma />}
                     />
                     <Route
                        path="estadisticas/estadisticasps"
                        element={<PageEstadisticaPSB />}
                     />
                     <Route
                        path="estadisticas/estadisticasrh"
                        element={<PageEstadisticaRHB />}
                     />
                     <Route
                        path="estadisticas/estadisticasventas"
                        element={<PageEstadisticaVentasB />}
                     />

                     <Route
                        path="estadisticas/estadisticasmedica"
                        element={<PageEstadisticaMedica />}
                     />

                     <Route
                        path="estadisticas/estadisticasmedica"
                        element={<PageEstadisticaMedica />}
                     />
                     <Route
                        path="/rutaatencion/resultados/:tipo"
                        element={
                           <RAProvider>
                              <PageRAResultados />
                           </RAProvider>
                        }
                     />
                     <Route
                        path="/atencion/revision/:idPaciente"
                        element={
                           <GestionProvider>
                              <RAProvider>
                                 <PageRevision />
                              </RAProvider>
                           </GestionProvider>
                        }
                     />
                     <Route
                        path="/rutaatencion/historial"
                        element={<PageRAHistorial />}
                     />
                     <Route
                        path="/rutaatencion/estadisticas"
                        element={<PageRAEstadisticas />}
                     />
                     <Route
                        path="/rutaatencion/atencion/:idPaciente"
                        element={
                           <RAProvider>
                              <PageRAAtencion />
                           </RAProvider>
                        }
                     />

                     <Route
                        path="/ventas/gestionclientes/proforma"
                        element={<PageProforma />}
                     />
                     <Route
                        path="/getsiondeatenciones/historiaclinica/:idPaciente"
                        element={
                           <RAProvider>
                              <HCNavigator />
                           </RAProvider>
                        }
                     />
                  </Route>
               )) ||
               (tipo_acceso === 3 && (
                  <Route
                     element={
                        <Container
                           // persona={dataPersona}
                           // loading={loadingPersona}
                           sidebar={accesos}
                           rol={tipo_acceso}
                        />
                     }
                  >
                     {accesos.map((acceso) =>
                        acceso.slug === "gestionatenciones" ? (
                           <Route
                              key={acceso.slug}
                              path={acceso.slug}
                              element={
                                 <GestionAtencionProvider>
                                    <RouteWrapper
                                       Component={
                                          accesoComponentEmpresa[acceso.slug] ||
                                          PageMantenimiento
                                       }
                                       accesoProp={acceso.children}
                                    />
                                 </GestionAtencionProvider>
                              }
                           />
                        ) :
                           (
                              <>
                                 <Route
                                    key={acceso.slug}
                                    path={acceso.slug}
                                    element={
                                       <RouteWrapper
                                          Component={
                                             accesoComponentEmpresa[acceso.slug] ||
                                             PageMantenimiento
                                          }
                                          accesoProp={acceso.children}
                                       />
                                    }
                                 />

                                 {acceso.children.map((accesoChildren) => (
                                    <>
                                       <Route
                                          key={accesoChildren.slug}
                                          path={accesoChildren.slug}
                                          element={
                                             <RouteWrapper
                                                Component={
                                                   accesoComponentEmpresa[
                                                   accesoChildren.slug
                                                   ] || PageMantenimiento
                                                }
                                             />
                                          }
                                       />
                                       {
                                          accesoChildren.slug ===
                                          "recursoshumanos/locales" && (
                                             <>
                                                <Route
                                                   path="recursoshumanos/local/:id/areas"
                                                   element={
                                                      <PageMisAreasE />
                                                   }
                                                />
                                             </>
                                          )
                                       }
                                    </>
                                 ))}
                              </>
                           ))}
                  </Route>
               )) ||
               (tipo_acceso === 4 && (
                  <Route
                     element={
                        <Container
                           // persona={dataPersona}
                           // loading={loadingPersona}
                           sidebar={accesos}
                           rol={tipo_acceso}
                        />
                     }
                  >
                     {accesos.map((acceso) => (
                        <>
                           <Route
                              key={acceso.slug}
                              path={acceso.slug}
                              element={
                                 <RouteWrapper
                                    Component={
                                       accesoComponentProveedor[acceso.slug] ||
                                       PageMantenimiento
                                    }
                                    accesoProp={acceso.children}
                                 />
                              }
                           />

                           {acceso.children.map((accesoChildren) => (
                              <>
                                 <Route
                                    key={accesoChildren.slug}
                                    path={accesoChildren.slug}
                                    element={
                                       <RouteWrapper
                                          Component={
                                             accesoComponentProveedor[
                                             accesoChildren.slug
                                             ] || PageMantenimiento
                                          }
                                       />
                                    }
                                 />
                              </>
                           ))}
                        </>
                     ))}
                  </Route>
               ))
            }
         </Routes>
      </div>
   );
}

export default App; 