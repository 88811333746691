import React, { useContext, useRef, useState } from 'react';
import { Button } from "primereact/button";
import AuthUser from "../../AuthUser";
import { AudContext } from "./AudContext";
import { TabPanel, TabView } from "primereact/tabview";
import PageAudPrincipal from "./Principal/PageAudPrincipal";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconAudiometria } from "../../Services/importIcons";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';

const PageAud = () => {
  const [imprimirVisible, setImprimirVisible] = useState(false)

  let { principalContext } = useContext(AudContext)

  const { http } = AuthUser();

  const [activeIndex, setActiveIndex] = useState(0);
  const [savingStatus, setSavingStatus] = useState(false);

  const { atencionStatus, setAtencionStatus } = useContext(AudContext);
  const { setTimeStart } = useContext(AudContext);

  const toast = useRef(null);

  const showToast = (type, title, detail) => {
    toast.current.show({
      severity: type,
      summary: title,
      detail: detail,
      life: 3000,
    });
  };

  const handleSubmit = () => {
    setSavingStatus(true)

    const allFetching = [
      http
        .post('audiometria/vistaprincipal/create', principalContext)
        .then(() => {
          console.log('Audiometria Principal guardado');
        })
        .catch((error) => {
          console.log(error);
          console.log('Error en Audiometria Principal');
        })
    ]

    Promise.allSettled(allFetching)
      .then((results) => {
        results.forEach((result) => console.log(result.status));
        showToast(
          "success",
          `Atención en Audiometría guardada`,
          `Se guardó la atención correctamente`
        );
        setSavingStatus(false);
      })
      .catch(() => {
        setSavingStatus(false);
      })

  }

  const tabSubmitHeader = (options) => (
    <Button
      icon={savingStatus ? `pi pi-spin pi-spinner` : `pi pi-save`}
      label={savingStatus ? 'Guardando ...' : options.titleElement}
      className="p-button-sm"
    />
  );

  return (
    <>

      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        scrollable
      >
        <TabPanel header="Principal" >
          <PageAudPrincipal />
        </TabPanel>
        { }
      </TabView>
      <div style={{ borderRadius: '10px', height: "70px" }} className='flex justify-content-end gap-2 mt-2 bg-white'>
        <button className='p-button p-component btn-2 flex gap-1'>
          <i className='pi pi-save'> </i>
          <span>Guardar</span>
        </button>
        <button style={{ color: 'red' }} className='p-button  p-component btn-2 flex gap-1'>
          <i className='pi pi-times'></i>
          <span>Cancelar</span>
        </button>
        <button style={{ color: 'black' }} onClick={() => setImprimirVisible(true)} className='p-button p-component btn-2 flex gap-1'>
          <i className='pi pi-print'></i>
          <span>Imprimir</span>
        </button>
      </div>
      <ModalImprimir
        imprimirVisible={imprimirVisible}
        setImprimirVisible={setImprimirVisible}
      />

      {/* {
        atencionStatus === 0
        &&
        <RATimeView title="Audiometría" icon={iconAudiometria} setTimeStart={setTimeStart} setAtencionStatus={setAtencionStatus} />
      } */}

    </>
  );
};

export default PageAud;