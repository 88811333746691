import Botones from "../../../Components/Pure/MenuPrincipal/botones";
import Imagenes from "../../../Components/Pure/MenuPrincipal/Imagenes";
import Eclipses from "../../../Components/Pure/MenuPrincipal/Eclipses";
import Icon1 from "../../../Images/Pure/MenuPrincipal/img-icons/ven1.png";
import Productos from "../../../Images/Pure/MenuPrincipal/Img/Operaciones.png";
import "../../../Style/PageSalesC.css"

const PageSalesC = ({ accesoProp }) => {
   console.log("accesos en prop", accesoProp);

   const list_images = (url) => {
      switch (url) {
         case "gestiondeventas":
            return Icon1;
         default:
            return Icon1;
      }
   }

   return (
      <div>
         <div className="arriba" style={{ marginBottom: "200px" }}>
            <span className="tit">Ventas</span>
            <br />
            <span className="con">
               En este módulo usted podrá crear proformas, clientes y asignación de módulos.
            </span>
         </div>
         <div>
            {  
               accesoProp.filter(acceso => acceso.url !== "gestiondeclientes").map((acceso, key) => (
                  <Botones
                     key={key}
                     Link={acceso.url}
                     img={list_images(acceso.url)}
                     span={acceso.label}
                     button={"button b"+ (key+1)}
                  />
               ))

               /*accesoProp.map((acceso, key) => {
                  return <>
                     <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b" + (key + 1)}
                     />
                  </>
               })*/
            }

            <Eclipses />
            <Imagenes src={Productos} className="logo2" />
            {/* <Botones
                    Link="gestionventas"
                    img={Icon1}
                    span="Gestion Ventas"
                    button="button b3"
                /> */}
         </div>
      </div>
   );
}

export default PageSalesC;
