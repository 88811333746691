import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from 'primereact/toast';
import AuthUser from "../../../../AuthUser";
import { endLoading, startLoading } from "../../../../Redux/isLoadingSlice";

export default function ModalPerfilEMO({
   visiblePerfilEmo,
   setVisiblePerfilEmo,
   paquetes,
   cargo,
   showToast,
   getCargos,
   dispatch
}) {
   const { http } = AuthUser();
   const [paquete, setPaquete] = useState(null);
   const [perfiles, setPerfiles] = useState([]);
   const [perfil, setPerfil] = useState(null);
   const toast = useRef(null);

   useEffect(() => {
      const paqueteEncontrado = paquetes.find(elemento => elemento.paquete.id === cargo?.con_perfil?.con_clinica_paquete?.id);
      if (paqueteEncontrado) {
         // Si se encuentra el paquete, obtener los perfiles relacionados
         const perfilesDelPaquete = paqueteEncontrado.perfil;
         // Buscar el perfil con el ID deseado
         const perfilEncontrado = perfilesDelPaquete.find(perfil => perfil.id === cargo?.con_perfil?.id);
         if (perfilEncontrado) {
            setPaquete(paqueteEncontrado);
            setPerfiles(perfilesDelPaquete);
            setPerfil(perfilEncontrado);
         }
      } else {
         setPaquete(null);
         setPerfiles([]);
         setPerfil(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cargo?.con_perfil?.con_clinica_paquete?.id, cargo?.con_perfil?.id, visiblePerfilEmo]);

   const HidePerfilEmo = () => {
      setPaquete({});
      setPerfiles([]);
      setPerfil({});
      setVisiblePerfilEmo(false);

   };

   const asignarPerfil = () => {
      if (!paquete || Object.keys(paquete).length === 0) {
         showToast('error', 'Falta completar', 'Por favor, seleccione un paquete.');
      } else if (!perfil || Object.keys(perfil).length === 0) {
         showToast('error', 'Falta completar', 'Por favor, seleccione un perfil.');
      } else {
         let data_send = {
            con_perfil_id: perfil?.id
         };
         dispatch(startLoading());
         http
            .put(`empresa/cargo/con_perfil/${cargo?.id}`, data_send)
            .then((response) => {
               if (response?.data?.resp) {
                  console.log("RESPUESTA ASIGNAR", response?.data?.resp);
                  showToast('success', 'Asignación exitosa', 'Se asignó el perfil correctamente');
                  getCargos();
                  HidePerfilEmo();
               }else if (response?.data?.error) {
                  showToast("error", 'Error de asignación', 'Hubo un error al asignar el perfil', response?.data?.error)
               }

            })
            .catch((error) => {
               console.log(error);
               showToast("error", "Error", "Contáctese con Soporte Técnico.")
            })
            .finally(()=>{
               dispatch(endLoading());
            })
      }
   };

   const handleChangePaquete = (e) => {
      setPaquete(e.value);
      setPerfiles(e.value.perfil);
   };

   const handleChangePerfil = (e) => {
      setPerfil(e.value);
   };

   const paqueteFooter = (
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="flex w-full bot">
         <Button style={{ width: "50%" }}
            type="button"
            label="Cancelar"
            className="delete"
            onClick={() => {
               HidePerfilEmo();
            }}
         />
         <Button
            type="button"
            label="Asignar"
            onClick={asignarPerfil}
            className="p-button-success"
            style={{
               width: "50%",
               backgroundColor: "#5555D8",
               borderColor: "#5555D8"
            }}
         />
      </div>
   );

   /*    const paqueterPlace= cargo?.con_perfil?.con_clinica_paquete?.nombre || "Selecciona una opción"
      const tipoPerfilPlace= cargo?.con_perfil?.nombre || "Selecciona una opción"
     */
   return (
      <div className="flex justify-content-center">
         <Toast ref={toast} />

         <form
            id="asignar-personal-paquete-form"
            className="asignar-personal-paquete-form flex flex-column gap-3"
            noValidate
         />

         <Dialog
            appendTo={document.getElementById("asignar-personal-paquete-form")}
            visible={visiblePerfilEmo}
            style={{ width: "50vw", maxWidth: "424px" }}
            onHide={() => HidePerfilEmo(false)}
            header={
               <>
                  <div className="flex align-items-center">
                     <div className="flex justify-content-center align-items-center"
                        style={{ height: "60px", width: '60px', background: "#EBEBFF", borderRadius: "10px", marginRight: '7px' }}
                     >
                        <i className='pi pi-user' style={{ color: '#5555d8', fontSize: '2rem' }}></i>
                     </div>
                     <h1 style={{ fontWeight: "700", fontSize: "18px", color: "#5555D8" }}>
                        Perfil EMO
                     </h1>
                  </div>

                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#7B8794" }}>
                     Elija el tipo de perfil que corresponda{" "}
                  </p>
               </>
            }
            footer={paqueteFooter}
         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column gap-3">
                  <p
                     style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}
                  >
                     Paquete:
                  </p>
                  <Dropdown
                     id="perfil_id"
                     name="perfil_id"
                     value={/*paquetes[0].*/paquete/*.nombre*/}
                     options={paquetes}
                     optionLabel="paquete.nombre"
                     placeholder="Selecciona una opción"
                     className="w-full "
                     onChange={(e) => handleChangePaquete(e)}
                  />
                  <p
                     style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}
                  >
                     Tipo de Perfil:
                  </p>
                  <Dropdown
                     id="perfil_tipo_id"
                     name="perfil_tipo_id"
                     options={perfiles}
                     value={perfil}
                     optionLabel="nombre"
                     placeholder="Selecciona una opción"
                     className="w-full "
                     onChange={(e) => handleChangePerfil(e)}
                  />
               </div>
            </div>
         </Dialog>
      </div>
   );
}