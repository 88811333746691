import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../../../Style/ANTECEDENTE.css";
import ICONO from "../img/ICONODEANTECEDENTE.svg";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from "primereact/dropdown";
import InputCalendar from "../../../../Components/form/InputCalendar";
import { TablaRiesgo } from "./TablaRiesgo";
import DialogAgregarRiesgo from "./DialogAgregarRiesgo";
import { TriajeContext } from "../../../Triaje/TriajeContext";
import { RAContext } from "../../../RutaAtencion/RAContext";

export default function DialogAgregarAntLab({ DatosEditar, DatosEditarRiesgo, VisibleEditarAntLab, setVisibleEditarAntLab, mostrarDatosActualizados, setDatosEditar,showToast }) {
   let { antLabRiesgoContext, setAntLabRiesgoContext } = useContext(TriajeContext);
   let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
   /* DATOS DENTRO DE ANTECEDENTE LABORAL */
   const [TrabajoActual, setTrabajoActual] = useState(DatosEditar?.trabajo_actual ?? null);
   const [Empresa, setEmpresa] = useState(DatosEditar?.empresa ?? null);
   const [ActividaEmp, setActividaEmp] = useState(DatosEditar?.actividad_empresarial ?? null);
   const [Ocupacion, setOcupacion] = useState(DatosEditar?.ocupacion ?? null);
   const [PuestoTrab, setPuestoTrab] = useState(DatosEditar?.puesto_trabajo ?? null);
   const [AreaTrabajo, setAreaTrabajo] = useState(DatosEditar?.area_trabajo ?? null);
   const [AlturaLabor, setAlturaLabor] = useState(DatosEditar?.altura_labor ?? null);
   const [FIngreso, setFIngreso] = useState(DatosEditar?.fecha_ingreso ?? " ");
   const [FSalida, setFSalida] = useState(DatosEditar?.fecha_salida ?? " ");
   const [Tiempo, setTiempo] = useState(DatosEditar?.tiempo ?? null);
   const [AreaTrabajoInput, setAreaTrabajoInput] = useState(DatosEditar?.area_trabajo ?? null);
   const [AlturaTrabajoInput, setAlturaTrabajoInput] = useState(DatosEditar?.altura_labor ?? null);
   const [HabilitarinpAreaTab, setHabilitarinpAreaTab] = useState(true);
   const [HabilitarinpAltTab, setHabilitarinpAltTab] = useState(true);
   const [fechaFormateadaIngreso, setfechaFormateadaIngreso] = useState(null);
   const [fechaFormateadaSalida, setfechaFormateadaSalida] = useState(null);
   const [FechaFormateadaIngresoEditar, setFechaFormateadaIngresoEditar] = useState(null);
   const [FechaFormateadaSalidaEditar, setFechaFormateadaSalidaEditar] = useState(null);
   /*actualización de los datos, de acuerdo al DatosEditar */
   useEffect(() => {
      setTrabajoActual(DatosEditar?.trabajo_actual ?? null);
      setEmpresa(DatosEditar?.empresa ?? null);
      setActividaEmp(DatosEditar?.actividad_empresarial ?? null);
      setOcupacion(DatosEditar?.ocupacion ?? null);
      setPuestoTrab(DatosEditar?.puesto_trabajo ?? null);
      setAreaTrabajo(DatosEditar?.area_trabajo ?? null);
      setAlturaLabor(DatosEditar?.altura_labor ?? null);
      setFIngreso(DatosEditar?.fecha_ingreso ?? " ");
      setFSalida(DatosEditar?.fecha_salida ?? " ");
      setTiempo(DatosEditar?.tiempo ?? null);
      setAreaTrabajoInput(DatosEditar?.area_trabajo ?? null);
      setAlturaTrabajoInput(DatosEditar?.altura_labor ?? null);

      /**VALIDACIÓN PARA QUE SE MUESTRE LA OPCIÓN GUARDADA EN TRABAJO ACTUAL */
      if (DatosEditar?.trabajo_actual === "Si") {
         setTrabajoActual({ name: 'Si', code: '0' })
      } else if (DatosEditar?.trabajo_actual === "No") {
         setTrabajoActual({ name: 'No', code: '1' })
      }

       // VALIDACIÓN PARA CONFIGURAR EN FECHA DE INGRESO
   if (DatosEditar?.fecha_ingreso != null && !fechaFormateadaIngreso) {
      let fechaOriginal = DatosEditar?.fecha_ingreso;
      let [anio, mes, dia] = fechaOriginal?.split('-');
      setfechaFormateadaIngreso(`${dia}/${mes}/${anio}`);
      setFechaFormateadaIngresoEditar(`${anio}-${mes}-${dia}`);
   }

   // VALIDACIÓN PARA CONFIGURAR EN FECHA DE SALIDA
   if (DatosEditar?.fecha_salida != null && !fechaFormateadaSalida) {
      let fechaOriginal = DatosEditar?.fecha_salida;
      let [anio, mes, dia] = fechaOriginal?.split('-');
      setfechaFormateadaSalida(`${dia}/${mes}/${anio}`);
      setFechaFormateadaSalidaEditar(`${anio}-${mes}-${dia}`);
   }

      /*if (DatosEditar?.area_trabajo != null) {
         setAreaTrabajo({ name: 'Otros', code: '6' })
         setHabilitarinpAreaTab(false)
      }

      if (DatosEditar?.altura_labor != null) {
         setAlturaLabor({ name: 'Otros', code: '6' })
         setHabilitarinpAltTab(false)
      }*/
      if (DatosEditar?.area_trabajo != null) {
   const areaTrabajoSeleccionada = areatrabajo.find(option => option.name === DatosEditar.area_trabajo);
   if (areaTrabajoSeleccionada) {
      setAreaTrabajo(areaTrabajoSeleccionada);
      setAreaTrabajoInput("");
      if (areaTrabajoSeleccionada.name !== "Otros") {
         setHabilitarinpAreaTab(true); // Si la opción no es "Otros", deshabilita el input
      } else {
         setHabilitarinpAreaTab(false);
      }
   } else {
      // Si el valor cargado no coincide con ninguna opción, forzar la visualización de "Otros"
      setAreaTrabajo({ name: 'Otros', code: '6' });
      setAreaTrabajoInput(DatosEditar.area_trabajo);
      setHabilitarinpAreaTab(false);
   }
} else {
   setAreaTrabajo({ name: 'Otros', code: '6' });
   setHabilitarinpAreaTab(false);
}

if (DatosEditar?.altura_labor != null) {
   const alturaLaborSeleccionada = alturalabor.find(option => option.name === DatosEditar.altura_labor);
   if (alturaLaborSeleccionada) {
      setAlturaLabor(alturaLaborSeleccionada);
      setAlturaTrabajoInput("");
      if (alturaLaborSeleccionada.name !== "Otros") {
         setHabilitarinpAltTab(true); // Si la opción no es "Otros", deshabilita el input
      } else {
         setHabilitarinpAltTab(false);
      }
   } else {
      // Si el valor cargado no coincide con ninguna opción, forzar la visualización de "Otros"
      setAlturaLabor({ name: 'Otros', code: '6' });
      setAlturaTrabajoInput(DatosEditar.altura_labor);
      setHabilitarinpAltTab(false);
   }
} else {
   setAlturaLabor({ name: 'Otros', code: '6' });
   setHabilitarinpAltTab(false);
}
   }, [DatosEditar])

   const UpdateAntLab = () => {
      let datosActualizados = {
         TrabajoActual,
         Empresa,
         ActividaEmp,
         Ocupacion,
         PuestoTrab,
         AreaTrabajo,
         AlturaLabor,
         FechaFormateadaIngresoEditar,
         FechaFormateadaSalidaEditar,
         Tiempo,
         AreaTrabajoInput,
         AlturaTrabajoInput
      };
      mostrarDatosActualizados(datosActualizados);
      setVisibleEditarAntLab(false)
   }
   const ChangeTrabajoActual = (e) => {
      setTrabajoActual(e);
   };
   const ChangeEmpresa = (e) => {
      setEmpresa(e);
   };
   const ChangeActividaEmp = (e) => {
      setActividaEmp(e);
   };
   const ChangeOcupacion = (e) => {
      setOcupacion(e);
   };
   const ChangePuestoTrab = (e) => {
      setPuestoTrab(e);
   };
   const ChangeAreaTrabajo = (e) => {
      setAreaTrabajo(e);
      if (e?.name === "Otros") {
         setHabilitarinpAreaTab(false);
      } else {
         setHabilitarinpAreaTab(true);
         setAreaTrabajoInput("");
      }
   };

   const ChangeAreaTrabajoInput = (e) => {
      setAreaTrabajoInput(e);
   }

   const ChangeAlturaLabor = (e) => {
      setAlturaLabor(e);
      if (e?.name === "Otros") {
         setHabilitarinpAltTab(false)
      } else {
         setHabilitarinpAltTab(true)
         setAlturaTrabajoInput("")
      }
   };

   const ChangeAlturaLaborInput = (e) => {
      setAlturaTrabajoInput(e)

   }
   const ChangeFIngreso = (e) => {
      let formattedFecha = null;

      if (e !== "") {
         const fecha = new Date(e);
         formattedFecha = fecha.toISOString().split("T")[0];
         setFechaFormateadaIngresoEditar(formattedFecha)
      }
      setFIngreso(e);
   };

   const ChangeFSalida = (e) => {
      let formattedFecha01 = null;
      if (e !== "") {
         const fecha = new Date(e);
         formattedFecha01 = fecha.toISOString().split("T")[0];
         setFechaFormateadaSalidaEditar(formattedFecha01)
         console.log("hola", formattedFecha01)
      }
      setFSalida(e);
   };

   // const ChangeTiempo = (e) => {
   //    // setTiempo(e);
   // };

   useEffect(() => {
      if (FSalida != " " && FIngreso != " ") {
         let dia01 = new Date(FSalida)
         let dia02 = new Date(FIngreso)

         let diferenciaEnMeses = (dia01.getFullYear() - dia02.getFullYear()) * 12 + (dia01.getMonth() - dia02.getMonth());
         let años = Math.floor(diferenciaEnMeses / 12);
         let meses = diferenciaEnMeses % 12;
         setTiempo(años + " años " + meses + " meses");
      }
   }, [FIngreso, FSalida]);

   useEffect(() => {

   })

   const areatrabajo = [
      { name: "Mina", code: "0" },
      { name: "Superficie", code: "1" },
      { name: "Planta", code: "2" },
      { name: "Administración", code: "3" },
      { name: "Producción", code: "4" },
      { name: "Mantenimiento", code: "5" },
      { name: "Otros", code: "6" },
   ];

   const alturalabor = [
      { name: "Hasta 2500 mt", code: "0" },
      { name: "2501 a 3000 mt", code: "1" },
      { name: "3001 a 3500 mt", code: "2" },
      { name: "3501 a 4000 mt", code: "3" },
      { name: "4001 a 4500 mt", code: "4" },
      { name: "Ninguno", code: "5" },
      { name: "Otros", code: "6" },
   ];

   const TrabajoActualOpcion = [
      { name: "Si", code: "0" },
      { name: "No", code: "1" },
   ];

   const footerContent = (
      <div>
         <Button
            label="Aceptar"
            onClick={() => UpdateAntLab()}
            autoFocus
         />
         <Button
            label="Cancelar"
            onClick={() => setVisibleEditarAntLab(false)}
            className="p-button-text"
         />
      </div>
   );

   return (
      <Dialog
         visible={VisibleEditarAntLab}
         style={{ width: "70vw" }}
         onHide={() => setVisibleEditarAntLab(false)}
         footer={footerContent}
      >
         <div className="flex flex-column gap-3">
            <div className="CONTENEDORTITULOCONICONO gap-3">
               <div className="CONTENEDOR_ICONO">
                  <img src={ICONO} />
               </div>
               <span>Editar Antecedente Laboral</span>
            </div>
            <div
               className="CONTENEDOR_ITEM_HASTA_ALTURA flex flex-wrap gap-3"
               style={{ marginTop: "20px" }}
            >
               {/* Lado izquierdo */}
               <div className="CONTENEDOR_IZQUIERDO_ANTECEDENTE gap-3 flex-1">
                  <div className="INPUTTEXTIZQUIERDA">
                     <span>Empresa</span>
                     <InputTextarea
                        autoResize
                        rows={1}
                        className="flex w-9"
                        value={Empresa}
                        onChange={(e) => ChangeEmpresa(e.target.value)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
                  <div className="INPUTTEXTIZQUIERDA">
                     <span>Ocupación</span>
                     <InputTextarea
                        autoResize
                        rows={1}
                        className="flex w-9"
                        value={Ocupacion}
                        onChange={(e) => ChangeOcupacion(e.target.value)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
                  <div className="INPUTTEXTIZQUIERDA">
                     <span>Área de Trabajo</span>
                     <div className="flex flex-row justify-content-end gap-2 w-9">
                        <Dropdown
                           value={AreaTrabajo}
                           onChange={(e) => ChangeAreaTrabajo(e.value)}
                           options={areatrabajo}
                           optionLabel="name"
                           className="w-full "
                        //disabled={stateAtencion[pageViewContext].estado === 3}
                        />
                        <InputTextarea
                           autoResize
                           rows={1}
                           className="w-5"
                           onChange={(e) => ChangeAreaTrabajoInput(e.target.value)}
                           value={AreaTrabajoInput}
                           disabled={HabilitarinpAreaTab || stateAtencion[pageViewContext].estado === 3}
                        />
                     </div>
                  </div>
               </div>
               {/* Lado Derecho */}
               <div className="CONTENEDOR_DERECHO_ANTECEDENTE gap-3 flex-1">
                  <div className="INPUTTEXTDERECHA">
                     <span>Trabajo Actual: </span>
                     <Dropdown
                        className="flex w-9"
                        options={TrabajoActualOpcion}
                        optionLabel="name"
                        value={TrabajoActual}
                        onChange={(e) => ChangeTrabajoActual(e.target.value)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
                  <div className="INPUTTEXTDERECHA">
                     <span>Act. Empresarial:</span>
                     <InputTextarea
                        autoResize
                        rows={1}
                        className="flex w-9"
                        value={ActividaEmp}
                        onChange={(e) => ChangeActividaEmp(e.target.value)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
                  <div className="INPUTTEXTDERECHA">
                     <span>Puesto Trab./Proyec</span>
                     <InputTextarea
                        autoResize
                        rows={1}
                        className="flex w-9"
                        value={PuestoTrab}
                        onChange={(e) => ChangePuestoTrab(e.target.value)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
                  <div className="INPUTTEXTDERECHA">
                     <span>Altura Labor</span>
                     <div className="flex flex-row justify-content-end gap-2 w-9">
                        <Dropdown
                           value={AlturaLabor}
                           //optios={TrabajoActualOpcion}
                           //onChange={(e) => ChangeAlturaLabor(e.target.value)}
                           onChange={(e) => ChangeAlturaLabor(e.value)}
                           options={alturalabor}
                           optionLabel="name"
                           placeholder=""
                           className="w-full "
                           disabled={stateAtencion[pageViewContext].estado === 3}
                        />
                        <InputTextarea
                           autoResize
                           rows={1}
                           className="w-5"
                           value={AlturaTrabajoInput}
                           onChange={(e) => ChangeAlturaLaborInput(e.target.value)}
                           disabled={HabilitarinpAltTab || stateAtencion[pageViewContext].estado === 3}
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className="flex flex-wrap gap-3">
               {/* Fecha ingreso */}
               <div className="flex flex-column gap-2 w-full flex-1">
                  <span>F.Ingreso</span>
                  <InputCalendar
                  value={FIngreso}
                  placeholder={fechaFormateadaIngreso}
                  onChange={(e) => ChangeFIngreso(e.target.value)}
                  showIcon
                  disabled={stateAtencion[pageViewContext].estado === 3}
                  />
                  {/* <p>F. registrada actualmente: {DatosEditar?.fecha_ingreso == null ? "No existe" : fechaFormateadaIngreso}</p> */}
               </div>
               {/* Fecha Salida */}
               <div className="flex flex-column gap-2 w-full flex-1">
                  <span>F.Retiro</span>
                  <InputCalendar
                     value={FSalida}
                     placeholder={DatosEditar?.fecha_salida == null ? " " : fechaFormateadaSalida}
                     onChange={(e) => ChangeFSalida(e.target.value)}
                     showIcon
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />
                  {/* <p>F. registrada actualmente: {DatosEditar?.fecha_salida == null ? "No existe" : fechaFormateadaSalida}</p> */}

               </div>
               {/* Tiempo */}
               <div className="flex flex-column gap-2 w-full flex-1">
                  <span>Tiempo</span>
                  <InputText
                     placeholder="0"
                     value={Tiempo}
                     // onChange={(e) => ChangeTiempo(e.target.value)}
                     disabled={true}
                  />
               </div>
            </div>
            <div>
               <DialogAgregarRiesgo />
               <TablaRiesgo DatosEditar={DatosEditar} setDatosEditar={setDatosEditar} />
               <DialogAgregarAntLab showToast={showToast} />

            </div>
         </div>
      </Dialog>
   );
}
