import AuthUser from "../../../../../AuthUser";

const SB3Product = () => {
    const { http } = AuthUser();

    const postPackage = async (contrato_id, data, showToast, setEstadoEnvio) => {
        await http.post(`bregma/asignar/paquete/${contrato_id}`, data).then(async (response) => {
            if (response.data.resp) {
                showToast("success", "Completado", response.data.resp);
                setEstadoEnvio(true);
            } else if (response.data.error) {
                showToast("error", "Error", "Paquete no asignado");
            }
        }).catch(async (error) => {
            console.log(error);
        }).finally(async () => {
            console.log("postPackage", postPackage);
        });
    };

    return {
        postPackage,
    };
};

export default SB3Product;