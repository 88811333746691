import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

export default function ModalEliminarLocal({ handleDelete, setVisibleDelete, visibleDelete }) {

  // BOTONES DE CONFIMACION (ELIMINAR)
  const handleDeleteConfirmation = (id_eliminar) => (
    <React.Fragment>
      <div className="flex w-full bot">
        <Button
          type="button"
          label="Cancelar"
          className="delete w-full"
          style={{
            color: "#5555D8",
            backgroundColor: "#EBEBFF",
            borderColor: "#5555D8",
            
          }}
          onClick={() => {
            setVisibleDelete(false);
          }}
        />
        <Button
          type=""
          label="Eliminar"
          className="p-button-success w-full"
          style={{
            color: "#fff",
            backgroundColor: "#5555D8",
            borderColor: "#5555D8",
            margin:0
          }} onClick={() => {
            handleDelete(id_eliminar);
            setVisibleDelete(false);
          }}
        /></div>
    </React.Fragment>
  );


  return (
    <Dialog
      visible={visibleDelete.status}
      sizable={false}
      style={{height: "250px", width: "450px" }}
      className="p-fluid eliminarLocal"
      header={<p className="pi pi-trash text-indigo-600 text-lg font-bold" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal", fontSize:"25px" }}> Eliminar local</i></p>}
      
      footer={handleDeleteConfirmation(visibleDelete.id)}

      onHide={() => {
        setVisibleDelete(false);
      }}

    >
      <div className="flex flex-column gap-3">
        <div className="flex flex-column w-full">
          <p className=" m-0 pt-5">¿Está seguro qué desea eliminar local?</p>
        </div>
      </div>
    </Dialog>

  )
}