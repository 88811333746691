import React, { useRef, useState, } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AuthUser from '../../../AuthUser';
import { Toast } from "primereact/toast";



export default function ModalCredenciales({
  visibleCredenciales,
  setVisibleCredenciales,
  personal,
  setPersonal,
  showToast,
  tipoDocumentos,
  roles,
  idPersonal,

}) {
  const { http } = AuthUser()
  let toastRef = useRef(null);
  const [correoSeleccionado, setCorreoSeleccionado] = useState(false);
  const [visible, setVisible] = useState(false);
  const HideDialogCredenciales = () => {
    setVisibleCredenciales(false);


  };



  const handleEnviarCredenciales = () => {
    http
      .post(`clinica/personal/enviarCredenciales/${idPersonal}`)
      .then((response) => {
        console.log("Respuesta de la API:", response.data);

        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Credenciales enviadas correctamente",
        });
      })

      .catch((error) => {
        console.error("Error al consultar la API:", error);
      });
  };

  return (

    <Dialog
      visible={visibleCredenciales}
      header={
        <>
          <div className="flex align-items-center">
            <div className="flex justify-content-center align-items-center"
              style={{ backgroundColor: '#EBEBFF', width: '60px', height: '60px', borderRadius: '10px', marginRight:'7px' }}
            >
              <i className="pi pi-id-card" style={{color: '#5555d8', fontSize:'2.3rem'}}></i>
            </div>
            <h1 className="per2">Envio de Credenciales</h1>
          </div>

        </>
      }
      style={{ width: '413px', heigth: '403px', borderRadius: '10px'}}
      onHide={() => HideDialogCredenciales()}
    >
      <div className="flex flex-column gap-3">
        <p className="cred">Enviar las credenciales por:</p>
        <div className="flex flex-1 w-full flex-column gap-2">
          <Button
            label="Correo"
            className={`email ${correoSeleccionado ? 'selected' : ''}`}
            icon="pi pi-envelope"
            onClick={() => setCorreoSeleccionado(!correoSeleccionado)}
          />

        </div>

        <div className="flex flex-1 w-full flex-column">
          <Button
            label="Whatsapp"
            className="wsp"
            icon="pi pi-whatsapp"
          />
        </div>

        <div className="flex flex-1 w-full flex-column gap-2">
          <div className="flex flex-1 w-full gap-2">
            <Button
              onClick={() => HideDialogCredenciales(false)}
              label="Cancelar"
              className="w-full delete"
            />
            <Button
              onClick={() => {
                if (correoSeleccionado) {
                  handleEnviarCredenciales();
                }
                setVisible(true);
              }}
              label="Enviar"
              className="w-full enviar1"
            />

          </div>
        </div>
        <Toast ref={toastRef} />
      </div>
    </Dialog>

  );
}
