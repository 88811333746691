import React, { useState, useContext, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import AuthUser from "../../../AuthUser";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
export default function ViewCoprocultivo({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
  const { http } = AuthUser();
  const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
  const [prueba, setPrueba] = useState({})
  const [nombreArchivo, setNombreArchivo] = useState(null);
  const [file, setFile] = useState(null);
  const [indexSup, setIndexSup] = useState(0);
  const [indexSub, setIndexSub] = useState(0);
  const [parametros, setParametros] = useState([
    {
      prueba: 'Color',
      resultado: "",

    },
    {
      prueba: 'Aspecto',
      resultado: "",
    },
    {
      prueba: 'Moco',
      resultado: "",
    }, {
      prueba: 'Sangre',
      resultado: "",
    }, {
      prueba: 'Reacción inflamatoria',
      resultado: "",
    },
    {
      prueba: 'Polimorfonucleares',
      resultado: "",
    }, {
      prueba: 'Mononucleares',
      resultado: "",
    }, {
      prueba: 'Flora coliforme',
      resultado: "",
    }, {
      prueba: 'Investigación de Salmonella SP',
      resultado: "",
    }, {
      prueba: 'Investigación de Shigella SP',
      resultado: "",
    }, {
      prueba: 'Investigación de Campylobacter SP',
      resultado: "",
    }, {
      prueba: 'Investigación de Vibrio SP',
      resultado: "",
    }, {
      prueba: 'Investigación de E. Coli Enteropatogena',
      resultado: "",
    }, {
      prueba: 'Investigación bacteriana',
      resultado: "",
    }, {
      prueba: 'I. Cultivo',
      resultado: "",
    }, {
      prueba: 'II. Antibiograma - Cultivo 1',
      resultado: "",
    }, {
      prueba: 'Ampicilina',
      resultado: "",
    }, {
      prueba: 'AMP./Sulbactam',
      resultado: "",
    }, {
      prueba: 'Ceftriaxona',
      resultado: "",
    }, {
      prueba: 'Ciprofloxacina',
      resultado: "",
    },
    {
      prueba: 'Furazolidona',
      resultado: "",
    }, {
      prueba: 'Trime/Sulfa',
      resultado: "",
    },


  ]);

  const handleCreate = () => {
    const formData = new FormData();
    formData.append("examen_id", tipoExamen);
    formData.append("prueba[examen_id]", tipoPrueba);
    formData.append("prueba[estado]", 1);
    formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
    formData.append("prueba[cantidad_parametros]", parametros?.length);
    formData.append("prueba[parametros]", JSON.stringify(parametros));
    formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));

    http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
      .then(response => {
        console.log(response)
        showToast("success","Creado Correctamente",response?.data?.resp)
      })
      .catch(error => {
        console.log(error)
        showToast("Error","Error de Guardar",error?.data?.resp)
      })
  }

  const changeExamenContext = (superior, inferior, auxParametros) => {
    setExamenesContext(examenesContext.map((elementoSup, index) => {
      if (index === superior) {
        let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
          if (indexAux === inferior) {
            return {
              ...prueba,
              parametros: auxParametros,
              estado: 1,
              cantidad_parametros: auxParametros?.length,
              perfil: auxParametros?.length > 1 ? 2 : 1
            }
          }
          return prueba;
        })
        return { ...elementoSup, pruebas: auxPrueba };
      }
      return elementoSup;
    }))
  }

  const handleInputChange = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].resultado = e.value;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };

  const handleInputInicial = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].rango_inicial = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };
  const handleInputFinal = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].rango_final = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };
  const handleObservaciones = (index, e) => {
    const tempTask = [...parametros];
    if (!tempTask[index]) {
      tempTask.push({
        observaciones: "",
        seleccion: ""
      })


    }
    tempTask[16]["observaciones"] = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };
  const handleSelec = (index, e) => {
    const tempTask = [...parametros];
    if (!tempTask[index]) {
      tempTask.push({
        seleccion: ""
      })


    }
    tempTask[index].resultado = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };

  useEffect(() => {
    console.log(examenesContext)
    let sup = 0;
    let inf = 0;
    let auxPruebas = examenesContext.find((examen, index) => {
      if (examen.examen_id === tipoExamen) {
        setIndexSup(index);
        sup = index;
        console.log(index)
      }
      return examen.examen_id === tipoExamen
    });
    let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
      if (prueba.examen_id === tipoPrueba) {
        setIndexSub(index);
        inf = index;
        console.log(index)
      }
      return prueba.examen_id === tipoPrueba
    });
    if (auxPrueba?.archivo) {
      setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
    }
    setPrueba(auxPrueba);
    console.log("PRUEBA", auxPrueba)
    console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
    let auxParametros = typeof auxPrueba?.parametros === 'string' ? JSON.parse(auxPrueba?.parametros) : auxPrueba?.parametros
    auxPrueba?.parametros && setParametros(auxParametros);
    changeExamenContext(sup, inf, auxParametros);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [selectedColor, setSelectedColor] = useState(null);
  const color = [
    { name: 'Amarillo claro', code: '1' },
    { name: 'Amarillo oscuro', code: '2' },
    { name: 'Ambar', code: '3' },
    { name: 'Anaranjado', code: '4' },
    { name: 'Marrón', code: '5' },
    { name: 'Pardo', code: '6' },
    { name: 'Amarillo', code: '7' },
  ];

  const [selectedAspecto, setSelectedAspecto] = useState(null);
  const aspecto = [
    { name: 'Blanda', code: '1' },
    { name: 'Dura', code: '2' },
  ];

  const [selectedMoco, setSelectedMoco] = useState(null);
  const moco = [
    { name: 'Ausente', code: '1' },
    { name: 'Presente', code: '2' },
    { name: 'Escaso', code: '3' },
    { name: 'Disminuida', code: '4' },
  ];

  const [selectedSangre, setSelectedSangre] = useState(null);
  const sangre = [
    { name: 'Ausente', code: '1' },
    { name: 'Presente', code: '2' },
  ];

  const [selectedReacInfla, setSelectedReacInfla] = useState(null);
  const inflamatoria = [
    { name: 'Negativo', code: '1' },
    { name: 'Positivo +', code: '2' },
    { name: 'Positivo ++', code: '2' },
    { name: 'Positivo +++', code: '2' },
  ];

  const [selectedFloraC, setSelectedFloraC] = useState(null);
  const floracoliforme = [
    { name: 'Ausente', code: '1' },
    { name: 'Presente', code: '2' },
    { name: 'Escaso', code: '3' },
    { name: 'Disminuida', code: '4' },
  ];

  const [selectedInsSal, setSelectedInsSal] = useState(null);
  const salmonella = [
    { name: 'Negativo', code: '1' },
    { name: 'Positivo', code: '2' },
  ];

  const [selectedShigella, setSelectedShigella] = useState(null);
  const shigella = [
    { name: 'Negativo', code: '1' },
    { name: 'Positivo', code: '2' },
  ];

  const [selectedCampylobacter, setSelectedCampylobacter] = useState(null);
  const campylobacter = [
    { name: 'Negativo', code: '1' },
    { name: 'Positivo', code: '2' },
  ];

  const [selectedVibrio, setSelectedVibrio] = useState(null);
  const vibrio = [
    { name: 'Negativo', code: '1' },
    { name: 'Positivo', code: '2' },
  ];

  const [selectedColiEnte, setSelectedColiEnte] = useState(null);
  const colienteropatogena = [
    { name: 'Negativo', code: '1' },
    { name: 'Positivo', code: '2' },
  ];

  const [selectedBacteriana, setSelectedBacteriana] = useState(null);
  const bacteriana = [
    { name: 'Negativo', code: '1' },
    { name: 'Positivo', code: '2' },
  ];

  const [selectedAmpicilina, setSelectedAmpicilina] = useState(null);
  const ampicilinana = [
    { name: 'Intermedio', code: '1' },
    { name: 'Sentible', code: '2' },
    { name: 'Resistente', code: '3' },
    { name: '', code: '4' },
  ];

  const [selectedSulbactam, setSelectedSulbactam] = useState(null);
  const sulbactam = [
    { name: 'Intermedio', code: '1' },
    { name: 'Sentible', code: '2' },
    { name: 'Resistente', code: '3' },
    { name: '', code: '4' },
  ];

  const [selectedCeftriaxona, setSelectedCeftriaxona] = useState(null);
  const ceftriaxona = [
    { name: 'Intermedio', code: '1' },
    { name: 'Sentible', code: '2' },
    { name: 'Resistente', code: '3' },
    { name: '', code: '4' },
  ];
  const [selectedCiprofloxacina, setSelectedCiprofloxacina] = useState(null);
  const ciprofloxacina = [
    { name: 'Intermedio', code: '1' },
    { name: 'Sentible', code: '2' },
    { name: 'Resistente', code: '3' },
    { name: '', code: '4' },
  ];

  const [selectedFurazolidona, setSelectedFurazolidona] = useState(null);
  const furazolidona = [
    { name: 'Intermedio', code: '1' },
    { name: 'Sentible', code: '2' },
    { name: 'Resistente', code: '3' },
    { name: '', code: '4' },
  ];

  const [selectedTriSul, setSelectedTriSul] = useState(null);
  const trisul = [
    { name: 'Intermedio', code: '1' },
    { name: 'Sentible', code: '2' },
    { name: 'Resistente', code: '3' },
    { name: '', code: '4' },
  ];

  return (
    <div className="flex flex-column w-full h-full" style={{ padding: '20px' }}>
      <div className="flex flex-column w-full h-full">
        <div className="flex flex-column w-full">
          <table className="tabla">
            <tr>
              <th className="encabezado" style={{ width: "50%" }}>Prueba</th>
              <th className="encabezado" style={{ width: "50%" }}>Resultado</th>
            </tr>
            <tr>
              <td className="dato">Color</td>
              <td className="dato">
                <Dropdown
                  value={color[color.findIndex(ele => { return (ele.code == parametros[0]["resultado"]) })]}
                  onChange={(e) => handleSelec(0, e.value.code)}
                  options={color}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Aspecto</td>
              <td className="dato">
                <Dropdown
                  value={aspecto[aspecto.findIndex(ele => { return (ele.code == parametros[1]["resultado"]) })]}
                  onChange={(e) => handleSelec(1, e.value.code)}
                  options={aspecto}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Moco</td>
              <td className="dato">
                <Dropdown
                  value={moco[moco.findIndex(ele => { return (ele.code == parametros[2]["resultado"]) })]}
                  onChange={(e) => handleSelec(2, e.value.code)}
                  options={moco}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Sangre</td>
              <td className="dato">
                <Dropdown
                  value={sangre[sangre.findIndex(ele => { return (ele.code == parametros[3]["resultado"]) })]}
                  onChange={(e) => handleSelec(3, e.value.code)}
                  options={sangre}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Reacción inflamatoria</td>
              <td className="dato">
                <Dropdown
                  value={inflamatoria[inflamatoria.findIndex(ele => { return (ele.code == parametros[4]["resultado"]) })]}
                  onChange={(e) => handleSelec(4, e.value.code)}
                  options={inflamatoria}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Polimorfonucleares</td>
              <td className="dato" >
                <InputNumber style={{width:"280px"}} value={parametros[5]["resultado"]} onChange={(e)=>{handleInputChange(5,e)}}/>
              </td>
            </tr>
            <tr>
              <td className="dato">Mononucleares</td>
              <td className="dato">
                <InputNumber style={{width:"280px"}} value={parametros[6]["resultado"]} onChange={(e)=>{handleInputChange(6,e)}} />
              </td>
            </tr>
            <tr>
              <td className="dato">Flora coliforme</td>
              <td className="dato">
                <Dropdown
                  value={floracoliforme[floracoliforme.findIndex(ele => { return (ele.code == parametros[7]["resultado"]) })]}
                  onChange={(e) => handleSelec(7, e.value.code)}
                  options={floracoliforme}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Investigación de Salmonella SP.</td>
              <td className="dato">
                <Dropdown
                  value={salmonella[salmonella.findIndex(ele => { return (ele.code == parametros[8]["resultado"]) })]}
                  onChange={(e) => handleSelec(8, e.value.code)}
                  options={salmonella}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr><tr>
              <td className="dato">Investigación de Shigella SP.</td>
              <td className="dato">
                <Dropdown
                  value={shigella[shigella.findIndex(ele => { return (ele.code == parametros[9]["resultado"]) })]}
                  onChange={(e) => handleSelec(9, e.value.code)}
                  options={shigella}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Investigación de Campylobacter SP.</td>
              <td className="dato">
                <Dropdown
                  value={campylobacter[campylobacter.findIndex(ele => { return (ele.code == parametros[10]["resultado"]) })]}
                  onChange={(e) => handleSelec(10, e.value.code)}
                  options={campylobacter}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Investigación de Vibrio SP</td>
              <td className="dato">
                <Dropdown
                  value={vibrio[vibrio.findIndex(ele => { return (ele.code == parametros[11]["resultado"]) })]}
                  onChange={(e) => handleSelec(11, e.value.code)}
                  options={vibrio}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Investigación de E. Coli Enteropatogena</td>
              <td className="dato">
                <Dropdown
                  value={colienteropatogena[colienteropatogena.findIndex(ele => { return (ele.code == parametros[12]["resultado"]) })]}
                  onChange={(e) => handleSelec(12, e.value.code)}
                  options={colienteropatogena}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Investigación bacteriana</td>
              <td className="dato">
                <Dropdown
                  value={bacteriana[bacteriana.findIndex(ele => { return (ele.code == parametros[13]["resultado"]) })]}
                  onChange={(e) => handleSelec(13, e.value.code)}
                  options={bacteriana}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">I. Cultivo</td>
              <td className="dato">
                <InputText className="" style={{width:"280px"}} value={parametros[14]["resultado"]} onChange={(e)=>{handleInputChange(14,e.target)}}/>
              </td>
            </tr>
            <tr>
              <td className="dato">II. Antibiograma - Cultivo 1</td>
              <td className="dato">
                <InputText style={{width:"280px"}} value={parametros[15]["resultado"]} onChange={(e)=>{handleInputChange(15,e.target)}}/>
              </td>
            </tr>
            <tr>
              <td className="dato">Ampicilina</td>
              <td className="dato">
                <Dropdown
                  value={ampicilinana[ampicilinana.findIndex(ele => { return (ele.code == parametros[16]["resultado"]) })]}
                  onChange={(e) => handleSelec(16, e.value.code)}
                  options={ampicilinana}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr><tr>
              <td className="dato">AMP./Sulbactam</td>
              <td className="dato">
                <Dropdown
                  value={sulbactam[sulbactam.findIndex(ele => { return (ele.code == parametros[17]["resultado"]) })]}
                  onChange={(e) => handleSelec(17, e.value.code)}
                  options={sulbactam}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Ceftriaxona</td>
              <td className="dato">
                <Dropdown
                  value={ceftriaxona[ceftriaxona.findIndex(ele => { return (ele.code == parametros[18]["resultado"]) })]}
                  onChange={(e) => handleSelec(18, e.value.code)}
                  options={ceftriaxona}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Ciprofloxacina</td>
              <td className="dato">
                <Dropdown
                  value={ciprofloxacina[ciprofloxacina.findIndex(ele => { return (ele.code == parametros[19]["resultado"]) })]}
                  onChange={(e) => handleSelec(19, e.value.code)}
                  options={ciprofloxacina}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>3
            </tr>
            <tr>
              <td className="dato">Furazolidona</td>
              <td className="dato">
                <Dropdown
                  value={furazolidona[furazolidona.findIndex(ele => { return (ele.code == parametros[20]["resultado"]) })]}
                  onChange={(e) => handleSelec(20, e.value.code)}
                  options={furazolidona}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
            <tr>
              <td className="dato">Trime/Sulfa</td>
              <td className="dato">
                <Dropdown
                  value={trisul[trisul.findIndex(ele => { return (ele.code == parametros[21]["resultado"]) })]}
                  onChange={(e) => handleSelec(21, e.value.code)}
                  options={trisul}
                  optionLabel="name"
                  placeholder="Seleccione" />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="flex flex-row gap-3 mt-2" style={{ justifyContent: 'end' }}>
        <Button label="Grabar" icon="fas fa-save" onClick={handleCreate}/>
        <Button label="Recuperar" icon="fas fa-undo" />
      </div>
    </div>
  );
}
