import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "primereact/button";
import iconobtnsearch from "../../../Images/iconoarchivoconlupa.svg";
import exercise1 from "../../../image1/Capa_1.svg";
import exercise2 from "../../../image1/Capa_2.svg";
import exercise3 from "../../../image1/Capa_3.svg";
import exercise4 from "../../../image1/Capa_4.svg";
import exercise5 from "../../../image1/Capa_5.svg";
import exercise6 from "../../../image1/Capa_6.svg";
import exercise7 from "../../../image1/Capa_7.svg";
import exercise8 from "../../../image1/Capa_8.svg";
import exercise9 from "../../../image1/Capa_9.svg";
import exercise9b from "../../../image1/Capa_9.2.svg";
import exercise9c from "../../../image1/Capa_9.3.svg";
import exercise9d from "../../../image1/Capa_9.4.svg";
import exercise10 from "../../../image1/Capa_10.svg";
import exercise11 from "../../../image1/Capa_11.svg";
import exercise12 from "../../../image1/Capa_12.svg";
import exercise13 from "../../../image1/Capa_13.svg";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Divider } from "primereact/divider";
import "../../../Style/style9.css";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import MusculoEsqueleticoSelect from './MusculoFlexibilidadSelect';
import EnvioArchivoMedGeneral from '../../../Components/MedicinaGeneral/EnvioArchivoMedGeneral'
import './MusculoEsqueletico.css';
import { MGContext } from "../MGProvider";
import { RAContext } from "../../RutaAtencion/RAContext";

export default function MusculoRotacion({ }) {
  let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
  const { musculoEsqueleticoContext, setMusculoEsqueleticoContext } = useContext(MGContext)

  const [Archivo, setArchivo] = useState(musculoEsqueleticoContext?.med_flexibilidad?.archivo);
  const updateArchivo = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        archivo: nuevoValor
      }
    }));
  }

  const { Recepcion, setRecepcion } = useContext(MGContext);
  console.log(Recepcion);

  //Opciones
  const [FuerzaAbdomen, setFuerzaAbdomen] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_fuerza_abdomen?.opcion || 'Excelente');
  const updateFuerzaAbdomen = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_fuerza_abdomen: {
          ...prevMuscEsqueletico.med_flexibilidad?.med_fuerza_abdomen,
          opcion: nuevoValor,
        },
      },
    }));
  };

  const [Cadera, setCadera] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_cadera?.opcion || 'Excelente');
  const updateCadera = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_cadera: {
          ...prevMuscEsqueletico.med_flexibilidad?.med_cadera,
          opcion: nuevoValor,
        },
      },
    }));
  };

  const [FuerzaAbdomen2, setFuerzaAbdomen2] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_muslo?.opcion || 'Excelente');
  const updateFuerzaAbdomen2 = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_muslo: {
          ...prevMuscEsqueletico.flexibilidad?.med_muslo,
          opcion: nuevoValor,
        },
      },
    }));
  };

  const [Cadera2, setCadera2] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_abdomen_lateral?.opcion || 'Excelente');
  const updateCadera2 = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_abdomen_lateral: {
          ...prevMuscEsqueletico.med_flexibilidad?.med_abdomen_lateral,
          opcion: nuevoValor,
        },
      },
    }));
  };

  //Observaciones
  const [value, setValue] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_fuerza_abdomen?.observacion);
  const updateFuerzaAbdomenObser = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_fuerza_abdomen: {
          ...prevMuscEsqueletico.med_flexibilidad?.med_fuerza_abdomen,
          observacion: nuevoValor,
        },
      },
    }));
  };

  const [value1, setValue1] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_cadera?.observacion);
  const updateCaderaObser = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_cadera: {
          ...prevMuscEsqueletico.med_flexibilidad?.med_cadera,
          observacion: nuevoValor,
        },
      },
    }));
  };

  const [value2, setValue2] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_muslo?.observacion);
  const updateFuerzaAbdomen2Obser = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_muslo: {
          ...prevMuscEsqueletico.med_flexibilidad?.med_muslo,
          observacion: nuevoValor,
        },
      },
    }));
  };

  const [value3, setValue3] = useState(musculoEsqueleticoContext?.med_flexibilidad?.med_abdomen_lateral?.observacion);
  const updateCadera2Obser = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        med_abdomen_lateral: {
          ...prevMuscEsqueletico.med_flexibilidad?.med_abdomen_lateral,
          observacion: nuevoValor,
        },
      },
    }));
  };

  const [nombre, setNombre] = useState(musculoEsqueleticoContext?.med_flexibilidad?.nombre)
  const [observacion, setObservacion] = useState(musculoEsqueleticoContext?.med_flexibilidad?.observacion);
  const updateObservacion = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        observacion: nuevoValor
      },
    }));
  };

  const updateNombre = (nuevoValor) => {
    setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
      ...prevMuscEsqueletico,
      med_flexibilidad: {
        ...prevMuscEsqueletico.med_flexibilidad,
        nombre: nuevoValor
      },
    }));
  };

  useEffect(() => {
    updateFuerzaAbdomen(FuerzaAbdomen)
    updateCadera(Cadera)
    updateFuerzaAbdomen2(FuerzaAbdomen2)
    updateCadera2(Cadera2)
  }, [])

  useEffect(() => {
    updateFuerzaAbdomenObser(value);
  }, [value]);

  useEffect(() => {
    updateCaderaObser(value1);
  }, [value1]);

  useEffect(() => {
    updateFuerzaAbdomen2Obser(value2);
  }, [value2]);

  useEffect(() => {
    updateCadera2Obser(value3);
  }, [value3]);

  useEffect(() => {
    updateObservacion(observacion)
  }, [observacion])

  useEffect(() => {
    updateNombre(nombre)
  }, [nombre])

  return (
    <div className="flex flex-column flex-row gap-2 MusculoFleibilidad_content_principal">
      <div className="flex flex-column justify-content-center aling-items-center">
        <div style={{ marginBottom: '8px' }}>
          <p>Fuerza abdomen</p>
        </div>
        <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
          <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '58.5%' }}>
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen} ComandoUpdate={updateFuerzaAbdomen} ComandoValue={FuerzaAbdomen} imagen={exercise1} ValueName='Excelente' NameSelect="Excelente 1" id_C="FuerzaAbdomen_1" class_C="FuerzaAbdomen" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen} ComandoUpdate={updateFuerzaAbdomen} ComandoValue={FuerzaAbdomen} imagen={exercise2} ValueName='Promedio' NameSelect="Promedio 2" id_C="FuerzaAbdomen_2" class_C="FuerzaAbdomen" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen} ComandoUpdate={updateFuerzaAbdomen} ComandoValue={FuerzaAbdomen} imagen={exercise3} ValueName='Regular' NameSelect="Regular 3" id_C="FuerzaAbdomen_3" class_C="FuerzaAbdomen" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen} ComandoUpdate={updateFuerzaAbdomen} ComandoValue={FuerzaAbdomen} imagen={exercise4} ValueName='Malo' NameSelect="Malo 4" id_C="FuerzaAbdomen_" class_C="FuerzaAbdomen" />
          </div>
          <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '40%' }}>
            <span>Observación</span>
            <VoiceButton
              value={value}
              onChange={setValue}
              placeholder="Comentario"
              disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-column justify-content-center aling-items-center">
        <div style={{ marginBottom: '8px' }}>
          <p>Cadera</p>
        </div>
        <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
          <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '58.5%' }}>
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera} ComandoUpdate={updateCadera} ComandoValue={Cadera} imagen={exercise5} ValueName='Excelente' NameSelect="Excelente 1" id_C="Cadera_1" class_C="Cadera" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera} ComandoUpdate={updateCadera} ComandoValue={Cadera} imagen={exercise6} ValueName='Promedio' NameSelect="Promedio 2" id_C="Cadera_2" class_C="Cadera" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera} ComandoUpdate={updateCadera} ComandoValue={Cadera} imagen={exercise7} ValueName='Regular' NameSelect="Regular 3" id_C="Cadera_3" class_C="Cadera" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera} ComandoUpdate={updateCadera} ComandoValue={Cadera} imagen={exercise8} ValueName='Malo' NameSelect="Malo 4" id_C="Cadera_4" class_C="Cadera" />
          </div>
          <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '40%' }}>
            <span>Observación</span>
            <VoiceButton
              value={value1}
              onChange={setValue1}
              placeholder="Comentario"
              disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-column justify-content-center aling-items-center">
        <div style={{ marginBottom: '8px' }}>
          <p>Fuerza abdomen</p>
        </div>
        <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
          <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '58.5%' }}>
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen2} ComandoUpdate={updateFuerzaAbdomen2} ComandoValue={FuerzaAbdomen2} imagen={exercise9} ValueName='Excelente' NameSelect="Excelente 1" id_C="FuerzaAbdomen02_01" class_C="FuerzaAbdomen02" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen2} ComandoUpdate={updateFuerzaAbdomen2} ComandoValue={FuerzaAbdomen2} imagen={exercise9b} ValueName='Promedio' NameSelect="Promedio 2" id_C="FuerzaAbdomen02_02" class_C="FuerzaAbdomen02" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen2} ComandoUpdate={updateFuerzaAbdomen2} ComandoValue={FuerzaAbdomen2} imagen={exercise9c} ValueName='Regular' NameSelect="Regular 3" id_C="FuerzaAbdomen02_03" class_C="FuerzaAbdomen02" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setFuerzaAbdomen2} ComandoUpdate={updateFuerzaAbdomen2} ComandoValue={FuerzaAbdomen2} imagen={exercise9d} ValueName='Malo' NameSelect="Malo 4" id_C="FuerzaAbdomen02_04" class_C="FuerzaAbdomen02" />
          </div>
          <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '40%' }}>
            <span>Observación</span>
            <VoiceButton
              value={value2}
              onChange={setValue2}
              placeholder="Comentario"
              disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-column justify-content-center aling-items-center">
        <div style={{ marginBottom: '8px' }}>
          <p>Cadera</p>
        </div>
        <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
          <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '58.5%' }}>
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera2} ComandoUpdate={updateCadera2} ComandoValue={Cadera2} imagen={exercise10} ValueName='Excelente' NameSelect="Excelente 1" id_C="Cadera02_1" class_C="Cadera02" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera2} ComandoUpdate={updateCadera2} ComandoValue={Cadera2} imagen={exercise11} ValueName='Promedio' NameSelect="Promedio 2" id_C="Cadera02_2" class_C="Cadera02" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera2} ComandoUpdate={updateCadera2} ComandoValue={Cadera2} imagen={exercise12} ValueName='Regular' NameSelect="Regular 3" id_C="Cadera02_3" class_C="Cadera02" />
            <MusculoEsqueleticoSelect ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setCadera2} ComandoUpdate={updateCadera2} ComandoValue={Cadera2} imagen={exercise13} ValueName='Malo' NameSelect="Malo 4" id_C="Cadera02_4" class_C="Cadera02" />
          </div>
          <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '40%' }}>
            <span>Observación</span>
            <VoiceButton
              value={value3}
              onChange={setValue3}
              placeholder="Comentario"
              disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
        </div>
      </div>
      <Divider />
      <EnvioArchivoMedGeneral
        FuncionSetEnvio={setArchivo}
        FuncionUpData={updateArchivo}
        ValorSet={Archivo}
        DataContext={musculoEsqueleticoContext?.med_flexibilidad?.archivo} />
    </div>
  );
}
