import { useState, useEffect, useCallback } from "react";
import Paquete from "./Paquete.jsx";
import SeleccionProductos from "./SeleccionProductos.jsx";
import PeriodoSeleccion from "./PeriodoSeleccion.jsx";

export default function NCPSeleccionPlan({ paquetes, sendProducto, setSendProducto }) {

    //ACTUALIZA LOS DATOS PARA ENVIAR (ACTUALIZA sendProducto)
    function OnChangeSeleccion(s) {
        setSendProducto({ ...sendProducto, seleccion: s });
    }
    function OnChangePeriodo(p) {
        setSendProducto({ ...sendProducto, periodo: p });
    }

    //PAQUETES SELECCIONADOS
    //ESTA CONSTANTE SE LO PASAS AL COMPONENTE PAQUETES PARA QUE ACTUALICES EL TREETABLE
    const [paquetesSeleccionados, setPaquetesSeleccionados] = useState([]);

    function OnChangePaquetesSeleccionados(ps) { }

    useEffect(() => {
        console.log(sendProducto);
    });

    
    //Buscador
    const [selectedNodes, setSelectedNodes] = useState([]);

    const onchangeChecks = useCallback((paquete) => {
        setSelectedNodes((prevSelectedNodes) => {
            if (paquete.seleccionado) {
                return prevSelectedNodes.filter((node) => node !== paquete.key);
            } else {
                return [...prevSelectedNodes, paquete.key];
            }
        });
    }, []);
    // console.log({ value});
    //console.log({ paquetesSeleccionados }); // Mostrar solo los valores seleccionados en la consola
    return (
        <div className="flex gap-3 flex-wrap">
            <div className="xl:flex-initial lg:flex-1 md:flex-1 sm:flex-1 flex-1 flex flex-column gap-3 justify-content-between">

                {/*@David*/}
                <SeleccionProductos
                    sendProducto={sendProducto}
                    setSendProducto={OnChangeSeleccion}
                />

                {/*@David*/}
                <PeriodoSeleccion
                    paquetes={paquetes}
                    paquetesSeleccionados={OnChangePaquetesSeleccionados}
                    sendProducto={sendProducto}
                    setSendProducto={OnChangePeriodo}
                    onchangeChecks={onchangeChecks}
                />

            </div>

            {/*@Johan*/}
            <Paquete
                paquetes={paquetes}
                selectedNodes={selectedNodes}
                paquetesSeleccionados={paquetesSeleccionados}
                sendProducto={sendProducto}
                setSendProducto={OnChangeSeleccion}
            />

        </div>
    );
}