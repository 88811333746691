import React, { useState, useContext, useRef } from "react";
import S3ProductPackageSelection from "../../NewClient/S3Product/S3ProductPackageSelection";
import S3ProductType from "../../NewClient/S3Product/S3ProductType";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DialogServicios from "../dialogs/DialogServicios";
import { transformData } from "../../../../Services/Ventas/Clinica/ventas";
import { SalesContext } from "../../SalesContext/SalesContext";
import { Toast } from 'primereact/toast';
import "./Responvidad.css"
import iconProforma from '../../../../Images/gestionVentas/icon-proforma.svg'
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { ColumnGroup } from "primereact/columngroup";

import { Row } from 'primereact/row';

export default function S3ProductC({ packages, postPackages, showToast, visible, setVisible, getProforma }) {
   //#region VARIABLES DE INSTANCIA
   const [estadoEnvio, setEstadoEnvio] = useState(false);
   const [selectId, setSelectId] = useState(false);
   const [currentView, setCurrentView] = useState("main"); // Inicialmente, muestra la vista principal
   const [docpaquete, setDocPaquete] = useState();
   const [sendPaquetecopy, setSendPaquetecopy] = useState(null)
   const [rowid, setRowId] = useState()

   //const [perfiles, setPerfiles] = useState([]);

   const [dataErrors, setDataErrors] = useState({
      selectPaquete: false
   });

   const toast = useRef(null);

   const { sales, productType, priceType, setProductType, setPriceType, sendPaquete, setSendPaquete, checkedIndexC,} = useContext(SalesContext);
   //#endregion
   console.log("packages", packages);
   console.log("paquete", sendPaquete);
   //#region VARIABLES
   const [tipoData, setTipoData] = useState({
      tipo: null,
      nombreServicio: '',
      documento: null
   });
   /*    const [priceType, setPriceType] = useState("precio_mensual");
      const [productType, setProductType] = useState("servicio"); */
   const [rowData, setRowData] = useState()
   /*    const [perfiles, setPerfiles] = useState([{
         id: 1,
         nombre: "(click para cambiar)",
         entrada: {},
         selectedEntrada: [],
         rutina: {},
         selectedRutina: [],
         salida: {},
         selectedSalida: []
      }]) */
   const [visibleDialog, setVisibleDialog] = useState(false);



   const sumarPrecioVenta = (element) => {
      let suma = 0;
      const calcularSuma = (data) => {
         if (data) {
            data.forEach((item) => {
               const precioVenta = parseFloat(item.data.precio_mensual);
               suma += !isNaN(precioVenta) ? precioVenta : 0;

               if (item.children && item.children.length > 0) {
                  calcularSuma(item.children);
               }
            })
         };
      };
      calcularSuma(element);
      return suma.toFixed(2);
   };

   const seleccionarEntrada = (rowData, tipo) => {
      return (<button
         className="add__container_main_content_body_table_td_btn_service"
         onClick={() => {
            setVisibleDialog(true);
            let data_aux;
            let data_doc;
            setTipoData({ tipo, nombreServicio: rowData?.nombre });
            switch (tipo) {
               case 1:
                  data_aux = [rowData?.servicios?.ingreso?.[0], rowData?.servicios?.ingreso?.[(rowData?.servicios?.ingreso.length - 1)]];
                  data_doc = rowData?.documentos?.ingreso
                  break;
               case 2:
                  data_aux = [rowData?.servicios?.periodico?.[0], rowData?.servicios?.periodico?.[(rowData?.servicios?.periodico.length - 1)]];
                  data_doc = rowData?.documentos?.periodico
                  break;
               default:
                  data_aux = [rowData?.servicios?.retiro?.[0], rowData?.servicios?.retiro?.[(rowData?.servicios?.retiro.length - 1)]];
                  data_doc = rowData?.documentos?.retiro
                  break;
            }
            setRowData(data_aux);
            setDocPaquete(data_doc)
            setRowId(rowData?.id)
            setSendPaquetecopy(data_aux)
         }
         }
      >
         Ver
      </button>)
   }
   const [codigo, setCodigo] = useState("");
   console.log("VER CÓDIGO:", codigo);

   const [nombreSelect, setNombreSelect] = useState(null);
   //#region FUNCIONES
   const handleSelectionPackage = (selectedPackages) => {

      setNombreSelect(selectedPackages[0]?.paquete?.nombre)
      setSelectId(selectedPackages[0]?.paquete?.id)
      setSendPaquete(selectedPackages[0]?.perfil_tipos?.map((perfil, key) => {
         let packE = perfil?.perfil_tipo_items?.[0]?.perfil_tipo.documentos
         let packP = perfil?.perfil_tipo_items?.[1]?.perfil_tipo.documentos
         let packR = perfil?.perfil_tipo_items?.[2]?.perfil_tipo.documentos

         let priceI = sumarPrecioVenta(transformData([perfil?.perfil_tipo_items[0]]))
         let priceP = sumarPrecioVenta(transformData([perfil?.perfil_tipo_items[1]]))
         let priceR = sumarPrecioVenta(transformData([perfil?.perfil_tipo_items[2]]))

         return {
            id: key,
            nombre: perfil?.perfil?.nombre,
            servicios: {
               ingreso: transformData([perfil?.perfil_tipo_items[0]]),
               periodico: transformData([perfil?.perfil_tipo_items[1]]),
               retiro: transformData([perfil?.perfil_tipo_items[2]]),
            },
            documentos: {
               ingreso: packE,
               periodico: packP,
               retiro: packR,
            },
            precios: {
               ingreso: priceI,
               periodico: priceP,
               retiro: priceR,

            }


         }

      }))
   };

   const enviarData = () => {
      let form = {
         clinica_paquete_id: selectId,
         tipo_venta_tiempo: priceType === "precio_mensual" ? 1 : 2,
         clinica_paquete: sendPaquete,
         nombre: codigo
      }

      let status = true;

      let newErrors = {
         codigo: false,
         selectPaquete: false
      }

      if (!checkedIndexC) {
         newErrors.selectPaquete = true
         status = false
      }

      setDataErrors(newErrors)

      if (status) {
         postPackages(sales.GCEmpresa.contrato_id, form, showToast, setEstadoEnvio(), getProforma)

      }
   }

   const headerGroup = (
      <ColumnGroup>
         <Row>
            <Column header="" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header="Entrada" colSpan={2} />
            <Column header="Rutina" colSpan={2} />
            <Column header="Salida" colSpan={2} />
         </Row>
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header="Servicios" field="thisYearSale" />
            <Column header="Precio de Venta" field="lastYearProfit" />
            <Column header="Servicios" field="thisYearSale" />
            <Column header="Precio de Venta" field="lastYearProfit" />
            <Column header="Servicios" field="thisYearSale" />
            <Column header="Precio de Venta" field="lastYearProfit" />
         </Row>
      </ColumnGroup>
   );

   const headerContent = () => {
      return (
         <div className="flex align-items-center gap-5 pl-3">
            <div
               className="flex align-items-center justify-content-center"
               style={{
                  height: "60px",
                  width: "60px",
                  background: "#dadafc",
                  borderRadius: "10px"
               }}
            >
               <img src={iconProforma} alt="icono-proforma" />
            </div>

            <span>Crear proforma</span>
         </div>
      )
   }

   // Suma Precio Referencial
   const sumarPrecioReferencial = () => {
      let suma = 0;

      const calcularSuma = (data) => {
         if (data) {
            data.forEach((item) => {
               suma += parseFloat(item.data.precio_referencial_mensual || 0);
               if (item.children && item.children.length > 0) {
                  calcularSuma(item.children);
               }
            })
         };
      };

      calcularSuma(sendPaquetecopy);
      return suma.toFixed(2);
   };
const rowDataTemplate = (rowData, column) => {
   return (
      <strong className="mr-6">
         S/{sumarPrecioReferencial()}
      </strong>
   );
};

   const [showError, setShowError] = useState(false);

   return (
      <div className="gap-3 flex flex-wrap w-full content-dialog-s3">

         <Dialog header={headerContent} className="dialog-s3" visible={visible} style={{ width: '90%', height: "940px" }} onHide={() => setVisible(false)} >
            {currentView === "main" && (
               <>
                  <div className="flex gap-3 flex-row Responsivvv">
                     <div className="vp-card-1 flex flex-column gap-3">

                        <div className="flex flex-column p-3 bg-white border-round-2xl relative">

                           <div className="flex justify-content-start align-items-center gap-3">
                              <span className="ventas-button bg-indigo-100 border-round-lg">
                                 <img
                                    src="https://i.ibb.co/CJ4qNzy/briefcase.png"
                                    alt="img"
                                 />
                              </span>
                              <p className="text-indigo-600 font-bold">Código de Proforma</p>
                           </div>

                           <InputText className="mt-3" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
                           {showError && codigo === "" && <small className="text-red-500">Ingrese un código de proforma</small>}
                        </div>

                        <div className="flex flex-1 gap-3 p-3 flex-column bg-white border-round-lg ">
                           <div className="flex justify-content-start">
                              <span className="ventas-button bg-indigo-100 border-round-lg">
                                 <img src="https://i.ibb.co/CJ4qNzy/briefcase.png" alt="img" />
                              </span>
                              <p style={{ marginLeft: "20px" }} className="text-indigo-600 font-bold">Selección de producto</p>
                           </div>



                           <S3ProductType type={productType} onChangeType={setProductType} valueButton1="bien" valueButton2="servicio" valueDefault="servicio" labelButton1="Bien" labelButton2="Servicio" />
                        </div>

                        <div className="flex-1 gap-3 p-3 flex flex-column bg-white border-round-lg ">
                           <div className="flex justify-content-start">
                              <span className="ventas-button bg-indigo-100 border-round-lg">
                                 <img src="https://i.ibb.co/CJ4qNzy/briefcase.png" alt="img" />
                              </span>
                              <p style={{ marginLeft: "20px" }} className="text-indigo-600 font-bold white-space-nowrap">Periodo y selección de paquete</p>
                           </div>

                           <S3ProductType type={priceType} onChangeType={setPriceType} valueButton1="precio_anual" valueButton2="precio_mensual" valueDefault="precio_mensual" labelButton1="Anual" labelButton2="Mensual" />

                           <S3ProductPackageSelection packages={packages} onSelectionPackage={handleSelectionPackage} origin={1} />
                           {dataErrors?.selectPaquete && !checkedIndexC && (
                              toast.current.show({
                                 severity: 'error',
                                 summary: 'Error',
                                 detail: 'Seleccione un paquete',
                                 life: 3000, // Duration in milliseconds
                              })
                           )}

                           {/* <Button label={estadoEnvio !== false ? "Actualizar" : "Asignar"} className={estadoEnvio !== false ? "bg-green-500 border-green-500" : ""} onClick={() => enviarData()} /> */}
                           {//<Button label={"Limpiar"} className={"bg-green-500 border-green-500"} onClick={() => {console.log('Esto se envia:', sendPaquete, '...', paqueteS3C ); }} />
                           }
                        </div>
                     </div>

                     <div className="vp-card vp-card-2 overflow-hidden flex flex-column gap-3 card w-full h-full" style={{ padding: "10px" }}>
                        <div className="flex justify-content-start" style={{ marginBottom: "30px" }}>
                           <span className="ventas-button bg-indigo-100 border-round-lg">
                              <img src="https://i.ibb.co/Pr9nJDV/Icon.png" alt="img" />
                           </span>
                           <div className="flex flex-column" style={{ marginLeft: "20px" }} >
                              <h3 className="text-indigo-600 font-bold white-space-nowrap">{nombreSelect}</h3>
                              <p className="text-gray-500">Contenido total de pruebas habilitadas.</p>
                           </div>
                        </div>



                        <span className="border-top-1 border-gray-400"></span>

                        <div className="card p-fluid">
                           <DataTable
                              value={sendPaquete}
                              headerColumnGroup={headerGroup}
                              dataKey="id"
                              editMode="cell"
                              tableStyle={{ maxWidth: '100%' }}
                              size="small"
                              style={{overflowX:"auto"}}>
                              <Column field="nombre" header="" style={{ width: '22%' }}
                              // editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}
                              ></Column>

                              <Column
                                 body={(e) => seleccionarEntrada(e, 1)}
                                 header="Servicios" style={{ width: '13%' }}></Column>
                              <Column
                                 field="ingreso"
                                 body={rowDataTemplate}
                                 header="Precio de Venta" style={{ width: '13%' }}></Column>
                              <Column
                                 body={(e) => seleccionarEntrada(e, 2)}
                                 header="Servicios" style={{ width: '13%' }}></Column>
                              <Column
                                 field="periodico"
                                 body={rowDataTemplate}
                                 header="Precio de Venta" style={{ width: '13%' }}
                              ></Column>
                              <Column
                                 body={(e) => seleccionarEntrada(e, 3)}
                                 header="Servicios" style={{ width: '13%' }}></Column>
                              <Column
                                 field="retiro"
                                 body={rowDataTemplate}
                                 header="Precio de Venta" style={{ width: '13%' }}
                              ></Column>
                           </DataTable>
                        </div>
                        <div className="content-options justify-content-end flex gap-4 mx-3 mt-2">
                           <Button
                              label="Cancelar"
                              className={"bg-white border-gray-400 color-gray-400"}
                              style={{ width: "170px", color: "#6366F1" }}
                              onClick={() => { setVisible(false); setCodigo(""); setShowError(false) }} />

                           <Button
                              label="Crear"
                              style={{ width: "170px" }}
                              // className={estadoEnvio !== false ? "bg-green-500 border-green-500" : ""}
                              onClick={() => {
                                 // Enviar();
                                 // setEnvio([]);
                                 if (codigo === "") {
                                    setShowError(true);
                                 }
                                 else {
                                    enviarData();
                                    setVisible(false);
                                    setCodigo("");
                                    setShowError(false)
                                 }
                              }}
                           />

                        </div>

                        <DialogServicios
                           show={visibleDialog}
                           setShow={setVisibleDialog}
                           data={rowData}
                           priceType={priceType}
                           tipoData={tipoData}
                           packDoc={docpaquete}
                           sendPaquetecopy={sendPaquetecopy}
                           setSendPaquetecopy={setSendPaquetecopy}
                           setSendPaquete={setSendPaquete}
                           sendPaquete={sendPaquete}
                           ide={rowid}

                        />
                     </div>


                  </div>
               </>
            )}
            <Toast ref={toast} />
         </Dialog>

      </div>
   );
}