import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import '../../../Style/PagePerfilGeneral/USU-0/Empresa.css'
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import AuthUser from "../../../AuthUser";
import CreateEmpresa from "../CreateEmpresa/CreateEmpresa";
import { Skeleton } from 'primereact/skeleton';
// import "../../../Style/usuario/EMPRESA.css";
import icono from "../../../Images/Perfil/icon/ActualizarUsuario.svg";
import { Carousel } from 'primereact/carousel';
import { DatosEmpresa } from '../../../Services/DatosEmpresa';
import { Toast } from 'primereact/toast';
import SlideEmpresa from './Slide';
// bregma, setBregma
export default function EmpresaComponent(props) {
    const { http } = AuthUser();
    const [visible, setVisible] = useState(false);
    //const [departamentos, setDepartamentos] = useState([])
    // const [datos, setDatos] = useState([]);
    // const handleChangeDistrito = (e) => {
    //     setDistrito(e.value);

    // };
  
    /*CONVERTIR DE MAYUSCULA A MINUSCULA */
    const editText = (text, separador) => {
        if (text) {
            const palabras = text.split(" ")
            for (let i = 0; i < palabras.length; i++) {
                palabras[i] = (palabras[i][0]?.charAt(0).toUpperCase() || '') + palabras[i].substr(1).toLowerCase();
            }
            return (text ? palabras.join(" ") + (separador || '') : '');
        } else {
            return ''
        }
        /*  return (text?text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()+(separador||''):''); */
    }

    const handleChangeTipoDocumento = (e) => {
        props.setBregma({ ...props.data, tipo_documento: e.target.value, tipo_documento_id: e.value === null ? null : e.value.id })
    }
    const handleChangeCelular = (e) => {
        props.setBregma({ ...props.data, celular: e.target.value })
    }

    const handleChangeCorreo = (e) => {
        props.setBregma({ ...props.data, correo: e.target.value })
    }

    const handleChangeDireccion = (e) => {
        props.setBregma({ ...props.data, direccion: e.target.value })
    }

    const handleChangeRazonSocial = (e) => {
        props.setBregma({ ...props.data, razon_social: e.target.value })
    }

    const handleChangeNumeroDocumento = (e) => {
        props.setBregma({ ...props.data, numero_documento: e.target.value })
    }

    const handleChangeWhatsApp = (e) => {
        props.setBregma({ ...props.data, whatsapp: e.target.value });
    }
    
    const handleChangeSitioWeb = (e) => {
        props.setBregma({ ...props.data, sitio_web: e.target.value });
    }
    


    // useEffect(() => {

    //     getAllUbicaciones()
    // }, []);

    // const getAllUbicaciones = () => {
    //     http
    //         .get("/ubicacion")
    //         .then((response) => {
    //             setDepartamentos(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };
    
    const toast = useRef(null);
   
   const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Actualizado correctamente', detail:'Los datos se actualizaron correctamente', life: 3000});
}
    
    const footerContent = (
        <div  className="card flex flex-wrap justify-content-center gap-3">   
            <Button 
            className=" p-button-raised" 
            style={{ marginTop: "10px" }} 
            onClick={() => { props.handleSubmitUpdate(props.data.id, props.data); setVisible(false)}} >
            <span style={{ fontWeight: "500", fontSize: "14px" }}>Actualizar</span>
            </Button>       
        </div>
    );

    const datosTemplate = (datos) => {
        return (
          <div className="SUB_SECTIONS_ICONO_DATA_EMPRE text-center">
            <div>
              <img src={`${datos.image}`}/>
              <h4 className="TITULO_CONT_DOWN_EMPRE">{datos.name}</h4>
              <p className='SUBTITULO_CONT_DOWN_EMPRE'>{datos.description}</p>
            </div>
          </div>
        );
    };

    const responsiveOptions = [
        {
            breakpoint: '1150px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1018px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '650px',
            numVisible: 2,
            numScroll: 1
        },
        
    ]


    const EstiloBorderAHP = {
        border:'2px solid black'
    };
    // Skeleton
    const [loading, setLoading] = useState(false);
   //  useEffect(() => {
   //      setTimeout(() => {
   //          setLoading(false);
   //      }, 3000);
   //  }, [])
    const loader = () => {
        return (
            <div className=" flex flex-wrap h-auto w-full">
                <div className="wrapperizquierda flex w-full h-full pt-4 flex-column flex-wrap"
                    style={{ marginLeft: '80px' }}
                ><Toast ref={toast} />
                    <div className="right flex-1 info flex-row w-auto ">
                        {/* Datos y botones */}

                        <div style={{ marginTop: '20px' }} className='flex flex-column'>
                            <Skeleton width="423px" height="47px" borderRadius="16px"></Skeleton>
                            <h6 className='flex flex-row w-full subtitulos' style={{ marginTop: '10px' }}><Skeleton width="202px" height="39.9px" borderRadius="16px"></Skeleton></h6>
                            <div className='flex flex-row justify-content-end' style={{ marginTop: "-130px"}}>
                                {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                                <Skeleton width="85.75px" height="30.56px" borderRadius="16px"></Skeleton>
                            </div>
                        </div>

                        {/* Datos del usuario parte inferior */}
                        <div style={{ marginLeft: '60px' }} className="flex flex-wrap justify-content-between pt-5">
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (loading) {
        return (loader())
    }
    
    else {
        return (
            <div className="CONTENEODRENTERO_EMPRE">
                {/* Img de usuario */}
                <div className="CONTENEDOR_GENERAL_EMPRE">
                    <div className='CONTENEDOR_BOTON_EDITAR_EMPRE'  >
                        {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                        <Button onClick={() => setVisible(true)} className="BOTONAGREGARDATOSUSUARIO p-button-outlined flex" style={{margin:'0'}}>
                            <i className='ICONODEAGREGAAR pi pi-pencil' style={{ fontSize: "10px", marginRight: "5px" }}></i>
                            <p className='TEXTOAGREGARUSUARIO'>Editar</p>

                        </Button>
                    </div>
                    <div className="CONTENEDOR_DATA_GENERAL_EMPRE">
                        {/* Datos y botones */}

                        <div className='CONTENEDOR_UP_AHP_EMPRE'>
                            <div className="CONTENEDOR_DATA_EMPRE_TITU">
                                <label className='TITULO_CONT_UP' htmlFor="">Datos de la Empresa </label>
                                <label className='TITULO_CONT_UP_020' htmlFor="">
                                    {editText(props.dataShow?.distrito?.provincia?.departamento?.departamento, '-') +
                                        editText(props.dataShow?.distrito?.provincia?.provincia, '-') +
                                        editText(props.dataShow?.distrito?.distrito) || 'No hay departamento'}
                                </label>
                            </div>
                            <div className="CONTENEDOR_DATA_EMPRE_TITU">
                                {/* AV. San Borja Norte NRO. 772 URB. San Borja */}
                                <h6 className="SUBTITULO_CONT_DOWN_EMPRE">{props.dataShow?.direccion || 'Sin dirección'}</h6>
                            
                                <h6 className="SUBTITULO_CONT_DOWN_EMPRE">
                                    {props.persona?.correo ?? "No existente"}
                                </h6>
                            </div>
                        </div>

                        {/* Datos del usuario parte inferior */}
                        <div className="CONTENEDOR_DOWN_AHP_EMPRE">
                                <SlideEmpresa 
                                dataEx={props.dataShow}/>
                        </div>

                    </div>
                    {/* Cuestionario emergente */}
                    

                </div>
                    <Dialog
                        header={<>
                            <div className="flex flex-column gap-2">
                                <div className="flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#EBEBFF', borderRadius: '10px', width: '60px', height: '60px' }}>
                                    <img src={icono} alt="" style={{ width: '25px', height: '31.25px' }} />
                                </div>
                                <div>
                                    <h3 style={{ fontWeight: '700', fontSize: '16px', color: '#5555D8' }}>Datos de la empresa</h3>
                                    <p style={{ fontWeight: '500', fontSize: '14px', color: '#7B8794' }}>Complete la siguiente información sobre la empresa</p>
                                </div>
                            </div>
                        </>}
                        footer={footerContent}
                        visible={visible} className="form0-container" style={{ width: '45vw', minWidth: "200px", maxWidth: "446px" }} onHide={() => setVisible(false)}>
                        <div className="card">
                            <div className="flex flex-column gap-3 mb-4" style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>
                                {/* <div className="flex-auto">
                                    <label htmlFor="integer" className="font-bold block mb-2">
                                        Tipo de documento
                                    </label>
                                    <Dropdown className="w-full" optionLabel="descripcion" options={tiposDocumentos} onChange={(e) => { handleChangeTipoDocumento(e) }} value={data?.tipo_documento} />
                                </div> */}

                                <div className="flex-auto">
                                    <label htmlFor="integer" className="font-bold block mb-2" style={{ marginTop: "10px" }}>
                                        Razón Social
                                    </label>
                                    <InputText id="name" className="w-full text-area2"
                                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                                        defaultValue={props.data?.razon_social}
                                        onChange={(e) => { handleChangeRazonSocial(e) }} />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="integer" className="font-bold block mb-2">
                                        Celular
                                    </label>
                                    <InputText id="celularE" keyfilter="num" className="w-full text-area2"
                                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                                        defaultValue={props.data?.celular}
                                        onChange={(e) => { handleChangeCelular(e) }} />
                                </div>
                                {/* <div className="flex-auto">
                                <label htmlFor="idE" className="font-bold block mb-2 w-full">
                                    Numero de Documento
                                </label>
                                <InputText id="idE" keyfilter="text" className="w-full" defaultValue={bregma?.numero_documento} onChange={(e) => { handleChangeNumeroDocumento(e) }} />
                            </div> */}
                                <div className="flex-auto">
                                    <label htmlFor="email" className="font-bold block mb-2">
                                        Correo
                                    </label>
                                    <InputText id="email" keyfilter="email" className="w-full text-area2"
                                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                                        defaultValue={props.data?.correo}
                                        onChange={(e) => { handleChangeCorreo(e) }} />
                                </div>

                                <div className="flex-auto">
                            <label htmlFor="whatsapp" className="font-bold block mb-2">
                                WhatsApp
                            </label>
                            <InputText id="whatsapp" keyfilter="num" className="w-full text-area2"
                                style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={props.data?.whatsapp}
                                onChange={(e) => { handleChangeWhatsApp(e) }} />
                        </div>

                        <div className="flex-auto">
                            <label htmlFor="web" className="font-bold block mb-2">
                                Sitio Web
                            </label>
                            <InputText id="web" className="w-full text-area2"
                                style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={props.data?.sitio_web}
                                onChange={(e) => { handleChangeSitioWeb(e) }} />
                        </div>


                                {/* <CreateEmpresa
                                    setBregma={props.setBregma}
                                    data={props.data}
                                    departamentos={departamentos}
                                /> */}

                                <div className="flex-auto">
                                    <label htmlFor="integer" className="font-bold block mb-2">
                                        Dirección
                                    </label>
                                    <InputText id="direccion" className="w-full text-area2"
                                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                                        defaultValue={props.data?.direccion}
                                        onChange={(e) => { handleChangeDireccion(e) }} />
                                </div>
                            </div>
                        </div>
                    </Dialog>
            </div>
        )
    }
}