import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import AuthUser from "../../../AuthUser";
import axios from "axios";
import InputCalendar from "../../form/InputCalendar";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function ModalEditar({
  visibleEdit,
  setVisibleEdit,
  personal,
  setPersonal,
  showToast,
  tipoDocumentos,
  roles,
  getPersonal,
  api,
  data,
  contractOptions
}) {
  console.log(data, "mod")
  console.log(roles, "rol")
  const { http } = AuthUser();

  console.log("PERSONAL", personal)

  let options = [
    { label: 'Es usuario', value: true },
    { label: 'No es usuario', value: false }
  ]

  const [value, setValue] = useState(true);

  console.log("VALOOOOOOOOOOOOOOOOR", value)
  const [HabRol, setHabRol] = useState(false);
  const [selectedContract, setSelectedContract] = useState(personal?.tipo_contrato)
  console.log("ID CONTRATO", personal?.tipo_contrato)
  const initialValues = {
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    tipo_contrato: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "2022-01-01",
    distrito_id: 2,
    celular: "",
    correo: "",
    direccion: "",
    rol: "",
    usuario: "",
    empresa_cargo: ""
  };

  //03
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (datos) => {
      console.log("entro a update correctamente");
      const formDataSend = new FormData();
      for (var key in datos) {
        formDataSend.append(key, datos[key]);
      }
      formDataSend.append("tipo_documento_id", datos?.tipo_documento?.id);
      datos.tipo_documento_id = datos?.tipo_documento?.id;

      formDataSend.append("tipo_contrato_id", datos?.tipo_contrato?.id);
      datos.tipo_contrato_id = datos?.tipo_contrato?.id;

      formDataSend.append("rol_id", datos?.rol?.id);
      datos.rol_id = datos?.rol?.id;

      formDataSend.append("empresa_cargo_id", datos?.empresa_cargo?.id);
      datos.empresa_cargo_id = datos?.empresa_cargo?.id;

      datos.usuario = formik.values.usuario;

      dispatch(startLoading());
      try {
        const response = await http.put(`${api}/${datos.id}`, datos)
        if (response?.data?.resp) {
          HideDialogEdit();
          await Promise.all([
            getPersonal()
          ])
          showToast("success", "Actualizado correctamente", "Se creo correctamente el personal");
        } else if (response?.data?.error) {
          showToast("error", "Error", response?.data?.error);
        }
      } catch (error) {
        showToast("error", "Error", "Contáctese con soporte técnico");
        console.log(error)
      } finally {
        dispatch(endLoading());
      }
    },
  });
  console.log("formik", formik.values)
  const HideDialogEdit = () => {
    setVisibleEdit(false);
  };

  const paqueteCreateDialogFooter = (
    <div className="flex w-full bot">
      <Button
        type="button"
        label="Cancelar"
        className="delete w-full"
        onClick={() => {
          HideDialogEdit();
        }}
      />
      <Button
        type="submit"
        label="Actualizar"
        className="p-button-success w-full"
        style={{
          backgroundColor: "#5555D8",
          borderColor: "#5555D8",
        }}
      />
    </div>
  );


  const updatePersonal = () => {
    formik.setValues({
      id: personal?.id,
      tipo_documento: personal?.persona?.tipo_documento,
      numero_documento: personal?.persona?.numero_documento,
      tipo_contrato: personal?.tipo_contrato,
      nombres: personal?.persona?.nombres,
      apellido_paterno: personal?.persona?.apellido_paterno,
      apellido_materno: personal?.persona?.apellido_materno,
      fecha_nacimiento: "",
      distrito_id: 2,
      celular: personal?.persona?.celular,
      correo: personal?.persona?.correo,
      direccion: personal?.persona?.direccion,
      rol: personal?.rol,
      usuario: personal?.user_rol,
      empresa_cargo: personal?.empresa_cargo,
    });

  };

  console.log("roles", personal);
  useEffect(() => {
    updatePersonal();

    if (personal?.rol) {
      setValue(options[0].value);
      formik.setFieldValue('usuario', "1")
      setHabRol(true);
    } else {
      setValue(options[1].value);
      formik.setFieldValue('usuario', "0")
      setHabRol(false);
    };
  }, [personal, roles, data]);

  const getNombreCompleto = (numdocumento, tipoContrato, usuario) => {
    if (formik.values.tipo_documento !== "") {
      dispatch(startLoading());
      console.log("tipo de documento", formik.values.tipo_documento.nombre)
      if (formik.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            console.log("DATOS RENIEC", res.data.nombres, res.data.apellidoPaterno, res.data.apellidoMaterno);
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              numero_documento: numdocumento,
              tipo_contrato: tipoContrato,
              nombres: res.data.nombres,
              apellido_paterno: res.data.apellidoPaterno,
              apellido_materno: res.data.apellidoMaterno,
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              rol: formik.values.rol,
              usuario: formik.values.usuario,
              empresa_cargo: formik.values.empresa_cargo
            })
            getPersonal();
          })
          .catch((error) => {
            console.log("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
          .finally(() => {
            dispatch(endLoading());
          })
      } else if (formik.values.tipo_documento.nombre === "RUC") {
        axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            console.log("DATOS RENIEC", res.data.razonSocial);
            getPersonal();
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              tipo_contrato: tipoContrato,
              numero_documento: numdocumento,
              nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
              apellido_paterno: res.data.razonSocial.split(" ")[0],
              apellido_materno: res.data.razonSocial.split(" ")[1],
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              rol: formik.values.rol,
              usuario: formik.values.usuario,
              empresa_cargo: formik.values.empresa_cargo
            })
          })
          .catch((error) => {
            console.log("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
      }
    }
    else {
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }
  console.log("index", roles?.filter(e => e?.id === formik?.values?.rol?.id)[0])

  // const handleChangeContract = (e) => {
  //   setSelectedContract(e.value)
  //   console.log("PERSONAL", personal)
  // }

  return (
    <div>
      <form
        id="personal-form-edit"
        className="personal-form-edit"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog
          visible={visibleEdit}
          style={{ width: "450px", margin: "5%" }}
          appendTo={document.getElementById("personal-form-edit")}
          header={
            <>
              <div className="flex align-items-center">
                <div className="flex justify-content-center align-items-center"
                  style={{ backgroundColor: "#EBEBFF", width: "60px", height: "60px", borderRadius: "10px", marginRight: '7px' }}
                >
                  <i className="pi pi-user" style={{ color: '#5555d8', fontSize: '30px' }}></i>
                </div>
                <div className="flex">
                  <h1 className="per">Editar Personal</h1>
                </div>
              </div>
            </>
          }
          dismissableMask
          className="p-fluid "
          footer={paqueteCreateDialogFooter}
          onHide={() => { HideDialogEdit(); }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p className="m-0">En esta seccion usted puede editar los datos para personal</p>
              <p className="per1" style={{ marginTop: '8px' }}>Tipo de documento</p>
              <div className="flex w-full flex-column">
                <Dropdown
                  id="tipo_documento"
                  name="tipo_documento"
                  value={formik.values.tipo_documento}
                  onChange={formik.handleChange}
                  options={tipoDocumentos}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de documento"
                  className="w-full"
                />
              </div>
            </div>

            <div className="flex flex-1 w-full flex-column gap-2">
              <div className="flex flex-1 w-full gap-2">
                <InputText
                  id="numero_documento"
                  name="numero_documento"
                  value={formik.values.numero_documento}
                  onChange={formik.handleChange}
                  required
                />
                <Button
                  type="button"
                  label="Validar"
                  style={{ background: "#07A34F", borderColor: "#07A34F" }}
                  onClick={() => getNombreCompleto(
                    formik.values.numero_documento,
                    formik.values.usuario,
                    formik.values.tipo_contrato
                  )}
                />
              </div>
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="name">Nombres Completos</label>
              <InputText
                id="nombres"
                name="nombres"
                value={formik.values.nombres}
                className="w-full"
                onChange={formik.handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">Apellidos Paterno</label>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={formik.values.apellido_paterno}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">Apellidos Materno</label>
              <InputText
                id="apellido_materno"
                name="apellido_materno"
                value={formik.values.apellido_materno}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="">Correo</label>
              <InputText
                id="correo"
                name="correo"
                value={formik.values.correo}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="">Celular</label>
              <InputText
                id="celular"
                name="celular"
                value={formik.values.celular}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column">
              <p>Puesto de trabajo</p>
              <Dropdown
                id="empresa_cargo"
                name="empresa_cargo"
                value={formik.values.empresa_cargo}
                onChange={formik.handleChange}
                options={data}
                optionLabel="nombre"
                placeholder="Selecciona un Puesto de trabajo"
                className="w-full"
              />
            </div>

            {/* <p className="per1">Tipo de contrato</p>
            <div className="flex w-full flex-column">
              <Dropdown
                id="tipo_contrato"
                name="tipo_contrato"
                value={formik.values.tipo_contrato}
                onChange={formik.handleChange}
                options={contractOptions}
                optionLabel="nombre"
                placeholder="Selecciona un tipo de contrato"
                className="w-full"
              />
            </div> */}

            <div className="flex w-full flex-column">
              <p className="per1">Último examen médico</p>
              <Calendar
                id="examen_medico"
                name="examen_medico"
                value={formik.values.examen_medico}
                onChange={formik.handleChange}
                optionLabel="nombre"
                placeholder="Seleccionar fecha"
                className="w-full"
              />
            </div>

            <div className="flex justify-content-center align-content-center">
              <SelectButton
                className="flex-1"
                value={value}
                onChange={(e) => setValue(e.value)}
                options={options}
              ></SelectButton>
            </div>

            <p>Rol</p>
            <Dropdown
              id="rol"
              name="rol"
              value={roles?.filter(e => e?.id === formik?.values?.rol?.id)[0]}
              onChange={formik.handleChange}
              options={roles}
              optionLabel="nombre"
              placeholder="Selecciona un Rol"
              className="w-full"
              disabled={!value}
            />
            <div className="flex flex-1 w-full flex-column gap-2">
              <div className="flex flex-1 w-full gap-2">
                <div>
                  <p>Sexo</p>
                  <Dropdown
                    id="sexo"
                    name="sexo"
                    placeholder="Selecciona tu sexo"
                    className="w-full"
                  />
                </div>

                <div>
                  <p>Fecha de nacimiento</p>
                  <InputCalendar
                    id="fecha_nacimiento"
                    name="fecha_nacimiento"
                    placeholder=""
                  />
                </div>
              </div>
            </div>

          </div>
        </Dialog>
      </form>
    </div>
  );
}