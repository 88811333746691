const changeDataToTreeTable = (arreglo, item) => {
   

   let data = arreglo.map((servicio) => {
      
      let aux = [];
      if (servicio.children && servicio.children.length > 0) {
         aux = changeDataToTreeTable(servicio.children, item);
      }

      const precioMensual = parseFloat(servicio.precio_referencial_mensual_venta) || 0;
      const precioAnual = parseFloat(servicio.precio_referencial_anual_venta) || 0;

      const childResult = {
         precio_mensual: precioMensual,
         precio_anual: precioAnual
      };
      if (aux.length > 0) {
         childResult.precio_mensual += aux.reduce((total, child) => total + child.precio_mensual, 0);
         childResult.precio_anual += aux.reduce((total, child) => total + child.precio_anual, 0);
      }

      //console.log("Servicio:", servicio.nombre);
      //console.log("Aux:", aux);

      return {
         key: item + "-" + servicio.path,
         data: {
            nombre: servicio.nombre,
            precio_mensual: childResult.precio_mensual !== 0 ? childResult.precio_mensual.toString() : "0",
            precio_anual: childResult.precio_anual !== 0 ? childResult.precio_anual.toString() : "0",
            precio_referencial_mensual: servicio.precio_referencial_mensual?.toString(),
            precio_referencial_anual: servicio.precio_referencial_anual?.toString(),
         },
         children: aux,
      };
   });

   //console.log("Data:", data);
   return data;
};



export const transformData = (nodes) => {
   var areaMedicaChildren = [];
   var capacitacionChildren = [];
   var examenChildren = [];
   //console.log("nodes", nodes)
   nodes.forEach((item) => {


      if (item.areas_medicas && item.areas_medicas.length > 0) {
         let data = changeDataToTreeTable(item?.areas_medicas, 1);
         areaMedicaChildren = data;
      }

      if (item.capacitaciones && item.capacitaciones.length > 0) {
         let data = changeDataToTreeTable(item?.capacitaciones, 2);
         capacitacionChildren = data;
      }

      if (item.examenes && item.examenes.length > 0) {
         let data = changeDataToTreeTable(item?.examenes, 3);
         examenChildren = data;
      }
   });

   const areaMedicaNode = {
      key: "areas_medicas",
      data: {
         nombre: (
            <span className="d-flex align-items-center">
               <i className="p-2 pi pi-book bg-green-100 border-radius text-green-500 border-round-lg" />{" "}
               <strong>Áreas Médicas</strong>
            </span>
         ),
         precio_mensual: "Precio Mensual",
         precio_anual: "Precio Anual",
      },
      children: areaMedicaChildren,
   };

   const capacitacionNode = {
      key: "capacitaciones",
      data: {
         nombre: (
            <span className="d-flex align-items-center">
               <i className="p-2 pi pi-book bg-orange-100 border-radius text-orange-500 border-round-lg" />{" "}
               <strong>Capacitaciones</strong>
            </span>
         ),
         precio_mensual: "",
         precio_anual: "",
      },
      children: capacitacionChildren,
   };

   const examenNode = {
      key: "examenes",
      data: {
         nombre: (
            <span className="">
               <i className="p-2 pi pi-book bg-blue-100 border-radius text-blue-500 border-round-lg" />{" "}
               <strong>Examen de ayuda al diagnóstico</strong>
            </span>
         ),
         precio_mensual: "",
         precio_anual: "",
      },
      children: examenChildren,
   };
   return [areaMedicaNode, capacitacionNode, examenNode];
};
export const findElement = (lista, elemento) => {
   let aux = {
      precio_mensual: 0,
      precio_anual: 0
   };

   lista.forEach((area) => {
      if (area.key === elemento) {
         aux.precio_mensual = parseFloat(area.data.precio_mensual_venta) || 0;
         aux.precio_anual = parseFloat(area.data.precio_anual_venta) || 0;
      } else if (area.children && area.children.length > 0) {
         const childResult = findElement(area.children, elemento);
         aux.precio_mensual += childResult.precio_mensual_venta;
         aux.precio_anual += childResult.precio_anual;
      }
   });

   return aux;
};
