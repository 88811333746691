import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import AuthUser from "../../../AuthUser";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { FileUpload } from "primereact/fileupload";
import icono from "../../../Images/Perfil/icon/ActualizarUsuario.svg";
import "../../../Style/usuario/PERSONA.css";

export default function UpdateEmpresaIMGComponent({ dataShow, visible, setVisible, getPersona, showToast, getEmpresa }) {
   const { http } = AuthUser();
   const [foto, setFoto] = useState({ foto: null })
   const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
   const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
   const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

   const HideDialogEdit = () => {
      setVisible(false);
   };

   // Subir imagen
   const [totalSize, setTotalSize] = useState(0);
   const fileUploadRef = useRef(null);

   const headerTemplate = (options) => {
      const { className, chooseButton, uploadButton, cancelButton } = options;
      const value = totalSize / 20000;
      const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

      return (
         <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
            {chooseButton}
            {uploadButton}
            {cancelButton}
            <div className="flex align-items-center gap-3 ml-auto">
               <span>{formatedValue} / 2 MB</span>
               <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
            </div>
         </div>
      );
   };

   const onTemplateSelect = (e) => {
      let _totalSize = totalSize;
      let files = e.files;

      Object.keys(files).forEach((key) => {
         _totalSize += files[key].size || 0;
      });

      setTotalSize(_totalSize);
   };

   const onTemplateUpload = (e) => {
      let _totalSize = 0;

      e.files.forEach((file) => {
         _totalSize += file.size || 0;
      });

      setTotalSize(_totalSize);
   };

   const onTemplateRemove = (file, callback) => {
      setTotalSize(totalSize - file.size);
      callback();
   };

   const onTemplateClear = () => {
      setTotalSize(0);
   };

   const itemTemplate = (file, props) => {
      return (
         <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center" style={{ width: "100%", marginBottom: "15px" }}>
               <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
               <span className="flex flex-column text-left ml-3">
                  {file.name}
                  <small>{new Date().toLocaleDateString()}</small>
               </span>
            </div>
            <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
            <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
         </div>
      );
   };

   const emptyTemplate = () => {
      return (
         <div className="flex align-items-center flex-column">
            <i className="pi pi-upload" style={{ fontSize: '29px', borderRadius: '50%', color: 'rgba(85, 85, 216, 0.8)' }}></i>
            <span style={{ fontSize: '14px', color: 'rgba(85, 85, 216, 0.5)', fontWeight: '400' }}>
               Arrastre y coloque su logo aquí
            </span>
         </div>
      );
   };

   const subirFoto = () => {
      console.log("persona update", foto);
      const formDataSend = new FormData();
      formDataSend.append("logo", foto.foto);
      http.post(`empresa/update/foto/${dataShow?.id}`, formDataSend)
         .then((response) => {
            getEmpresa();
            showToast(
               "success",
               "Actualizado correctamente",
               "Se subió la foto de perfil correctamente"
            );
            setVisible(false);

         }).catch((err) => {
            showToast(
               "error",
               "Error al actualizar",
               "No se subió la foto de perfil"
            );
            console.log("algo salio mal", err);
         })
   }

   const paqueteCreateDialogFooter = (
      <div className="card flex flex-wrap justify-content-center gap-3">
         <Button
            type="button"
            className=" p-button-raised"
            onClick={subirFoto}
         >

            <span style={{ fontWeight: '500', fontSize: '14px', color: '#FFFFFF' }}>Actualizar</span>
         </Button>
      </div>
   );

   const handleChangeImagen = (e) => {
      console.log("entro cambio de imagen", e?.files[0])
      setFoto({ foto: e?.files[0] })
   }

   return (
      <div>
         <Dialog
            header={<>
               <div className="flex gap-2">
                  <div className="flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#EBEBFF', borderRadius: '10px', width: '60px', height: '60px' }}>
                     <img src={icono} alt="" style={{ width: '25px', height: '31.25px' }} />
                  </div>
                  <div className="flex flex-column justify-content-center">
                     <h3 style={{ fontWeight: '700', fontSize: '16px', color: '#5555D8' }}>Foto de perfil - Empresa</h3>
                     <p style={{ fontWeight: '500', fontSize: '14px', color: '#7B8794' }}>Inserte una foto de perfil</p>
                  </div>
               </div>
            </>}
            visible={visible} style={{ width: '50vw', maxWidth: '446px' }} className="form3-container"
            appendTo={document.getElementById("persona-form-edit")}
            dismissableMask
            footer={paqueteCreateDialogFooter}
            onHide={() => HideDialogEdit()}>
            <div style={{ marginTop: '10px' }}>
               <div className="flex flex-column gap-3 mb-4">
                  <div className="body-form flex flex-wrap w-full h-full md:flex-row gap-3">
                     {/* Subir foto */}
                     <div className="flex flex-column md:flex-wrap gap-3 flex-1">
                        <div>
                           <span style={{ fontWeight: '500', fontSize: '14px' }}>Agregar una imagen</span>
                        </div>
                        <div>


                           <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                           <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                           <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                           <FileUpload ref={fileUploadRef}
                              className="SubirIMGDelUsuario7u7"
                              id="foto"
                              name="foto[]"
                              accept="image/*"
                              maxFileSize={2000000}
                              auto
                              customUpload
                              uploadHandler={handleChangeImagen}
                              onUpload={onTemplateUpload}
                              onSelect={onTemplateSelect}
                              onError={onTemplateClear} onClear={onTemplateClear}
                              headerTemplate={headerTemplate}
                              itemTemplate={itemTemplate}
                              emptyTemplate={emptyTemplate}
                              chooseOptions={chooseOptions}
                              uploadOptions={uploadOptions}
                              cancelOptions={cancelOptions}
                           />
                        </div>
                        <div className="flex flex-column gap-3">
                           <div style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>
                              <p>Recomendaciones:</p>
                           </div>
                           <div className="flex flex-column gap-1" style={{ fontWeight: "400", fontSize: "14px", color: "#7B8794" }}>
                              <p>*Peso máximo de imagen 2 Mb</p>
                              <p>*Tamaño de 300 x 300px</p>
                              <p>*Imagen en formato .jpg</p>
                              <p>*Formato de buena calidad</p>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </Dialog>
      </div>
   )
}