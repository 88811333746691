import React, { useContext, useEffect, useRef, useState } from 'react'
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AuthUser from '../../../../AuthUser';
import { iconCANCELADO, iconNO_REALIZADO, iconPROCESO, iconREALIZADO, } from "../../../../Services/importIcons";
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import MultiLevelTreeSelectWithAPIClinica from '../../ProductoServicio/Servicios/SelectTipoServicioClinica';
import ModalFinalizar from '../../../../Components/Recepcion/ModalFinalizar';
import { GestionContext } from '../services/GestionContext';
import ResultadosOverlay from '../../../../Components/Clinica/GestionAtencion/components/ResultadosOverlay';
import FinalizarRevisionComponent from '../../../../Components/Clinica/GestionAtencion/Revision/pure/FinalizarRevision';
import "../../../../Style/styleLiquidaciones.css";

export default function PageLiquidacion() {

   const { http } = AuthUser();
   const toast = useRef(null);
   const showToast = (severity, summary, detail) => {
      toast.current.show({ severity: severity, summary: summary, detail: detail });
   };

   const [pacientes, setPacientes] = useState([]);
   const [paciente, setPaciente] = useState({});
   const [Empresa, setEmpresa] = useState([]);
   const [locales, setLocales] = useState([]);
   const [selectedEmpresa, setselectedEmpresa] = useState(null);
   const [visibleFinalizar, setVisibleFinalizar] = useState(false);
   const [originalPacientes, setOriginalPacientes] = useState([]);
   const [selectEstablecimiento, setselectEstablecimiento] = useState(null);
   const [selectEvaluaciones, setSelecEvaluaciones] = useState(null);
   // const [selectEmpresa, setSelecEmpresa] = useState(null);
   const [key, setKey] = useState(0);

   const limpiarCalendarios = () => {
      setFechaInicio(null);
      setFechaFin(null);
      setFechaValida(true); // limpia los calendarios
      getPacientes();// Actualiza la lista de pacientes
      setselectEstablecimiento(null); // Limpia la selección de establecimiento
      setSelecEvaluaciones(null);
      setselectedEmpresa(null); // Limpia la selección de Empresa

   };

   const optionTipoEvaluaciones = [
      { nombre: "Ingreso", id: 1 },
      { nombre: "Periódico", id: 2 },
      { nombre: "Retiro", id: 3 },
   ];
   const [fechaInicio, setFechaInicio] = useState(null);
   const [fechaFin, setFechaFin] = useState(null);
   const [filtroRealizado, setFiltroRealizado] = useState(false);

   const [fechaValida, setFechaValida] = useState(true);

   //#region filter
   const [globalFilterValue, setGlobalFilterValue] = useState("");


   // const [filtroSeleccionado, setFiltroSeleccionado] = useState(false);

   const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
   });

   const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
   };

   //filtro BOTON

    const handleFiltrar = () => {

      // Verifica si ambas fechas están vacías

      // Verifica si solo una de las fechas está vacía y si no se han seleccionado opciones de establecimiento o evaluaciones
      if (((!fechaInicio && fechaFin) || (fechaInicio && !fechaFin)) && (!selectEstablecimiento || !selectEvaluaciones || !selectedEmpresa)) {
         // Si solo una de las fechas está vacía y no se han seleccionado opciones de establecimiento o evaluaciones
         setFechaValida(false); // Establecer fecha válida como false para mostrar el mensaje
         return;
      }

      setFechaValida(true); // Establece fecha válida como true si no se cumple ninguna de las condiciones anteriores

      let filteredPacientes = originalPacientes;

      // if (selectedEmpresa) {
      //    filteredPacientes = filteredPacientes.filter(
      //       (paciente) =>
      //          paciente?.empresa?.razon_social === selectedEmpresa.id
      //    );
      // }

      if (selectEstablecimiento) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente.clinica_local_id === selectEstablecimiento.id
         );
      }

      if (selectEvaluaciones) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente?.hoja_ruta?.con_perfil_tipo?.tipo_perfil_id ===
               selectEvaluaciones.id
         );
      }

      // if (selectedTipoServicio) {
      //    filteredPacientes = filteredPacientes.filter(
      //       (paciente) =>
      //          paciente?.hoja_ruta?.tipo_servicio_id === selectedTipoServicio.id
      //    );
      // }

      if (fechaInicio && fechaFin) {
         const fechaInicioSinHora =
            new Date(
               fechaInicio.getFullYear(),
               fechaInicio.getMonth(),
               fechaInicio.getDate()
            ).getTime() - 100000000;
         const fechaFinSinHora = new Date(
            fechaFin.getFullYear(),
            fechaFin.getMonth(),
            fechaFin.getDate()
         ).getTime();

         filteredPacientes = filteredPacientes.filter((paciente) => {
            const fechaPaciente = new Date(paciente.fecha).getTime();
            return (
               fechaPaciente >= fechaInicioSinHora &&
               fechaPaciente <= fechaFinSinHora
            );
         });
      }
      console.log("pacientes finales", filteredPacientes)

      setPacientes(filteredPacientes);
      setFiltroRealizado(true);
   };

   // Verifica si algún filtro está seleccionado
   // if (selectEstablecimiento || selectEvaluaciones) {
   //    setFiltroSeleccionado(true);
   // } else {
   //    setFiltroSeleccionado(false);
   // }

   //    function addDays(date, days) {
   //       const result = new Date(date);
   //       result.setDate(result.getDate() + 1);
   //       return result;
   //   }

   //#endregion filter

   //#region apis
   const handleDescargar = () => {

      if (filtroRealizado && pacientes.length > 0) {
         if (fechaInicio && fechaFin && selectedEmpresa?.id) {
            const cuerpoSolicitud = {
               fecha_inicial: fechaInicio,
               fecha_final: fechaFin,
               empresa_id: selectedEmpresa.id
            };

            http
               .get("formato/valorizado/venta", {
                  data: cuerpoSolicitud, // Enviando los datos en el cuerpo de la solicitud
                  responseType: 'arraybuffer' // Indicando el tipo de respuesta esperada (un archivo)
               })
               .then((response) => {
                  const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  const url = window.URL.createObjectURL(blob);
                  const enlace = document.createElement("a");
                  enlace.href = url;
                  enlace.setAttribute("download", "archivo_valorizado.xlsx");
                  document.body.appendChild(enlace);
                  enlace.click();
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(enlace);
               })
               .catch((error) => {
                  console.error("Error al descargar el archivo:", error);
               });
         } else {
            showToast("warn", "Datos incompletos", "Por favor selecciona una empresa y establece un rango de fechas antes de descargar.");
         }
      } else {
         if (!filtroRealizado) {
            showToast("warn", "Filtrado no realizado", "Realiza un filtrado antes de descargar.");
         } else {
            showToast("warn", "No hay datos filtrados", "No hay datos para descargar. Por favor, filtra la tabla primero.");
         }
      }
   };



   const getPacientes = () => {
      http
         .get("clinica/recepcion/pacientes")
         .then((response) => {
            setPacientes(response.data.data);
            setOriginalPacientes(response.data.data);
            setKey(key + 1);
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   const GetEmpresa = () => {
      http
         .get("clinica/contratos/get")
         .then((response) => {
            setEmpresa(response?.data?.data);
            console.log("empresaaaa", response?.data?.data)
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   

   const GetLocalesClinica = () => {
      http
         .get("clinica/recursoshumanos/local/get")
         .then((response) => {
            setLocales(response.data.data); //Asignar o almacenar datos en "locales"
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   //Recorrido locales
   const OpcionesEmpresa = Empresa?.map((item) =>
      //Seleccionar datos requeridos
      ({ nombre: item.empresa?.razon_social, id: item.empresa?.id })
   );

   const OpcionesLocales = locales?.map((item) =>
      //Seleccionar datos requeridos
      ({ nombre: item.nombre, id: item.id })
   );

   

   const calcularEdad = (fechaNacimiento) => {
      if (!fechaNacimiento) return '';
      const fechaActual = new Date();
      const nacimiento = new Date(fechaNacimiento);
      let edad = fechaActual.getFullYear() - nacimiento.getFullYear();
      const diferenciaMeses = fechaActual.getMonth() - nacimiento.getMonth();

      if (diferenciaMeses < 0 || (diferenciaMeses === 0 && fechaActual.getDate() < nacimiento.getDate())) {
         edad--;
      }

      return edad;
   }

   const handleLocalA = (rowData) => {
      return rowData.clinica_local ? rowData.clinica_local?.nombre : "-"
   }

   const handleEmpresa = (rowData) => {
      return rowData.empresa_personal ? rowData.empresa_personal?.empresa?.razon_social : "-"
   }

   const handlePuestoT = (rowData) => {
      return rowData.empresa_personal ? rowData.empresa_personal?.empresa_cargo?.nombre : "-"
   }

   const handleTipoE = (rowData) => {
      return rowData.hoja_ruta ? rowData.hoja_ruta?.con_perfil_tipo?.tipo_perfil?.nombre : "-"
   }

   const handlePerfil = (rowData) => {
      return rowData.hoja_ruta ? rowData.hoja_ruta?.con_perfil_tipo?.con_perfil?.nombre : "-"
   }

   const actionState = (rowData) => {
      let text = "";
      switch (rowData.estado_paciente) {
         case "0":
            text = "En espera";
            break;
         case "1":
            text = "En atención";
            break;
         case "2":
            text = "Atendido";
            break;
         default:
            text = "No atendido";
      }
      return <Tag value={text} severity={getEstado(rowData)}></Tag>;
   };

   const getEstado = (paciente) => {
      switch (paciente.estado_paciente) {
         case "0":
            return "warning";

         case "1":
            return null;

         case "2":
            return "success";

         default:
            return "danger";
      }
   };


   //#endregion

   useEffect(() => {
      getPacientes();
      GetEmpresa();
      GetLocalesClinica();
   }, []);


   const acciones = () => {
      return (
         <div className="flex flex-wrap justify-content-around">
            <Button
               icon="pi pi-info"
               className="border-circle"
               style={{ height: "37px", width: "37px" }}
               tooltip=" Informacion"
               tooltipOptions={{ position: "bottom" }}
            />

         </div>
      );
   };

   //datos principales de UPDATE

   const { listRutasContext, setAtencionContext, setPacienteContext } = useContext(GestionContext);

   // LISTOOOOOOOOOOOOOOOOOOOOOOOOOO!!! TITTO PAGAME 10000 DOLARES!!!
   return (
      <div className="flex flex-nowrap flex-column w-full">
         <div className="arriba mb-6">
            <span className="tit">Mis Liquidaciones</span>
            <br />
            <span className="con">
               En este módulo usted podrá ver sus liquidaciones
            </span>
         </div>
         <div className="flex flex-wrap flex-column gap-6">
            <Toast ref={toast} />
            {/* Contenido para ingresar una búsqueda, establecimiento, servicio, tipos de evaluación */}
            <div className="flex flex-wrap gap-2 w-12 ">

               <div className="flex flex-wrap flex-column gap-3 flex-1">
                  <div>
                     <p className="nombre" style={{ marginLeft: "-65px" }}>
                        Empresa
                     </p>
                  </div>
                  <div className="card flex Busqueda">
                     <Dropdown
                        value={selectedEmpresa}
                        onChange={(e) => setselectedEmpresa(e.value)}
                        options={OpcionesEmpresa}
                        optionLabel="nombre"
                        placeholder="Seleccione"
                        className="w-full"
                        showClear
                     />
                  </div>
               </div>
               <div className="flex flex-wrap flex-column Busqueda gap-3 flex-1">
                  <p className="nombre" style={{ marginLeft: "-65px" }}>
                     Local de la Empresa
                  </p>
                  <Dropdown
                     // value={selectEmpresa}
                     // onChange={(e) => setSelecEmpresa(e.value)}
                     // options={OpcionesLocalesEmpresa}
                     value={selectEvaluaciones}
                     onChange={(e) => setSelecEvaluaciones(e.value)}
                     options={optionTipoEvaluaciones}
                     optionLabel="nombre"
                     placeholder="Seleccione"
                     className="w-full"
                     showClear
                  />
               </div>

               <div className="flex flex-wrap Busqueda flex-column gap-3 flex-1">
                  <div>
                     <p
                        className="nombre"
                        style={{ marginLeft: "-65px", whiteSpace: "nowrap" }}
                     >
                        Local de la Clínica
                     </p>
                  </div>
                  <div className="card flex justify-content-center">
                     <Dropdown
                        value={selectEstablecimiento}
                        onChange={(e) => setselectEstablecimiento(e.value)}
                        options={OpcionesLocales}
                        optionLabel="nombre"
                        placeholder="Seleccione"
                        className="w-12"
                        showClear
                     />
                  </div>

               </div>

            </div>
            <div className='flex gap-3'>
               <div className="flex flex-column gap-1 Calendar ">
                  <p className="p-mb-1 mb-2">Fecha Inicial</p>

                  <Calendar
                     value={fechaInicio}
                     onChange={(e) => { setFechaInicio(e.value); console.log("FechaInicio", fechaInicio); }}
                     dateFormat="dd/mm/yy"
                     placeholder="dd/mm/aaaa"
                     showIcon
                     className={!fechaValida && !fechaInicio ? "p-invalid" : ""}
                  />

                  {!fechaValida && !fechaInicio && <small className="p-error">Fecha requerida</small>}

               </div>
               <div className="flex flex-column gap-1 Calendar " >
                  <p className="p-mb-1 mb-2">Fecha Final</p>

                  <Calendar
                     value={fechaFin}
                     onChange={(e) => { setFechaFin(e.value); }}
                     dateFormat="dd/mm/yy"
                     placeholder="dd/mm/aaaa"
                     showIcon
                     className={!fechaValida && !fechaFin ? "p-invalid" : ""}
                  />

                  {!fechaValida && !fechaFin && <small className="p-error">Fecha requerida</small>}

               </div>

               <div className="flex flex-1 w-full justify-content-between gap-3 ">
                  <Button
                     className="h-3rem BotonF"
                     onClick={handleFiltrar}
                     label="Filtrar"
                  />

                  <Button
                     className="h-3rem BotonF"
                     onClick={limpiarCalendarios}
                     label="Limpiar"

                  />

                  <Button className="h-3rem BotonF" label="Descargar" onClick={handleDescargar}>
                     <i className="pi pi-download" style={{ marginLeft: '7px' }}></i>
                  </Button>

               </div>
            </div>
            {/* Creacion de calendario y funcionalidad de validacion. */}

            <div className="p-field flex flex-wrap"
            // style={{ marginTop: "-155px", marginLeft: "32px" }}
            >
               <div className="flex  gap-2 " style={{ marginRight: '10px' }}>

                  <div className="flex flex-wrap flex-column pt-5 flex-1 cont-buscador" style={{ marginRight: "10px", marginTop: "-50px" }}>
                     <div className="p-input-icon-left  ">
                        <InputText
                           placeholder="Ingresa busqueda"
                           value={globalFilterValue}
                           onChange={onGlobalFilterChange}
                           style={{ fontSize: "100%", width: "550px" }}
                        />
                        <i className="pi pi-search" />
                     </div>
                  </div>

               </div>

            </div>

            <DataTable
               className="TablaRecepcionCSSNew"
               paginator
               value={pacientes}
               key={key}
               filters={filters}
               responsiveLayout="stack"
               rows={5}
               rowsPerPageOptions={[5, 20, 50]}
               tableStyle={{ width: "100%" }}
               style={{ width: "100%", overflowX: "scroll", marginTop: "-30px" }}
            >
               <Column
                  //field="id"
                  header="Ítem"
                  align="center"
                  body={(rowData, rowIndex) => {
                     return (
                        <div>{rowIndex.rowIndex + 1}</div>
                     );
                  }}
                  style={{ whiteSpace: "wrap", width: "2rem" }}
               />
               <Column
                  field="persona.tipo_documento.nombre"
                  header="Tipo de Documento"
                  align="center"
                  style={{ width: "5.5rem", whiteSpace: 'normal' }}
               />
               <Column
                  field="persona.numero_documento"
                  header="Nro. de Documento"
                  align="center"
                  style={{ width: "7rem", whiteSpace: 'normal' }}

               />
               <Column
                  field="nombres_completos"
                  header="Nombres Completos"
                  align="left"
                  style={{ width: "10rem", whiteSpace: 'normal' }}
               />

               <Column field=" " header="Edad" align="center"
                  body={(rowData) => <div>{calcularEdad(rowData.fecha_nacimiento)}</div>}
                  style={{ width: "3rem" }}
               />

               <Column field="" header="Local de Atención" align="center" body={handleLocalA}
                  style={{ width: "7rem", whiteSpace: 'normal' }}
               />

               <Column field="" header="Empresa" align="left" body={handleEmpresa}
                  style={{ width: "10rem", whiteSpace: 'normal' }}
               />

               <Column field="" header="Puesto de Trabajo" align="center" body={handlePuestoT}
                  style={{ width: "9rem", whiteSpace: 'normal' }}
               />

               <Column field="" header="Tipo de Examen" align="center" body={handleTipoE}
                  style={{ width: "6rem", whiteSpace: 'normal' }}
               />

               <Column field="" header="Perfil" align="center" body={handlePerfil}
                  style={{ width: "6.5rem", whiteSpace: 'normal' }}
               />

               <Column
                  body={actionState}
                  header="Estado"
                  align="center"
                  style={{ width: "7rem", whiteSpace: 'normal' }}
               />

               <Column
                  body={acciones}
                  header="Acciones"
                  align="center"
                  style={{ width: "4rem", whiteSpace: 'normal' }}
               />

            </DataTable>
         </div>
      </div>
   );
};
