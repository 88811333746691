import React, { createContext, useContext, useState } from 'react';
// import { useLocation } from "react-router-dom";
import "../../Style/Atencion/atencionTimeLine.css";
import {
   iconAudiometria,
   iconEkg,
   iconLab,
   iconMedicina,
   iconOft,
   iconPsicologia,
   iconRadiologia,
   iconTriaje,
   iconEspirometria,
   iconOdontologia,
} from "../../Services/importIcons";

export const RAContext = createContext();

export const RAProvider = (props) => {
   const [stateAtencion, setStateAtencion] = useState([
      { estado: 0, nombre: "Triaje", icon: iconTriaje },
      { estado: 0, nombre: "Psicología", icon: iconPsicologia },
      { estado: 0, nombre: "EKG", icon: iconEkg },
      { estado: 0, nombre: "Oftalmología", icon: iconOft },
      { estado: 0, nombre: "Audiometría", icon: iconAudiometria },
      { estado: 0, nombre: "Examen Radiológico", icon: iconRadiologia },
      { estado: 0, nombre: "Examen de Laboratorio", icon: iconLab },
      { estado: 0, nombre: "Odontología", icon: iconOdontologia },
      { estado: 0, nombre: "Espirometría", icon: iconEspirometria },
      { estado: 0, nombre: "Medicina General", icon: iconMedicina },
   ]);

   const [pageViewContext, setPageViewContext] = useState(0);
   const [DatosGenerales, setDatosGenerales] = useState({});

   const [accesos, setAccesos] = useState([]);

   const [atencionContext, setAtencionContext] = useState(null);

   const [listRutasContext, setListRutasContext] = useState([
      { id: 0, nombre: "Triaje", icon: iconTriaje },
      { id: 1, nombre: "Psicología", icon: iconPsicologia },
      { id: 2, nombre: "EKG", icon: iconEkg },
      { id: 3, nombre: "Oftalmología", icon: iconOft },
      { id: 4, nombre: "Audiometría", icon: iconAudiometria },
      { id: 5, nombre: "Examen Radiológico", icon: iconRadiologia },
      { id: 6, nombre: "Examen de Laboratorio", icon: iconLab },
      { id: 7, nombre: "Odontología", icon: iconOdontologia },
      { id: 8, nombre: "Espirometría", icon: iconEspirometria },
      { id: 9, nombre: "Medicina General", icon: iconMedicina },
   ]);


   const [pacienteContext, setPacienteContext] = useState(null);

   const refresh = () => {
      setStateAtencion([
         { estado: 0, nombre: "Triaje", icon: iconTriaje },
         { estado: 0, nombre: "Psicología", icon: iconPsicologia },
         { estado: 0, nombre: "EKG", icon: iconEkg },
         { estado: 0, nombre: "Oftalmología", icon: iconOft },
         { estado: 0, nombre: "Audiometría", icon: iconAudiometria },
         { estado: 0, nombre: "Examen Radiológico", icon: iconRadiologia },
         { estado: 0, nombre: "Examen de Laboratorio", icon: iconLab },
         { estado: 0, nombre: "Odontologia", icon: iconOdontologia },
         { estado: 0, nombre: "Espirometria", icon: iconEspirometria },
         { estado: 0, nombre: "Medicina General", icon: iconMedicina },
      ]);
   };

   const [triajeAreasContext, setTriajeAreasContext] = useState([]);
   const [triajeDetalleId, setTriajeDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });
   const [psicologiaAreasContext, setPsicologiaAreasContext] = useState([]);
   const [psicologiaDetalleId, setPsicologiaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });
   const [ekgAreasContext, setEkgAreasContext] = useState([]);
   const [ekgDetalleId, setEkgDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });
   const [oftalmologiaAreasContext, setOftalmologiaAreasContext] = useState([]);
   const [oftalmologiaDetalleId, setOftalmologiaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });

   const [radiologiaAreasContext, setRadiologiaAreasContext] = useState([]);
   const [radiologiaDetalleId, setRadiologiaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });

   const [audiometriaAreasContext, setAudiometriaAreasContext] = useState([]);
   const [audiometriaDetalleId, setAudiometriaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });

   const [espirometriaAreasContext, setEspirometriaAreasContext] = useState([]);
   const [espirometriaDetalleId, setEspirometriaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   })

   const [medicinaGeneralAreasContext, setMedicinaGeneralAreasContext] = useState([]);
   const [medicinaGeneralDetalleId, setMedicinaGeneralDetalleId] = useState(
      {
         id: 0,
         estado: '0',
         hora_inicio: null,
         fecha_inicio: null,
         hora_fin: null,
         fecha_fin: null,
      }
   )
   const [laboratorioAreasContext, setLaboratorioAreasContext] = useState([]);
   const [laboratorioDetalleId, setLaboratorioDetalleId] = useState(
      {
         id: 0,
         estado: '0',
         hora_inicio: null,
         fecha_inicio: null,
         hora_fin: null,
         fecha_fin: null,
      }
   )

   return (
      <>
         {props.revision !== 1 && (<div className="arriba">
            <span className="tit atencitit">{(props.Titulo ? props.Titulo : "Atencion")}</span>
            <br />
            <span className="con atenci">
               {(props.Descripcion ? props.Descripcion : "En esta área usted hará la gestión de atención de sus pacientes")}
            </span>
         </div>)}
         <RAContext.Provider
            value={{
               pageViewContext,
               setPageViewContext,
               atencionContext,
               setAtencionContext,
               listRutasContext,
               setListRutasContext,
               pacienteContext,
               setPacienteContext,
               stateAtencion,
               setStateAtencion,
               accesos,
               setAccesos,
               DatosGenerales,
               setDatosGenerales,
               refresh,
               triajeAreasContext, setTriajeAreasContext,
               triajeDetalleId, setTriajeDetalleId,
               psicologiaAreasContext, setPsicologiaAreasContext,
               psicologiaDetalleId, setPsicologiaDetalleId,
               ekgAreasContext, setEkgAreasContext,
               ekgDetalleId, setEkgDetalleId,
               oftalmologiaAreasContext, setOftalmologiaAreasContext,
               oftalmologiaDetalleId, setOftalmologiaDetalleId,
               audiometriaAreasContext, setAudiometriaAreasContext,
               audiometriaDetalleId, setAudiometriaDetalleId,
               radiologiaAreasContext, setRadiologiaAreasContext,
               radiologiaDetalleId, setRadiologiaDetalleId,
               espirometriaAreasContext, setEspirometriaAreasContext,
               espirometriaDetalleId, setEspirometriaDetalleId,
               medicinaGeneralAreasContext, setMedicinaGeneralAreasContext,
               medicinaGeneralDetalleId, setMedicinaGeneralDetalleId,
               laboratorioAreasContext, setLaboratorioAreasContext,
               laboratorioDetalleId, setLaboratorioDetalleId,
            }}
         >
            {props.children}
         </RAContext.Provider>
      </>
   );
};