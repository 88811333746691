import React, { useEffect, useRef, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import './CodigoBarras.css';


const PopUpArchivoZLP = (props) => {
    
    const Stylecuadro = {
        width: '100px',
        height: '60px',
        borderRadius: '10px',
        backgroundColor:'#EBEBFF'
    };  
    const StyleLetracuadro = {
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: '700',
        color: '#5555D8',
    };  
    const headerPopUp=(
        <div className='w-full flex justify-content-start align-items-center'>
        <div className='flex justify-content-center align-items-center' style={Stylecuadro}>
        <svg version="1.1" id="_x32_"  viewBox="0 0 512.00 512.00"  width="70px" height="60px" fill="#5555D8" stroke="#5555D8">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
            <g> <rect y="94.103" class="st0" width="48.569" height="323.795"></rect> <rect x="72.854" y="94.103" class="st0" width="24.284" height="323.795"></rect> 
            <polygon class="st0" points="121.423,94.103 121.423,417.897 145.708,417.897 169.992,417.897 169.992,94.103 145.708,94.103 "></polygon> 
            <polygon class="st0" points="194.277,94.103 194.277,417.897 218.561,417.897 242.846,417.897 242.846,94.103 218.561,94.103 "></polygon> 
            <rect x="267.13" y="94.103" class="st0" width="24.285" height="323.795"></rect> 
            <polygon class="st0" points="339.984,94.103 315.7,94.103 315.7,417.897 339.984,417.897 364.269,417.897 388.554,417.897 388.554,94.103 364.269,94.103 "></polygon> 
            <polygon class="st0" points="412.838,94.103 412.838,417.897 437.122,417.897 462.083,417.897 462.083,94.103 437.122,94.103 "></polygon> 
            <rect x="487.044" y="94.103" class="st0" width="24.956" height="323.795"></rect> </g> </g></svg>
        </div>
        <p className='ml-2' style={StyleLetracuadro}>
        Imprimir Codigo de barras
        </p>
    </div>
    );
    return (
        <Dialog header={headerPopUp} visible={props.valorSet} style={{ width: '750px'}} onHide={() => props.metSet(false)}>
            <div>Nuevo Pop Up</div>
        </Dialog>    )
}
export default PopUpArchivoZLP;