import React, { useState,useContext,useEffect } from "react";
import "../../../Style/style5.css";
import "../../../Style/Button.css";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import AuthUser from "../../../AuthUser";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
export default function TablaLaborat({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
  const { http } = AuthUser();
  const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
  const [prueba, setPrueba] = useState({})
  const [nombreArchivo, setNombreArchivo] = useState(null);
  const [file, setFile] = useState(null);
  const [indexSup, setIndexSup] = useState(0);
  const [indexSub, setIndexSub] = useState(0);
  const [value, setValue] = useState('');
  const [parametros, setParametros] = useState([
    {
      prueba: 'Hematocrito',
      resultado: 0,
      um: '%',
      rango_final: 0,
      rango_inicial: 0,
      valor_normal: "Mujeres: 35.00 - 50.00",
      conclusion: ""
    },

  ])
  //funcion Se puede simplificar
  const [isClicked, setIsClicked] = useState(false);

  function handleClick() {
    setIsClicked(true);
  }

  const [selectedConclusion, setSelectedConclusion] = useState(null);
  const conclusion = [
    { name: 'Normal', code: 'NL' },
    { name: 'Anormal', code: 'AL' },
  ];

  const handleCreate = () => {
    const formData = new FormData();
    formData.append("examen_id", tipoExamen);
    formData.append("prueba[examen_id]", tipoPrueba);
    formData.append("prueba[estado]", 1);
    formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
    formData.append("prueba[cantidad_parametros]", parametros?.length);
    formData.append("prueba[parametros]", JSON.stringify(parametros));
    formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));

    http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
      .then(response => {
        console.log(response)
        showToast("success","Creado Correctamente",response?.data?.resp)
      })
      .catch(error => {
        console.log(error)
        showToast("Error","Error de Guardar",error?.data?.resp)
      })
  }

  const changeExamenContext = (superior, inferior, auxParametros) => {
    setExamenesContext(examenesContext.map((elementoSup, index) => {
      if (index === superior) {
        let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
          if (indexAux === inferior) {
            return {
              ...prueba,
              parametros: auxParametros,
              estado: 1,
              cantidad_parametros: auxParametros?.length,
              perfil: auxParametros?.length > 1 ? 2 : 1
            }
          }
          return prueba;
        })
        return { ...elementoSup, pruebas: auxPrueba };
      }
      return elementoSup;
    }))
  }

  const handleInputChange = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].resultado = e.target.value;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };

  const handleInputInicial = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].rango_inicial = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };
  const handleInputFinal = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].rango_final = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };

  const handleSelec = (index, e) => {
    const tempTask = [...parametros];
    tempTask[index].conclusion = e;
    setParametros(tempTask);
    changeExamenContext(indexSup, indexSub, tempTask);
  };

  useEffect(() => {
    console.log(examenesContext)
    let sup = 0;
    let inf = 0;
    let auxPruebas = examenesContext.find((examen, index) => {
      if (examen.examen_id === tipoExamen) {
        setIndexSup(index);
        sup = index;
        console.log(index)
      }
      return examen.examen_id === tipoExamen
    });
    let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
      if (prueba.examen_id === tipoPrueba) {
        setIndexSub(index);
        inf = index;
        console.log(index)
      }
      return prueba.examen_id === tipoPrueba
    });
    if (auxPrueba?.archivo) {
      setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
    }
    setPrueba(auxPrueba);
    console.log("PRUEBA", auxPrueba)
    console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
    let auxParametros = typeof auxPrueba?.parametros === 'string' ? JSON.parse(auxPrueba?.parametros) : auxPrueba?.parametros
    auxPrueba?.parametros && setParametros(auxParametros);
    changeExamenContext(sup, inf, auxParametros);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className="flex flex-column w-full">
          <table className="tabla">
            <tr>
              <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>Prueba</th>
              <th className="encabezado" style={{ width: '25%', textAlign: "center" }}>Resultado</th>
              <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>U.M</th>
              <th className="encabezado">Rango</th>
              <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>Valor Normal</th>
            </tr>
            <tr>
              <td className="dato">Hematocrito</td>
              <td className="dato"><InputText placeholder="Resultado" value={parametros?.[0].resultado} onChange={(e) => handleInputChange(0, e)} /></td>
              <td className="dato">%</td>
              <td className="dato">
                <InputText className='w-3' value={parametros?.[0]?.rango_inicial} onChange={(e) => handleInputInicial(0, e.target.value)} />
                <span> - </span>
                <InputText className='w-3' value={parametros?.[0]?.rango_final} onChange={(e) => handleInputFinal(0, e.target.value)} /></td>
              <td className="dato">35.00 - 50.00</td>
            </tr>
            <tr>
              <td className="dato"></td>
              <td className="dato"></td>
              <td className="dato"></td>
              <td className="dato"></td>
              <td className="dato"></td>
            </tr>
          </table>
        </div>

      </div>
      <div className="flex flex-column gap-3">
        <div className="flex flex-row gap-3 w-full" style={{ alignItems: 'center' }}>
          <p style={{ borderLeft: "0px", fontWeight: '700', fontSize: '16px' }}>Conclusión médica</p>
          <Dropdown
            value={conclusion[examenesContext?.[4]?.pruebas?.[3]?.parametros?.[0]?.conclusion == "NL" ? 0 : 1]}
            onChange={(e) => handleSelec(0, e.value.code)}
            options={conclusion}
            optionLabel="name"
            placeholder="Normal" />
        </div>
      </div>
      <div className="flex flex-row gap-3 mt-2" style={{ justifyContent: 'end' }}>
        <Button label="Grabar" icon="fas fa-save" onClick={handleCreate} />
        <Button label="Recuperar" icon="fas fa-undo" />
        {/* <Button label="Hematólogo" icon="fas fa-user-md" /> */}
      </div>
    </div>
  );
}
