import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import DialogDetallesPersonal from "../../../EmpresaRol/RecursosHumanos/Personal/Dialog/DialogDetalles";
import { Checkbox } from "primereact/checkbox";

import AuthUser from "../../../../AuthUser";
import ModalCreatePersonal from "../../../../Components/RecursosHumanos/Personal/ModalCreatePersonal";
import ModalCredenciales from "../../../../Components/RecursosHumanos/Personal/ModalCredenciales";
import ModalEditar from "../../../../Components/RecursosHumanos/Personal/ModalEditar";
import ModalEliminar from "../../../../Components/RecursosHumanos/Personal/ModalEliminar";
import ModalCarga from "../../../../Components/RecursosHumanos/Personal/ModalCarga";
import ModalReporte from "../../../../Components/RecursosHumanos/Personal/ModalReporte";
import TablaCrud from "../../../../Components/Table/TablaCrud";
import "../../../../Style/personal.css";
import "../../../../Style/Botones.css"
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

function PagePersonalB() {
   const [visible, setVisible] = useState(false);
   const dispatch = useDispatch();
   const { http } = AuthUser();

   const [selectedIds, setSelectedIds] = useState([]);
   const toast = useRef(null);
   const [tipoDocumentos, setTipoDocumentos] = useState([]);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [personal, setPersonal] = useState([]);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleCredenciales, setVisibleCredenciales] = useState(false);
   const [personaleliminar, setPersonaleliminar] = useState([]);
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [visibleCarga, setVisibleCarga] = useState(false);
   const [visibleReporte, setVisibleReporte] = useState(false);
   const [roles, setRoles] = useState([]);
   const [contracts, setContracts] = useState([]);
   const [sexos, setSexos] = useState([]);
   const [data, setData] = useState([]);
   const [selectPersonal, setSelectPersonal] = useState({
      id: 0,
      tipo_documento: "",
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "2022-01-01",
      distrito_id: 2,
      celular: "",
      correo: "",
      usuario: 0,
      rol: "",
      notificacionesActivadas: false,
   });


   const getAllTipoDocumentos = async () => {
      await http
         .get("/tipodocumentos/get")
         .then((response) => {
            if (response?.data?.data) {
               setTipoDocumentos(response.data.data);
            } else if (response?.data?.error) {
               showToast('error', 'Error', response.data.error)
            }
         })
         .catch((error) => {
            showToast('error', 'Error', 'Contáctese con soporte técnico.')
         });
   };

   const getRoles = async () => {
      await http
         .get("roles/get")
         .then((response) => {
            setRoles(response?.data?.data);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const getPersonal = async () => {
      await http
         .get("clinica/personal/get")
         .then((response) => {
            console.log("personal11111111", response.data?.data);
            setPersonal(response.data?.data);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const getGenero = async () => {
      try {
         const response = await http.get("sexos/get");
         if (response?.data?.data) {
            setSexos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      };
   };

   const getContract = async () => {
      await http
         .get("tipocontrato/show")
         .then((response) => {
            console.log("Contratos", response.data)
            setContracts(response.data)
         })
   }

   const contractOptions = contracts.map((item) => ({
      label: item.nombre, id: item.id
   }))

   // const handleObtenerPuesto = async () => {
   //    try {
   //       const response = await http.get("empresa/cargo/get1")
   //       if (response?.data?.data) {
   //          setData(response.data.data);
   //       } else if (response?.data?.error) {
   //          showToast('error', 'Error', response?.data.error)
   //       }
   //    } catch (error) {
   //       showToast('error', 'Error', "Contáctese con soporte técnico")
   //    }
   // };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getAllTipoDocumentos(),
            getGenero(),
            getPersonal(),
            getRoles(),
            // handleObtenerPuesto(),
            getContract()
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }



   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };



   const handleSwitchChange = (e, rowData) => {
      const updatedPersonal = personal.map((user) =>
         user.id === rowData.id
            ? { ...user, notificacionesActivadas: e.value }
            : user
      );
      setPersonal(updatedPersonal);
   };

   const checkboxBodyTemplate = (rowData) => {
      const itemId = rowData.id;
      return (
         <React.Fragment>
            <Checkbox
               inputId={`checkbox_${rowData.id}`}
               value={rowData.id}
               onChange={(e) => {
                  const selectedId = e.value;
                  setSelectedIds((prevSelectedIds) =>
                     e.checked
                        ? [...prevSelectedIds, selectedId]
                        : prevSelectedIds.filter((id) => id !== selectedId)
                  );
               }}
               checked={selectedIds.includes(rowData.id)}
            />{itemId}</React.Fragment>

      );
   };

   const actionBodyTemplate = (rowData) => {
      return (

         <React.Fragment>
            { }
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => editPaquete(rowData)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />
         </React.Fragment>
      );
   };


   const editPaquete = (rowData) => {
      setVisibleEdit(true);
      setSelectPersonal(rowData)
   };

   const handleEnviarCredenciales = (idPersonal) => {
      const { http } = AuthUser();
      http
         .post(`clinica/personal/enviarCredenciales/${idPersonal}`)
         .then((response) => {
            console.log("Respuesta de la API:", response.data);

            toast.current.show({
               severity: "success",
               summary: "Éxito",
               detail: "Credenciales enviadas correctamente",
            });
         })
         .catch((error) => {
            console.error("Error al consultar la API:", error);
         });
   };
   const [personaId, setPersonaId] = useState(null);

   const showCredencialesModal = (rowData) => {
      const personaId = rowData.persona_id;
      setSelectPersonal(rowData);
      setVisibleCredenciales(true);
      setPersonaId(personaId);
   };


   const actionBodyCredenciales = (rowData) => {
      return (
         <Button
            label="Enviar Credenciales"
            icon="pi pi-send"
            className="enviar-credenciales"
            onClick={() => showCredencialesModal(rowData)}
         />
      )
   }

   const deletePaquete = (rowData) => {
      console.log({ rowData });
      setPersonaleliminar(rowData);
      setVisibleEliminar(true);
   };



   const verInfo = () => {
      return (
         <React.Fragment>
            <Button
               className="flex flex-row gap-2 align-items-center p-button-text"
               onClick={() => setVisible(true)}
               style={{ height: "55px", fontWeight: "450", fontSize: "12px", color: "#00a15c", padding: 0 }}
            >
               <i className="pi pi-info-circle" />
               <span>Ver información</span>
            </Button>
         </React.Fragment>
      )
   };

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   
   //
   return (
      <div>
         <Toast ref={toast} />
         <div className="flex flex-column">
            <h1 className="tit">Mi Personal</h1>
            <p className="con">Administralo de la mejor manera</p>
         </div>

         <div className="desktop-personal-b">
            <TablaCrud
               name="Personal"
               tableNombre={`Lista de Personal`}
               descripcion={`A continuación, se visualiza la lista de personal.`}
               onInputSearch={(e) => setGlobalFilter(e.target.value)}
               valueGlobalFilter={globalFilter}
               value={personal}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={" Crear"}
               // leftLabelButton4={" Reporte"}
               onClickLeftButton4={() => {
                  setVisibleReporte(true);
               }}
               // leftLabelButton2={" Carga de Datos"}
               onClickLeftButton2={() => {
                  setVisibleCarga(true);
               }}
            >
               <Column
                  field={"id"}
                  header="Item"
                  style={{ textAlign: "center" }}
                  body={checkboxBodyTemplate}
                  headerStyle={{ textAlign: "center" }}>
               </Column>

               <Column
                  style={{ textAlign: "center" }}
                  field={"persona.tipo_documento.nombre"}
                  header="Documento">
               </Column>

               <Column
                  style={{ textAlign: "center" }}
                  field={"persona.numero_documento"}
                  header="Num. de Doc">
               </Column>

               <Column style={{ textAlign: "center" }}
                  header="Nombre Completos"
                  body={(rowData) =>
                     rowData.persona.nombres + " " +
                     rowData.persona.apellido_paterno + " " +
                     rowData.persona.apellido_materno}
               />
               { }

               <Column
                  style={{ textAlign: "center" }}
                  field={"persona.correo"}
                  body={verInfo}
                  header="Información">
               </Column>



               <Column
                  header="Credenciales"
                  body={actionBodyCredenciales}
                  style={{ maxiwwidth: "8rem", textAlign: "center" }}
               ></Column>

               <Column
                  style={{ textAlign: "center" }}
                  body={<DialogDetallesPersonal />}
                  header="Detalles"
               ></Column>

               { }

               <Column
                  style={{ textAlign: "center" }}
                  header="Notificaciones"
                  body={(rowData) => (
                     <InputSwitch
                        style={{ color: "#00015ac" }}
                        checked={rowData.notificacionesActivadas}
                        onChange={(e) => handleSwitchChange(e, rowData)}
                     />
                  )}
               ></Column>

               <Column
                  style={{ textAlign: "center", minWidth: "8rem" }}
                  header="Acciones"
                  body={actionBodyTemplate}
               ></Column>

            </TablaCrud>
         </div>

         <div className="movil-personal-b">
            <TablaCrud
               name="Personal"
               tableNombre={`Lista de Personal`}
               descripcion={`A continuación, se visualiza la lista de personal.`}
               onInputSearch={(e) => setGlobalFilter(e.target.value)}
               valueGlobalFilter={globalFilter}
               value={personal}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={" Crear"}
               leftLabelButton4={" Reporte"}
               onClickLeftButton4={() => {
                  setVisibleReporte(true);
               }}
               leftLabelButton2={" Carga de Datos"}
               onClickLeftButton2={() => {
                  setVisibleCarga(true);
               }}
            >

               <Column className="color-fila" body={checkboxBodyTemplate} field={"id"} header="Item"></Column>
               <Column field={"persona.tipo_documento.nombre"} header="Tipo de Documento"></Column>
               <Column className="color-fila" field={"persona.numero_documento"} header="# de Documento"></Column>
               <Column header="Nombre Completo" body={(rowData) => rowData.persona.nombres + " " + rowData.persona.apellido_paterno + " " + rowData.persona.apellido_materno} />
               { }
               <Column className="color-fila" header="Información" body={verInfo}
               ></Column>

               { }

               { }

               <Column
                  header="Credenciales"
                  body={actionBodyCredenciales}
                  style={{ maxiwwidth: "8rem" }}
               ></Column>

               <Column className="color-fila"
                  body={<DialogDetallesPersonal />}
                  header="Detalles"
               ></Column>

               <Column
                  className="custom-switch"
                  body={(rowData) => (
                     <InputSwitch
                        checked={rowData.notificacionesActivadas}
                        onChange={(e) => handleSwitchChange(e, rowData)}
                     />
                  )}
                  header="Notificaciones"
               ></Column>

               <Column
                  className="color-fila"
                  header="Acciones"
                  body={actionBodyTemplate}
               ></Column>
               { }
               { }

            </TablaCrud>

         </div>
         <ModalCreatePersonal
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api="/clinica/personal/create"
            contractOptions={contractOptions}
            sexos={sexos}
            data={data}
         />

         <ModalEditar
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api="/clinica/personal/update"
            contractOptions={contractOptions}

         />
         <ModalCredenciales
            visibleCredenciales={visibleCredenciales}
            setVisibleCredenciales={setVisibleCredenciales}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            idPersonal={personaId}
            handleEnviarCredenciales={handleEnviarCredenciales}
         />
         <ModalEliminar
            visibleEliminar={visibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            personal={personal}
            setPersonal={personaleliminar}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api="/clinica/personal/delete"
         />

         <ModalCarga
            visibleCarga={visibleCarga}
            setVisibleCarga={setVisibleCarga}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />
         <ModalReporte
            visibleReporte={visibleReporte}
            setVisibleReporte={setVisibleReporte}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />
      </div>
   );
}

export default PagePersonalB;
