import React from 'react'
import calendario from '../../images/calendario.svg'
import { Tag } from 'primereact/tag';
export default function HojaRutaDetalleComponent({ data }) {

   const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
   };

   const fechaInicioFormateada = formatDate(data?.fecha_inicio);
   const fechaFinFormateada = formatDate(data?.fecha_fin);

   const validarFecha = (dataString) => {
      if (dataString === "31/12/1969") {
         const vacío = "--";
         return vacío;
      }
      else {
         const fechaValida = dataString;
         return fechaValida;
      }
   }


   const validarHora = (dataString) => {
      if (dataString === null) {
         const vacío = "--";
         return vacío;
      }
      else {
         const horaValida = dataString;
         return horaValida;
      }
   }

   const horaInicio = validarHora(data?.hora_inicio);
   const fechaInicioValida = validarFecha(fechaInicioFormateada);
   const horaFin = validarHora(data?.hora_fin);
   const fechaFinValida = validarFecha(fechaFinFormateada);

   const buttonDetails = (rowData) => {
      switch (rowData) {
         case "0":
            return <Tag value="No Atendido"></Tag>;
         case "1":
            return <Tag severity="warning" value="En atención"></Tag>;
         case "2":
            return <Tag severity="success" value="Atendido"></Tag>;
         case "3":
            return <Tag value="New"></Tag>;
         default:
            return <Tag value="No programado"></Tag>;
      }
   };


   return (
      <div>
         <div class='info2-cptraz'>
            <div className='field'><h1>{data.nombre}</h1></div>
            <div className='fild grid'>
               <div className='field col-3'>
                  <p>Fecha de inicio:</p>
               </div>
               <div className='field col-9 '>
                  <img alt='img_calendario' src={calendario} /> <span>{horaInicio} - {fechaInicioValida}</span>
               </div>
            </div>
            <div className='fild grid'>
               <div className='field col-3'>
                  <p>Fecha de fin:</p>
               </div>
               <div className='field col-9 '>
                  <img alt='img_calendario' src={calendario} /> <span>{horaFin} - {fechaFinValida}</span>
               </div>
            </div>
            <div className='fild grid'>
               <div className='field col-3'>
                  <p>Estado:</p>
               </div>
               <div className='field col-9 '>
                  <span>{buttonDetails(data.estado_ruta)}</span>
               </div>
            </div>
         </div>
      </div>
   )
}
