import React, { useContext, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import ModalCrearAbsentismo from "./ModalCrearAbsentismo";
import ModalEditarAbsentismo from "./ModalEditarAbsentismo";
import './TriajeAntPerTableANH.css';
import './TriajeAntPerTable.css';
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";

const TriajeAntPerTable = () => {
   const toast = useRef(null);
   const dt = useRef(null);

   const { antPerContext, setAntPerContext } = useContext(MGContext);
   let { stateAtencion, pageViewContext } = useContext(RAContext);
   const [rowselected, SetRowSelected] = useState()
   const [antDialog, setAntDialog] = useState(false);
   const [editDialog, setEditDialog] = useState(false);
   const [globalFilter, setGlobalFilter] = useState(null);

   const handledeleteAbsentismo = (data, e) => {
      const newData = [...antPerContext.med_ant_absentismo]
      const datosCompletos = { ...antPerContext, med_ant_absentismo: newData }

      console.log("inicio", newData)
      newData.splice(rowselected, 1)
      console.log("prueba", datosCompletos)
      setAntPerContext({ ...antPerContext, med_ant_absentismo: newData })

   }
   const handleRowSelected = (event) => {
      SetRowSelected(event?.rowIndex)
   }

   const actionBodyTemplateboton = (rowData, rowIndex) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => {
                  editNew()
                  handleRowSelected(rowIndex)
               }}

            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={(e) => {
                  handleRowSelected(rowIndex)
                  handledeleteAbsentismo(rowData, rowIndex)
               }}
            />
         </React.Fragment>
      );
   };
   const openNew = () => {
      setAntDialog(true);
   };

   const editNew = () => {
      setEditDialog(true);
   };

   const hideDialog = () => {
      setAntDialog(false);
   };
   const hideEditDialog = () => {
      setEditDialog(false);
   };
   const onSave = (tipo, otros, tipoA, desc, FID, FFD, diasI, menos, fechaIn, fechaF) => {
      let datos = antPerContext
      datos["med_ant_absentismo"][0] = { "tipo_absentismo_id": tipo, "otros": otros, "asociado_trabajo": tipoA, "descripcion": desc, "fechaInicioDate": FID, "fechaFinalDate": FFD, dias_incapacidad: diasI, menoscabo: menos, fecha_inicio: fechaIn, fecha_final: fechaF }
      setAntPerContext(datos)
   }


   const header = (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between contenedor_tabla_botones_ANH">
         <div className="Cuadro_titulo_boton_ANH">
            <h3 className="m-0">Registros de Absentismo</h3>
            <Button
               className="p-button boton_ANH_Create_2"
               label="Crear"
               // severity=""
               onClick={openNew}
               disabled={stateAtencion[pageViewContext].estado === 3}
            />
         </div>
         <span className="p-input-icon-left Cuadro_search_ANH " style={{ background: "#F6F7FB" }} >
            <i className="pi pi-search" />
            <InputText
               className="input_Search_2_ANH w-12 border-round-2xl"
               type="search"
               onInput={(e) => setGlobalFilter(e.target.value)}
               placeholder="Buscar"
               disabled={stateAtencion[pageViewContext].estado === 3}
            />
         </span>
      </div>
   );

   const bodyTipoAbsentismo = (tipoAbsentismo) => {

      switch (tipoAbsentismo) {
         case 1: return <div >Accidente</div>
         case 2: return <div >Enfermedad</div>
         case 3: return <div >Cirugías</div>
         case 4: return <div >Otros</div>
         default: return <></>
      }

   }

   return (
      <div>
         <Toast ref={toast} />
         <DataTable
            className="tabla_datos_ANH"
            ref={dt}
            value={antPerContext.med_ant_absentismo}
            header={header}
            globalFilter={globalFilter}
            dataKey="id"
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            onRowClick={(e) => { console.log(e) }}
            paginatorTemplate="PrevPageLink NextPageLink"
            size="small"
         >
            <Column body={(e) => bodyTipoAbsentismo(e?.tipo_absentismo_id)} header="Tipo" />
            <Column field="fecha_final" header="Fecha Final" />
            <Column field="fecha_inicio" header="Fecha de inicio" />
            <Column field="dias_incapacidad" header="Días Inc." />
            <Column body={(e) => <div>{e.asociado_trabajo === "1" ? "No" : "Si"}</div>} header="Asoc. Trab." />
            {/* <Column field="descripcion" header="Descripción" /> */}
            {/* <Column field="menoscabo" header="Menoscabo" /> */}
            <Column field="" header="Acción" body={actionBodyTemplateboton} />
         </DataTable>

         <ModalCrearAbsentismo
            antDialog={antDialog}
            hideDialog={hideDialog}
         />
         <ModalEditarAbsentismo
            editDialog={editDialog}
            hideEditDialog={hideEditDialog}
            data={antPerContext?.med_ant_absentismo?.[rowselected]}
            absentismoToEdit={antPerContext?.med_ant_absentismo?.[rowselected]}
            onSave={onSave}
         />
      </div>
   );
};

export default TriajeAntPerTable;
