import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import "../../../Style/Radiologia/Radiologia.css";
import VoiceRadiologia from "../../ButtonVoice/VoiceRadiologia";
import { RadContext } from "../../../Page/Radiologia/RadProvider";

const OpcRayosXTR = ({ titulo, idRayo, RayTorax, setRayTorax }) => {

   
   const [selectedOption, setSelectedOption] = useState();
   const [value, setValue] = useState("");

   let { dataRayosXToraxOIT, setDataRayosXToraxOIT } = useContext(RadContext);

   const Opciones = [
      { name: "Normal", code: "1" },
      { name: "Patológico", code: "2" },
      { name: "No concluyente", code: "3" },
   ];

   useEffect(() => {
      const selectedDetalle = RayTorax.rad_detalles[idRayo - 1];

      if (selectedDetalle) {
         setSelectedOption(Opciones.find((opt) => opt.code === selectedDetalle.opcion));
         setValue(selectedDetalle.descripcion);

      }

   }, [idRayo, RayTorax]);



   const onChange = (e) => {
      setSelectedOption(e.value);
      const newRayTorax = { ...RayTorax };
      newRayTorax.rad_detalles[idRayo - 1] = {
         rad_partes_id: idRayo,
         descripcion: value,
         opcion: e.value.code
      };
      setRayTorax(newRayTorax);
   };

   const onChangeValue = (e) => {
      setValue(e);
      const newRayTorax = { ...RayTorax };
      const selectedOptionCode = selectedOption ? selectedOption.code : null;
      newRayTorax.rad_detalles[idRayo - 1] = {
         rad_partes_id: idRayo,
         descripcion: e,
         opcion: selectedOptionCode,
      };
      setRayTorax(newRayTorax);
   };

   const LmpiarRegistro = (nulo) => {
      if (RayTorax.rad_detalles.length > 0) {
         const nuevoRayTorax = { ...RayTorax };
         nuevoRayTorax.rad_detalles[idRayo - 1].opcion = nulo;
         setRayTorax(nuevoRayTorax);
      }
   };

   const subTitulo = {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: "600",
   };

   useEffect(() => {
      console.log(RayTorax);
   }, [RayTorax]);

   useEffect(() => {

     /*  if(dataRayosXToraxOIT[idRayo]?.descripcion !== RayTorax.rad_detalles?[idRayo - 1]?.descripcion && 
         dataRayosXToraxOIT[idRayo]?.opcion !== RayTorax.rad_detalles?[idRayo - 1]?.opcion){
         setRayTorax(prevRayTorax => ({
            ...prevRayTorax,
            rad_detalles: prevRayTorax.rad_detalles.map(detalle => {
               if (detalle.rad_partes_id === (idRayo - 1)) {
                  return {
                     rad_partes_id: idRayo,
                     descripcion: dataRayosXToraxOIT[idRayo].descripcion,
                     opcion: dataRayosXToraxOIT[idRayo].opcion,
                  };
               }
               return detalle;
            })
         }));

         setSelectedOption(dataRayosXToraxOIT[idRayo].descripcion);
         setValue(dataRayosXToraxOIT[idRayo].opcion); 
   }*/
   }, []);

   return (
      <div className="w-full flex flex-1 flex-wrap flex-column gap-2" >
         <div className="w-full h-auto" style={subTitulo}>{titulo}</div>
         <div className="w-full flex gap-2 CuadroDropInputOpcRayosTorax">
            <div className="w-5 flex h-auto relative CuadroDropInputOpcRayosToraxDrop">
               <Dropdown
                  value={selectedOption}
                  onChange={(e) => onChange(e)}
                  options={Opciones}
                  optionLabel="name"
                  placeholder="Seleccionar"
                  className="desplegable-RadiografiaUwU w-full align-items-center"
                  style={{ height: "48px" }}
               />
               {selectedOption != null ? (
                  <button
                     className="absolute pi pi-times"
                     style={{right:'39px', top:'16px', background:'white', border:'0px solid transparent', color:'#6c757d'}}
                     onClick={() => {
                        setSelectedOption({});
                        LmpiarRegistro(null);
                     }}
                  ></button>
               ) : null}
            </div>


            <div className="w-7 CuadroDropInputOpcRayosToraxInput">
               <VoiceRadiologia
                  value={value}
                  onChange={(e) => onChangeValue(e)}
                  className="flex-1"
               />
            </div>

         </div>


      </div>
   );
};

export default OpcRayosXTR;
