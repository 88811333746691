import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import pencil from "../../../Images/roles/pencil.svg";
import React, { useState } from "react";

const CreateRolModal = ({
   visibleCreate,
   setVisibleCreate,
   rolCreate,
   getRoles,
}) => {
   const [rol, setRol] = useState({ id: 0, nombre: "" });

   const handleCreateRol = () => {
      rolCreate(rol);
      getRoles();
      setVisibleCreate(false);
      setRol({ id: 0, nombre: "" })
   };
   // Crear
   const paqueteCreateDialogFooter = (
      <div className="flex justify-content-center gap-2">
         <Button
            label="Cancelar"
            style={{
               backgroundColor: "#FFFFFF",
               color: "black",
               borderColor: "#CBC9C9",
               width: "-webkit-fill-available",
               margin: "0"
            }}
            onClick={() => {
               setVisibleCreate(false);
            }}
         />
         <Button
            label="Crear"
            style={{
               backgroundColor: "#5555D8",
               width: "-webkit-fill-available",
               margin: "0"
            }}
            onClick={() => {
               handleCreateRol();
            }}
         />
      </div>
   );

   const hideDialogCreate = () => {
      setVisibleCreate(false);
      setRol({ id: 0, nombre: "" })
   };
   const handleChangeNombre = (e) => {
      setRol({ ...rol, nombre: e.target.value });
   };

   return (
      <>
         <Dialog
            visible={visibleCreate}
            style={{ width: "407px", height: "432px" }}
            header={
               <div>
                  <img className="pencil1  bg-indigo-100 border-round-lg p-2 mb-2" src={pencil} alt="pencil" />
                  <p style={{ color: "#5555D8" }} className="mb-3">Crear nuevo Rol</p>
               </div>
            }
            modal
            className="p-fluid"
            footer={paqueteCreateDialogFooter}
            onHide={() => {
               hideDialogCreate();
            }}
            draggable={false}
         >
            <div className="flex flex-column gap-4">
               <label htmlFor="razon_social" className=" text-gray-500">
                  En esta sección usted puede crear un nuevo rol.
               </label>
               <InputText
                  
                  id="razon_social"
                  placeholder="Colocar rol"
                  type="text"
                  value={rol.nombre}
                  onChange={(e) => handleChangeNombre(e)}
                  required
                  sle="off"
               />
            </div>
         </Dialog>
      </>
   );
};

export default CreateRolModal;
