import React from "react";
import "../../../Style/Botones.css";
import Botones from "../../../Components/Pure/MenuPrincipal/botones";
import Imagenes from "../../../Components/Pure/MenuPrincipal/Imagenes";
import Eclipses from "../../../Components/Pure/MenuPrincipal/Eclipses";
import Business from "../../../Images/Pure/MenuPrincipal/Img/Super.png";
import Icono1 from "../../../Images/Pure/MenuPrincipal/img-icons/con1.png";
import Icono2 from "../../../Images/Pure/MenuPrincipal/img-icons/con2.png";
import Icono3 from "../../../Images/Pure/MenuPrincipal/img-icons/con3.png";
import Icono4 from "../../../Images/Pure/MenuPrincipal/img-icons/star.png";
import Icono5 from "../../../Images/Pure/MenuPrincipal/img-icons/con4.png";

function PageRecursosHumanosB({ accesoProp }) {
   console.log("accesos en prop", accesoProp);
   const list_images = (url) => {
      switch (url) {
         case "locales":
            return Icono1;
         case "roles":
            return Icono2;
         case "personal":
            return Icono3;
         case "reclutamiento":
            return Icono4;
         case "evaluadores":
            return Icono4;
         default:
            return Icono4;
      }
   };
   return (
      <div className="flex flex-nowrap flex-column w-full h-full">
         <div className="arriba">
            <span className="tit">Recursos Humanos</span>
            <br />
            <span className="con">
               En este módulo usted podrá realizar la gestión de recursos
               humanos de Bregma.
            </span>
         </div>
         <div className="gap-3 w-full flex flex-nowrap flex-column justify-content-center h-full">
            {

               accesoProp
                  .filter(acceso => acceso.url !== "puestotrabajo" && acceso.url !== "reclutamiento")
                  .map((acceso, key) => (
                     // Comprueba si el botón actual no es "Reclutamiento" antes de renderizarlo
                     acceso.url !== "reclutamiento" && (
                        <Botones
                           key={key}
                           Link={acceso.url}
                           img={list_images(acceso.url)}
                           span={acceso.label}
                           button={"button b" + (key + 1)}
                        />
                     )
                  ))

               /*accesoProp.map((acceso, key) => {
                  return (
                     <>
                        <Botones
                           key={key}
                           Link={acceso.url}
                           img={list_images(acceso.url)}
                           span={acceso.label}
                           button={"button b" + (key + 1)}
                        />
                     </>
                  );
               })*/

            }

            <div className="logo-container">
               <Imagenes src={Business} className="logo3" />
            </div>

            <Eclipses />
         </div>
      </div>
   );
}

export default PageRecursosHumanosB;