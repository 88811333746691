import React, { useContext, useEffect, useRef, useState } from 'react';
import { TabPanel, TabView } from "primereact/tabview";
import { LaboratorioContext } from "./LaboratorioProvider";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconLab } from "../../Services/importIcons";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Hemograma from './Hemograma/Hemograma';
import TablaGlucosa from '../../Components/Laboratorio/Glucosa/glucosa';
import ExamenCompletoDeOrina from '../../Components/Laboratorio/ExamenCompletoDeOrina';
import ViewColesterol from '../../Components/Laboratorio/Colesterol/ViewColesterol';
import ViewTriglicerido from '../../Components/Laboratorio/Trigliceridos/ViewTriglicerido';
import ViewColesterolHDL from '../../Components/Laboratorio/ColesterolHDL/ViewColesterolHDL';
import ViewColesterolLDL from '../../Components/Laboratorio/ColesterolLDL/ViewColesterolLDL';
import ViewColesterolVLDL from '../../Components/Laboratorio/ColesterolVLDL/ViewColesterolVLDL';
import ViewPerfilLipidico from '../../Components/Laboratorio/PerfilLipídico/ViewPerfilLipídico';
import ViewUrea from '../../Components/Laboratorio/Urea/ViewUrea'
import ViewCreatinina from '../../Components/Laboratorio/Creatinina/ViewCreatinina';
import ViewPerfilHepatico from '../../Components/Laboratorio/PerfilHepatico/ViewPerfilHepatico';
import ViewBillirubinaTotal from '../../Components/Laboratorio/PerfilHepatico/ViewBillirubinaTotal';
import ViewBillirubinaDirecta from '../../Components/Laboratorio/PerfilHepatico/ViewBillirubinaDirecta';
import ViewBillirubinaIndirecta from '../../Components/Laboratorio/PerfilHepatico/ViewBillirubinaIndirecta';
import ViewProteinasTotales from '../../Components/Laboratorio/PerfilHepatico/ViewProteinasTotales';
import ViewAlbumina from '../../Components/Laboratorio/PerfilHepatico/ViewAlbumina';
import ViewGlobulina from '../../Components/Laboratorio/PerfilHepatico/ViewGlobulina';
import ViewFostfatasaAlcalina from '../../Components/Laboratorio/PerfilHepatico/ViewFosfatasaAlcalina';
import ViewTGO from '../../Components/Laboratorio/TGO/ViewTGO';
import ViewTGP from '../../Components/Laboratorio/TGP/ViewTGP';
import ViewAcidoUrico from '../../Components/Laboratorio/AcidoUrico/VIewAcidoUrico';
import ViewGGTP from '../../Components/Laboratorio/GGTP/ViewGGTP';
import ViewCocaina from '../../Components/Laboratorio/Toxicologia/ExamCocaina/ViewCocaina'
import ViewMarihuana from '../../Components/Laboratorio/Toxicologia/ViewMarihuana/ViewMarihuana';
import ViewPlaquetas from '../../Components/Laboratorio/TablaLaboratorio/ViewPlaquetas';
import ViewHematocrito from '../../Components/Laboratorio/TablaLaboratorio/ViewHematocrito';
import ViewHemoglobina from '../../Components/Laboratorio/TablaLaboratorio/ViewHemoglobina';
import ViewHepatitisCIGM from '../../Page/Laboratorio/Inmunobioquimica/Hepatitis/ViewHepatitisCIGM';
import ViewHepatitisAIGM from '../../Page/Laboratorio/Inmunobioquimica/Hepatitis/ViewHepatitisAIGM';
import ViewHepatitisCIGG from '../../Page/Laboratorio/Inmunobioquimica/Hepatitis/ViewHepatitisCIGG';
import ViewHepatitisAIGG from '../../Page/Laboratorio/Inmunobioquimica/Hepatitis/ViewHepatitisAIGG';
import ViewHepatitisB from '../../Page/Laboratorio/Inmunobioquimica/Hepatitis/ViewHepatitisB';
import ViewRPR from '../../Page/Laboratorio/Inmunobioquimica/ViewRPR';
import ViewVDRL from '../../Page/Laboratorio/Inmunobioquimica/ViewVDRL';
import ViewVIH from '../../Page/Laboratorio/Inmunobioquimica/ViewVIH';
import ViewPSA from '../../Page/Laboratorio/Inmunobioquimica/ViewPSA';
import ViewAfetamina from '../../Components/Laboratorio/Toxicologia/ViewAfetamina';
import ViewBenzodiazepina from '../../Components/Laboratorio/Toxicologia/ViewBenzodiazepina';
import ViewExtasis from '../../Components/Laboratorio/Toxicologia/ViewExtasis';
import ViewCoprocultivo from '../../Components/Laboratorio/Microbiologia/ViewCoprocultivo';
import ViewBKEsputo from '../../Components/Laboratorio/Microbiologia/ViewBKEsputo';

import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
import TablaLaborat from '../../Components/Laboratorio/TablaLaboratorio/TablaLaborat';
import TablaVPH from '../../Components/Laboratorio/TablaLaboratorio/tablaVPH';
import { RAContext } from "../RutaAtencion/RAContext";
import AuthUser from '../../AuthUser';
import { useParams } from 'react-router';
import { Toast } from "primereact/toast";
import ModalCancelarAtencion from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion';
import ModalGuardarAtencion from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion';
import BottomsFootComponent from '../../Components/Clinica/AtencionMedica/pure/BottomsFoot';
import ViewProteinas from '../../Components/Laboratorio/Proteinas/Proteinas';
import TablaCovid from '../../Components/Laboratorio/TablaLaboratorio/TablaCovid';
import ViewFactorRH from './FactorRH/ViewFactorRH';

const PageLaboratorio = ({ revision }) => {
   const { http } = AuthUser();
   const { idPaciente } = useParams();
   const toast = useRef(null);

   const { atencionStatus, setExamenesContext, setAtencionStatus, setTimeStart } = useContext(LaboratorioContext);
   let { stateAtencion, laboratorioDetalleId, setLaboratorioDetalleId, laboratorioAreasContext, setStateAtencion, pageViewContext } = useContext(RAContext);

   const [visibleImprimir, setVisibleImprimir] = useState(false)
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [visibleCancelar, setVisibleCancelar] = useState(false);

   const [activeIndex, setActiveIndex] = useState(0);
   const [idLaboratorio, setIdLaboratorio] = useState(null);
   const [estado, setEstado] = useState(false)
   const [documentos, setDocumentos] = useState([])

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };




   //INICIAR ATENCION
   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setLaboratorioDetalleId({
               id: laboratorioDetalleId.id,
               estado: 1,
               hora_inicio: laboratorioDetalleId.hora_inicio,
               fecha_inicio: laboratorioDetalleId.fecha_inicio,
               hora_fin: laboratorioDetalleId.hora_fin,
               fecha_fin: laboratorioDetalleId.fecha_fin,
            })
         })
   }
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setLaboratorioDetalleId({
               id: laboratorioDetalleId.id,
               estado: 2,
               hora_inicio: laboratorioDetalleId.hora_inicio,
               fecha_inicio: laboratorioDetalleId.fecha_inicio,
               hora_fin: laboratorioDetalleId.hora_fin,
               fecha_fin: laboratorioDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setLaboratorioDetalleId({
                  id: laboratorioDetalleId.id,
                  estado: 2,
                  hora_inicio: laboratorioDetalleId.hora_inicio,
                  fecha_inicio: laboratorioDetalleId.fecha_inicio,
                  hora_fin: laboratorioDetalleId.hora_fin,
                  fecha_fin: laboratorioDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }
   //CREAR EL ID DE TRIAJE
   const laboratorioCreate =async () => {

      await http
         .post("laboratorio/create", { paciente_id: idPaciente })
         .then((response) => {
            console.log("los")
            if (response.data.resp) {
               setIdLaboratorio(response.data.laboratorio_id);
               setExamenesContext(laboratorioAreasContext.map(area => {
                  let auxArea = {
                     examen_id: area.id,
                     pruebas: area.hijos.map(hijo => {
                        return {
                           examen_id: hijo,
                           estado: 0,
                           perfil: 0,
                           cantidad_parametros: 0,
                           parametros: {},
                           archivo: null
                        }
                     })
                  }
                  return auxArea
               }))
               showToast('success', 'Éxito', response.data.resp);
            } else {
               showToast('error', 'Error', response.data.error);
            }

         }).catch((error) => {
            console.log(error);
            showToast('error', 'Error', error.message);
         });
   }

   //obtener la información del triaje del paciente
   const getPacienteLaboratorio = async () => {
      console.log("entro a get Laboratorio")
      try {
         const response = await http.get(`laboratorio/get/${idPaciente}`);
         console.log("entro a get Laboratorio-2")
         if (response.data.resp) {
            setIdLaboratorio(response.data.resp.id);
            const datas = response.data.resp.lab_tipos;
            console.log("labora",laboratorioAreasContext)
            console.log("datas",datas)
            if(laboratorioAreasContext.length>0){
               
               let exams = laboratorioAreasContext.map((area) => {
                  let auxArea = {};
                  let auxData = datas.find((data) => data?.examen_id === area?.id);
                  
                  console.log("esta es aux", auxData)
                  if (auxData) {
                     auxArea = {
                        examen_id: area.id,
                        pruebas: area.hijos.map((hijo) => {
                           let auxPrueba = auxData.lab_pruebas.find((prueba) => prueba.examen_id === hijo);
                           if (auxPrueba) {
                              return {
                                 examen_id: hijo,
                                 estado: auxPrueba?.resultado?.estado,
                                 perfil: auxPrueba?.resultado?.perfil,
                                 cantidad_parametros: auxPrueba?.resultado?.cantidad_parametros,
                                 parametros: auxPrueba?.resultado?.parametros,
                                 archivo: auxPrueba.archivo ? auxPrueba.archivo.replace("/app", "") : "",
                              };
                           } else {
                              return {
                                 examen_id: hijo,
                                 estado: 0,
                                 perfil: 0,
                                 cantidad_parametros: 0,
                                 parametros: null,
                                 archivo: null,
                              };
                           }
                        }),
                     };
                  } else {
                     auxArea = {
                        examen_id: area.id,
                        pruebas: area.hijos.map((hijo) => {
                           return {
                              examen_id: hijo,
                              estado: 0,
                              perfil: 0,
                              cantidad_parametros: 0,
                              parametros: null,
                              archivo: null,
                           };
                        }),
                     };
                  }
                  return auxArea;
               })
               console.log("examen",exams)
               setExamenesContext(exams);

            }
            
            // Otros procesamientos o acciones después de obtener los datos
         }
      } catch (error) {
         console.error("Error al obtener datos del paciente:", error);
         // Puedes manejar el error de alguna manera, por ejemplo, mostrando un mensaje al usuario
      }
   };

   const renderTabs = (area, hijos) => {
      const optionMapping = {
         6: (
            <TabPanel header="Anatomía patológica" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  <AccordionTab header="">

                  </AccordionTab>
               </Accordion>
            </TabPanel>
         ),
         7: (
            <TabPanel header="Biología molecular" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 8) &&
                     <AccordionTab header="Covid-19" >
                        <TablaCovid tipoExamen={7} tipoPrueba={8} idLaboratorio={idLaboratorio} indice={0} showToast={showToast} />
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 9) &&
                     <AccordionTab header="VPH">
                        <TablaVPH tipoExamen={7} tipoPrueba={9} idLaboratorio={idLaboratorio} showToast={showToast} />
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 10) &&
                     <AccordionTab header="Coronavirus SARS-COV-2 molecular">
                        <TablaCovid tipoExamen={7} tipoPrueba={10} idLaboratorio={idLaboratorio} indice={2} showToast={showToast}/>
                     </AccordionTab>
                  }
               </Accordion>

            </TabPanel>
         ),
         11: (
            <TabPanel header="Bioquímica">
               <Accordion multiple className='Acordioon_Antecerentes'>
                  {hijos.find((elemento) => elemento === 12) &&
                     <AccordionTab header="Glucosa">
                        <TablaGlucosa tipoExamen={11} tipoPrueba={12} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {/* {hijos.find((elemento) => elemento === 20) &&
                     <AccordionTab header="Perfil Lipídico">
                        <ViewPerfilLipidico tipoExamen={11} tipoPrueba={13} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  } */}
                  {hijos.find((elemento) => elemento === 14) &&
                     <AccordionTab header="Colesterol">
                        <ViewColesterol tipoExamen={11} tipoPrueba={14} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 15) &&
                     <AccordionTab header="Trigliceridos">
                        <ViewTriglicerido tipoExamen={11} tipoPrueba={15} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 16) &&
                     <AccordionTab header="Colesterol HDL">
                        <ViewColesterolHDL tipoExamen={11} tipoPrueba={16} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 17) &&
                     <AccordionTab header="Colesterol LDL">
                        <ViewColesterolLDL tipoExamen={11} tipoPrueba={17} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 18) &&
                     <AccordionTab header="Colesterol VLDL">
                        <ViewColesterolVLDL tipoExamen={11} tipoPrueba={18} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 19) &&
                     <AccordionTab header="Urea">
                        <ViewUrea tipoExamen={11} tipoPrueba={19} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 20) &&
                     <AccordionTab header="Creatinina">
                        <ViewCreatinina tipoExamen={11} tipoPrueba={20} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 21) &&
                     <AccordionTab header="Perfil Hepático">
                        <ViewPerfilHepatico tipoExamen={11} tipoPrueba={21} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 22) &&
                     <AccordionTab header="Billirubina total">
                        <ViewBillirubinaTotal tipoExamen={11} tipoPrueba={22} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 23) &&
                     <AccordionTab header="Billirubina directa">
                        <ViewBillirubinaDirecta tipoExamen={11} tipoPrueba={23} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 24) &&
                     <AccordionTab header="Billirubina indirecta">
                        <ViewBillirubinaIndirecta tipoExamen={11} tipoPrueba={24} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 25) &&
                     <AccordionTab header="Proteinas totales">
                        <ViewProteinasTotales tipoExamen={11} tipoPrueba={25} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 26) &&
                     <AccordionTab header="Albumina">
                        <ViewAlbumina tipoExamen={11} tipoPrueba={26} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 27) &&
                     <AccordionTab header="Globulina">
                        <ViewGlobulina tipoExamen={11} tipoPrueba={27} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 28) &&
                     <AccordionTab header="Fostfatasa Alcalina">
                        <ViewFostfatasaAlcalina tipoExamen={11} tipoPrueba={28} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 29) &&
                     <AccordionTab header="TGO - Transaminasa oxalacético (AST)">
                        <ViewTGO tipoExamen={11} tipoPrueba={29} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 30) &&
                     <AccordionTab header="TGP - Transaminasa piruvico (ALT)">
                        <ViewTGP tipoExamen={11} tipoPrueba={30} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 31) &&
                     <AccordionTab header="Ácido Úrico">
                        <ViewAcidoUrico tipoExamen={11} tipoPrueba={31} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 32) &&
                     <AccordionTab header="Proteínas totales y fraccionales">
                        <ViewProteinas tipoExamen={11} tipoPrueba={32} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 33) &&
                     <AccordionTab header="GGTP  - Gamma glutamil transpeptidasa">
                        <ViewGGTP tipoExamen={11} tipoPrueba={33} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }

               </Accordion>
            </TabPanel>
         ),
         34: (
            <TabPanel header="Coagulación" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 35) &&
                     <AccordionTab header="Tiempo de Coagulación">
                        <TablaLaborat tipoExamen={34} tipoPrueba={35} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 36) &&
                     <AccordionTab header="Tiempo de Sangria">
                        <></>
                     </AccordionTab>
                  }
               </Accordion>
            </TabPanel>
         ),
         37: (
            <TabPanel header="Hematología" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 38) &&
                     <AccordionTab header="Hemograma">
                        {/* <TablaLaborat /> */}
                        <Hemograma tipoExamen={37} tipoPrueba={38} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 39) &&
                     // <AccordionTab header="Velocidad de sedimentación (VSG)">
                     //    <></>
                     // </AccordionTab>
                     <AccordionTab header="Grupo sanguíneo y Factor Rh">
                        <ViewFactorRH tipoExamen={37} tipoPrueba={39} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 40) &&
                     <AccordionTab header="Hemoglobina">
                        <ViewHemoglobina tipoExamen={37} tipoPrueba={40} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 41) &&
                     <AccordionTab header="Hematocrito">
                        <ViewHematocrito tipoExamen={37} tipoPrueba={41} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 42) &&
                     <AccordionTab header="Plaquetas">
                        <ViewPlaquetas tipoExamen={37} tipoPrueba={42} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
               </Accordion>
            </TabPanel>
         ),
         43: (
            <TabPanel header="Inmunobioquímica" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 44) &&
                     <AccordionTab header="Hepatitis C - IGM ">
                        <ViewHepatitisCIGM tipoExamen={43} tipoPrueba={44} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 45) &&
                     <AccordionTab header="Hepatitis C - IGG ">
                        <ViewHepatitisCIGG tipoExamen={43} tipoPrueba={45} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 46) &&
                     <AccordionTab header="Hepatitis A - IGM ">
                        <ViewHepatitisAIGM tipoExamen={43} tipoPrueba={46} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 47) &&
                     <AccordionTab header="Hepatitis A - IGG ">
                        <ViewHepatitisAIGG tipoExamen={43} tipoPrueba={47} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 48) &&
                     <AccordionTab header="Hepatitis B">
                        <ViewHepatitisB tipoExamen={43} tipoPrueba={48} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 49) &&
                     <AccordionTab header="RPR">
                        <ViewRPR tipoExamen={43} tipoPrueba={49} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 50) &&
                     <AccordionTab header="VDRL">
                        <ViewVDRL tipoExamen={43} tipoPrueba={50} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 51) &&
                     <AccordionTab header="VIH">
                        <ViewVIH tipoExamen={43} tipoPrueba={51} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 52) &&
                     <AccordionTab header="PSA (Antigeno Prostático Especifico)">
                        <ViewPSA tipoExamen={43} tipoPrueba={52} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
               </Accordion>
            </TabPanel>
         ),
         53: (
            <TabPanel header="Microbiología" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 54) &&
                     <AccordionTab header="Coprocultivo">
                        <ViewCoprocultivo tipoExamen={53} tipoPrueba={54} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 55) &&
                     <AccordionTab header="BK en esputo">
                        <ViewBKEsputo tipoExamen={53} tipoPrueba={55} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
               </Accordion>

            </TabPanel>
         ),
         56: (
            <TabPanel header="Urianálisis" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 57) &&
                     <AccordionTab header="Examen completo de orina">
                        <ExamenCompletoDeOrina tipoExamen={56} tipoPrueba={57} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
               </Accordion>
            </TabPanel>
         ),
         58: (
            <TabPanel header="Genética" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 59) &&
                     <AccordionTab header="">

                     </AccordionTab>
                  }
               </Accordion>
            </TabPanel>
         ),
         59: (
            <TabPanel header="Toxicología" className='Acordioon_Antecerentes'>
               <Accordion multiple>
                  {hijos.find((elemento) => elemento === 60) &&
                     <AccordionTab header="Marihuana">
                        <ViewMarihuana tipoExamen={59} tipoPrueba={60} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 61) &&
                     <AccordionTab header="Cocaína">
                        <ViewCocaina tipoExamen={59} tipoPrueba={61} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 62) &&
                     <AccordionTab header="Anfetamina">
                        <ViewAfetamina tipoExamen={59} tipoPrueba={62} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 63) &&
                     <AccordionTab header="Benzodiacepina">
                        <ViewBenzodiazepina tipoExamen={59} tipoPrueba={63} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
                  {hijos.find((elemento) => elemento === 64) &&
                     <AccordionTab header="Éxtasis">
                        <ViewExtasis tipoExamen={59} tipoPrueba={64} idLaboratorio={idLaboratorio} showToast={showToast}/>
                     </AccordionTab>
                  }
               </Accordion>
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };
   function handleSubmit() {
      // createPesoTalla(pesTalContext)
      // createSignosVitales(sigVitContext)
      // createAntecedentesOcupacionales(AntOcupContext)
      showToast(
         "success",
         "Guardado correctamente",
         "Datos del paciente en triaje guardados"
      )
   }


   const handleGetBD = () => {
      showToast('success', 'Borrado correctamente', "Datos borrados correctamente");
      // getAntOcup();
      // getPestall();
      // getSigVit();
   }


   useEffect(() => {
      const fetchData = async () => {
         try {
            await getPacienteLaboratorio();
            getDocumentos()
            // Otras acciones después de que getPacienteLaboratorio haya completado
         } catch (error) {
            console.error('Error al obtener datos del paciente:', error);
         } finally {
            // Independientemente de si hay un error o no, establece loading en falso

         }
      };

      fetchData();

      // Dependencias vacías para ejecutar el efecto solo una vez al montar el componente
   }, [laboratorioAreasContext]);

   // useEffect(() => {
   //    getPacienteLaboratorio();
   //    getDocumentos();
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [laboratorioAreasContext])

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               //areas
               laboratorioAreasContext?.map(area => {
                  return renderTabs(area.id, area.hijos)
               })
            }
         </TabView>
         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
            btnSave={false}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
         />
         <ModalCancelarAtencion
            visible={visibleCancelar}
            Atencion="Laboratorio"
            onNo={() => setVisibleCancelar(false)}
            onYes={() => { handleGetBD(); setVisibleCancelar(false) }}
            onHide={() => setVisibleCancelar(false)}
         />
         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="Laboratorio"
            onNo={() => setVisibleGuardar(false)}
            onYes={() => { handleSubmit(); setVisibleGuardar(false) }}
            onHide={() => setVisibleGuardar(false)}
         />

         {laboratorioDetalleId.estado === '0' && (
            <RATimeView
               title="Laboratorio"
               AreaDetalleId={laboratorioDetalleId?.id}
               icon={iconLab}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={laboratorioCreate}
               textButton='Iniciar atención'

            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title="Laboratorio"
               AreaDetalleId={laboratorioDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar revisión'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={laboratorioDetalleId?.hora_final}
               VerHoraInicio={laboratorioDetalleId?.hora_inicio}
               VerfechaInicio={laboratorioDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}

         {estado && revision !== 1 && (
            <RATimeView
               title="Laboratorio"
               AreaDetalleId={laboratorioDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={laboratorioDetalleId?.hora_final}
               VerHoraInicio={laboratorioDetalleId?.hora_inicio}
               VerfechaInicio={laboratorioDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PageLaboratorio;