import React, { useContext, useEffect, useState } from 'react';
import InputCalendar from "../../form/InputCalendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { PsicologiaContext } from "../../../Page/Psicologia/PsicologiaContext";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


const ModalPsiExpLabEditar = ({ visibleEdit, setVisibleEdit, selectIndice, editarExperiencia }) => {

  let { expLabContext } = useContext(PsicologiaContext)

  //Varible de estado
  const [experiencia, setExperiencia] = useState(null)

  //Opciones del dropdown
  let trabajadorOptions = [
    { label: 'Superficie', id: 1 },
    { label: 'Subsuelo', id: 2 }
  ]

      //Funcion para transformar el date del calendar a string(YY/MM/DD)
    const formatDate = (dateValue, clave) => {
        if (dateValue instanceof Date) {
          const year = dateValue.getFullYear().toString();
          const month = (dateValue.getMonth() + 1).toString().padStart(2, '0');
          const day = dateValue.getDate().toString().padStart(2, '0');
          setExperiencia({...experiencia, [clave]:`${year}-${month}-${day}`});
        }
    };

    //Funcion para transformar un string(YY/MM/DD) a date, soporta "/" y "-"

    const parseYYMMDDDate = (dateString) => {
        if (!dateString) return null;
        let parts = dateString.split('/');

        if (parts.length !== 3) parts = dateString.split('-');
          
        if (parts.length === 3) {
            const year = parts[0];
            const month = parts[1] - 1;
            const day = parts[2];
            return new Date(year, month, day);
        }
    };

  //funciones para agregar el campo de fecha a la experiencia

  const handleFechaIngresoChange = (e) => {
    let Fecha = e?.value.toLocaleDateString('es-PE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const ordenFecha= Fecha.split('/')
    let fecha=ordenFecha[2]+'/'+ordenFecha[1]+'/'+ordenFecha[0];
    setExperiencia({...experiencia, fecha_ingreso: fecha, fecha_ingreso_date: e.value})
  };

  const handleFechaSalidaChange = (e) => {
    let Fecha = e?.value.toLocaleDateString('es-PE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const ordenFecha= Fecha.split('/')
    let fecha=ordenFecha[2]+'/'+ordenFecha[1]+'/'+ordenFecha[0];
    setExperiencia({...experiencia, fecha_salida: fecha, fecha_salida_date: e.value})
  };

  //Cuerpo del footer del dialog
  const dialogFooter = (
    <div className="flex justify-content-end mt-4 gap-2">
      <Button
        type="button"
        label="Cancelar"
        className="delete"
        onClick={() => {
          setVisibleEdit(false);
        }}
      />
      <Button
        label="Actualizar" 
        onClick={() => editarExperiencia(experiencia)} 
      />
    </div>
  )

  //useEffect para obtener el dato seleccionado de la tabla segun su indice
  useEffect(() => {
    let selectData = expLabContext[selectIndice]
    console.log(selectData)
    setExperiencia(selectData)
  }, [selectIndice])

  return (

    <Dialog
      visible={visibleEdit}
      style={{ width: '400px', margin: '20px' }}
      onHide={() => setVisibleEdit(false)}
      header={<div style={{ marginBottom: "15px" }}> Editar Experiencia Laboral</div>}
      footer={dialogFooter}>

      <div className='flex flex-wrap gap-5'>

        <div className="flex flex-wrap justify-content-between gap-3 w-full">
          <div className='flex flex-1 flex-wrap justify-content-between align-items-center gap-2'>
            <label className='texto w-12 exp-laboral-txt'>Fecha ingreso</label>
            <InputCalendar className="exp-laboral-calendar" value={parseYYMMDDDate(experiencia?.fecha_ingreso)} onChange={e => formatDate(e.value, 'fecha_ingreso')} />
          </div>
          <div className='flex flex-1 flex-wrap justify-content-between align-items-center gap-2'>
            <label className='texto w-12 exp-laboral-txt'>Fecha retiro</label>
            <InputCalendar className="exp-laboral-calendar" value={parseYYMMDDDate(experiencia?.fecha_salida)} onChange={e => formatDate(e.value, 'fecha_salida')} />
          </div>
        </div>
        <div className="w-12  flex flex-column gap-1 ">
          <div className="flex">
            <label className='texto exp-laboral-txt'>Nombre de la empresa</label>
            </div>
          <InputText value={experiencia?.nombre_empresa || ''} onChange={(e) => setExperiencia({...experiencia, nombre_empresa: e.target.value})} />
        </div>
        <div className="w-12  flex flex-column gap-1">
          <div className="flex">
            <label className='texto exp-laboral-txt'>Act. Empresa</label>
          </div>
          <InputText onChange={e => setExperiencia({...experiencia, act_empresa: e.target.value})} value={experiencia?.act_empresa || ''} />
        </div>
        <div className="pel_grid_responsive flex flex-wrap flex-row">
          <div className="flex flex-1">
            <div >
              <label className='texto exp-laboral-txt'>Puesto</label>
            </div>
            <InputText value={experiencia?.puesto || ''} onChange={e => setExperiencia({...experiencia, puesto: e.target.value})}/>
          </div>
          <div className="flex flex-1">
            <div >
              <label className='texto exp-laboral-txt'>Labor trabajo</label>
              </div>
            <Dropdown value={trabajadorOptions.find(opcion => opcion?.label === experiencia?.labor_trabajo)} onChange={e => setExperiencia({...experiencia, labor_trabajo: e.value.label})} options={trabajadorOptions} />
          </div>
        </div>
        <div className="w-12 flex flex-column gap-1">
          <div className="flex">
            <label className='texto exp-laboral-txt'>Causa de retiro</label>
            </div>
          <InputText onChange={e => setExperiencia({...experiencia, causa_retiro: e.target.value})}  value={experiencia?.causa_retiro || ''} />
        </div>

      </div>

    </Dialog>
  );
};

export default ModalPsiExpLabEditar;