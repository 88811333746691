import React from 'react'

function PageProductosE() {
return (
    <div>
        <div>
            <span className='tit'>Productos</span><br/>
            <span className='con'>Agregar contenido aqui</span>
        </div>
        <div></div>
    </div>
)
}

export default PageProductosE