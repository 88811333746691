import React, { useContext, useEffect, useState } from 'react';
import AuthUser from "../../AuthUser";
import RAAtencionContainer from "../../Components/Container/RAAtencionContainer";
import RAAtencionTabNavigatorHistorial from "../../Components/RutaAtencion/Atencion/RAAtencionTabNavigatorHistorial";
import RAAtencionFormViewHistorial from "../../Components/RutaAtencion/Atencion/RAAtencionFormViewHistorial";
import { RAContextHistorial } from "../../Page/RutaAtencion/RAContextHistorial";
import { useParams } from 'react-router-dom';

function HCNavigator() {

   const { idPaciente } = useParams();
  //  const [nombre, setNombre] = useState("");
  //  const [dni, setDNI] = useState("");
  //  const [perfil, setPerfil] = useState(""); // Estado para el perfil
  //  const [id, setId] = useState("");
  //  const [date, setDate] = useState(null);
  //  const [checkedFecha, setCheckedFecha] = useState(false);
  //  const [visibleCrear, setVisibleCrear] = useState(false);
  //  const [checked, setChecked] = useState(false);

   let rowDataJson = localStorage.getItem("rowData");
   let rowData = JSON.parse(rowDataJson);
   console.log("Data: ",rowData)

  let jsonTabs = [
    { id: 0, nombre: 'Triaje'},
    { id: 1, nombre: 'Psicología'},
    { id: 2, nombre: 'EKG'},
    { id: 3, nombre: 'Oftalmología'},
    { id: 4, nombre: 'Audiometría'},
    { id: 5, nombre: 'Radiología'},
    { id: 6, nombre: 'Laboratorio'},
    { id: 7, nombre: 'Odontología'},
    { id: 8, nombre: 'Espirometria'},
    { id: 9, nombre: 'Medicina General'}

  ]
   return (
    <>
       <main>
       <RAAtencionContainer 
          tabs={<RAAtencionTabNavigatorHistorial arraytabNavigate={jsonTabs} />}
          form={<RAAtencionFormViewHistorial datosPaciente={rowData}/>}
       />
       </main>
    </>
 );
}

export default HCNavigator;
