import React, { useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import AuthUser from '../../../../AuthUser';
import AtencionContainer from '../../../../Components/Clinica/GestionAtencion/Revision/components/AtencionContainer';
import AtencionTabNavigator from '../../../../Components/Clinica/GestionAtencion/Revision/components/AtencionTabNavigator';
import { GestionContext } from '../services/GestionContext';
import { RAContext } from '../../../RutaAtencion/RAContext';
import AtencionFormView from '../../../../Components/Clinica/GestionAtencion/Revision/components/AtencionFormView';

export default function PageRevision() {
   const accesos = (JSON.parse(sessionStorage.getItem("accesos")));
   const { http } = AuthUser();
   const { idPaciente } = useParams()

   let { setPacienteContext,
      setTriajeAreasContext, setTriajeDetalleId,
      setPsicologiaAreasContext, setPsicologiaDetalleId,
      setEkgAreasContext, setEkgDetalleId,
      setOftalmologiaAreasContext, setOftalmologiaDetalleId,
      setAudiometriaAreasContext, setAudiometriaDetalleId,
      setRadiologiaAreasContext, setRadiologiaDetalleId,
      setEspirometriaAreasContext, setEspirometriaDetalleId,
      setMedicinaGeneralAreasContext, setMedicinaGeneralDetalleId,
      setLaboratorioAreasContext, setLaboratorioDetalleId,
   } = useContext(RAContext)


   const servicios = [
      { id: 0, nombre: 'Triaje' },
      { id: 1, nombre: 'Psicología' },
      { id: 2, nombre: 'EKG' },
      { id: 3, nombre: 'Oftalmología' },
      { id: 4, nombre: 'Audiometría' },
      { id: 5, nombre: 'Radiología' },
      { id: 7, nombre: 'Odontología' },
      { id: 8, nombre: 'Espirometría' },
      { id: 6, nombre: 'Laboratorio' },
      { id: 9, nombre: 'Medicina General' }
   ]
   console.log("RESPUESTA FINAL", accesos)
   const jsonTabs = servicios?.filter((servicio) =>
      accesos?.some((acceso) => acceso.label === servicio.nombre)
   )



   const getPaciente =async () => {
      http.get(`clinica/paciente/get/${idPaciente}`).then((response) => {
         console.log("pacienteee", response?.data);
         const areas = response?.data?.hoja_ruta?.hoja_ruta_detalle;
         console.log("esta_areas",areas)
         setContextLaboratorio(areas, setLaboratorioAreasContext, setLaboratorioDetalleId, 5);
         setPacienteContext(response?.data?.paciente);
         setContextAreas(areas, setTriajeAreasContext, setTriajeDetalleId, 1);
         setContextAreas(areas, setPsicologiaAreasContext, setPsicologiaDetalleId, 5);
         setContextAreas(areas, setEkgAreasContext, setEkgDetalleId, 48);
         setContextAreas(areas, setOftalmologiaAreasContext, setOftalmologiaDetalleId, 49);
         setContextAreas(areas, setAudiometriaAreasContext, setAudiometriaDetalleId, 59);
         setContextExamenes(areas, setRadiologiaAreasContext, setRadiologiaDetalleId, 1);
         setContextAreas(areas, setMedicinaGeneralAreasContext, setMedicinaGeneralDetalleId, 67);
         setContextAreas(areas, setEspirometriaAreasContext, setEspirometriaDetalleId, 64);
         
      })
   }

   const setContextAreas = (areas, setAreaContext, setAreaDetalleId, idAreaMedica) => {
      let servicios = areas.find((hojaRuta) => hojaRuta?.areamedicas?.id === idAreaMedica);
      if (servicios !== undefined) {
         setAreaDetalleId({
            id: servicios.id,
            estado: servicios.estado_ruta,
            hora_inicio: servicios.hora_inicio,
            fecha_inicio: servicios.fecha_inicio,
            hora_fin: servicios.hora_fin,
            fecha_fin: servicios.fecha_fin,
         })

         const servicios_id = servicios?.children.length === 0 ? [servicios?.areamedicas?.id] : servicios?.children?.map((servicio) => servicio?.areamedicas?.id);
         console.log("las areas",servicios_id)

         setAreaContext(servicios_id)
      }
   }

   const setContextExamenes = (areas, setExamenContext, setAreaDetalleId, idExamen) => {
      let servicios = areas.find((hojaRuta) => hojaRuta?.examen?.id === idExamen);
      if (servicios !== undefined) {
         setAreaDetalleId({
            id: servicios.id,
            estado: servicios.estado_ruta,
            hora_inicio: servicios.hora_inicio,
            fecha_inicio: servicios.fecha_inicio,
            hora_fin: servicios.hora_fin,
            fecha_fin: servicios.fecha_fin,
         })

         const servicios_id = servicios?.children.length === 0 ? [servicios?.examen?.id] : servicios?.children?.map((servicio) => servicio?.examen?.id);
         console.log("servicios_id", servicios_id);
         setExamenContext(servicios_id)
      }
      console.log("encontro", servicios, idExamen);
   }
   const setContextLaboratorio = (areas, setExamenContext, setAreaDetalleId, idExamen) => {
      console.log("se entro-en-labora")
      let servicios = areas.find((hojaRuta) => hojaRuta?.examen?.id === idExamen);
      if (servicios !== undefined) {
         setAreaDetalleId({
            id: servicios.id,
            estado: servicios.estado_ruta,
            hora_inicio: servicios.hora_inicio,
            fecha_inicio: servicios.fecha_inicio,
            hora_fin: servicios.hora_fin,
            fecha_fin: servicios.fecha_fin,
         })

         const servicios_id = servicios?.children.length === 0 ? [servicios?.examen?.id] : servicios?.children?.map((servicio) => { return { id: servicio?.examen?.id, hijos: servicio?.children?.map(hijo => hijo?.examen?.id) } });
         console.log("se seteo laboratorio",servicios_id)
         setExamenContext(servicios_id)
         
      }

   }

   useEffect(() => {
      getPaciente();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <>
         <AtencionContainer
            tabs={<AtencionTabNavigator arraytabNavigate={jsonTabs} />}
            form={<AtencionFormView />}
         />
      </>
   );
};
