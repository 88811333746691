import React, { useContext, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import PageTriajeAntFam from "./AntecedenteFam/PageTriajeAntFam";
import { TriajeContext } from "./TriajeContext";
import { Accordion, AccordionTab } from 'primereact/accordion';

import "../../Style/Triaje/triaje.css";
import { iconTriaje } from "../../Services/importIcons";
import PageTriajeAntPer from "./AntecedentePer/PageTriajeAntPer";
import PageTriajeSigVit from "./SignosVitales/PageTriajeSigVit";
import PageTriajePesTal from "./PesoTalla/PageTriajePesTal";
import PageTriajeHabNoc from "./HabitosNocivos/PageTriajeHabNoc";
import PageTriajeAntGin from "./AntecedenteGin/PageTriajeAntGin";

import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import ANTECEDENTEOCUPACIONAL from "../MedicinaGeneral/AntecedednteOcupacionales/Antececdente";
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";
import AuthUser from '../../AuthUser';
import { useParams } from "react-router-dom";
const PageTriaje = () => {
   let { sigVitContext, pesTalContext } = useContext(TriajeContext);
   let { AntOcupContext, setAntOcupContext } = useContext(TriajeContext);
   let { antLabContext, setAntLabContext } = useContext(TriajeContext);

   const [imprimirVisible, setImprimirVisible] = useState(false);
   const [activeIndex, setActiveIndex] = useState(0);
   const { atencionStatus, setAtencionStatus } = useContext(TriajeContext);
   const { setTimeStart } = useContext(TriajeContext);
   const [PesTall, setPesTall] = useState([]);
   const [SigVit, setSigVit] = useState([]);
   const [AntOcup, setAntOcup] = useState([]);
   let EnviarDataSigVit = {datos: {...sigVitContext}};
   const [ultimoElemento, setultimoElemento] = useState(null);
   const [IdTriaje, setIdTriaje] = useState(null);
   const { http } = AuthUser();
   const toast = useRef(null);
   const { idPaciente } = useParams();

   const IdPac = { paciente_id: idPaciente }

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };
   useEffect(() => {
      CreateIdTriaje(IdPac);
      GetPesTall();
      GetSigVit();
   }, [])

   const CreateIdTriaje = (data) => {
      http
         .post("triaje/create", data)
         .then((response) => {
            setIdTriaje(response.data.triaje_id);
         })
   }

   function handleSubmit() {
      // CreatePesTall(...pesTalContext)
      // CreateSigVit({...EnviarDataSigVit})
      // CreateAntocup({...AntOcupContext})
      
      console.log("AntOcupContext",AntOcupContext)
   }

   const CreatePesTall = (data) => {
      http
         .post(`triaje/pesotalla/create/${IdTriaje}`, data)
         .then((response) => {
            // setPesTall(response.data.data);
            GetPesTall();
            console.log("CreatePesTall", data)
         })
   }

   const CreateSigVit = (data) => {
      http
         .post(`triaje/signosvitales/create/${IdTriaje}`, data)
         .then((response) => {
            GetSigVit();
            console.log("CreateSigVit", data)
         })
   }

   
   const CreateAntocup = (data) => {
      http
      .post(`triaje/antecedenteocupacional/create/${IdTriaje}`)
      .then((response) => {
         GetAntOcup();
         console.log("CreateSigVit", data)
      })
   }
   const GetAntOcup = () => {
      http
         .get(`triaje/antecedenteocupacional/show`)
         .then((response) => {
            setAntOcup(response?.data?.data)
            console.log("setAntOcup", AntOcup)

         })
   }
   const GetPesTall = () => {
      http
         .get(`triaje/pesotalla/get`)
         .then((response) => {
            setPesTall(response?.data?.data)
            console.log("setPesTall", PesTall)
         })
   }
   const GetSigVit = () => {
      http
         .get(`triaje/signosvitales/get`)
         .then((response) => {
            if(response?.data?.resp){
               setSigVit(response?.data?.data)
               console.log("setSigVit", SigVit)
            }
         })
   }
   return (
      <>
         <Toast ref={toast} />
         {
            <TabView
               activeIndex={activeIndex}
               onTabChange={(e) => setActiveIndex(e.index)}
               scrollable
            >
               <TabPanel header="Signos Vitales">
                  <PageTriajeSigVit SigVit={SigVit} />
               </TabPanel>
               <TabPanel header="Peso y Talla">
                  <PageTriajePesTal PesTall={PesTall} ultimoElemento={ultimoElemento} />
               </TabPanel>
               <TabPanel header="Antecedentes Ocupacionales">
                  <ANTECEDENTEOCUPACIONAL />
               </TabPanel>
            </TabView>
         }
         <div
            style={{
               borderRadius: "10px",
               height: "70px",
               position: "relative",
            }}
            className="flex justify-content-end gap-2 mt-2 bg-white myDiv"
         >
            <button
               onClick={handleSubmit}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-save"> </i>
               <span>Guardar</span>
            </button>
            <button
               style={{ color: "red" }}
               className="p-button  p-component btn-2 flex gap-1"
            >
               <i className="pi pi-times"></i>
               <span>Cancelar</span>
            </button>
            <button
               style={{ color: "black" }}
               onClick={() => setImprimirVisible(true)}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-print"></i>
               <span>Imprimir</span>
            </button>
         </div>
         <ModalImprimir
            imprimirVisible={imprimirVisible}
            setImprimirVisible={setImprimirVisible}
         />

         {/* {atencionStatus === 0 && (
            <RATimeView
               title="Triaje"
               icon={iconTriaje}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
            />
         )} */}
      </>
   );
};

export default PageTriaje;
