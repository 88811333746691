import React, { useContext, useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import AuthUser from "../../AuthUser";
import { MGContext } from "./MGProvider";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
// import { iconEkg, iconLab, iconMedicina } from "../../Services/importIcons";
import { iconMedicina } from "../../Services/importIcons";
import PageExamenFisico from "./ExamenFisico/PageExamenFisico";
import PageEvaluacionMedica from "./EvalucionMedica/PageEvaluacionMedica";
import Osteomioarticular from "./EvaluaciónOsteomioarticular/EvaluaciónOsteomioarticular";
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";
import Anexo16 from "./Anexo16/Anexo16";
import PageAltura from "./ExamendeAltura/PageAltura";
import Esqueleto from "./MusculoEsqueletico";
import TamizajeDermatologico from "./TamizajeDermatologico";
import { EvaluacionNeurologica } from "./EvaluacionNeurologica";
import Pageexploracion from "./Exploracionclinica/pageexploracion";
import PageTriajeHabNoc from "../Triaje/HabitosNocivos/PageTriajeHabNoc";
import PageTriajeAntFam from "../Triaje/AntecedenteFam/PageTriajeAntFam";
import PageTriajeAntPer from "../Triaje/AntecedentePer/PageTriajeAntPer";
import PageTriajeAntGin from "../Triaje/AntecedenteGin/PageTriajeAntGin";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { RAContext } from "../RutaAtencion/RAContext";
import ModalGuardarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion";
import ModalCancelarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion";
import { PruebaEsfuerzoC } from "./PruebaEsfuerzo";
import convertToFormData from "../../Components/Helpers/convertToFormData";
import BottomsFootComponent from "../../Components/Clinica/AtencionMedica/pure/BottomsFoot";

const PageMedicinaGeneral = ({ revision }) => {
   const { http } = AuthUser()
   const { idPaciente } = useParams();
   const toast = useRef(null);

   const {
      habNocContext, setHabNocContext,
      antFamContext, setAntFamContext,
      antPerContext, setAntPerContext,
      antGinContext, setAntGinContext,
      exploracionDataContext, setExploracionDataContext,
      evaluacionMedicaContext, setEvaluacionMedicaContext,
      examenFisicoContext, setExamenFisicoContext,
      evaluacionOsteomioarticularContext, setEvaluacionOsteomioarticularContext,
      anexo16Context, setAnexo16Context,
      examenAlturaContext, setExamenAlturaContext,
      musculoEsqueleticoContext, setMusculoEsqueleticoContext,
      PruebaEsfuerzo, setPruebaEsfuerzo,
      EvNeurologica, setEvNeurologica,
      diagMedicoContext, setDiagMedicoContext,
      setAtencionStatus, setTimeStart, desabilitarPractica, setDesabilitarPractica, selectedPatologiasP, setSelectedPatologiasP,
      selectedPatologiasM, setSelectedPatologiasM,
      selectedPatologiasH, setSelectedPatologiasH,
      selectedPatologiasAP, setSelectedPatologiasAP,
      selectedPatologiasAM, setSelectedPatologiasAM, setInterconsultaContext,
      tamizajeDermatologico, setTamizajeDermatologico
   } = useContext(MGContext)
   let { stateAtencion, setStateAtencion, pageViewContext, medicinaGeneralDetalleId, setMedicinaGeneralDetalleId } = useContext(RAContext);

   const [visibleImprimir, setVisibleImprimir] = useState(false);
   const [estado, setEstado] = useState(false)
   const [VisibleCancelar, setVisibleCancelar] = useState(false);
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [EvMedicaData, setEvMedicaData] = useState([]);
   const [activeIndex, setActiveIndex] = useState(0);
   const [idMedGen, setIdMedGen] = useState(null);
   const [documentos, setDocumentos] = useState([])


   let areas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

   const showToast = (type, title, detail) => {
      if (toast.current) {
         toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
         });
      } else {
         console.error("Toast no inicializado");
      }
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };

   //INICIAR ATENCION
   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      console.log("id", id)
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setMedicinaGeneralDetalleId({
               id: medicinaGeneralDetalleId.id,
               estado: 1,
               hora_inicio: medicinaGeneralDetalleId.hora_inicio,
               fecha_inicio: medicinaGeneralDetalleId.fecha_inicio,
               hora_fin: medicinaGeneralDetalleId.hora_fin,
               fecha_fin: medicinaGeneralDetalleId.fecha_fin,
            })
         })
   }


   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setMedicinaGeneralDetalleId({
               id: medicinaGeneralDetalleId.id,
               estado: 2,
               hora_inicio: medicinaGeneralDetalleId.hora_inicio,
               fecha_inicio: medicinaGeneralDetalleId.fecha_inicio,
               hora_fin: medicinaGeneralDetalleId.hora_fin,
               fecha_fin: medicinaGeneralDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setMedicinaGeneralDetalleId({
                  id: medicinaGeneralDetalleId.id,
                  estado: 2,
                  hora_inicio: medicinaGeneralDetalleId.hora_inicio,
                  fecha_inicio: medicinaGeneralDetalleId.fecha_inicio,
                  hora_fin: medicinaGeneralDetalleId.hora_fin,
                  fecha_fin: medicinaGeneralDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }
   //AREA CREATE

   const createMedicinaGeneral = () => {
      http.post("medicina_general/create", { paciente_id: idPaciente })
         .then((response) => {
            setIdMedGen(response.data.medicinageneral_id)
            showToast(
               "success",
               "MEDICINA GENERAL",
               response.data.resp
            )

         })
         .catch((error) => {
            console.error(error)
         })
   }

   const createHabitos = (datos) => {
      let habito_deporte = []
      datos?.habito_deporte?.map((item) => {
         let deporte = { deporte_id: item }
         habito_deporte.push(deporte)
      })
      let temporal_datos = { ...datos, "habito_deporte": habito_deporte }
      console.log("h", datos)
      http
         .post(`medicina_general/habito_nocivo/create/${idMedGen}`, temporal_datos)
         .then((response) => {
            // setHabNocContext(response.data.data);
            console.log("habitos", datos)
            console.log("Habitos nocivos", response.data);

         })
         .catch((error) => {
            console.log(error);
            console.log("Habitos nocivos", datos);
         });
   };

   const createAntecedenteFamiliar = (datos) => {

      http.post(`medicina_general/antecedente_familiar/create/${idMedGen}`, datos)
         .then((response) => {
            console.log("envio", datos)
            console.log(response)
            showToast("success", "Antecende Familiar", response?.data?.resp?.toUpperCase())
         })
         .catch((error) => {
            showToast("error", "Antecende Familiar", "Hubo un error en el envio")
            console.error(error)
         })
   }

   const createAntecedentePersonal = (datos) => {
      http.post(`medicina_general/antecedente_personal/create/${idMedGen}`, datos)
         .then((response) => {
            console.log("data", datos)
            console.log(response)
            showToast("success", "Antecende Personal", response?.data?.resp?.toUpperCase())
         })
         .catch((error) => {
            showToast("error", "Antecende Personal", "Hubo un error en el envio")
            console.log("data", datos)
            console.error(error)
         })
   }

   const createAntecedenteGinecologico = (datos) => {
      http.post(`medicina_general/antecedente_ginecologico/create/${idMedGen}`, datos)
         .then((response) => {
            console.log(response)
            showToast("success", "Antecende ginecológico", response.data.resp.toUpperCase())
            console.log("sed", datos)
            console.log("sedMed", idMedGen)
         })
         .catch((error) => {
            showToast("error", "Antecende ginecológico", "Hubo un error en el envio")
            console.error(error)
         })
   }

   const createExploracionClinica = (datos) => {
      const formData = new FormData();
      // DESVIACION
      formData.append("desviacion[archivo]", datos?.desviacion?.archivo);
      formData.append("desviacion[eje_anterior][0][patologia_id]", datos?.desviacion?.eje_anterior[0]?.patologia_id);
      formData.append("desviacion[eje_anterior][0][resultado]", datos?.desviacion?.eje_anterior[0]?.resultado.toString());
      formData.append("desviacion[eje_anterior][0][observacion]", datos?.desviacion?.eje_anterior[0]?.observacion);
      formData.append("desviacion[eje_anterior][1][patologia_id]", datos?.desviacion?.eje_anterior[1]?.patologia_id);
      formData.append("desviacion[eje_anterior][1][resultado]", datos?.desviacion?.eje_anterior[1]?.resultado.toString());
      formData.append("desviacion[eje_anterior][1][observacion]", datos?.desviacion?.eje_anterior[1]?.observacion);
      formData.append("desviacion[eje_anterior][2][patologia_id]", datos?.desviacion?.eje_anterior[2]?.patologia_id);
      formData.append("desviacion[eje_anterior][2][resultado]", datos?.desviacion?.eje_anterior[2]?.resultado.toString());
      formData.append("desviacion[eje_anterior][2][observacion]", datos?.desviacion?.eje_anterior[2]?.observacion);
      formData.append("desviacion[eje_lateral][0][patologia_id]", datos?.desviacion?.eje_lateral[0]?.patologia_id);
      formData.append("desviacion[eje_lateral][0][resultado]", datos?.desviacion?.eje_lateral[0]?.resultado.toString());
      formData.append("desviacion[eje_lateral][0][observacion]", datos?.desviacion?.eje_lateral[0]?.observacion);
      formData.append("desviacion[eje_lateral][1][patologia_id]", datos?.desviacion?.eje_lateral[1]?.patologia_id);
      formData.append("desviacion[eje_lateral][1][resultado]", datos?.desviacion?.eje_lateral[1]?.resultado.toString());
      formData.append("desviacion[eje_lateral][1][observacion]", datos?.desviacion?.eje_lateral[1]?.observacion);


      // MOVILIDAD
      formData.append("movilidad[archivo]", datos?.movilidad?.archivo);
      datos.movilidad_patologia.forEach((item, index) => {
         formData.append(`movilidad_patologia[${index}][patologia_id]`, item.patologia_id ? item.patologia_id : "");
         formData.append(`movilidad_patologia[${index}][resultado]`, item.resultado ? item.resultado.toString() : "");
         formData.append(`movilidad_patologia[${index}][observaciones]`, item.observacion ? item.observacion : "");
      });

      // EXPLORACION PALPACION
      datos.med_exploracion_patologias.forEach((item, index) => {
         formData.append(`med_exploracion_patologias[${index}][patologia_id]`, item.patologia_id ? item.patologia_id : null);
         formData.append(`med_exploracion_patologias[${index}][resultado]`, item.resultado.toString());
         formData.append(`med_exploracion_patologias[${index}][observacion]`, item.observacion ? item.observacion : "");
      });
      datos.med_palpacion_patologias.forEach((item, index) => {
         formData.append(`med_palpacion_patologias[${index}][patologia_id]`, item.patologia_id ? item.patologia_id : null);
         formData.append(`med_palpacion_patologias[${index}][resultado]`, item.resultado.toString());
         formData.append(`med_palpacion_patologias[${index}][observacion]`, item.observacion ? item.observacion : "");
      });
      // ARTICULACION
      datos.med_art_movilidad_patologias.forEach((item, index) => {
         formData.append(`med_art_movilidad_patologias[${index}][patologia_id]`, item.patologia_id ? item.patologia_id : null);
         formData.append(`med_art_movilidad_patologias[${index}][resultado]`, item.resultado.toString());
         formData.append(`med_art_movilidad_patologias[${index}][observacion]`, item.observacion ? item.observacion : "");
      });
      datos.med_art_test_patologias.forEach((item, index) => {
         formData.append(`med_art_test_patologias[${index}][patologia_id]`, item.patologia_id ? item.patologia_id : null);
         formData.append(`med_art_test_patologias[${index}][resultado]`, item.resultado.toString());
         formData.append(`med_art_test_patologias[${index}][observacion]`, item.observacion ? item.observacion : "");
      });

      // SISTEMA MOTOR Y COORDINACION
      formData.append(`motor_cordinacion[archivo]`, datos.motor_cordinacion.archivo);
      datos.med_motor_patologias.forEach((item, index) => {
         formData.append(`med_motor_patologias[${index}][patologia_id]`, item.patologia_id ? item.patologia_id : null);
         formData.append(`med_motor_patologias[${index}][resultado]`, item.resultado.toString());
         formData.append(`med_motor_patologias[${index}][observacion]`, item.observacion ? item.observacion : "");
      });
      datos.med_test_coordinacion_patologias.forEach((item, index) => {
         formData.append(`med_test_coordinacion_patologias[${index}][patologia_id]`, item.patologia_id ? item.patologia_id : null);
         formData.append(`med_test_coordinacion_patologias[${index}][resultado]`, item.resultado.toString());
         formData.append(`med_test_coordinacion_patologias[${index}][observacion]`, item.observacion ? item.observacion : "");
      });

      // ANAMNESIS DIAGNOSTICO
      formData.append(`levanta`, datos.levanta);
      formData.append(`empuja`, datos.empuja);
      formData.append(`arrastra`, datos.arrastra);
      formData.append(`med_anamnesis[puesto]`, datos.med_anamnesis.puesto);
      formData.append(`med_anamnesis[sedente]`, datos.med_anamnesis.sedente);
      formData.append(`med_anamnesis[bipedestacion]`, datos.med_anamnesis.bipedestacion);
      formData.append(`med_anamnesis[peso_inf]`, datos.med_anamnesis.peso_inf);
      formData.append(`med_anamnesis[peso_sub]`, datos.med_anamnesis.peso_sub);
      formData.append(`med_anamnesis[movimiento_manual_carga]`, datos.med_anamnesis.movimiento_manual_carga);
      formData.append(`med_anamnesis[movimiento_repetitivo]`, datos.med_anamnesis.movimiento_repetitivo);
      formData.append(`med_anamnesis[postura_forzada]`, datos.med_anamnesis.postura_forzada);
      formData.append(`med_anamnesis[pvd]`, datos.med_anamnesis.pvd);
      formData.append(`med_anamnesis[horas]`, `${datos.med_anamnesis.horas}:00:00`);
      formData.append(`med_anamnesis[dominancia]`, datos.med_anamnesis.dominancia);
      formData.append(`med_anamnesis[diagnosticos]`, datos.med_anamnesis.diagnostico);
      formData.append(`med_anamnesis[recomendaciones]`, datos.med_anamnesis.recomendaciones);
      formData.append(`med_anamnesis[conclusiones]`, datos.med_anamnesis.conclusiones);



      http.post(`medicina_general/exploracion_clinica/create/${idMedGen}`, formData)
         .then((response) => {
            console.log("error", formData)
            showToast("success", "Exploración Clínica", response.data.resp.toUpperCase())
         })
         .catch((error) => {
            showToast("error", "Exploración Clínica", "Hubo un error en el envio")
            console.error(error)
         })
   }

   const createEvaluacionMedica = (datos) => {
      // setEvaluacionMedicaContext(prevState => ({
      //    ...prevState,
      //    med_dx_conclusion: {
      //       ...prevState.med_dx_conclusion,
      //       med_lista_general_diagnostico: diagMedicoContext
      //    }
      // }));
      http
         .post(`medicina_general/evaluacion_medica/create/${idMedGen}`, datos)
         .then(response => {
         })
   }

   const createExamenFisico = (datos) => {
      console.log("datos_exploracion_clinica", datos)

      const dataPrepare = convertToFormData(datos)
      http
         .post(`medicina_general/examen_fisico/create/${idMedGen}`, dataPrepare)
         .then((response) => {
            console.log("si se pudo siiiii", response)
         })
         .catch((error) => {
            console.log("no se pudo", error)
            showToast('error', 'Error', error.message);
         })
   };
   //funcion que pre
   const PrepareDataForExamenFisico = (data) => {
      const dataEF = new FormData()
      Object.keys(data?.med_detalle).map(item => {
         console.log("datos-i ", item)
         dataEF.append(`med_detalle[${item}]`, data?.med_detalle?.[item])
      })
      Object.keys(data?.med_examen_detalle).map((item, index) => {
         console.log("datos-i ", data?.med_examen_detalle?.[index])
         dataEF.append(`med_examen_detalle[${index}][patologia_id]`, data?.med_examen_detalle?.[index]?.patologia_id)
         dataEF.append(`med_examen_detalle[${index}][resultado]`, data?.med_examen_detalle?.[index]?.resultado)
         dataEF.append(`med_examen_detalle[${index}][observaciones]`, data?.med_examen_detalle?.[index]?.observaciones)
      })
      dataEF.append(`med_examen[anamnesis]`, data?.med_examen?.anamnesis)
      return dataEF
   }

   const createOsteomioarticular = (datos) => {
      console.log("datosssss", datos);
      // Crear un nuevo FormData
      const formData = new FormData();
      formData.append('observaciones', datos.observaciones);
      console.log("arcccccccc", typeof datos.archivo);
      formData.append('archivo', datos.archivo);

      // Iterar sobre el array med_osteomioarticular_detalle y agregar cada elemento al formData
      datos.med_osteomioarticular_detalle.forEach((detalle, index) => {
         formData.append(`med_osteomioarticular_detalle[${index}][id]`, detalle.id);
         formData.append(`med_osteomioarticular_detalle[${index}][med_osteomioarticular_id]`, detalle.med_osteomioarticular_id);
         formData.append(`med_osteomioarticular_detalle[${index}][parte_cuerpo]`, detalle.parte_cuerpo);
         formData.append(`med_osteomioarticular_detalle[${index}][meses]`, detalle.meses);
         formData.append(`med_osteomioarticular_detalle[${index}][incapacidad_casa]`, detalle.incapacidad_casa);
         formData.append(`med_osteomioarticular_detalle[${index}][incapacidad_trabajo]`, detalle.incapacidad_trabajo);
      });

      http
         .post(`medicina_general/osteomioarticular/create/${idMedGen}`, formData)
         .then((response) => {
         })
         .catch((error) => {
            console.log(error);
            showToast(
               "error",
               "Evaluación Osteomioarticular",
               "No se ha creado una evaluación"
            )
         })
   }
   const createAnexo = (datos) => {
      http.post(`medicina_general/anexo_16/create/${idMedGen}`, datos)
         .then((response) => {
            console.log("ID", response.data)
         })
         .catch((error) => {
            console.log(error)
            showToast(
               "error",
               "Evaluación Osteomioarticular",
               "No se ha creado una evaluación"
            )
         })
   }

   const createExamenAltura = (datos) => {
      http
         .post(`medicina_general/examen_altura/create/${idMedGen}`, datos)
         .then((response) => {
            console.log("si se pudo siiiii", response);
         })
         .catch((error) => {
            console.log("no se pudo", error)
            showToast('error', 'Error', error.message);
         })
   }

   const createMusculoEsqueletico = (datos) => {
      const formData = new FormData();
      //med_flexibilidad
      formData.append("med_flexibilidad[archivo]", datos?.med_flexibilidad?.archivo ? datos?.med_flexibilidad?.archivo.replace("/app", "") : "");
      //med_flexibilidad --> med_fuerza_abdomen
      formData.append("med_flexibilidad[med_fuerza_abdomen][id]", datos?.med_flexibilidad?.med_fuerza_abdomen?.id);
      formData.append("med_flexibilidad[med_fuerza_abdomen][med_musculo_flexibilidad_id]", datos?.med_flexibilidad?.med_fuerza_abdomen?.med_musculo_flexibilidad_id);
      formData.append("med_flexibilidad[med_fuerza_abdomen][opcion]", datos?.med_flexibilidad?.med_fuerza_abdomen?.opcion);
      formData.append("med_flexibilidad[med_fuerza_abdomen][observacion]", datos?.med_flexibilidad?.med_fuerza_abdomen?.observacion);
      formData.append("med_flexibilidad[med_fuerza_abdomen][estado_registro]", datos?.med_flexibilidad?.med_fuerza_abdomen?.estado_registro);

      //med_flexibilidad --> med_cadera
      formData.append("med_flexibilidad[med_cadera][id]", datos?.med_flexibilidad?.med_cadera?.id);
      formData.append("med_flexibilidad[med_cadera][med_musculo_flexibilidad_id]", datos?.med_flexibilidad?.med_cadera?.med_musculo_flexibilidad_id);
      formData.append("med_flexibilidad[med_cadera][opcion]", datos?.med_flexibilidad?.med_cadera?.opcion);
      formData.append("med_flexibilidad[med_cadera][observacion]", datos?.med_flexibilidad?.med_cadera?.observacion);
      //med_flexibilidad --> med_abdomen_lateral
      formData.append("med_flexibilidad[med_abdomen_lateral][id]", datos?.med_flexibilidad?.med_abdomen_lateral?.id);
      formData.append("med_flexibilidad[med_abdomen_lateral][med_musculo_flexibilidad_id]", datos?.med_flexibilidad?.med_abdomen_lateral?.med_musculo_flexibilidad_id);
      formData.append("med_flexibilidad[med_abdomen_lateral][opcion]", datos?.med_flexibilidad?.med_abdomen_lateral?.opcion);
      formData.append("med_flexibilidad[med_abdomen_lateral][observacion]", datos?.med_flexibilidad?.med_abdomen_lateral?.observacion);
      //med_flexibilidad --> med_muslo
      formData.append("med_flexibilidad[med_muslo][opcion]", datos?.med_flexibilidad?.med_muslo?.opcion);
      formData.append("med_flexibilidad[med_muslo][observacion]", datos?.med_flexibilidad?.med_muslo?.observacion);

      formData.append("med_flexibilidad[observacion]", datos?.med_flexibilidad?.observacion);
      formData.append("med_flexibilidad[nombre]", datos?.med_flexibilidad?.nombre);
      formData.append("med_flexibilidad[file_name]", datos?.med_flexibilidad?.file_name);
      formData.append("med_flexibilidad[file_url]", datos?.med_flexibilidad?.file_url);

      //med_rotación
      formData.append("med_rotacion[archivo]", datos?.med_rotacion?.archivo ? datos?.med_rotacion?.archivo.replace("/app", "") : "");
      //med_rotación --> med_abduccion_hombro
      formData.append("med_rotacion[med_abduccion_hombro][id]", datos?.med_rotacion?.med_abduccion_hombro?.id);
      formData.append("med_rotacion[med_abduccion_hombro][med_musculo_rotacion_id]", datos?.med_rotacion?.med_abduccion_hombro?.med_musculo_rotacion_id);
      formData.append("med_rotacion[med_abduccion_hombro][opcion]", datos?.med_rotacion?.med_abduccion_hombro?.opcion);
      formData.append("med_rotacion[med_abduccion_hombro][observacion]", datos?.med_rotacion?.med_abduccion_hombro?.observacion);
      formData.append("med_rotacion[med_abduccion_hombro][dolor]", datos?.med_rotacion?.med_abduccion_hombro?.dolor);
      //med_rotación --> med_abduccion_hombros
      formData.append("med_rotacion[med_abduccion_hombros][id]", datos?.med_rotacion?.med_abduccion_hombros?.id);
      formData.append("med_rotacion[med_abduccion_hombros][med_musculo_rotacion_id]", datos?.med_rotacion?.med_abduccion_hombros?.med_musculo_rotacion_id);
      formData.append("med_rotacion[med_abduccion_hombros][opcion]", datos?.med_rotacion?.med_abduccion_hombros?.opcion);
      formData.append("med_rotacion[med_abduccion_hombros][observacion]", datos?.med_rotacion?.med_abduccion_hombros?.observacion);
      formData.append("med_rotacion[med_abduccion_hombros][dolor]", datos?.med_rotacion?.med_abduccion_hombros?.dolor);
      //med_rotación --> med_rotacion_externa
      formData.append("med_rotacion[med_rotacion_externa][id]", datos?.med_rotacion?.med_rotacion_externa?.id);
      formData.append("med_rotacion[med_rotacion_externa][med_musculo_rotacion_id]", datos?.med_rotacion?.med_rotacion_externa?.med_musculo_rotacion_id);
      formData.append("med_rotacion[med_rotacion_externa][opcion]", datos?.med_rotacion?.med_rotacion_externa?.opcion);
      formData.append("med_rotacion[med_rotacion_externa][observacion]", datos?.med_rotacion?.med_rotacion_externa?.observacion);
      formData.append("med_rotacion[med_rotacion_externa][dolor]", datos?.med_rotacion?.med_rotacion_externa?.dolor);
      //med_rotación --> med_rotacion_externa_hombro
      formData.append("med_rotacion[med_rotacion_externa_hombro][id]", datos?.med_rotacion?.med_rotacion_externa_hombro?.id);
      formData.append("med_rotacion[med_rotacion_externa_hombro][med_musculo_rotacion_id]", datos?.med_rotacion?.med_rotacion_externa_hombro?.med_musculo_rotacion_id);
      formData.append("med_rotacion[med_rotacion_externa_hombro][opcion]", datos?.med_rotacion?.med_rotacion_externa_hombro?.opcion);
      formData.append("med_rotacion[med_rotacion_externa_hombro][observacion]", datos?.med_rotacion?.med_rotacion_externa_hombro?.observacion);
      formData.append("med_rotacion[med_rotacion_externa_hombro][dolor]", datos?.med_rotacion?.med_rotacion_externa_hombro?.dolor);

      formData.append("med_rotacion[observacion]", datos?.med_rotacion?.observacion);
      formData.append("med_rotacion[nombre]", datos?.med_rotacion?.nombre);
      formData.append("med_rotacion[file_name]", datos?.med_rotacion?.file_name);
      formData.append("med_rotacion[file_url]", datos?.med_rotacion?.file_url);

      http
         .post(`medicina_general/musculo_esqueletico/create/${idMedGen}`, formData)
         .then((response) => {
            console.log("Envio POST MUSCULO", ...formData);
            showToast('success', 'Músculo Esquelético', 'Enviado Correctamente');
         })
         .catch((error) => {
            console.log("no se pudo", error)
            showToast('error', 'Músculo Esquelético ', error.message);
         })
   };

   const createTamizajeDermatologico = (datos) => {
      const formData = new FormData();

      formData.append('tam_detalles[sintomas_hallazgo]', datos.tam_detalles.sintomas_hallazgo);
      formData.append('tam_detalles[observaciones]', datos.tam_detalles.observaciones);
      formData.append('tam_detalles[recomendaciones]', datos.tam_detalles.recomendaciones);
      formData.append('tam_detalles[conclusiones]', datos.tam_detalles.conclusiones);
      formData.append('tam_detalles[fecha]', datos.tam_detalles.fecha);
      formData.append('tam_detalles[evaluador_id]', datos.tam_detalles.evaluador_id);
      formData.append('tam_detalles[resultado_id]', datos.tam_detalles.resultado_id);
      formData.append('tam_detalles[archivo]', datos.tam_detalles.archivo ? datos.tam_detalles.archivo.replace("/app", "") : "");

      //Conversión a arreglo
      const arrayTamPreguntas = Object.values(datos.tam_preguntas);

      arrayTamPreguntas.forEach((pregunta, index) => {
         formData.append(`tam_preguntas[${index}][med_tam_derm_preguntas_id]`, pregunta.med_tam_derm_preguntas_id);
         formData.append(`tam_preguntas[${index}][respuesta]`, pregunta.respuesta);
         formData.append(`tam_preguntas[${index}][descripcion]`, pregunta.descripcion);
      });

      http
         .post(`medicina_general/tamizaje_dermatologico/create/${idMedGen}`, formData)
         .then((response) => {
            console.log("Tamizaje Dermatológico", response);
            showToast("success", "Tamizaje Dermatológico", 'Enviado Correctamente');
         })
         .catch((error) => {
            showToast('error', 'Tamizaje Dermatológico', error.message);
         })
   }

   // Evaluación neurológica crear

   const createEvaluacionNeurologica = (data) => {
      const formData = new FormData()
      formData.append("nistagmus_espontaneo", data?.nistagmus_espontaneo)
      formData.append("nistagmus_posicion", data?.nistagmus_posicion)
      formData.append("nistagmus_agitacion", data?.nistagmus_agitacion)
      formData.append("prueba_romberg", data?.prueba_romberg)
      formData.append("posturografia_dinamica", data?.posturografia_dinamica)
      formData.append("posturografia_estatica", data?.posturografia_estatica)
      formData.append("observaciones", data?.observaciones)
      formData.append("archivo", data?.archivo ? data?.archivo.replace("/app", "") : "")
      http
         .post(`medicina_general/evaluacion-neurologica/create/${idMedGen}`, formData)
         .then((response) => {
            console.log("E_Neurologica si se pudo", response);
            showToast("success", "Evaluación Neurológica", 'Enviado Correctamente');
         })
         .catch((error) => {
            console.log("E_Neurologica no se pudo", error)
            showToast('error', 'Evaluación Neurológica', error.message);
         })
   }

   //AREA GET

   const getPacienteMedinicaGeneral = () => {
      http
         .get(`medicina_general/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setIdMedGen(response?.data?.resp?.id)
               getHabito();
               getAntecedenteFamiliar();
               getAntecedentePersonal();
               getAntecedenteGinecologico();
               getExploracionClinica();
               getEvaluacionMedica();
               getExamenFisico();
               getOsteomioarticular();
               getAnexo();
               getExamenAltura();
               getMusculoEsqueletico();
               getPruebaEsfuerzo();
               getTamizajeDermatologico();
               getEvaluacionNeurologica();
            }
         })
         .catch((error) => {
            console.log(error)
         })
   }
   const getPruebaEsfuerzo = () => {
      http
         .get(`medicina_general/esfuerzo/get/${idPaciente}`)
         .then((response) => {
            console.log("Esfuerzo: ", response.data.Resp)
            setPruebaEsfuerzo(
               {
                  sintomas_hallazgos: response?.data?.Resp?.sintomas_hallazgos,
                  observaciones: response.data.Resp.observaciones,
                  recomendaciones: response.data.Resp.recomendaciones,
                  conclusiones: response.data.Resp.conclusiones,
                  fecha: response.data.Resp.fecha,
                  evaluador_id: response.data.Resp.evaluador_id,
                  evaluador_nombre: "",
                  evaluador_colegiatura: "",
                  resultado: "",
                  resultado_id: response.data.Resp.resultado_id,
                  nombre_archivo: "",
                  archivo: response.data.Resp.archivo ? response.data.Resp.archivo.replace("/app", "") : ""
               })
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const getHabito = () => {
      http
         .get(`medicina_general/habito_nocivo/get/${idPaciente}`)
         .then((response) => {

            if (response?.data?.resp) {
               setDataHabitos(response?.data?.resp)

            }
         })
         .catch((error) => {
            console.log(error);
         });
   };
   const setDataHabitos = (data) => {
      let Habilitar = true
      let dataHabitos = {}
      let habito_deporte = data?.habito_deporte
      dataHabitos["frecuencia_id"] = habito_deporte?.frecuencia_id
      dataHabitos["tipo_tiempo_id"] = habito_deporte?.tiempo?.tipo_tiempo_id
      dataHabitos["cantidad"] = habito_deporte?.tiempo?.cantidad
      dataHabitos["practica"] = parseInt(habito_deporte?.practica)
      dataHabitos["medicamento"] = habito_deporte?.medicamento
      dataHabitos["otros"] = habito_deporte?.otros
      dataHabitos["observaciones"] = habito_deporte?.observaciones
      dataHabitos["habito_deporte"] = []
      dataHabitos["habitos"] = [{
         tipo_habito_id: 1,
         nombre: "Alcohol",
         frecuencia: "",
         tiempo: "",
         tipo: "",
         cantidad: ""
      },
      {
         tipo_habito_id: 2,
         nombre: "Tabaco",
         frecuencia: "",
         tiempo: "",
         tipo: "",
         cantidad: "",
      },
      {
         tipo_habito_id: 3,
         nombre: "Droga",
         frecuencia: "",
         tiempo: "",
         tipo: "",
         cantidad: "",
      },
      {
         tipo_habito_id: 4,
         nombre: "Café",
         frecuencia: "",
         tiempo: "",
         tipo: "",
         cantidad: "",
      }]
      habito_deporte?.det_habito_deporte?.forEach(item => {
         console.log("datos", item)
         dataHabitos["habito_deporte"].push(item?.deporte_id)
      })
      data?.habitos?.forEach((item) => {
         for (let i = 0; i < dataHabitos["habitos"].length; i++) {
            if (dataHabitos["habitos"][i]["tipo_habito_id"] === item?.tipo_habito_id) {
               dataHabitos["habitos"][i]["frecuencia"] = item?.frecuencia
               dataHabitos["habitos"][i]["tiempo"] = item?.tiempo
               dataHabitos["habitos"][i]["tipo"] = item?.tipo
               dataHabitos["habitos"][i]["cantidad"] = item?.cantidad
               dataHabitos["habitos"][i]["opcion"] = item?.opcion
            }
         }
      })
      setHabNocContext(dataHabitos)
      switch (parseInt(habito_deporte?.practica)) {
         case 1:
            Habilitar = false

            break;
         case 0:
            Habilitar = true
            break;
         default: Habilitar = false
            break;
      }
      setDesabilitarPractica(Habilitar)
      console.log("datos", dataHabitos)

   }


   const getAntecedenteFamiliar = () => {
      http.get(`medicina_general/antecedente_familiar/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setDataAntecendeteFamiliar(response?.data?.resp)
               // setAntFamContext(response?.data?.resp)
            }
         })
         .catch((error) => {
            showToast("error", "Antecende Familiar", "Hubo un error en el envio")
            console.error(error)
         })
   }
   const setDataAntecendeteFamiliar = (data) => {
      let dataAF = {
         numero_hijos_vivos: "",
         numero_hijos_fallecidos: "",
         comentario: "",
         familiar: []
      }

      dataAF["numero_hijos_vivos"] = data?.numero_hijos_vivos
      dataAF["numero_hijos_fallecidos"] = data?.numero_hijos_fallecidos
      data?.familiar?.map((item) => {
         for (let i = 0; i < dataAF["familiar"].length; i++) {
            if (dataAF["familiar"][i]["tipo_familiar_id"] == item?.tipo_familiar_id) {
               item?.med_familiar_patologia?.map(item => {
                  let patologia = { "patologia_id": item.patologia_id, "comentario": item.comentario }
                  dataAF["familiar"][i]["med_familiar_patologia"].push(patologia)
               })

            }
         }
      })

      // console.log("RESPONSE", antFamContext)
      // let selectedM = dataAF?.familiar?.[1]?.med_familiar_patologia[0]
      // let selectedP = dataAF?.familiar?.[0]?.med_familiar_patologia
      // let selectedAM = dataAF?.familiar?.[4]?.med_familiar_patologia[0]
      // let selectedAP = dataAF?.familiar?.[3]?.med_familiar_patologia[0]
      // let selectedH = dataAF?.familiar?.[2]?.med_familiar_patologia[0]
      console.log("DATA AF", data)
      console.log("DATA AF2", dataAF)
      setAntFamContext(data)
      setSelectedPatologiasM(data?.familiar[1]?.med_familiar_patologia)
      setSelectedPatologiasP(data?.familiar[0]?.med_familiar_patologia)
      setSelectedPatologiasAM(data?.familiar[4]?.med_familiar_patologia)
      setSelectedPatologiasAP(data?.familiar[3]?.med_familiar_patologia)
      setSelectedPatologiasH(data?.familiar[2]?.med_familiar_patologia)
   }

   const getAntecedentePersonal = () => {
      http.get(`medicina_general/antecedente_personal/get/${idPaciente}`)
         .then((response) => {
            if (response.data.resp) {
               setDataAP(response?.data?.resp)
            }
         })
         .catch((error) => {
            //showToast("error", "Antecende personal", "Hubo un error en el envio")
            console.error(error)
         })
   }

   const setDataAP = (data) => {
      let dataAP = {
         inmunizaciones: data?.inmunizaciones,
         inmunizaciones_id: [],
         med_ant_inmunizaciones_personal: [],
         med_ant_patologia_personal: [],
         med_ant_absentismo: [],
      }
      data?.med_ant_inmunizaciones_personal?.forEach((item) => {
         dataAP?.med_ant_inmunizaciones_personal?.push({ "patologia_id": item?.patologia_id, id: item?.patologia_id, comentario: item?.comentario })
      })
      data?.med_ant_patologia_personal?.forEach((item) => {
         dataAP?.med_ant_patologia_personal.push({ "patologia_id": item?.patologia_id, id: item?.patologia_id, comentario: item?.comentario })
      })
      data?.absentismo?.forEach((item) => {
         dataAP?.med_ant_absentismo.push({
            "tipo_absentismo_id": item?.tipo_absentismo_id,
            "asociado_trabajo": item?.asociado_trabajo === 1 ? true : false,
            "descripcion": item?.descripcion,
            "fecha_inicio": item?.fecha_inicio,
            "fechaInicioDate": new Date(item?.fecha_inicio),
            "fecha_final": item?.fecha_final,
            "fechaFinalDate": new Date(item?.fecha_final),
            "dias_incapacidad": item?.dias_incapacidad,
            "menoscabo": item?.menoscabo
         })
      })
      setAntPerContext(dataAP)

   }

   const getAntecedenteGinecologico = () => {
      http.get(`medicina_general/antecedente_ginecologico/get/${idPaciente}`)
         .then((response) => {
            if (response.data.resp) {

               setDataAG(response.data.resp)
            }
         })
         .catch((error) => {
            //showToast("error", "Antecende ginecologico", "Hubo un error en el envio")
            console.error(error)
         })
   }
   const setDataAG = (data) => {
      let fur_date = new Date(data?.fur + 'T00:00:00Z')
      let fup_date = new Date(data?.fup + 'T00:00:00Z')
      let ultimopap_date = new Date(data?.ultimopap + 'T00:00:00Z')
      let m_fecha_date = new Date(data?.mamografia?.fecha + 'T00:00:00Z')
      let c_fecha_date = new Date(data?.gestaciones?.fecha_cesarea + 'T00:00:00Z')

      const dataAG = {
         fur: data?.fur,
         fur_date: fur_date,
         rc: data?.rc,
         fup: data?.fup,
         fup_date: fup_date,
         menarquia: data?.menarquia,
         ultimopap: data?.ultimopap,
         ultimopap_date: ultimopap_date,
         estado: (data?.estado) == "1" ? true : false,
         metodos_anticonceptivos: data?.metodos_anticonceptivos,
         gestaciones: {
            gestaciones: data?.gestaciones?.gestaciones,
            abortos: data?.gestaciones?.abortos,
            partos: data?.gestaciones?.partos,
            p_prematuros: data?.gestaciones?.p_prematuros,
            p_eutacico: data?.gestaciones?.p_eutacico,
            p_distocias: data?.gestaciones?.p_distocias,
            cesareas: data?.gestaciones?.cesareas,
            fecha_cesarea: data?.gestaciones?.fecha_cesarea,
            fecha_cesarea_date: c_fecha_date,
         },
         mamografia: {
            se_hizo: (data?.mamografia?.se_hizo == 1) ? true : false,
            fecha: data?.mamografia?.fecha,
            estado: (data?.mamografia?.estado == 1) ? true : false,
            resultado: data?.mamografia.resultado,
            fecha_date: m_fecha_date,
         }
      }
      console.log("ag", data)
      setAntGinContext(dataAG)
   }

   const getExploracionClinica = () => {
      http.get(`medicina_general/exploracion_clinica/get/${idPaciente}`)
         .then((response) => {
            console.log("Exploracion", response)
            setExploracionDataContext({
               // DESVIACION
               "desviacion": {
                  "archivo": response.data.resp.med_desviacion.archivo ? response.data.resp.med_desviacion.archivo.replace("/app", "") : "",

                  "eje_anterior": response.data.resp.med_desviacion.med_eje_anterior ?
                     response.data.resp.med_desviacion.med_eje_anterior.map((item) => ({
                        patologia_id: item.patologia_id,
                        resultado: item.resultado ? item.resultado : 'N',
                        observacion: item.observacion
                     })) : [],
                  "eje_lateral": response.data.resp.med_desviacion.med_eje_lateral ?
                     response.data.resp.med_desviacion.med_eje_lateral.map((item) => ({
                        "patologia_id": item.patologia_id,
                        "resultado": item.resultado ? item.resultado : 'N',
                        "observacion": item.observacion
                     })) : [],
               },

               // MOVILIDAD
               "movilidad": {
                  "archivo": response.data.resp.med_movilidad.archivo ? response.data.resp.med_movilidad.archivo.replace("/app", "") : "",
               },
               "movilidad_patologia": response.data.resp.med_movilidad.med_movilidad_patologias ?
                  response.data.resp.med_movilidad.med_movilidad_patologias.map((item) => ({
                     "patologia_id": item.patologia_id,
                     "resultado": item.resultado ? item.resultado : 'N',
                     "observacion": item.observaciones,
                  })) : [],


               // EXPLORACION PALPACION
               "med_exploracion_patologias": response.data.resp.med_palpacion.med_pal_exploracion.med_exploracion_patologias ?
                  response.data.resp.med_palpacion.med_pal_exploracion.med_exploracion_patologias.map((item) => ({
                     "patologia_id": item.patologia_id,
                     "resultado": item.resultado ? item.resultado : 'N',
                     "observacion": item.observacion,
                  })) : [],


               "med_palpacion_patologias": response.data.resp.med_palpacion.med_pal_palpacion.med_palpacion_patologias ?
                  response.data.resp.med_palpacion.med_pal_palpacion.med_palpacion_patologias.map((item) => ({
                     "patologia_id": item.patologia_id,
                     "resultado": item.resultado ? item.resultado : 'N',
                     "observacion": item.observacion,
                  })) : [],

               // ARTICULACION

               "med_art_movilidad_patologias": response.data.resp.articulacion.art_movilidad.movilidad_patologia ?
                  response.data.resp.articulacion.art_movilidad.movilidad_patologia.map((item) => ({
                     "patologia_id": item.patologia_id,
                     "resultado": item.resultado ? item.resultado : 'N',
                     "observacion": item.observacion,
                  })) : [],
               "med_art_test_patologias": response.data.resp.articulacion.art_test.test_patologia ?
                  response.data.resp.articulacion.art_test.test_patologia.map((item) => ({
                     "patologia_id": item.patologia_id,
                     "resultado": item.resultado ? item.resultado : 'N',
                     "observacion": item.observacion,
                  })) : [],


               // SISTEMA MOTOR Y COORDINACION
               "motor_cordinacion": {
                  "archivo": response.data.resp.motor_coordinacion.archivo ? response.data.resp.motor_coordinacion.archivo.replace("/app", "") : ""

               },

               "med_motor_patologias": response.data.resp.motor_coordinacion.motor.motor_patologia ?
                  response.data.resp.motor_coordinacion.motor.motor_patologia.map((item) => ({
                     "patologia_id": item.patologia_id,
                     "resultado": item.resultado ? item.resultado : 'N',
                     "observacion": item.observacion,
                  })) : [],

               "med_test_coordinacion_patologias": response.data.resp.motor_coordinacion.art_coordinacion.test_coordinacion_patologia ?
                  response.data.resp.motor_coordinacion.art_coordinacion.test_coordinacion_patologia.map((item) => ({
                     "patologia_id": item.patologia_id,
                     "resultado": item.resultado ? item.resultado : 'N',
                     "observacion": item.observacion,
                  })) : [],

               // ANAMNESIS DIAGNOSTICO
               "med_anamnesis":
               {
                  "puesto": response.data.resp.anamnesis.puesto,
                  "sedente": response.data.resp.anamnesis.sedente,
                  "bipedestacion": response.data.resp.anamnesis.bipedestacion,
                  "peso_inf": response.data.resp.anamnesis.peso_inf,
                  "peso_sub": response.data.resp.anamnesis.peso_sub,
                  "movimiento_manual_carga": response.data.resp.anamnesis.movimiento_manual_carga,
                  "movimiento_repetitivo": response.data.resp.anamnesis.movimiento_repetitivo,
                  "postura_forzada": response.data.resp.anamnesis.postura_forzada,
                  "pvd": response.data.resp.anamnesis.pvd,
                  "horas": response.data.resp.anamnesis.horas.slice(0, -6),
                  "dominancia": response.data.resp.anamnesis.dominancia,
                  "diagnostico": response.data.resp.anamnesis.diagnosticos,
                  "recomendaciones": response.data.resp.anamnesis.recomendaciones,
                  "conclusiones": response.data.resp.anamnesis.conclusiones,
                  "conclusiones_detalles": ""
               }
               ,
               "levanta": response.data.resp.anamnesis.ana_metodo.levanta === "true" ? true : response.data.resp.anamnesis.ana_metodo.levanta === "false" ? false : null,
               "empuja": response.data.resp.anamnesis.ana_metodo.empuja === "true" ? true : response.data.resp.anamnesis.ana_metodo.empuja === "false" ? false : null,
               "arrastra": response.data.resp.anamnesis.ana_metodo.arrastra === "true" ? true : response.data.resp.anamnesis.ana_metodo.arrastra === "false" ? false : null,
            })
         })
         .catch((error) => {
            //showToast("error", "ExploracionClinica", "Hubo un error en el envio")
            console.error(error)
         })
   }

   const getEvaluacionMedica = () => {
      http
         .get(`medicina_general/evaluacion_medica/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setEvaluacionMedicaContext(response.data.resp)
               setDiagMedicoContext(response?.data?.resp?.med_dx_conclusion?.med_lista_general_diagnostico.map((item) => {
                  return ({
                     enfermedad_especifica_id: item.enfermedad_especifica_id,
                     cie: item.enfermedad_especifica.codigo,
                     tipo_diagnostico: item.tipo_diagnostico,
                     ocupacional: item.ocupacional,
                     importante: item.importante,
                     observacion: item.observacion,
                     recomendacion: item.recomendacion,
                  })
               }))
               setInterconsultaContext(response?.data?.resp?.med_interconsultas)
            }
         })
         .catch((error) => {
            console.log(error);
         });
   }

   const getExamenFisico = () => {
      http
         .get(`medicina_general/examen_fisico/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               const data_med_detalle = response?.data?.resp?.med_detalle
               data_med_detalle["url_image"] = response?.data?.resp?.med_detalle?.archivo ? response?.data?.resp?.med_detalle?.archivo.replace("/app", "") : ""
               const partesUrl = response?.data?.resp?.med_detalle?.archivo ? response?.data?.resp?.med_detalle?.archivo?.split("/") : ""
               data_med_detalle["name_file"] = partesUrl ? partesUrl[partesUrl?.length - 1] : ""
               setExamenFisicoContext({
                  med_examen: {
                     anamnesis: response?.data?.resp?.med_examen?.anamnesis
                  },
                  med_examen_detalle: response?.data?.resp?.med_examen?.med_examen_detalle,
                  med_detalle: data_med_detalle
               })
            }
         })
         .catch((error) => {
            console.log("no puede traer", error)
            showToast('error', 'Error', error.message);
         })
   }


   const getOsteomioarticular = () => {
      http
         .get(`medicina_general/osteomioarticular/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setEvaluacionOsteomioarticularContext({
                  observaciones: response?.data?.resp?.observaciones,
                  archivo: response?.data?.resp?.archivo ? response?.data?.resp?.archivo.replace("/app", "") : "",
                  med_osteomioarticular_detalle: response?.data?.resp?.med_osteomioarticular_detalle
               })
            }

         })
         .catch(error => {
            console.error("Error obteniendo datos:", error);
         });
   };

   const getAnexo = () => {
      http
         .get(`medicina_general/anexo_16/get/${idPaciente}`)
         .then((response) => {
            console.log("Anexo", response?.data?.resp)
            if (response?.data?.resp) {
               setAnexo16Context({
                  observaciones: response?.data?.resp.observaciones,
                  uso_medicamentos: response?.data?.resp.uso_medicamentos,
                  aptitud: response?.data?.resp.aptitud,
                  med_anexo_preguntas: response?.data?.resp.med_anexo_pregunta
               })
            }
         })
         .catch(error => {
            console.error("Error obteniendo datos:", error);
         });
   };

   const getExamenAltura = () => {
      http
         .get(`medicina_general/examen_altura/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setExamenAlturaContext({
                  med_personal_patologia: response?.data?.resp?.med_personal_patologia,
                  med_hallazgo_patologia: response?.data?.resp?.med_hallazgo_patologia,
                  med_prueba: response?.data?.resp?.med_prueba,
                  med_conclusion: {
                     observaciones: response?.data?.resp?.med_conclusion?.observaciones,
                     recomendaciones: response?.data?.resp?.med_conclusion?.recomendaciones,
                     datos_medicos: `${response?.data?.resp?.med_conclusion?.evaluador?.nombres} ${response?.data?.resp?.med_conclusion?.evaluador?.apellidos}`,
                     nro_colegiatura: response?.data?.resp?.med_conclusion?.evaluador?.evaluador_tipo_especialista?.cmp,
                     evaluador_id: response?.data?.resp?.med_conclusion?.evaluador_id,
                     med_resultado_id: response?.data?.resp?.med_conclusion?.resultado_id,
                  }
               })
            }
         })
         .catch((error) => {
            console.log("no puede traer", error)
            showToast('error', 'Error', error.message);
         })
   }

   const getMusculoEsqueletico = () => {
      http
         .get(`medicina_general/musculo_esqueletico/get/${idPaciente}`)
         .then((response) => {

            if (response?.data?.resp) {
               console.log("trayendo datos", response.data)
               setMusculoEsqueleticoContext({
                  med_flexibilidad: {
                     archivo: response?.data?.resp?.musculo_flexibilidad?.archivo ? response?.data?.resp?.musculo_flexibilidad?.archivo.replace("/app", "") : "",
                     med_fuerza_abdomen: response?.data?.resp?.musculo_flexibilidad?.fuerza_abdomen,
                     med_cadera: response?.data?.resp?.musculo_flexibilidad?.cadera,
                     med_abdomen_lateral: response?.data?.resp?.musculo_flexibilidad?.abdomen_lateral,
                     med_muslo: response?.data?.resp?.musculo_flexibilidad?.muslo,
                     observacion: response?.data?.resp?.musculo_flexibilidad?.observacion,
                     nombre: response?.data?.resp?.musculo_flexibilidad?.nombre
                  },
                  med_rotacion: {
                     archivo: response?.data?.resp?.musculo_rotacion?.archivo ? response?.data?.resp?.musculo_rotacion?.archivo.replace("/app", "") : "",
                     med_abduccion_hombro: response?.data?.resp?.musculo_rotacion?.abduccion_hombro,
                     med_abduccion_hombros: response?.data?.resp?.musculo_rotacion?.abduccion_hombros,
                     med_rotacion_externa: response?.data?.resp?.musculo_rotacion?.rotacion_externa,
                     med_rotacion_externa_hombro: response?.data?.resp?.musculo_rotacion?.rotacion_externa_hombro,
                     observacion: response?.data?.resp?.musculo_rotacion?.observacion,
                     nombre: response?.data?.resp?.musculo_rotacion?.nombre
                  }
               })
            }
         })
         .catch((error) => {
            console.log("no puede traer", error)
            showToast('error', 'Error', error.message);
         })
   }

   const getTamizajeDermatologico = () => {
      http
         .get(`medicina_general/tamizaje_dermatologico/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               console.log("RESPONSE TAMIZAJE DERMAT", response?.data?.resp)
               setTamizajeDermatologico(response?.data?.resp)
            }
         })
   }

   const createPruebaEsfuerzo = (data) => {
      const formData = new FormData()
      formData.append("sintomas_hallazgos", data?.sintomas_hallazgos)
      formData.append("observaciones", data?.observaciones)
      formData.append("recomendaciones", data?.recomendaciones)
      formData.append("conclusiones", data?.conclusiones)
      formData.append("fecha", data?.fecha)
      formData.append("evaluador_id", data?.evaluador_id)
      formData.append("resultado_id", data?.resultado_id)
      formData.append("archivo", data?.archivo)
      http
         .post(`medicina_general/esfuerzo/create/${idMedGen}`, formData)
         .then((response) => {
            showToast("success", "Prueba de Esfuerzo", 'Enviado Correctamente');
         })
         .catch((error) => {
            console.log("no se pudo, QUE PENA", error)
            showToast('error', 'Prueba de Esfuerzo', error.message);
         })
   }


   // Get de Evaluación Neurológica
   const [archivoUrl, setArchivoUrl] = useState('');
   useEffect(() => {
      // Verifica si ya tienes un archivo cargado en datosContext.archivo
      if (EvNeurologica.archivo) {
         if (typeof EvNeurologica.archivo === 'string') {
            // Si el archivo ya es una URL, simplemente establece la URL
            setArchivoUrl(EvNeurologica.archivo);
         } else {
            // Si el archivo es un objeto (por ejemplo, un File), lee su contenido
            const reader = new FileReader();
            reader.onload = () => {
               setArchivoUrl(reader.result);
            };
            reader.readAsDataURL(EvNeurologica.archivo);
         }
      }
   }, [EvNeurologica.archivo]);

   const getEvaluacionNeurologica = () => {
      http
         .get(`medicina_general/evaluacion-neurologica/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setEvNeurologica({
                  nistagmus_espontaneo: response?.data?.resp.nistagmus_espontaneo,
                  nistagmus_posicion: response?.data?.resp.nistagmus_posicion,
                  nistagmus_agitacion: response?.data?.resp.nistagmus_agitacion,
                  prueba_romberg: response?.data?.resp.prueba_romberg,
                  posturografia_dinamica: response?.data?.resp.posturografia_dinamica,
                  posturografia_estatica: response?.data?.resp.posturografia_estatica,
                  observaciones: response?.data?.resp.observaciones,
                  archivo: response?.data?.resp?.archivo ? response?.data?.resp?.archivo.replace("/app", "") : ""
               })
            }
         })
         .catch(error => {
            console.error("Error obteniendo evaluacionNerologicaa:", error);
         });
   };

   const HandleSubmit = () => {
      console.log("TAMIZAJE DERAMATOLÓGICO", tamizajeDermatologico)
      createHabitos(habNocContext);
      createAntecedenteFamiliar(antFamContext);
      createAntecedentePersonal(antPerContext);
      createAntecedenteGinecologico(antGinContext);
      createExploracionClinica(exploracionDataContext);


      console.log("idPaci", evaluacionMedicaContext)



      createEvaluacionMedica(evaluacionMedicaContext);
      createExamenFisico(examenFisicoContext);
      createOsteomioarticular(evaluacionOsteomioarticularContext);
      createAnexo(anexo16Context);
      createExamenAltura(examenAlturaContext);
      createMusculoEsqueletico(musculoEsqueleticoContext);
      createPruebaEsfuerzo(PruebaEsfuerzo)
      createTamizajeDermatologico(tamizajeDermatologico);
      createEvaluacionNeurologica(EvNeurologica);
      showToast(
         "success",
         "Guardado correctamente",
         "Datos del paciente en medicina general guardados"
      )
   }

   const handleGetBDMedicina = () => {
      getHabito();
      getAntecedenteFamiliar();
      getAntecedentePersonal();
      getAntecedenteGinecologico();
      getExploracionClinica();
      getEvaluacionMedica();
      getExamenFisico();
      getOsteomioarticular();
      getAnexo();
      getExamenAltura();
      getMusculoEsqueletico();
      getTamizajeDermatologico();
      getEvaluacionNeurologica();
      showToast('success', 'Borrado correctamente', "Datos borrados correctamente");
   }

   useEffect(() => {
      getDocumentos();
      getPacienteMedinicaGeneral();
      setDataAntecendeteFamiliar(antFamContext)
   }, [])

   const renderTabs = (area) => {
      const optionMapping = {
         1: (
            <TabPanel header="Hábitos">
               <PageTriajeHabNoc />
            </TabPanel>
         ),
         2: (
            <TabPanel header="Antecedente Familiar">
               <PageTriajeAntFam />
            </TabPanel>
         ),
         3: (
            <TabPanel header="Antecedente Personal">
               <PageTriajeAntPer />
            </TabPanel>
         ),
         4: (
            <TabPanel header="Antecedente Ginecológico">
               <PageTriajeAntGin />
            </TabPanel>
         ),
         5: (
            <TabPanel header="Exploración Clínica">
               <Pageexploracion />
            </TabPanel>
         ),
         6: (
            <TabPanel header="Evaluación Médica">
               <PageEvaluacionMedica EvMedicaData={EvMedicaData} />
            </TabPanel>
         ),
         7: (
            <TabPanel header="Examen Físico">
               <PageExamenFisico />
            </TabPanel>
         ),
         8: (
            <TabPanel header="Evaluación Osteomioarticular">
               <Osteomioarticular />
            </TabPanel>
         ),
         9: (
            <TabPanel header="Anexo 16">
               <Anexo16 />
            </TabPanel>
         ),
         10: (
            <TabPanel header="Examen de Altura">
               <PageAltura />
            </TabPanel>
         ),
         11: (
            <TabPanel header="Músculo Esquelético">
               <Esqueleto />
            </TabPanel>
         ),
         12: (
            <TabPanel header="Prueba de Esfuerzo">
               <PruebaEsfuerzoC />
            </TabPanel>
         ),
         13: (
            <TabPanel header="Tamizaje Dermatológico">
               <TamizajeDermatologico />
            </TabPanel>
         ),
         14: (
            <TabPanel header="Evaluación Neurológica">
               <EvaluacionNeurologica url={archivoUrl} seturl={setArchivoUrl} />
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               areas?.map(area => {
                  return renderTabs(area)
               })
            }
         </TabView>
         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
            showToast={showToast}

         />
         <ModalCancelarAtencion
            visible={VisibleCancelar}
            Atencion="Medicina General"
            onNo={() => setVisibleCancelar(false)}
            onYes={() => { handleGetBDMedicina(); setVisibleCancelar(false) }}
            onHide={() => setVisibleCancelar(false)}
         />

         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="Medicina General"
            onNo={() => setVisibleGuardar(false)}
            onYes={() => { HandleSubmit(); setVisibleGuardar(false) }}
            onHide={() => setVisibleGuardar(false)}
         />

         {medicinaGeneralDetalleId.estado === '0' && (
            <RATimeView
               title="Medicina General"
               AreaDetalleId={medicinaGeneralDetalleId?.id}
               icon={iconMedicina}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={createMedicinaGeneral}
               textButton='Iniciar atención'
            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title="Medicina General"
               AreaDetalleId={medicinaGeneralDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar revisión'
               icon={iconMedicina}
               setTimeStart={setTimeStart}
               VerHoraFin={medicinaGeneralDetalleId?.hora_final}
               VerHoraInicio={medicinaGeneralDetalleId?.hora_inicio}
               VerfechaInicio={medicinaGeneralDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}

         {estado && revision !== 1 && (
            <RATimeView
               title="Medicina General"
               AreaDetalleId={medicinaGeneralDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={iconMedicina}
               setTimeStart={setTimeStart}
               VerHoraFin={medicinaGeneralDetalleId?.hora_final}
               VerHoraInicio={medicinaGeneralDetalleId?.hora_inicio}
               VerfechaInicio={medicinaGeneralDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );

};

export default PageMedicinaGeneral;
