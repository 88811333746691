import React, { useState, useEffect } from "react";
import AuthUser from "../../AuthUser";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
/* import Table from '../../Table/Table'; */
import { Column } from "primereact/column";
/* import ModalGClientes from './ComponentesGClientes/ModalGClientes'; */
import GraficaDona from "../../Page/EmpresaRol/Estadisticas/GraficoDonut";
import GraficoBar from "./GraficoBar";
// import img from "./img/Cerco_E.png";

export default function Estadisticas() {
  const Ndatos = [1, 2, 3];
  const [visibleModal, setVisibleModal] = useState(false);
  const [tipoCliente, setTipoCliente] = useState("");
  const [tituloModal, setTituloModal] = useState("");
  const [locales, setLocales] = useState([]);
  const [cliente, setCliente] = useState(1);
  const { http } = AuthUser();

  /*Grafico de Donas*/
  // no poner 00.00 Descuadra
  const porcentajeCompletado1 = 58;
  const porcentajeCompletado2 = 23;
  const porcentajeCompletado3 = 17;

  //  Segundo grupo
  const porcentajeCompletado01 = 48;
  const porcentajeCompletado02 = 35;
  const porcentajeCompletado03 = 17;

  //  tercero grupo
  const porcentajeCompletado001 = 55;
  const porcentajeCompletado002 = 25;
  const porcentajeCompletado003 = 20;

  //  cuarto grupo
  const porcentajeCompletado0001 = 75;
  const porcentajeCompletado0002 = 10;
  const porcentajeCompletado0003 = 15;

  /*Datos para grafico de barras*/
  const labels = ["Vigentes", "Pendientes", "Vencidos"]

  const chartDatasets = [
    {
      label: ('Vigente', 'pendiente', 'Vencidos'),
      backgroundColor: [
        '#71F260',
        '#6098FF',
        '#EF4444'
      ],
      borderColor: [
        'green',
        'blue',
        'red'
      ],
      data: [58, 23, 17]
    }
  ]
  const chartData2 = [
    {
      label: ('Vigente', 'pendiente', 'Vencidos'),
      backgroundColor: [
        '#71F260',
        '#6098FF',
        '#EF4444'
      ],
      borderColor: [
        'green',
        'blue',
        'red'
      ],
      data: [48, 35, 17]
    }
  ]
  const chartData3 = [
    {
      label: ('Vigente', 'pendiente', 'Vencidos'),
      backgroundColor: [
        '#71F260',
        '#6098FF',
        '#EF4444'
      ],
      borderColor: [
        'green',
        'blue',
        'red'
      ],
      data: [55, 25, 20]
    }
  ]
  const chartData4 = [
    {
      label: ('Vigente', 'pendiente', 'Vencidos'),
      backgroundColor: [
        '#71F260',
        '#6098FF',
        '#EF4444'
      ],
      borderColor: [
        'green',
        'blue',
        'red'
      ],
      data: [75, 10, 15]
    }
  ]
  const titulos = ['EXAMENES MEDICOS OCUPACIONALES', 'ESTADO', 'PORCENTAJE']
  const tituloStyle = ['', '', '']

  /* DATOS ESTATICOS */
  const [datosCorporativo, setDatosCorporativo] = useState([
    {
      id: 1,
      TipoDoc: "RUC",
      NumDoc: 45678901,
      RazonS: "Alicorp",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Locales: "locales",
      Actividad: "Pendiente",
      Detalles: "detalles",
    },
  ]);
  const [datosClinica, setDatosClinica] = useState([
    {
      id: 1,
      TipoDoc: "RUC",
      NumDoc: 45678901,
      RazonS: "Medical Roma",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Locales: "locales",
      Actividad: "En espera",
      Detalles: "detalles",
    },
  ]);
  const [datosParticular, setDatosParticular] = useState([
    {
      id: 1,
      TipoDoc: "DNI",
      NumeroDoc: 45678901,
      Nombres: "Jorge Tito",
      APaterno: "Carranzas",
      AMaterno: "Campo",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Actividad: "Pendiente",
      Detalles: "Detalles",
    },
    {
      id: 2,
      TipoDoc: "DNI",
      NumeroDoc: 45678901,
      Nombres: "Sara",
      APaterno: "Flores",
      AMaterno: "Palma",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Actividad: "Pendiente",
      Detalles: "Detalles",
    },
  ]);
  const [datos, setDatos] = useState(datosCorporativo);

  let vista;
  if (cliente === 0) {
    vista = (
      <div className="flex flex-column aling-items-centern gap-3">
        <div className="grid w-full justify-content-center gap-3 ml-1">
          {/* {Ndatos.map((donut) =>{
            return <GraficaDona porcentaje={porcentajeCompletado} />
          })} */}
          <GraficaDona
            porcentaje={porcentajeCompletado1}
            Vigente="VIGENTE"
            trabajadores="6390"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado2}
            Vigente="PENDIENTES"
            trabajadores="2595"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado3}
            Vigente="VENCIDOS A LA FECHA DE HOY"
            trabajadores="1915"
          />
        </div>

        {/* Estadisticas */}

        <div className=" w-full h-30rem">
          <GraficoBar
            card
            labels={labels}
            datasets={chartDatasets}
            titles={titulos}
            titlesClassName={tituloStyle}
            labelshidden
          />
        </div>
      </div>
    );
  } else if (cliente === 1) {
    vista = (
      <div className="flex flex-column aling-items-centern gap-3">
        <div className="grid w-full justify-content-center gap-3 ml-1">
          {/* {Ndatos.map((donut) =>{
            return <GraficaDona porcentaje={porcentajeCompletado} />
          })} */}
          <GraficaDona
            porcentaje={porcentajeCompletado01}
            Vigente="VIGENTE"
            trabajadores="1680"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado02}
            Vigente="PENDIENTES"
            trabajadores="1225"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado03}
            Vigente="VENCIDOS A LA FECHA DE HOY"
            trabajadores="595"
          />
        </div>

        {/* Estadisticas */}

        <div className=" w-full h-30rem ">
          <GraficoBar
            card
            labels={labels}
            datasets={chartData2}
            titles={titulos}
            titlesClassName={tituloStyle}
            labelshidden
          />
        </div>
      </div>
    );
  } else if (cliente === 2) {
    vista = (
      <div className="flex flex-column aling-items-centern gap-3">
        <div className="grid w-full justify-content-center gap-3 ml-1">
          {/* {Ndatos.map((donut) =>{
            return <GraficaDona porcentaje={porcentajeCompletado} />
          })} */}
          <GraficaDona
            porcentaje={porcentajeCompletado001}
            Vigente="VIGENTE"
            trabajadores="2310"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado002}
            Vigente="PENDIENTES"
            trabajadores="1050"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado003}
            Vigente="VENCIDOS A LA FECHA DE HOY"
            trabajadores="840"
          />
        </div>

        {/* Estadisticas */}

        <div className=" w-full h-30rem">
          <GraficoBar
            card
            labels={labels}
            datasets={chartData3}
            titles={titulos}
            titlesClassName={tituloStyle}
            labelshidden
          />
        </div>
      </div>
    );
  } else if (cliente === 3) {
    vista = (
      <div className="flex flex-column aling-items-centern gap-3">
        <div className="grid w-full justify-content-center gap-3 ml-1">
          {/* {Ndatos.map((donut) =>{
            return <GraficaDona porcentaje={porcentajeCompletado} />
          })} */}
          <GraficaDona
            porcentaje={porcentajeCompletado0001}
            Vigente="VIGENTE"
            trabajadores="2400"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado0002}
            Vigente="PENDIENTES"
            trabajadores="320"
          />
          <GraficaDona
            porcentaje={porcentajeCompletado0003}
            Vigente="VENCIDOS A LA FECHA DE HOY"
            trabajadores="480"
          />
        </div>

        {/* Estadisticas */}

        <div className=" w-full h-30rem ">
          <GraficoBar
            card
            labels={labels}
            datasets={chartData4}
            titles={titulos}
            titlesClassName={tituloStyle}
            labelshidden
          />
        </div>
      </div>
    );
  }

  const saltoLTabla = (L1, L2) => {
    return (
      <span
        className="flex text-center"
        dangerouslySetInnerHTML={{ __html: `${L1}<br>${L2}` }}
      />
    );
  };

  const getLocales = () => {
    http
      .get("empresa/local/show")
      .then((response) => {
        console.log("LOCALES", response?.data?.data)
        setLocales(response?.data?.data)
      })
  }

  useEffect(() => {
    getLocales();
  }, [])

  return (
    <div className="flex flex-1 gap-2 h-full">

      <div

        className="flex flex-column gap-5 bg-white border-round-xl p-3"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <div className="flex gap-2 align-items-center justify-content-center">
          <label htmlFor="" className="font-semibold text-base">
            Lista de Sedes
          </label>
          {/*           <Button
            label=" Reporte"
            className="pi pi-file border-round-xl surface-0 text-green-500 border-green-500"
          /> */}
        </div>

        <div className="flex flex-column gap-2">
          {/* CLIENTE CORPOTARIVO */}
          <div
            className={`flex gap-3 py-2 px-3 align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-purple-300 ${cliente === 1 ? "border-purple-100" : ""
              }`}
            onClick={() => {
              setCliente(0);
              setDatos(datosCorporativo);
            }}
          >
            <i className="pi pi-user text-xl bg-purple-100 border-round-lg p-2 text-purple-500" />
            <div>
              <label htmlFor="" className="text-purple-500 font-bold">
                Global
              </label>
              <p
                className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                style={{ width: "150px" }}
              ></p>
            </div>
            {cliente === 0 ? (
              <i className="pi pi-ellipsis-v text-400 font-semibold" />
            ) : (
              <div className="w-1rem"></div>
            )}
          </div>
          {/* CLIENTE CLINICA */}
          {locales.map((local, index) => (
            <div
              key={local.id}
              className={`flex gap-3 py-2 px-3 align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-${index % 2 === 0 ? "purple" : "blue"
                }-300 ${cliente === index + 1 ? `border-${index === 0 ? "purple" : "blue"}-100` : ""
                }`}
              onClick={() => {
                setCliente(index + 1);
                // Dependiendo del índice, puedes establecer diferentes datos.
                // En este ejemplo, se alterna entre datosCorporativo y datosClinica.
                setDatos(index % 2 === 0 ? datosCorporativo : datosClinica);
              }}
            >
              <i
                className={`pi pi-user text-xl bg-${index === 0 ? "blue" : index === 1 ? "green" : index === 2 ? "yellow" : index % 2 === 0 ? "purple" : "blue"}-100 border-round-lg p-2 text-${index === 0 ? "blue" : index === 1 ? "green" : index === 2 ? "yellow" : index % 2 === 0 ? "purple" : "blue"}-500`}
              />
              <div>
                <label
                  htmlFor=""
                  className={`text-${index === 0 ? "blue" : index === 1 ? "green" : index === 2 ? "yellow" : index % 2 === 0 ? "purple" : "blue"}-500 font-bold`}
                >
                  {local.nombre}
                </label>
                <p
                  className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                  style={{ width: "150px" }}
                ></p>
              </div>
              {cliente === index + 1 ? (
                <i className="pi pi-ellipsis-v text-400 font-semibold" />
              ) : (
                <div className="w-1rem"></div>
              )}
            </div>
          ))}
        </div>

        <div className="flex flex-column flex-1 align-items-center justify-content-center">
          <img
            src="https://cdn.discordapp.com/attachments/1089924612944580719/1143597414264082453/Cerco_E.png"
            alt="imagencita"
            style={{ width: "291px", height: "369px" }}
          />
        </div>
      </div>

      <div className=" flex flex-column border-round-xl tablaGClientes h-screen w-9 aling-items-centern gap-3">
        {vista}
        {/* dunout */}
      </div>
      {/* <ModalGClientes visibleModal={visibleModal} setVisibleModal={setVisibleModal} tituloModal={tituloModal} tipoCliente={tipoCliente}/> */}
    </div >
  );
}
