import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { Dialog } from "primereact/dialog";
import Icon from "../../../../../Images/Icon.png";
import carpetawhite from "../../../../../Images/Icons/Mis_Locales/Areas/carpeta-white.svg";
import AuthUser from "../../../../../AuthUser";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import ModalAgregar from "./ModalAgregar";
import ModalEditarArea from "../../Modals/ModalEditarArea";
import ActionTemplate from "./MenuDesplegable";

const TablaAreas = ({
   areas,
   handleagregarAreasgenerales,
   act,
   id,
   handleGetPersonal,
   source,
   setSource,
   target,
   setTarget,
   agregarPersonal,
   handleEditArea,
   handleDelete,
}) => {
   const { http } = AuthUser();
   const [visible, setVisible] = useState(false);
   const [visible2, setVisible2] = useState(false);
   const [visible3, setVisible3] = useState(false);
   const [visibleEdAr, setvisibleEdAr] = useState(false);
   const [visibleAgPersonal, setvisibleAgPersonal] = useState(false);
   const [rowData, setRowData] = useState();
   const [dataAsignar, setDataAsignar] = useState({});
   const [area, setArea] = useState({
      nombre: "",
      parent_id: null,
   });
   const [showError, setShowError] = useState(false);

   const handleChangeNombre = (e) => {
      setArea({
         ...area,
         nombre: e.target.value,
      });
   };

   const handleChangeNombreSubArea = (e) => {
      setArea({
         parent_id: rowData.data.id,
         nombre: e.target.value,
      });
   };

   const dialogHeader = () => {
      return (
         <div>
            <i
               className="pi pi-trash text-indigo-600 text-lg font-bold"
               style={{ color: "#5555D8" }}
            >
               {" "}
               <label
                  style={{
                     color: "#5555D8",
                     fontStyle: "normal",
                     fontFamily: "Montserrat",
                  }}
               >
                  {" "}
                  Eliminar Área
               </label>
            </i>
         </div>
      );
   };

   const dialogFooter = () => {
      return (
         <div className="flex justify-content-between">
            <Button
               type="button"
               label="Cancelar"
               className="delete w-6"
               onClick={() => {
                  setVisible3(false);
               }}
            />
            <Button
               label="Eliminar"
               className="p-button-success w-6"
               style={{
                  color: "#5555D8",
                  backgroundColor: "#EBEBFF",
                  borderColor: "#5555D8",
                  margin: 0,
               }}
               onClick={() => BotonEliminar()}
            />
         </div>
      );
   };

   const dialogFooterCreate = () => {
      return (
         <div>
            <Button
               label="Cancelar"
               className="flex-1"
               onClick={() => setVisible(false)}
            />
            <Button
               label="Crear"
               className="flex-1"
               onClick={() => {
                  if (area.nombre === '') {
                     setShowError(true);
                  } else {
                     handleagregarAreasgenerales(area);
                     setVisible(false);
                     setArea({ 
                        nombre: "",
                        parent_id: null 
                     });
                     setShowError(false);
                  }
               }}
            />
         </div>
      );
   };

   const dialogTitle = () => {
      return (
         <div className="flex gap-3 mb-1">
            <img
               src={Icon}
               alt=""
               className=" border-round-xl "
               style={{
                  padding: "15px",
                  backgroundColor: " #EBEBFF",
               }}
            />
            <div className="pt-3">
               <p className="text-indigo-600 text-lg font-bold">Crear área</p>
            </div>
         </div>
      );
   };

   const actionTemplate = (rowData) => {
      return (
         <ActionTemplate
            rowData={rowData}
            setVisible2={setVisible2}
            setVisible3={setVisible3}
            setvisibleEdAr={setvisibleEdAr}
            setRowData={setRowData}
         />
      );
   };

   const header = (
      <div className="flex flex-column">
         <div className="flex align-items-baseline justify-content-between">
            <div className="inline-flex gap-2"></div>
            <div className=" inline-flex  gap-2">
               <Button
                  label="Agregar nueva área "
                  icon="pi pi-plus text-indigo-400 text-xl"
                  className="bg-white text-indigo-400"
                  onClick={() => setVisible(true)}
               />
               <Dialog
                  style={{ width: "413px", height: "320px" }}
                  visible={visible}
                  onHide={() => setVisible(false)}
                  header={dialogTitle}
                  footer={dialogFooterCreate}
               >
                  <div className="flex flex-column gap-5 ">
                     <div className=" flex flex-column gap-2">
                        <div className="">
                           <p className="text-400">
                              En esta sección usted podrá registrar las áreas.
                           </p>
                        </div>
                        <div className="">
                           <p className="text-700">Área</p>
                        </div>
                        <div className="">
                           <InputText 
                              defaultValue={area.nombre}
                              className="w-full "
                              onChange={(e) => handleChangeNombre(e)}
                           />
                           {showError && area.nombre === '' && <small className="text-red-500">Ingrese un nombre</small>}
                        </div>
                     </div>
                  </div>
               </Dialog>
               <Dialog
                  style={{ width: "413px", height: "420px" }}
                  visible={visible2}
                  onHide={() => setVisible2(false)}
               >
                  <div className="flex flex-column gap-5 ">
                     <div className=" flex flex-column gap-2">
                        <div>
                           <img
                              src={Icon}
                              alt=""
                              className=" border-round-xl "
                              style={{
                                 padding: "15px",
                                 backgroundColor: " #EBEBFF",
                              }}
                           />
                        </div>
                        <div>
                           <p className="text-indigo-600 text-lg font-bold">
                              Crear nueva sub área
                           </p>
                        </div>
                        <div className="w-full">
                           <p className="text-400">
                              En esta sección usted puede registrar las sub
                              áreas que necesite áreas generales.
                           </p>
                        </div>
                        <div>
                           <p className="text-700">
                              Sub Áreas {rowData?.data?.nombre}
                           </p>
                        </div>
                        <div className=" ">
                           <InputText
                              className="w-full"
                              onChange={(e) => handleChangeNombreSubArea(e)}
                           />
                           {showError && area.nombre === '' && <small className="text-red-500">Ingrese un nombre</small>}
                        </div>
                     </div>
                     <div className=" flex justify-content-evenly gap-2">
                        <Button
                           label="Cancelar"
                           className="flex-1"
                           onClick={() => {
                              setVisible2(false);
                              setArea({
                                 nombre: "",
                                 parent_id: null,
                              });
                           }}
                        />
                        <Button
                           label="Crear"
                           className="flex-1"
                           onClick={() => {
                              if (area.nombre === '') {
                                 setShowError(true);
                              } else {
                                 handleagregarAreasgenerales(area);
                                 setVisible2(false);
                                 setArea({
                                    nombre: "",
                                    parent_id: null,
                                 });
                                 setShowError(false);
                              }
                           }}
                        />
                     </div>
                  </div>
               </Dialog>
               <Dialog
                  header={dialogHeader}
                  footer={dialogFooter}
                  resizable={false}
                  visible={visible3}
                  onHide={() => setVisible3(false)}
                  style={{ height: "200px" }}
               >
                  <div>
                     ¿Estas seguro que quieres eliminarlo?
                  </div>
               </Dialog>
            </div>
         </div>
      </div>
   );

   const botonagregar = (rowData) => {
      return (
         <Button
            className="btn-personal"
            label={
               <div className="flex align-items-center">
                  <img className="carpeta-white" src={carpetawhite} alt="" />
                  <span className="display-n-m">Agregar personal</span>
               </div>
            }
            onClick={() => {
               setvisibleAgPersonal(true);
               setRowData(rowData);
               setDataAsignar({
                  bregma_area_id: rowData.data.id,
                  bregma_personales: [],
                  clinica_area_id: rowData.data.id,
                  clinica_personales: [],
                  empresa_area_id: rowData.data.id,
                  empresa_personales: [],
               });
               handleGetPersonal(rowData.data.id);
            }}
         />
      );
   };

   const BotonEditar = () => {
      handleEditArea(rowData?.data?.id, area);
      setvisibleEdAr(false);
   };

   const BotonEliminar = () => {
      handleDelete(rowData?.data?.id);
      setVisible3(false);
      setArea({ 
         nombre: "",
         parent_id: null 
      });
   };

   return (
      <div className="bg-white border-round-2xl">
         <TreeTable
            value={areas}
            header={header}
            emptyMessage="No se encontraron resultados"
            className="w-full bg-white "
         >
            <Column
               headerClassName="w-m"
               field="nombre"
               header="Nombre"
               expander
            />
            <Column
               headerClassName="w-35-m text-align-center"
               field=""
               body={botonagregar}
               header="Agregar"
               bodyStyle={{textAlign: 'center'}}
               style={{textAlign: 'center'}}
            />
            <Column
               header="Acciones"
               body={actionTemplate}
               headerClassName="w-15-m text-align-center"
               style={{textAlign: 'center'}}
            />
         </TreeTable>

         <ModalAgregar
            Area={area}
            setvisibleAgPersonal={setvisibleAgPersonal}
            visibleAgPersonal={visibleAgPersonal}
            setSource={setSource}
            source={source}
            target={target}
            setTarget={setTarget}
            agregarPersonal={agregarPersonal}
            dataAsignar={dataAsignar}
            rowData={rowData}
         />

         <ModalEditarArea
            Area={area}
            visibleEdAr={visibleEdAr}
            setvisibleEdAr={setvisibleEdAr}
            handleEditArea={handleEditArea}
            rowData={rowData}
            BotonEditar={BotonEditar}
            handleChangeNombre={handleChangeNombre}
         />
      </div>
   );
};
export default TablaAreas;
