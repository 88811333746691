import exercise14 from "../../../image1/Capa_14.svg";
import exercise15 from "../../../image1/Capa_15.svg";
import exercise16 from "../../../image1/Capa_16.svg";
import exercise17 from "../../../image1/Capa_17.svg";
import exercise18 from "../../../image1/Capa_18.svg";
import exercise19 from "../../../image1/Capa_19.svg";
import exercise20 from "../../../image1/Capa_20.svg";
import exercise21 from "../../../image1/Capa_21.svg";
import exercise22 from "../../../image1/Capa_22.svg";
import exercise23 from "../../../image1/Capa_23.svg";
import exercise24 from "../../../image1/Capa_24.svg";
import exercise25 from "../../../image1/Capa_25.svg";

import React, { useEffect, useState, useRef, useContext } from "react";
import { Checkbox } from 'primereact/checkbox';
import { Divider } from "primereact/divider";
import "../../../Style/style9.css";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import MusculoEsqueleticoSelectRotacion from './MusculoRotacionSelect';
import EnvioArchivoMedGeneral from '../../../Components/MedicinaGeneral/EnvioArchivoMedGeneral'
import './MusculoEsqueletico.css';
import { MGContext } from "../MGProvider";
import { RAContext } from "../../RutaAtencion/RAContext";

export default function MusculoRotacion({ }) {
    let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
    const { musculoEsqueleticoContext, setMusculoEsqueleticoContext } = useContext(MGContext)
    console.log("mirando musculoContext id", musculoEsqueleticoContext ? musculoEsqueleticoContext?.med_flexibilidad?.med_abdomen_lateral?.id : "no hay id");

    const [Archivo, setArchivo] = useState(musculoEsqueleticoContext?.med_rotacion?.archivo);
    const updateArchivo = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                archivo: nuevoValor
            }
        }));
    }

    //Checks Dolor
    const [dolor1, setDolor1] = useState(musculoEsqueleticoContext ? musculoEsqueleticoContext.med_rotacion.med_abduccion_hombro.dolor : null);
    const [dolor2, setDolor2] = useState(musculoEsqueleticoContext ? musculoEsqueleticoContext.med_rotacion.med_abduccion_hombros.dolor : null);
    const [dolor3, setDolor3] = useState(musculoEsqueleticoContext ? musculoEsqueleticoContext.med_rotacion.med_rotacion_externa.dolor : null);
    const [dolor4, setDolor4] = useState(musculoEsqueleticoContext ? musculoEsqueleticoContext.med_rotacion.med_rotacion_externa_hombro.dolor : null);

    useEffect(() => {
        setMusculoEsqueleticoContext({
            ...musculoEsqueleticoContext,
            med_rotacion: {
                ...musculoEsqueleticoContext.med_rotacion,
                med_abduccion_hombro: {
                    ...musculoEsqueleticoContext.med_rotacion?.med_abduccion_hombro,
                    dolor: dolor1 ? dolor1 : "0",
                },
                med_abduccion_hombros: {
                    ...musculoEsqueleticoContext.med_rotacion?.med_abduccion_hombros,
                    dolor: dolor2 ? dolor2 : "0",
                },
                med_rotacion_externa: {
                    ...musculoEsqueleticoContext.med_rotacion?.med_rotacion_externa,
                    dolor: dolor3 ? dolor3 : "0",
                },
                med_rotacion_externa_hombro: {
                    ...musculoEsqueleticoContext.med_rotacion?.med_rotacion_externa_hombro,
                    dolor: dolor4 ? dolor4 : "0",
                }
            }
        })
    }, [dolor1, dolor2, dolor3, dolor4]);
    //Opciones
    const [AbducciónHombro_01, setAbducciónHombro_01] = useState(musculoEsqueleticoContext?.med_rotacion?.med_abduccion_hombro?.opcion || "Optimo");
    const updateAbducciónHombro_01 = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_abduccion_hombro: {
                    ...prevMuscEsqueletico.med_rotacion?.med_abduccion_hombro,
                    opcion: nuevoValor,
                },
            },
        }));
    };

    const [AbducciónHombro_02, setAbducciónHombro_02] = useState(musculoEsqueleticoContext?.med_rotacion?.med_abduccion_hombros?.opcion || "Optimo");
    const updateAbducciónHombro_02 = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_abduccion_hombros: {
                    ...prevMuscEsqueletico.med_rotacion?.med_abduccion_hombros,
                    opcion: nuevoValor,
                },
            },
        }));
    };

    const [RotaciónExterna, setRotaciónExterna] = useState(musculoEsqueleticoContext?.med_rotacion?.med_rotacion_externa?.opcion || "Optimo");
    const updateRotaciónExterna = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_rotacion_externa: {
                    ...prevMuscEsqueletico.med_rotacion?.med_rotacion_externa,
                    opcion: nuevoValor,
                },
            },
        }));
    };

    const [RotaciónExterHombrointer, setRotaciónExterHombrointer] = useState(musculoEsqueleticoContext?.med_rotacion?.med_rotacion_externa_hombro?.opcion || "Optimo");
    const updateRotaciónExterHombrointer = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_rotacion_externa_hombro: {
                    ...prevMuscEsqueletico.med_rotacion?.med_rotacion_externa_hombro,
                    opcion: nuevoValor,
                },
            },
        }));
    };

    //Observaciones
    const [value, setValue] = useState(musculoEsqueleticoContext?.med_rotacion?.med_abduccion_hombro?.observacion);
    const updateAbducciónHombro_01Obser = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_abduccion_hombro: {
                    ...prevMuscEsqueletico.med_rotacion?.med_abduccion_hombro,
                    observacion: nuevoValor,
                },
            },
        }));
    };

    const [value1, setValue1] = useState(musculoEsqueleticoContext?.med_rotacion?.med_abduccion_hombros?.observacion);
    const updateAbducciónHombro_02Obser = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_abduccion_hombros: {
                    ...prevMuscEsqueletico.med_rotacion?.med_abduccion_hombros,
                    observacion: nuevoValor,
                },
            },
        }));
    };

    const [value2, setValue2] = useState(musculoEsqueleticoContext?.med_rotacion?.med_rotacion_externa?.observacion);
    const updateRotaciónExternaObser = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_rotacion_externa: {
                    ...prevMuscEsqueletico.med_rotacion?.med_rotacion_externa,
                    observacion: nuevoValor,
                },
            },
        }));
    };

    const [value3, setValue3] = useState(musculoEsqueleticoContext?.med_rotacion?.med_rotacion_externa_hombro?.observacion);
    const updateRotaciónExterHombrointerObser = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                med_rotacion_externa_hombro: {
                    ...prevMuscEsqueletico.med_rotacion?.med_rotacion_externa_hombro,
                    observacion: nuevoValor,
                },
            },
        }));
    };

    const [nombre, setNombre] = useState(musculoEsqueleticoContext?.med_rotacion?.nombre)
    const [observacion, setObservacion] = useState(musculoEsqueleticoContext?.med_rotacion?.observacion);
    const updateObservacion = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                observacion: nuevoValor
            },
        }));
    };

    const updateNombre = (nuevoValor) => {
        setMusculoEsqueleticoContext((prevMuscEsqueletico) => ({
            ...prevMuscEsqueletico,
            med_rotacion: {
                ...prevMuscEsqueletico.med_rotacion,
                nombre: nuevoValor
            },
        }));
    };

    useEffect(() => {
        updateAbducciónHombro_01(AbducciónHombro_01)
        updateAbducciónHombro_02(AbducciónHombro_02)
        updateRotaciónExterna(RotaciónExterna)
        updateRotaciónExterHombrointer(RotaciónExterHombrointer)

    }, [])

    useEffect(() => {
        updateAbducciónHombro_01Obser(value);
    }, [value]);

    useEffect(() => {
        updateAbducciónHombro_02Obser(value1);
    }, [value1]);

    useEffect(() => {
        updateRotaciónExternaObser(value2);
    }, [value2]);

    useEffect(() => {
        updateRotaciónExterHombrointerObser(value3);
    }, [value3]);

    useEffect(() => {
        updateObservacion(observacion);
    }, [observacion]);

    useEffect(() => {
        updateNombre(nombre);
    }, [nombre]);

    return (
        <div className="flex flex-column flex-row gap-2 MusculoRotacion_content_principal">
            <div className="flex flex-column justify-content-center aling-items-center">
                <div style={{ marginBottom: '8px' }}>
                    <p>Abducción de Hombro (0 - 180)</p>
                </div>
                <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
                    <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '53.5%' }}>
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setAbducciónHombro_01} ComandoUpdate={updateAbducciónHombro_01} ComandoValue={AbducciónHombro_01} imagen={exercise14} ValueName='Optimo' NameSelect="Óptimo 1" id_C="Abducción01_1" class_C="Abducción01" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setAbducciónHombro_01} ComandoUpdate={updateAbducciónHombro_01} ComandoValue={AbducciónHombro_01} imagen={exercise15} ValueName='Limitado' NameSelect="Limitado 2" id_C="Abducción01_2" class_C="Abducción01" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setAbducciónHombro_01} ComandoUpdate={updateAbducciónHombro_01} ComandoValue={AbducciónHombro_01} imagen={exercise16} ValueName='Muy_Limitado' NameSelect="Muy Limitado 3" id_C="Abducción01_3" class_C="Abducción01" />
                    </div>
                    <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '45%' }}>
                        <span>Observación</span>
                        <VoiceButton
                            value={value}
                            onChange={setValue}
                            placeholder="Comentario"
                            disabled={stateAtencion[pageViewContext].estado === 3} />
                    </div>
                    <div className="flex mx-3" style={{ justifyContent: "center", alignItems: "center" }}>
                        <Checkbox value="1"
                            onChange={(e) => {
                                if (dolor1 !== "1") {
                                    setDolor1(e.value);
                                }
                                else {
                                    setDolor1("0");
                                }
                            }}
                            checked={dolor1 === '1'} />
                        <label className="ml-2">Dolor</label>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="flex flex-column justify-content-center aling-items-center">
                <div style={{ marginBottom: '8px' }}>
                    <p>Abducción de Hombro (0 - 180)</p>
                </div>
                <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
                    <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '53.5%' }}>
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setAbducciónHombro_02} ComandoUpdate={updateAbducciónHombro_02} ComandoValue={AbducciónHombro_02} imagen={exercise17} ValueName='Optimo' NameSelect="Óptimo 1" id_C="Abducción02_1" class_C="Abducción02" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setAbducciónHombro_02} ComandoUpdate={updateAbducciónHombro_02} ComandoValue={AbducciónHombro_02} imagen={exercise18} ValueName='Limitado' NameSelect="Limitado 2" id_C="Abducción02_2" class_C="Abducción02" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setAbducciónHombro_02} ComandoUpdate={updateAbducciónHombro_02} ComandoValue={AbducciónHombro_02} imagen={exercise19} ValueName='Muy_Limitado' NameSelect="Muy Limitado 3" id_C="Abducción02_3" class_C="Abducción02" />
                    </div>
                    <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '45%' }}>
                        <span>Observación</span>
                        <VoiceButton
                            value={value1}
                            onChange={setValue1}
                            placeholder="Comentario"
                            disabled={stateAtencion[pageViewContext].estado === 3} />
                    </div>
                    <div className="flex mx-3" style={{ justifyContent: "center", alignItems: "center" }}>
                        <Checkbox value="1"
                            onChange={(e) => {
                                if (dolor2 !== "1") {
                                    setDolor2(e.value);
                                }
                                else {
                                    setDolor2("0");
                                }
                            }}
                            checked={dolor2 === '1'} />
                        <label className="ml-2">Dolor</label>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="flex flex-column justify-content-center aling-items-center">
                <div style={{ marginBottom: '8px' }}>
                    <p>Rotación Externa (0-90)</p>
                </div>
                <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
                    <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '53.5%' }}>
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setRotaciónExterna} ComandoUpdate={updateRotaciónExterna} ComandoValue={RotaciónExterna} imagen={exercise20} ValueName='Optimo' NameSelect="Óptimo 1" id_C="RotacionExter_1" class_C="RotacionExter" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setRotaciónExterna} ComandoUpdate={updateRotaciónExterna} ComandoValue={RotaciónExterna} imagen={exercise21} ValueName='Limitado' NameSelect="Limitado 2" id_C="RotacionExter_2" class_C="RotacionExter" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setRotaciónExterna} ComandoUpdate={updateRotaciónExterna} ComandoValue={RotaciónExterna} imagen={exercise22} ValueName='Muy_Limitado' NameSelect="Muy Limitado 3" id_C="RotacionExter_3" class_C="RotacionExter" />
                    </div>
                    <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '45%' }}>
                        <span>Observación</span>
                        <VoiceButton
                            value={value2}
                            onChange={setValue2}
                            placeholder="Comentario"
                            disabled={stateAtencion[pageViewContext].estado === 3} />
                    </div>
                    <div className="flex mx-3" style={{ justifyContent: "center", alignItems: "center" }}>
                        <Checkbox value="1"
                            onChange={(e) => {
                                if (dolor3 !== "1") {
                                    setDolor3(e.value);
                                }
                                else {
                                    setDolor3("0");
                                }
                            }}
                            checked={dolor3 === '1'} />
                        <label className="ml-2">Dolor</label>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="flex flex-column justify-content-center aling-items-center">
                <div style={{ marginBottom: '8px' }}>
                    <p>Rotación Externa de Hombro interna</p>
                </div>
                <div className="flex aling-items-center justify-content-between ContenedorPrincipalSelectMuscuEsque">
                    <div className="flex aling-items-center flex-wrap h-auto justify-content-between contenedorSelectdd" style={{ width: '53.5%' }}>
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setRotaciónExterHombrointer} ComandoUpdate={updateRotaciónExterHombrointer} ComandoValue={RotaciónExterHombrointer} imagen={exercise23} ValueName='Optimo' NameSelect="Óptimo 1" id_C="RotacionExterInter_1" class_C="RotacionExterInter" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setRotaciónExterHombrointer} ComandoUpdate={updateRotaciónExterHombrointer} ComandoValue={RotaciónExterHombrointer} imagen={exercise24} ValueName='Limitado' NameSelect="Limitado 2" id_C="RotacionExterInter_2" class_C="RotacionExterInter" />
                        <MusculoEsqueleticoSelectRotacion ValorDisabled={stateAtencion[pageViewContext].estado === 3} ComandoSet={setRotaciónExterHombrointer} ComandoUpdate={updateRotaciónExterHombrointer} ComandoValue={RotaciónExterHombrointer} imagen={exercise25} ValueName='Muy_Limitado' NameSelect="Muy Limitado 3" id_C="RotacionExterInter_3" class_C="RotacionExterInter" />
                    </div>
                    <div className="flex flex-column gap-2 justify-content-center MusculoEsqueletico_textArea" style={{ width: '45%' }}>
                        <span>Observación</span>
                        <VoiceButton
                            value={value3}
                            onChange={setValue3}
                            placeholder="Comentario"
                            disabled={stateAtencion[pageViewContext].estado === 3} />
                    </div>
                    <div className="flex mx-3" style={{ justifyContent: "center", alignItems: "center" }}>
                        <Checkbox value="1"
                            onChange={(e) => {
                                if (dolor4 !== "1") {
                                    setDolor4(e.value);
                                }
                                else {
                                    setDolor4("0");
                                }
                            }}
                            checked={dolor4 === '1'} />
                        <label className="ml-2">Dolor</label>
                    </div>
                </div>
            </div>
            <Divider />
            <EnvioArchivoMedGeneral
                FuncionSetEnvio={setArchivo}
                FuncionUpData={updateArchivo}
                ValorSet={Archivo}
                DataContext={musculoEsqueleticoContext?.med_rotacion?.archivo} />
        </div>
    );
}
