import React, { useState, useEffect, useRef } from "react";
import medicTermConditions from "../../../assets/OperIcons/termConditions/medic-term-conditions.png";
import "../../../Style/TermConditions.css";
import "primeicons/primeicons.css";
import AuthUser from "../../../AuthUser";
import { DataTermsCondition } from "../../../api";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

export default function TermConditions() {
   // Data Get
   const { terms, getAllTerms } = DataTermsCondition('/clinica/servicio/get');
   const { http } = AuthUser();
   const toast = useRef(null);
   // Variables

   // Visible Dialog
   const [visibleDelete, setVisibleDelete] = useState(false);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visible, setVisible] = useState(false);
   const [visibleDescription, setVisibleDescription] = useState(false);
   const [visibleSubTermino, setVisibleSubTermino] = useState(false);

   // Datos de terminos

   const [areaNombre, setAreaNombre] = useState(""); 
   const [descripcion, setDescripcion] = useState("");
   const [selectedTerm, setSelectedTerm] = useState(null);
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //Validar los campos
    const isNombreValido = areaNombre.trim() !== "";

    /*const handleCrearArea = () => {
       if (isNombreValido) {
          // Realiza la lógica para crear el área
          console.log("Área creada:", areaNombre);
          // Puedes cerrar el cuadro de diálogo si es necesario
          setVisible(false);
       }
    };*/

   // End Variables
   
   // Footer/Header de dialog

   //en caso quieran saber lo que hay en el array, descomentar el console.log
   //porque cada accion o letra que se presiona se actualiza automaticamente y me estresa
   //console.log("Datos del array", terms);

   const footerDescription = (
      <div className="flex w-full align-items-center justify-content-end butons-des">
         <Button
            type="button"
            label="Cancelar"
            className="delete w-full"
            onClick={() => {
               setVisibleDescription(false);
            }}
         />
         <button
            style={{ margin: "3%" }}
            className="dialogs_butons_default_primary"
            onChange={(e) => setDescripcion(e.target.value)}
            onClick={() => {
               setVisibleDescription(false);
               handleDescriptionCreate(selectedTerm?.data?.id /*, descripcion*/);
            }}
         >
            Guardar
         </button>
      </div>
   );

   const headerDescription = (
      <div>
         <div className="dialogs_header_around_icon">
            <i className="i-icon-header-dialog-blue"></i>
         </div>
         <h4 className="dialogs_header_subtitle mt-2">
            Descripción de Términos y Condiciones
         </h4>
      </div>
   );

   //Editar

   const footerEditar = (
      <div>
         <Button
            label="Cancelar"
            onClick={() => setVisibleEdit(false)}
            className="p-button-text"
         />
         <Button
            label="Guardar"
            onClick={() => {
               setVisibleEdit(false)
               //handleCrearArea()
               handleEditArea(selectedTerm?.data?.id)
            }}
            autoFocus
            //disabled={!isNombreValido}
         />
      </div>
   );

   const headerEditar = (
      <>
         <p className="font-bold text-lg" style={{ color: "#5555D8" }}>
            Editar
         </p>
         <span className="font-normal text-sm" style={{ color: "#7B8794", }}
         >
            Aquí podras editar el nombre de la área seleccionada
         </span>
      </>
   );

   //Eliminar

   const footerEliminar = (
      <div className=" flex flex-row gap-2 justify-content-end">
         <Button label="Cancelar" />
         <Button onClick={() => {
            handleDelete(selectedTerm.data.id);
            setVisibleDelete(false);
         }} label="Eliminar" />
      </div>
   );

   //crear area

   const footerCrearArea = (
      <div className=" flex justify-content-evenly gap-2 butons-area">
         <Button
            label="Cancelar"
            className="dialogs_butons_default_secondary flex-1"
            onClick={() => setVisible(false)}
         />
         <Button
            label="Crear"
            className="flex-1"
            onClick={() => {
               setVisible(false);
               //handleCrearArea()
               handleSubmit()
            }}
            //disabled={!isNombreValido}
         />
      </div>
   );

   const headerCrearArea = (
      <div>
         <div>
            <p className="font-bold text-xl py-2" style={{ color: "#5555D8", }}>
            Nombre del producto o servicio
            </p>
         </div>
         <div className="">
            <p className="font-normal text-sm" style={{ color: "#7B8794", }}>
            En esta sección usted puede crear los términos y condiciones para los productos o servicios que ofrece
            </p>
         </div>
      </div>
   );

   //crear sub area

   const headerSubTermino = (
      <>
         <div>
            <p className="font-bold text-lg" style={{ color: "#5555D8", }}>
               Crear
            </p>
         </div>
         <div className="w-full">
            <p className="font-normal text-sm" style={{ color: "#7B8794", }}>
               En esta sección usted puede registrar las sub
               áreas que necesite áreas generales.
            </p>
         </div>
      </>
   );

   const footerSubTermino = (
      <div className=" flex w-full align-items-center justify-content-end butons-area">
         <Button
            style={{ margin: "3%" }}
            label="Cancelar"
            className="dialogs_butons_default_secondary"
            onClick={() => {
               setVisibleSubTermino(false);
            }}
         />
         <Button
            style={{ margin: "3%" }}
            label="Crear"
            className="dialogs_butons_default_primary"
            onClick={() => {
               //agregarPersonal();
               //handleCrearArea()
               handleCreateHijo(selectedTerm?.data?.id)
               setVisibleSubTermino(false);
            }}
            //disabled= {!isNombreValido}
         />
      </div>
   );

   //end Footer/Header de dialog

   //Acciones

   //Editar

   const showToast = (severity, summary, message) => {
      toast.current.show({
         severity: severity,
         summary: summary,
         detail: message,
         life: 3000
      });
   };

   const handleEditArea = (id) => {
      const data = {
         nombre: areaNombre,
         descripcion: descripcion?.[id]?.descripcion
      };
      http
         .put(`/clinica/servicio/update/${id}`, data)
         .then((response) => {
            console.log("Area editada correctamente");
            getAllTerms();
            showToast(
               "success",
               "Editado correctamente",
               "Se editó el área correctamente"
            )
         })
         .catch((error) => {
            console.error(error);
            showToast(
               "error",
               "No se editó el local",
               "Hubo un error al editar local, inténtelo de nuevo"
            )
         });
   };

   //Eliminar

   const handleDelete = (id) => {

      http
         .delete(`/clinica/servicio/delete/${id}`)
         .then((response) => {
            console.log("Área eliminado correctamente");
            getAllTerms();
            showToast(
               "success",
               "Eliminado correctamente",
               "Se eliminó el área correctamente"
            );
            //setSelectedTerm(null);
         })
         .catch((error) => {
            console.error(error);
            showToast(
               "error",
               "No se eliminó el local",
               "Hubo un error al eliminar Área, inténtelo de nuevo"
            );
         });
   };

   //Crear Area
   const handleSubmit = () => {
      /*console.log("Entró a handleSubmit");
      if (!areaNombre.trim()) {
         showToast(
            "error",
            "Falta información",
            "Por favor, ingrese un nombre para el área."
         );
         console.log("Nombre de área vacío. Salida de handleSubmit");
         return;
      }*/
   
      const data = {
         nombre: areaNombre,
         parent_id: terms.id
      };
   
      // Verificar si ya existe un área con el mismo nombre
      const areaExistente = terms.find((area) => area.data.nombre === areaNombre);
   
      if (areaExistente) {
         showToast(
            "error",
            "Nombre duplicado",
            "Ya existe un área con el mismo nombre. Por favor, elija un nombre único."
         );
         console.log("Nombre duplicado. Salida de handleSubmit");
         return;
      }
   
      http
         .post('clinica/servicio/create', data)
         .then((response) => {
            console.log(response.data);
            getAllTerms();
            showToast(
               "success",
               "Área creada con éxito",
               "La nueva área se ha creado exitosamente."
            );
            /*getAllTerms();
            setVisible(false);
            //setSelectedTerm(null);*/
         })
         .catch((error) => {
            console.error(error);
            showToast(
               "error",
               "Error al crear el área",
               "Hubo un error al crear el área. Por favor, inténtelo nuevamente."
            );
         });
   };
   
   //Agregar descripción

   const handleDescriptionCreate = (id, descripcion) => {
      const data = {
         descripcion: descripcion || null
      };
   
      http
         .put(`/clinica/servicio/update/${id}`, data)
         .then((response) => {
            console.log("DESCRIPCIÓN ACTUALIZADA CORRECTAMENTE");
            getAllTerms();
            showToast(
               "success",
               "Descripción actualizada correctamente",
               "Se actualizó la descripción correctamente."
            );
         })
         .catch((error) => {
            console.error(error);
            showToast(
               "error",
               "Error al actualizar la descripción",
               "Hubo un error al actualizar la descripción. Por favor, inténtelo de nuevo."
            );
         });
   };

   //Crear Sub Area

   const handleCreateHijo = (idArea) => {
      if (!areaNombre.trim()) {
         showToast(
            "error",
            "Falta información",
            "Por favor, ingrese un nombre para el área."
         );
         return;
      }
   
      // Verificar si ya existe un área con el mismo nombre
      const areaExistente = terms.find((area) => area.data.nombre === areaNombre);
   
      if (areaExistente) {
         showToast(
            "error",
            "Nombre duplicado",
            "Ya existe un área con el mismo nombre. Por favor, elija un nombre único."
         );
         return;
      }
   
      const data = {
         nombre: areaNombre,
         parent_id: idArea || null,
      };
   

      http
         .post(`/clinica/servicio/create`, data)
         .then((response) => {
            console.log("Agregado hijo correctamente");
            getAllTerms();
            showToast(
               "success",
               "Área creada con éxito",
               "La nueva área se ha creado exitosamente."
             );
         })
         .catch((error) => {
            console.error(error);
            showToast(
            "error",
            "Error al crear un Área",
            "Hubo un error al craer un hijo de Área. Por favor, inténtelo de nuevo."
         );
         });
   };

   //End Acciones


   //Botones

   const actionTemplate = (rowData) => {
      return (
         <div className="busetresbtn">
            <Button className="busecuatrobtn border-0"
               label="Agregar"
               type="button"
               icon="pi pi-plus"
               severity="success"
               onClick={() => {
                  setVisibleSubTermino(true);
                  setAreaNombre('');
                  setSelectedTerm(rowData);
               }}

            ></Button>
            <Button
               icon="pi pi-trash text-red-500 text-xl"
               label="Eliminar"
               className="busecuatrobtn bg-red-100  border-0"
               onClick={() => {
                  setVisibleDelete(true);
                  setSelectedTerm(rowData);
                  console.log(rowData);
               }}
            />

            <Button className="busecuatrobtn bg-green-100 border-0"
               label="Editar"
               icon="pi pi-pencil text-green-700 text-xl"
               onClick={() => {
                  setVisibleEdit(true)
                  setSelectedTerm(rowData);
               }}
            />

            <Button className="busecuatrobtn bg-purple-100 border-0"
               label="Descripción"
               type="button"
               onClick={() => {
                  setVisibleDescription(true)
                  setSelectedTerm(rowData);
               }}
               icon="pi pi-book text-indigo-400 text-xl"
            ></Button>
         </div>
      );
   }

   const TresPuntos = (rowData) => {
      const [mostrarOpciones, setMostrarOpciones] = useState(false);
      const toggleOpciones = () => {
         setMostrarOpciones((prevState) => !prevState);

      };

      const isMobile = window.innerWidth < 670;

      if (isMobile) {
         return (
            <div className="tres-puntos-container" style={{ position: "relative", width: "100%", textAlign: "center" }}>
               <span onClick={toggleOpciones}>
                  <Button style={{ backgroundColor: "#f8f9fa", color: "#000", border: "1px solid #dee2e6" }}
                     icon="pi pi-ellipsis-v"
                  />
               </span>
               {mostrarOpciones && (
                  <div className="opciones-flotantes">
                     {actionTemplate(rowData)}
                  </div>
               )}
            </div>
         );
      } else {
         return (
            <div>
               {actionTemplate(rowData)}
            </div>
         )
      }
   };


   const header = (
      <div className="flex align-items-baseline justify-content-end gap-2">
         <Button
            label="Agregar"
            style={{ marginTop: "5px" }}
            icon="pi pi-plus text-indigo-400 text-xl"
            className="bg-white text-indigo-400 buton-area"
            onClick={() => setVisible(true)}
         />
      </div>
   );

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      getAllTerms();
      return () => {
         window.removeEventListener("resize", handleResize);
      };

   }, []);

   return (
      <div className="term__context">

         <section className="term__context_section_one">
            <h1 className="term__context_section_title">
               Términos y Condiciones
            </h1>
            <p className="term__context_section_paragrph">
               En este módulo usted podrá colocar los términos y condiciones de
               los productos y servicios a Bregma y ofertados a sus clientes.
               Por defecto están colocados nuestros términos y condiciones, sin
               embargo usted puede editar los mismos.
            </p>
            <img
               src={medicTermConditions}
               alt="medic"
               className="term__context_section_img"
            />
         </section>
         <section className="term__context_section_two">
            <div className="term__context_section_two_header">
               <div className="term__context_section_two_header_one">
                  <div className="term__context_section_logo">
                     <i className="i-term-conditions"></i>
                  </div>
                  <h5 className="term__context_section_subtitle">
                     Productos y Servicios
                  </h5>
               </div>
            </div>


            <div className="term__context_section_two_body">
               <TreeTable
                  value={terms}
                  header={header}
                  className="w-full bg-white "
               >
                  <Column field="nombre" header="Nombre" expander />
                  <Column
                     style={{ textAlign: "center", margin: "auto" }}
                     header="Acciones"
                     body={TresPuntos}
                     headerClassName="w-15rem"
                  />
               </TreeTable>

            </div>

         </section>
         <Toast ref={toast} />
         {/* Dialog Acciones */}

         {/* Dialog Descricion */}
         <Dialog
            style={{ width: "400px", margin: "5%" }}
            visible={visibleDescription}
            header={headerDescription}
            footer={footerDescription}
            onHide={() => setVisibleDescription(false)}
         >
            <textarea
               id="myTextarea"
               className="text-area-des"
               placeholder="Descripción..."
               style={{ width: "100%", height: "50px", marginTop: "20px", border: "1px solid #ccc" }}
              
               onChange={(e) => setDescripcion(e.target.value)}
            />
         </Dialog>

         {/* Dialog Crear area */}

         <Dialog
            style={{ width: "413px", height: "" }}
            visible={visible}
            header={headerCrearArea}
            footer={footerCrearArea}
            onHide={() => setVisible(false)}
         >
            <div className="flex flex-column gap-2 ">
               <p className="text-700">Área</p>
               <InputText
               //value={areaNombre}
               onChange={(e) => setAreaNombre(e.target.value)}
            />
            </div>
         </Dialog>

         {/* Dialog Crear sub area */}

         <Dialog
            style={{ width: "413px", height: "auto" }}
            visible={visibleSubTermino}
            onHide={() => setVisibleSubTermino(false)}
            header={headerSubTermino}
            footer={footerSubTermino}
         >
            <div className="flex flex-column gap-5" style={{ margin: "5%" }}>
               <div className=" flex flex-column gap-2">
                  <InputText
                     value={areaNombre}
                     style={{ width: "100%", height: "50px", marginTop: "20px", border: "1px solid #ccc" }}
                     className="w-full"
                     onChange={(e) => setAreaNombre(e.target.value)}
                  />
               </div>

            </div>
         </Dialog>

         {/* Dialog Aliminar */}


         <Dialog

            visible={visibleDelete}
            resizable={false}
            style={{ width: "500px", height: "240px" }}
            className="p-fluid eliminarLocal"
            header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Producto</i></p>}

            footer={footerEliminar}

            onHide={() => {
               setVisibleDelete(false);
            }}

         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column w-full">
                  <p style={{ fontSize: "20px" }} className=" m-0 pt-5">¿Está seguro qué desea eliminar?</p>
               </div>
            </div>
         </Dialog>

         {/* Dialog Editar */}

         <Dialog
            visible={visibleEdit}
            onHide={() => setVisibleEdit(false)}
            footer={footerEditar}
            header={headerEditar}
         >
            <div className="flex flex-column w-full gap-2" style={{ margin: "15px 0px 0px 0px" }}>
               <InputText //value={areaNombre}
               defaultValue={selectedTerm?.data?.nombre} onChange={(e) => setAreaNombre(e.target.value)} style={{ width: "100%" }} />
            </div>
         </Dialog>
      </div>
   );
}