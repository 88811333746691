import React from 'react'
import '../../../Style/ProductoyServicios/Botones_E.css';
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';
import Trabajadores from '../../../Images/Pure/MenuPrincipal/Img/P_S.png';
import im1 from '../../../Images/Pure/MenuPrincipal/img-icons/mua.png';
import im2 from '../../../Images/Pure/MenuPrincipal/img-icons/Produc.png';
import im3 from '../../../Images/Pure/MenuPrincipal/img-icons/opera1.png';


function PageProductosServiciosE({ accesoProp }) {
    console.log("accesos en prop", accesoProp);
    const list_images = (url) => {
        switch (url) {
            case "productos":
                return im2;
            case "servicios":
                return im3;
            case "gestiondeproforma":
                return im1;
            default:
                return im1;
        }
    }
return (
    <div className=' flex flex-nowrap flex-column w-full' style={{height:"100%"}}>
        <div className='arriba'>
            <span className='tit'>Productos y Servicios</span><br/>
            <span className='con'>Crea productos y servicios para ofrecer a tus clientes. </span>
        </div>
        <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{height:"100%"}}>
            {
                accesoProp.filter(acceso => acceso.url !== "productos" && acceso.url !== "gestiondeproforma").map((acceso, key) => (
                    <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b"+ (key+1)}
                    />
                ))
                
                /*accesoProp.map((acceso, key) => {
                    return <>
                        <Botones
                            key={key}
                            Link={acceso.url}
                            img={list_images(acceso.url)}
                            span={acceso.label}
                            button={"button b" + (key + 1)}
                        />
                    </>
                })*/
            }
          
            <Imagenes
            src={Trabajadores}
            className="logo3"
            />
            <Eclipses/>
        </div>
    </div>
)
}

export default PageProductosServiciosE