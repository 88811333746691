
import React, { useState, useEffect } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Logo from "../../../../../Images/Icon-folder.svg"
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from 'primereact/scrollpanel';
import '../../../../../Style/ScrollPanelDemo.css';
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";


export default function BotonIngreso({ dataIngreso, nombrePaquete }) {

   const transformData = (paquetes) => {
      if (!paquetes[0]) {
         return [];
      } else {
         const areas = ['areas_medicas', 'capacitaciones', 'examenes'];

         return areas.map((area, index) => {
            const data = {
               key: `node-${index}`,
               data: {
                  nombre: (index === 0 && "Áreas Médicas") || (index === 1 && "Capacitaciones") || (index === 2 && "Exámenes de ayuda al diagnóstico"),
                  description: `This is node ${index}`,
               },
               children: [],
            };

            const areaData = paquetes.flatMap((paquete) => paquete[area]);

            console.log(areaData)

            const areaNodes = transformDataChild(areaData, `node-${index}`);

            data.children = areaNodes;
            return data;
         });
      }
   };

   function transformDataChild(data, parentKey) {
      const children = ['Psicología', 'Triaje', 'Examen de Laboratorio', 'Examen Radiológico', 'Laboratorio Molecular', 'Laboratorio Clínico', 'Oftalmología'];
      return data.map((item, index) => {
         const isChild = children.includes(item.nombre);

         const node = isChild
            ? {
               key: `${parentKey}-${index}`,
               data: {
                  nombre: item.nombre.replace('_', ' '),
               },
               children: [],
            }
            : {
               key: `${parentKey}-${index}`,
               data: {
                  nombre: item.nombre,
               },
               children: [],
            };

         if (item.children && item.children.length > 0) {
            node.children = transformDataChild(item.children, `${parentKey}-${index}`);
         }

         return node;
      });
   }


   console.log(transformData([dataIngreso]))

   const DateForTreeTable = transformData([dataIngreso])
   DateForTreeTable?.splice(1, 1);


   const [filterText, setFilterText] = useState("");
   const [filteredData, setFilteredData] = useState(DateForTreeTable);

   const handleFilterChange = (e) => {
      const searchText = e.target.value.toLowerCase();
      setFilterText(searchText);

      const filterData = (data, parentMatch) => {
         return data.map(item => {
            const lowerCaseNombre = item.data.nombre.toLowerCase();
            let match = parentMatch || lowerCaseNombre.includes(searchText);
            let children = null;
            if (item.children) {
               children = filterData(item.children, match);
               if (children.some(child => child.match)) {
                  match = true;
               }
            }
            return { ...item, children, match };
         }).filter(item => item.match || (item.children && item.children.length > 0));
      };

      const filteredData = filterData(DateForTreeTable, false);
      setFilteredData(filteredData);
   };


   const [visible, setVisible] = useState(false);
   const footerContent = (
      <div className="flex" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
         <Button label="Aceptar" onClick={() => setVisible(false)} autoFocus style={{ width: '180px' }} />
      </div>
   );
   

   const Subtitulos = {
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '22px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#5555D8'
   };

   const Subtitulos2 = {
      fontSize: '15px',
      fontWeight: '700',
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#363e4b'
   };

   return (
      <div className="card flex justify-content-center align-items-center mb-0">
         <Button className="p-button-text p-button-success" label="Ver detalles" icon="pi pi-info-circle" onClick={() => setVisible(true)} style={{ height: '4.123711340206185vh' }} />
         <Dialog visible={visible} style={{ width: '50%' }} onHide={() => setVisible(false)}
            header={
               <div className="flex" style={{ alignItems: 'center' }}>
                  <div className="flex" style={{ width: '60px', height: '60px', borderRadius: '10px', justifyContent: 'center', alignItems: 'center', background: '#EBEBFF' }}>
                     <img src={Logo} alt="" />
                  </div>
                  <div className="flex flex-column gap-2" style={{ marginLeft: '10px' }} >
                     <span className="tit">Detalle de ingreso</span>
                  </div>
               </div>
            }
            footer={footerContent}>
            {/* <div className="flex justify-content-between"> */}
               {/* <div className="w-6"> */}
                  <div className="flex flex-column gap-2 mb-4">
                     <span style={Subtitulos}>Perfil {nombrePaquete}</span>
                  </div>
                  {/* Detalle de perfil Buscador */}
                  <div className="flex flex-column gap-3">
                     {/* <span style={Subtitulos}>Detalle de perfil</span> */}
                     <span className="p-input-icon-left flex w-full">
                        <i className="pi pi-search " />
                        <InputText placeholder="Buscador" className="flex w-full" value={filterText} onChange={handleFilterChange} />
                     </span>
                  </div>
                  <div className="card scrollpanel-demo" style={{ marginTop: '20px' }}>
                     <div className="flex flex-column md:flex-row gap-5">
                        <div className="flex-auto">
                           <ScrollPanel style={{ width: '100%', height: '380px' }} className="custombar1">
                              <div className="middle" style={{ padding: '10px 10px 10px 10px' }}>
                                 <TreeTable
                                    // value={DateForTreeTable}
                                    value={filteredData}
                                 >
                                    <Column field="nombre" expander></Column>
                                 </TreeTable>
                              </div>
                           </ScrollPanel>
                        </div>
                     </div>
                  {/* </div> */}
               {/* </div> */}
               {/* <div className="encabezado"></div>
               <div className="w-5">
                  <div className="mb-4">
                     <p style={Subtitulos}>Documentos</p>
                  </div>
                  <span className="p-input-icon-left flex w-full">
                     <i className="pi pi-search " />
                     <InputText placeholder="Buscador" className="flex w-full" value={filterText} onChange={handleFilterChange} />
                  </span>
               </div> */}
            </div>
         </Dialog>
      </div>
   )
}
