import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useState, useEffect, useContext } from 'react';
import { RadContext } from '../../Page/Radiologia/RadProvider';

const Voicet = ({ onChange1 ,valueDefect}) => {
  let { RayTorax, setRayTorax } = useContext(RadContext);

  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);

  useEffect(() => {
    if (isListening) {
      window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const newRecognition = new window.SpeechRecognition();

      newRecognition.onstart = () => {
        console.log('Reconocimiento de voz iniciado.');
      };

      newRecognition.onresult = (event) => {
        const currentTranscript = event.results[0][0].transcript;
        setTranscript(currentTranscript);
      };

      newRecognition.onend = () => {
        console.log('Reconocimiento de voz finalizado.');
        setIsListening(false);
      };

      newRecognition.start();
      setRecognition(newRecognition);
    } else {
      // Detener el reconocimiento si isListening es false
      if (recognition) {
        recognition.stop();
      }
    }
  }, [isListening, recognition]);

  const handleInputChange = (e) => {
    const newText = e.target.value;
    setTranscript(newText);
    if (typeof onChange1 === 'function') {
      onChange1(newText);
    }
  };

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-column w-full">
        <InputTextarea
          value={valueDefect||transcript}
          onChange={handleInputChange}
          placeholder="Texto reconocido"
          style={{ width: "90%", height: "200px" }}
        />
      </div>
      <div className="flex flex-column justify-content-end" style={{ marginLeft: "-200px", marginBottom: "5px" }}>
        <Button
          onClick={() => setIsListening(!isListening)}
          className="voice-button"
          style={{ height: "25px" }}
          label={isListening ? 'Off' : 'On'}
        />
      </div>
    </div>
  );
};

export default Voicet;
