import AuthUser from "../../../../../AuthUser";

const SC3Product = () => {
    const { http } = AuthUser();

    const postPackage = async (contrato_id, data, showToast, setEstadoEnvio,getProforma) => {
        console.log(data);
        await http.post(`clinica/asignar/paquete/${contrato_id}`, data).then(async (response) => {
            if (response.data.resp) {
                await getProforma()
                showToast("success", "Completado", response.data.resp);
                setEstadoEnvio(true);
                
            } else if (response.data.error) {
                showToast("error", "Error", "Paquete no asignado");
            }
        }).catch(async (error) => {
            console.log(error);
        }).finally(async () => {
            console.log("postPackage");
        });
    };
    const getProforma = async (id) => {
        try {
            const response = await http.get(`clinica/proforma/get/${id}`);
            console.log("Respuesta de la solicitud:", response);
            return response.data.resp;
        } catch (error) {
            console.error("Error al obtener la proforma:", error);
            throw error;
        }
        };

    return {
        postPackage,
        getProforma
    };
    
}

export default SC3Product;