import React, { useState } from "react";
import { Button } from "primereact/button";
import "../../../Style/ProductoyServicios/EstiloTablaServicios.css";
import NuevoAgregarPaquete from "./Modals/NuevoAgregarPaquete";


function    BrandComponent({ transformedNodes, toggleSortOrder, showToast, update }) {
   const [visibleEditar, setVisibleEditar] = useState(false)
   //console.log(transformedNodes)

   return (
      <div className="flex justify-content-between flex-row w-full align-items-center">
         <p style={{ fontWeight: "400", height: "18px", color: "#B6B6B6" }}></p>
         <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div className="brand flex flex-row">
               <div className="flex flex-row gap-3">
                  <button
                     onClick={toggleSortOrder}
                     style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer"
                     }}
                  >
                     <div className="contenedor-orden">
                        <div className="flex flex-column">
                           <p className="letra-orden" style={{ fontSize: "14px", fontWeight: "400", color: "#B6B6B6", }}>Ordenar por</p>
                           <div className="flex flex-row gap-2">
                              <i className="pi pi-arrow-down" style={{ color: "#393838" }} />
                              <p style={{ fontWeight: "400", fontSize: "16px", color: "#393838" }}>Alfabético</p>
                           </div>
                        </div>
                     </div>
                  </button>

                  <Button
                     icon="pi pi-plus"
                     label=" Crear Nuevo"
                     onClick={() => setVisibleEditar(true)}
                     style={{ height: "44px" }}
                  />


               </div>
            </div>
         </div>
         <NuevoAgregarPaquete
            update={update}
            showToast={showToast}
            servicios={transformedNodes}
            close={setVisibleEditar}
            estado={visibleEditar}
         />
      </div>
   );
}

export default BrandComponent;
