import React, { useState, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import VoiceButton from '../../../Components/ButtonVoice/VoiceButton';
import { MGContext } from '../MGProvider';
import { RAContext } from "../../RutaAtencion/RAContext";
import { Button } from 'primereact/button';
import EvaluadorTable from '../../../Components/MedicinaGeneral/examenAltura/pure/EvaluadorTable';
import EvaluadoresEvaluacionMedica from '../EvalucionMedica/VerEvaluadorEvaluacionMedica';
import AuthUser from '../../../AuthUser';

export default function Conclusion() {
   let { examenAlturaContext, setExamenAlturaContext } = useContext(MGContext);
   let { stateAtencion, pageViewContext } = useContext(RAContext);



   // VER EVALUADORES
   const { http } = AuthUser();
   const [NombreEvaluador, setNombreEvaluador] = useState('');
   const [Colegiatura, setColegiatura] = useState('');
   const [evaluadores, setEvaluadores] = useState({})
   const [viewPop, setviewPop] = useState(false);
   const getMedicoEvas = () => {
      http
         .get("medicina_general/evaluacion_medica/evaluadores/get")
         .then((response) => {
            setEvaluadores(response.data.evaluadores)
            console.log("Evaluadores: ", response.data.evaluadores);
         })
   }
   useEffect(() => {
      getMedicoEvas();
   }, []);
   useEffect(() => {
      if (evaluadores &&  evaluadores.length > 0) {
         console.log("trued");
         evaluadores.map((item) => {
            console.log(item);
            if (item.id == examenAlturaContext.med_conclusion.evaluador_id) {
               setNombreEvaluador(item.nombres_completos);
               setColegiatura(item.colegiatura);
            }
         })
      }
   }, [evaluadores, examenAlturaContext]);
   const ActualizarView = () => {
      if (evaluadores &&  evaluadores.length > 0) {
         if (examenAlturaContext.med_conclusion.evaluador_id) {
            evaluadores.map((item) => {
               console.log(item);
               if (item.id == examenAlturaContext.med_conclusion.evaluador_id) {
                  setNombreEvaluador(item.nombres_completos);
                  setColegiatura(item.colegiatura);
               }
            })
         }
         else {
            setNombreEvaluador("");
            setColegiatura("");
         }
      }
   }
   useEffect(() => {
      ActualizarView();
   }, [examenAlturaContext])
   const ActualizarEvaluador = (value) => {
      setExamenAlturaContext(prevState => ({
         ...prevState,
         med_conclusion: {
            ...prevState.med_conclusion,
            evaluador_id: value
         }
      }));
   };


   const ActualizarObservaciones = (value) => {
      setExamenAlturaContext(prevState => ({
         ...prevState,
         med_conclusion: {
            ...prevState.med_conclusion,
            observaciones: value
         }
      }));
   };

   const ActualizarRecomendaciones = (value) => {
      setExamenAlturaContext(prevState => ({
         ...prevState,
         med_conclusion: {
            ...prevState.med_conclusion,
            recomendaciones: value
         }
      }));
   };


   

   const optionsResultado = [
      { name: 'APTO', code: 1 },
      { name: 'NO APTO', code: 2 },
   ];
   const ActualizarObjeeto =(array, seteo, code)=>{
      array.map((item)=>{
         if(item.code == code){
            seteo(item)
         }
      })
   }
   const ActualizarResultado = (value) => {
      setExamenAlturaContext(prevState => ({
         ...prevState,
         med_conclusion: {
            ...prevState.med_conclusion,
            med_resultado_id: value
         }
      }));
   };

   const [resultado_code, setresultado_code]= useState(examenAlturaContext?.med_conclusion?.med_resultado_id);
   const [resultado_obj, setresultado_obj]= useState();
   useEffect(()=>{
      ActualizarResultado(resultado_code)
   },[resultado_code])
   useEffect(()=>{
      ActualizarObjeeto(optionsResultado, setresultado_obj, resultado_code)
   },[examenAlturaContext])


   return (

      <div className='flex flex-column gap-3'>
         <div className='flex flex-wrap w-full gap-3'>
            <div className='flex-1'>
               <span>Observaciones</span>
               <div>
                  <VoiceButton
                     value={examenAlturaContext?.med_conclusion?.observaciones}
                     onChange={ActualizarObservaciones}
                     placeholder="Comentar aquí"
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />
               </div>
            </div>
            <div className='flex-1'>
               <span>Recomendaciones</span>
               <div>
                  <VoiceButton
                     value={examenAlturaContext?.med_conclusion?.recomendaciones}
                     onChange={ActualizarRecomendaciones}
                     placeholder="Comentar aquí"
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />
               </div>
            </div>
            <div className='flex-1'>
               <span>Resultado</span>
               <div>
                  <Dropdown
                     value={resultado_obj}
                     onChange={(e) => setresultado_code(e.value.code)}
                     options={optionsResultado}
                     optionLabel="name"
                     placeholder=""
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     className="w-full md:w-14rem"
                  />
               </div>
            </div>
         </div>
         <div className="flex flex-wrap w-full gap-3">
            <div className='flex-1'>
               <span>Nombre y Apellido del médico</span>
               <div>
                  <InputText
                     style={{ width: '300px' }}
                     className="flex flex-1" disabled value={NombreEvaluador} onChange={(e) => setNombreEvaluador(e.target.value)}
                  />
               </div>
            </div>
            <div className='flex-1'>
               <span>N° de colegiatura</span>
               <div>
                  <InputText
                     className="w-7rem" disabled value={Colegiatura} onChange={(e) => setColegiatura(e.target.value)}
                  />
               </div>
            </div>
            <div className='flex-1'>
               <span>Buscar</span>
               <div>
                  <Button className="ButonEvaluadoresRadiologiaAg flex align-center justify-content-center"
                     icon="pi pi-search" severity="success" onClick={() => { setviewPop(true) }}
                     style={{ width: '40px', maxWidth: '40px', height: '45px', backgroundColor: '#BFF1DF', borderColor: '#248D63' }}>
                     <style>
                        {`
                              .ButonEvaluadoresRadiologiaAg .pi-search:before{
                                 color: #248D63 !important;
                              }
                        `}
                     </style>
                  </Button>
                  <EvaluadoresEvaluacionMedica
                     evaluadores={evaluadores}
                     visible={viewPop}
                     setVisible={setviewPop}
                     id={examenAlturaContext?.med_conclusion?.evaluador_id}
                     setid={ActualizarEvaluador}
                  />
               </div>
            </div>
         </div>
      </div>

   )
}