import React from "react";
import Registro from "../../Components/RecursosHumanos/Local/MisLocales";

const Locales = () => {
  return (
    <div>
      <Registro></Registro>
    </div>
  );
};

export default Locales;
