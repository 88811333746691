import React, { useEffect, useState, useRef } from "react";
import AuthUser from "../../../AuthUser";
import "../../../Style/PagePerfil1.css";
import PersonaComponent from "../../../Components/Perfil/usuario/Persona";
import CardDatosSecundarios from "../../../Components/Perfil/PerfilCuatro/CardDatosSecundarios";
import CardBienvenidoLong from "../../../Components/Perfil/PerfilCuatro/CardBienvenida";
import CardParteInferiorPerfil from "../../../Components/Perfil/PerfilCuatro/CardInferior";
import InboxPerfilCuatro from "../../../Components/Perfil/PerfilCuatro/InboxCuartoPerfil";
import { Toast } from "primereact/toast";

function PagePerfilPerfilSoloDatosUsuario() {
    const { http } = AuthUser();
    const toast = useRef();
    const [tipoDcoumentos, setTipoDcoumentos] = useState([]);
    const [empresa, setEmpresa] = useState({
        id: 0,
        tipo_documento_id: null,
        distrito_id: null,
        numero_documento: null,
        razon_social: null,
        direccion: null,
        estado_pago: null,
        latitud: null,
        longitud: null,
        tipo_documento: null,
        distrito: null,
        celulares: null,
        correos: null,
        detracciones: null,
        entidad_pagos: null
    });
    const [empresaUpdate, setEmpresaUpdate] = useState([])
    const [persona, setPersona] = useState([])
    const [personaUpdate, setPersonaUpdate] = useState([])

    const showToast = (type, title, detail) => {
        toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
        });
    };

    const getEmpresa = () => {
        http
            .get("empresa/show")
            .then((response) => {
                console.log("empresaaaaa", response.data);
                setEmpresa(response?.data);
                setEmpresaUpdate(response?.data);
            })
            .catch((error) => {
                console.error("errrror", error);
            });
    };

    const getAllTipoDocumentos = () => {
        http
            .get("/tipodocumentos/get")
            .then((response) => {
                if (response?.data?.data) {
                    setTipoDcoumentos(response.data.data);
                } else if (response?.data?.error) {
                    showToast('error', 'Error', response.data.error)
                }
            })
            .catch((error) => {
                showToast('error', 'Error', 'Contáctese con soporte técnico.')
            });
    };

    const getPersona = () => {
        http
            .get('persona/show')
            .then((response) => {
                console.log("personaaa", response?.data?.data?.persona)
                setPersona(response?.data?.data?.persona);
                setPersonaUpdate(response?.data?.data?.persona);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleSubmitUpdate = (id, data) => {
        console.log("data antes de enviar", data)
        http
            .post(`empresa/update/${id}`, data)
            .then((response) => {
                console.log("se actualizo")
                getEmpresa();
            })
            .catch((error) => {
                console.error(error);
            })
    }

    useEffect(() => {
        getEmpresa();
        getAllTipoDocumentos();
        getPersona();
    }, [])


    return (
        <div className="ContenedorGeneralPerfil flex flex-wrap flex-column mt-3 pr-6 pl-6" style={{ gap: '1.5rem' }}>
            <Toast ref={toast}/>
            <div>
                <p className="tit "> Mi Perfil </p>
                <p className="con" style={{ maxWidth: '333px' }}>Este está área podrás ver tu información personal y general de tu trabajo.</p>
            </div>
            <div className="flex flex-row gap-3 ConenedorGeneralPerfil4 w-full mt-4">

                <div className="flex flex-column gap-3">
                    <CardBienvenidoLong namefull={persona?.nombres + " " + persona?.apellido_paterno + " " + persona?.apellido_materno} />
                    <div className="flex flex-row flex-row gap-3 ConteneDorPersonaConDatosSecundarios">
                        <div className="flex-1 flex flex-wrap">
                            <PersonaComponent
                                persona={persona}
                                personaUpdate={personaUpdate}
                                setPersonaUpdate={setPersonaUpdate}
                                getPersona={getPersona}
                                institucion={"Coorporativo"}
                            />
                        </div>
                        <div className="flex-1 flex flex-wrap">
                            <CardDatosSecundarios />
                        </div>
                    </div>
                    <CardParteInferiorPerfil />
                </div>

                <InboxPerfilCuatro />

            </div>
        </div>
    )
}

export default PagePerfilPerfilSoloDatosUsuario;