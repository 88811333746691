import React, { useEffect, useState } from 'react'
import AvatarMap from "../../../assets/36.png"
import { Button } from "primereact/button";
import { Skeleton } from 'primereact/skeleton';
// import '../../../Style/stylePerfil.css';
import UpdatePersonaComponent from '../usuario/UpdatePersona';
// import "../../../Style/usuario/PERSONA.css";
import UpdatePersonaIMGComponent from '../usuario/UpdatePersonaIMG';
import "../../../Style/PagePerfilGeneral/USU-0/Persona-Coorporativo.css";
import CorreoIcon from "../../../Images/Iconperfil/Correo.png"
import Celular from "../../../Images/Iconperfil/Celular.png"

const PersonaComponentCoorporativo = ({ showToast, persona, personaUpdate, setPersonaUpdate, getPersona, institucion }) => {
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);

    // Skeleton
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [])
    const loader = () => {
        return (
            <div className=" flex flex-wrap w-full">
                {/* Img de usuario */}
                <div className='relative'>
                    <div className='absolute' style={{ marginTop: "75px" }}>
                        <Skeleton width="150px" height="150px" borderRadius="16px"></Skeleton>
                    </div>

                </div>

                <div className="wrapperizquierda flex w-full pt-4 flex-wrap"
                    style={{ marginLeft: '80px', height: '298px' }}
                >
                    <div className=" flex-1 flex-row w-auto ">
                        {/* Datos y botones */}

                        <div style={{ marginLeft: '60px', marginTop: '10px' }} className='flex flex-column'>
                            <Skeleton width="423px" height="47px" borderRadius="16px"></Skeleton>
                            <h6 className='flex flex-row w-full subtitulos' style={{ marginTop: '20px' }}><Skeleton width="202px" height="39.9px" borderRadius="16px"></Skeleton></h6>
                            <div className='flex flex-row justify-content-end' style={{ marginTop: "-130px" }}>
                                {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                                <Skeleton width="85.75px" height="30.56px" borderRadius="16px"></Skeleton>
                            </div>
                        </div>

                        {/* Datos del usuario parte inferior */}
                        <div style={{ marginLeft: '60px' }} className="flex flex-wrap justify-content-between pt-5">
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (loading) {
        return (loader())
    }
    else {
        return (
            <div className="BASE_CONTENEDOR_PERSONA_AHPerfil" >
                {/* Img de usuario */}
                <div className='POSICIONAMIENTO_IMAGEN' style={{ width: 'auto', height: 'auto' }}>
                    <img
                        src={persona?.foto ?? AvatarMap}
                        //src={AvatarMap}
                        alt="user"
                        className='IMGDEUSUARIO' />
                    <Button icon="pi pi-pencil" onClick={() => setVisible2(true)} className="BOTONEDITARIMGUSARIO flex flex-row" />
                </div>

                <div className="CONTENEDOR_GENERAL">
                    <div className='CONTENEDOR_BOTON_EDITAR'  >
                        {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                        <Button onClick={() => setVisible(true)} className="BOTONAGREGARDATOSUSUARIO p-button-outlined flex" style={{ margin: '0' }}>
                            <i className='ICONODEAGREGAAR pi pi-pencil' style={{ fontSize: "10px", marginRight: "5px" }}></i>
                            <p className='TEXTOAGREGARUSUARIO'>Editar</p>

                        </Button>
                    </div>
                    <div className="CONTENEDOR_DATA_GENERAL">
                        {/* Datos y botones */}

                        <div className='CONTENEDOR_UP_AHP' style={{ borderBottom: '0.45px solid #88888E99' }}>
                            <h3 className='TITULO_CONT_UP'>
                                {persona?.nombres} {persona?.apellido_paterno} {persona?.apellido_materno}
                                {/* Julio Valentino Aquino Perez */}
                            </h3>
                            <h6 className='SUBTITULO_CONT_DOWN' style={{ border: '0' }}>{institucion}</h6>
                        </div>

                        {/* Datos del usuario parte inferior */}
                        <div className="CONTENEDOR_DOWN_AHP">
                            <div className="SUB_SECTIONS_ICONO_DATA">
                                <img src={CorreoIcon} />
                                <h4 className='TITULO_CONT_DOWN'>Correo</h4>
                                <p className='SUBTITULO_CONT_DOWN'>
                                    {persona?.correo === "null" || !persona?.correo ? "No hay correo" : persona?.correo}
                                    {/* administracion@bregma.com.pe */}
                                </p>
                            </div>
                            <div className="SUB_SECTIONS_ICONO_DATA">
                                <img src={Celular} />
                                <h4 className='TITULO_CONT_DOWN'>Celular</h4>
                                <p className='SUBTITULO_CONT_DOWN'>
                                    {(persona?.celular === "null" || !persona?.celular) ? "No hay celular" : persona?.celular}
                                    {/* 938210243 */}
                                </p>
                            </div>

                        </div>

                    </div>
                    {/* Cuestionario emergente */}


                </div>
                <UpdatePersonaComponent
                    getPersona={getPersona}
                    personaUpdate={personaUpdate}
                    setVisible={setVisible}
                    visible={visible}
                    institucion={"Coorporativo"}
                    showToast={showToast}
                />

                <UpdatePersonaIMGComponent
                    getPersona={getPersona}
                    personaUpdate={personaUpdate}
                    setVisible={setVisible2}
                    visible={visible2}
                    institucion={"Coorporativo"}
                    showToast={showToast}
                />
            </div>
        )
    }
}

export default PersonaComponentCoorporativo;