import React, { useContext, useEffect, useState, useRef } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "../../../../Style/ANTECEDENTE.css";
import ICONO from "../img/ICONORIESGOS.svg";
import { Dropdown } from 'primereact/dropdown';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from "primereact/inputtext";
import { TriajeContext } from "../../../Triaje/TriajeContext";
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import InputCalendar from "../../../../Components/form/InputCalendar";
import { InputNumber } from "primereact/inputnumber";
import { RAContext } from "../../../RutaAtencion/RAContext";
import VoiceButton from "../../../../Components/ButtonVoice/VoiceButtonTriajeRiesgo";

export default function DialogAgregarRiesgo() {

    let { antLabContext, setAntLabContext } = useContext(TriajeContext);
    let { antLabRiesgoContext, setAntLabRiesgoContext } = useContext(TriajeContext);
    let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
    const [RiesgoLab, setRiesgoLab] = useState([])

    /** DATOS DE RIEGO LABORAL EN ANTECEDENTES*/
    const [Riesgo, setRiesgo] = useState(antLabRiesgoContext?.riesgo ?? "");
    const [Agente, setAgente] = useState(antLabRiesgoContext?.agente ?? []);
    const [Observacion, setObservacion] = useState(antLabRiesgoContext?.observacion ?? "");
    const [LaboresExpuestas, setLaboresExpuestas] = useState(antLabRiesgoContext?.labores_expuestas ?? "");

    const [FuentesRiesgo, setFuentesRiesgo] = useState(antLabRiesgoContext?.fuentes_riesgo ?? "");
    const [TipoExposicion, setTipoExposicion] = useState(antLabRiesgoContext?.tipo_exposicion ?? null);
    const [EPP, setEPP] = useState(antLabRiesgoContext?.equipo_proteccion_personal ?? null);
    const [Hr, setHr] = useState(antLabRiesgoContext?.hora ?? null);
    const [Dia, setDia] = useState(antLabRiesgoContext?.dia ?? null);
    const [DetalleEPP, setDetalleEPP] = useState(antLabRiesgoContext?.detalle_epp ?? "");
    const LaboresExpuestasRef = useRef(null); // Definición de la referencia
    const FuentesRiesgoRef = useRef(null); // Referencia para el campo de "Fuentes de Riesgo"
    const ObservacionRef = useRef(null);
    
    const detalleEPPRef = useRef(null); // Referencia para el campo de "Detalle EPP"
    const showToast = (type, message, description) => {
        // Lógica para mostrar el mensaje de tostada
        console.log(`Tipo: ${type}, Mensaje: ${message}, Descripción: ${description}`);
      };

    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div className="flex flex-wrap flex-row mt-3 mb-3 justify-content-end">
            <Button label="Aceptar" onClick={() => CrearAntOcup(showToast)} autoFocus />
            <Button label="Cancelar" onClick={() => setVisible(false)} className="p-button-text" />
        </div>
    );
    
    const limpiar = () => {
        setRiesgo(null)
        setAgente([])
        setObservacion(null)
        setLaboresExpuestas(null)
        setFuentesRiesgo(null)
        setTipoExposicion(null)
        setEPP(null)
        setHr(null)
        setDia(null)
        setDetalleEPP(null)
    }

    useEffect(() => {
        setRiesgoLab({
            observacion: null,
            labores_expuestas: null,
            fuentes_riesgo: null,
            tipo_exposicion: null,
            equipo_proteccion_personal: null,
            hora: null,
            dia: null,
            detalle_epp: null,
            riesgo: null,
            riesgo_agente: []
        })
    }, []);

    useEffect(() => {
        setRiesgoLab({
            observacion: null,
            labores_expuestas: null,
            fuentes_riesgo: null,
            tipo_exposicion: null,
            equipo_proteccion_personal: null,
            hora: null,
            dia: null,
            detalle_epp: null,
            riesgo: null,
            riesgo_agente: []
        })
    }, [antLabRiesgoContext]);

    useEffect(() => {
        const nuevosAgentes = Agente.map(item => ({ agente: item }));

        setRiesgoLab([{
            riesgo: Riesgo,
            observacion: Observacion,
            labores_expuestas: LaboresExpuestas,
            tipo_exposicion: TipoExposicion ? TipoExposicion.code : null,
            equipo_proteccion_personal: EPP ? EPP?.name : null,
            fuentes_riesgo: FuentesRiesgo,
            hora: Hr,
            dia: Dia,
            detalle_epp: DetalleEPP,
            riesgo_agente: nuevosAgentes
        }])
    }, [Riesgo, Agente, Observacion, TipoExposicion, EPP, LaboresExpuestas, FuentesRiesgo, Hr, Dia, DetalleEPP,])

    const riesgosCheckboxes = {
        Biológicas: [
            { label: 'Bacterias', value: '1' },
            { label: 'Parásitos', value: '2' },
            { label: 'Virus', value: '3' },
            { label: 'Hongos', value: '4' },
            { label: 'Ricketsias', value: '5' },
            { label: 'Animales', value: '6' },
            { label: 'Plantas', value: '7' }
        ],
        Ergonómicas: [
            { label: 'Monotonia - Movimiento repetitivos', value: '8' },
            { label: 'Sobrecarga 1', value: '9' },
            { label: 'Posiciones corporales', value: '10' },
            { label: 'Ciclos metabolicos', value: '11' },
            { label: 'Lugaresde trabajo y controles', value: '12' }
        ],
        Físicas: [
            { label: 'Ruido', value: '13' },
            { label: 'Vibración', value: '14' },
            { label: 'Temperaturas y humedades extremas', value: '15' },
            { label: 'Iluminación defectuosa', value: '16' },
            { label: 'Variaciones de presión', value: '17' },
            { label: 'Radiación ionizante', value: '18' },
            { label: 'Radiación no ionizante', value: '19' }
        ],
        Psicosocial: [
            { label: 'Stress', value: '20' },
            { label: 'interacción con personas', value: '21' },
            { label: 'Trabajo monótono', value: '22' },
            { label: 'Carga mental', value: '23' },
        ],
        Químicas: [
            { label: 'Gases', value: '24' },
            { label: 'Vapores', value: '25' },
            { label: 'Polvos orgánicos naturales', value: '26' },
            { label: 'Polvos orgánicos sintéticos', value: '27' },
            { label: 'Polvos inorgánicos', value: '28' },
            { label: 'Humos', value: '29' },
            { label: 'Neblina o rocios', value: '30' },
            { label: 'Diferencia de oxígeno', value: '31' }
        ],
    };

    const citiesExposicion = [
        { name: 'Esporádica', code: '0' },
        { name: 'Permanente', code: '1' },
        { name: 'Ninguno', code: '2' }
    ];

    const citiesEPP = [
        { name: 'Incompleto', code: '0' },
        { name: 'Completo', code: '1' },
        { name: 'Ninguno', code: '2' }
    ];

    const handleCheckboxChange = (e, checkboxLabel) => {
        const isChecked = e.target.checked; //e.target.checked = true;
        if (isChecked) {
            setAgente([...Agente, checkboxLabel]);
        } else {
            // Elimina el checkboxLabel del arreglo de Agente
            setAgente(Agente.filter((value) => value !== checkboxLabel));
        }
    };

    const CrearAntOcup = () => {
        // Lógica para crear el antecedente ocupacional
        setAntLabRiesgoContext([...RiesgoLab, ...antLabRiesgoContext]);
        setVisible(false);
        limpiar();
        if (typeof showToast === 'function') {
            showToast("success", "Creado correctamente", 
            "Se creó el riesgo de antecedente laboral");

        }
    };
    

    const DefaultValueObs = Agente.map(checkboxLabel => {
        const selectedCheckbox = Object.values(riesgosCheckboxes[Riesgo]).find(checkbox => checkbox.label === checkboxLabel);
        return selectedCheckbox ? `• ${selectedCheckbox.label}: ` : '';
    }).join('\n');

    useEffect(() => {
        setObservacion(DefaultValueObs)
    }, [DefaultValueObs])

    return (
        <div className="flex justify-content-start" style={{ marginTop: '20px', marginBottom: '10px' }}>
            <Button label="Agregar" icon="pi pi-plus" onClick={() => setVisible(true)}
                disabled={stateAtencion[pageViewContext].estado === 3} />
                
            <Dialog visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className="flex flex-column gap-1">
                    <div className="CONTENEDORTITULOCONICONO gap-3">
                        <div className="CONTENEDOR_ICONO">
                            <img src={ICONO} alt="Ícono" />
                        </div>
                        <span>Crear Nuevo Antecedente Riesgo Laboral</span>
                    </div>
                    <div className="CONTENEDORDROPDOWNLIST flex flex-column gap-3">
                        <div className="flex flex-column gap-2">
                            <span>Riesgo:</span>
                            <Dropdown
                                value={Riesgo}
                                onChange={(e) => setRiesgo(e.value)}
                                options={Object.keys(riesgosCheckboxes)}
                                placeholder="Selecciona un riesgo"
                                className="w-full"
                            />
                        </div>
                        <div className="flex-auto">
                            <span className="flex mb-3">Agente:</span>
                            <ScrollPanel style={Riesgo ? { width: '100%', height: '200px' } : { width: '100%', height: '0px' }} className="custombar1">
                                {Riesgo && (
                                    <div className="LISTACHECKBOX flex flex-column gap-3">
                                        {riesgosCheckboxes[Riesgo].map((checkbox) => (
                                            <label key={checkbox.value} htmlFor={checkbox.value} className="flex flex-row gap-3" style={{ alignItems: 'center' }}>
                                                <Checkbox className="CheckboxOPCIONESNUEVOSRIESGO" value={checkbox.value} checked={Agente.includes(checkbox.label)}
                                                    onChange={(e) => handleCheckboxChange(e, checkbox.label)} />
                                                {checkbox.label} {/**SE MUESTRA EL TEXTO QUE RECORRE */}
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </ScrollPanel>
                        </div>
                    </div>
                    <div className="CONTENEDORINPUTTAREAEPP flex flex-column gap-3">
                        <div className="flex flex-column gap-2">
                            <span>Observación</span>
                            <VoiceButton
                                rows="3"
                                cols="50"
                                className="INPUTTAREASTYLE"
                                value={Observacion} onChange={(value) => setObservacion(value)}
                                ref={ObservacionRef}
                            />
                            
                        </div>
                        <div className="flex flex-column gap-2">
                            <span>Labores Expuestas</span>
                            <VoiceButton rows="3" cols="50" 
                            value={LaboresExpuestas} 
                            onChange={(value) => setLaboresExpuestas(value)} 
                            className="INPUTTAREASTYLE" 
                            ref={LaboresExpuestasRef}
                            />
                        </div>
                        <div className="flex flex-column gap-2">
                            <span>Fuentes de Riesgo</span>
                            <VoiceButton rows="3" cols="50" 
                            value={FuentesRiesgo} 
                            onChange={(value) => setFuentesRiesgo(value)} 
                            className="INPUTTAREASTYLE" 
                            ref={FuentesRiesgoRef}
                            />
                        </div>
                        <div className="flex flex-column gap-2">
                            <span>Tipo de Exposición</span>
                            <Dropdown value={TipoExposicion} 
                            onChange={(e) => setTipoExposicion(e.target.value)} 
                            options={citiesExposicion} optionLabel="name"
                            placeholder="Selecionar una Opcion" 
                            className="w-full" />
                        </div>
                    </div>
                    <div className="CONTENEDOREPPS flex flex-wrap gap-3 mt-3">
                        <div className="flex flex-column gap-2 w-full flex-1">
                            <span>Equipo Protección Personal (EPP)</span>
                            <Dropdown value={EPP} onChange={(e) => setEPP(e.target.value)} options={citiesEPP} optionLabel="name"
                                placeholder="Selecionar una Opcion" className="w-full" />
                        </div>
                        <div className="flex flex-row gap-3 w-full flex-1">
                            <div className="flex flex-column gap-2">
                                <span>Hr</span>
                                <InputNumber 
                                useGrouping={false}
                                locale="en-US"
                                minFractionDigits={1}
                                maxFractionDigits={2}
                                value={Hr} 
                                onValueChange={(e) => setHr(e.target.value)} />
                            </div>
                            <div className="flex flex-column" style={{ justifyContent: 'end', paddingBottom: '15px' }}>
                                <span>%</span>
                            </div>
                            <div className="flex flex-column gap-2">
                                <span>Dia</span>
                                <InputNumber
                                    value={Dia}
                                    onValueChange={(e) => setDia(e.target.value)}
                                    showIcon
                                    useGrouping={false}
                                    locale="en-US"
                                    minFractionDigits={1}
                                    maxFractionDigits={2}
                                />
                            </div>
                        </div>
                        <div className="flex flex-column w-full gap-2">
                            <span>Detalle EPP</span>
                            <VoiceButton rows="3" cols="50" value={DetalleEPP} onChange={(value) => setDetalleEPP(value)} className="INPUTTAREASTYLE" ref={detalleEPPRef}/>
                        </div>
                    </div>
                    
                </div>
            </Dialog>
        </div>
    )
}
