import React, { useContext, useEffect, useState } from 'react'
import AuthUser from '../../AuthUser';
import RAResultadosTable from '../../Components/RutaAtencion/Resultados/RAResultadosTable';
import { RAContext } from '../RutaAtencion/RAContext';

export default function PageMenuLaboratorio() {
    const { http } = AuthUser();
    const [pacientes, setPacientes] = useState([]);
    const getPacientes = () => {
       http
          .get("/atencion/paciente/examenes/2")
          .then((response) => {
             console.log("personal paciente", response.data.data);
             setPacientes(response.data.data);
          })
          .catch((error) => {
             console.log(error);
          });
    };
 
    useEffect(() => {
       getPacientes();
    }, [])
 
    console.log(pacientes);
 
    return (
       <div>
          <RAResultadosTable
             pacientes={pacientes}
             nombre={"Laboratorio"}
          ></RAResultadosTable>
       </div>
    )
 }
 