import React from "react";
import CrearEvaluador from "../../../../Components/RecursosHumanos/Evaluadores/CrearEvaluador";

function PageEvaluadores() {
   return (
      <div>
         <div className="arriba">
            <span className="tit">Lista de Evaluadores</span>
            <br />
            <span className="con">Agregar contenido</span>
         </div>
         <div>
            <CrearEvaluador></CrearEvaluador>
         </div>
      </div>
   );
}

export default PageEvaluadores;
