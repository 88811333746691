import React, { useContext } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from 'primereact/multiselect';
import { RAContext } from "../../RutaAtencion/RAContext";
import { MGContext } from "../../MedicinaGeneral/MGProvider";
import TiposHabito from "./TriajeHabNoc";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButtonMG";
import HabNoc from "../../../Images/Triaje/HabitosNocivos/icons/ICONOHAB.svg";
import "../../../Style/Triaje/HabitosNocivos/triaje_hab_noc.css";
import "../../../Style/Triaje/HabitosNocivos/style/CSSHab.css";
import { InputText } from "primereact/inputtext";

const PageTriajeHabNoc = () => {
   let { habNocContext, setHabNocContext ,desabilitarPractica, setDesabilitarPractica} = useContext(MGContext);
   let { stateAtencion, pageViewContext } = useContext(RAContext);

   let deportOptions = [
      { name: "Si", code: 1 },
      { name: "No", code: 0 },
   ];
   let listDeportes = [
      { label: "Fútbol", id: 1 },
      { label: "Baloncesto", id: 2 },
      { label: "Natación", id: 3 },
      { label: "Voleibol", id: 4 },
      { label: "Otros Deportes", id: 5 },
   ];
   let listTiempo = [
      { name: "Días", code: 1 },
      { name: "Semanas", code: 2 },
      { name: "Meses", code: 3 },
      { name: "Años", code: 4 },
   ];

   let frecuencia = [
      { id: 1, nombre: "Nada" },
      { id: 2, nombre: "Poco" },
      { id: 3, nombre: "Regular" },
      { id: 4, nombre: "Excesivo" },
      { id: 5, nombre: "Niega" },
   ]

   

   // Función para limpiar los valores seleccionados. 
   const handlePractDeporte = (value) => {
      console.log(value)
      if (value === 0) {
         setDesabilitarPractica(true);
         setHabNocContext({
            ...habNocContext,
            practica: value,
            frecuencia_id: "",
            tipo_tiempo_id: "",
            habito_deporte: [],
            cantidad: ""
         });
      } else if (value === 1) {
         setDesabilitarPractica(false);
         setHabNocContext({
            ...habNocContext,
            practica: value
         });
      }
   }

   const handleChangeActividadFisica = (e) => {
      console.log(e.target.name, e.target.value)
      setHabNocContext({
         ...habNocContext,
         [e.target.name]: e.target.value
      })
   }

   const handleChangObservaciones = (e) => {
      setHabNocContext({
         ...habNocContext,
         observaciones: e
      })
   }

   return (
      <div className="Contenedor_general_Hab_Malos flex flex-row gap-3">
         <div className="grid flex">
            <>
               {habNocContext?.habitos.map((item, index) => (
                  <TiposHabito
                     key={index}
                     index={index}
                     tipo_habito_id={item.tipo_habito_id}
                     nombre={item.nombre}
                     frecuencia={item.frecuencia}
                     tiempo={item.tiempo}
                     tipo={item.tipo}
                     habitos={habNocContext}
                     cantidad={item.cantidad}
                     opcion={item.opcion}
                  />
               ))}
            </>
         </div>
         <div className="ConenedorActIvidadFisica flex flex-column gap-3">
            <div className="flex flex-wrap align-items-center gap-3">
               <img src={HabNoc} alt="" />
               <p className="font-bold text-base text-indigo-500" >
                  Actividad Física
               </p>
            </div>
            <div className="Preguntas_Actividad_Fisica flex flex-column gap-3">
               <div>
                  <label
                     style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "500",
                     }}
                  >
                     ¿Practica algún deporte?
                  </label>
                  <Dropdown
                     name="practica"
                     placeholder="Seleccione una opción"
                     value={habNocContext.practica}
                     // value={1}

                     onChange={(e) => { handlePractDeporte(e.value) }}
                     optionLabel="name"
                     options={deportOptions}
                     optionValue="code"
                     disabled={stateAtencion[pageViewContext].estado === 3 }
                     className="w-full"
                  />
               </div>
               <div>
                  <div className="thn_form_input_2 flex flex-wrap gap-2 align-items-center pb-2">
                     <div className="input_micro_label">
                        <label className="text-sm font-medium"
                           style={{ fontFamily: "Montserrat" }} >
                           ¿Qué deporte?
                        </label>
                     </div>
                     <MultiSelect
                        name="habito_deporte"
                        className="w-full"
                        value={habNocContext.habito_deporte}
                        options={listDeportes}
                        optionValue="id"
                        optionLabel="label"
                        placeholder="Seleccione una opción"
                        maxSelectedLabels={3}
                        disabled={desabilitarPractica || stateAtencion[pageViewContext].estado === 3}
                        onChange={(e) => handleChangeActividadFisica(e)}
                     />
                  </div>
                  {habNocContext?.habito_deporte?.includes(5) && <InputText
                        name="otros"
                        className="w-full"
                        value={habNocContext.otros}
                        placeholder="Ninguno"
                        onChange={(e) => handleChangeActividadFisica(e)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                        style={{ height: "50px" }}
                     />}
               </div>
               <div className="thn_form_input_2">
                  <div className="input_micro_label flex flex-wrap gap-2 align-items-center">
                     <label
                        className="flex flex-1 w-full text-sm font-medium"
                        style={{ fontFamily: "Montserrat" }}
                     >
                        ¿Cuánto tiempo?
                     </label>
                     <Dropdown
                        name="tipo_tiempo_id"
                        className="flex flex-1 w-full"
                        options={listTiempo}
                        optionLabel="name"
                        optionValue="code"
                        value={habNocContext.tipo_tiempo_id}
                        onChange={(e) => handleChangeActividadFisica(e)}
                        placeholder="Seleccione la unidad"
                        disabled={desabilitarPractica || stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
                  <InputNumber
                     name="cantidad"
                     value={habNocContext.cantidad}
                     disabled={desabilitarPractica || stateAtencion[pageViewContext].estado === 3}
                     min={0}
                     onValueChange={(e) => handleChangeActividadFisica(e)}
                  />
               </div>
               <div className="thn_form_input_2">
                  <div className="input_micro_label">
                     <label className="text-sm font-medium"
                        style={{ fontFamily: "Montserrat" }}
                     >
                        ¿Con qué frecuencia?
                     </label>
                  </div>
                  <Dropdown
                     name="frecuencia_id"
                     value={habNocContext.frecuencia_id}
                     options={frecuencia}
                     optionValue="id"
                     optionLabel="nombre"
                     placeholder="Seleccione una opción"
                     disabled={desabilitarPractica || stateAtencion[pageViewContext].estado === 3}
                     onChange={(e) => handleChangeActividadFisica(e)}
                  />
               </div>
            </div>
            <div className="Contenedor_medicamentos_Obervaciones flex flex-column gap-3">
               <div>
                  <div>
                     <h3 className="text-sm font-medium"
                        style={{ fontFamily: "Montserrat" }}
                     >
                        Medicamento
                     </h3>
                     <InputTextarea
                        name="medicamento"
                        className="w-full"
                        value={habNocContext.medicamento}
                        placeholder="Ninguno"
                        onChange={(e) => handleChangeActividadFisica(e)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                        style={{ height: "50px" }}
                     />
                  </div>
               </div>
               <div>
                  <h3 className="text-sm font-medium"
                     style={{ fontFamily: "Montserrat" }}
                  >
                     Observaciones
                  </h3>
                  <VoiceButton
                     nombre={"observaciones"}
                     value={habNocContext.observaciones}
                     onChange={handleChangObservaciones}
                     placeholder="Observaciones"
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />
               </div>
            </div>
         </div>
      </div>

   );
};

export default PageTriajeHabNoc;
