import React, { useEffect, useRef, useState } from "react";
import AuthUser from "../../../../AuthUser";
import { Button } from "primereact/button";
import ModalCreate from "../../../../Components/RecursosHumanos/Personal/ModalCreate";
import ModalEditar from "../../../../Components/RecursosHumanos/Personal/ModalEditar";
import ModalCredenciales from "../../../../Components/RecursosHumanos/Personal/ModalCredenciales";
import ModalEliminar from "../../../../Components/RecursosHumanos/Personal/ModalEliminar";
import "../../../../Style/CSS_tabla.css";
import AsignarServicioDialog from "./Dialog/DialogAsignarServicio";
import "../../../../Style/Botones.css";
import { Toast } from "primereact/toast";
import CargarData from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarData";
import CargarDatosDNI from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarDatosDNI";
import TablaCrud from "../../../../Components/Table/TablaCrud";
import { Column } from "primereact/column";
import AsignarServicioGrupal from './Dialog/AsignarServicioGrupal';
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

function PagePersonalEmpresa() {
   const { http } = AuthUser();
   const toast = useRef(null);
   const dispatch = useDispatch();
   const [rowClick, setRowClick] = useState(null)
   const [visibleAsignarGrupal, setVisibleAsignarGrupal] = useState(false);
   const [visibleCredenciales, setVisibleCredenciales] = useState(false);
   const [visibleDialogDNI, setVisibleDialogDNI] = useState(false);
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [visibleAsignar, setVisibleAsignar] = useState(false);
   const [visibleDialog, setVisibleDialog] = useState(false);
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [selectPersonal, setSelectPersonal] = useState({
      id: 0,
      tipo_documento: "",
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "2022-01-01",
      distrito_id: 2,
      celular: "",
      correo: "",
      usuario: "",
      rol: "",
   });

   const [personaleliminar, setPersonaleliminar] = useState([]);
   const [selectedPersonal, setSelectedPersonal] = useState([]);
   const [tipoDocumentos, setTipoDocumentos] = useState([]);
   const [sexos, setSexos] = useState([]);
   const [contratos, setContratos] = useState([]);
   const [personal, setPersonal] = useState([]);
   const [locales, setLocales] = useState([]);
   const [roles, setRoles] = useState([]);
   const [data, setData] = useState([]);

   const getAllTipoDocumentos = async () => {
      try {
         const response = await http.get("tipodocumentos/get");
         if (response?.data?.data) {
            setTipoDocumentos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      };
   };

   const getGenero = async () => {
      try {
         const response = await http.get("sexos/get");
         if (response?.data?.data) {
            setSexos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      };
   };

   const getRoles = async () => {
      try {
         const response = await http.get("roles/get")
         if (response?.data?.data) {
            setRoles(response?.data?.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', "Contáctese con soporte técnico.")
      };
   };

   const getPersonal = async () => {
      try {
         const response = await http.get("empresa/personal/get")
         if (response?.data?.data) {
            setPersonal(response?.data?.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error);
         }
      } catch (error) {
         showToast("error", "Error", "Contáctese con soporte técnico.")
      };
   };

   //  const getLocales = async (id) => {
   //     try {
   //        const response = await http.get(`empresa/local/get/${id}`)
   //        setLocales(response?.data?.data)

   //     } catch (error) {
   //        console.log(error);
   //     };
   //  };

   const getContract = async () => {
      try {
         const response = await http.get("tipocontrato/show")
         if (response?.data) {
            setContratos(response?.data)
         } else if (response?.data?.error) {
            showToast('error', 'Error', response?.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', "Contáctese con soporte técnico")
      };
   }

   const handleObtenerPuesto = async () => {
      try {
         const response = await http.get("empresa/cargo/get1")
         if (response?.data?.data) {
            setData(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response?.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', "Contáctese con soporte técnico")
      }
   };

   const showCredencialesModal = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleCredenciales(true);
   };

   const actionBodyCredenciales = (rowData) => {
      return (
         <Button
            icon="pi pi_tamaño pi-id-card"
            className="p-button-text"
            onClick={() => showCredencialesModal(rowData)}
         />
      )
   }

   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   const asignarPaquetes = (data) => {
      dispatch(startLoading());
      http
         .post("empresa/personal/asignar/perfil", data)
         .then((response) => {
            if (response.data.error) {
               showToast('error', 'Error', response.data.error);
            } else if (response?.data?.resp) {
               showToast("success", "Asignado correctamente", "Se asignó correctamente al personal");
            }
         })
         .catch((error) => {
            showToast('error', 'Error', "Contáctese con soporte técnico");
         })
         .finally(() => {
            dispatch(endLoading());
         })
   };

   const asignarPaqueteGrupal = (data) => {
      dispatch(startLoading());
      http
         .post("empresa/personal/asignar/servicio", data)
         .then((response) => {
            if (response.data.error) {
               showToast('error', 'Error', response.data.error);
            } else if (response?.data?.resp) {
               showToast("success", "Envío grupal", "Grupo asignado correctamente")
            }
         })
         .catch((error) => {
            showToast('error', 'Error', "Contáctese con soporte técnico");
         })
         .finally(() => {
            dispatch(endLoading());
         })
   }

   const subirArchivoDNI = async (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", data);
      dispatch(startLoading());
      try {
         const response = await http.post("/empresa/personal/import/dni", formData)
         if (response.data.error) {
            showToast('error', 'Error', response.data.error);
         } else if (response?.data?.resp) {
            await Promise.all([
               getPersonal()
            ])
            setTipoDocumentos(response.data.data);
            setIsLoading(false);
            showToast("success", "Éxito", "El personal fue asignado.")
         }
      } catch (error) {
         setIsLoading(false);
         showToast("error", "Fallo al enviar personal", "Contáctese con soporte técnico")
      } finally {
         dispatch(endLoading());
      }
   };

   const subirArchivo = async (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", data);
      dispatch(startLoading());
      try {
         const response = await http.post("/empresa/personal/import", formData)
         if (response.data.error) {
            showToast('error', 'Error', response.data.error);
         } else if (response?.data?.resp) {
            await Promise.all([
               getPersonal()
            ])
            setTipoDocumentos(response);
            setVisibleDialog(false);
            setIsLoading(false);
            showToast("success", "Éxito", response?.data?.resp)
         }
      } catch (error) {
         setIsLoading(false);
         showToast("error", "Fallo al enviar personal", "Contáctese con soporte técnico")
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getAllTipoDocumentos(),
            getGenero(),
            getPersonal(),
            getRoles(),
            handleObtenerPuesto(),
            getContract()
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }



   const actionBodyTemplateAsignar = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-send"
               className="p-button-text"
               onClick={() => asignarServicio(rowData)}
               style={{ height: "32px" }}
            />
         </React.Fragment>
      );
   };

   const actionBodyTemplate = (rowData) => {
      return (

         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => editPaquete(rowData)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />

         </React.Fragment>
      );
   };

   const asignarServicio = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleAsignar(true);
   };

   const editPaquete = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleEdit(true);
   };

   const deletePaquete = (rowData) => {
      setPersonaleliminar(rowData);
      setVisibleEliminar(true);
   };

   const handleItem = (rowData, index) => {
      return <div>{index.rowIndex + 1}</div>
   }

   const openDialog = () => {
      setVisibleDialog(true)
   };
   const openDialogDNI = () => {
      setVisibleDialogDNI(true)
   };

   const handleContrato = (rowData) => {
      return rowData.tipo_contrato ? rowData.tipo_contrato.nombre : "-"
   }

   const handleEvaluacion = (rowData) => {
      return rowData.fecha_atencion ? rowData.fecha_atencion : "-"
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
   }, []);

   return (
      <div className="flex flex-column gap-2">
         <Toast ref={toast} />
         <div className="flex flex-column">
            <h1 className="titulo w-full">Mi Personal</h1>
            <p className="subtitulo" style={{ marginBottom: "10px" }}>Adminístralo de la mejor forma</p>
         </div>

         <div className="content-table-rol flex w-12 justify-content-end table centrar">
            <TablaCrud
               selectionMode={rowClick ? 'checkbox' : null}
               selection={selectedPersonal}
               onSelectionChange={(e) => setSelectedPersonal(e.value)}
               dataKey="id"
               name={"personal"}
               value={personal}
               leftLabelButton1={"Crear"}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton3={"Asignar servicios"}
               onClickLeftButton3={() => { setVisibleAsignarGrupal(true) }}
               leftLabelButton5={"Cargar datos"}
               onClickLeftButton5={() => openDialog()}
               leftLabelButton6={"Cargar datos DNI"}
               onClickLeftButton6={() => openDialogDNI()}

            >
               <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
               <Column
                  className='ccc1'
                  body={handleItem}
                  header={<span>Item</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc1'
                  field="persona.tipo_documento.nombre"
                  header={<span className="header-rol" >Documento</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc2'
                  field="persona.numero_documento"
                  header={<span className="header-accesos">Num. Documento</span>}
                  style={{ minWidth: "60%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

               <Column
                  className='ccc4'
                  field="persona.nombres"
                  header={<span className="header-locales">Nombres completos</span>}
                  style={{ minWidth: "60%", whiteSpace: "wrap", textAlign: "center" }}>
               </Column>

               <Column
                  className='ccc1'
                  body={handleContrato}
                  header={<span>Tipo de Contrato</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc1'
                  field="empresa_cargo.nombre"
                  header={<span>Puesto de Trabajo</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Envio Credenciales</span>}
                  body={actionBodyCredenciales}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

               <Column
                  className='ccc1'
                  body={handleEvaluacion}
                  header={<span>Último examen médico</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Envio Servicios</span>}
                  body={actionBodyTemplateAsignar}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Acciones</span>}
                  body={actionBodyTemplate}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

            </TablaCrud>
         </div>

         {/* Modales */}

         <AsignarServicioDialog
            visibleAsignar={visibleAsignar}
            setVisibleAsignar={setVisibleAsignar}
            item={selectPersonal}
            locales={locales}
            asignarPaquetes={asignarPaquetes}
            data={data}
         />

         <AsignarServicioGrupal
            visibleAsignar={visibleAsignarGrupal}
            setVisibleAsignar={setVisibleAsignarGrupal}
            item={selectedPersonal}
            examenes={selectedPersonal}
            locales={locales}
            asignarPaquetes={asignarPaqueteGrupal}
         />

         <ModalCreate
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            sexos={sexos}
            roles={roles}
            getPersonal={getPersonal}
            api={"/empresa/personal/create"}
            data={data}
            contractOptions={contratos}
         />

         <ModalEditar
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            personal={selectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            sexos={sexos}
            roles={roles}
            getPersonal={getPersonal}
            api={"empresa/personal/update"}
            data={data}
            contractOptions={contratos}
         />

         <ModalCredenciales
            visibleCredenciales={visibleCredenciales}
            setVisibleCredenciales={setVisibleCredenciales}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />

         <ModalEliminar
            visibleEliminar={visibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            personal={personal}
            setPersonal={personaleliminar}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api={"/empresa/personal/delete"}
         />

         <CargarDatosDNI
            visible={visibleDialogDNI}
            setVisible={setVisibleDialogDNI}
            subirArchivo={subirArchivoDNI}
            isLoading={isLoading}
         />

         <CargarData
            visible={visibleDialog}
            setVisible={setVisibleDialog}
            subirArchivo={subirArchivo}
            isLoading={isLoading}
         />

      </div>
   );
}

export default PagePersonalEmpresa;