//ISAAC

import AuthUser from "../../../../AuthUser";
import axios from "axios";

const SalesCommon = () => {

    const { http } = AuthUser();


    const getRUC = async (ruc, setData, showToast) => {
        try {
            const response = await axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${ruc}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`);
            const razonSocial = response.data.razonSocial;

            if (razonSocial === undefined) {
                showToast("error", "Error", `${ruc} no encontrado`);
            } else {
                showToast("success", "Encontrado", `${ruc} encontrado`);
            }

            setData(prevState => ({
                ...prevState,
                GCEmpresa: {
                    ...prevState.GCEmpresa,
                    razon_social: razonSocial || "No se encontró"
                }
            }));

            console.log("Common getRUC");
        } catch (error) {
            console.log(error);
        }
    };

    const getLocations = async () => {
        try {
            const response = await http.get("ubicacion");
            if (response.data.data) {
                return response.data.data;
            } else {
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getLocations");
        }
    };



    const getTypesOfCustomers = async () => {
        try {
            const response = await http.get("tipo/cliente/show");
            if (response.data) {
                const optionsTipoClientes = response.data.map((tc) => {
                    return {
                        label: tc.nombre,
                        value: tc.id,
                    };
                });

                return optionsTipoClientes;
            } else {
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getTypesOfCustomers");
        }
    };


    const getSectors = async (id) => {
        try {
            const response = await http.get(`empresa/rubro/get/${id}`);
            if (response.data.data) {
                const optionsRubro = response.data.data.map((e) => {
                    return {
                        label: e.nombre,
                        value: e.id
                    };
                });
                return optionsRubro;
            } else {
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getSectors");
        }
    };

    return {

        getRUC,
        getLocations,
        getTypesOfCustomers,
        getSectors
    }
}

export default SalesCommon;