import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import AuthUser from '../../../../AuthUser';
import ModalExamenComponent from '../../../../Components/RecursosHumanos/Reclutamiento/ModalExamen';
import '../../../../Style/TablaCrudd.css';
import Logo from '../../../../Images/ICONOFOLDER.svg';
import Logot from '../../../../Images/Pure/MenuPrincipal/img-icons/col.png';
import BotonEstado from '../../../../Components/RecursosHumanos/Reclutamiento/BotonEstadoTraz';
import { Checkbox } from 'primereact/checkbox';
import comments from '../../../../Images/Comments.svg';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import completado from '../../../../Images/com.png';
import enproceso from '../../../../Images/proc.png';
import enespera from '../../../../Images/esp.png';
import { Dropdown } from 'primereact/dropdown';
import { useRef } from 'react';
import { Stepper, Step, StepLabel, Box, StepContent, Paper, Typography} from '@mui/material';
import { Calendar } from 'primereact/calendar';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';



const PageIngreso = () => {
  const { http } = AuthUser();
  const [personal, setPersonal] = useState([]);
  const [visibleExamen, setVisibleExamen] = useState(false);
  const [observado, setObservado] = useState(false);

  useEffect(() => {
    getPersonal();
  }, []);

  useEffect(() => {
    const hasObservado = personal.some((item) => item.estado === 'Observado');
    setObservado(hasObservado);
  }, [personal]);

  const getPersonal = () => {
    http
      .get('empresa/personal/show/get')
      .then((response) => {
        console.log('personal', response.data?.data);
        setPersonal(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionTemplateExamen = (rowData) => (
    <Button
      icon="pi pi-send"
      label="Enviar exámen médico"
      onClick={() => setVisibleExamen(true)}
      className="BOTON_EnVIARExaMEN p-button-text"
      style={{ height: '32px' }}
    />
  );

  /*Subida de aarchivos */
  const [visible, setVisible] = useState(false)

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const toggleSelectItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const [selectedCity, setSelectedPlan] = useState(null);
  const cities = [
      { name: '----------', code: 'BL' },
      { name: 'Plan gen plus', code: 'Pl' },
      { name: 'Plan gen prime', code: 'Pr' },
      { name: 'Plan gen ultra', code: 'Ul' },
      { name: 'Plan gen familia', code: 'Fa' },
    ]

  const [selectedFil, setSelectedFiltro] = useState(null);
  const elm = [
      { name: '----------', code: 'BL' },
      { name: 'Ver por plan', code: 'Pla' },
      { name: 'Ver por estado', code: 'Est' },
      { name: '#', code: 'El1' },
      { name: '#', code: 'El2' },
    ]

  const [datetime12h, setDateTime12h] = useState(null);
  const [datetime12ha, setDateTime12ha] = useState(null);
  const [datetime12hb, setDateTime12hb] = useState(null);
  const [datetime12hc, setDateTime12hc] = useState(null);
  const [datetime12hd, setDateTime12hd] = useState(null);
  const [datetime12he, setDateTime12he] = useState(null);
  const [datetime12hf, setDateTime12hf] = useState(null);
  

  const steps = [
  {
    label: 'Toma de muestra',
    description:
      'Selecciona fecha y hora',
    calendario:
      <Calendar id="calendar-12h" value={datetime12h} onChange={(e) => setDateTime12h(e.value)} showTime hourFormat="12" showIcon style={{paddingTop:"20px", paddingBottom:"10px" }} />
  },
  {
    label: 'Extraccion de ADN',
    description:
      'Selecciona fecha y hora.',
    calendario:
      <Calendar id="calendar-12h" value={datetime12ha} onChange={(e) => setDateTime12ha(e.value)} showTime hourFormat="12" showIcon style={{paddingTop:"20px", paddingBottom:"10px"}} />   
  },
  {
    label: 'Envio de ADN',
    description:
    'Selecciona fecha y hora',
    calendario:
      <Calendar id="calendar-12h" value={datetime12hb} onChange={(e) => setDateTime12hb(e.value)} showTime hourFormat="12" showIcon style={{paddingTop:"20px", paddingBottom:"10px"}} />  
  },
  {
    label: 'Secuenciamiento de ADN',
    description:
    'Selecciona fecha y hora',
    calendario:
      <Calendar id="calendar-12h" value={datetime12hc} onChange={(e) => setDateTime12hc(e.value)} showTime hourFormat="12" showIcon style={{paddingTop:"20px", paddingBottom:"10px"}} /> 
  },
  {
    label: 'Analisis de Secuenciamiento',
    description:
      'Selecciona fecha y hora',
    calendario:
      <Calendar id="calendar-12h" value={datetime12hd} onChange={(e) => setDateTime12hd(e.value)} showTime hourFormat="12" showIcon style={{paddingTop:"20px", paddingBottom:"10px"}} />
  },
  {
    label: 'Generacion de resultados',
    description:
      'Selecciona fecha y hora',
    calendario:
      <Calendar id="calendar-12h" value={datetime12he} onChange={(e) => setDateTime12he(e.value)} showTime hourFormat="12" showIcon style={{paddingTop:"20px", paddingBottom:"10px"}} />
  },
  {
    label: 'consulta medica',
    description:
      'Selecciona fecha y hora',
    calendario:
      <Calendar id="calendar-12h" value={datetime12hf} onChange={(e) => setDateTime12hf(e.value)} showTime hourFormat="12" showIcon style={{paddingTop:"20px", paddingBottom:"10px"}} />
  }
];


  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  

  return (
    <div className="gestion-ingreso">
      <div className="flex flex-column" style={{ width: "477px", marginBottom: "34px" }}>
        <h1 className="titulo" style={{ marginBottom: "10px" }}>Gestion de trazabilidad</h1>
        <p className="subtitulo">Gestiona trazabilidad de tus pacientes.</p>
      </div>
      <div className="CONTENEDORDELATABLAINGRESO flex flex-column">

        <div className='Contenedor-encabezado'>
          <div className="flex flex-wrap justify-content-between align-items-center">
            <div>
              <div className="CONTENEDORICONOINGRESO flex">
                <img src={Logo} alt="" />
              </div>

              <div>
                <span style={{ fontWeight: '700', fontSize: '18px', color: '#5555D8' }}>Lista de pacientes</span>
              </div>
            </div>

            <div className="flex-column flex-row">
              <div>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" style={{ fontSize: '19px', color: '#4545BC' }} />
                  <InputText
                    placeholder="Buscador"
                    style={{ fontWeight: '400',marginBottom:"20px" , fontSize: '14px', color: 'rgba(69, 69, 188, 0.8)', height: '43px', borderRadius: '13px', width: '470px', fontFamily: 'Montserrat', border: '1px solid #eeeeee' }}
                  />
                </span>
              </div>
              <div className='flex'>
                <div className="card flex justify-content-center" style={{width:"150px",display:"block", height:"40px"}}>
                  <Dropdown value={selectedCity} onChange={(e) => setSelectedPlan(e.value)} options={cities} optionLabel="name" 
                  className="w-full md:w-14rem"/>
                </div>
                <div className="card flex justify-content-center" style={{width:"150px", height:"40px", marginLeft:"15px"}}>
                  <Dropdown value={selectedFil} onChange={(e) => setSelectedFiltro(e.value)} options={elm} optionLabel="name" 
                  className="w-full md:w-14rem"/>
                </div>
                <div className="card flex justify-content-center" style={{width:"150px", height:"40px", marginLeft:"15px"}}>
                  <Button label="Aplicar filtros"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <table className="TABLACRUDINGRESO">
          <thead>
            <tr>
              <th className='item'>Item</th>
              <th className='reclutado'>Nombre y Apellido</th>
              <th className='comentario'>DNI</th>
              <th className='resultados'>Fecha de atencion</th>
              <th className='estado'>Plan elegido</th>
              <th className='estado-resultado'>Estado</th>
              <th className='interconsulta'>Accion</th>
            </tr>
          </thead>
          <tbody>
            {personal.map((item) => (
              <tr key={item.id}>
                <td className='id' style={{ borderLeft: '0px' }}>{item.id}</td>
                <td className='nombre'>{item.persona.nombres}</td>
                <td className='dni'>{item.persona.dni}</td>
                <td className='Fech'>{item.persona.dni}</td>
                <td>{actionTemplateExamen(item)}</td>
                <td>
                  <BotonEstado estado="Apto" />
                </td>
                <td>
                  {observado ? <Button label="Subir" className="BOTON_SUBIR_INTERCONSULTA" /> : null}
                </td>
              </tr>
            ))}
            <tr key={1}>
              <td className='id' style={{ borderLeft: '0px' }}> 1</td>
              <td className='nombre' >Arturo Juan</td>
              <td className='dni'>1111111</td>
              <td className='fech'>11/11/1111</td>
              <td><BotonEstado estado="Plan gen plus" /></td>
              <td><div><img src={completado} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
              </Button>
              </td>
            </tr>

            <tr key={2}>
              <td className='id' style={{ borderLeft: '0px' }}> 2</td>
              <td className='nombre' >Arturo Juan</td>
              <td className='dni'>1111111</td>
              <td className='fech'>11/11/1111</td>
              <td><BotonEstado estado="Plan gen prime" /></td>
              <td><div><img src={enproceso} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
              </Button>
              </td>
            </tr>

            <tr key={3}>
              <td className='id' style={{ borderLeft: '0px' }}> 3</td>
              <td className='nombre' >Arturo Juan</td>
              <td className='dni'>1111111</td>
              <td className='fech'>11/11/1111</td>
              <td><BotonEstado estado="Plan gen familia" /></td>
              <td><div><img src={enespera} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
              </Button>
              </td>
            </tr>
            <tr key={4}>
              <td className='id' style={{ borderLeft: '0px' }}>4</td>
              <td className='nombre' >Arturo Juan</td>
              <td className='dni'>1111111</td>
              <td className='fech'>11/11/1111</td>
              <td><BotonEstado estado="Plan gen ultra" /></td>
              <td><div><img src={completado} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
              </Button>
              </td>
            </tr>

            <tr key={5}>
              <td className='id' style={{ borderLeft: '0px' }}>5</td>
              <td className='nombre' >Arturo Juan</td>
              <td className='dni'>1111111</td>
              <td className='fech'>11/11/1111</td>
              <td><BotonEstado estado="Plan gen familia" /></td>
              <td><div><img src={enespera} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
              </Button>
              </td>
            </tr>

            <tr key={6}>
              <td className='id' style={{ borderLeft: '0px' }}>6</td>
              <td className='nombre' >Arturo Juan</td>
              <td className='dni'>1111111</td>
              <td className='fech'>11/11/1111</td>
              <td><BotonEstado estado="Plan gen familia" /></td>
              <td><div><img src={enespera} alt="" style={{ width: '120px' }} /></div> {/* codigo orginal {actionTemplateExamen} */}</td>
              <td>
              <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}} onClick={() => setVisible(true)}>
                <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
              </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="card flex justify-content-center">
            <Dialog visible={visible} style={{ width: '70vw', height:"100%" }} onHide={() => setVisible(false)}>
              <div className='flex'>
                <div className='aa' style={{marginLeft:"20px",width:"60%"}}>
                  <div className='flex-block'>
                    <div className="CONTENEDORICONOINGRESO flex">
                      <img src={Logot} alt="" />
                    </div>
                    <div className='flex-block'>
                      <span style={{ fontWeight: '700', fontSize: '18px', color: '#5555D8' }}>Trazabilidad</span>
                      <p>Aqui usted podra definir el proceso de examen de paciente</p>
                    </div>
                    <div style={{paddingLeft:"60px"}}>
                      <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                          {steps.map((step, index) => (
                            <Step key={step.label}>
                              <StepLabel>
                                {step.label}
                              </StepLabel>
                              <StepContent>
                                <Typography>{step.description}</Typography>
                                <Typography>{step.calendario}</Typography>
                                <Box sx={{ mb: 2 }}>
                                  <div>
                                    
                                    <Button
                                      variant="contained"
                                      onClick={handleNext}
                                      sx={{ mt: 1, mr: 1 }}
                                      style={{marginRight:"10px"}}
                                    >
                                      {index === steps.length - 1 ? 'Finish' : 'Siguiente'}
                                    </Button>
                                    <Button
                                      disabled={index === 0}
                                      onClick={handleBack}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Anterior
                                    </Button>
                                  </div>
                                </Box>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                        {activeStep === steps.length && (
                          <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>Link de Reunion</Typography>
                            <div className="p-inputgroup">
                              <InputText placeholder="Username" />
                              <span className="p-inputgroup-addon">
                                  <i className="pi pi-video"></i>
                              </span>
                            </div>
                            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Reset
                            </Button>  
                          </Paper>
                        )}
                      </Box>

                    </div>
                  </div>
                </div>
                <div className='aa' style={{paddingLeft:"20px", borderLeft:"3px dotted #384cff", width:"40%"}}>
                  <h1 style={{textAlign:"center"}}>Detalle del proceso de examen</h1>
                  <div className='flex justify-content-center' style={{paddingTop:"40px"}}>
                    <div style={{textAlign:"center"}}>
                      <Avatar image="Images/saludo1" size="xlarge" shape="circle" />
                    </div>
                    <div className='justify-content-center' style={{paddingLeft:"10px"}}>
                      <span style={{fontSize:"20px"}}>Arturo Juan</span>
                      <BotonEstado estado="Plan gen familia" />
                    </div>
                  </div>
                  <div className='flex-column justify-content-center' style={{paddingTop:"40px", textAlign:"center"}}>
                    <h1 style={{backgroundColor:"#dcdde0", color:"#7f8dc9", textAlign:"center"}}>Toma de muestra</h1>
                    <span style={{textAlign:"center",paddingTop:"10px", paddingBottom:"10px"}}>No especificado</span>
                  </div>
                  <div className='flex-column justify-content-center' style={{paddingTop:"40px", textAlign:"center"}}>
                    <h1 style={{backgroundColor:"#dcdde0", color:"#7f8dc9", textAlign:"center"}}>Extraccion de ADN</h1>
                    <span style={{textAlign:"center",paddingTop:"10px", paddingBottom:"10px"}}>No especificado</span>
                  </div>
                  <div className='flex-column justify-content-center' style={{paddingTop:"40px", textAlign:"center"}}>
                    <h1 style={{backgroundColor:"#dcdde0", color:"#7f8dc9", textAlign:"center"}}>Envio de ADN</h1>
                    <span style={{textAlign:"center",paddingTop:"10px", paddingBottom:"10px"}}>No especificado</span>
                  </div>
                  <div className='flex-column justify-content-center' style={{paddingTop:"40px", textAlign:"center"}}>
                    <h1 style={{backgroundColor:"#dcdde0", color:"#7f8dc9", textAlign:"center"}}>Secuenciamiento de ADN</h1>
                    <span style={{textAlign:"center",paddingTop:"10px", paddingBottom:"10px"}}>No especificado</span>
                  </div>
                  <div className='flex-column justify-content-center' style={{paddingTop:"40px", textAlign:"center"}}>
                    <h1 style={{backgroundColor:"#dcdde0", color:"#7f8dc9", textAlign:"center"}}>Analicis de Secuenciamiento</h1>
                    <span style={{textAlign:"center",paddingTop:"10px", paddingBottom:"10px"}}>No especificado</span>
                  </div>
                  <div className='flex-column justify-content-center' style={{paddingTop:"40px", textAlign:"center"}}>
                    <h1 style={{backgroundColor:"#dcdde0", color:"#7f8dc9", textAlign:"center"}}>Generacion de resultados</h1>
                    <span style={{textAlign:"center",paddingTop:"10px", paddingBottom:"10px"}}>No especificado</span>
                  </div>
                  <div className='flex-column justify-content-center' style={{paddingTop:"40px", textAlign:"center"}}>
                    <h1 style={{backgroundColor:"#dcdde0", color:"#7f8dc9", textAlign:"center"}}>Entrevista</h1>
                    <span style={{textAlign:"center",paddingTop:"10px", paddingBottom:"10px"}}>No especificado</span>
                  </div>
                </div>
              </div>
            </Dialog>
      </div>
    </div>
  );
}

export default PageIngreso;