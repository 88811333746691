import React, { useRef, useEffect, useState } from "react";
import PersonaComponentCoorporativo from "../../../Components/Perfil/Coorporativo/PersonaCoorporativo";
import ProveedorComponentCoorporativa from "../../../Components/Perfil/Coorporativo/ProveedorCoorporativo";
import PermisosComponent from "../../../Components/Perfil/usuario/Permisos";
import AuthUser from "../../../AuthUser";
import InboxComponent from "./Inbox/Inbox";
import Logo from "../../../Images/kerfin7_nea_2746 1.png";
import { Toast } from "primereact/toast";
import "../../../Style/PagePerfil1.css";
import "./MediaQueryPerfilEmpresa.css"
//import SectionNotificacionCoorporativo01 from './SeccionNotificacionesCoorp01';

function PagePerfilProveedor({ getAllPersona }) {
    const toast = useRef(null);
    const { http } = AuthUser();
    const [tipoDcoumentos, setTipoDcoumentos] = useState([]);
    const [empresa, setEmpresa] = useState({
        id: 0,
        tipo_documento_id: null,
        distrito_id: null,
        numero_documento: null,
        razon_social: null,
        direccion: null,
        estado_pago: null,
        latitud: null,
        longitud: null,
        tipo_documento: null,
        distrito: null,
        celulares: null,
        correos: null,
        detracciones: null,
        entidad_pagos: null
    });
    const [empresaUpdate, setEmpresaUpdate] = useState([])
    const [persona, setPersona] = useState([])
    const [personaUpdate, setPersonaUpdate] = useState([])
    const getEmpresa = () => {
        http
            .get("empresa/show")
            .then((response) => {
                console.log("empresaaaaa", response.data);
                setEmpresa(response?.data);
                setEmpresaUpdate(response?.data);
            })
            .catch((error) => {
                console.error("errrror", error);
            });
    };

    const getAllTipoDocumentos = () => {
        http
            .get("/tipodocumentos/get")
            .then((response) => {
                if (response?.data?.data) {
                    setTipoDcoumentos(response.data.data);
                } else if (response?.data?.error) {
                    showToast('error', 'Error', response.data.error)
                }
            })
            .catch((error) => {
                showToast('error', 'Error', 'Contáctese con soporte técnico.')
            });
    };

    const getPersona = () => {
        http
            .get('persona/show')
            .then((response) => {
                console.log("personaaa", response?.data?.data?.persona)
                setPersona(response?.data?.data?.persona);
                setPersonaUpdate(response?.data?.data?.persona);
                getAllPersona();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleSubmitUpdate = (id, data) => {
        console.log("data antes de enviar", data)
        http
            .post(`empresa/update/me/login`, data)
            .then((response) => {
                console.log("se actualizo")
                getEmpresa();
                showToast(
                    "success",
                    "Datos actualizados correctamente",
                    "Los datos de empresa se actualizaron correctamente"
                )
            })
            .catch((error) => {
                console.error(error);
            })
    }

    const showToast = (type, title, detail) => {
        console.log("entro al toast");
        toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
        });
    };

    useEffect(() => {
        getEmpresa();
        getAllTipoDocumentos();
        getPersona();
    }, [])


    return (
        <>
            <Toast ref={toast} />
            <div className="ContenedorGeneralPerfil flex flex-wrap flex-column mt-3 pr-6 pl-6" style={{ gap: '1.5rem' }}>
                <div>
                    <p className="tit "> Mi Perfil </p>
                    <p className="con" style={{ maxWidth: '333px' }}>Adminístralo a tu mejor manera </p>
                </div>
                <div className="gap-3 perfil-res ContenedorGeneralComponente" style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className=" flex flex-wrap ContenedorComponentePersona">
                        <PersonaComponentCoorporativo
                            showToast={showToast}
                            persona={persona}
                            personaUpdate={personaUpdate}
                            setPersonaUpdate={setPersonaUpdate}
                            getPersona={getPersona}
                            institucion={"Asociado"}
                        />
                    </div>
                    <div className="flex flex-wrap ContenedorComponenteEmpresa">
                        <ProveedorComponentCoorporativa
                            dataShow={empresa}
                            data={empresaUpdate}
                            setBregma={setEmpresaUpdate}
                            getBregma={getEmpresa}
                            tiposDocumentos={tipoDcoumentos}
                            handleSubmitUpdate={handleSubmitUpdate}
                        />
                    </div>

                </div>
                <div className=" w-full flex flex-row gap-3">
                    {/*
                    <SectionNotificacionCoorporativo01 
                        RazonS={empresa.razon_social}
                    >

                    </SectionNotificacionCoorporativo01>
                     */}

                    {/* <div className="" style={{border: '2px solid black'}}>
                        <PermisosComponent namefull={persona?.nombres + " " + persona?.apellido_paterno + " " + persona?.apellido_materno} />
                    </div>
                    <div className="w-full flex flex-column gap-1" style={{border:'2px solid orange'}}>
                        <div className="flex flex-row">
                            <InboxComponent />
                            <img src={Logo} alt="" className="IMGDELTERCERROL" />
                        </div>

                    </div> */}
                </div>
            </div>
        </>
    )
}

export default PagePerfilProveedor