import React from 'react';
import { useRef,useEffect,useState } from 'react';
import { Button } from 'primereact/button';
import { SlideMenu } from 'primereact/slidemenu';

const ActionTemplate = ({ rowData, setVisible2, setVisible3, setvisibleEdAr, setRowData }) =>  {
    const menu = useRef(null);
    const items = [
    {
        // label: 'Agregar',
        icon: 'pi pi-plus',
        className: 'p-button-success',
        command: () => {
        // Lógica para la opción "Agregar"
        setVisible2(true);
        setRowData(rowData);
        console.log('Opción Agregar seleccionada');
        },
    },
    {
        // label: 'Eliminar',
        icon: 'pi pi-trash',
        className: 'p-button-danger',
        command: () => {
        // Lógica para la opción "Eliminar"
        setVisible3(true);
        setRowData(rowData);
        console.log('Opción Eliminar seleccionada');
        },
    },
    {
        //label: 'Editar',
        icon: 'pi pi-pencil',
        className: 'p-button-info',
        command: () => {
        // Lógica para la opción "Editar"
        setvisibleEdAr(true);
        setRowData(rowData);
        console.log('Opción Editar seleccionada');
        }, 
    },
    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
    // Función para actualizar el estado del ancho de la pantalla
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Agregar el event listener para actualizar el ancho de pantalla
    window.addEventListener('resize', handleResize);

    // Limpieza del event listener cuando el componente se desmonte
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    if (windowWidth < 741) {
    return (
        <div className="flex gap-2">
            <div className="card flex justify-content-center button-bars">
                <SlideMenu ref={menu} model={items} popup viewportHeight={145} menuWidth={47} className='MenuDesplegable'></SlideMenu>
                <Button type="button" icon="pi pi-bars" outlined onClick={(event) => menu.current.toggle(event)} o></Button>
            </div>
        </div>
    );
    }else {
        return (
            <div className="flex gap-2">
            <Button
                type="button"
                icon="pi pi-plus"
                severity="success"
                onClick={() => {
                    setVisible2(true);
                    setRowData(rowData);
                }}
            ></Button>
            <Button
                icon="pi pi-trash text-red-500 text-xl"
                className="bg-red-100  border-0"
                onClick={() => {
                    setVisible3(true);
                    setRowData(rowData);
                }}
            />

            <Button
                icon="pi pi-pencil text-green-700 text-xl"
                className=" bg-green-200 border-0"
                onClick={() => {
                    setvisibleEdAr(true);
                    setRowData(rowData);
                }}
            />
            </div>
        );
    };
}
/*Menu desplegable*/ /*
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { SlideMenu } from 'primereact/slidemenu';

    const ActionTemplate = ({ rowData, setVisible2, setVisible3, setvisibleEdAr, setRowData }) =>  {
        const menu = useRef(null);
        const items = [
        {
            // label: 'Agregar',
            icon: 'pi pi-plus',
            className: 'p-button-success',
            command: () => {
            // Lógica para la opción "Agregar"
            setVisible2(true);
            setRowData(rowData);
            console.log('Opción Agregar seleccionada');
            },
        },
        {
            // label: 'Eliminar',
            icon: 'pi pi-trash',
            className: 'p-button-danger',
            command: () => {
            // Lógica para la opción "Eliminar"
            setVisible3(true);
            setRowData(rowData);
            console.log('Opción Eliminar seleccionada');
            },
        },
        {
            //label: 'Editar',
            icon: 'pi pi-pencil',
            className: 'p-button-info',
            command: () => {
            // Lógica para la opción "Editar"
            setvisibleEdAr(true);
            setRowData(rowData);
            console.log('Opción Editar seleccionada');
            }, 
        },
        ];
    
        return (
        <div className="flex flex-wrap gap-2">
            <div className="card flex justify-content-center button-bars">
                <SlideMenu ref={menu} model={items} popup viewportHeight={145} menuWidth={47} className='MenuDesplegable'></SlideMenu>
                <Button type="button" icon="pi pi-bars" outlined onClick={(event) => menu.current.toggle(event)} o></Button>
            </div>
        </div>
        );
    };
*/
export default ActionTemplate;