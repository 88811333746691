import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from "primereact/button";
import AuthUser from "../../AuthUser";
import { iconAudiometria } from "../../Services/importIcons";
import { AudContext } from "./AudContext";
import { TabPanel, TabView } from "primereact/tabview";
import PageAudPrincipal from "./Principal/PageAudPrincipal";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import { RAContext } from '../RutaAtencion/RAContext';
import RATimeView from '../../Components/RutaAtencion/Atencion/RATimeView';
import ModalCancelarAtencion from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion';
import ModalGuardarAtencion from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion';
import BottomsFootComponent from '../../Components/Clinica/AtencionMedica/pure/BottomsFoot';

const PageAud = ({ revision }) => {
   const { http } = AuthUser();
   const toast = useRef(null);
   const { idPaciente } = useParams();

   let { principalContext, setPrincipalContext, audData, setAtencionStatus, setTimeStart } = useContext(AudContext);
   let { stateAtencion, setStateAtencion, pageViewContext, audiometriaAreasContext, audiometriaDetalleId, setAudiometriaDetalleId } = useContext(RAContext); // 1. Importar RAContext

   useEffect(() => {
      console.log("ddddd:", principalContext)
   }, [principalContext])

   const [visibleImprimir, setVisibleImprimir] = useState(false)
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [visibleCancelar, setVisibleCancelar] = useState(false);
   const [activeIndex, setActiveIndex] = useState(0);
   const [savingStatus, setSavingStatus] = useState(false);
   const [idAudiometria, setIdAudiometria] = useState(null);
   const [estado, setEstado] = useState(false);
   const [documentos, setDocumentos] = useState([])

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };

   //INICIAR ATENCION
   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setAudiometriaDetalleId({
               id: audiometriaDetalleId.id,
               estado: 1,
               hora_inicio: audiometriaDetalleId.hora_inicio,
               fecha_inicio: audiometriaDetalleId.fecha_inicio,
               hora_fin: audiometriaDetalleId.hora_fin,
               fecha_fin: audiometriaDetalleId.fecha_fin,
            })
         })
   }

   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            console.log(response);
            setAudiometriaDetalleId({
               id: audiometriaDetalleId.id,
               estado: 2,
               hora_inicio: audiometriaDetalleId.hora_inicio,
               fecha_inicio: audiometriaDetalleId.fecha_inicio,
               hora_fin: audiometriaDetalleId.hora_fin,
               fecha_fin: audiometriaDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setAudiometriaDetalleId({
                  id: audiometriaDetalleId.id,
                  estado: 2,
                  hora_inicio: audiometriaDetalleId.hora_inicio,
                  fecha_inicio: audiometriaDetalleId.fecha_inicio,
                  hora_fin: audiometriaDetalleId.hora_fin,
                  fecha_fin: audiometriaDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.log(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }

   //CREAR EL ID DE AUDIOMETRIA
   const audiometriaCreate = () => {
      http
         .post("audiometria/create", { paciente_id: idPaciente })
         .then((response) => {
            //SETEAR EL ID_AUDIOMETRIA
            setIdAudiometria(response.data.audiometria_id);
            showToast('success', 'Éxito', response.data.resp);

         }).catch((error) => {
            console.log(error);
            showToast('error', 'Error', error.message);
         });
   }

   //obtener la información del audiometria del paciente
   const getPacienteAudiometria = () => {
      http
         .get(`audiometria/get/${idPaciente}`)
         .then((response) => {

            console.log(response?.data?.resp?.id)
            if (response?.data?.resp) {
               setIdAudiometria(response?.data?.resp?.id);
               getAudiometriaPrincipal();
            }

         })
         .catch(err => {
            console.log("No hay audiometria")
         });
   }

   const createAudiometriaPrincipal = (data) => {

      http
         .post(`audiometria/principal/create/${idAudiometria}`, data) //
         .then((response) => {
            console.log("Creado correctamente", response.data);
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Audiometria");
         });
   }

   const getAudiometriaPrincipal = () => {
      console.log(idAudiometria)
      http
         .get(`audiometria/principal/get/${idPaciente}`) //
         .then((response) => {
            console.log("Creado correctamente", response.data);
            let data = response?.data?.resp;
            if (response?.data?.resp) {
               let auxAntecedente = [
                  {
                     patologia_id: 270,
                     resultado: "no",
                     observaciones: "",
                  },
                  {
                     patologia_id: 271,
                     resultado: "no",
                     observaciones: "",
                  },
                  {
                     patologia_id: 272,
                     resultado: "no",
                     observaciones: "",
                  },
                  {
                     patologia_id: 273,
                     resultado: "no",
                     observaciones: "",
                  },
                  {
                     patologia_id: 274,
                     resultado: "no",
                     observaciones: "",
                  },
                  {
                     patologia_id: 275,
                     resultado: "no",
                     observaciones: "",
                  },
                  {
                     patologia_id: 276,
                     resultado: "no",
                     observaciones: "",
                  },
               ]
               let auxSintomas = [
                  {
                     patologia_id: 277,
                     resultado: "no",
                     observaciones: "",
                  }, {
                     patologia_id: 278,
                     resultado: "no",
                     observaciones: "",
                  }, {
                     patologia_id: 279,
                     resultado: "no",
                     observaciones: "",
                  }, {
                     patologia_id: 280,
                     resultado: "no",
                     observaciones: "",
                  }, {
                     patologia_id: 281,
                     resultado: "no",
                     observaciones: "",
                  }, {
                     patologia_id: 282,
                     resultado: "no",
                     observaciones: "",
                  }, {
                     patologia_id: 283,
                     resultado: "no",
                     observaciones: "",
                  },
               ]
               setPrincipalContext({
                  AudCae: data.aud_cae,
                  AudTimpanos: data.aud_timpanos,
                  AudAudicion: data.aud_audicion,
                  AudOtoscopia: data.aud_otoscopia,
                  AudInterpretacion: data.aud_interpretacion,
                  observaciones: data.observaciones,
                  metodo_clasificacion: data.metodo_clasificacion,
                  audiograma: data.audiograma ? data?.audiograma?.map(elemento => {

                     return {
                        audFichaAudiologica: {
                           ...elemento.aud_ficha_audiologica,
                           aud_fich_antecedentes: elemento.aud_ficha_audiologica.aud_fich_antecedentes.length > 0 ? elemento.aud_ficha_audiologica.aud_fich_antecedentes : auxAntecedente,
                           aud_fich_sintomas: elemento.aud_ficha_audiologica.aud_fich_sintomas.length > 0 ? elemento.aud_ficha_audiologica.aud_fich_sintomas : auxSintomas,
                        },
                        audiometria: elemento.audiometrias,
                     }
                  }) : []
               })
            }
         })
         .catch((error) => {
            console.log(error);
            console.log("Error en Datos Audiometria");
         });
   }

   const handleSubmit = () => {
      console.log("Principal", principalContext);
      console.log("Principal", audData);

      const allFetchingg = [
         createAudiometriaPrincipal(principalContext),
         // GetAudio(principalContext)
      ]
      Promise.allSettled(allFetchingg)
         .then((results) => {
            results.forEach((result) => console.log(result.status));
            showToast(
               "success",
               `Atención en Audiometria guardada`,
               `Se guardó la atención correctamente`
            );
            setSavingStatus(false);
         })
         .catch(() => {
            setSavingStatus(false);
         })

   }

   const handleGetBD = () => {
      showToast('success', 'Borrado correctamente', "Datos borrados correctamente");
      getAudiometriaPrincipal();
   }

   const tabSubmitHeader = (options) => (
      <Button
         icon={savingStatus ? `pi pi-spin pi-spinner` : `pi pi-save`}
         label={savingStatus ? 'Guardando ...' : options.titleElement}
         className="p-button-sm"
      />
   );


   const renderTabs = (area) => {
      const optionMapping = {
         59: (
            <TabPanel header="Principal" >
               <PageAudPrincipal />
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };


   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      getPacienteAudiometria();
   }, [])

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               audiometriaAreasContext?.map(area => {
                  return renderTabs(area)
               })
            }
         </TabView>
         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
         />
         <ModalCancelarAtencion
            visible={visibleCancelar}
            Atencion="Audiometría"
            onNo={() => setVisibleCancelar(false)}
            onYes={() => { handleGetBD(); setVisibleCancelar(false) }}
            onHide={() => setVisibleCancelar(false)}
         />
         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="Audiometría"
            onNo={() => setVisibleGuardar(false)}
            onYes={() => { handleSubmit(); setVisibleGuardar(false) }}
            onHide={() => setVisibleGuardar(false)}
         />
         {audiometriaDetalleId.estado === '0' && (
            <RATimeView
               title="Audiometría"
               AreaDetalleId={audiometriaDetalleId?.id}
               icon={iconAudiometria}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={audiometriaCreate}
               textButton='Iniciar atención'

            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title="Audiometria"
               AreaDetalleId={audiometriaDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar revisión'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={audiometriaDetalleId?.hora_final}
               VerHoraInicio={audiometriaDetalleId?.hora_inicio}
               VerfechaInicio={audiometriaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}

         {estado && revision !== 1 && (
            <RATimeView
               title="Audiometria"
               AreaDetalleId={audiometriaDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={audiometriaDetalleId?.hora_final}
               VerHoraInicio={audiometriaDetalleId?.hora_inicio}
               VerfechaInicio={audiometriaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PageAud;