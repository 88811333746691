import react, { useState, useContext, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { RecepcionContext } from "./RecepcionProvider";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import AuthUser from '../../AuthUser';
import axios from "axios";

function DatosApoderado({ tiposDocumentos }) {
  const { http } = AuthUser();
  const { datosGeneralesParticular, setDatosGeneralesParticular, camposInvalidos } = useContext(RecepcionContext);
  const [mostrarMensaje, setMostrarMensaje] = useState(false);
  const [botonHabilitado, setBotonHabilitado] = useState(true);
  const toast = useRef(null);

  const initialValuesApoderado = {
    id: 0,
    tipo_documento: tiposDocumentos[0],
    tipo_documento_id: tiposDocumentos[0]?.id,
    numero_documento: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: ""
  };

  const showToast = (tipo, titulo, detalle) => {
    toast.current.show({
      severity: tipo,
      summary: titulo,
      detail: detalle,
      life: 3000,
    });
  };

  const formikApoderado = useFormik({
    initialValues: initialValuesApoderado,
    onSubmit: (data) => {
      console.log("data", data);
      const formDataSend = new FormData();
      for (var key in data) {
        formDataSend.append(key, data[key]);
      }
      formDataSend.append("tipo_documento_id", datosGeneralesParticular?.apoderado?.tipo_documento?.id)
      formDataSend.append("numero_documento", datosGeneralesParticular?.apoderado?.numero_documento);

      // formDataSend.append("empresa_cargo_id", datosGeneralesParticular.empresa_cargo?.id)

      // formDataSend.append("rol_id", data?.rol?.id)
    },

  });

  console.log("ID APODERADO", datosGeneralesParticular?.apoderado?.tipo_documento?.id)

  const getNombreCompletoApoderado = (numdocumento, tipoDocumento) => {
    if (formikApoderado.values.tipo_documento !== "") {
      if (formikApoderado.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            console.log("DATOS APODERADO", res.data)
            formikApoderado.setValues({
              tipo_documento: tipoDocumento,
              numero_documento: numdocumento,
              nombres: res.data.nombres,
              apellido_paterno: res.data.apellidoPaterno,
              apellido_materno: res.data.apellidoMaterno,
              fecha_nacimiento: res.data.fechaNacimiento,
            })

            if (res.data.apellidoPaterno !== undefined) {
              setDatosGeneralesParticular({
                ...datosGeneralesParticular,
                apoderado: {
                  ...datosGeneralesParticular.apoderado,
                  apellido_paterno: res.data.apellidoPaterno,
                  apellido_materno: res.data.apellidoMaterno,
                  nombres_completos: res.data.nombres,
                },
              })
            } else {
              showToast(
                "error",
                "Número de documento no encontrado",
                `No se encontró ningun dato`
              );
            }
          })

      }
    } else {
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }

  console.log("Posible APODERADO", formikApoderado.values)

  const handleValidarClickApoderado = () => {
    if (botonHabilitado) {
      setBotonHabilitado(false);

      getNombreCompletoApoderado(
        datosGeneralesParticular.apoderado.numero_documento,
        datosGeneralesParticular.apoderado.tipo_documento
      );

      console.log("Nombre", formikApoderado.values);

      if (!mostrarMensaje) {
        setMostrarMensaje(true);

        setTimeout(() => {
          setMostrarMensaje(false);
          setBotonHabilitado(true);
        }, 1000);
      }
    }
  };

  return (
    <><Toast ref={toast} />
      <div className="flex flex-wrap flex-column gap-3 w-full" style={{ marginTop: "20px" }}>
        <div className="contenedorD2 flex flex-wrap flex-row p-3 gap-3 w-full">
          <p className="subtituloR">Datos del Apoderado</p>
          <div className="flex flex-wrap flex-row gap-3 w-full">
            <div className="flex flex-wrap flex-column gap-3 flex-1">
              <p className="datos">Tipo de Documento</p>
              <Dropdown
                id="tipo_documento"
                name="tipo_documento"
                value={datosGeneralesParticular?.apoderado?.tipo_documento}
                onChange={(e) => {
                  setDatosGeneralesParticular((prevDatos) => ({
                    ...prevDatos,
                    apoderado: {
                      ...prevDatos.apoderado,
                      tipo_documento: e.value,
                      tipo_documento_id: e.value.id
                    },
                  }));
                  formikApoderado.handleChange(e);
                }}
                options={tiposDocumentos}
                optionLabel="nombre" // Establecer la propiedad del objeto que se mostrará como etiqueta
                placeholder="Selecciona un tipo de documento"
                className={camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.tipo_documento_id ? "dropdown-styles campo-invalido" : "dropdown-styles"}
              />

              {camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.tipo_documento_id && (
                <p className="mensaje-error">¡Falta rellenar este campo!</p>
              )}
            </div>
            <div className="flex flex-wrap flex-column gap-3 flex-1">
              <p className="datos">Nro. de Documento</p>
              <InputText
                id="numero_documento"
                name="numero_documento"
                value={datosGeneralesParticular?.apoderado?.numero_documento}
                onChange={(e) => {
                  setDatosGeneralesParticular((prevDatos) => ({
                    ...prevDatos,
                    apoderado: {
                      ...prevDatos.apoderado,
                      numero_documento: e.target.value,
                    },
                  }));
                  formikApoderado.handleChange(e);
                }}
                required
                className={camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.numero_documento ? "dropdown-styles campo-invalido" : "dropdown-styles"}
              />
              {camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.numero_documento && (
                <p className="mensaje-error">¡Falta rellenar este campo!</p>
              )}

            </div>
            <div className="flex flex-wrap flex-column gap-3 justify-content-end">
              <p className="datos"></p>
              <Button
                label="Validar"
                type="button"
                style={{ background: "#07A34F", borderColor: "#07A34F" }}
                onClick={handleValidarClickApoderado}
                disabled={!botonHabilitado}
              />

              {mostrarMensaje && (
                <p style={{ fontSize: "15px", position: "fixed", top: "40.5%" }}>Inténtalo nuevamente</p>
              )}
            </div>

          </div>
          <div className="flex flex-wrap flex-row gap-3 w-full">
            <div className="flex flex-wrap flex-column gap-3 flex-1">
              <p className="datos">Apellido Paterno</p>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={datosGeneralesParticular?.apoderado?.apellido_paterno}
                onChange={(e) => {
                  const nuevoValor = e.target.value;
                  setDatosGeneralesParticular((prevDatos) => ({
                    ...prevDatos,
                    apoderado: {
                      ...prevDatos.apoderado,
                      apellido_paterno: nuevoValor,
                    }
                  }));
                  formikApoderado.handleChange(e);
                }}
                className={camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.apellido_paterno ? "dropdown-styles campo-invalido" : "dropdown-styles"}
              />
              {camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.apellido_paterno && (
                <p className="mensaje-error">¡Falta rellenar este campo!</p>
              )}

            </div>
            <div className="flex flex-wrap flex-column gap-3 flex-1">
              <p className="datos">Apellido Materno</p>
              <InputText
                id="apellido_materno"
                name="apellido_materno"
                value={datosGeneralesParticular?.apoderado?.apellido_materno}
                onChange={(e) => {
                  const nuevoValor = e.target.value;
                  setDatosGeneralesParticular((prevDatos) => ({
                    ...prevDatos,
                    apellido_materno: nuevoValor,
                  }));
                  formikApoderado.setFieldValue('apellido_materno', nuevoValor);
                }}
                className={camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.apellido_materno ? "dropdown-styles campo-invalido" : "dropdown-styles"}
              />
              {camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.apellido_materno && (
                <p className="mensaje-error">¡Falta rellenar este campo!</p>
              )}
            </div>
            <div className="flex flex-wrap flex-column gap-3 flex-1">
              <p className="datos">Nombres Completos</p>
              <InputText
                id="nombres"
                name="nombres"
                value={datosGeneralesParticular?.apoderado?.nombres_completos}
                onChange={(e) => {
                  const nuevoValor = e.target.value;
                  setDatosGeneralesParticular((prevDatos) => ({
                    ...prevDatos,
                    nombres_completos: nuevoValor,
                  }));
                  formikApoderado.setFieldValue('nombres', nuevoValor);
                }}
                required
                autoComplete="off"
                className={camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.nombres_completos ? "dropdown-styles campo-invalido" : "dropdown-styles"}
              />
              {camposInvalidos.includes('apoderado') && !datosGeneralesParticular?.apoderado?.nombres_completos && (
                <p className="mensaje-error">¡Falta rellenar este campo!</p>
              )}

            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default DatosApoderado;