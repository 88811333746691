import React, { useEffect, useRef , useState} from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import AuthUser from "../../../AuthUser";
import { Button } from "primereact/button";
import icono from "../../../Images/Perfil/icon/ActualizarUsuario.svg";
import { InputText } from "primereact/inputtext";
import "../../../Style/usuario/formulario-persona.css";
import './UpdatePersona.css'

export default function UpdatePersonaComponent({ personaUpdate, visible, setVisible, getPersona, showToast, institucion }) {
   const { http } = AuthUser();
   const toast = useRef(null);

   const initialValues = {
      id: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      celular: "",
      correo: "",
      foto: "",
   };

   const HideDialogEdit = () => {
      setVisible(false);
   };

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (data) => {
         const formDataSend = new FormData();
         formDataSend.append("nombres", data.nombres);
         formDataSend.append("apellido_paterno", data.apellido_paterno);
         formDataSend.append("apellido_materno", data.apellido_materno);
         formDataSend.append("celular", data.celular);
         formDataSend.append("correo", data.correo);
         formDataSend.append("foto", data.foto);

         console.log("DATA SEND", ...formDataSend)
         http
            .post(`persona/update/${data.id}`, formDataSend)
            .then((response) => {
               getPersona();
               HideDialogEdit()
               showToast(
                  "success",
                  "Actualizado correctamente",
                  "Los datos se actualizaron correctamente"
               );
            })
            .catch((error) => {
               console.log(error)
            })
      }
   });
   const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Actualizado correctamente', detail:'Los datos se actualizaron correctamente', life: 3000});
}

   const updatePersonal = () => {
      formik.setValues({
         id: personaUpdate?.id,
         nombres: personaUpdate?.nombres,
         apellido_paterno: personaUpdate?.apellido_paterno,
         apellido_materno: personaUpdate?.apellido_materno,
         celular: personaUpdate?.celular,
         correo: personaUpdate?.correo,
         foto: personaUpdate?.foto,
      })
   }

   const paqueteCreateDialogFooter = (
      <div className="card flex flex-wrap justify-content-center gap-3">
         <Button style={{ marginTop: "10px" }}
            type="submit"
            className=" p-button-raised"
            onClick={showSuccess} 
            >
            <span onClick={showSuccess} style={{ fontWeight: '500', fontSize: '14px', color: '#FFFFFF' }}>Actualizar</span>
         </Button>
         
      </div>
   );

   useEffect(() => {
      updatePersonal()
   }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , [])

    // const headerTemplate = (options) => {
    //     const { className, chooseButton, uploadButton, cancelButton } = options;
    //     const value = totalSize / 10000;
    //     const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    //     return (
    //         <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
    //             {chooseButton}
    //             {uploadButton}
    //             {cancelButton}
    //             <div className="flex align-items-center gap-3 ml-auto">
    //                 <span>{formatedValue} / 1 MB</span>
    //                 <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
    //             </div>
    //         </div>
    //     );
    // };

    // const onTemplateSelect = (e) => {
    //     let _totalSize = totalSize;
    //     let files = e.files;

    //     Object.keys(files).forEach((key) => {
    //         _totalSize += files[key].size || 0;
    //     });

    //     setTotalSize(_totalSize);
    // };

    // const onTemplateUpload = (e) => {
    //     let _totalSize = 0;

    //     e.files.forEach((file) => {
    //         _totalSize += file.size || 0;
    //     });

    //     setTotalSize(_totalSize);
    //     toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    // };

    // const onTemplateRemove = (file, callback) => {
    //     setTotalSize(totalSize - file.size);
    //     callback();
    // };

    // const onTemplateClear = () => {
    //     setTotalSize(0);
    // };

    // const itemTemplate = (file, props) => {
    //     return (
    //         <div className="flex align-items-center flex-wrap">
    //             <div className="flex align-items-center" style={{ width: '40%' }}>
    //                 <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
    //                 <span className="flex flex-column text-left ml-3">
    //                     {file.name}
    //                     <small>{new Date().toLocaleDateString()}</small>
    //                 </span>
    //             </div>
    //             <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
    //             <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
    //         </div>
    //     );
    // };

    // const emptyTemplate = () => {
    //     return (
    //         <div className="flex align-items-center flex-column">
    //             <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
    //             <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
    //                 Arrastra y suelta la imagen aquÃ­
    //             </span>
    //         </div>
    //     );
    // };

    // const paqueteCreateDialogFooter = (
    //     <div className="card flex flex-wrap justify-content-center gap-3">
    //         <Button style={{ marginTop: "10px" }}
    //             type="submit"
    //             className=" p-button-raised"
    //             onClick={formik.handleSubmit} >
    //             <span style={{ fontWeight: '500', fontSize: '14px', color: '#FFFFFF' }}>Actualizar</span>
    //         </Button>
    //     </div>
    // );

    // const handleChangeImagen = (e) => {
    //     console.log("entro cambio de imagen")
    //     formik.setValues({
    //         ...formik.values,
    //         foto: e?.files[0],
    //     })
    // }

    // useEffect(() => {
    //     updatePersonal()
    // }
    //     , [])
    const [InputCel, setInputCel] = useState(false);
    const [InputCorreo, setInputCorreo] = useState(false);

    return (
        <div>
            <form id="persona-form-edit" className="persona-form-edit" noValidate onSubmit={formik.handleSubmit}></form>
            <Dialog
                header={
                    <>
                        <div className="flex flex-column gap-2" >
                            <div className="flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#EBEBFF', borderRadius: '10px', width: '60px', height: '60px' }}>
                                <img src={icono} alt="" style={{ width: '25px', height: '31.25px' }} />
                            </div>
                            <div>
                                <h3 style={{ fontWeight: '700', fontSize: '16px', color: '#5555D8' }}>{institucion}</h3>
                                <p style={{ fontWeight: '500', fontSize: '14px', color: '#7B8794' }}>Complete la información requerida para el administrador de la empresa.</p>
                            </div>
                        </div>
                    </>
                }
                visible={visible} className="form-container" style={{ width: '50vw', maxWidth: "446px", height:'auto' }}
                appendTo={document.getElementById("persona-form-edit")}
                dismissableMask
                footer={paqueteCreateDialogFooter}
                onHide={() => {HideDialogEdit() ; setInputCorreo(false);setInputCel(false)}}
                >


                <div className="Contenedor_input_POPUP">
                        <div className="Inputss_POPUP_Update">
                            <label htmlFor="nombres">Nombres</label>
                            <InputText id="nombres" name="nombres"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={formik.values?.nombres}
                                onChange={formik.handleChange}
                                className="w-full text-area2"
                            />
                        </div>
                        <div className="Inputss_POPUP_Update">
                            <label htmlFor="apellido_paterno">Apellido Paterno</label>
                            <InputText id="apellido_paterno" name="apellido_paterno"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={formik.values?.apellido_paterno}
                                onChange={formik.handleChange}
                                className="w-full text-area2"
                            />
                        </div>
                        <div className="Inputss_POPUP_Update">
                            <label htmlFor="apellido_materno">Apellido Materno</label>
                            <InputText id="apellido_materno" name="apellido_materno"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={formik.values?.apellido_materno}
                                onChange={formik.handleChange}
                                className="w-full text-area2"
                            />
                        </div>
                        <div className="Inputss_POPUP_Update">
                            <label htmlFor="correo">Correo</label>
                            <InputText id="correo" name="correo"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={formik.values?.correo}
                                onChange={formik.handleChange}
                                className="w-full text-area2"
                            />
                        </div>
                        <div className="Inputss_POPUP_Update">
                            <label htmlFor="celular">Célular</label>
                            <InputText id="celular" name="celular"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={formik.values?.celular}
                                onChange={formik.handleChange}
                                className="w-full text-area2"
                            />
                        </div>

                        {InputCel && (
                            <div className="Inputss_POPUP_Update">
                            <label htmlFor="celular2">Célular secundario</label>
                            <InputText id="celular2" name="celular2"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                className="w-full text-area2"
                            />
                        </div>)}
                        {InputCorreo && (
                            <div className="Inputss_POPUP_Update">
                            <label htmlFor="correo2">Correo secundario</label>
                            <InputText id="correo2" name="correo2"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                className="w-full text-area2"
                            />
                        </div>)}
                    </div>

                    {/* {(InputCel===false || InputCorreo===false) && (
                        <div className="SectionExtraPOPUP">
                            <p className="SectionExtraPOPUPTitu">Agregar nuevo dato</p>
                            {(InputCel===false && InputCorreo===false) && (
                                <div className="SectionExtraPOPUPContenButton">
                                    {InputCel==false &&(
                                        <div onClick={() => setInputCel(true)}>Nuevo célular</div>
                                    )}
                                    {InputCorreo==false &&(
                                        <div onClick={() => setInputCorreo(true)}>Nuevo correo</div>
                                    )}
                                </div>
                            )}
                            {(InputCel===true || InputCorreo===true) && (
                                <div className="SectionExtraPOPUPContenButton" style={{justifyContent:'center'}}>
                                    {InputCel==false &&(
                                        <div onClick={() => setInputCel(true)}>Nuevo celular</div>
                                    )}
                                    {InputCorreo==false &&(
                                        <div onClick={() => setInputCorreo(true)}>Nuevo correo</div>
                                    )}
                                </div>
                            )}
                        </div>

                    )} */}
            </Dialog>
            <Toast ref={toast} />
        </div>
    )
}