import React, { useContext, useState, useEffect } from 'react';
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import { Skeleton } from "primereact/skeleton";
import '../../../Style/Botones.css';
import MIChi from "../../../Images/RutaAtencion/icon/MIChiSVG.svg";
import Sgundo from "../../../Images/RutaAtencion/icon/IconPersonaSVG.svg";
import T3rcero from "../../../Images/RutaAtencion/icon/Maleta3SVG.svg";
import Cuarto from "../../../Images/RutaAtencion/icon/Star4SVG.svg";
import Quinto from "../../../Images/RutaAtencion/icon/Calendar5SVG.svg";
import Sexto from "../../../Images/RutaAtencion/icon/Icon6SVG.svg";
import Septimo from "../../../Images/RutaAtencion/icon/checkbox7SVG.svg";
import Octavo from "../../../Images/RutaAtencion/icon/Octavo.svg";
import Noveno from "../../../Images/RutaAtencion/icon/CorazonNoveno.svg";
import "../../../Style/Atencion/CSSDatosPaciente.css";
import ModalCrear from "./ModalCrearAtencion";

import { Button } from 'primereact/button';


const RAAtencionFormView = (props) => {

  const [ingredient, setIngredient] = useState('');
  let { pacienteContext, pageViewContext, stateAtencion, setStateAtencion } = useContext(RAContext)
  let trab = pacienteContext?.persona

  const calcularEdad = (fechaNacimiento) => {
    const fechaActual = new Date();
    const nacimiento = new Date(fechaNacimiento);
    let edad = fechaActual.getFullYear() - nacimiento.getFullYear();
    const diferenciaMeses = fechaActual.getMonth() - nacimiento.getMonth();

    if (diferenciaMeses < 0 || (diferenciaMeses === 0 && fechaActual.getDate() < nacimiento.getDate())) {
      edad--;
    }

    return edad;
  }

  const [visibleCrear, setVisibleCrear] = useState(false);
  const [checked, setChecked] = useState(false);

  const actualizarEstado = () =>{
    let actualizacion = stateAtencion.map((area, index) =>{
      if (pageViewContext === index) {
        return { estado: 1, titulo: area.titulo, icon: area.icon }
      }else{
        return area
      }
      }
    )
    setStateAtencion(actualizacion)
  }
console.log("Esta es la data: ",props.datosPaciente)
  return (
    <div className='Contenedor_DATOS_DEl_PaciENTE'>
      <div className="cardIMG">
        <div className="card-image">
        </div>
        <div className='flex flex-column w-full align-items-center gap-3'>
          <div className='flex gap-3' style={{ marginTop: '80px' }}>
            <div className='flex justify-content-evenly' style={{ background: '#BBF6DD', borderRadius: '50px', padding: '5px', fontWeight: '700', fontSize: '12px', color: '#00A15C', width: '113px' }}>
              {/* <input onClick={() => actualizarEstado()} type="radio" id="opcion1" name="opciones" value="opcion1" checked={stateAtencion[pageViewContext].estado} disabled={stateAtencion[pageViewContext].estado}/> */}
              <label for="opcion1">Atendido</label>
            </div>
            <div className='flex justify-content-evenly' style={{ background: '#FFECEC', borderRadius: '50px', padding: '5px', fontWeight: '700', fontSize: '12px', color: '#FF6767', width: '113px' }}>
              {/* <input type="radio" id="opcion2" name="opciones" value="opcion2"  disabled={stateAtencion[pageViewContext].estado}/> */}
              <label for="opcion2">No atendido</label>
            </div>
          </div>

          <div className='flex flex-row w-full justify-content-center'>
            <span style={{ color: '#5555D8', fontWeight: '700', fontSize: '18px', marginTop: '20px' }}>Datos del paciente</span>
          </div>

          <div className='flex gap-3' style={{ marginTop: '12px', fontFamily: 'Montserrat' }}>
            <div className='flex justify-content-evenly' style={{ padding: '3px', fontWeight: '700', fontSize: '12px', color: '#363E4B', width: '113px' }}>
              <label for="opcion1">Código</label>
            </div>
            <div className='flex justify-content-evenly' style={{ padding: '3px', fontWeight: '700', fontSize: '12px', color: '#363E4B', width: '113px' }}>
              <label for="opcion2">N° de atención</label>
            </div>
          </div>

          <div className='flex gap-3' style={{ marginTop: '0px' }}>
            <div className='flex justify-content-evenly' style={{ padding: '0px', fontWeight: '700', fontSize: '12px', color: '#363E4B', width: '100px', paddingRight: '30px' }}>
  
                  <div className="pfv_form_input_value">
                    <span>{props.datosPaciente.persona.id}</span>
                  </div>
            </div>
            <div className='flex justify-content-evenly' style={{ padding: '0px', fontWeight: '700', fontSize: '12px', color: '#363E4B', width: '100px' }}>

                  <div className="pfv_form_input_value">
                    <span>{props.datosPaciente.id}</span>
                  </div>
            </div>
          </div>

          {/* <div className='flex gap-3' style={{ marginTop: '80px' }}>
            <div className="ConedorDatosDelPacienteInputs pfv_form_input">
              <div className='flex justify-content-evenly'>
                <img src={MIChi} alt="" />
                <label>Código</label>
              </div>
              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton">
                    <Skeleton className="pfv_form_input_skeleton" height="21px" width="90px"></Skeleton>
                  </div>
                  :
                  <div className="pfv_form_input_value">
                    <span>{pacienteContext?.persona_id}</span>
                  </div>
              }

              <div className='flex justify-content-evenly'>
                <img src={MIChi} alt="" />
                <label>N° de atención</label>
              </div>
              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton">
                    <Skeleton className="pfv_form_input_skeleton" height="21px" width="90px"></Skeleton>
                  </div>
                  :
                  <div className="pfv_form_input_value">
                    <span>{pacienteContext?.num_atencion}</span>
                  </div>
              }
            </div>
          </div> */}

          <div className="contenedor-nom-ape">
            <div className='flex flex-row w-full justify-content-center'>
              <span style={{ color: '#5555D8', fontWeight: '500', fontSize: '13px', fontFamily: 'Montserrat', paddingRight: '40px' }}>Nombres y apellidos</span>
            </div>
                <div className="pfv_form_input_value">
                  <span>
                  {props.datosPaciente.persona.nombre_completo}
                  </span>
                </div>
          </div>



          {/* <div className="ConedorDatosDelPacienteInputs pfv_form_input">
            <div className="pfv_form_input_name">
              <img src={T3rcero} alt="" />
              <label>Ocupación</label>
            </div>

            {
              pacienteContext === null
                ?
                <div className="pfv_form_input_skeleton">
                  <Skeleton className="pfv_form_input_skeleton" height="21px"></Skeleton>
                </div>
                :
                <div className="pfv_form_input_value">
                  <span>{pacienteContext?.ocupacion}</span>
                </div>
            }
          </div> */}

          <div className='div-datos-paciente2'>
            <div className="ConedorDatosDelPacienteInputs pfv_form_input">
              <div className="pfv_form_input_name1">
                <img className="iconos" src="https://cdn.discordapp.com/attachments/1078045652904325191/1151248640157954148/image.png" alt="" />
                <label>Edad</label>
              </div>

              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton1">
                    <Skeleton className="pfv_form_input_skeleton" height="21px"></Skeleton>
                  </div>
                  :
                  <div className="pfv_form_input_value">
                    <span>{calcularEdad(trab?.fecha_nacimiento)}</span>
                  </div>
              }
            </div>

            <div className="ConedorDatosDelPacienteInputs pfv_form_input">
              <div className="pfv_form_input_name1">
                <img className="iconos" src="https://cdn.discordapp.com/attachments/1078045652904325191/1151249537885806744/image.png" alt="" />
                <label>Plan de Atención</label>
              </div>

              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton1">
                    <Skeleton className="pfv_form_input_skeleton" height="21px"></Skeleton>
                  </div>
                  :
                  <div className="pfv_form_input_value">
                    <span>{pacienteContext?.plan_atencion}</span>
                  </div>
              }
            </div>

            <div className="ConedorDatosDelPacienteInputs pfv_form_input">
              <div className="pfv_form_input_name1">
                <img className="iconos" src="https://cdn.discordapp.com/attachments/1078045652904325191/1151249916698566756/image.png" alt="" />
                <label>Fecha de Evaluación</label>
              </div>

              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton1">
                    <Skeleton className="pfv_form_input_skeleton" height="21px"></Skeleton>
                  </div>

                  :
                  <div className="pfv_form_input_value">
                    <span>{pacienteContext?.fecha}</span>
                  </div>
              }
            </div>

            <div className="ConedorDatosDelPacienteInputs pfv_form_input">
              <div className="pfv_form_input_name1">
                <img className="iconos" src="https://cdn.discordapp.com/attachments/1078045652904325191/1151250083241807912/image.png" alt="" />
                <label>Tipo de Evaluación</label>
              </div>

              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton1">
                    <Skeleton className="pfv_form_input_skeleton" height="21px"></Skeleton>
                  </div>
                  :
                  <div className="pfv_form_input_value">
                    <span>{pacienteContext?.tipo_evaluacion}</span>
                  </div>
              }
            </div>
            {/* <div className='contebot'>
              <button className="boton">Ver más</button>
            </div> */}


          </div>
        </div>
      </div>
      <div className='flex' style={{ marginTop: '30px', width: '100%' }}  >
        <Button label="Ver más" style={{ height: '32px', width: '100%', borderRadius: '0px 0px 20px 20px ' }} onClick={() => setVisibleCrear(true)} />
      </div>

      <ModalCrear
        setVisibleCrear={setVisibleCrear}
        visibleCrear={visibleCrear}
        checked={checked}
        setChecked={setChecked}
      />


    </div>
  );
};

export default RAAtencionFormView;

