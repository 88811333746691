import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast';
import AuthUser from '../../../../AuthUser';
import imgRoles from "../../../../Images/roles/roles.svg";
import TablaRolComponent from '../../../../Components/RecursosHumanos/Rol/TablaRol';
import '../../../../Style/estilospagrol.css';
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";


function PageRolesEmpresa() {
    const dispatch = useDispatch();

    const { http } = AuthUser();
    const toast = useRef(null);
    const [key, setkey] = useState(0);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [GetLocales, setGetLocales] = useState([]);
    const [roles, setRoles] = useState([]);
    const [Idroles, setIdRoles] = useState({});
    const [accesos, setAccesos] = useState([]);
    const [LocalAsignadoBD, setLocalAsignadoBD] = useState(null);

    const showToast = (type, title, detail) => {
        toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
        });
    }

    const getAllRoles = async () => {
        try {
            const response = await http.get("/roles/get")
            if (response?.data?.data) {
                //console.log("ROLESSS",response.data.data[0]);
                setRoles(response.data.data);
                setIdRoles(response.data.data[0]);
                setkey(key + 1);

            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }

        } catch (error) {
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
            console.log(error);
        };
    };

    const asignarRoles = (data) => {
        dispatch(startLoading());
        http
            .post("roles/asignar", data)
            .then((response) => {
                if (response?.data?.resp) {
                    getAllRoles();
                    showToast("success", "Acceso Asignado", "Se asignaron correctamente los accesos")

                } else if (response?.data?.error) {
                    showToast("error", "Error", response?.data?.error)
                }

            })
            .catch((error) => {
                showToast("error", "Error", "Contáctese con Soporte Técnico.")
                console.log(error);
            })
            .finally(() => {
                dispatch(endLoading());
            })
    }

    const getAccesos = async () => {
        try {
            const response = await http.get("/accesos/get")
            if (response?.data?.data) {
                setAccesos(response.data.data);

            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }

        } catch (error) {
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
            console.log(error);
        };
    };

    useEffect(() => {
        getAllRoles();
        getAccesos();
        //GetLocalAsignado();
    }, []);


    const handleCreateRol = async (rol) => {
        dispatch(startLoading());
        try {
            const response = await http.post(`roles/create`, rol)
            if (response?.data?.resp) {
                await Promise.all([
                    getAllRoles()
                ]);
                showToast("success", "Rol creado", "Se ha creado un nuevo rol correctamente")
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
        } finally {
            dispatch(endLoading());
        }
    };


    const handleEditRol = async (rol) => {
        dispatch(startLoading());
        try {
            const response = await http.put(`roles/update/${rol.id}`, rol)
            if (response?.data?.resp) {
                await Promise.all([
                    getAllRoles()
                ]);
                showToast("success", "Rol Editado", "El rol se editó correctamente")
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
        } finally {
            dispatch(endLoading());
        }
    };


    const handleDeleteRol = async (id) => {
        dispatch(startLoading());
        try {
            const response = await http.delete(`/roles/delete/${id}`)
            if (response?.data?.resp) {
                await Promise.all([
                    getAllRoles()
                ]);
                
                showToast("success", "Rol eliminado", "El rol se eliminó correctamente", response?.data?.resp)
            }else if (response?.data?.error){
                showToast("error", "Error", response?.data?.error)
            }

        } catch (error) {
            console.log("Elimar rol error", error);
            showToast("error", "Error", "Contáctese con soporte técnico.")

        } finally {
            dispatch(endLoading());
        }
    };

    //FUNCIÓN PARA TRAER LOS TODOS LOS LOCALES
    const ObtenerLocales = (ruta) => {
        http
            .get(`${ruta}`)
            .then((response) => {
                if (response.data.data) {
                    setGetLocales(response.data.data);
                    console.log("LOCALES PAGEROLES EMPRESA", response.data.data)
                } else if (response?.data?.error) {
                    showToast("error", "Error", response?.data?.error)
                }

            }).catch((error) => {
                showToast("error", "Error", "Contáctese con Soporte Técnico.")
                console.log(error);
            });

    }

    //CONDICIONAL: SEGÚN EL ID DEL ROL SE ASIGNARÁ LA RUTA PARA OBTENER LOS LOCALES DE ESE USUARIO
    const ObtenerLocalesCondicional = () => {
        if (Idroles.bregma_id != null) {
            ObtenerLocales(`/bregma/local/show`);

            console.log("entro bregma")
        }
        else if (Idroles.clinica_id != null) {
            ObtenerLocales(`clinica/recursoshumanos/local/get`);
            console.log("entro clinica")
        } else if (Idroles.empresa_id != null) {
            ObtenerLocales(`/empresa/local/show`);
            console.log("entro empresa")
        } else {
            console.log("no entro a niguno")
        }
    }

    //API PARA ASIGNAR LOCALES SEGÚN ROLES
    const AsignarLocales = (idrol, data) => {
        dispatch(startLoading());
        http
            .put(`/roles/locales/asignar/${idrol}`, data)
            .then((response) => {
                getAllRoles();
                dispatch(endLoading());
            }).catch(error => {
               dispatch(endLoading());
            })
    }

    //API PARA OBTENER LOCAL ASIGNADO AL ROL
    const GetLocalAsignado = (id) => {
        http
            .get(`/roles/show/${id}`)
            .then((response) => {
                if (response?.data?.data) {
                    if (response?.data?.data?.bregma_local != null) {
                        setLocalAsignadoBD(response?.data?.data?.bregma_local?.nombre)
                    } else if (response?.data?.data?.clinica_local != null) {
                        setLocalAsignadoBD(response?.data?.data?.clinica_local?.nombre)
                    } else if (response?.data?.data?.empresa_local != null) {
                        setLocalAsignadoBD(response?.data?.data?.empresa_local?.nombre)
                    } else {
                        setLocalAsignadoBD(null)
                    }
                    console.log("PROBANDOOO", LocalAsignadoBD)
                } else if (response?.data?.error) {
                    showToast("error", "Error", response?.data?.error)
                }

            }).catch((error) => {
                showToast("error", "Error", "Contáctese con Soporte Técnico.")
                console.log(error);
            });
    }

    const fetchAllData = async () => {
        try {
            await Promise.all([
                getAllRoles(),
                getAccesos(),
            ]);
        } catch (error) {
            console.error('Error al cargar datos:', error);
        } finally {
            dispatch(endLoading());
        }
    }

    useEffect(() => {
        dispatch(startLoading());
        fetchAllData()
    }, []);

    return (
        <div className="table1 flex w-full flex-wrap  tabla-rol flex-row pl-7 pr-6">
            <div className="section-one flex flex-column flex-1 pr-4 w-8">
                <h1 className="tit">Roles</h1>
                <p className="con">Crea roles con acceso a Bregma</p>

                <TablaRolComponent
                    key={key}
                    roles={roles}
                    rolUpdate={handleEditRol}
                    rolCreate={handleCreateRol}
                    rolDelete={handleDeleteRol}
                    getRoles={getAllRoles}
                    accesos={accesos}
                    asignarRoles={asignarRoles}
                    GetLocales={GetLocales}
                    setGetLocales={setGetLocales}
                    ObtenerLocalesCondicional={ObtenerLocalesCondicional} //SE ENVIA A TABLA ROL
                    AsignarLocales={AsignarLocales}
                    LocalAsignadoBD={LocalAsignadoBD}
                    GetLocalAsignado={GetLocalAsignado}
                />
            </div>

            <div className="section-two pl-6 padding-top">
                <img src={imgRoles} alt="roles" />
            </div>
            { }
            { }
            <Toast ref={toast} />
        </div>
    );
}

export default PageRolesEmpresa

