import React, { useState, useEffect, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import AuthUser from "../../../../AuthUser";
import CardLocal from "../../../../Components/RecursosHumanos/Local/LocalComponentes/CardLocal"
import ModalEliminarLocal from "../../../../Components/RecursosHumanos/Local/Modals/ModalEliminarLocal";
import ModalCrearLocal from "../../../../Components/RecursosHumanos/Local/Modals/ModalCrearLocal";
import Spinner from "../../../../Components/Loader/Spinner";
import { Toast } from "primereact/toast";

function PageMisLocalesB() {
   const { http } = AuthUser();
   const toast = useRef(null);
   const [spinnerVisibility, setSpinnerVisibility] = useState(true);

   const [visible1, setVisible1] = useState({
      status: false,
      nombre: ''
   });
   const [departamentos, setDepartamentos] = useState(null);
   const [locales, setLocales] = useState([])
   const [datosbusqueda, setBusqueda] = useState(locales)
   const [visibleDelete, setVisibleDelete] = useState({
      status: false,
      id: null
   });


   const [url, setUrl] = useState("./PageAreas")



   const [local, setLocal] = useState({
      id: null,
      nombre: '',
      direccion: '',
      latitud: null,
      longitud: null,
      departamento_id: null,
      departamento: null,
      distrito_id: null,
      distrito: null,
      provincia_id: null,
      provincia: null,
   })


   // BUSCAR LOCALES
   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   const busqueda = (e) => {
      if (e.target.value !== '') {
         let datosFiltrados = locales.filter(
            locales => locales.nombre.toLowerCase().includes(e.target.value.toLowerCase()) || locales.departamento.departamento.toLowerCase().includes(e.target.value.toLowerCase())
               || locales.provincia.provincia.toLowerCase().includes(e.target.value.toLowerCase()) || locales.distrito.distrito.toLowerCase().includes(e.target.value.toLowerCase())
         );
         setBusqueda(datosFiltrados)
      }
      else {
         setBusqueda(locales)
      }
   };

   const handleEditarlocal = (id, datos) => {
      console.log('datosss de editar locallll', datos);
      http
         .put(`clinica/recursoshumanos/local/update/${id}`, datos)
         .then((response) => {
            getLocales();
            showToast(
               "success",
               `Actualizado correctamente`,
               `El local se actualizó correctamente`
            );
         })
         .catch((error) => {
            console.error(error)
         })
   }


   const handleDelete = (id) => {
      http
         .delete(`clinica/recursoshumanos/local/delete/${id}`)
         .then((response) => {
            getLocales();
            showToast(
               "success",
               `Eliminado correctamente`,
               `El local se eliminó correctamente`
            );
         })
         .catch((error) => {
            console.error(error)
         })
   }

   const handleCreate = (datosLocal) => {
      http
         .post('clinica/recursoshumanos/local/create', datosLocal)
         .then((response) => {

            getLocales();
            showToast(
               "success",
               `Creado correctamente`,
               `El local se creó correctamente`
            );
         })
         .catch((error) => {
            console.error(error)
         })
   }


   // OBTENER LOCALES
   const getLocales = async () => {
      try{
         const response = await  http.get('clinica/recursoshumanos/local/get')
         setLocales(response?.data?.data)
         setBusqueda(response?.data?.data)
      }catch(error){
         console.error(error)
        
      }
      }

   // OBTENER LISTA DE  DEPARTAMENTOS
   const getAllUbicaciones = async () => {
      try{  
         const response=await http.get("/ubicacion")
         setDepartamentos(response.data.data);
         console.log('departamentosssss', response.data.data);
      }catch(error){
         console.log("error",error)
      }
   };
   const handlerequests=async()=>{
      await getLocales()
      await getAllUbicaciones()
      setTimeout(() => {
         setSpinnerVisibility(false);
      }, 1000);
      
   }
   useEffect(() => {
      handlerequests()
   }, [])

   console.log("LOCALES", datosbusqueda)


   return (

      <div className='flex flex-wrap flex-column gap-5 px-6'>

         <Spinner show={spinnerVisibility} setVisibles={setSpinnerVisibility} />
         <div className="flex flex-wrap flex-column  ">
            <span className="text-indigo-600 text-3xl font-bold">Mis Locales</span>
            <p className="w-30rem text-indigo-200 text-sm">A continuación se visualiza los locales previamente creados para poder colocar las áreas que usted a definido en cada uno de sus locales.</p>

         </div>

         <div className=" w-full">
            {/* CARTAS */}
            <ScrollPanel className="scroll" >

               <div className=" p-input-icon-left w-full mb-3  flex justify-content-center ">
                  <span className="bg-transparent p-input-icon-left  " style={{ width: '85%' }}>
                     <i className="px-2 pi pi-search" style={{ color: "#ccd2d8" }} />
                     <InputText placeholder="Busca tu local..." style={{ background: 'white', width: '100%', height: '43px', top: '251px', left: '774px', borderRadius: '13px' }} onChange={(e) => busqueda(e)} />
                  </span>
               </div>

               <div className="px-8  contenedorcard ">


                  { }

                  <button
                     className="relative overflow-hidden cursor-pointer border-2 border-dashed border-round-2xl flex flex-1 flex-column bg-purple-50 p-3 gap-2 border-round-lg align-items-center 
                            justify-content-center border-indigo-500"


                     style={{
                        width: "370px",
                        height: "361px"
                     }}
                     onClick={() => setVisible1({ ...visible1, status: true, nombre: 'Crear local' })}
                  >
                     <i className="pi pi-plus-circle text-indigo-600 font-bold text-xl"></i>
                     <p className="font-bold text-indigo-600 text-base">Crear Nuevo Local</p><span className="StepsV_shine"></span>
                  </button>

                  {
                     datosbusqueda.map((localitem, key) => {
                        return (
                           <CardLocal
                              key={key}
                              localitem={localitem}
                              local={local}
                              setLocal={setLocal}
                              setVisible1={setVisible1}
                              visible1={visible1}
                              visibleDelete={visibleDelete}
                              setVisibleDelete={setVisibleDelete}
                              url={url}
                              provincia={localitem?.provincia?.provincia}
                           />
                        )
                     })
                  }


               </div>
            </ScrollPanel>
         </div>
         <ModalCrearLocal
            local={local}
            setLocal={setLocal}
            handleCreate={handleCreate}
            setVisible1={setVisible1}
            visible1={visible1}
            departamentos={departamentos}
            handleEditarlocal={handleEditarlocal}

         />
         { }
         <ModalEliminarLocal
            handleDelete={handleDelete}
            setVisibleDelete={setVisibleDelete}
            visibleDelete={visibleDelete}
         />
         <Toast ref={toast} />
      </div>

   )
}



export default PageMisLocalesB