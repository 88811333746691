import React, { useContext, useRef, useState, useEffect } from 'react';
import { Button } from "primereact/button";
import AuthUser from "../../AuthUser";
import { EkgContext } from "./EkgContext";
import EkgPreguntas from "../../Components/Ekg/EkgPreguntas";
import '../../Style/ekg.css'
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconEkg } from "../../Services/importIcons";

const PageEkg = () => {
  const [imprimirVisible, setImprimirVisible] = useState(false)

  let { datosContext, setDatosContext } = useContext(EkgContext)

  const { atencionStatus, setAtencionStatus } = useContext(EkgContext);
  const { setTimeStart } = useContext(EkgContext);

  const { http } = AuthUser();

  const [activeIndex, setActiveIndex] = useState(0);
  const [listaEkg, setListaEkg] = useState();

  const [savingStatus, setSavingStatus] = useState(false);



  const tabSubmitHeader = (options) => (
    <Button
      icon={savingStatus ? `pi pi-spin pi-spinner` : `pi pi-save`}
      label={savingStatus ? 'Guardando ...' : options.titleElement}
      className="p-button-sm"
      onClick={handleSubmit}
    />
  );

  const toast = useRef(null);

  const showToast = (type, title, detail) => {
    toast.current.show({
      severity: type,
      summary: title,
      detail: detail,
      life: 3000,
    });
  };

  const handleSubmit = () => {
    setSavingStatus(true)

    const allFetching = [
      http
        .post('ekg/datosekg/create', datosContext)
        .then(() => {
          console.log('Datos Ekg guardado');
        })
        .catch((error) => {
          console.log(error);
          console.log('Error en Datos Ekg');
        })

    ]

    Promise.allSettled(allFetching)
      .then((results) => {
        results.forEach((result) => console.log(result.status));
        showToast(
          "success",
          `Atención en Ekg guardada`,
          `Se guardó la atención correctamente`
        );
        setSavingStatus(false);
      })
      .catch(() => {
        setSavingStatus(false);
      })

  }

  const getEkg = () => {
    http
      .get('ekg/datosekg/get')
      .then((response) => {
        if (response?.data?.resp) {
          setListaEkg(response.data.data)
          console.log('datosssss Egk', response.data.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getEkg()
  }, [])

  return (
    <>
      <EkgPreguntas listaEkg={listaEkg} />
      { }
      {/* {
        atencionStatus === 0
        &&
        <RATimeView title="Ekg" icon={iconEkg} setTimeStart={setTimeStart} setAtencionStatus={setAtencionStatus} />
      } */}
    </>
  );
};

export default PageEkg;