import { InputText } from "primereact/inputtext";
import LaboratorioTabla from "../../../Page/Laboratorio/LaboratorioTabla";
import { InputNumber } from 'primereact/inputnumber';
import React, { useContext, useEffect, useState } from "react";
import LaboratorioListComponent from "../container/laboratorio_list";
import AuthUser from "../../../AuthUser";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
import DialogArchivoComponent from "../container/dialog_archivo";
import { Button } from "primereact/button";

export default function ViewTGP({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
    const { http } = AuthUser();

    const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);

    const [prueba, setPrueba] = useState({})
    const [nombreArchivo, setNombreArchivo] = useState(null);
    const [file, setFile] = useState(null);
    const [indexSup, setIndexSup] = useState(0);
    const [indexSub, setIndexSub] = useState(0);

    
    
    const [parametros, setParametros] = useState
        ([
            {
                prueba: 'TGP',
                resultado: 0,
                um: 'U/L',
                rango_inicial: 0,
                rango_final: 42,
                valor_normal: '0 - 42 U/L'
            }
        ])


    const [inputValue, setInputValue] = useState('');

    const handleCreate = () => {
        const formData = new FormData();
        formData.append("examen_id", tipoExamen);
        formData.append("prueba[examen_id]", tipoPrueba);
        formData.append("prueba[estado]", 1);
        formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
        formData.append("prueba[cantidad_parametros]", parametros?.length);
        formData.append("prueba[parametros]", JSON.stringify(parametros));
        formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));
  
        http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
           .then(response => {
              console.log(response)
              showToast("success","Creado Correctamente",response?.data?.resp)
           })
           .catch(error => {
              console.log(error)
              showToast("Error","Error de Guardar",error?.data?.resp)
           })
     }
  
     const changeExamenContext = (superior, inferior, auxParametros) => {
        setExamenesContext(examenesContext.map((elementoSup, index) => {
           if (index === superior) {
              let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
                 if (indexAux === inferior) {
                    return {
                       ...prueba,
                       parametros: auxParametros,
                       estado: 1,
                       cantidad_parametros: auxParametros?.length,
                       perfil: auxParametros?.length > 1 ? 2 : 1
                    }
                 }
                 return prueba;
              })
              return { ...elementoSup, pruebas: auxPrueba };
           }
           return elementoSup;
        }))
     }
  
     const handleInputChange = (index, e) => {
        const tempTask = [...parametros];
        tempTask[index].resultado = e.value;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
     const handleInputInicial = (index, e) => {
        console.log(e)
        const tempTask = [...parametros];
        tempTask[index].rango_inicial = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
     const handleInputFinal = (index, e) => {
        const tempTask = [...parametros];
        tempTask[index].rango_final = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
  
     useEffect(() => {
        console.log(examenesContext)
        let sup = 0;
        let inf = 0;
        let auxPruebas = examenesContext.find((examen, index) => {
           if (examen.examen_id === tipoExamen) {
              setIndexSup(index);
              sup = index;
              console.log(index)
           }
           return examen.examen_id === tipoExamen
        });
        let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
           if (prueba.examen_id === tipoPrueba) {
              setIndexSub(index);
              inf = index;
              console.log(index)
           }
           return prueba.examen_id === tipoPrueba
        });
        if (auxPrueba?.archivo) {
           setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
        }
        setPrueba(auxPrueba);
        console.log("PRUEBA", auxPrueba)
        console.log("PRUEBA", typeof auxPrueba?.parametros === 'string')
        let auxParametros = typeof auxPrueba?.parametros === 'string' ?  JSON.parse(auxPrueba?.parametros): auxPrueba?.parametros
        auxPrueba?.parametros && setParametros(auxParametros);
        changeExamenContext(sup, inf, auxParametros);
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

    return (
        <div className="flex flex-column w-full h-full" style={{ padding: '20px' }}>
            <LaboratorioListComponent
               handleInputChange={handleInputChange}
               handleInputFinal={handleInputFinal}
               handleInputInicial={handleInputInicial}
               parametros={parametros}
            />
            {/* <DialogArchivoComponent
               file={file}
               handleCreate={handleCreate}
               nombreArchivo={nombreArchivo}
               prueba={prueba}
               setFile={setFile}
               setNombreArchivo={setNombreArchivo}
            /> */}
            <div className="flex flex-row gap-3 mt-2" style={{ justifyContent: 'end' }}>
               <Button label="Grabar" icon="fas fa-save" onClick={handleCreate} />
               <Button label="Recuperar" icon="fas fa-undo" />
               {/* <Button label="Hematólogo" icon="fas fa-user-md" /> */}
            </div>
        </div>
    );
}
