import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

export default function EliminarModal({ eliminar, close, estado, paquete }) {
    //console.log(paquete);

    // BOTONES DE CONFIMACION (ELIMINAR) 
    const handleDeleteConfirmation = (id_paquete) => (
        <div className="flex justify-content-end">    
            <Button
                label="Cancelar"
                className="p-button-secondary"
                style={{ background: "white", color: "#344054" }}
                onClick={() => {
                    close(false);
                }}
            />
            <Button
                label="Eliminar"
                className="p-button-danger "
                onClick={() => {
                    close(false);
                    eliminar(id_paquete) 
                }}
            />
        </div>
    );


    return (
        <Dialog
            /*visible={estado}
            style={{ width: "350px", height: "auto" }}
            className="p-fluid"
            header={<p className="text-red-500">Eliminar Paquete</p>}*/
            visible={estado}
            resizable={false}
            style={{ width: "500px", height: "240px" }}
            className="p-fluid eliminarLocal"
            header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Paquete</i></p>}
            footer={handleDeleteConfirmation(paquete?.paquete?.id)}
            onHide={() => close(false)}
        >
            {/*<div className="flex flex-column w-full justify-content-center align-items-center" style={{ marginTop: "20px" }}>
                <p style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "16px" }}>¿Está seguro qué desea eliminar    ?</p>

            </div>*/}
            <div className="flex flex-column gap-3">
                <div className="flex flex-column w-full">
                <p style={{ fontSize: "20px" }} className=" m-0 pt-5">¿Está seguro qué desea eliminar el paquete?</p>
                </div>
            </div>
        </Dialog>

    )
}