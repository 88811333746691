import AuthUser from "../../../../../AuthUser";

const SC1Customer = () => {
    const { http } = AuthUser();

    const getCompany = async () => {
        try {
            const response = await http.get("clinica/contratos/get");

            if (response.data.Error) {
                return [];
            } else {
                const transformedData = response.data.data.map((item) => (
                    ({
                        id: item.id,
                        logo: item.empresa.logo || "",
                        empresa: item.empresa.razon_social,
                        nombre: item.empresa.responsable,
                        sevicio_1: 'Áreas médicas',
                        sevicio_2: 'Capacitaciones',
                        estado: item.estado_registro === 'A' ? 1 : 2,
                        empresa_id: item.empresa_id
                    })
                ));
                return transformedData
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getCompany");
        }
    };

    const getPackages = async () => {
        try {
            const response = await http.get("clinica/paquete/get");
            if (response.data.data) {
                return response.data.data;
            } else {
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
            console.log("getPackages");
        }
    };


    const getCompanyID = async (id, ubicaciones, setData) => {
        //FALTA SINPLIFICAR LA CONDICIÓN GENERAL
        try {
            const response = await http.get(`empresa/get/${id}`);


            if (Object.keys(response.data).length > 0) {
                let departamentoSeleccionado = null
                let provinciaSeleccionada = null
                let distritoSeleccionado = null
                console.log("data_get", response.data?.data?.contratos?.[0]?.id)
                const empresa = response.data.data;

                if (ubicaciones) {
                    departamentoSeleccionado = ubicaciones?.find(
                        (departamento) => departamento.id === empresa?.distrito?.provincia?.departamento?.id
                    );
                    provinciaSeleccionada = departamentoSeleccionado?.provincias.find(
                        (provincia) => provincia.id === empresa?.distrito?.provincia?.id
                    );
                    distritoSeleccionado = provinciaSeleccionada?.distritos.find(
                        (distrito) => distrito.id === empresa?.distrito?.id
                    );
                }
                setData(prevState => (
                    {
                        ...prevState,
                        GCEmpresa: {
                            ...prevState.GCEmpresa,
                            contrato_id: response?.data?.data?.contratos[0]?.id,
                            tipo_cliente: response.data.data.contratos[0].tipo_cliente_id,
                            numero_documento: response.data.data.numero_documento,
                            razon_social: response.data.data.razon_social,
                            responsable: response.data.data.responsable || "",
                            nombre_comercial: response.data.data.nombre_comercial,
                            latitud: parseFloat(response.data.data.latitud) || -12.0464,
                            longitud: parseFloat(response.data.data.longitud) || -77.0428,
                            tipo_documento_id: response.data.data.tipo_documento.id,
                            departamento: departamentoSeleccionado,
                            provincia: provinciaSeleccionada,
                            distrito: distritoSeleccionado,
                            direccion: response.data.data.direccion,
                            ubicacion_mapa: response.data.data.ubicacion_mapa || "",
                            aniversario: response.data.data.aniversario || null,
                            rubro: response.data.data.rubro_id || null,
                            cantidad_trabajadores: response.data.data.cantidad_trabajadores || null,
                            actividad: response.data.data.años_actividad || null,
                            logo: response.data.data.logo || "",
                            estado_registro: response.data.data.estado_registro,
                            empresa_id: response.data?.data?.id

                        }
                    }
                ));
                return response.data?.data?.contratos?.[0]?.id
                //console.log(JSON.stringify(response.data.data));
            }

            return false;
        } catch (error) {
            throw error;
        } finally {
            console.log("getCompanyID");
        }
    };

    const postCompany = async (data, setData, showToast, navigate) => {
        await http.post("empresa/create", data).then((response) => {
            setData(prevState => ({
                ...prevState,
                GCEmpresa: {
                    ...prevState.GCEmpresa,
                    clinica_id: response.data.empresa_id,
                    contrato_id: response.data.contrato_id || null
                }
            }));
            if (response.data.resp) {
                showToast("success", "Completado", response.data.resp);
                navigate(`../ventas/gestiondeventas/empresa/${response.data.empresa_id}/editar`);
            } else {
                showToast("error", "Error", response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            console.log("postClinic");
        });
    }

    const putCompany = async (id, data, setData, showToast) => {
        await http.post(`empresa/update/${id}`, data).then((response) => {

            showToast(response.data.resp ? "success" : "error", response.data.resp ? "Completado" : "Error", response.data.resp || response.data.Error)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            console.log("putCompany", "Actualizando la empresa: ", id, data);//te da un id que no es
        });
    }

    return {
        getCompany,
        getPackages,

        getCompanyID,
        postCompany,
        putCompany,
    };
}

export default SC1Customer;