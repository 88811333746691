import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import AuthUser from "../../../AuthUser";
import iconAdvertencia from "../../../Images/Reclutaminento/img/icon-advertencia.png";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function ModalTransferir({
    setvisibleTransferir,
    visibleTransferir,
    showToast,
    item,
    api,
    getPersonal
}) {

    const { http } = AuthUser();
    const dispatch = useDispatch();
    
    const HideDialogEdit = async () => {
        dispatch(startLoading());
        try {
            const response = await http.put(`${api}/${item.id}`)
            if (response?.data?.[0]) {
                await Promise.all([
                    setvisibleTransferir(),
                    getPersonal()
                ])
                showToast("success", "Se transferio correctament", "Se creo correctamente el personal");

            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }

        } catch (error) {
            showToast("error", "Error", "Contáctese con soporte técnico.")
            console.log(error);
        } finally {
            dispatch(endLoading());
        }
        setvisibleTransferir(false);

    };

    useEffect(() => {
        console.log("iteeeee", item.id);

    }, [item]);

    const HeaderTransferir = (

        <div className=" advertencia_header flex align-items-center">
            <div className="flex justify-content-center align-items-center"
                style={{ backgroundColor: "#EBEBFF", width: "60px", height: "60px", borderRadius: "10px", }}
            >
                <img src={iconAdvertencia} alt="" />

            </div>
            <div className="transferir">
                <h1 style={{ color: '#5555d8', marginLeft: '8px', fontSize: '22px' }}>Transferir</h1>
            </div>
        </div>
    );

    const paqueteCreateDialogFooter = (
        <div className="flex w-full bot">
            <Button
                type="button"
                label="SI"
                className="delete w-full"
                onClick={() => {
                    HideDialogEdit();
                }}
            />
            <Button
                type="submit"
                label="NO"
                className="p-button-success w-full"
                style={{
                    backgroundColor: "#5555D8",
                    borderColor: "#5555D8",
                }}
            />
        </div>

    );

    return (
        <div>
            <Dialog
                visible={visibleTransferir}
                style={{ width: "450px" }}
                /*  appendTo={document.getElementById("personal-form-edit")} */
                dismissableMask
                className="p-fluid "
                header={HeaderTransferir}
                footer={paqueteCreateDialogFooter}
                onHide={() => { setvisibleTransferir(); }}

            >
                <>
                    <div className="Body_Advertencia">
                        <h1 className="per" style={{ color: '#363e4b' }}> ¿Está seguro de transferir este postulante a personal? </h1>
                        <p style={{ marginTop: '8px', marginBottom: '8px' }}> Recuerde que al hacerlo no hay forma de retroceder la acción </p>
                    </div>
                </>
            </Dialog>
        </div>
    );
}