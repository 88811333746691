import React, { useContext, useEffect } from 'react';
import AuthUser from "../../../AuthUser";
import RAAtencionContainer from "../../../Components/Container/RAAtencionContainer";
import RAAtencionTabNavigator from "../../../Components/RutaAtencion/Atencion/RAAtencionTabNavigator";
import RAAtencionFormView from "../../../Components/RutaAtencion/Atencion/RAAtencionFormView";
import { RAContext } from "../RAContext";
import { useParams } from 'react-router-dom';

const PageRAAtencion = () => {
   const accesos = (JSON.parse(sessionStorage.getItem("accesos")));
   const { http } = AuthUser();
   const { idPaciente } = useParams()

   let { setPacienteContext,
      setTriajeAreasContext, setTriajeDetalleId,
      setPsicologiaAreasContext, setPsicologiaDetalleId,
      setEkgAreasContext, setEkgDetalleId,
      setOftalmologiaAreasContext, setOftalmologiaDetalleId,
      setAudiometriaAreasContext, setAudiometriaDetalleId,
      setRadiologiaAreasContext, setRadiologiaDetalleId,
      setEspirometriaAreasContext, setEspirometriaDetalleId,
      setMedicinaGeneralAreasContext, setMedicinaGeneralDetalleId,
      setLaboratorioAreasContext, setLaboratorioDetalleId,
   } = useContext(RAContext)


   const servicios = [
      { id: 0, nombre: 'Triaje' },
      { id: 1, nombre: 'Psicología' },
      { id: 2, nombre: 'EKG' },
      { id: 3, nombre: 'Oftalmología' },
      { id: 4, nombre: 'Audiometría' },
      { id: 5, nombre: 'Radiología' },
      { id: 7, nombre: 'Odontología' },
      { id: 8, nombre: 'Espirometría' },
      { id: 6, nombre: 'Laboratorio' },
      { id: 9, nombre: 'Medicina General' }
   ]
   const jsonTabs = servicios?.filter((servicio) =>
      accesos?.some((acceso) => acceso.label === servicio.nombre)
   )

   console.log("RESPUESTA FINAL", jsonTabs)


   const getPaciente = async () => {
     await http.get(`clinica/paciente/get/${idPaciente}`).then((response) => {
         console.log("pacienteee", response?.data);
         const areas = response?.data?.hoja_ruta?.hoja_ruta_detalle;
         setPacienteContext(response?.data?.paciente);
         setContextAreas(areas, setTriajeAreasContext, setTriajeDetalleId, 1);
         setContextAreas(areas, setPsicologiaAreasContext, setPsicologiaDetalleId, 5);
         setContextAreas(areas, setEkgAreasContext, setEkgDetalleId, 48);
         setContextAreas(areas, setOftalmologiaAreasContext, setOftalmologiaDetalleId, 49);
         setContextAreas(areas, setAudiometriaAreasContext, setAudiometriaDetalleId, 59);
         setContextExamenes(areas, setRadiologiaAreasContext, setRadiologiaDetalleId, 1);
         setContextAreas(areas, setMedicinaGeneralAreasContext, setMedicinaGeneralDetalleId, 67);
         setContextAreas(areas, setEspirometriaAreasContext, setEspirometriaDetalleId, 64);
         setContextLaboratorio(areas, setLaboratorioAreasContext, setLaboratorioDetalleId, 5);
      })
   }

   const setContextAreas = (areas, setAreaContext, setAreaDetalleId, idAreaMedica) => {
      let servicios = areas.find((hojaRuta) => hojaRuta?.areamedicas?.id === idAreaMedica);
      if (servicios !== undefined) {
         setAreaDetalleId({
            id: servicios.id,
            estado: servicios.estado_ruta,
            hora_inicio: servicios.hora_inicio,
            fecha_inicio: servicios.fecha_inicio,
            hora_fin: servicios.hora_fin,
            fecha_fin: servicios.fecha_fin,
         })

         const servicios_id = servicios?.children.length === 0 ? [servicios?.areamedicas?.id] : servicios?.children?.map((servicio) => servicio?.areamedicas?.id);
         setAreaContext(servicios_id)
      }
   }

   const setContextExamenes = (areas, setExamenContext, setAreaDetalleId, idExamen) => {
      let servicios = areas.find((hojaRuta) => hojaRuta?.examen?.id === idExamen);
      if (servicios !== undefined) {
         setAreaDetalleId({
            id: servicios.id,
            estado: servicios.estado_ruta,
            hora_inicio: servicios.hora_inicio,
            fecha_inicio: servicios.fecha_inicio,
            hora_fin: servicios.hora_fin,
            fecha_fin: servicios.fecha_fin,
         })

         const servicios_id = servicios?.children.length === 0 ? [servicios?.examen?.id] : servicios?.children?.map((servicio) => servicio?.examen?.id);
         console.log("servicios_id", servicios_id);
         setExamenContext(servicios_id)
      }
      console.log("encontro", servicios, idExamen);
   }
   const setContextLaboratorio = (areas, setExamenContext, setAreaDetalleId, idExamen) => {
      
      let servicios = areas.find((hojaRuta) => hojaRuta?.examen?.id === idExamen);
      if (servicios !== undefined) {
         setAreaDetalleId({
            id: servicios.id,
            estado: servicios.estado_ruta,
            hora_inicio: servicios.hora_inicio,
            fecha_inicio: servicios.fecha_inicio,
            hora_fin: servicios.hora_fin,
            fecha_fin: servicios.fecha_fin,
         })

         const servicios_id = servicios?.children.length === 0 ? [servicios?.examen?.id] : servicios?.children?.map((servicio) => { return { id: servicio?.examen?.id, hijos: servicio?.children?.map(hijo => hijo?.examen?.id ) } });
         setExamenContext(servicios_id)
      }

   }

   useEffect(() => {
      getPaciente();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <>
         <RAAtencionContainer
            tabs={<RAAtencionTabNavigator arraytabNavigate={jsonTabs} />}
            form={<RAAtencionFormView/>}
         />
      </>
   );
};

export default PageRAAtencion;