import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import imgServicio from "../../../../ProductosServicios/Servicios/NewService.svg";
import { red } from "@mui/material/colors";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { ScrollPanel } from 'primereact/scrollpanel';


export default function DialogIngreso(props) {
   const [PrecioReferencial, setPrecioReferencial] = useState(0);
   const [IdServiciosSelect, setIdServiciosSelect] = useState([]);
   const [ArraySimplificado, setArraySimplificado] = useState([]);
   const [ItemsSelect, setItemsSelect] = useState([]);
   const [totalPrecioReferencialMensualVenta, setTotalPrecioReferencialMensualVenta] = useState(0);

   const resultadoFiltrado = filtrarPorClaves(ArraySimplificado, IdServiciosSelect);


   useEffect(() => {
      obtenerPrecioReferencial();
      obtenerItemsRelacionados();
      SimplificarArray(props.DataServicios);
   }, [props.IdSelect]);

   useEffect(() => {
      actualizarItemsSeleccionados(props.IdSelect,IdServiciosSelect, resultadoFiltrado);
      // ActualizarItemsSeleccionadosDetalles(props.IdSelect,resultadoFiltrado);
      setTotalPrecioReferencialMensualVenta(sumarPrecioReferencialMensualVenta(ArraySimplificado, IdServiciosSelect));
   }, [ArraySimplificado, IdServiciosSelect]);

   const obtenerPrecioReferencial = () => {
      if (props.DataActual && props.DataActual[props.IdSelect - 1]) {
         setPrecioReferencial(props.DataActual[props.IdSelect - 1].PrecioReferencial);
      }
   };

   const obtenerItemsRelacionados = () => {
      if (props.DataActual && props.DataActual[props.IdSelect - 1]) {
         setIdServiciosSelect(props.DataActual[props.IdSelect - 1].ItemsSeleccionados);
      }
   };

   const actualizarPrecioReferencial = (id, nuevoPrecioReferencial) => {
      const nuevosPerfiles = props.DataActual.map(perfil => {
         if (perfil.Id === id) {
            return { ...perfil, PrecioReferencial: nuevoPrecioReferencial };
         }
         return perfil;
      });
      props.setDataActual(nuevosPerfiles);
   };

   const actualizarItemsSeleccionados = (id, nuevoArrayItems, nuevoArrayItemsDetalle) => {
      const nuevosPerfiles1 = props.DataActual.map(perfil => {
         if (perfil.Id === id) {
            return { ...perfil, ItemsSeleccionados: nuevoArrayItems, ItemsSeleccionadosDetalles:nuevoArrayItemsDetalle};
         }
         return perfil;
      });
      props.setDataActual(nuevosPerfiles1);
   };

   const sumarPrecioReferencialMensualVenta = (array, idsSeleccionados) => {
      let total = 0;
      const clavesSeleccionadas = Object.keys(idsSeleccionados);
      
      array.forEach(item => {
          if (clavesSeleccionadas.includes(item.key)) {
              total += parseFloat(item.data?.precio_referencial_mensual_venta) || 0;
          }
      });
  
      return total;
  };

   const SimplificarArray =(Array)=>{
      const ArraySimplificadoBase = [];
      for (let index = 0; index < Array.length; index++) {
         if(Array[index].children.length > 0){
            for (let a = 0; a < Array[index].children.length; a++) {
               if(Array[index].children[a].children.length > 0){
                  for (let b = 0; b < Array[index].children[a].children.length; b++) {
                     if(Array[index].children[a].children[b].children.length > 0){
                        for (let c = 0; c < Array[index].children[a].children[b].children.length; c++) {
                           if (Array[index].children[a].children[b].children[c].children.length > 0) {
                           }
                           else{
                              ArraySimplificadoBase.push(Array[index].children[a].children[b].children[c])
                           }
                           
                        }
                     }
                     else{
                        ArraySimplificadoBase.push(Array[index].children[a].children[b])
                     }
                  }
               }
               else{
                  ArraySimplificadoBase.push(Array[index].children[a])
               }               
            }
         }
      }
      setArraySimplificado(ArraySimplificadoBase)
   }

   const styleLetra01 = {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'justified',
      color:'#344054',
      marginBottom:'8px'
    };
    const styleLetra02 = {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '17.07px',
      color:'#5555D8',
    };
    const styleLetra03 = {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '500',
      color:'#000000',
      opacity:'.5'
    };

    let sin_capacitaciones = obtenerElementosConChildrenLlenosPrimerNivel(props.DataServicios);
    function obtenerElementosConChildrenLlenosPrimerNivel(array) {
      const elementosConChildrenLlenos = array.filter(elemento => elemento.children && elemento.children.length > 0);
      return elementosConChildrenLlenos;
  }


   const HeaderDialog = () => {
      return (
          <div className="w-10 h-auto flex flex-column align-items-start justity-content-star">
            <div className="flex align-items-center justify-content-center" style={{width:'60px', height:'60px', backgroundColor:'#EBEBFF', borderRadius:'10px'}}>
               <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
                  <mask id="mask0_2215_7506" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="43">
                  <rect width="43" height="43" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_2215_7506)">
                  <path d="M21.8064 15.7854C24.7275 15.7854 27.0956 13.4173 27.0956 10.4962C27.0956 7.57507 24.7275 5.20703 21.8064 5.20703C18.8853 5.20703 16.5172 7.57507 16.5172 10.4962C16.5172 13.4173 18.8853 15.7854 21.8064 15.7854ZM21.8064 15.7854C17.1326 15.7854 13.3438 18.627 13.3438 22.1323M21.8064 15.7854C26.4802 15.7854 30.2691 18.627 30.2691 22.1323" stroke="#5555D8" stroke-width="4" stroke-linecap="round"/>
                  <path d="M2.6875 22.1328H40.2993M7.73248 22.1328V37.8044H35.7936V22.1328" stroke="#5555D8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
               </svg>
            </div>
            <div style={{fontFamily:'Montserrat', fontSize:'18px', color:'#5555D8',margin:'22px 0px 14px 0px'}} className="flex align-items-start justify-content-start">
               <p style={{fontWeight:'700'}}>Seleccionar servicios - </p>
               <p style={{fontWeight:'600', marginLeft:'4px'}}> Entrada</p>
            </div>

            <div className="w-full h-auto flex flex-column align-items-start justify-content-start">
               <p style={styleLetra01}>Perfil:</p>
               <div className="w-full flex align-items-center" style={{backgroundColor:'#F6F2FC', height:'39px',borderRadius:'7px', paddingLeft:'18px'}}>
                  <p style={styleLetra02}>{props.DataActual[props.IdSelect-1].NombrePerfil}</p>
               </div>
            </div>

            <div className="w-full h-auto flex flex-column align-items-start justify-content-start" >
               <p style={{...styleLetra03,margin:'8px 0px'}}>Busca y selecciona el servicio</p>
               <div className="w-full h-auto">
                  <span className="p-input-icon-left">
                     <i className="pi pi-search" />
                     <InputText placeholder="Buscar Servicio" style={{width:'100%'}}/>
                  </span>

               </div>
            </div>
          </div>
      );
  };
  const BodyDialog = () => {
   return (
      <div className="w-full h-auto flex flex-column" >
         {/* <style>{`
            .sin_capacitaciones tbody tr:nth-child(2){
               display:none;    

            }
            .p-scrollpanel-content{
               overflow-x:hidden;
            }
         `}</style> */}
      <ScrollPanel className="custombar1">
         <TreeTable className="sin_capacitaciones" value={sin_capacitaciones} selectionMode="checkbox" selectionKeys={IdServiciosSelect} onSelectionChange={(e) => setIdServiciosSelect(e.value)} tableStyle={{ Width: '100%' }}>
             <Column style={{width:'60%', overflow:'hidden'}} field="nombre" header="Servicio" expander></Column>
             <Column
                        field="data.pVenta"
                        style={{width:'40%'}}
                        header="Precio Referencial"
                        body={(node) => (
                            <>
                                {node?.children?.length !== 0 || ( node?.data?.precio_mensual==='' || node?.data?.precio_anual ==='') ? (
                                    <span >--</span>
                                 )  : (
                                    <span className="text-center">
                                    S/{node?.data?.precio_referencial_mensual_venta}
                                    </span>
                                )}
                            </>
                        )}
                    ></Column>
         </TreeTable>
      </ScrollPanel>
            <div className="w-full h-auto" style={{...styleLetra01, textAlign:'end', margin:'7px 0px'}}>
               Total Actual:   S/. {totalPrecioReferencialMensualVenta}
            </div>
   </div>  
   );
  }

  const styleBotonGuardar = {
   width: '168px',
   height: '44px',

   borderRadius: '5px',
   border: '1px solid transparent',
   backgroundColor:'#5555D8',
   color:'#FFFFFF',

   fontFamily: 'Montserrat',
   fontSize: '14px',
   fontWeight: '500',
 };
 const styleBotonCancelar = {
   width: '168px',
   height: '44px',
   borderRadius: '5px',
   border: '1px solid #D0D5DD',
   backgroundColor:'white',
   color:'#344054',

   fontFamily: 'Montserrat',
   fontSize: '14px',
   fontWeight: '500',
 };

 const limpiarVariables = () => {
   const newDataActual = [...props.DataActual]; // Crear una copia del array para evitar mutaciones directas

   newDataActual[props.IdSelect - 1].PrecioReferencial = 0; // Establecer el precio referencial a cero
   newDataActual[props.IdSelect - 1].ItemsSeleccionados = []; // Establecer la lista de items seleccionados como un array vacÃ­o
   setPrecioReferencial(0); // Reiniciar el estado local del precio referencial
   setIdServiciosSelect([]); // Reiniciar el estado local de los servicios seleccionados
   setArraySimplificado([]); // Reiniciar el estado local del array simplificado
   setTotalPrecioReferencialMensualVenta(0); // Reiniciar el estado local del total del precio referencial mensual
   props.setDataActual(newDataActual); // Actualizar el estado global con los nuevos datos
};



function filtrarPorClaves(objeto1, objeto2) {
   const clavesObjeto2 = Object.keys(objeto2);
   const resultado = {};
 
   for (const clave in objeto1) {
     if (objeto1.hasOwnProperty(clave)) {
       if (clavesObjeto2.includes(objeto1[clave].key)) {
         resultado[clave] = objeto1[clave];
       }
     }
   }
 
   return resultado;
 }



 const ActualizarItemsSeleccionadosDetalles = (id, resultado) =>{
   const nuevosItems = props.DataActual.map(perfil => {
      if (perfil.Id === id) {
         return { ...perfil, ItemsSeleccionadosDetalles: resultado };
      }
      return perfil;
   });
   props.setDataActual(nuevosItems);

 }








  const FooterDialog = () => {
      return (
         <div>
            <button onClick={() => {limpiarVariables();props.setActivador(false)}} style={{...styleBotonCancelar}}>
               Cancelar
            </button>
            <button onClick={() => {
               actualizarPrecioReferencial(props.IdSelect, totalPrecioReferencialMensualVenta); 
               props.setActivador(false)}} style={{...styleBotonGuardar}}>
               Guardar
            </button>
            {/* <button onClick={() => {console.log("Array Simplificado: ",resultadoFiltrado)}} style={{...styleBotonCancelar}}>
               Ver Array Simplificado
            </button> */}
         </div>
         
      )
  }
  
   return (
      <Dialog
         style={{ width: "573px", height: "auto" }}
         visible={props.Activador}
         header={HeaderDialog}
         onHide={() => props.setActivador(false)}
         footer={FooterDialog}
      >
            {BodyDialog()}
        
      </Dialog>
   );
}