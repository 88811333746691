import React, { useEffect, useState } from 'react'
import AuthUser from '../../AuthUser';
import RAResultadosTable from '../../Components/RutaAtencion/Resultados/RAResultadosTable';


export default function PageMenuTriaje() {
   const { http } = AuthUser();
   const [pacientes, setPacientes] = useState();
   const getPacientes = () => {
      http
         .get("/atencion/paciente/area/1")
         .then((response) => {
            setPacientes(response.data.data);
         })
         .catch((error) => {
            console.log(error);
         });
   }; 

   useEffect(() => { 
      getPacientes();
   }, [])


   return (
      <div>
         <RAResultadosTable
            pacientes={pacientes}
            nombre={"Triaje"}
         ></RAResultadosTable>
      </div>
   )
}