import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useSelector } from "react-redux"; // Importa useSelector de react-redux
import logo from "../../Images/logo.png";
import logoimagen from "../../Images/logoimagen.png";
import logoMiniClinica from "../../Images/logoMiniclinica.png";
//import logoClinica from "../../Images/logoclinica.png";
import { useNavigate } from "react-router-dom";
import Logo3 from "../../Images/SideBar/img/logo_melaform.jpg";
import "../../Style/Sidebar/sidebar.css";

import logoClinica from "../../Images/logos/logoCliniica.png";
import logoEmpresa from "../../Images/logos/logoEmpresa.png";
import defectoLogo from "../../Images/logos/defectoLogo.png";

function Sidebar({ sidebar, setOpen, isOpen, rol }) {
   const [initialOpen, setInitialOpen] = useState(true);
   const [isMobile, setIsMobile] = useState(window.innerWidth <= 1111);
   const { logo: sidebarLogo } = useSelector((state) => state.data); // Obtén la URL del logo desde el estado de Redux
   
   useEffect(() => {
      const handleResize = () => {
         setIsMobile(window.innerWidth <= 1111);
         setInitialOpen(window.innerWidth > 1111);
      };

      window.addEventListener("resize", handleResize);

      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);

   useEffect(() => {
      setOpen(isMobile ? false : initialOpen);
   }, [isMobile, initialOpen, setOpen]);

   let navigate = useNavigate();

   return (
      <React.Fragment>
         <div className="sidebar-logo">
            {rol === 3 && <img src={sidebarLogo === "null" || sidebarLogo === null ? logoEmpresa : sidebarLogo} alt="logo" style={{ width: '8rem', height: '4rem', marginTop: '0.5rem', marginBottom: '1rem', borderRadius: "1rem" }} />}
            {rol === 2 && <img src={sidebarLogo === "null" || sidebarLogo === null ? logoEmpresa : sidebarLogo} alt="logo" style={{ width: '8rem', height: '4rem', marginTop: '0.5rem', marginBottom: '1rem', borderRadius: "1rem" }} />}


            {/* <img src={logoClinica} alt="logo" style={{ width: '8rem', height: '4rem', marginTop: '0.5rem', marginBottom: '1rem', borderRadius:"1rem" }} /> */}
         </div>
         <Button
            icon={isOpen ? "pi pi-chevron-left" : "pi pi-chevron-right"}
            className={isOpen ? "BotonSidebarAbierto" : "BotonSidebarCerrado"}
            onClick={() => setOpen(!isOpen)}
         />
         <div className={isOpen ? "sidebar-lista abierto scroll" : "sidebar-lista cerrado sideba scroll"}>
            <div className="sidebar-home-btn">
               {/* <Button label={isOpen && "Inicio"} icon="pi pi-th-large" /> */}
            </div>
            <ul className={isOpen ? "sidebar-lista-ul pad" : "sidebar-lista-ul nopad"}>
               {sidebar.map((item) => {
                  return (
                     item.tipo === "0" && (
                        <li key={item.id} className="sidebar-lista-opcion hover" onClick={() => navigate(`${item.url}`)}>
                           <i className={item.icon} style={{ fontSize: isOpen ? "1rem" : "1.5rem", transition: "0.5s" }}></i>
                           <span>{isOpen && item.label}</span>
                        </li>
                     )
                  );
               })}
            </ul>
         </div>
      </React.Fragment>
   );
}

export default Sidebar;