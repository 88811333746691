import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import React, { useState, useEffect, useContext } from "react";
import { SalesContext } from "../../SalesContext/SalesContext";

const S3ProductPackageSelection = (props) => {

   const { setSendPaquete,  setSelectedPackageIndex, checkedIndexC, setCheckedIndexC } = useContext(SalesContext);

   console.log(props.checkedIndex)
   const handleCheckboxChange = (index) => {
      if ((index === props.checkedIndex && props.origin === 0) || (index === checkedIndexC && props.origin === 1)) {
         // Desmarcar el checkbox y reiniciar datos cargados
         if (props.origin === 0) {
            props.setCheckedIndex(null);
         }

         if (props.origin === 1) {
            setCheckedIndexC(null);
         }

         props.onSelectionPackage([]);
         setSendPaquete(null);
      } else {
         setSelectedPackageIndex(index - 1);

         if (props.origin === 0) {
            props.setCheckedIndex(index);
         }
         if (props.origin === 1) {
            setCheckedIndexC(index);
         }
         const Seleccionado = (props.packages || []).filter((i) => i.paquete.id === index);
         if (props.onSelectionPackage) {
            console.log('data seleccionada', Seleccionado);
            setSendPaquete(null);
            // Actualizar los datos seleccionados en el contexto
            props.onSelectionPackage([Seleccionado[0]]);  //Seleccionar el primer elemento del array filtrado
            //setPaqueteS3C(Seleccionado[0].paquete.nombre)
         }
      }

      console.log(`Checkbox con ID ${index} seleccionado`);
   };

   //#region BUSCADOR
   const [searchText, setSearchText] = useState('');
   const [filteredData, setFilteredData] = useState(props.packages || []);

   const handleSearch = (e) => {
      const keyword = e.target.value.toLowerCase();
      setSearchText(keyword);
      console.log('paquettte', props.packages)
      const filtered = (props.packages || []).filter((item) =>
         item.paquete.nombre.toLowerCase().includes(keyword) || item.paquete.id === props.checkedIndex
         //error 
      );
      setFilteredData(filtered);
   };


   useEffect(() => {
      console.log('filteredData', filteredData);
   }, [filteredData]);

   //#endregion

   /*     useEffect(() => {
           if (props.onSelectionPackage) {
               props.onSelectionPackage(props.packages ? [props.packages[selectedPackageIndex]] : []);
           }
       }, []); */


   console.log('paquetes filtrados', filteredData)

   console.log('asds', props.packages)

   return (
      <div className="flex flex-column gap-2 justify-content-center">
         <span className="p-input-icon-left flex-1 flex align-content-center w-full">
            <i className="pi pi-search" />
            <InputText
               value={searchText}
               onChange={(e) => handleSearch(e)}
               placeholder="Buscar"
               className="p-inputtext-sm w-full"
            />
         </span>

         <div className="flex flex-column border-round-lg bg-gray-300 border-gray-300 gap-2 p-3 ventas-scroll" style={props.styleListPackeges || {}}>
            {props?.packages?.length > 0 ?
               filteredData.map((ucp, index) => {
                  return (
                     <div className="flex gap-2 justify-content-between align-items-center" key={index}>
                        <p className="text-gray-500">{ucp.paquete.nombre}</p>
                        <Checkbox
                           onChange={() => handleCheckboxChange(ucp.paquete.id)}
                           checked={((ucp.paquete.id === props.checkedIndex && props.origin === 0) || (ucp.paquete.id === checkedIndexC && props.origin === 1))}
                        />
                     </div>
                  );
               })
               :
               'no se encontraron paquetes'
            }
         </div>

      </div>
   );
}
export default S3ProductPackageSelection;