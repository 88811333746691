import React, { useContext, useState, useRef, useEffect } from "react";
import { EkgContext } from "../../Page/Ekg/EkgContext";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import AuthUser from "../../AuthUser";
import "../../Style/EkgDatos.css";
import EvaluadorTableAHP from "../MedicinaGeneral/examenAltura/pure/EvaluadorTableAHP";
import { EnvioArchivoEKG } from "./EnvioArchivoEKG";
const EkgDatos = (props) => {
   let { datosContext, setDatosContext } = useContext(EkgContext);
   console.log("datos context", datosContext);

   const { http } = AuthUser();
   const [mostrarDialog, setMostrarDialog] = useState(false);
   const [urlPDF, setUrlPDF] = useState("");

   const [selectEvaluador, setselectEvaluador] = useState(datosContext.objeto_medico ? datosContext.objeto_medico : null);
   const [evaluadores, setEvaluadores] = useState({})
   const [visibleEvaluador, setVisibleEvaluador] = useState(false);
   const [ColegiaturaEv, setColegiaturaEv] = useState(selectEvaluador ? selectEvaluador.colegiatura : null);
   const [NombreMedicoEvaluador, setNombreMedicoEvaluador] = useState(selectEvaluador ? selectEvaluador.nombres_completos : null);

   const getMedicos = () => {
      http
         .get("medicina_general/evaluacion_medica/evaluadores/get")
         .then((response) => {
            setEvaluadores(response.data.evaluadores)
         })
   }

   useEffect(() => {
      if (selectEvaluador != null) {
         setNombreMedicoEvaluador(selectEvaluador.nombres_completos);
         setColegiaturaEv(selectEvaluador.colegiatura);
      }
      else {
         setNombreMedicoEvaluador("");
         setColegiaturaEv("");
      }

   }, [selectEvaluador]);

   useEffect(() => {
      getMedicos()
   }, []);

   useEffect(() => {
      // Actualizar el contexto con los datos del evaluador seleccionado
      setDatosContext(RayTorax => ({
         ...RayTorax,
         medico_evaluador: NombreMedicoEvaluador,
         colegiatura: ColegiaturaEv
      }));
   }, [NombreMedicoEvaluador, ColegiaturaEv]);

   return (
      <>
         <div className="tsv_form1_container">
            <div className="tsv_form1_input">
               <label>Ritmo</label>
               <InputText
                  value={datosContext.ritmo}
                  onChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        ritmo: e.target.value
                     })
                  }
                  placeholder="Ritmo"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
               />
            </div>
            <div className="tsv_form1_input">
               <label>PR</label>
               <InputNumber
                  value={datosContext.pr}
                  min={0}
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        pr: e.value
                     })
                  }
                  placeholder="0"
               />
            </div>
            <div className="tsv_form1_input">
               <label>Frecuencia (/min)</label>
               <InputNumber
                  value={datosContext.frecuencia}
                  min={0}
                  onValueChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        frecuencia: e.value
                     })
                  }
                  placeholder="0"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
               />
            </div>
            <div className="tsv_form1_input">
               <label>QRS</label>
               <InputNumber
                  value={datosContext.qrs}
                  min={0}
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        qrs: e.value
                     })
                  }
                  placeholder="0"
               />
            </div>
            <div className="tsv_form1_input">
               <label>Eje eléctrico</label>
               <InputText
                  value={datosContext.eje_electrico}
                  min={0}
                  onChange={(e) => {
                     const inputValue = e.target.value;
                     const regex = /^[+-]?[0-9]*\.?[0-9]{0,2}$/;
                     if (regex.test(inputValue)) {
                        setDatosContext({
                           ...datosContext,
                           eje_electrico: inputValue
                        })
                     }
                  }
                  }
                  placeholder="0"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
               />
            </div>
            <div className="tsv_form1_input">
               <label>QT</label>
               <InputNumber
                  value={datosContext.qt}
                  min={0}
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        qt: e.value
                     })
                  }
                  placeholder="0"
               />
            </div>
            <div className="tsv_form1_input">
               <label>Conclusiones</label>
               <InputTextarea
                  onChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        conclusiones: e.target.value,
                     })
                  }
                  autoResize
                  value={datosContext.conclusiones}
                  placeholder="Ingrese una conclusión"
               />
            </div>
            <div className="tsv_form1_input">
               <label>Recomendaciones</label>
               <InputTextarea
                  onChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        recomendaciones: e.target.value,
                     })
                  }
                  autoResize
                  value={datosContext.recomendaciones}
                  placeholder="Ingrese una recomendación"
               />
            </div>
            <div className="tsv_form1_input">
               <label>Médico Evaluador</label>
               <InputText
                  value={datosContext.medico_evaluador}
                  onChange={(e) =>
                     setDatosContext({
                        ...datosContext,
                        medico_evaluador: e.target.value,
                     })
                  }
                  placeholder="Seleccione un médico evaluador"
                  disabled
               />
            </div>
            <div className="flex flex-column gap-2">
               <label>Colegiatura</label>
               <div className="flex flex-row">
                  <InputText
                     value={datosContext.colegiatura}
                     onChange={(e) =>
                        setDatosContext({
                           ...datosContext,
                           colegiatura: e.target.value,
                        })
                     }
                     placeholder="Seleccione un médico evaluador"
                     disabled
                     style={{
                        width: "72%", marginRight: "10px"
                     }} />
                  <Button
                     className="flex justify-content-center"
                     label="Buscar"
                     onClick={() => { setVisibleEvaluador(true) }}
                     style={{
                        width: "25%"
                     }}
                  />
               </div>
            </div>
         </div>
         <div className="mt-5">
            <EnvioArchivoEKG />
         </div>
         <EvaluadorTableAHP
            medico={"Evaluador"}
            evaluadores={evaluadores}
            visible={visibleEvaluador}
            setVisible={setVisibleEvaluador}
            evaluadorSelect={selectEvaluador}
            setEvaluador={setselectEvaluador}
         ></EvaluadorTableAHP>
      </>
   );
};

export default EkgDatos;