import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';

const ModalFinalizar = ({ visible, setVisible, terminarAtencion, getPacientes, estadoPaciente }) => {

   const HideDialog = () => {
      setVisible(false);
   };


   let mensajeDialogo = "";
   let titulo = "";

   switch (estadoPaciente.estado_paciente) {
      case "0":
         mensajeDialogo = "¿Está seguro que desea finalizar la atención?";
         titulo = "Empezar atención"
         break;
      case "1":
         mensajeDialogo = "¿Seguro que desea terminar la atención?";
         titulo = "Finalizar atención"
         break;
      case "2":
         mensajeDialogo = "La atencion ya fue finalizada";
         titulo = "Atencion finalizada"
         break;
      default:
         mensajeDialogo = "Algo salió mal";
         titulo = "Error"
         break;
   }

   const deleteDialogFooter = (id) => {

      if (estadoPaciente.estado_paciente === "2") {
         return (
            <div className="flex w-full bot justify-content-end">
               <Button
                  type="button"
                  label="Aceptar"
                  className="delete w-5"
                  onClick={() => {
                     HideDialog();
                  }}
               /></div>
         )
      }

      return (
         <div className="flex w-full h-3rem">
            <Button
               type="button"
               label="Cancelar"
               className="delete w-full"
               onClick={() => {
                  HideDialog();
               }}
            />
            <Button
               type=""
               label="Finalizar Atención"
               className="p-button-success w-full"
               style={{
                  backgroundColor: "#5555d8",
                  borderColor: "#5555d8",
               }}
               onClick={() => {
                  terminarAtencion();
                  getPacientes();
                  HideDialog();
               }}
            />
         </div>
      );
   };


   return (
      <div>
         <Dialog
            visible={visible}
            style={{ margin: "20px", width: "350px" }}
            //appendTo={document.getElementById("personal-form-delete")}
            header={
               <>
                  {/* <div
              className="flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#EBEBFF",
                width: "60px",
                height: "60px",
                borderradius: "10px",
              }}
              onClick={() => {
                HideDialog();
              }}
            >
              <div className="flex justify-content-center align-items-center">
                <i className="pi pi-trash" style={{ fontSize: '2rem', color: "#FF6767", fontWeight: "700" }}></i>
              </div>
            </div> */}
                  <h1 style={{
                     fontFamily: 'Montserrat',
                     fontStyle: "normal",
                     fontWeight: "700",
                     fontSize: "18",
                     color: "#5555d8"
                  }}>{titulo}</h1>
               </>
            }
            footer={deleteDialogFooter()}
            onHide={() => {
               HideDialog();
            }}
         >
            <div className="flex flex-column gap-3 pt-4">
               <div className="flex flex-column w-full">
                  <p className="m-0 justify-content-center align-items"
                  >{mensajeDialogo}</p>
               </div>
            </div>
         </Dialog>
      </div>
   );
}

export default ModalFinalizar;
