import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useContext } from "react";
import { TriajeContext } from "../../../Triaje/TriajeContext";

export default function DialogEliminarGeneral(props) {
    let { AntOcupContext, setAntOcupContext } = useContext(TriajeContext);
    let { antLabContext } = useContext(TriajeContext);

    const EliminarAntLab = () => {
        props.setVisibleEliminar(false);
        props.ContextEliminar.splice(props.DataId, 1);
        console.log("props.ContextEliminar", props.ContextEliminar);
        setAntOcupContext({ ...AntOcupContext, antecedente_laboral: [...antLabContext] });

        // Verificar si props.showToast es una función antes de llamarla
        if (typeof props.showToast === 'function') {
            props.showToast(
                "success",
                "Eliminado correctamente",
                "Se eliminó " + props.texto
            );
        }
    };

    // BOTONES DE CONFIMACION (ELIMINAR)
    const handleDeleteConfirmation = () => (
        <React.Fragment>
            <div className="flex justify-content-between">
                <Button
                    label="Cancelar"
                    className="p-button p-component delete w-full"
                    onClick={() => props.setVisibleEliminar(false)}
                />
                <Button
                    label="Eliminar"
                    className="p-button-success w-full"
                    style={{
                        color: "#5555D8",
                        backgroundColor: "#EBEBFF",
                        borderColor: "#5555D8",
                    }}
                    onClick={() => EliminarAntLab()}
                />
            </div>
        </React.Fragment>
    );

    return (
        <Dialog
            visible={props.VisibleEliminar}
            style={{ width: "450px", height: "220px" }}
            className="p-fluid"
            header={<p style={{ color: "#5555D8", fontSize: "32px" }}>Eliminar</p>}
            footer={handleDeleteConfirmation}
            onHide={() => props.setVisibleEliminar(false)}
        >
            <p style={{ fontSize: "20px" }}>¿Está seguro que desea eliminar?</p>
        </Dialog>
    );
}
