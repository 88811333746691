import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState } from "react";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import AuthUser from "../../../AuthUser";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import InputCalendar from "../../form/InputCalendar";
import { classNames } from 'primereact/utils';

export default function ModalCreate({
   visibleCreate,
   setVisibleCreate,
   setPersonal,
   showToast,
   tipoDocumentos,
   sexos,
   roles,
   getPersonal,
   api,
   data,
   contractOptions
}) {

   const { http } = AuthUser();
   const dispatch = useDispatch();
   const [fechaNacimiento, setFechaNacimiento] = useState(null);


   const handleSexoChange = (e) => {
      console.log(e)
      setSexo(e.value);
   };
   const [sexo, setSexo] = useState(null);
   const handleFechaNacimientoChange = (date) => {
      console.log("Fecha seleccionada:", date);
      setFechaNacimiento(date.value);
      if (date.value instanceof Date) {
         const año = date.value.getFullYear();
         const mes = ('0' + (date.value.getMonth() + 1)).slice(-2); // Se agrega 1 al mes porque los meses van de 0 a 11
         const dia = ('0' + date.value.getDate()).slice(-2);

         // Formatear la fecha en el formato deseado (yyyy-dd-mm)
         const fechaFormateada = `${año}-${dia}-${mes}`;
         formik.setFieldValue("fecha_nacimiento", fechaFormateada);
      }
   };

   let options = [
      { label: 'Es usuario', value: true },
      { label: 'No es usuario', value: false }
   ]
   const [value, setValue] = useState("");
   const initialValues = {
      id: 0,
      tipo_documento: tipoDocumentos?.[0],
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      distrito_id: "",
      celular: "",
      correo: "",
      direccion: "",
      rol: "",
      empresa_cargo: "",
      usuario: "",
      sexo_id: ""
   };

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: async (data) => {
         const formDataSend = new FormData();
         for (var key in data) {
            formDataSend.append(key, data[key]);
         }
         formDataSend.append("tipo_documento_id", data?.tipo_documento?.id);
         formDataSend.append("tipo_contrato_id", data?.tipo_contrato?.id);
         formDataSend.append("empresa_cargo_id", data?.empresa_cargo?.id);
         formDataSend.append("rol_id", data?.rol?.id);
         console.log(...formDataSend)
         dispatch(startLoading());
         try {
            const response = await http.post(api, formDataSend)
            if (response?.data?.resp) {
               HideDialogCreate();
               await Promise.all([
                  getPersonal()
               ])
               setPersonal();
               showToast("success", "Personal creado", response?.data?.resp);
            } else if (response?.data?.error) {
               showToast("error", "Error", response?.data?.error);
            }
         } catch (error) {
            HideDialogCreate();
            showToast("error", "Error", "Contáctese con soporte técnico");
         } finally {
            dispatch(endLoading());
         }
      },
      validate: (data) => {
         let errors = {};

         if (!data.sexo_id) {
            errors.sexo_id = "Sexo es requerido.";
         }
         if (!data.numero_documento) {
            errors.numero_documento = "Número de documento es requerido"
         }
         if (!data.tipo_documento) {
            errors.tipo_documento = "Tipo de documento requerido.";
         }
         if (!data.apellido_paterno) {
            errors.apellido_paterno = "Apellido paterno requerido"
         }
         if (!data.apellido_materno) {
            errors.apellido_materno = "Apellido materno requerido"
         }
         if (!data.nombres) {
            errors.nombres = "Nombres requeridos"
         }

         return errors;
      }
   });

   const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
   const getFormErrorMessage = (name) => {
      return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
   };

   const HideDialogCreate = () => {
      setVisibleCreate(false);
      formik.resetForm();
      setValue(null);
   };

   const paqueteCreateDialogFooter = (
      <div className="flex w-full bot">
         <Button
            type="button"
            label="Cancelar"
            className="delete w-full"
            onClick={() => {
               HideDialogCreate();
            }}
         />
         <Button
            type="submit"
            label="Crear Usuario"
            className="p-button-success w-full"
            style={{
               backgroundColor: "#5555D8",
               borderColor: "#5555D8"
            }}
         />
      </div>
   );

   const getNombreCompleto = (numdocumento, tipoDocumento, tipo_contrato, correo, celular, rol, usuario, empresa_cargo) => {
      if (formik.values.tipo_documento !== "") {
         dispatch(startLoading())
         if (formik.values.tipo_documento.nombre === "DNI") {
            axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
               .then(res => {
                  formik.setValues({
                     tipo_documento: tipoDocumento,
                     numero_documento: numdocumento,
                     tipo_contrato: tipo_contrato,
                     nombres: res.data.nombres,
                     apellido_paterno: res.data.apellidoPaterno,
                     apellido_materno: res.data.apellidoMaterno,
                     fecha_nacimiento: "2022-01-01",
                     distrito_id: 2,
                     celular: correo,
                     correo: celular,
                     direccion: "",
                     rol: rol,
                     usuario: usuario,
                     empresa_cargo: empresa_cargo,
                  })
               })
               .catch((error) => {
                  showToast(
                     "error",
                     "Número de documento no encontrado",
                     `No se encontró ningun dato`
                  )
               })
               .finally(() => {
                  dispatch(endLoading());
               })
         } else if (formik.values.tipo_documento.nombre === "RUC") {
            axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
               .then(res => {
                  formik.setValues({
                     tipo_documento: tipoDocumento,
                     numero_documento: numdocumento,
                     nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
                     apellido_paterno: res.data.razonSocial.split(" ")[0],
                     apellido_materno: res.data.razonSocial.split(" ")[1],
                     fecha_nacimiento: "2022-01-01",
                     distrito_id: 2,
                     celular: celular,
                     correo: correo,
                     direccion: "",
                     rol: rol,
                     usuario: "",
                     empresa_cargo: empresa_cargo,
                     tipo_contrato: tipo_contrato,
                  })
               })
               .catch((error) => {
                  showToast(
                     "error",
                     "Número de documento no encontrado",
                     `No se encontró ningun dato`
                  )
               })
         }
      } else {
         showToast(
            "error",
            "Tipo de documento",
            `No se eligió un tipo de documento`
         );
      }
   }

   useEffect(() => {
      if (value) {
         formik.setFieldValue('usuario', "1")
      } else {
         formik.setFieldValue('usuario', "0")
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [value]);

   return (
      <div>
         <form
            id="persona-form"
            className="persona-form"
            noValidate
            onSubmit={formik.handleSubmit}
         >
            <Dialog
               visible={visibleCreate}
               style={{
                  width: "450px",
                  // height: "878px",
                  // borderRadius: '25px',
                  margin: "5%"
               }}
               appendTo={document.getElementById("persona-form")}
               header={
                  <>
                     <div className="flex align-items-center">
                        <div className="flex justify-content-center align-items-center"
                           style={{ backgroundColor: "#EBEBFF", width: "60px", height: "60px", borderRadius: "10px", marginRight: '7px' }}
                        >
                           <i className="pi pi-user" style={{ color: '#5555d8', fontSize: '30px' }}></i>
                        </div>
                        <div className="flex">
                           <h1 className="per">Crear Personal</h1>
                        </div>
                     </div>
                  </>
               }
               dismissableMask
               className="p-fluid "
               footer={paqueteCreateDialogFooter}
               onHide={() => {
                  HideDialogCreate();
               }}
            >
               <div className="flex flex-column gap-3">
                  <div className="flex flex-column w-full">
                     <p className="m-0 mb-2">
                        En esta seccion usted puede registrar los datos del personal para su creación.
                     </p>

                     <p className="per1">Tipo de documento</p>
                     <div className="flex w-full flex-column">
                        <Dropdown
                           id="tipo_documento"
                           name="tipo_documento"
                           value={formik.values.tipo_documento}
                           onChange={formik.handleChange}
                           options={tipoDocumentos}
                           optionLabel="nombre"
                           placeholder="Selecciona un tipo de documento"
                           className={classNames({ 'p-invalid': isFormFieldValid('tipo_documento') })}
                        />
                        {getFormErrorMessage('tipo_documento')}
                     </div>
                  </div>

                  <div className="flex flex-1 w-full flex-column gap-2">
                     <p className="per1">Número de documento</p>
                     <div className="flex flex-1 w-full gap-2">
                        <InputText
                           id="numero_documento"
                           name="numero_documento"
                           value={formik.values.numero_documento}
                           onChange={formik.handleChange}
                           required
                           className={classNames({ 'p-invalid': isFormFieldValid('numero_documento') })}
                        />
                        <Button
                           label="Validar"
                           type="button"
                           style={{ background: "#07A34F", borderColor: "#07A34F" }}
                           onClick={() => getNombreCompleto(
                              formik.values.numero_documento,
                              formik.values.tipo_documento,
                              formik.values.tipo_contrato,
                              formik.values.correo,
                              formik.values.celular,
                              formik.values.rol,
                              formik.values.empresa_cargo,
                              formik.values.usuario,
                           )}
                           disabled={formik.values.tipo_documento?.id === 2}
                        />
                     </div>
                     {getFormErrorMessage('numero_documento')}
                  </div>

                  <div className="flex flex-column gap-2">
                     <label>Nombres Completos
                        <InputText
                           id="nombres"
                           name="nombres"
                           value={formik.values.nombres}
                           className={classNames({ 'p-invalid': isFormFieldValid('nombres') })}
                           onChange={formik.handleChange}
                           required
                           autoComplete="off"
                        />
                        {getFormErrorMessage('nombres')}
                     </label>
                  </div>

                  <div className="flex flex-column gap-2">
                     <label htmlFor="lastname">Apellidos Paterno</label>
                     <InputText
                        id="apellido_paterno"
                        name="apellido_paterno"
                        value={formik.values.apellido_paterno}
                        onChange={formik.handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValid('apellido_paterno') })} />
                     {getFormErrorMessage('apellido_paterno')}
                  </div>

                  <div className="flex flex-column gap-2">
                     <label htmlFor="lastname">Apellidos Materno</label>
                     <InputText
                        id="apellido_materno"
                        name="apellido_materno"
                        value={formik.values.apellido_materno}
                        onChange={formik.handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValid('apellido_materno') })} />
                     {getFormErrorMessage('apellido_materno')}
                  </div>

                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Correo</label>
                     <InputText
                        id="correo"
                        name="correo"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>

                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Celular</label>
                     <InputText
                        type="number"
                        id="celular"
                        name="celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>

                  <div className="flex flex-column">
                     <p>Puesto de trabajo</p>
                     <Dropdown
                        id="empresa_cargo"
                        name="empresa_cargo"
                        value={formik.values.empresa_cargo}
                        onChange={formik.handleChange}
                        options={data}
                        optionLabel="nombre"
                        placeholder="Selecciona un Puesto de trabajo"
                        className="w-full"
                     />
                  </div>

                  <div className="flex w-full flex-column">
                     <p className="per1">Tipo de contrato</p>
                     <Dropdown
                        id="tipo_contrato"
                        name="tipo_contrato"
                        value={formik.values.tipo_contrato}
                        onChange={formik.handleChange}
                        options={contractOptions}
                        optionLabel="nombre"
                        placeholder="Selecciona un tipo de contrato"
                        className="w-full"
                     />
                  </div>

                  <div className="flex w-full flex-column">
                     <p className="per1">Último examen médico</p>
                     <Calendar
                        id="examen_medico"
                        name="examen_medico"
                        value={formik.values.examen_medico}
                        onChange={formik.handleChange}
                        optionLabel="nombre"
                        placeholder="Seleccionar fecha"
                        className="w-full"
                     />
                  </div>

                  <div className="flex justify-content-center align-content-center">
                     <SelectButton
                        className="flex-1"
                        value={value}
                        onChange={(e) => (setValue(e.value))}
                        options={options}
                     ></SelectButton>
                  </div>

                  <div className="flex flex-column">
                     <p>Rol</p>
                     <Dropdown
                        id="rol"
                        name="rol"
                        value={formik.values.rol}
                        onChange={formik.handleChange}
                        options={roles}
                        optionLabel="nombre"
                        placeholder="Selecciona un Rol"
                        className="w-full"
                        disabled={!value}
                     />
                  </div>
                  <div className="flex flex-1 w-full flex-column gap-2">
                     <div className="flex flex-1 w-full gap-2">

                        <div>
                           <p>Sexo</p>
                           <Dropdown
                              id="sexo_id"
                              name="sexo_id"
                              value={formik.values.sexo_id}
                              onChange={formik.handleChange}
                              options={sexos}
                              optionLabel="nombre"
                              optionValue="id"
                              placeholder="Selecciona tu sexo"
                              className={classNames({ 'p-invalid': isFormFieldValid('sexo_id') })}
                           />
                           {getFormErrorMessage('sexo_id')}
                        </div>

                        <div>
                           <p>Fecha de nacimiento</p>
                           {/* <Calendar value={fechaNacimiento} onChange={handleFechaNacimientoChange} /> */}
                           <InputCalendar
                              value={fechaNacimiento} onChange={handleFechaNacimientoChange}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Dialog>
         </form>
      </div>
   );
}