import React, { useState, useRef, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import AuthUser from "../../../AuthUser";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import '../../../Style/stylePerfil.css';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import RucIcon from "../../../Images/Iconperfil/Credit-Card.png"




export default function CardCuentaComponent({ entidadPago, getbregma, entidadesBancarias }) {
    const { http } = AuthUser();
    const [visible, setVisible] = useState(false);
    const [entidadPagoUpdate, setEntidadPagoUpdate] = useState({
        id: 0,
        numero_cuenta: "",
        cci: "",
        entidad_bancaria_id: null,
        entidad_bancaria: null,
    })
    

    const handleChangeEntidadBancaria = (e) => {
        setEntidadPagoUpdate({ ...entidadPagoUpdate, entidad_bancaria: e.target.value, entidad_bancaria_id: e.value === null ? null : e.value.id })
    }

    useEffect(() => {
        setEntidadPagoUpdate(entidadPago)
        //console.log('entidad bancaria', entidadPago)

    }, [entidadPago])

    const cciRef = useRef('');
    const numeroCuentaRef = useRef('');

    const save = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Data Saved' });
    };
    const saveCuentaBancaria = () => {
        const cuentaBancaria = {
            entidad_bancaria_id: entidadPagoUpdate.entidad_bancaria_id,
            entidad_bancaria: entidadPagoUpdate.entidad_bancaria,
            numero_cuenta: numeroCuentaRef.current.value,
            cci: cciRef.current.value
        }
        //console.log("cuentttaaaaaaaaaaaa a enviaaar", cuentaBancaria)
        http
            .put(`entidad_pago/update/${entidadPago.id}`, cuentaBancaria)
            .then((response) => {
                setVisible(false)
                getbregma();
                //console.log("Actualización cuenta_bancaria", response);
            })
            .catch((error) => {
                setVisible(false)
                console.error(error);
            })

        showSuccessToast('Cuenta bancaria guardada exitosamente');
        setVisible(false);


    }
    const toast = useRef(null);

    //delete

    const [btndelete, setBtndelete] = useState(false);

    const showSuccessToast = (message) => {
        toast.current.show({ severity: 'success', summary: 'Éxito', detail: message });
    };

    const showErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message });
    };


    const handleEliminarEntidad = () => {
        http
            .delete(`entidad_pago/delete/${entidadPago.id}`)
            .then((response) => {
                setBtndelete(false);
                getbregma();
                //console.log(response.data.Resp);
                showSuccessToast('Registro eliminado exitosamente');
            })
            .catch((error) => {
                setBtndelete(false);
                console.error(error);
                showErrorToast('Error al eliminar el registro');
            });
    };

    const footerContent = (
        <div className="card flex flex-wrap justify-content-end gap-3">
            <Button onClick={() => handleEliminarEntidad()} className="p-button-danger">
                <span style={{ fontWeight: "500", fontSize: "14px" }}>Borrar</span>
            </Button>
            <Button onClick={() => setBtndelete(false)} severity="danger" outlined="true" className="p-button-outlined p-button-success" >
                <span style={{ fontWeight: "500", fontSize: "14px" }}>Cancelar</span>
            </Button>
        </div>
    );
    

    // Skeleton
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2200);
    }, [])
    const loader = () => {
        return (
            <div>
                <div>
                    <Skeleton width="281px" height="226px" borderRadius="16px"></Skeleton>
                </div>
            </div>
        );
    }

    //console.log("LOGGG", entidadPago?.entidad_bancaria?.logo)

    if (loading) {
        return (loader())
    }

    else {
        return (
            <div className="wrapperdbb p-1 px-3 m-3">
                <div className= "carousel-card-cuentas">
                    <div className="flex flex-wrap justify-content-between gap-1 linea">
                        <img src={entidadPago?.entidad_bancaria?.logo} alt="Entidad bancaria" width="150px" style={{ padding: '8px', height: '80px', marginLeft: '-20px', marginTop: '-10px', marginBottom:'-10px', position: "relative" }} />
                        <div className="flex flex-row gap-2">
                            <Button icon='pi pi-trash' onClick={() => setBtndelete(true)} severity="danger" outlined="true" className="p-button-danger p-button-text " style={{ width: '30px', height: '30px', marginTop: '10px', marginRight: '-5px', backgroundColor: '#FFECEC' }} />
                            <Button icon="pi pi-pencil" onClick={() => setVisible(true)} className="p-button-success p-button-text " style={{ width: '30px', height: '30px', marginTop: '10px', marginRight: '-5px', backgroundColor: '#BFF1DF' }} />
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className="linea">
                                <h4 className="tituloCuentas text-base">
                                    Número de cuenta<br />
                                </h4>
                                <h6 className="numero text-sm">
                                    {entidadPago?.numero_cuenta}
                                </h6>
                            </div>

                            <div>
                                <h4 className="tituloCuentas text-base">
                                    CCI<br />
                                </h4>
                                <h6 className="numero text-sm">
                                    {entidadPago?.cci}
                                </h6>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card flex justify-content-center">
                    <Dialog resizable={false} visible={btndelete} onHide={() => setBtndelete(false)} className="card-buton"
                            header={<><i className='font-bold pi pi-credit-card text-4xl' 
                            style={{ color: "#5555D8" }} >
                        <label style={{ color: "#5555D8", fontStyle: "normal", fontFamily: 'Montserrat'}} className="titulo-eliminar"> Eliminar Tarjeta</label></i>
                    </>} style={{ width: '30vw', minWidth: '500px', maxWidth: "446px" }} >
                        <div className="h-full card mb-0">
                            <div className="flex justify-content-between h-full flex-column gap-3">
                                <div className="flex ">
                                    <label htmlFor="num" className="flex justify-content-center" >
                                        
                                    </label>
                                </div>
                                <div className="flex w-full bot gap-3 butons-card">
                                    <Button
                                        type="button"
                                        // style={{margin: "3%"}}
                                        label="Cancelar"
                                        className="delete w-full"
                                        onClick={() => setBtndelete(false)}
                                    />
                                    <Button
                                        type="button"
                                        label="Eliminar"
                                        className="p-button w-full"
                                        style={{
                                            color: "#5555D8",
                                            backgroundColor: "#EBEBFF",
                                            borderColor: "#5555D8",
                                            // margin: "3%"
                                        }}
                                        onClick={() => handleEliminarEntidad()} />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Toast ref={toast} />
                </div>
                <div className="card flex justify-content-center">
                    <Dialog
                        onHide={() => setVisible(false)}
                        visible={visible}
                        className="card-buton"
                        header={
                            <>
                                <div className="flex flex-row gap-3 align-items-center">
                                    <div className="flex" style={{ width: "60px", height: "60px", background: "#EBEBFF", borderRadius: "10px" }}>
                                        <img src={RucIcon} alt="" />
                                    </div>
                                    <p style={{ fontWeight: "700", fontSize: "18px", color: "#5555D8" }}>Editar datos de la tarjeta</p>
                                </div>
                            </>
                        }
                        style={{ width: '25vw', minWidth: '500px', maxWidth: "446" }}
                    >
                        <div className="card">
                            <div className="flex flex-column gap-3 mb-4">
                                <div className="flex-auto">
                                    <label htmlFor="num" className="font-bold block mb-2">
                                        Entidad Bancaria
                                    </label>
                                    <Dropdown
                                        value={entidadPagoUpdate?.entidad_bancaria}
                                        onChange={(e) => handleChangeEntidadBancaria(e)}
                                        options={entidadesBancarias}
                                        optionLabel="nombre"
                                        placeholder="Seleccionar tipo de Tarjeta"
                                        className="w-full"
                                    />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="integer" className="font-bold block mb-2">
                                        Numero de Cuenta
                                    </label>
                                    <InputText  id="name" ref={numeroCuentaRef}
                                            style={{width:"100%", height:"50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px"}} 
                                            defaultValue={entidadPago.numero_cuenta} 
                                            keyfilter="num" 
                                            className="w-full text-area" />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="email" className="font-bold block mb-2">
                                        CCI
                                    </label>
                                    <InputText  id="email" ref={cciRef}
                                            style={{width:"100%", height:"50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px"}} 
                                            defaultValue={entidadPago.cci} 
                                            keyfilter="num" 
                                            className="w-full text-area" />
                                </div>
                                <div className="card flex flex-wrap justify-content-end gap-3 butons-card">
                                    <Button onClick={() => saveCuentaBancaria()} 
                                            label="Guardar" 
                                            className="p-button-success p-button-raised" 
                                    />

                                    <Button onClick={() => setVisible(false)} 
                                            label="Cancelar" severity="danger" 
                                            outlined="true" 
                                            className="p-button-danger p-button-outlined" 
                                    />

                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <Toast ref={toast} />
            </div>
        )
    }
}
