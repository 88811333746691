import React, { useState, useContext, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AuthUser from "../../../../AuthUser";
import S3Product from "./S3Product";
import S4Credentials from "../S4Credentials/S4Credentials";
import { InputText } from 'primereact/inputtext';
import "./tablaProforma.css"
// import SB3Product from "../../Services/Bregma/SB3Product/SB3Product";
// import ModalCarga from "./ModalCarga";

export default function TablaProforma({
   sales,
   packages,
   postPackages,
   showToast,
   getContact,
}) {
   const { http } = AuthUser();
   const [data, setData] = useState([]);
   const dat1 = [
      { id: 1, codigo: '001', razon_social: 'Empresa A', created_at: '2023-09-19', estado: 'Activo' },
      { id: 2, codigo: '002', razon_social: 'Empresa B', created_at: '2023-09-18', estado: 'Inactivo' },
      { id: 3, codigo: '003', razon_social: 'Empresa C', created_at: '2023-09-17', estado: 'Activo' },
      // Agrega más filas según tus necesidades
    ];
   const getProforma = async (event) => {
      try {
         const response = await http.get("proforma/get");
         const responseData = response.data;

         // Verificar si responseData.proformas es un array
         if (Array.isArray(responseData)) {
            setData(responseData); // Establecer el array directamente en data
         } else {
            setData([]); // Si responseData.proformas no es un array válido, establecer data como un array vacío
         }
         console.log("Datos:", response.data);

      } catch (error) {
         console.error("Error al obtener los datos:", error);
      }
   };
   

   useEffect(() => {
      getProforma();
   }, []);
   
   const Detalles = () => {
      return (
         <Button
            icon="pi pi-upload"
            onClick={() => setVisibleModal(true)}
            style={{
               background: "#E3E3FF8A",
               color: "#5555d8",
               border: "#E3E3FF8A",
               borderRadius: "25px"
            }}
         />
      );
   };

   const [visible, setVisible] = useState(false);
   const [visibleModal, setVisibleModal] = useState(false);
   const [first, setFirst] = useState(0); 
   const rowsPerPage = 10;

   
   const onPageChange = (event) => {
      setFirst(event.first);
   };

   return (
      <>
         <div className="p-d-flex p-jc-between p-mb-2 flex justify-content-between align-items-center pt-5 pb-5">
            
            <div style={{ width: "30%" }}>
               <span className="p-input-icon-left flex-1 border-none">
               <i className="pi pi-search" />
               <InputText
                  className="p-inputtext-sm w-full border-none border-round-2xl"
                  placeholder="Buscar Proformas"
                  style={{ fontSize: "14px", height: "45px"}}
                  />
               </span>
            </div> {/*Modificar para filtrar datos*/}
            

            <Button
               label="Crear Nuevo"
               icon="pi pi-plus"
               className="p-button-success"
               onClick={() => setVisible(true)}
            />
         </div>
         <DataTable
            value={data?.filter(proforma => proforma?.razon_social === sales?.GCEmpresa?.razon_social)}
            className="tabla-proforma"
            paginator={true}
            first={first}
            rows={rowsPerPage}
            onPage={onPageChange}
         >
            <Column field="id" header="ID" className="id_p"></Column>
            <Column field={"codigo"} header="Código de Proforma" className="codigo_p"></Column>
            <Column field="razon_social" header="Razón Social" className="razonsocial_p"></Column>
            <Column field="created_at" header="Fecha de Creación" className="fecha_p"></Column>
            <Column field="estado" header="Estado" className="estado_p"></Column>
            <Column body={Detalles} header="Carga de Archivos" className="detalles_p"></Column>
         </DataTable>

         <S3Product
            visible={visible}
            setVisible={(value) => {
               setVisible(value);
               if (!value) {
                  getProforma(); 
               }
            }}
            packages={packages}
            postPackages={postPackages}
            showToast={showToast}
            getProforma={getProforma} 
         />

         <S4Credentials 
            getContact={getContact} 
            showToast={showToast} 
            visibleModal={visibleModal} 
            setVisibleModal={setVisibleModal} 
         />
         
      </>
   );
}
