import React from 'react'
import ICN_Atencion from "../../../Images/ICN_Atencion.svg";
import '../../../Style/Recepcion/Paciente/paciente.css';
import Paciente from '../../../Components/Recepcion/Paciente/Paciente';

function PagePaciente() {
  return (
    <>
        <div className="atencion">
          <div className="ate-container">
            <div className="title">
              <img src={ICN_Atencion} alt="Atenciones" />
              <h1>Pacientes</h1>
            </div>
            <div style={{ paddingTop: "1rem" }}>
              <Paciente />
            </div>
          </div>
        </div>
    </>
  )
}

export default PagePaciente