import React, { useState, useEffect } from "react";
import '../../../Style/Permisos.css'
import check from '../../../assets/check-ultimate.svg'
import { Skeleton } from 'primereact/skeleton';
import Bienvenido from "../../../Images/Iconperfil/Bienvenido.png"

export default function PermisosComponent() {
  // Skeleton
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [])
  const loader = () => {
    return (
      <div>
        <Skeleton width="315px" height="472.89px" borderRadius="16px"></Skeleton>
      </div>
    );
  }

  if (loading) {
    return (loader())
  }
  else {
    return (
      <div className="contenedorBienvenido p-3 pt-3 pb-8">
        <div className="flex justify-content-center">
          <img
            src={Bienvenido}
            alt="" style={{ width: '160px', height: '165px' }}
          />
        </div>
        <div style={{ borderBottom: "1px solid rgba(205, 221, 244, 1)",marginTop:"-12px",marginBottom:"5px" }}/>
        <div className="cardi-content flex flex-column">
          <h3>Bienvenido</h3>
          <div className="flex flex-wrap md:flex-center gap-3" style={{ justifyContent: 'center' }} >
            <p >Las funciones que podrás realizar son las siguientes</p>
          </div>

          <p><img src={check} alt="check" style={{ marginTop: "-6px", marginRight: "5px", height: "26px", width: "26px" }} />Editar los datos de tu perfil</p>
          <p><img src={check} alt="check" style={{ marginTop: "-3px", marginRight: "5px", height: '26px', width: '26px' }} />Editar la información de las cuentas bancarias</p>
          <p><img src={check} alt="check" style={{ marginTop: "-6px", marginRight: "5px", height: '26px', width: '26px' }} />Agregar nuevas entidades bancarias</p>

        </div>
      </div>
    )
  }
}