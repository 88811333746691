import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import AuthUser from "../../../AuthUser";
import ModalCreatePersonal from "../../../Components/RecursosHumanos/Personal/ModalCreatePersonal";
import ModalCredenciales from "../../../Components/RecursosHumanos/Personal/ModalCredenciales";
import ModalEditar from "../../../Components/RecursosHumanos/Personal/ModalEditar";
import ModalEliminar from "../../../Components/RecursosHumanos/Personal/ModalEliminar";
import TablaCrud from "../../../Components/Table/TablaCrud";
import ModalCarga from "../../../Components/RecursosHumanos/Personal/ModalCarga";
import ModalReporte from "../../../Components/RecursosHumanos/Personal/ModalReporte";
import "../../../Style/personal.css";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

function PagePersonal() {
   const dispatch = useDispatch();
   const { http } = AuthUser();

   const toast = useRef(null);
   const [tipoDocumentos, setTipoDocumentos] = useState([]);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [personal, setPersonal] = useState([]);
   const [personaleliminar, setPersonaleliminar] = useState([]);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleCredenciales, setVisibleCredenciales] = useState(false);
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [visibleDelete, setVisibleDelete] = useState(false);
   const [visibleCarga, setVisibleCarga] = useState(false);
   const [visibleReporte, setVisibleReporte] = useState(false);
   const [roles, setRoles] = useState([]);
   const [locales, setLocales] = useState([]);
   const [selectPersonal, setSelectPersonal] = useState({
      id: 0,
      tipo_documento: "",
      numero_documento: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "2022-01-01",
      distrito_id: 2,
      celular: "",
      correo: "",
      usuario: "",
      rol: "",
      local: "",
   });

   const reject = () => {
      toast.current.show({
         severity: "info",
         summary: "Rechazada",
         detail: "No se realizo ninguna acción",
         life: 3000,
      });
   };

   const getAllTipoDocumentos = async () => {
      try {
         const response = await http
            .get("/tipodocumentos/get")
         setTipoDocumentos(response.data.data);
      } catch (error) {
         console.log(error);
      };
   };

   const getRoles = async () => {
      try {
         const response = await http
            .get("roles/get")
         setRoles(response?.data?.data);
      } catch (error) {
         console.log(error);
      };
   };

   const getLocales = async () => {
      try {
         const response = await http
            .get("locales/get")
         setRoles(response?.data?.data);
      } catch (error) {
         console.log(error);
      };
   };

   const getPersonal = async () => {
      try {
         const response = await http
            .get("bregma/personal/get")
         console.log("personal clinica", response.data?.data);
         setPersonal(response.data?.data);
      } catch (error) {
         console.log(error);
      };
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getAllTipoDocumentos(),
            getPersonal(),
            getRoles(),
            getLocales(),

         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
   }, []);

   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => editPaquete(rowData)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />
         </React.Fragment>
      );
   };

   const editPaquete = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleEdit(true);
      setSelectPersonal(rowData);
   };

   const actionBodyTemplateEliminar = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />
         </React.Fragment>
      );
   };
   const deletePaquete = (rowData) => {
      console.log({ rowData });
      setPersonaleliminar(rowData);
      setVisibleEliminar(true);
   };

   return (
      <div>
         <div className="flex flex-column">
            <h1 className="tit">Mi Personal</h1>
            <p className="con">Administralo de la mejor manera</p>
         </div>
         <div className="desktop-page-personal">
            <Toast ref={toast} />
            <TablaCrud
               name="Personal"
               tableNombre={`Lista de Personal`}
               descripcion={`A continuación, se visualiza la lista de personal.`}
               onInputSearch={(e) => setGlobalFilter(e.target.value)}
               valueGlobalFilter={globalFilter}
               value={personal}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={" Crear"}
               leftLabelButton4={" Reporte"}
               onClickLeftButton4={() => {
                  setVisibleReporte(true);
               }}
               leftLabelButton2={" Carga de Datos"}
               onClickLeftButton2={() => {
                  setVisibleCarga(true);
               }}
            >
               <Column field={"id"} header="Item"></Column>
               <Column
                  field={"persona.tipo_documento.nombre"}
                  header="Tipo de Documento"
               ></Column>
               <Column
                  field={"persona.numero_documento"}
                  header="# de Documento"
               ></Column>
               <Column
                  field={"persona.nombres"}
                  header="Nombre Completos"
               ></Column>
               <Column field={"persona.correo"} header="Correo"></Column>
               <Column field={"persona.celular"} header="Celular"></Column>
               <Column field={"rol.nombre"} header="Rol"></Column>
               <Column field={"local.nombre"} header="Local"></Column>
               <Column header="Acciones" body={actionBodyTemplate}></Column>
            </TablaCrud>
         </div>

         <div className="movil-page-personal">
            <Toast ref={toast} />
            <TablaCrud
               name="Personal"
               tableNombre={`Lista de Personal`}
               descripcion={`A continuación, se visualiza la lista de personal.`}
               onInputSearch={(e) => setGlobalFilter(e.target.value)}
               valueGlobalFilter={globalFilter}
               value={personal}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={" Crear"}
               leftLabelButton4={" Reporte"}
               onClickLeftButton4={() => {
                  setVisibleReporte(true);
               }}
               leftLabelButton2={" Carga de Datos"}
               onClickLeftButton2={() => {
                  setVisibleCarga(true);
               }}
            >
               <Column
                  className="cc"
                  style={{ height: "60px" }}
                  field={"id"}
                  header="Item"
               ></Column>
               <Column
                  className="cc2"
                  style={{ height: "70px" }}
                  field={"persona.tipo_documento.nombre"}
                  header="Tipo de Documento"
               ></Column>
               <Column
                  className="cc"
                  style={{ height: "70px" }}
                  field={"persona.numero_documento"}
                  header="Números de Documento"
               ></Column>
               <Column
                  className="cc2"
                  style={{ height: "70px" }}
                  field={"persona.nombres"}
                  header="Nombre Completo"
               ></Column>
               <Column
                  className="cc"
                  style={{ height: "60px" }}
                  field={"persona.correo"}
                  header="Correo"
               ></Column>
               <Column
                  className="cc2"
                  style={{ height: "60px" }}
                  field={"persona.celular"}
                  header="Celular"
               ></Column>
               <Column
                  className="cc"
                  style={{ height: "60px" }}
                  field={"rol.nombre"}
                  header="Rol"
               ></Column>
               <Column
                  className="cc2"
                  style={{ height: "60px" }}
                  field={"local.nombre"}
                  header="Local"
               ></Column>
               <Column
                  className="cc2"
                  style={{ height: "60px" }}
                  header="Acciones"
                  body={actionBodyTemplate}
               ></Column>
            </TablaCrud>
         </div>

         <ModalCreatePersonal
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            locales={locales}
            getPersonal={getPersonal}
            api="/bregma/personal/create"
         />

         <ModalEditar
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            locales={locales}
            getPersonal={getPersonal}
            api="/bregma/personal/update"
         />
         <ModalCredenciales
            visibleCredenciales={visibleCredenciales}
            setVisibleCredenciales={setVisibleCredenciales}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            locales={locales}
         />
         <ModalEliminar
            visibleEliminar={visibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            personal={personal}
            setPersonal={personaleliminar}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            locales={locales}
            getPersonal={getPersonal}
            api="/bregma/personal/delete"
         />
         <ModalCarga
            visibleCarga={visibleCarga}
            setVisibleCarga={setVisibleCarga}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            locales={locales}
         />
         <ModalReporte
            visibleReporte={visibleReporte}
            setVisibleReporte={setVisibleReporte}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            locales={locales}
         />
      </div>
   );
}

export default PagePersonal;
