import React, { useState, useEffect } from "react";
import TablaCrud from "../../Table/TablaCrud";
import { Column } from "primereact/column";
import EditRolModalComponent from "./EditRolModal";
import DeleteRolModal from "./DeleteRolModal";
import edit from "../../../Images/roles/edit.svg";
import trash from "../../../Images/roles/trash.svg";
import candado from "../../../Images/roles/candado.svg";
import AccesosModalComponent from "./AccesosModal";
import LocalesModalComponent from "./AccesosLocal";
import CreateRolModal from "./CreateRolModal";
import { Button } from "primereact/button";
import "../../../Style/Rol/TablaRol.css";
import "../../../Style/Rol/MediaJ.css";

import { listaAccesos } from "../../../Services/Operaciones/listaAccesos";
import { FilterMatchMode } from "primereact/api";

const TablaRolComponent = ({
   key,
   roles,
   rolUpdate,
   rolCreate,
   rolDelete,
   getRoles,
   accesos,
   asignarRoles,
   locales,
   GetLocales,
   setGetLocales,
   ObtenerLocalesCondicional,
   AsignarLocales,
   LocalAsignadoBD,
   GetLocalAsignado,
}) => {
   const [rol, setRol] = useState({});
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleDelete, setVisibleDelete] = useState(false);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleAccess, setVisibleAccess] = useState(false);
   const [visibleLocales, setVisibleLocales] = useState(false);
   const [listaDeAccesos, setListaDeAccesos] = useState();

   useEffect(() => {
      // Llama a la función para obtener locales cuando haya un cambio en la lista de roles
      ObtenerLocalesCondicional();
   }, [roles]); // Ejecuta la función cada vez que haya un cambio en la lista de roles

   const enumerateRoles = (roles) => {
      return roles.map((role, index) => {
         return {
            ...role,
            item: index + 1,
         };
      });
   };

   const OpenAsignarLocales = (rowData) => {
      setRol(rowData);
      GetLocalAsignado(rowData.id);
      setVisibleLocales(true);
   };

   const handleAcciones = (rowData) => {
      return (
         <div className="flex">
            <Button
               onClick={() => editRol(rowData)}
               className="p-button-text"
               tooltip="Editar"
            >
               <img src={edit} alt="button-edit" />
            </Button>
            <Button
               onClick={() => deleteRol(rowData)}
               className="p-button-text p-button-danger"
               tooltip="Eliminar"
            >
               <img src={trash} alt="button-delete" />
            </Button>
         </div>
      );
   };

   const handleAccesos = (rowData) => {
      const tieneAccesos = Object.keys(rowData?.accesos || {}).length > 0;
      const backgroundColor = tieneAccesos ? "#22C55E" : "transparent";

      return (
         <div className="accesos-container flex">
            <Button
               onClick={() => {
                  sendAccesos(rowData);
                  setListaDeAccesos(rowData?.accesos);
               }}
               className="p-button-text p-button-success"
               tooltip="Administrar Accesos"
               tooltipOptions={{ position: "top" }}
               style={{ backgroundColor: backgroundColor }}
            >
               <img
                  src={candado}
                  alt="access"
                  className="access"
                  style={{
                     marginRight: "5px",
                     width: "20px",
                     height: "20px",
                     fill: tieneAccesos ? "white" : "none",
                  }}
               />
               <p
                  style={{
                     fontWeight: "700",
                     fontSize: "12px",
                     lineHeight: "19.5px",
                     color: tieneAccesos ? "#FFF" : "#00A15C",
                  }}
               >
                  Administrar accesos
               </p>
            </Button>
         </div>
      );
   };

   const handleLocales = (rowData) => {
      const tieneLocales =
         rowData.bregma_local_id ||
         rowData.clinica_local_id ||
         rowData.empresa_local_id;
      const backgroundColor = tieneLocales ? "#22C55E" : "transparent";

      return (
         <div className="flex">
            <Button
               onClick={() => OpenAsignarLocales(rowData)}
               className="p-button-text p-button-success gap-2"
               tooltip="Administrar Locales"
               tooltipOptions={{ position: "top" }}
               style={{ backgroundColor: backgroundColor }}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 38 47"
                  fill="none"
                  marginRight="5px"
               >
                  <path
                     d="M10.5772 28.8039C5.55687 30.256 2.1792 32.97 2.1792 36.0784C2.1792 40.7165 9.69905 44.4765 18.9753 44.4765C28.2515 44.4765 35.7713 40.7165 35.7713 36.0784C35.7713 32.97 32.3936 30.256 27.3733 28.8039M31.5723 15.9232C31.5723 23.3441 22.1245 36.0784 18.9753 36.0784C15.826 36.0784 6.37821 23.3441 6.37821 15.9232C6.37821 8.50221 12.0181 2.48633 18.9753 2.48633C25.9324 2.48633 31.5723 8.50221 31.5723 15.9232ZM23.1743 15.0834C23.1743 17.4024 21.2943 19.2824 18.9753 19.2824C16.6562 19.2824 14.7762 17.4024 14.7762 15.0834C14.7762 12.7643 16.6562 10.8844 18.9753 10.8844C21.2943 10.8844 23.1743 12.7643 23.1743 15.0834Z"
                     stroke="rgb(0, 161, 92)"
                     strokeWidth="4.20136"
                  />
               </svg>
               <p
                  style={{
                     fontWeight: "700",
                     fontSize: "12px",
                     lineHeight: "19.5px",
                     color: tieneLocales ? "#FFF" : "#00A15C",
                  }}
               >
                  Administrar Locales
               </p>
            </Button>
         </div>
      );
   };

   const handleItem = (rowData) => {
      return <div className="item-rol">{rowData.item}</div>;
   };

   const sendAccesos = (rowData) => {
      setRol(rowData);
      setVisibleAccess(true);
   };

   const editRol = (rowData) => {
      setRol(rowData);
      setVisibleEdit(true);
   };

   const deleteRol = (rowData) => {
      setRol(rowData);
      setVisibleDelete(true);
   };

   const enumeratedRoles = enumerateRoles(roles);

   return (
      <div className="TablaCrudRolesRecursosHumanos content-table-rol flex w-12 justify-content-end table centrar">
         <div className="rol-desktop">
            <TablaCrud
               key={key}
               name={"Roles"}
               value={enumerateRoles(roles)}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={"Crear"}
            >
               <Column
                  className="cccc"
                  field="item"
                  header={<span className="header-item">Item</span>}
                  body={handleItem}
                  style={{ minWidth: "16%" }}
               ></Column>

               <Column
                  className="ccc1"
                  field="nombre"
                  header={<span className="header-rol">Rol</span>}
                  style={{
                     minWidth: "60%",
                     textAlign: "center",
                     fontWeight: "700",
                     fontSize: "12px",
                     whiteSpace: "wrap",
                  }}
               ></Column>

               <Column
                  className="ccc2"
                  header={<span className="header-accesos">Accesos</span>}
                  body={handleAccesos}
                  style={{ minWidth: "60%", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className="ccc4"
                  header={<span className="header-locales">Locales</span>}
                  body={handleLocales}
                  style={{ minWidth: "60%", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className="ccc3"
                  header={<span className="header-acciones">Acciones</span>}
                  body={handleAcciones}
                  style={{ minWidth: "45%", whiteSpace: "wrap" }}
               ></Column>
            </TablaCrud>
         </div>

         <div className="rol-movil-view">
            <TablaCrud
               name={"Roles"}
               value={enumeratedRoles}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={"Crear"}
            >
               <Column
                  className="ccc2"
                  field="item"
                  header={
                     <span
                        className="header-item"
                        style={{ marginLeft: "5px" }}
                     >
                        Item
                     </span>
                  }
                  style={{ minWidth: "26%" }}
               ></Column>
               <Column
                  className="ccc1"
                  field="nombre"
                  header="Rol"
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
               <Column
                  className="ccc2"
                  header="Accesos"
                  body={handleAccesos}
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
               <Column
                  header={<span className="">Locales</span>}
                  body={handleLocales}
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
               <Column
                  className="ccc1"
                  header="Acciones"
                  body={handleAcciones}
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
            </TablaCrud>
         </div>

         <CreateRolModal
            rol={rol}
            setRol={setRol}
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            rolCreate={rolCreate}
            getRoles={getRoles}
         />

         <EditRolModalComponent
            rol={rol}
            setRol={setRol}
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            rolUpdate={rolUpdate}
            getRoles={getRoles}
         />
         <DeleteRolModal
            rol={rol}
            visibleDelete={visibleDelete}
            setVisibleDelete={setVisibleDelete}
            rolDelete={rolDelete}
            getRoles={getRoles}
         />
         <AccesosModalComponent
            visibleAccess={visibleAccess}
            setVisibleAccess={setVisibleAccess}
            rol={rol}
            setRol={setRol}
            accesos={accesos}
            asignarRoles={asignarRoles}
            listaAccesos={listaDeAccesos}
         />
         <LocalesModalComponent
            visibleLocales={visibleLocales}
            setVisibleLocales={setVisibleLocales}
            rol={rol}
            setRol={setRol}
            locales={locales}
            GetLocales={GetLocales}
            setGetLocales={GetLocales}
            AsignarLocales={AsignarLocales}
            LocalAsignadoBD={LocalAsignadoBD}
         />
      </div>
   );
};

export default TablaRolComponent;
